import List from "@mui/material/List";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SchoolIcon from '@mui/icons-material/School';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import Divider from "@mui/material/Divider";
import * as React from "react";
import NavigationItem from "./NavigationItem";

interface MainNavigationProps {
    collapsed: boolean
}


export default function MainNavigation(props: MainNavigationProps) {
    return (
        <>
            <List>
                <NavigationItem label={"Schools/Daycares"} icon={<SchoolIcon/>} collapsed={props.collapsed} to={"/collectingPlaces"}/>
                <NavigationItem label={"Retails"} icon={<ShoppingBagIcon/>} collapsed={props.collapsed} to={"/collectingPlaces/retails"}/>
                <NavigationItem label={"Others"} icon={<TakeoutDiningIcon/>} collapsed={props.collapsed} to={"/collectingPlaces/others"}/>
                <NavigationItem label={"Returns"} icon={<LocalShippingIcon/>} collapsed={props.collapsed}
                                to={"/returns"}/>
            </List>
            <Divider/>
        </>
    )
}
