import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import DataList from "../../../common/tables/DataList";
import {
    getMaterialShipments,
} from "../../features/collectingPlaces";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {Shipment} from "../../services/takebackApi";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


interface MaterialShipmentsListProps {
    collectingPlaceId: string

    onUpdate? : (time: number) => void
    lastUpdate?: number
}

export default function MaterialShipmentsList(props: MaterialShipmentsListProps) {
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [materialShipments, setMaterialShipments] = useState<Shipment[]>([]);

    const [lastUpdate, setLastUpdate] = useState<number>(-1)

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 175,
        },
        {
            field: 'date',
            headerName: 'date',
            type: "dateTime",
            minWidth: 150,
            flex: 1,
            valueGetter: ({ value }) => value && new Date(value),
        },
        {
            field: 'weight',
            headerName: 'weight',
            type: "number",
            minWidth: 150,
            flex: 1,
            valueGetter: (params) => params.row.details.weight.value + "" + params.row.details.weight.uom,
        },
        {
            field: 'label',
            headerName: 'Label',
            type: 'actions',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<PictureAsPdfIcon/>}
                                                  label="Label"
                                                  onClick={downloadLabel(params.row.shipmentData.labelData)}
                                                  showInMenu={false}/>)
                return actions
            }
        },
    ]

    // taken from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        return new Blob(byteArrays, {type: contentType})
    }

    const downloadLabel = (label: string) => () => {
        // @ts-ignore
        const file = b64toBlob(label, 'application/pdf')

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = "labels-" + props.collectingPlaceId + ".pdf";

        // simulate link click
        document.body.appendChild(element); // Required for this to work in firefox
        element.click();
    }

    const reloadMaterialShipments = () => {
        setIsLoading(true)

        getMaterialShipments(props.collectingPlaceId)
            .then((allShipments) => {
                setMaterialShipments(allShipments)
                setIsLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    useEffect(() => {
        if(lastUpdate !== props.lastUpdate)
            reloadMaterialShipments()
    }, [props.collectingPlaceId, props.lastUpdate])

    return (
        <>
            <DataList
                items={materialShipments}
                columns={columns}
                isLoading={isLoading}/>
        </>
    )
}
