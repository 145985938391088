/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum ReturnOrderCategory {
    CONSUMER = 'consumer',
    BAG = 'bag',
    DAYCARE = 'daycare',
    SCHOOL = 'school',
    RETAIL = 'retail',
    OTHER = 'other',
}
