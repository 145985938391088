export default `Ort;Zusatz;Plz;Vorwahl;Bundesland
Aach;b Trier;54298;0651;Rheinland-Pfalz
Aach;, Hegau;78267;07774;Baden-Württemberg
Aachen;;52062;0241;Nordrhein-Westfalen
Aachen;;52064;0241;Nordrhein-Westfalen
Aachen;;52066;0241;Nordrhein-Westfalen
Aachen;;52068;0241;Nordrhein-Westfalen
Aachen;;52070;0241;Nordrhein-Westfalen
Aachen;;52072;0241;Nordrhein-Westfalen
Aachen;;52074;0241;Nordrhein-Westfalen
Aachen;;52076;0241;Nordrhein-Westfalen
Aachen;;52078;0241;Nordrhein-Westfalen
Aachen;;52080;0241;Nordrhein-Westfalen
Aalen;, Württ;73430;07361;Baden-Württemberg
Aalen;, Württ;73431;07361;Baden-Württemberg
Aalen;, Württ;73432;07361;Baden-Württemberg
Aalen;, Württ;73433;07361;Baden-Württemberg
Aalen;, Württ;73434;07361;Baden-Württemberg
Aarbergen;;65326;06120;Hessen
Aasbüttel;;25560;04892;Schlewig-Holstein
Abbau Ader;;29416;;Sachsen-Anhalt
Abbendorf;a d Havel;19322;038791;Brandenburg
Abbenrode;b Wernigerode;38871;039452;Sachsen-Anhalt
Abberode;;06543;034779;Sachsen-Anhalt
Abenberg;, Mittelfr;91183;09178;Bayern
Abend;;01623;035246;Sachsen
Abensberg;, Hallertau;93326;09443;Bayern
Abentheuer;;55767;06782;Rheinland-Pfalz
Abraham;, Kr Passau;94036;;Bayern
Absberg;;91720;09175;Bayern
Abstatt;;74232;07062;Baden-Württemberg
Abtlöbnitz;;06628;034466;Sachsen-Anhalt
Abtsbessingen;;99713;036020;Thüringen
Abtsdorf;b Lutherstadt Wittenberg;06888;03491;Sachsen-Anhalt
Abtsgmünd;;73453;07366;Baden-Württemberg
Abtsteinach;;69518;06207;Hessen
Abtswind;;97355;09383;Bayern
Abtweiler;;55568;06753;Rheinland-Pfalz
Achberg;b Lindau, Bodensee;88147;08380;Baden-Württemberg
Achen;b Evenhausen, Oberbay;83552;08075;Bayern
Achern;, Baden;77855;07841;Baden-Württemberg
Achim;b Bremen;28832;04202;Niedersachsen
Achim;, Kr Wolfenbüttel;38312;05334;Niedersachsen
Achslach;;94250;09929;Bayern
Achstetten;;88480;07392;Baden-Württemberg
Acht;;56729;02656;Rheinland-Pfalz
Achtelsbach;;55767;06782;Rheinland-Pfalz
Achterspannerhof;;56299;;Rheinland-Pfalz
Achterwehr;;24239;04340;Schlewig-Holstein
Achtrup;;25917;04662;Schlewig-Holstein
Ackendorf;b Haldensleben;39343;039202;Sachsen-Anhalt
Ackermannshof;;16259;033451;Brandenburg
Adamshoffnung;;17213;039932;Mecklenburg-Vorpommern
Addebüll;;25842;04672;Schlewig-Holstein
Adelberg;, Württ;73099;07166;Baden-Württemberg
Adelebsen;;37139;05506;Niedersachsen
Adelhausen;b Hildburghausen;98646;03685;Thüringen
Adelheidsdorf;;29352;05085;Niedersachsen
Adelmannsfelden;;73486;07963;Baden-Württemberg
Adelschlag;;85111;08421;Bayern
Adelsdorf;b Großenhain, Sachs;01561;03522;Sachsen
Adelsdorf;, Mittelfr;91325;09195;Bayern
Adelsheim;;74740;06291;Baden-Württemberg
Adelshofen;, Kr Fürstenfeldbruck;82276;08146;Bayern
Adelshofen;, Mittelfr;91587;09865;Bayern
Adelsried;b Augsburg;86477;08294;Bayern
Adelzhausen;;86559;08258;Bayern
Adenau;;53518;02691;Rheinland-Pfalz
Adenbach;;67742;06753;Rheinland-Pfalz
Adenbüttel;;38528;05304;Niedersachsen
Adendorf;, Kr Lüneburg;21365;04131;Niedersachsen
Adenstedt;b Alfeld, Leine;31079;05060;Niedersachsen
Aderstedt;b Bernburg;06408;03471;Sachsen-Anhalt
Aderstedt;b Halberstadt;38838;039428;Sachsen-Anhalt
Adlersteige;;73479;07961;Baden-Württemberg
Adligstadt;;83364;08666;Bayern
Adlkofen;b Landshut, Isar;84166;08707;Bayern
Adlkofen;b Landshut, Isar;84166;08707;Bayern
Admannshagen-Bargeshagen;;18211;038203;Mecklenburg-Vorpommern
Adorf;, Vogtl;08626;037423;Sachsen
Aebtissinwisch;;25572;04825;Schlewig-Holstein
Aerzen;;31855;05154;Niedersachsen
Affalterbach;, Württ;71563;07144;Baden-Württemberg
Affing;;86444;08207;Bayern
Affinghausen;;27257;04247;Niedersachsen
Affler;;54689;06524;Rheinland-Pfalz
Aftholderbach;;56355;06772;Rheinland-Pfalz
Agathenburg;;21684;04141;Niedersachsen
Agethorst;;25560;04892;Schlewig-Holstein
Aglasterhausen;;74858;06262;Baden-Württemberg
Aham;, Vils;84168;08744;Bayern
Ahaus;;48683;02561;Nordrhein-Westfalen
Ahausen;, Kr Rotenburg, Wümme;27367;04269;Niedersachsen
Ahlbeck;;17375;039775;Mecklenburg-Vorpommern
Ahlden;(Aller);29693;05164;Niedersachsen
Ahlefeld;b Rendsburg;24811;04353;Schlewig-Holstein
Ahlen;, Westf;59227;02382;Nordrhein-Westfalen
Ahlen;, Westf;59229;02382;Nordrhein-Westfalen
Ahlerstedt;;21702;04166;Niedersachsen
Ahlsdorf;b Schönewalde;04916;035362;Brandenburg
Ahlsdorf;b Lutherstadt Eisleben;06313;034772;Sachsen-Anhalt
Ahlstädt;;98553;036873;Thüringen
Ahlum;, Altmark;38489;039007;Sachsen-Anhalt
Ahnatal;;34292;05609;Hessen
Ahneby;;24996;04637;Schlewig-Holstein
Ahnsbeck;;29353;05145;Niedersachsen
Ahnsen;b Stadthagen;31708;05722;Niedersachsen
Aholfing;;94345;09429;Bayern
Aholming;;94527;09938;Bayern
Ahorn;, Baden;74744;06296;Baden-Württemberg
Ahorn;, Kr Coburg;96482;09561;Bayern
Ahorntal;;95491;09202;Bayern
Ahrain;b Wörnsmühl;83739;08025;Bayern
Ahrbrück;;53506;02643;Rheinland-Pfalz
Ahrensbök;;23623;04525;Schlewig-Holstein
Ahrensburg;;22926;04102;Schlewig-Holstein
Ahrensdorf;b Ludwigsfelde;14974;03378;Brandenburg
Ahrensdorf;b Beeskow;15864;033677;Brandenburg
Ahrensfelde;b Berlin;16356;030;Brandenburg
Ahrenshagen;b Ribnitz-Damgarten;18320;038225;Mecklenburg-Vorpommern
Ahrenshöft;;25853;04846;Schlewig-Holstein
Ahrenstedt;, Gem Rickert;24787;;Schlewig-Holstein
Ahrenviöl;;25885;04847;Schlewig-Holstein
Ahrenviölfeld;;25885;04626;Schlewig-Holstein
Aicha;vorm Wald;94529;08544;Bayern
Aichach;a d Paar;86551;08251;Bayern
Aichat;;83565;08092;Bayern
Aichberg;b Waging a See;83329;08681;Bayern
Aichelberg;, Kr Göppingen;73101;07164;Baden-Württemberg
Aichen;, Schwab;86479;08284;Bayern
Aichhalden;b Schramberg;78733;07422;Baden-Württemberg
Aichstetten;b Leutkirch im Allgäu;88317;07565;Baden-Württemberg
Aichtal;;72631;07127;Baden-Württemberg
Aichwald;, Schurw;73773;0711;Baden-Württemberg
Aichwies;;83329;08681;Bayern
Aidenbach;, Niederbay;94501;08543;Bayern
Aidhausen;;97491;09526;Bayern
Aidlingen;, Württ;71134;07034;Baden-Württemberg
Aiglsbach;;84089;08753;Bayern
Ailertchen;;56459;02663;Rheinland-Pfalz
Aindling;;86447;08237;Bayern
Ainring;;83404;08654;Bayern
Aislingen;, Schwab;89344;09075;Bayern
Aiterhofen;;94330;09421;Bayern
Aitern;;79677;07673;Baden-Württemberg
Aiteröd;;84137;08741;Bayern
Aitrach;;88319;07565;Baden-Württemberg
Aitrang;;87648;08343;Bayern
Aitzendorf;;09326;037382;Sachsen
Aken;(Elbe);06385;034909;Sachsen-Anhalt
Alach;;99100;0361;Thüringen
Alach;;99100;0361;Thüringen
Albaching;;83544;08076;Bayern
Albbruck;;79774;07753;Baden-Württemberg
Albernhof;b Waldmünchen;93449;09978;Bayern
Albersdorf;b Stadtroda;07646;036692;Thüringen
Albersdorf;, Holst;25767;04835;Schlewig-Holstein
Albershausen;;73095;07161;Baden-Württemberg
Albersroda;;06268;034632;Sachsen-Anhalt
Alberstedt;;06279;034774;Sachsen-Anhalt
Albersweiler;;76857;06345;Rheinland-Pfalz
Alberthofen;;57614;02684;Rheinland-Pfalz
Albertinenhof;b Neuruppin;16818;033925;Brandenburg
Albertinenhof;b Boizenburg;19260;038848;Mecklenburg-Vorpommern
Albertshofen;, Kr Kitzingen;97320;09321;Bayern
Albessen;;66871;06384;Rheinland-Pfalz
Albig;;55234;06731;Rheinland-Pfalz
Albisheim;(Pfrimm);67308;06355;Rheinland-Pfalz
Albrechts;b Suhl;98529;03681;Thüringen
Albrechts;b Suhl;98529;03681;Thüringen
Albrechtshof;b Prenzlau;17291;039862;Brandenburg
Albsfelde;;23909;04541;Schlewig-Holstein
Albstadt;, Württ;72458;07432;Baden-Württemberg
Albstadt;, Württ;72459;07432;Baden-Württemberg
Albstadt;, Württ;72461;07432;Baden-Württemberg
Aldenhoven;b Jülich;52457;02464;Nordrhein-Westfalen
Aldersbach;;94501;08543;Bayern
Aldingen;, Kr Tuttlingen;78554;07424;Baden-Württemberg
Alerheim;;86733;09085;Bayern
Alesheim;;91793;09146;Bayern
Aletshausen;;86480;08282;Bayern
Alf;;56859;06542;Rheinland-Pfalz
Alfdorf;;73553;07172;Baden-Württemberg
Alfeld;(Leine);31061;05181;Niedersachsen
Alfeld;, Mittelfr;91236;09157;Bayern
Alfhausen;;49594;05464;Niedersachsen
Alflen;;56828;02678;Rheinland-Pfalz
Alfstedt;b Bremervörde;27432;04765;Niedersachsen
Alfter;;53347;0228;Nordrhein-Westfalen
Algenstedt;;39638;03907;Sachsen-Anhalt
Algermissen;;31191;05126;Niedersachsen
Algertshausen;b Greifenberg, Ammersee;86926;;Bayern
Algesbüttel;;38551;05373;Niedersachsen
Algishofen;;73453;07975;Baden-Württemberg
Alheim;;36211;05664;Hessen
Alikendorf;;39398;039408;Sachsen-Anhalt
Alken;, Mosel;56332;02605;Rheinland-Pfalz
Alkersleben;;99310;036200;Thüringen
Alkersum;;25938;04681;Schlewig-Holstein
Allenbach;, Hunsrück;55758;06786;Rheinland-Pfalz
Allendorf;b Rudolstadt;07426;036730;Thüringen
Allendorf;(Eder);35108;06407;Hessen
Allendorf;(Lumda);35469;06452;Hessen
Allendorf;, Rhein-Lahn-Kr;56370;06486;Rheinland-Pfalz
Allenfeld;;55595;06756;Rheinland-Pfalz
Allensbach;;78476;07533;Baden-Württemberg
Alleringersleben;;39343;039400;Sachsen-Anhalt
Allersberg;, Mittelfr;90584;09176;Bayern
Allersdorf;b Ilmenau, Thür;98701;036781;Thüringen
Allershausen;, Oberbay;85391;08166;Bayern
Alleshausen;;88422;07374;Baden-Württemberg
Alling;, Oberbay;82239;08141;Bayern
Allmannshofen;;86695;08273;Bayern
Allmannsweiler;b Bad Saulgau;88348;07582;Baden-Württemberg
Allmendingen;, Württ;89604;07391;Baden-Württemberg
Allmenhausen;;99713;036020;Thüringen
Allmersbach;im Tal;71573;07191;Baden-Württemberg
Allmosen;;01983;035753;Brandenburg
Allrode;;06507;039487;Sachsen-Anhalt
Allstedt;;06542;034652;Sachsen-Anhalt
Almdorf;;25821;04671;Schlewig-Holstein
Almersbach;;57610;02681;Rheinland-Pfalz
Almosmühle;;93449;09975;Bayern
Almstedt;;31079;05060;Niedersachsen
Alpen;;46519;02802;Nordrhein-Westfalen
Alpenrod;;57642;02662;Rheinland-Pfalz
Alperstedt;;99195;036204;Thüringen
Alpirsbach;;72275;07444;Baden-Württemberg
Alsau;;53547;02638;Rheinland-Pfalz
Alsbach;, Westerw;56237;02601;Rheinland-Pfalz
Alsbach-Hähnlein;;64665;06257;Hessen
Alsdorf;, Rheinl;52477;02404;Nordrhein-Westfalen
Alsdorf;, Eifel;54668;06568;Rheinland-Pfalz
Alsdorf;, Sieg;57518;02741;Rheinland-Pfalz
Alsenz;;67821;06362;Rheinland-Pfalz
Alsfeld;;36304;06631;Hessen
Alsheim;, Rheinhess;67577;06249;Rheinland-Pfalz
Alsleben;(Saale);06425;034692;Sachsen-Anhalt
Alt Bennebek;;24848;04624;Schlewig-Holstein
Alt Bork;;14822;033845;Brandenburg
Alt Brieselang;;14656;033232;Brandenburg
Alt Bukow;;18233;038294;Mecklenburg-Vorpommern
Alt Duvenstedt;;24791;04338;Schlewig-Holstein
Alt Golm;;15526;033631;Brandenburg
Alt Jabel;;19303;038759;Mecklenburg-Vorpommern
Alt Karin;;18236;038297;Mecklenburg-Vorpommern
Alt Kätwin;;18299;038459;Mecklenburg-Vorpommern
Alt Krenzlin;;19288;038751;Mecklenburg-Vorpommern
Alt Krüssow;;16928;03395;Brandenburg
Alt Lanschvitz;;18581;038301;Mecklenburg-Vorpommern
Alt Lutterow;;16837;033923;Brandenburg
Alt Madlitz;;15518;033607;Brandenburg
Alt Mahlisch;;15306;033602;Brandenburg
Alt Meteln;;19069;03867;Mecklenburg-Vorpommern
Alt Mölln;;23881;04542;Schlewig-Holstein
Alt Necheln;;19412;038483;Mecklenburg-Vorpommern
Alt Poorstorf;;23992;038429;Mecklenburg-Vorpommern
Alt Rehse;;17217;03962;Mecklenburg-Vorpommern
Alt Ruppin;;16827;03391;Brandenburg
Alt Schönau;;17192;039934;Mecklenburg-Vorpommern
Alt Schwerin;;17214;039932;Mecklenburg-Vorpommern
Alt Stahnsdorf;;15859;033678;Brandenburg
Alt Tellin;;17129;039991;Mecklenburg-Vorpommern
Alt Tucheband;;15328;033472;Brandenburg
Alt Zachun;;19230;038859;Mecklenburg-Vorpommern
Alt Zauche;;15913;03546;Brandenburg
Altbach;, Württ;73776;07153;Baden-Württemberg
Altbarnim;;15320;033474;Brandenburg
Altbrand;;76726;07274;Rheinland-Pfalz
Altbrandsleben;;39387;039407;Sachsen-Anhalt
Altdöbern;;03229;035434;Brandenburg
Altdorf;, Pfalz;67482;06327;Rheinland-Pfalz
Altdorf;, Kr Böblingen;71155;07031;Baden-Württemberg
Altdorf;b Nürtingen;72655;07127;Baden-Württemberg
Altdorf;, Niederbay;84032;08704;Bayern
Altdorf;b. Nürnberg;90518;09187;Bayern
Altefähr;;18573;038306;Mecklenburg-Vorpommern
Alteglofsheim;;93087;09453;Bayern
Altena;, Westf;58762;02352;Nordrhein-Westfalen
Altenahr;;53505;02643;Rheinland-Pfalz
Altenau;b Mühlberg, Elbe;04931;035342;Brandenburg
Altenau;, Harz;38707;05328;Niedersachsen
Altenbach;b Wurzen;04828;03425;Sachsen
Altenbamberg;;55585;06708;Rheinland-Pfalz
Altenbeken;;33184;05255;Nordrhein-Westfalen
Altenberg;, Erzgeb;01773;035056;Sachsen
Altenberga;;07768;036424;Thüringen
Altenberge;, Westf;48341;02505;Nordrhein-Westfalen
Altenbeuthen;;07338;036737;Thüringen
Altenbrak;;38889;039456;Sachsen-Anhalt
Altenbuch;, Unterfr;97901;09392;Bayern
Altenburg;, Thür;04600;03447;Thüringen
Altendambach;;98553;03681;Thüringen
Altendiez;;65624;06432;Rheinland-Pfalz
Altendorf;b Sebnitz;01855;035022;Sachsen
Altendorf;b Nabburg;92540;09675;Bayern
Altendorf;, Kr Bamberg;96146;09545;Bayern
Altenfeld;b Ilmenau, Thür;98701;036781;Thüringen
Altengesees;;07356;036643;Thüringen
Altenglan;;66885;06381;Rheinland-Pfalz
Altengottern;;99991;036022;Thüringen
Altenhagen;b Altentreptow;17091;039600;Mecklenburg-Vorpommern
Altenhagen;b Bad Doberan;18236;038292;Mecklenburg-Vorpommern
Altenhausen;b Haldensleben;39343;039052;Sachsen-Anhalt
Altenhof;b Eberswalde;16244;033363;Brandenburg
Altenhof;b Röbel;17209;039924;Mecklenburg-Vorpommern
Altenhof;b Eckernförde;24340;04351;Schlewig-Holstein
Altenhof;b Oberweis, Eifel;54636;06527;Rheinland-Pfalz
Altenhof;b Kyllburg;54655;06594;Rheinland-Pfalz
Altenhof;b Kaiserslautern;67677;06303;Rheinland-Pfalz
Altenholz;;24161;0431;Schlewig-Holstein
Altenholz;;24161;0431;Schlewig-Holstein
Altenkirchen;, Rügen;18556;038391;Mecklenburg-Vorpommern
Altenkirchen;(Westerwald);57610;02681;Rheinland-Pfalz
Altenkirchen;, Pfalz;66903;06386;Rheinland-Pfalz
Altenkrempe;;23730;04561;Schlewig-Holstein
Altenkunstadt;;96264;09572;Bayern
Altenmarkt;a d Alz;83352;08621;Bayern
Altenmedingen;;29575;05807;Niedersachsen
Altenmoor;;25335;04121;Schlewig-Holstein
Altenmünster;, Schwab;86450;08295;Bayern
Altenpleen;;18445;038323;Mecklenburg-Vorpommern
Altenriet;;72657;07127;Baden-Württemberg
Altenroda;;06642;034465;Sachsen-Anhalt
Altensalzwedel;;29416;039035;Sachsen-Anhalt
Altenstadt;, Hess;63674;06047;Hessen
Altenstadt;b Schongau;86972;08861;Bayern
Altenstadt;, Iller;89281;08337;Bayern
Altenstadt;a d Waldnaab;92665;09602;Bayern
Altensteig;, Württ;72213;07453;Baden-Württemberg
Altenthann;, Oberpf;93177;09408;Bayern
Altentreptow;;17087;03961;Mecklenburg-Vorpommern
Altenweddingen;;39171;039205;Sachsen-Anhalt
Altenzaun;;39596;039394;Sachsen-Anhalt
Alterkülz;;56288;06762;Rheinland-Pfalz
Alterode;;06543;034742;Sachsen-Anhalt
Altersbach;b Schmalkalden;98587;036847;Thüringen
Altertheim;;97237;09307;Bayern
Altes Forsthaus;;56130;;Rheinland-Pfalz
Altes Lager;;14913;03372;Brandenburg
Altfraunhofen;;84169;08705;Bayern
Altfriedland;;15320;033476;Brandenburg
Altfriesack;;16818;033925;Brandenburg
Alt-Galow;;16278;033338;Brandenburg
Altgersdorf;;16259;033451;Brandenburg
Altglietzen;;16259;033369;Brandenburg
Althegnenberg;;82278;08202;Bayern
Altheim;b Riedlingen, Württ;88499;07371;Baden-Württemberg
Altheim;(Alb);89174;07340;Baden-Württemberg
Altheim;b Ehingen, Donau;89605;07391;Baden-Württemberg
Altheimersberg;;91788;09143;Bayern
Althengstett;;75382;07051;Baden-Württemberg
Althornbach;;66484;06338;Rheinland-Pfalz
Althütte;, Württ;71566;07183;Baden-Württemberg
Althüttendorf;;16247;033361;Brandenburg
Altjeßnitz;;06800;03494;Sachsen-Anhalt
Altkalen;;17179;039973;Mecklenburg-Vorpommern
Altkirchen;b Schmölln, Thür;04626;034491;Thüringen
Altkünkendorf;;16278;033337;Brandenburg
Altlandsberg;;15345;033438;Brandenburg
Altlay;;56858;06543;Rheinland-Pfalz
Altleiningen;;67317;06356;Rheinland-Pfalz
Altlewin;;16259;033452;Brandenburg
Altlüdersdorf;;16775;03306;Brandenburg
Altlußheim;;68804;06205;Baden-Württemberg
Altmachern;;54492;;Rheinland-Pfalz
Altmädewitz;;16259;033456;Brandenburg
Altmannstein;;93336;09446;Bayern
Altmersleben;;39624;039080;Sachsen-Anhalt
Altmittweida;;09648;03727;Sachsen
Altomünster;;85250;08254;Bayern
Altona;b Eldena a d Elde;19294;038755;Mecklenburg-Vorpommern
Altottenhain;;04643;;Sachsen
Altötting;;84503;08671;Bayern
Altreetz;;16259;033457;Brandenburg
Altrich;;54518;06571;Rheinland-Pfalz
Altrip;, Kr Ludwigshafen am Rhein;67122;06236;Rheinland-Pfalz
Alt-Schadow;;15910;035473;Brandenburg
Altscheid;;54636;06527;Rheinland-Pfalz
Altshausen;;88361;07584;Baden-Württemberg
Altstrimmig;;56858;06545;Rheinland-Pfalz
Alt-Sührkow;;17166;03996;Mecklenburg-Vorpommern
Altthymen;;16798;033093;Brandenburg
Alttrebbin;;16259;033474;Brandenburg
Altusried;;87452;08373;Bayern
Altwarp;;17375;039773;Mecklenburg-Vorpommern
Altweidelbach;;55469;06761;Rheinland-Pfalz
Altwigshagen;;17379;039777;Mecklenburg-Vorpommern
Altwittenbek;;24214;0431;Schlewig-Holstein
Altwustrow;;16259;033457;Brandenburg
Alveslohe;;25486;04193;Schlewig-Holstein
Alzenau;, Unterfr;63755;06023;Bayern
Alzey;;55232;06731;Rheinland-Pfalz
Am Jägerberg;;21227;;Niedersachsen
Am Quicken;;82493;;Bayern
Am Rainberge;;34497;;Hessen
Am Römerhof;;61200;;Hessen
Am Römerschacht;;61200;;Hessen
Am Rönndeich;;21706;;Niedersachsen
Am Rüdesheimer Hafen;, Rheingau;65385;;Hessen
Am Sandkopf;Gem Fuldatal;34127;;Hessen
Am Sandweiher;;66849;06371;Rheinland-Pfalz
Am Springberg;;76889;;Rheinland-Pfalz
Am Tekenberge;;38350;05355;Niedersachsen
Amalienfelde;;16766;033055;Brandenburg
Amberg;b Buchloe;86854;08241;Bayern
Amberg;, Oberpf;92224;09621;Bayern
Amelinghausen;;21385;04132;Niedersachsen
Amerang;;83123;08075;Bayern
Amerdingen;;86735;09008;Bayern
Amesdorf;;39439;039262;Sachsen-Anhalt
Ammeldingen;bei Neuerburg;54673;06564;Rheinland-Pfalz
Ammeldingen;an der Our;54675;06566;Rheinland-Pfalz
Ammelstädt;;07407;03672;Thüringen
Ammerbuch;;72119;07073;Baden-Württemberg
Ammern;;99974;03601;Thüringen
Ammerndorf;;90614;09127;Bayern
Ammersbek;;22949;04532;Schlewig-Holstein
Ammerthal;, Oberpf;92260;09628;Bayern
Amöneburg;;35287;06429;Hessen
Amorbach;;63916;09373;Bayern
Ampfing;;84539;08636;Bayern
Ampfurth;;39387;039407;Sachsen-Anhalt
Amsdorf;;06317;034601;Sachsen-Anhalt
Amselgraben;b Waging a See;83329;;Bayern
Amstetten;, Württ;73340;07331;Baden-Württemberg
Amtsberg;;09439;037209;Sachsen
Amtzell;;88279;07520;Baden-Württemberg
An der Chaussee;b Friedrichstadt, Eider;25840;;Schlewig-Holstein
An der Rhumequelle;b Gieboldehausen;37434;;Niedersachsen
Andechs;;82346;08152;Bayern
Andenhausen;;36452;036964;Thüringen
Anderbeck;;38836;039422;Sachsen-Anhalt
Anderlingen;;27446;04284;Niedersachsen
Andernach;;56626;02632;Rheinland-Pfalz
Andervenne;;49832;05902;Niedersachsen
Andisleben;;99189;036201;Thüringen
Andrichstadt;;83364;08662;Bayern
Angelbachtal;;74918;07265;Baden-Württemberg
Angelburg;, Hess;35719;06464;Hessen
Angelhof I u. II;;67346;;Rheinland-Pfalz
Angelroda;;99338;036207;Thüringen
Anger;b Bad Reichenhall;83454;08656;Bayern
Anger;, Gem Frauenneuharting;83565;08656;Bayern
Angermünde;;16278;03331;Brandenburg
Angern;b Wolmirstedt;39326;039363;Sachsen-Anhalt
Angersdorf;b Halle, Saale;06179;0345;Sachsen-Anhalt
Angl;b Wörnsmühl;83739;08064;Bayern
Anhausen;, Kr Neuwied;56584;02639;Rheinland-Pfalz
Ankermühle;b Heilbad Heiligenstadt;37308;;Thüringen
Ankershagen;;17219;039921;Mecklenburg-Vorpommern
Anklam;;17389;03971;Mecklenburg-Vorpommern
Ankum;;49577;05462;Niedersachsen
Anna Luisenhof;;18239;038207;Mecklenburg-Vorpommern
Annaberg;b Bad Dürkheim;67098;;Rheinland-Pfalz
Annaberg-Buchholz;;09456;03733;Sachsen
Annaburg;, Sachs-Anh;06925;035385;Sachsen-Anhalt
Annahütte;;01994;035754;Brandenburg
Annarode;;06308;034772;Sachsen-Anhalt
Anning;b St Georgen, Chiemgau;83368;08621;Bayern
Annweiler;am Trifels;76855;06346;Rheinland-Pfalz
Anröchte;;59609;02947;Nordrhein-Westfalen
Ansbach;, Mittelfr;91522;0981;Bayern
Anschau;;56729;02656;Rheinland-Pfalz
Antdorf;;82387;08856;Bayern
Antrifttal;;36326;06692;Hessen
Antweiler;b Adenau;53533;02693;Rheinland-Pfalz
Anzing;b München;85646;08121;Bayern
Apelern;;31552;05043;Niedersachsen
Apen;;26689;04489;Niedersachsen
Apenburg;;38486;039001;Sachsen-Anhalt
Apensen;;21641;04167;Niedersachsen
Apfeldorf;;86974;08869;Bayern
Apfelstädt;;99192;036202;Thüringen
Apfeltrach;;87742;08261;Bayern
Apolda;;99510;03644;Thüringen
Appel;, Nordheide;21279;04168;Niedersachsen
Appelbeck;;21279;04165;Niedersachsen
Appeln;;27616;04747;Niedersachsen
Appen;, Kr Pinneberg;25482;04101;Schlewig-Holstein
Appenheim;, Rheinhess;55437;06725;Rheinland-Pfalz
Appenrode;b Ellrich;99768;036331;Thüringen
Appensee;;74597;07967;Baden-Württemberg
Appenweier;;77767;07805;Baden-Württemberg
Apperting;;83376;08667;Bayern
Arbach;;56769;02657;Rheinland-Pfalz
Arberg;;91722;09822;Bayern
Aremberg;, Eifel;53533;02693;Rheinland-Pfalz
Arendsee;b Prenzlau;17291;039855;Brandenburg
Arendsee;(Altmark);39619;039384;Sachsen-Anhalt
Arenrath;;54518;06575;Rheinland-Pfalz
Arensdorf;b Köthen, Anh;06369;034975;Sachsen-Anhalt
Arensdorf;b Fürstenwalde, Spree;15518;033635;Brandenburg
Arenshausen;;37318;036081;Thüringen
Arensnest;;14827;033849;Brandenburg
Arenzhain;;03253;035322;Brandenburg
Aresing;, Oberbay;86561;08252;Bayern
Arft;;56729;02655;Rheinland-Pfalz
Argenbühl;;88260;07566;Baden-Württemberg
Argenschwang;;55595;06706;Rheinland-Pfalz
Argenthal;;55496;06761;Rheinland-Pfalz
Arholzen;;37627;05532;Niedersachsen
Arkebek;;25767;04835;Schlewig-Holstein
Arleting;;83374;08669;Bayern
Arlewatt;;25860;04846;Schlewig-Holstein
Armsheim;, Rheinhess;55288;06734;Rheinland-Pfalz
Armstedt;;24616;04324;Schlewig-Holstein
Armstorf;, Niederelbe;21769;04773;Niedersachsen
Arnbruck;;93471;09945;Bayern
Arneburg;;39596;039321;Sachsen-Anhalt
Arnis;;24399;04642;Schlewig-Holstein
Arnsau;;53547;02638;Rheinland-Pfalz
Arnsbach;b Probstzella;07330;036731;Thüringen
Arnsberg;, Westf;59755;02931;Nordrhein-Westfalen
Arnsberg;, Westf;59757;02931;Nordrhein-Westfalen
Arnsberg;, Westf;59759;02931;Nordrhein-Westfalen
Arnsberg;, Westf;59821;02931;Nordrhein-Westfalen
Arnsberg;, Westf;59823;02931;Nordrhein-Westfalen
Arnschwang;;93473;09977;Bayern
Arnsdorf;b Dresden;01477;035200;Sachsen
Arnsdorf;b Jessen, Elster;06917;03537;Sachsen-Anhalt
Arnsgereuth;;07318;036736;Thüringen
Arnsgrün;b Zeulenroda;07952;037431;Thüringen
Arnshöfen;;56244;02666;Rheinland-Pfalz
Arnsnesta;;04916;03535;Brandenburg
Arnstadt;;99310;03628;Thüringen
Arnstedt;;06333;034785;Sachsen-Anhalt
Arnstein;, Kloster, Lahn;56379;;Rheinland-Pfalz
Arnstein;, Unterfr;97450;09363;Bayern
Arnstorf;;94424;08723;Bayern
Arpsdorf;;24634;04324;Schlewig-Holstein
Arpshagen;;23948;038825;Mecklenburg-Vorpommern
Arrach;, Bayerischer Wald;93474;09943;Bayern
Arras;b Rochlitz;09326;037382;Sachsen
Artamhof;;66849;06371;Rheinland-Pfalz
Artern;;06556;03466;Thüringen
Artlenburg;;21380;04153;Niedersachsen
Arzbach;, Westerw;56337;02603;Rheinland-Pfalz
Arzberg;b Torgau;04886;034222;Sachsen
Arzberg;, Oberfr;95659;09233;Bayern
Arzfeld;;54687;06550;Rheinland-Pfalz
Asbach;, Westerw;53567;02683;Rheinland-Pfalz
Asbach;, Hunsrück;55758;06786;Rheinland-Pfalz
Asbach;, Brenz;89522;07321;Baden-Württemberg
Asbach;b Schmalkalden;98574;03683;Thüringen
Asbach-Bäumenheim;;86663;0906;Bayern
Asbacherhütte;;55758;06786;Rheinland-Pfalz
Asbach-Sickenberg;;37318;;Thüringen
Ascha;;94347;09961;Bayern
Aschaffenburg;;63739;06021;Bayern
Aschaffenburg;;63741;06021;Bayern
Aschaffenburg;;63743;06021;Bayern
Aschara;;99958;03603;Thüringen
Aschau;b Rudolstadt;07426;036738;Thüringen
Aschau;i. Chiemgau;83229;08052;Bayern
Aschau;a. Inn;84544;08638;Bayern
Aschbach;, Pfalz;67753;06304;Rheinland-Pfalz
Aschbach;b Irschenberg;83739;08064;Bayern
Ascheberg;(Holstein);24326;04526;Schlewig-Holstein
Ascheberg;, Westf;59387;02593;Nordrhein-Westfalen
Ascheffel;;24358;04353;Schlewig-Holstein
Aschendorf;, Stadt Papenburg;26871;04962;Niedersachsen
Aschenhausen;;98634;036966;Thüringen
Aschenhütte;, Harz;37412;;Niedersachsen
Aschersleben;, Sachs-Anh;06449;03473;Sachsen-Anhalt
Aschheim;;85609;089;Bayern
Aseleben;;06317;034774;Sachsen-Anhalt
Asendorf;, Nordheide;21271;04183;Niedersachsen
Asendorf;b Bruchhausen-Vilsen;27330;04253;Niedersachsen
Aspach;b Backnang;71546;07191;Baden-Württemberg
Aspach;b Gotha, Thür;99880;03622;Thüringen
Aspelhorn;;21388;04132;Niedersachsen
Aspenstedt;;38822;039427;Sachsen-Anhalt
Asperg;;71679;07141;Baden-Württemberg
Aspisheim;;55459;06727;Rheinland-Pfalz
Assamstadt;;97959;06294;Baden-Württemberg
Asselfingen;;89176;07345;Baden-Württemberg
Aßlar;;35614;06441;Hessen
Aßling;;85617;08092;Bayern
Ast;, Gem Frauenneuharting;83565;;Bayern
Ast;, Oberpf;93449;09972;Bayern
Astert;;57627;02688;Rheinland-Pfalz
Ateritz;;06901;034921;Sachsen-Anhalt
Athenstedt;;38822;039427;Sachsen-Anhalt
Attendorn;;57439;02722;Nordrhein-Westfalen
Attenhausen;, Rhein-Lahn-Kr;56370;02604;Rheinland-Pfalz
Attenhofen;i d Hallertau;84091;08753;Bayern
Attenkirchen;;85395;08168;Bayern
Attenweiler;;88448;07357;Baden-Württemberg
Atterwasch;;03172;035692;Brandenburg
Atting;b Straubing;94348;09429;Bayern
Atzelgift;;57629;02662;Rheinland-Pfalz
Atzendorf;b Staßfurt;39443;039266;Sachsen-Anhalt
Atzlbach;;83364;08666;Bayern
Au;am Rhein;76474;07245;Baden-Württemberg
Au;, Breisgau;79280;0761;Baden-Württemberg
Au;b Neukirchen a Teisenberg;83364;0861;Bayern
Au;a Inn;83546;08073;Bayern
Au;i d Hallertau;84072;08752;Bayern
Aub;, Unterfr;97239;09335;Bayern
Aubstadt;;97633;09761;Bayern
Audenhain;;04838;034244;Sachsen
Auderath;;56766;02676;Rheinland-Pfalz
Aue;, Sachs;08280;03771;Sachsen
Aue a Berg;;07318;03671;Thüringen
Auel;b St Goarshausen;56357;06771;Rheinland-Pfalz
Auen;b Monzingen;55569;06754;Rheinland-Pfalz
Auenwald;;71549;07191;Baden-Württemberg
Auerbach;/Vogtl.;08209;03744;Sachsen
Auerbach;, Erzgeb;09392;03721;Sachsen
Auerbach;, Oberpf;91275;09643;Bayern
Auerbach;, Niederbay;94530;09901;Bayern
Auerberg;, Oberpf;95703;;Bayern
Auerstedt;;99518;036461;Thüringen
Auetal;;31749;05753;Niedersachsen
Aufhausen;, Oberpf;93089;09454;Bayern
Aufkirchen;b Unterschweinbach;82282;08145;Bayern
Aufseß;;91347;09198;Bayern
Auggen;;79424;07631;Baden-Württemberg
Augsburg;, Bay;86150;0821;Bayern
Augsburg;, Bay;86152;0821;Bayern
Augsburg;, Bay;86153;0821;Bayern
Augsburg;, Bay;86154;0821;Bayern
Augsburg;, Bay;86156;0821;Bayern
Augsburg;, Bay;86157;0821;Bayern
Augsburg;, Bay;86159;0821;Bayern
Augsburg;, Bay;86161;0821;Bayern
Augsburg;, Bay;86163;0821;Bayern
Augsburg;, Bay;86165;0821;Bayern
Augsburg;, Bay;86167;0821;Bayern
Augsburg;, Bay;86169;0821;Bayern
Augsburg;, Bay;86179;0821;Bayern
Augsburg;, Bay;86199;0821;Bayern
Augsdorf;;06308;03476;Sachsen-Anhalt
Augustdorf;;32832;05237;Nordrhein-Westfalen
Augustusburg;;09573;037291;Sachsen
Auhagen;;31553;05725;Niedersachsen
Auhausen;, Schwab;86736;09832;Bayern
Auhof;b Crailsheim;74564;07951;Baden-Württemberg
Aukrug;;24613;04873;Schlewig-Holstein
Auleben;;99765;036333;Thüringen
Aulendorf;, Württ;88326;07525;Baden-Württemberg
Aull;;65582;06432;Rheinland-Pfalz
Aulosen;;39615;039395;Sachsen-Anhalt
Auma;;07955;036626;Thüringen
Aumühle;b Hamburg;21521;04104;Schlewig-Holstein
Aura;a d Saale;97717;09704;Bayern
Aura;i. Sinngrund;97773;09356;Bayern
Aurach;, Mittelfr;91589;09804;Bayern
Aurachtal;;91086;09132;Bayern
Aurich;, Ostfriesland;26603;04941;Niedersachsen
Aurich;, Ostfriesland;26605;04941;Niedersachsen
Aurich;, Ostfriesland;26607;04941;Niedersachsen
Ausacker;;24975;04634;Schlewig-Holstein
Ausleben;;39393;039404;Sachsen-Anhalt
Außernzell;;94532;09903;Bayern
Authausen;;04849;034243;Sachsen
Auufer;;25548;04822;Schlewig-Holstein
Auw;bei Prüm;54597;06552;Rheinland-Pfalz
Auw;an der Kyll;54664;06562;Rheinland-Pfalz
Auwies;b St Oswald, Niederbay;94568;;Bayern
Aventoft;;25927;04664;Schlewig-Holstein
Averlak;;25715;04855;Schlewig-Holstein
Axien;;06922;035386;Sachsen-Anhalt
Axstedt;;27729;04748;Niedersachsen
Aying;;85653;08095;Bayern
Ayl;, Saar;54441;06581;Rheinland-Pfalz
Aystetten;;86482;0821;Bayern
Azmannsdorf;;99198;0361;Thüringen
Azmannsdorf;;99198;0361;Thüringen
Baabe;, Ostseebad;18586;038303;Mecklenburg-Vorpommern
Baalberge;;06408;03471;Sachsen-Anhalt
Baar;, Eifel;56729;02656;Rheinland-Pfalz
Baar;(Schwaben);86674;08276;Bayern
Baar-Ebenhausen;;85107;08453;Bayern
Baarz;;19309;038758;Brandenburg
Baasdorf;;06388;03496;Sachsen-Anhalt
Babben;;03246;035324;Brandenburg
Bäbelin;;23992;038429;Mecklenburg-Vorpommern
Baben;;39596;039388;Sachsen-Anhalt
Babenhausen;, Hess;64832;06073;Hessen
Babenhausen;, Schwab;87727;08333;Bayern
Babensham;;83547;08071;Bayern
Babke;;17237;039829;Mecklenburg-Vorpommern
Babst;;23992;038429;Mecklenburg-Vorpommern
Bach;b Wurzen;04828;034383;Sachsen
Bach;b Neukirchen a Teisenberg;83364;08666;Bayern
Bach;b Irschenberg;83739;08025;Bayern
Bach;a d Donau;93090;09403;Bayern
Bacharach;;55422;06743;Rheinland-Pfalz
Bachenberg;;57610;02681;Rheinland-Pfalz
Bachfeld;;96528;036766;Thüringen
Bachhagel;;89429;09077;Bayern
Bächingen;a d Brenz;89431;07325;Bayern
Bachzelten;;84428;08086;Bayern
Backnang;;71522;07191;Baden-Württemberg
Bad Abbach;;93077;09405;Bayern
Bad Aibling;;83043;08061;Bayern
Bad Alexandersbad;;95680;09232;Bayern
Bad Arolsen;;34454;05691;Hessen
Bad Bayersoien;;82435;08845;Bayern
Bad Bederkesa;;27624;04745;Niedersachsen
Bad Bellingen;, Baden;79415;07635;Baden-Württemberg
Bad Bentheim;;48455;05922;Niedersachsen
Bad Bergzabern;;76887;06343;Rheinland-Pfalz
Bad Berka;;99438;036458;Thüringen
Bad Berleburg;;57319;02751;Nordrhein-Westfalen
Bad Berneck;i. Fichtelgebirge;95460;09273;Bayern
Bad Bertrich;;56864;02674;Rheinland-Pfalz
Bad Bevensen;;29549;05821;Niedersachsen
Bad Bibra;;06647;034465;Sachsen-Anhalt
Bad Birnbach;, Rottal;84364;08563;Bayern
Bad Blankenburg;;07422;036741;Thüringen
Bad Bocklet;;97708;09708;Bayern
Bad Bodenteich;;29389;05824;Niedersachsen
Bad Brambach;;08648;037438;Sachsen
Bad Bramstedt;;24576;04192;Schlewig-Holstein
Bad Breisig;;53498;02633;Rheinland-Pfalz
Bad Brückenau;;97769;09741;Bayern
Bad Buchau;;88422;07582;Baden-Württemberg
Bad Camberg;;65520;06434;Hessen
Bad Colberg-Heldburg;;98663;036871;Thüringen
Bad Ditzenbach;;73342;07334;Baden-Württemberg
Bad Doberan;;18209;038203;Mecklenburg-Vorpommern
Bad Driburg;;33014;05253;Nordrhein-Westfalen
Bad Düben;;04849;034243;Sachsen
Bad Dürkheim;;67098;06322;Rheinland-Pfalz
Bad Dürrenberg;;06231;03462;Sachsen-Anhalt
Bad Dürrheim;, Schwarzw;78073;07706;Baden-Württemberg
Bad Eilsen;;31707;05722;Niedersachsen
Bad Elster;;08645;037437;Sachsen
Bad Ems;;56130;02603;Rheinland-Pfalz
Bad Emstal;;34308;05624;Hessen
Bad Endbach;;35080;02776;Hessen
Bad Endorf;, Oberbay;83093;08053;Bayern
Bad Essen;;49152;05472;Niedersachsen
Bad Feilnbach;;83075;08066;Bayern
Bad Frankenhausen;;06567;034671;Thüringen
Bad Freienwalde;(Oder);16259;03344;Brandenburg
Bad Friedrichshall;;74177;07136;Baden-Württemberg
Bad Füssing;;94072;08531;Bayern
Bad Gandersheim;;37581;05382;Niedersachsen
Bad Gottleuba;;01816;035023;Sachsen
Bad Griesbach;i. Rottal;94086;08532;Bayern
Bad Grönenbach;, Allgäu;87730;08334;Bayern
Bad Grund;, Harz;37539;05327;Niedersachsen
Bad Harzburg;;38667;05322;Niedersachsen
Bad Heilbrunn;;83670;08046;Bayern
Bad Herrenalb;;76332;07083;Baden-Württemberg
Bad Hersfeld;;36251;06621;Hessen
Bad Homburg;v d Höhe;61348;06172;Hessen
Bad Homburg;v d Höhe;61350;06172;Hessen
Bad Homburg;v d Höhe;61352;06172;Hessen
Bad Honnef;;53604;02224;Nordrhein-Westfalen
Bad Hönningen;;53557;02635;Rheinland-Pfalz
Bad Iburg;;49186;05403;Niedersachsen
Bad Karlshafen;;34385;05672;Hessen
Bad Kissingen;;97688;0971;Bayern
Bad Kleinen;;23996;038423;Mecklenburg-Vorpommern
Bad Klosterlausnitz;;07639;036601;Thüringen
Bad Kohlgrub;;82433;08845;Bayern
Bad König;;64732;06063;Hessen
Bad Königshofen;i. Grabfeld;97631;09761;Bayern
Bad Kösen;;06628;034463;Sachsen-Anhalt
Bad Köstritz;;07586;036605;Thüringen
Bad Kreuznach;;55543;0671;Rheinland-Pfalz
Bad Kreuznach;;55545;0671;Rheinland-Pfalz
Bad Krozingen;;79189;07633;Baden-Württemberg
Bad Laasphe;;57334;02752;Nordrhein-Westfalen
Bad Laer;;49196;05424;Niedersachsen
Bad Langensalza;;99947;03603;Thüringen
Bad Lauchstädt;;06246;034635;Sachsen-Anhalt
Bad Lausick;;04651;034345;Sachsen
Bad Lauterberg;im Harz;37431;05524;Niedersachsen
Bad Liebenstein;;36448;036961;Thüringen
Bad Liebenwerda;;04924;035341;Brandenburg
Bad Liebenzell;;75378;07052;Baden-Württemberg
Bad Lippspringe;;33175;05252;Nordrhein-Westfalen
Bad Marienberg;(Westerwald);56470;02661;Rheinland-Pfalz
Bad Mergentheim;;97980;07931;Baden-Württemberg
Bad Münder;am Deister;31848;05042;Niedersachsen
Bad Münster-Ebernburg;am Stein;55583;06708;Rheinland-Pfalz
Bad Münstereifel;;53902;02253;Nordrhein-Westfalen
Bad Muskau;;02953;035771;Sachsen
Bad Nauheim;;61231;06032;Hessen
Bad Nenndorf;;31542;05723;Niedersachsen
Bad Neuenahr-Ahrweiler;;53474;02641;Rheinland-Pfalz
Bad Neustadt;a d Saale;97616;09771;Bayern
Bad Oeynhausen;;32545;05731;Nordrhein-Westfalen
Bad Oeynhausen;;32547;05731;Nordrhein-Westfalen
Bad Oeynhausen;;32549;05731;Nordrhein-Westfalen
Bad Oldesloe;;23843;04531;Schlewig-Holstein
Bad Orb;;63619;06052;Hessen
Bad Peterstal-Griesbach;;77740;07806;Baden-Württemberg
Bad Pyrmont;;31812;05281;Niedersachsen
Bad Rappenau;;74906;07264;Baden-Württemberg
Bad Reichenhall;;83435;08651;Bayern
Bad Rippoldsau-Schapbach;;77776;07839;Baden-Württemberg
Bad Rodach;;96476;09564;Bayern
Bad Rothenfelde;;49214;05424;Niedersachsen
Bad Saarow-Pieskow;;15526;033631;Brandenburg
Bad Sachsa;;37441;05523;Niedersachsen
Bad Säckingen;;79713;07761;Baden-Württemberg
Bad Salzdetfurth;;31162;05063;Niedersachsen
Bad Salzschlirf;;36364;06648;Hessen
Bad Salzuflen;;32105;05222;Nordrhein-Westfalen
Bad Salzuflen;;32107;05222;Nordrhein-Westfalen
Bad Salzuflen;;32108;05222;Nordrhein-Westfalen
Bad Salzungen;;36433;03695;Thüringen
Bad Sassendorf;;59505;02927;Nordrhein-Westfalen
Bad Saulgau;;88348;07581;Baden-Württemberg
Bad Schandau;;01814;035022;Sachsen
Bad Schmiedeberg;;06905;034925;Sachsen-Anhalt
Bad Schönborn;;76669;07253;Baden-Württemberg
Bad Schussenried;;88427;07583;Baden-Württemberg
Bad Schwalbach;;65307;06124;Hessen
Bad Schwartau;;23611;0451;Schlewig-Holstein
Bad Segeberg;;23795;04551;Schlewig-Holstein
Bad Sobernheim;;55566;06751;Rheinland-Pfalz
Bad Soden;am Taunus;65812;06196;Hessen
Bad Soden-Salmünster;;63628;06056;Hessen
Bad Sooden-Allendorf;;37242;05652;Hessen
Bad Staffelstein;;96231;09573;Bayern
Bad Steben;;95138;09288;Bayern
Bad Suderode;;06507;039485;Sachsen-Anhalt
Bad Sulza;;99518;036461;Thüringen
Bad Sülze;;18334;038229;Mecklenburg-Vorpommern
Bad Teinach-Zavelstein;;75385;07053;Baden-Württemberg
Bad Tennstedt;;99955;036041;Thüringen
Bad Tölz;;83646;08041;Bayern
Bad Überkingen;;73337;07331;Baden-Württemberg
Bad Urach;;72574;07125;Baden-Württemberg
Bad Vilbel;;61118;06101;Hessen
Bad Waldsee;;88339;07524;Baden-Württemberg
Bad Wiessee;;83707;08022;Bayern
Bad Wildbad;im Schwarzwald;75323;07081;Baden-Württemberg
Bad Wildungen;;34537;05621;Hessen
Bad Wilsnack;;19336;038791;Brandenburg
Bad Wimpfen;;74206;07063;Baden-Württemberg
Bad Windsheim;;91438;09841;Bayern
Bad Wörishofen;;86825;08247;Bayern
Bad Wünnenberg;;33181;02953;Nordrhein-Westfalen
Bad Wurzach;;88410;07564;Baden-Württemberg
Bad Zwesten;;34596;05626;Hessen
Bad Zwischenahn;;26160;04403;Niedersachsen
Badbergen;;49635;05433;Niedersachsen
Baddeckenstedt;;38271;05345;Niedersachsen
Badeborn;;06493;039483;Sachsen-Anhalt
Badekow;;19258;038842;Mecklenburg-Vorpommern
Badel;;39624;039009;Sachsen-Anhalt
Badem;;54657;06563;Rheinland-Pfalz
Baden-Baden;;76530;07221;Baden-Württemberg
Baden-Baden;;76532;07221;Baden-Württemberg
Baden-Baden;;76534;07221;Baden-Württemberg
Badendorf;, Holst;23619;0451;Schlewig-Holstein
Badenhard;;56291;06746;Rheinland-Pfalz
Badenhausen;, Harz;37534;05522;Niedersachsen
Badenheim;;55576;06701;Rheinland-Pfalz
Badenmühle;b Bad Doberan;18209;038203;Mecklenburg-Vorpommern
Badenweiler;;79410;07632;Baden-Württemberg
Badersleben;;38836;039422;Sachsen-Anhalt
Badingen;b Gransee;16775;03307;Brandenburg
Badingen;b Stendal;39579;039324;Sachsen-Anhalt
Badisch Schöllenbach;;64754;;Baden-Württemberg
Badow;;19209;038874;Mecklenburg-Vorpommern
Badra;;99706;03632;Thüringen
Baek;;19348;038782;Brandenburg
Baesweiler;;52499;02401;Nordrhein-Westfalen
Bagemühl;;17326;039742;Brandenburg
Bagenz;;03130;035697;Brandenburg
Bagow;;14778;033838;Brandenburg
Bahlenhüschen;;19089;03861;Mecklenburg-Vorpommern
Bahlingen;am Kaiserstuhl;79353;07663;Baden-Württemberg
Bahnhof Zimmernsupra-Töttelstädt;;99100;;Thüringen
Bahnhof Zimmernsupra-Töttelstädt;;99100;;Thüringen
Bahnitz;;14715;033877;Brandenburg
Bahnsdorf;, NL;01983;035751;Brandenburg
Bahnsdorf;, Elster;04895;035365;Brandenburg
Bahnstock;;88441;07582;Baden-Württemberg
Bahratal;;01816;035023;Sachsen
Bahrdorf;, Niedersachs;38459;05358;Niedersachsen
Bahren;, NL;03249;035323;Brandenburg
Bahrenborstel;;27245;05763;Niedersachsen
Bahrendorf;, Börde;39171;039205;Sachsen-Anhalt
Bahrenfleth;;25569;04824;Schlewig-Holstein
Bahrenhof;, Holst;23845;04550;Schlewig-Holstein
Bahrenhorst;;18211;038203;Mecklenburg-Vorpommern
Bahretal;;01819;035025;Sachsen
Bahro;;15898;033656;Brandenburg
Baienfurt;;88255;0751;Baden-Württemberg
Baierbach;, Niederbay;84171;08705;Bayern
Baierbrunn;, Isartal;82065;089;Bayern
Baierhof;b Süßen;73079;;Baden-Württemberg
Baiern;, Kr Ebersberg, Oberbay;85625;08093;Bayern
Baiersbronn;;72270;07442;Baden-Württemberg
Baiersdorf;, Mittelfr;91083;09133;Bayern
Baindt;, Württ;88255;07502;Baden-Württemberg
Baisweil;;87650;08340;Bayern
Baitz;;14806;033841;Brandenburg
Bäk;b Ratzeburg;23909;04541;Schlewig-Holstein
Bakum;, Kr Vechta;49456;04446;Niedersachsen
Bälau;;23881;04542;Schlewig-Holstein
Balderschwang;;87538;08328;Bayern
Baldham;;85598;08106;Bayern
Baldringen;;54314;06587;Rheinland-Pfalz
Balduinstein;;65558;06432;Rheinland-Pfalz
Balesfeld;;54597;06553;Rheinland-Pfalz
Balge;;31609;05022;Niedersachsen
Balgheim;, Württ;78582;07424;Baden-Württemberg
Balgstädt;;06632;034464;Sachsen-Anhalt
Balingen;;72336;07433;Baden-Württemberg
Balje;, Kr Stade;21730;04753;Niedersachsen
Balkenkoppel;;18320;038225;Mecklenburg-Vorpommern
Ballendorf;, Württ;89177;07340;Baden-Württemberg
Ballenstedt;;06493;039483;Sachsen-Anhalt
Ballerstedt;;39606;039328;Sachsen-Anhalt
Ballhausen;b Bad Tennstedt;99955;036041;Thüringen
Ballrechten-Dottingen;;79282;07634;Baden-Württemberg
Ballstädt;;99869;036255;Thüringen
Ballstedt;;99439;036452;Thüringen
Balow;;19300;038752;Mecklenburg-Vorpommern
Bälow;;19322;038791;Brandenburg
Baltmannsweiler;;73666;07153;Baden-Württemberg
Baltrum;;26579;04939;Niedersachsen
Balve;;58802;02375;Nordrhein-Westfalen
Balzhausen;b Krumbach, Schwaben;86483;08281;Bayern
Balzheim;;88481;07347;Baden-Württemberg
Bamberg;;96047;0951;Bayern
Bamberg;;96049;0951;Bayern
Bamberg;;96050;0951;Bayern
Bamberg;;96052;0951;Bayern
Bamme;;14715;03385;Brandenburg
Bammental;;69245;06223;Baden-Württemberg
Bandau;;38486;039000;Sachsen-Anhalt
Bandekow;b Lübtheen;19249;038855;Mecklenburg-Vorpommern
Bandekow;b Neuhaus, Elbe;19273;038844;Mecklenburg-Vorpommern
Bandelin;;17506;038353;Mecklenburg-Vorpommern
Bandenitz;;19230;038859;Mecklenburg-Vorpommern
Bandow;;18258;03844;Mecklenburg-Vorpommern
Bann;, Pfalz;66851;06371;Rheinland-Pfalz
Bannberscheid;;56424;02602;Rheinland-Pfalz
Bannesdorf;auf Fehmarn;23769;04372;Schlewig-Holstein
Bannewitz;;01728;0351;Sachsen
Banteln;;31029;05182;Niedersachsen
Bantikow;;16868;033979;Brandenburg
Bantin;;19246;038851;Mecklenburg-Vorpommern
Banzendorf;;16835;033933;Brandenburg
Banzenhof;, Gem Reiff;54619;06559;Rheinland-Pfalz
Banzin;;19260;038848;Mecklenburg-Vorpommern
Banzkow;;19079;03868;Mecklenburg-Vorpommern
Barbelroth;;76889;06343;Rheinland-Pfalz
Barbing;;93092;09401;Bayern
Barby;;39249;039298;Sachsen-Anhalt
Barchfeld;, Werra;36456;036961;Thüringen
Bardenitz;;14913;033748;Brandenburg
Bardowick;;21357;04131;Niedersachsen
Bärenbach;b Simmern, Hunsrück;55483;06543;Rheinland-Pfalz
Bärenbach;b Idar-Oberstein;55758;06784;Rheinland-Pfalz
Bärenbach;, Württ;73655;;Baden-Württemberg
Bärenbrunnerhof;;66996;06391;Rheinland-Pfalz
Bärenbrunnermühle;;66996;06391;Rheinland-Pfalz
Barenburg;b Sulingen;27245;04273;Niedersachsen
Barendorf;, Kr Lüneburg;21397;04137;Niedersachsen
Barendorf;b Grevesmühlen;23936;03841;Mecklenburg-Vorpommern
Bärenhof;;73529;07165;Baden-Württemberg
Bärenklau;, NL;03172;035691;Brandenburg
Bärenklau;b Velten;16727;033052;Brandenburg
Bärenstein;, Osterzgeb;01768;035054;Sachsen
Bärenstein;b Annaberg-Buchholz;09471;037347;Sachsen
Bärenthal;, Hohenz;78580;07466;Baden-Württemberg
Barenthin;;16866;033972;Brandenburg
Bargenstedt;;25704;04832;Schlewig-Holstein
Bargfeld-Stegen;;23863;04532;Schlewig-Holstein
Bargholz;;25563;04822;Schlewig-Holstein
Bargischow;;17398;03971;Mecklenburg-Vorpommern
Bargstall;;24806;04335;Schlewig-Holstein
Bargstedt;, Kr Stade;21698;04164;Niedersachsen
Bargstedt;, Holst;24793;04392;Schlewig-Holstein
Bargteheide;;22941;04532;Schlewig-Holstein
Bargum;;25842;04672;Schlewig-Holstein
Bark;b Bad Segeberg;23826;04558;Schlewig-Holstein
Barkelsby;;24360;04358;Schlewig-Holstein
Barkenholm;, Holst;25791;04836;Schlewig-Holstein
Barkhorn;b Schleswig;24863;04627;Schlewig-Holstein
Barkow;b Lübz;19395;038735;Mecklenburg-Vorpommern
Barleben;;39179;039203;Sachsen-Anhalt
Barlt;;25719;04857;Schlewig-Holstein
Barmissen;;24245;04302;Schlewig-Holstein
Barmstedt;;25355;04123;Schlewig-Holstein
Bärnau;, Oberpf;95671;09635;Bayern
Barneberg;;39393;039402;Sachsen-Anhalt
Barnekow;;23968;03841;Mecklenburg-Vorpommern
Barnewitz;;14715;033876;Brandenburg
Barnin;;19089;03863;Mecklenburg-Vorpommern
Barnitz;, Trave;23858;04533;Schlewig-Holstein
Bärnsdorf;;01471;035207;Sachsen
Barnstädt;;06268;034771;Sachsen-Anhalt
Barnstedt;, Kr Lüneburg;21406;0451;Niedersachsen
Barnstorf;, Kr Diepholz;49406;05442;Niedersachsen
Barntrup;;32683;05263;Nordrhein-Westfalen
Barsbek;;24217;04344;Schlewig-Holstein
Barsbüttel;;22885;040;Schlewig-Holstein
Barsdorf;;16775;033087;Brandenburg
Barsikow;;16845;03391;Brandenburg
Barsinghausen;;30890;05105;Niedersachsen
Barßel;;26676;04499;Niedersachsen
Bartelshagen II;b. Barth;18314;038227;Mecklenburg-Vorpommern
Bartenshagen;;18209;038203;Mecklenburg-Vorpommern
Bartensleben;;39343;039050;Sachsen-Anhalt
Barth;;18356;038231;Mecklenburg-Vorpommern
Bartholomä;;73566;07173;Baden-Württemberg
Bartow;;17089;039991;Mecklenburg-Vorpommern
Barum;, Kr Lüneburg;21357;04133;Niedersachsen
Barum;b Bad Bevensen;29576;05806;Niedersachsen
Baruth;/ Mark;15837;033704;Brandenburg
Barver;;49453;05448;Niedersachsen
Bärwalde;b Jüterbog;14913;033744;Brandenburg
Barwedel;;38476;05366;Niedersachsen
Barweiler;;53534;02691;Rheinland-Pfalz
Bärweiler;;55606;06751;Rheinland-Pfalz
Barzig;;01994;035329;Brandenburg
Basberg;, Eifel;54578;06593;Rheinland-Pfalz
Basdahl;;27432;04766;Niedersachsen
Basdorf;b Berlin;16352;033397;Brandenburg
Basdorf;b Neuruppin;16818;033929;Brandenburg
Basedow;b Teterow;17139;03998;Mecklenburg-Vorpommern
Basedow;, Kr Hzgt Lauenb;21483;04153;Schlewig-Holstein
Bassenheim;;56220;02625;Rheinland-Pfalz
Bassum;;27211;04241;Niedersachsen
Bastheim;;97654;09773;Bayern
Basthorst;, Kr Hzgt Lauenb;21493;04159;Schlewig-Holstein
Bastorf;;18230;038293;Mecklenburg-Vorpommern
Battaune;;04838;034244;Sachsen
Battenberg;(Eder);35088;06452;Hessen
Battenberg;(Pfalz);67271;06359;Rheinland-Pfalz
Battgendorf;;99625;03635;Thüringen
Battin;b Jessen, Elster;06917;03537;Sachsen-Anhalt
Battinsthal;;17328;039746;Mecklenburg-Vorpommern
Battweiler;;66484;06337;Rheinland-Pfalz
Batzlow;;15377;033437;Brandenburg
Bauda;;01561;03522;Sachsen
Baudenbach;;91460;09164;Bayern
Bauerbach;b Meiningen;98617;036945;Thüringen
Bauerkuhl;;19372;038721;Mecklenburg-Vorpommern
Bauler;b Barweiler;53534;02692;Rheinland-Pfalz
Bauler;b Neuerburg;54673;06524;Rheinland-Pfalz
Baumersroda;;06632;034632;Sachsen-Anhalt
Baumgarten;b Gransee;16775;033083;Brandenburg
Baumgarten;b Bützow;18246;038462;Mecklenburg-Vorpommern
Baumholder;;55774;06783;Rheinland-Pfalz
Baunach;;96148;09544;Bayern
Baunatal;;34225;0561;Hessen
Bausendorf;;54538;06532;Rheinland-Pfalz
Baustert;;54636;06527;Rheinland-Pfalz
Bautzen;, Sachs;02625;03591;Sachsen
Bawinkel;;49844;05963;Niedersachsen
Bayerbach;b. Ergoldsbach;84092;08774;Bayern
Bayerbach;, Rott;94137;08532;Bayern
Bayerfeld-Steckweiler;;67808;06362;Rheinland-Pfalz
Bayerisch Eisenstein;;94252;09925;Bayern
Bayerisch Gmain;;83457;08651;Bayern
Bayreuth;;95444;0921;Bayern
Bayreuth;;95445;0921;Bayern
Bayreuth;;95447;0921;Bayern
Bayreuth;;95448;0921;Bayern
Bayrischzell;;83735;08023;Bayern
Bebendorf;;37308;036082;Thüringen
Bebensee;;23816;04552;Schlewig-Holstein
Beberstedt;;99976;036023;Thüringen
Bebertal;;39343;039062;Sachsen-Anhalt
Beblinstetten;;87770;;Bayern
Bebra;;36179;06622;Hessen
Becheln;;56132;02603;Rheinland-Pfalz
Bechelsdorf;;23923;038828;Mecklenburg-Vorpommern
Bechenheim;;55234;06736;Rheinland-Pfalz
Becherbach;b Kirn, Nahe;55608;06757;Rheinland-Pfalz
Becherbach;, Pfalz;67827;06364;Rheinland-Pfalz
Bechhofen;, Pfalz;66894;06372;Rheinland-Pfalz
Bechhofen;a d Heide;91572;09822;Bayern
Bechstedt;;07426;036730;Thüringen
Bechstedtstraß;;99428;03643;Thüringen
Bechtheim;, Rheinhess;67595;06242;Rheinland-Pfalz
Bechtolsheim;;55234;06733;Rheinland-Pfalz
Bechtsrieth;;92699;09651;Bayern
Beckdorf;;21643;04167;Niedersachsen
Beckedorf;b Stadthagen;31699;05725;Niedersachsen
Beckedorfer Schacht;;31698;;Niedersachsen
Beckeln;;27243;04244;Niedersachsen
Beckendorf;b Oschersleben;39393;039404;Sachsen-Anhalt
Beckenhof;b Pirmasens;66955;;Rheinland-Pfalz
Beckenkrug;;17194;039934;Mecklenburg-Vorpommern
Beckentin;;19300;038756;Mecklenburg-Vorpommern
Beckingen;;66701;06835;Saarland
Beckum;, Westf;59269;02521;Nordrhein-Westfalen
Bedburg;, Erft;50181;02272;Nordrhein-Westfalen
Bedburg-Hau;;47551;02821;Nordrhein-Westfalen
Bedesbach;;66885;06381;Rheinland-Pfalz
Beedenbostel;;29355;05145;Niedersachsen
Beelen;;48361;02586;Nordrhein-Westfalen
Beelitz;, Mark;14547;033204;Brandenburg
Beelitz;b Stendal;39596;039321;Sachsen-Anhalt
Beendorf;b Haldensleben;39343;039050;Sachsen-Anhalt
Beenz;b Neustrelitz;17258;039888;Brandenburg
Beerbaum;;16259;033451;Brandenburg
Beerfelde;;15518;033637;Brandenburg
Beerfelden;, Odenw;64743;06068;Hessen
Beesdau;;15926;03544;Brandenburg
Beesenlaublingen;;06425;034692;Sachsen-Anhalt
Beesenstedt;;06198;034607;Sachsen-Anhalt
Beeskow;;15848;03366;Brandenburg
Beesten;;49832;05905;Niedersachsen
Beestland;;17111;03998;Mecklenburg-Vorpommern
Beetz;;16766;033055;Brandenburg
Beetzendorf;;38489;039000;Sachsen-Anhalt
Beggerow;;17111;03998;Mecklenburg-Vorpommern
Behlendorf;;23919;04544;Schlewig-Holstein
Behlow;;15868;033671;Brandenburg
Behnkendorf;;18510;038328;Mecklenburg-Vorpommern
Behnsdorf;;39356;039055;Sachsen-Anhalt
Behrendorf;b Husum, Nordsee;25850;04843;Schlewig-Holstein
Behrendorf;, Altmark;39606;039393;Sachsen-Anhalt
Behrenhoff;;17498;038356;Mecklenburg-Vorpommern
Behren-Lübchin;;17179;039972;Mecklenburg-Vorpommern
Behrensdorf;b Beeskow;15864;033677;Brandenburg
Behrensdorf;b Lütjenburg;24321;04381;Schlewig-Holstein
Behringen;b Arnstadt;99326;03629;Thüringen
Behringen;b Bad Langensalza;99947;036254;Thüringen
Behringer Schenke;;99326;03629;Thüringen
Behrungen;;98631;036944;Thüringen
Bei den Tongruben;;63543;;Hessen
Beicha;;04720;034325;Sachsen
Beichlingen;;99625;03635;Thüringen
Beidendorf;b Bad Kleinen;23996;038424;Mecklenburg-Vorpommern
Beidenfleth;;25573;04829;Schlewig-Holstein
Beierfeld;, Erzgeb;08340;03774;Sachsen
Beiersdorf;b Löbau;02736;035872;Sachsen
Beiersdorf;b Falkenberg, Elster;04924;035365;Brandenburg
Beiersdorf;b Bad Freienwalde;16259;033451;Brandenburg
Beierstedt;;38382;05354;Niedersachsen
Beilingen;;54662;06562;Rheinland-Pfalz
Beilngries;;92339;08461;Bayern
Beilrode;;04886;03421;Sachsen
Beilstein;, Mosel;56814;02673;Rheinland-Pfalz
Beilstein;, Württ;71717;07062;Baden-Württemberg
Beimerstetten;;89179;07348;Baden-Württemberg
Beindersheim;;67259;06233;Rheinland-Pfalz
Beinerstadt;;98660;036873;Thüringen
Beinhausen;;54552;02692;Rheinland-Pfalz
Bekdorf;b Wilster;25554;04823;Schlewig-Holstein
Bekmünde;;25524;04821;Schlewig-Holstein
Bekond;;54340;06502;Rheinland-Pfalz
Belau;b Wankendorf;24601;04323;Schlewig-Holstein
Beldorf;;25557;04872;Schlewig-Holstein
Belg;;56858;06543;Rheinland-Pfalz
Belgern;b Torgau;04874;034224;Sachsen
Belgershain;;04683;034347;Sachsen
Belgweiler;;55469;06761;Rheinland-Pfalz
Bell;(Hunsrück);56288;06762;Rheinland-Pfalz
Bell;, Eifel;56745;02652;Rheinland-Pfalz
Belleben;;06425;034692;Sachsen-Anhalt
Bellenberg;, Schwab;89287;07306;Bayern
Bellers;, Hess;36208;06678;Hessen
Bellevue;b Bönnigheim;74357;;Baden-Württemberg
Bellheim;;76756;07272;Rheinland-Pfalz
Bellin;b Güstrow;18292;038458;Mecklenburg-Vorpommern
Belling;;17309;039741;Mecklenburg-Vorpommern
Bellingen;b Stendal;39579;039365;Sachsen-Anhalt
Bellingen;, Westerw;56459;02663;Rheinland-Pfalz
Bellstedt;;99713;036020;Thüringen
Belm;;49191;05406;Niedersachsen
Below;b Röbel;17209;039925;Mecklenburg-Vorpommern
Belrieth;;98617;036949;Thüringen
Belsch;;19230;038854;Mecklenburg-Vorpommern
Belsdorf;b Haldensleben;39356;039055;Sachsen-Anhalt
Beltheim;;56290;06762;Rheinland-Pfalz
Belum;;21785;04752;Niedersachsen
Belzhof;;73494;07967;Baden-Württemberg
Belzig;;14806;033841;Brandenburg
Bembermühle;;56203;02624;Rheinland-Pfalz
Bembsmühle;;56170;;Rheinland-Pfalz
Bembsmühle;;56170;;Rheinland-Pfalz
Bempflingen;;72658;07123;Baden-Württemberg
Benckendorf;;23942;038826;Mecklenburg-Vorpommern
Bendeleben;;99706;034671;Thüringen
Bendelin;;19339;038787;Brandenburg
Bendestorf;;21227;0451;Niedersachsen
Bendfeld;;24217;04344;Schlewig-Holstein
Bendhof;;19205;03886;Mecklenburg-Vorpommern
Bendorf;, Holst;25557;04827;Schlewig-Holstein
Bendorf;, Rhein;56170;02622;Rheinland-Pfalz
Benediktbeuern;;83671;08857;Bayern
Bengel;, Mosel;54538;06532;Rheinland-Pfalz
Benitz;b Schwaan;18258;03844;Mecklenburg-Vorpommern
Benjental;, Forsthaus;67435;;Rheinland-Pfalz
Benken;b Belzig;14806;033849;Brandenburg
Benkendorf;;29416;039032;Sachsen-Anhalt
Benkendorf b Salzmünde;;06179;034609;Sachsen-Anhalt
Benndorf;b Klostermansfeld;06308;034772;Sachsen-Anhalt
Benneckenstein;(Harz);38877;039457;Sachsen-Anhalt
Bennewitz;b Wurzen;04828;03425;Sachsen
Bennhausen;;67808;06357;Rheinland-Pfalz
Bennin;;19260;038843;Mecklenburg-Vorpommern
Benningen;am Neckar;71726;07144;Baden-Württemberg
Benningen;b Memmingen;87734;08331;Bayern
Bennstedt;;06179;0345;Sachsen-Anhalt
Bennungen;;06536;034651;Sachsen-Anhalt
Benratherhof;;54314;06587;Rheinland-Pfalz
Bensdorf;;14789;033839;Brandenburg
Benshausen;;98554;036843;Thüringen
Bensheim;;64625;06251;Hessen
Bentwisch;b Rostock;18182;0381;Mecklenburg-Vorpommern
Bentwisch;b Wittenberge;19322;03877;Brandenburg
Bentzin;;17129;039997;Mecklenburg-Vorpommern
Benz;, Usedom;17429;038379;Mecklenburg-Vorpommern
Benz;b Lübtheen;19249;038759;Mecklenburg-Vorpommern
Benz;b Wismar, Meckl;23970;038426;Mecklenburg-Vorpommern
Benzin;b Rehna;19217;03886;Mecklenburg-Vorpommern
Benzweiler;;55494;06766;Rheinland-Pfalz
Beratzhausen;;93176;09493;Bayern
Berbisdorf;b Dresden;01471;035208;Sachsen
Berching;;92334;08462;Bayern
Berchtesgaden;;83471;08652;Bayern
Bereborn;;56769;02692;Rheinland-Pfalz
Berenbach;;56766;02676;Rheinland-Pfalz
Berenbrock;b Haldensleben;39638;039051;Sachsen-Anhalt
Berendshagen;;18239;038295;Mecklenburg-Vorpommern
Berg;, Kr Ahrweiler;53505;02643;Rheinland-Pfalz
Berg;, Taunus;56357;06772;Rheinland-Pfalz
Berg;(Pfalz);76768;07273;Rheinland-Pfalz
Berg;, Starnberger See;82335;08151;Bayern
Berg;b Lauter, Oberbay;83362;08666;Bayern
Berg;, Gem Gars a Inn;83546;08073;Bayern
Berg;, Kr Ravensburg;88276;0751;Baden-Württemberg
Berg;b Neumarkt, Oberpf;92348;09189;Bayern
Berg;, Oberfr;95180;09293;Bayern
Berg im Gau;;86562;08433;Bayern
Berga;b Roßla;06536;034651;Sachsen-Anhalt
Berga;/Elster;07980;036623;Thüringen
Bergatreute;;88368;07527;Baden-Württemberg
Berge;b Nauen;14641;03321;Brandenburg
Berge;b Perleberg;19348;038785;Brandenburg
Berge;b Gardelegen;39638;03907;Sachsen-Anhalt
Berge;b Quakenbrück;49626;05435;Niedersachsen
Bergen;, NL;03246;035324;Brandenburg
Bergen;b Auerbach, Vogtl;08239;037463;Sachsen
Bergen;auf Rügen;18528;03838;Mecklenburg-Vorpommern
Bergen;, Kr Celle;29303;05051;Niedersachsen
Bergen;(Dumme);29468;05845;Niedersachsen
Bergen;b Kirn, Nahe;55608;06752;Rheinland-Pfalz
Bergen;, Chiemgau;83346;08662;Bayern
Bergen;, Mittelfr;91790;09148;Bayern
Bergenhausen;, Hunsrück;55469;06766;Rheinland-Pfalz
Bergenhusen;;24861;04885;Schlewig-Holstein
Bergerdamm;;14641;03321;Brandenburg
Bergerhof;b Holzappel, Rhein-Lahn-Kr;56379;06439;Rheinland-Pfalz
Bergewöhrden;;25779;04836;Schlewig-Holstein
Bergfeld;, Niedersachs;38467;05368;Niedersachsen
Bergfelde;;16562;03303;Brandenburg
Berghaupten;;77791;07803;Baden-Württemberg
Berghausen;, Rhein-Lahn-Kr;56368;06486;Rheinland-Pfalz
Bergheim;, Erft;50126;02271;Nordrhein-Westfalen
Bergheim;, Erft;50127;02271;Nordrhein-Westfalen
Bergheim;, Erft;50129;02271;Nordrhein-Westfalen
Bergheim;b Neuburg a d Donau;86673;08431;Bayern
Berghof;b Wernau, Neckar;73249;;Baden-Württemberg
Bergholz;b Belzig;14806;033841;Brandenburg
Bergholz;b Pasewalk;17321;039754;Mecklenburg-Vorpommern
Bergholz-Rehbrücke;;14558;033200;Brandenburg
Berghülen;;89180;07344;Baden-Württemberg
Bergisch Gladbach;;51427;02202;Nordrhein-Westfalen
Bergisch Gladbach;;51429;02202;Nordrhein-Westfalen
Bergisch Gladbach;;51465;02202;Nordrhein-Westfalen
Bergisch Gladbach;;51467;02202;Nordrhein-Westfalen
Bergisch Gladbach;;51469;02202;Nordrhein-Westfalen
Bergisdorf;;06712;03441;Sachsen-Anhalt
Bergkamen;;59192;02307;Nordrhein-Westfalen
Bergkirchen;, Kr Dachau;85232;08131;Bayern
Berglage;;49459;05447;Niedersachsen
Berglangenbach;;55776;06789;Rheinland-Pfalz
Berglen;, Württ;73663;07195;Baden-Württemberg
Berglern;;85459;08762;Bayern
Berglicht;;54426;06504;Rheinland-Pfalz
Bergmühle;b Rockenhausen;67823;06362;Rheinland-Pfalz
Bergneustadt;;51702;02261;Nordrhein-Westfalen
Bergrade Dorf;;19374;038728;Mecklenburg-Vorpommern
Bergrheinfeld;;97493;09721;Bayern
Bergschäferei;;15377;033433;Brandenburg
Bergsdorf;b Gransee;16775;033088;Brandenburg
Bergtheim;, Unterfr;97241;09367;Bayern
Bergweiler;b Wittlich;54518;06571;Rheinland-Pfalz
Bergwitz;;06773;034928;Sachsen-Anhalt
Bergzow;;39307;039349;Sachsen-Anhalt
Beringstedt;;25575;04874;Schlewig-Holstein
Berka;/Werra;99837;036922;Thüringen
Berka v d Hainich;;99826;036924;Thüringen
Berkach;b Meiningen;98631;036944;Thüringen
Berkatal;;37297;05657;Hessen
Berkau;, Altmark;39624;039089;Sachsen-Anhalt
Berkenbrück;b Fürstenwalde, Spree;15518;033634;Brandenburg
Berkenthin;;23919;04544;Schlewig-Holstein
Berkheim;, Kr Biberach an der Riß;88450;08395;Baden-Württemberg
Berkholz-Meyenburg;;16306;03332;Brandenburg
Berkoth;;54673;06564;Rheinland-Pfalz
Berlin;;10115;030;Berlin
Berlin;;10117;030;Berlin
Berlin;;10119;030;Berlin
Berlin;;10178;030;Berlin
Berlin;;10179;030;Berlin
Berlin;;10243;030;Berlin
Berlin;;10245;030;Berlin
Berlin;;10247;030;Berlin
Berlin;;10249;030;Berlin
Berlin;;10315;030;Berlin
Berlin;;10317;030;Berlin
Berlin;;10318;030;Berlin
Berlin;;10319;030;Berlin
Berlin;;10365;030;Berlin
Berlin;;10367;030;Berlin
Berlin;;10369;030;Berlin
Berlin;;10405;030;Berlin
Berlin;;10407;030;Berlin
Berlin;;10409;030;Berlin
Berlin;;10435;030;Berlin
Berlin;;10437;030;Berlin
Berlin;;10439;030;Berlin
Berlin;;10551;030;Berlin
Berlin;;10553;030;Berlin
Berlin;;10555;030;Berlin
Berlin;;10557;030;Berlin
Berlin;;10559;030;Berlin
Berlin;;10585;030;Berlin
Berlin;;10587;030;Berlin
Berlin;;10589;030;Berlin
Berlin;;10623;030;Berlin
Berlin;;10625;030;Berlin
Berlin;;10627;030;Berlin
Berlin;;10629;030;Berlin
Berlin;;10707;030;Berlin
Berlin;;10709;030;Berlin
Berlin;;10711;030;Berlin
Berlin;;10713;030;Berlin
Berlin;;10715;030;Berlin
Berlin;;10717;030;Berlin
Berlin;;10719;030;Berlin
Berlin;;10777;030;Berlin
Berlin;;10779;030;Berlin
Berlin;;10781;030;Berlin
Berlin;;10783;030;Berlin
Berlin;;10785;030;Berlin
Berlin;;10787;030;Berlin
Berlin;;10789;030;Berlin
Berlin;;10823;030;Berlin
Berlin;;10825;030;Berlin
Berlin;;10827;030;Berlin
Berlin;;10829;030;Berlin
Berlin;;10961;030;Berlin
Berlin;;10963;030;Berlin
Berlin;;10965;030;Berlin
Berlin;;10967;030;Berlin
Berlin;;10969;030;Berlin
Berlin;;10997;030;Berlin
Berlin;;10999;030;Berlin
Berlin;;12043;030;Berlin
Berlin;;12045;030;Berlin
Berlin;;12047;030;Berlin
Berlin;;12049;030;Berlin
Berlin;;12051;030;Berlin
Berlin;;12053;030;Berlin
Berlin;;12055;030;Berlin
Berlin;;12057;030;Berlin
Berlin;;12059;030;Berlin
Berlin;;12099;030;Berlin
Berlin;;12101;030;Berlin
Berlin;;12103;030;Berlin
Berlin;;12105;030;Berlin
Berlin;;12107;030;Berlin
Berlin;;12109;030;Berlin
Berlin;;12157;030;Berlin
Berlin;;12159;030;Berlin
Berlin;;12161;030;Berlin
Berlin;;12163;030;Berlin
Berlin;;12165;030;Berlin
Berlin;;12167;030;Berlin
Berlin;;12169;030;Berlin
Berlin;;12203;030;Berlin
Berlin;;12205;030;Berlin
Berlin;;12207;030;Berlin
Berlin;;12209;030;Berlin
Berlin;;12247;030;Berlin
Berlin;;12249;030;Berlin
Berlin;;12277;030;Berlin
Berlin;;12279;030;Berlin
Berlin;;12305;030;Berlin
Berlin;;12307;030;Berlin
Berlin;;12309;030;Berlin
Berlin;;12347;030;Berlin
Berlin;;12349;030;Berlin
Berlin;;12351;030;Berlin
Berlin;;12353;030;Berlin
Berlin;;12355;030;Berlin
Berlin;;12357;030;Berlin
Berlin;;12359;030;Berlin
Berlin;;12435;030;Berlin
Berlin;;12437;030;Berlin
Berlin;;12439;030;Berlin
Berlin;;12459;030;Berlin
Berlin;;12487;030;Berlin
Berlin;;12489;030;Berlin
Berlin;;12524;030;Berlin
Berlin;;12526;030;Berlin
Berlin;;12527;030;Berlin
Berlin;;12555;030;Berlin
Berlin;;12557;030;Berlin
Berlin;;12559;030;Berlin
Berlin;;12587;030;Berlin
Berlin;;12589;030;Berlin
Berlin;;12619;030;Berlin
Berlin;;12621;030;Berlin
Berlin;;12623;030;Berlin
Berlin;;12627;030;Berlin
Berlin;;12629;030;Berlin
Berlin;;12679;030;Berlin
Berlin;;12681;030;Berlin
Berlin;;12683;030;Berlin
Berlin;;12685;030;Berlin
Berlin;;12687;030;Berlin
Berlin;;12689;030;Berlin
Berlin;;13051;030;Berlin
Berlin;;13053;030;Berlin
Berlin;;13055;030;Berlin
Berlin;;13057;030;Berlin
Berlin;;13059;030;Berlin
Berlin;;13086;030;Berlin
Berlin;;13088;030;Berlin
Berlin;;13089;030;Berlin
Berlin;;13125;030;Berlin
Berlin;;13127;030;Berlin
Berlin;;13129;030;Berlin
Berlin;;13156;030;Berlin
Berlin;;13158;030;Berlin
Berlin;;13159;030;Berlin
Berlin;;13187;030;Berlin
Berlin;;13189;030;Berlin
Berlin;;13347;030;Berlin
Berlin;;13349;030;Berlin
Berlin;;13351;030;Berlin
Berlin;;13353;030;Berlin
Berlin;;13355;030;Berlin
Berlin;;13357;030;Berlin
Berlin;;13359;030;Berlin
Berlin;;13403;030;Berlin
Berlin;;13405;030;Berlin
Berlin;;13407;030;Berlin
Berlin;;13409;030;Berlin
Berlin;;13435;030;Berlin
Berlin;;13437;030;Berlin
Berlin;;13439;030;Berlin
Berlin;;13465;030;Berlin
Berlin;;13467;030;Berlin
Berlin;;13469;030;Berlin
Berlin;;13503;030;Berlin
Berlin;;13505;030;Berlin
Berlin;;13507;030;Berlin
Berlin;;13509;030;Berlin
Berlin;;13581;030;Berlin
Berlin;;13583;030;Berlin
Berlin;;13585;030;Berlin
Berlin;;13587;030;Berlin
Berlin;;13589;030;Berlin
Berlin;;13591;030;Berlin
Berlin;;13593;030;Berlin
Berlin;;13595;030;Berlin
Berlin;;13597;030;Berlin
Berlin;;13599;030;Berlin
Berlin;;13627;030;Berlin
Berlin;;13629;030;Berlin
Berlin;;14050;030;Berlin
Berlin;;14052;030;Berlin
Berlin;;14053;030;Berlin
Berlin;;14055;030;Berlin
Berlin;;14057;030;Berlin
Berlin;;14059;030;Berlin
Berlin;;14089;030;Berlin
Berlin;;14109;030;Berlin
Berlin;;14129;030;Berlin
Berlin;;14163;030;Berlin
Berlin;;14165;030;Berlin
Berlin;;14167;030;Berlin
Berlin;;14169;030;Berlin
Berlin;;14193;030;Berlin
Berlin;;14195;030;Berlin
Berlin;;14197;030;Berlin
Berlin;;14199;030;Berlin
Berlinchen;;16909;033966;Brandenburg
Berlingen;, Eifel;54570;06591;Rheinland-Pfalz
Berlingerode;;37339;036071;Thüringen
Berlstedt;;99439;036452;Thüringen
Bermatingen;, Baden;88697;07544;Baden-Württemberg
Bermbach;b Vacha;36419;036967;Thüringen
Bermbach;b Schmalkalden;98587;036847;Thüringen
Bermel;;56729;02657;Rheinland-Pfalz
Bermersheim;vor der Höhe;55234;06731;Rheinland-Pfalz
Bermersheim;b Worms;67593;06244;Rheinland-Pfalz
Bernau;bei Berlin;16321;03338;Brandenburg
Bernau;im Schwarzwald;79872;07675;Baden-Württemberg
Bernau;a. Chiemsee;83233;08051;Bayern
Bernbeuren;;86975;08860;Bayern
Bernburg;(Saale);06406;03471;Sachsen-Anhalt
Berndorf;, Eifel;54578;06593;Rheinland-Pfalz
Berndroth;;56370;06486;Rheinland-Pfalz
Berne;, Kr Wesermarsch;27804;04406;Niedersachsen
Berneck;b Geislingen an der Steige;73312;07334;Baden-Württemberg
Berngau;;92361;09181;Bayern
Bernhalden;;71560;07194;Baden-Württemberg
Bernhardswald;;93170;09407;Bayern
Bernheide;;19309;038780;Brandenburg
Bernitt;;18249;038464;Mecklenburg-Vorpommern
Bernkastel-Kues;;54470;06531;Rheinland-Pfalz
Bernöwe;;16515;033053;Brandenburg
Bernried;, Starnberger See;82347;08158;Bayern
Bernried;, Niederbay;94505;09905;Bayern
Bernsbach;;08315;03774;Sachsen
Bernsdorf;, OL;02994;035723;Sachsen
Bernsdorf;b Herzberg, Elster;04916;03535;Brandenburg
Bernsdorf;, Thür;07318;036736;Thüringen
Bernsdorf;b Hohenstein-Ernstthal;09337;03723;Sachsen
Bernsgrün;;07952;0371;Thüringen
Bernstadt;a. d. Eigen;02748;035874;Sachsen
Bernstadt;, Württ;89182;07348;Baden-Württemberg
Bernstorf;b Hagenow;19246;038858;Mecklenburg-Vorpommern
Bernstorf;b Grevesmühlen;23936;03881;Mecklenburg-Vorpommern
Bernterode;(bei Heilbad Heiligenstadt);37308;036082;Thüringen
Bernterode;, Untereichsfeld;37355;036076;Thüringen
Berod;bei Wallmerod;56414;06435;Rheinland-Pfalz
Berod;bei Hachenburg;57614;02680;Rheinland-Pfalz
Berscheid;, Eifel;54673;06524;Rheinland-Pfalz
Berschweiler;bei Kirn;55608;06752;Rheinland-Pfalz
Berschweiler;b Baumholder;55777;06783;Rheinland-Pfalz
Bersenbrück;;49593;05439;Niedersachsen
Berßel;;38835;039421;Sachsen-Anhalt
Berthelsdorf;b Herrnhut;02747;035873;Sachsen
Bertholling;;94565;08541;Bayern
Bertikow;;17291;039858;Brandenburg
Bertingen;;39517;039366;Sachsen-Anhalt
Bertkow;;39596;039388;Sachsen-Anhalt
Bertsdorf-Hörnitz;;02763;03583;Sachsen
Berumbur;;26524;04936;Niedersachsen
Berzhahn;;56459;02663;Rheinland-Pfalz
Berzhausen;;57632;02685;Rheinland-Pfalz
Besandten;;19309;038758;Brandenburg
Bescheid;b Hermeskeil;54413;06509;Rheinland-Pfalz
Beschendorf;;23738;04564;Schlewig-Holstein
Besdorf;;25584;04827;Schlewig-Holstein
Beselich;;65614;06484;Hessen
Besenthal;;23899;04547;Schlewig-Holstein
Beseritz;;17039;039601;Mecklenburg-Vorpommern
Besigheim;;74354;07143;Baden-Württemberg
Besitz;;19258;038844;Mecklenburg-Vorpommern
Bessenbach;;63856;06095;Bayern
Bestenrade;;19217;038872;Mecklenburg-Vorpommern
Bestensee;;15741;033763;Brandenburg
Bestwig;;59909;02904;Nordrhein-Westfalen
Bethanien;;08258;037423;Sachsen
Bethau;;06925;035386;Sachsen-Anhalt
Betheln;;31032;05182;Niedersachsen
Bethenhausen;;07554;036695;Thüringen
Betteldorf;;54570;06595;Rheinland-Pfalz
Betten;;03238;03531;Brandenburg
Bettendorf;b Nastätten;56355;06772;Rheinland-Pfalz
Bettenfeld;, Eifel;54533;06572;Rheinland-Pfalz
Bettenhausen;b Meiningen;98617;036943;Thüringen
Bettingen;, Eifel;54646;06527;Rheinland-Pfalz
Betzdorf;, Sieg;57518;02741;Rheinland-Pfalz
Betzendorf;, Kr Lüneburg;21386;04138;Niedersachsen
Betzenhausen;;87743;;Bayern
Betzenstein;;91282;09244;Bayern
Betzenweiler;;88422;07582;Baden-Württemberg
Betzigau;;87488;0831;Bayern
Betzin;;16833;033922;Brandenburg
Betzweiler-Wälde;;72291;07455;Baden-Württemberg
Beucha;b Wurzen;04824;034292;Sachsen
Beuchow;;18581;038301;Mecklenburg-Vorpommern
Beuernfeld;;99819;036920;Thüringen
Beughorst;;18314;;Mecklenburg-Vorpommern
Beulbar-Ilmsdorf;;07616;036692;Thüringen
Beulich;;56283;06745;Rheinland-Pfalz
Beulwitz;;07318;03671;Thüringen
Beuna;(Geiseltal);06217;03461;Sachsen-Anhalt
Beuren;b Leinefelde;37327;03605;Thüringen
Beuren;(Hochwald);54413;06586;Rheinland-Pfalz
Beuren;, Eifel;56825;02675;Rheinland-Pfalz
Beuren;b Nürtingen;72660;07025;Baden-Württemberg
Beurenermühle;;56864;;Rheinland-Pfalz
Beuron;;88631;07466;Baden-Württemberg
Beuster;;39615;039397;Sachsen-Anhalt
Beutel;;17268;03987;Brandenburg
Beutelsbach;, Niederbay;94501;08543;Bayern
Beutelsdorf;b Rudolstadt;07407;036742;Thüringen
Beutenmühle;b Lorch, Württ;73547;07172;Baden-Württemberg
Beutersitz;;04924;035341;Brandenburg
Beveringen;;16928;03395;Brandenburg
Bevern;, Holst;25355;04123;Schlewig-Holstein
Bevern;, Kr Holzminden;37639;05535;Niedersachsen
Beverstedt;;27616;04747;Niedersachsen
Beverungen;;37688;05273;Nordrhein-Westfalen
Bexbach;;66450;06826;Saarland
Beyern;;04895;035363;Brandenburg
Beyernaumburg;;06528;03464;Sachsen-Anhalt
Bias;;39264;03923;Sachsen-Anhalt
Biberach;, Baden;77781;07835;Baden-Württemberg
Biberach;an der Riß;88400;07351;Baden-Württemberg
Biberau;;98666;036874;Thüringen
Biberbach;, Schwab;86485;08271;Bayern
Bibersohl;;73566;;Baden-Württemberg
Bibertal;, Schwab;89346;08226;Bayern
Biblis;;68647;06245;Hessen
Bibow;;19417;038482;Mecklenburg-Vorpommern
Bibra;b Jena;07768;036424;Thüringen
Bibra;b Meiningen;98631;036944;Thüringen
Biburg;, Gem Gars a Inn;83546;08073;Bayern
Biburg;, Niederbay;93354;09443;Bayern
Bichl;b Bad Tölz;83673;08857;Bayern
Bichl;b Irschenberg;83739;08064;Bayern
Bicken;, Gem Rabenkirchen-Faulück;24392;;Schlewig-Holstein
Bickenbach;, Hunsrück;56291;06746;Rheinland-Pfalz
Bickenbach;, Bergstr;64404;06257;Hessen
Bickendorf;, Eifel;54636;06569;Rheinland-Pfalz
Bickenriede;;99976;036023;Thüringen
Bickhusen;;19258;038847;Mecklenburg-Vorpommern
Bidingen;, Schwab;87651;08348;Bayern
Biebelnheim;;55234;06733;Rheinland-Pfalz
Biebelried;;97318;09302;Bayern
Biebelsheim;;55546;06701;Rheinland-Pfalz
Bieberehren;;97243;09338;Bayern
Biebergemünd;;63599;06050;Hessen
Biebern;;55471;06761;Rheinland-Pfalz
Biebersdorf;;15913;035471;Brandenburg
Bieberstein;;09629;037324;Sachsen
Biebertal;, Hessen;35444;06409;Hessen
Biebesheim;am Rhein;64584;06258;Hessen
Biebing;b Frauenneuharting;83565;08092;Bayern
Biebrich;b Katzenelnbogen;56370;06486;Rheinland-Pfalz
Biedenkopf;;35216;06461;Hessen
Biederbach;, Schwarzw;79215;07682;Baden-Württemberg
Biederitz;;39175;039292;Sachsen-Anhalt
Biedershausen;;66917;06375;Rheinland-Pfalz
Biedesheim;, Pfalz;67308;06355;Rheinland-Pfalz
Biegen;b Frankfurt, Oder;15236;033608;Brandenburg
Biehlen;;01945;035752;Brandenburg
Biehlerhof;;66996;06393;Rheinland-Pfalz
Bielefeld;;33602;0521;Nordrhein-Westfalen
Bielefeld;;33604;0521;Nordrhein-Westfalen
Bielefeld;;33605;0521;Nordrhein-Westfalen
Bielefeld;;33607;0521;Nordrhein-Westfalen
Bielefeld;;33609;0521;Nordrhein-Westfalen
Bielefeld;;33611;0521;Nordrhein-Westfalen
Bielefeld;;33613;0521;Nordrhein-Westfalen
Bielefeld;;33615;0521;Nordrhein-Westfalen
Bielefeld;;33617;0521;Nordrhein-Westfalen
Bielefeld;;33619;0521;Nordrhein-Westfalen
Bielefeld;;33647;0521;Nordrhein-Westfalen
Bielefeld;;33649;0521;Nordrhein-Westfalen
Bielefeld;;33659;0521;Nordrhein-Westfalen
Bielefeld;;33689;0521;Nordrhein-Westfalen
Bielefeld;;33699;0521;Nordrhein-Westfalen
Bielefeld;;33719;0521;Nordrhein-Westfalen
Bielefeld;;33729;0521;Nordrhein-Westfalen
Bielefeld;;33739;0521;Nordrhein-Westfalen
Bielen;;99735;03631;Thüringen
Biendorf;b Bernburg;06408;034722;Sachsen-Anhalt
Biendorf;b Bad Doberan;18230;038294;Mecklenburg-Vorpommern
Bienenbüttel;;29553;05823;Niedersachsen
Bienstädt;;99100;036208;Thüringen
Biere;;39221;039297;Sachsen-Anhalt
Biermühle;, Eifel;54533;;Rheinland-Pfalz
Biersackschlag;;93195;09409;Bayern
Bierschbacher Mühle;;53520;;Rheinland-Pfalz
Biersdorf am See;;54636;06569;Rheinland-Pfalz
Bierstedt;;29416;039007;Sachsen-Anhalt
Biesdorf;, Eifel;54675;06566;Rheinland-Pfalz
Biesenbrow;;16278;039861;Brandenburg
Biesendahlshof;;16306;033331;Brandenburg
Biesenrode;;06343;034782;Sachsen-Anhalt
Biesenthal;b Bernau b Berlin;16359;03337;Brandenburg
Biessenhofen;;87640;08341;Bayern
Biesterholz;;16928;;Brandenburg
Bietigheim;, Baden;76467;07245;Baden-Württemberg
Bietigheim-Bissingen;;74321;07142;Baden-Württemberg
Bietikow;;17291;039858;Brandenburg
Bilkheim;;56414;06435;Rheinland-Pfalz
Billeben;;99713;036020;Thüringen
Billerbeck;, Westf;48727;02543;Nordrhein-Westfalen
Billigheim;, Baden;74842;06265;Baden-Württemberg
Billigheim-Ingenheim;;76831;06349;Rheinland-Pfalz
Billroda;;06647;036377;Sachsen-Anhalt
Bilsen;;25485;04106;Schlewig-Holstein
Bilshausen;;37434;05528;Niedersachsen
Bilzingsleben;;06578;036375;Thüringen
Bimöhlen;;24576;04192;Schlewig-Holstein
Binau;;74862;06263;Baden-Württemberg
Binde;;29416;039036;Sachsen-Anhalt
Bindfelde;;39590;03931;Sachsen-Anhalt
Bindlach;;95463;09208;Bayern
Bindow;;15754;033767;Brandenburg
Bingen;am Rhein;55411;06721;Rheinland-Pfalz
Bingen;b Sigmaringen;72511;07577;Baden-Württemberg
Bingenheimer Mühle;;61203;;Hessen
Binnen;;31619;05023;Niedersachsen
Binningen;, Eifel;56754;02672;Rheinland-Pfalz
Binnrot;;88450;07354;Baden-Württemberg
Binsfeld;b Wittlich;54518;06575;Rheinland-Pfalz
Binswangen;b Dillingen a d Donau;86637;08272;Bayern
Binzen;, Kr Lörrach;79589;07621;Baden-Württemberg
Bippen;;49626;05435;Niedersachsen
Birenbach;, Kr Göppingen;73102;07161;Baden-Württemberg
Birgel;b Lissendorf;54587;06597;Rheinland-Pfalz
Birgland;;92262;09666;Bayern
Birkachhof;b Winnenden;71364;07195;Baden-Württemberg
Birkenau;, Odenw;69488;06201;Hessen
Birkenbeul;;57589;0261;Rheinland-Pfalz
Birkenfeld;, Nahe;55765;06782;Rheinland-Pfalz
Birkenfeld;, Württ;75217;07231;Baden-Württemberg
Birkenfeld;b Marktheidenfeld;97834;09398;Bayern
Birkenfelde;b Pritzwalk;16928;033986;Brandenburg
Birkenfelde;b Heilbad Heiligenstadt;37318;036083;Thüringen
Birkenhain;b Ludwigsfelde;14979;03379;Brandenburg
Birkenheide;, Pfalz;67134;06237;Rheinland-Pfalz
Birkenhof;b Simmern, Hunsrück;55469;06761;Rheinland-Pfalz
Birken-Honigsessen;;57587;02742;Rheinland-Pfalz
Birkenhördt;;76889;06343;Rheinland-Pfalz
Birkenhügel;;07366;036642;Thüringen
Birkenschäferei;;06528;;Sachsen-Anhalt
Birkenstein;b Berlin;15366;03342;Brandenburg
Birkenstöcke;, Gem Birstein;63699;;Hessen
Birkenteich;;73566;07173;Baden-Württemberg
Birkenwerder;;16547;03303;Brandenburg
Birkheim;;56291;06746;Rheinland-Pfalz
Birkhof;b Alfdorf;73553;07182;Baden-Württemberg
Birkhof;b Biberach an der Riß;88441;07582;Baden-Württemberg
Birkholz;b Königs Wusterhausen;15748;033765;Brandenburg
Birkholz;b Zossen b Berlin;15831;033701;Brandenburg
Birkholz;b Beeskow;15848;03366;Brandenburg
Birkholz;b Perleberg;19357;038780;Brandenburg
Birkholz;b Tangerhütte;39517;03935;Sachsen-Anhalt
Birkhorst;b Potsdam;14547;033204;Brandenburg
Birkigt;b Saalfeld, Saale;07336;036732;Thüringen
Birkungen;;37327;03605;Thüringen
Birkweiler;;76831;06345;Rheinland-Pfalz
Birlenbach;, Rhein-Lahn-Kr;65626;06432;Rheinland-Pfalz
Birnbach;, Westerw;57612;02681;Rheinland-Pfalz
Birnbach;, Gem Sankt Wolfgang;84419;08082;Bayern
Birresborn;;54574;06594;Rheinland-Pfalz
Birstein;;63633;06054;Hessen
Birtlingen;;54634;06561;Rheinland-Pfalz
Birx;;98634;036946;Thüringen
Bischberg;, Oberfr;96120;0951;Bayern
Bischbrunn;;97836;09394;Bayern
Bischdorf;, NL;03205;03541;Brandenburg
Bischhagen;;37308;03606;Thüringen
Bischheim;, Pfalz;67294;06352;Rheinland-Pfalz
Bischheim-Häslich;;01920;03578;Sachsen
Bischoffen;;35649;06444;Hessen
Bischofferode;b Leinefelde;37345;036077;Thüringen
Bischofrod;;98553;036873;Thüringen
Bischofroda;;99826;036924;Thüringen
Bischofrode;;06295;03475;Sachsen-Anhalt
Bischofsgrün;;95493;09276;Bayern
Bischofsheim;b Rüsselsheim;65474;06144;Hessen
Bischofsheim;a d Rhön;97653;09772;Bayern
Bischofsmais;;94253;09920;Bayern
Bischofswerda;;01877;03594;Sachsen
Bischofswiesen;;83483;08652;Bayern
Bischweier;;76476;07222;Baden-Württemberg
Bisingen;b Hechingen;72406;07476;Baden-Württemberg
Bismarckquelle;;21521;;Schlewig-Holstein
Bismark;b Pasewalk;17322;039754;Mecklenburg-Vorpommern
Bismark;(Altmark);39629;039089;Sachsen-Anhalt
Bispingen;;29646;05194;Niedersachsen
Bissee;;24582;04322;Schlewig-Holstein
Bissendorf;, Kr Osnabrück;49143;05402;Niedersachsen
Bissersheim;;67281;06359;Rheinland-Pfalz
Bissingen;an der Teck;73266;07023;Baden-Württemberg
Bissingen;, Schwab;86657;09005;Bayern
Bistensee;;24358;04353;Schlewig-Holstein
Bisterschied;;67806;06364;Rheinland-Pfalz
Bitburg;;54634;06561;Rheinland-Pfalz
Bitter;;19273;038845;Niedersachsen
Bitterfeld;;06749;03493;Sachsen-Anhalt
Bittkau;;39517;039362;Sachsen-Anhalt
Bitz;, Württ;72475;07431;Baden-Württemberg
Bitzen;b Wissen, Sieg;57539;02682;Rheinland-Pfalz
Blaibach;;93476;09941;Bayern
Blaichach;, Allgäu;87544;08321;Bayern
Blandikow;;16909;033962;Brandenburg
Blangenmoorfeld;;25715;;Schlewig-Holstein
Blankenbach;, Unterfr;63825;06024;Bayern
Blankenberg;, Thür;07366;036642;Thüringen
Blankenberg;b Neustadt, Dosse;16845;033974;Brandenburg
Blankenberg;, Meckl;19412;0385;Mecklenburg-Vorpommern
Blankenburg;b Prenzlau;17291;039863;Brandenburg
Blankenburg;(Harz);38889;03944;Sachsen-Anhalt
Blankenburg;, Thür;99955;036043;Thüringen
Blankenfelde;b Zossen b Berlin;15827;03379;Brandenburg
Blankenförde;;17237;039829;Mecklenburg-Vorpommern
Blankenhagen;b Rövershagen;18182;038201;Mecklenburg-Vorpommern
Blankenhain;, Thür;99444;036459;Thüringen
Blankenheim;b Sangerhausen;06528;034659;Sachsen-Anhalt
Blankenheim;, Ahr;53945;02449;Nordrhein-Westfalen
Blankenhof;;17039;03962;Mecklenburg-Vorpommern
Blankenrath;;56865;06545;Rheinland-Pfalz
Blankensee;b Luckenwalde;14959;033731;Brandenburg
Blankensee;b Neustrelitz;17237;039826;Mecklenburg-Vorpommern
Blankensee;, Vorpommern;17322;039744;Mecklenburg-Vorpommern
Blankenstein;, Saale;07366;036642;Thüringen
Blasdorf;;15868;033671;Brandenburg
Blaubach;, Pfalz;66869;06381;Rheinland-Pfalz
Blaubeuren;;89143;07344;Baden-Württemberg
Blaueishütte;;83486;;Bayern
Blauenthal;, Stadt Eibenstock;08318;037752;Sachsen
Blaues Glück;;07338;;Thüringen
Blaufelden;;74572;07953;Baden-Württemberg
Blaustein;, Württ;89134;07304;Baden-Württemberg
Blechhammer;, Sachs;08309;037752;Sachsen
Bleckede;;21354;05852;Niedersachsen
Bleckederwerder;;19273;;Mecklenburg-Vorpommern
Bleckenrode;;37339;036071;Thüringen
Bleckhausen;, Eifel;54570;06572;Rheinland-Pfalz
Bleckhausener Mühle;;54570;06572;Rheinland-Pfalz
Bleialf;;54608;06555;Rheinland-Pfalz
Bleicherode;;99752;036338;Thüringen
Blekendorf;;24327;04381;Schlewig-Holstein
Blender;;27337;04233;Niedersachsen
Blengow;;18230;038296;Mecklenburg-Vorpommern
Blesendorf;;16909;033963;Brandenburg
Blesewitz;;17392;03971;Mecklenburg-Vorpommern
Bleyen;;15328;033479;Brandenburg
Blick;, Gem Rügge;24392;04641;Schlewig-Holstein
Bliedersdorf;;21640;04163;Niedersachsen
Bliederstedt;;99718;036370;Thüringen
Blieschendorf;b Grevesmühlen;23936;038872;Mecklenburg-Vorpommern
Bliesdorf;, Oder;16269;033456;Brandenburg
Bliesendorf;;14542;03327;Brandenburg
Blieskastel;;66440;06842;Saarland
Bliestorf;;23847;04501;Schlewig-Holstein
Blievenstorf;;19306;038757;Mecklenburg-Vorpommern
Blindham;b Großhelfendorf;85655;08063;Bayern
Blindheim;b Dillingen a d Donau;89434;09004;Bayern
Blindow;;17291;03984;Brandenburg
Blochwitz;;01561;035248;Sachsen
Bloischdorf;;03130;03563;Brandenburg
Blomberg;, Ostfriesl;26487;04977;Niedersachsen
Blomberg;, Lippe;32825;05235;Nordrhein-Westfalen
Blomesche Wildnis;;25348;04124;Schlewig-Holstein
Blönsdorf;;14913;033743;Brandenburg
Blossin;;15754;033767;Brandenburg
Blowatz;;23974;038427;Mecklenburg-Vorpommern
Blumberg;b Angermünde;16306;033331;Brandenburg
Blumberg;b Werneuchen;16356;033394;Brandenburg
Blumberg;, Baden;78176;07702;Baden-Württemberg
Blumenaue;;16845;;Brandenburg
Blumenhagen;b Angermünde;16306;03332;Brandenburg
Blumenhagen;b Strasburg;17337;039752;Mecklenburg-Vorpommern
Blumenholz;;17237;039824;Mecklenburg-Vorpommern
Blumenow;;16775;033087;Brandenburg
Blumenthal;b Pritzwalk;16928;033984;Brandenburg
Blumenthal;, Holst;24241;04347;Schlewig-Holstein
Blunk;;23813;04557;Schlewig-Holstein
Blüssen;;23923;038828;Mecklenburg-Vorpommern
Blütenberg;;16230;03334;Brandenburg
Blüthen;;19357;038797;Brandenburg
Bobbau;;06766;03494;Sachsen-Anhalt
Bobeck;b Stadtroda;07646;036692;Thüringen
Bobenheim am Berg;;67273;06353;Rheinland-Pfalz
Bobenheim-Roxheim;;67240;06239;Rheinland-Pfalz
Bobenneukirchen;;08606;037434;Sachsen
Bobenthal;;76891;06394;Rheinland-Pfalz
Boberow;b Perleberg;19357;038781;Brandenburg
Böbing;;82389;08867;Bayern
Bobingen;;86399;08234;Bayern
Böbingen;, Pfalz;67482;06327;Rheinland-Pfalz
Böbingen;an der Rems;73560;07173;Baden-Württemberg
Bobitz;;23996;038424;Mecklenburg-Vorpommern
Böblingen;;71032;07031;Baden-Württemberg
Böblingen;;71034;07031;Baden-Württemberg
Boblitz;;03222;03542;Brandenburg
Böbrach;, Arberregion;94255;09923;Bayern
Bobritzsch;;09627;037325;Sachsen
Bobzin;b Hagenow;19230;038852;Mecklenburg-Vorpommern
Böchingen;;76833;06341;Rheinland-Pfalz
Bocholt;;46395;02871;Nordrhein-Westfalen
Bocholt;;46397;02871;Nordrhein-Westfalen
Bocholt;;46399;02871;Nordrhein-Westfalen
Bochow;b Groß Kreutz;14550;033207;Brandenburg
Bochow;b Jüterbog;14913;03372;Brandenburg
Bochum;;44787;0234;Nordrhein-Westfalen
Bochum;;44789;0234;Nordrhein-Westfalen
Bochum;;44791;0234;Nordrhein-Westfalen
Bochum;;44793;0234;Nordrhein-Westfalen
Bochum;;44795;0234;Nordrhein-Westfalen
Bochum;;44797;0234;Nordrhein-Westfalen
Bochum;;44799;0234;Nordrhein-Westfalen
Bochum;;44801;0234;Nordrhein-Westfalen
Bochum;;44803;0234;Nordrhein-Westfalen
Bochum;;44805;0234;Nordrhein-Westfalen
Bochum;;44807;0234;Nordrhein-Westfalen
Bochum;;44809;0234;Nordrhein-Westfalen
Bochum;;44866;0234;Nordrhein-Westfalen
Bochum;;44867;0234;Nordrhein-Westfalen
Bochum;;44869;0234;Nordrhein-Westfalen
Bochum;;44879;0234;Nordrhein-Westfalen
Bochum;;44892;0234;Nordrhein-Westfalen
Bochum;;44894;0234;Nordrhein-Westfalen
Bocka;, Thür;07589;036604;Thüringen
Bockau;;08324;03771;Sachsen
Böcke;;14778;033832;Brandenburg
Bockelnhagen;;37345;036072;Thüringen
Bockelwitz;;04703;034362;Sachsen
Bockenau;;55595;06758;Rheinland-Pfalz
Bockenem;;31167;05067;Niedersachsen
Bockenheim;an der Weinstraße;67278;06359;Rheinland-Pfalz
Bockhorn;, Jadebusen;26345;04453;Niedersachsen
Bockhorn;, Oberbay;85461;08762;Bayern
Bockhorst;, Hümmling;26897;04967;Niedersachsen
Bockstadt;;98673;03686;Thüringen
Bockwitz;b Grimma;04680;034381;Sachsen
Böddensell;;39359;039054;Sachsen-Anhalt
Boddin;b Pritzwalk;16928;03395;Brandenburg
Boddin;b Teterow;17179;039971;Mecklenburg-Vorpommern
Boddin;b Hagenow;19243;038853;Mecklenburg-Vorpommern
Bodelshausen;;72411;07471;Baden-Württemberg
Bodelwitz;;07381;03647;Thüringen
Boden;, Westerw;56412;02602;Rheinland-Pfalz
Bodenbach;b Meißen, Sachs;01683;035242;Sachsen
Bodenbach;, Eifel;53539;02692;Rheinland-Pfalz
Bodenfelde;;37194;05572;Niedersachsen
Bodenheim;, Rhein;55294;06135;Rheinland-Pfalz
Bodenkirchen;;84155;08745;Bayern
Bodenmais;;94249;09924;Bayern
Bodenrode;;37308;03606;Thüringen
Bodensee;;37434;05507;Niedersachsen
Bodenwerder;;37619;05533;Niedersachsen
Bodenwöhr;;92439;09434;Bayern
Bödingerhof;;66500;06338;Rheinland-Pfalz
Bodman-Ludwigshafen;;78351;07773;Baden-Württemberg
Bodnegg;;88285;07520;Baden-Württemberg
Bodolz;;88131;08382;Bayern
Bodstedt;;18356;038231;Mecklenburg-Vorpommern
Böel;, Angeln;24401;04623;Schlewig-Holstein
Böelnorderfeld;;24405;04646;Schlewig-Holstein
Boen;, Ostfriesl;26831;04903;Niedersachsen
Boffzen;;37691;05271;Niedersachsen
Bogel;;56357;06772;Rheinland-Pfalz
Bogen;, Niederbay;94327;09422;Bayern
Böhen;, Unterallgäu;87736;08338;Bayern
Böhla;b Ortrand;01561;035755;Sachsen
Böhlen;b Riesa;01594;03525;Sachsen
Böhlen;b Riesa;01594;03525;Sachsen
Böhlen;b Leipzig;04564;034206;Sachsen
Böhlen;b Grimma;04668;034386;Sachsen
Böhlen;, Thür;98701;036781;Thüringen
Böhlendorf;;18334;038229;Mecklenburg-Vorpommern
Böhl-Iggelheim;;67459;06324;Rheinland-Pfalz
Böhme;;29693;05164;Niedersachsen
Böhmenkirch;;89558;07332;Baden-Württemberg
Böhmfeld;;85113;08406;Bayern
Bohmstedt;;25853;04843;Schlewig-Holstein
Bohmte;;49163;05471;Niedersachsen
Bohnenburg;b Neuhaus, Elbe;19273;038845;Niedersachsen
Böhnhusen;;24220;04347;Schlewig-Holstein
Bohsdorf;;03130;035698;Brandenburg
Boienhagen;;23936;038822;Mecklenburg-Vorpommern
Boiensdorf;;23974;038427;Mecklenburg-Vorpommern
Boissow;;19246;038851;Mecklenburg-Vorpommern
Boitin;;18249;038450;Mecklenburg-Vorpommern
Boitin-Resdorf;;23923;038821;Mecklenburg-Vorpommern
Boitze;;21368;05851;Niedersachsen
Boitzenburger Land;;17268;039889;Brandenburg
Boize;;19246;038851;Mecklenburg-Vorpommern
Boizenburg;;19258;038847;Mecklenburg-Vorpommern
Bokel;b Rendsburg;24802;04330;Schlewig-Holstein
Bokel;b Elmshorn;25364;04127;Schlewig-Holstein
Bokel;b Bremerhaven;27616;04748;Niedersachsen
Bokelhoop;;24797;04332;Schlewig-Holstein
Bokelrehm;;25596;04827;Schlewig-Holstein
Böken;b Schwerin, Meckl;19069;038871;Mecklenburg-Vorpommern
Bökensberg;;23717;04528;Schlewig-Holstein
Bokensdorf;;38556;05366;Niedersachsen
Bokholt-Hanredder;;25335;04123;Schlewig-Holstein
Bokhorst;b Schenefeld, Mittelholst;25560;04892;Schlewig-Holstein
Boklund;;24848;04624;Schlewig-Holstein
Böklund;;24860;04623;Schlewig-Holstein
Boksee;;24220;04302;Schlewig-Holstein
Bolanden;, Pfalz;67295;06352;Rheinland-Pfalz
Bolanderhof;;67292;06352;Rheinland-Pfalz
Boldekow;;17392;039722;Mecklenburg-Vorpommern
Bölkendorf;;16248;033365;Brandenburg
Boll;, Kr Göppingen;73087;07164;Baden-Württemberg
Bolland;;18233;038294;Mecklenburg-Vorpommern
Bollberg;;07646;036428;Thüringen
Bollbrücke;;18209;038203;Mecklenburg-Vorpommern
Böllen;;79677;07673;Baden-Württemberg
Bollenbach;, Hunsrück;55624;06544;Rheinland-Pfalz
Böllenborn;;76887;06343;Rheinland-Pfalz
Bollendorf;;54669;06526;Rheinland-Pfalz
Bollensdorf;;15936;035451;Brandenburg
Bolleroda;;99819;036920;Thüringen
Bollersdorf;;15377;033433;Brandenburg
Bollewick;;17207;039931;Mecklenburg-Vorpommern
Bollingstedt;;24855;04625;Schlewig-Holstein
Bollmannsruh;;14778;033838;Brandenburg
Bollschweil;;79283;07633;Baden-Württemberg
Bollweg;, Gem Kattendorf;24640;;Schlewig-Holstein
Bölsberg;;57648;02661;Rheinland-Pfalz
Bolschwitz;;03205;03541;Brandenburg
Bölsdorf;;39517;039322;Sachsen-Anhalt
Bolsterlang;;87538;08326;Bayern
Bolz;;19406;038481;Mecklenburg-Vorpommern
Bölzke;;16928;03395;Brandenburg
Bomlitz;;29699;05161;Niedersachsen
Boms;;88361;0751;Baden-Württemberg
Bomsdorf;b Eisenhüttenstadt;15898;033657;Brandenburg
Bondelum;;25850;04843;Schlewig-Holstein
Bondorf;, Kr Böblingen;71149;07457;Baden-Württemberg
Bönebüttel;;24620;04394;Schlewig-Holstein
Bonefeld;;56579;02634;Rheinland-Pfalz
Bönen;;59199;02383;Nordrhein-Westfalen
Bonerath;;54316;06588;Rheinland-Pfalz
Bonese;;29413;039039;Sachsen-Anhalt
Bongard;, Eifel;53539;02692;Rheinland-Pfalz
Bönitz;;04924;035341;Brandenburg
Bonn;;53111;0228;Nordrhein-Westfalen
Bonn;;53113;0228;Nordrhein-Westfalen
Bonn;;53115;0228;Nordrhein-Westfalen
Bonn;;53117;0228;Nordrhein-Westfalen
Bonn;;53119;0228;Nordrhein-Westfalen
Bonn;;53121;0228;Nordrhein-Westfalen
Bonn;;53123;0228;Nordrhein-Westfalen
Bonn;;53125;0228;Nordrhein-Westfalen
Bonn;;53127;0228;Nordrhein-Westfalen
Bonn;;53129;0228;Nordrhein-Westfalen
Bonn;;53173;0228;Nordrhein-Westfalen
Bonn;;53175;0228;Nordrhein-Westfalen
Bonn;;53177;0228;Nordrhein-Westfalen
Bonn;;53179;0228;Nordrhein-Westfalen
Bonn;;53225;0228;Nordrhein-Westfalen
Bonn;;53227;0228;Nordrhein-Westfalen
Bonn;;53229;0228;Nordrhein-Westfalen
Bonndorf;im Schwarzwald;79848;07703;Baden-Württemberg
Bonnhagen;;23936;03881;Mecklenburg-Vorpommern
Bönnigheim;;74357;07143;Baden-Württemberg
Bönningstedt;;25474;040;Schlewig-Holstein
Bonsbeuern;;56864;02674;Rheinland-Pfalz
Bonstetten;b Augsburg;86486;08293;Bayern
Boock;, Vorpommern;17322;039754;Mecklenburg-Vorpommern
Boock;, Altmark;39606;039399;Sachsen-Anhalt
Boos;, Nahe;55595;06758;Rheinland-Pfalz
Boos;, Eifel;56729;02656;Rheinland-Pfalz
Boos;b Memmingen;87737;08335;Bayern
Boostedt;;24598;04393;Schlewig-Holstein
Bootz;;19357;038797;Brandenburg
Bopfingen;;73441;07362;Baden-Württemberg
Boppard;, Rhein;56154;06742;Rheinland-Pfalz
Borchen;;33178;05251;Nordrhein-Westfalen
Bordelum;;25852;04671;Schlewig-Holstein
Bordelumsiel;;25821;04671;Schlewig-Holstein
Bordesholm;;24582;04322;Schlewig-Holstein
Boren;;24392;04841;Schlewig-Holstein
Börfink;;54422;06782;Rheinland-Pfalz
Borgdorf-Seedorf;;24589;04392;Schlewig-Holstein
Borgentreich;;34434;05643;Nordrhein-Westfalen
Börger;;26904;05953;Niedersachsen
Börgerende;;18211;038203;Mecklenburg-Vorpommern
Borgholzhausen;;33829;05425;Nordrhein-Westfalen
Borgisdorf;;14913;033746;Brandenburg
Borgsdorf;;16556;03301;Brandenburg
Borgstedt;b Rendsburg;24794;04331;Schlewig-Holstein
Borgsum;;25938;04683;Schlewig-Holstein
Borgwedel;;24857;04621;Schlewig-Holstein
Borken;, Elster;04916;03535;Brandenburg
Borken;(Hessen);34582;05682;Hessen
Borken;, Westf;46325;02861;Nordrhein-Westfalen
Borkheide;;14822;033845;Brandenburg
Bork-Lellichow;;16866;033976;Brandenburg
Borkow;;19406;038485;Mecklenburg-Vorpommern
Borkum;;26757;04922;Niedersachsen
Borkwalde;;14822;033845;Brandenburg
Borler;;53539;02696;Rheinland-Pfalz
Börm;b Schleswig;24863;04627;Schlewig-Holstein
Born;, Darß;18375;0381;Mecklenburg-Vorpommern
Born;b Haldensleben;39345;03904;Sachsen-Anhalt
Borna;b Leipzig;04552;03433;Sachsen
Borne;b Belzig;14806;033841;Brandenburg
Borne;b Staßfurt;39435;039263;Sachsen-Anhalt
Börnecke;b Belzig;14828;033847;Brandenburg
Börnersdorf-Breitenau;;01825;035025;Sachsen
Bornhagen;;37318;036081;Thüringen
Bornheim;, Rheinl;53332;02222;Nordrhein-Westfalen
Bornheim;, Rheinhess;55237;0671;Rheinland-Pfalz
Bornheim;, Pfalz;76879;06348;Rheinland-Pfalz
Bornholt;, Holst;25557;04872;Schlewig-Holstein
Bornholt;, Forsthaus;25725;04872;Schlewig-Holstein
Bornhöved;;24619;04323;Schlewig-Holstein
Bornich;, Taunus;56348;06771;Rheinland-Pfalz
Börnichen;b Zschopau;09437;037294;Sachsen
Börnicke;b Nauen;14641;033230;Brandenburg
Börnicke;b Bernau b Berlin;16321;03338;Brandenburg
Bornitz;b Zeitz, Elster;06712;034424;Sachsen-Anhalt
Bornow;;15848;03366;Brandenburg
Bornower Ausbau;;15848;03366;Brandenburg
Bornsdorf;;15926;035455;Brandenburg
Bornsen;b Salzwedel;29413;03902;Sachsen-Anhalt
Börnsen;;21039;040;Schlewig-Holstein
Bornsmühle;;56346;;Rheinland-Pfalz
Bornstedt;b Lutherstadt Eisleben;06295;03475;Sachsen-Anhalt
Bornstedt;b Haldensleben;39343;039206;Sachsen-Anhalt
Bornum;b Zerbst;39264;039248;Sachsen-Anhalt
Borod;, Westerw;57614;02688;Rheinland-Pfalz
Borrentin;;17111;039994;Mecklenburg-Vorpommern
Borrishaag;;24405;;Schlewig-Holstein
Börrstadt;, Pfalz;67725;06357;Rheinland-Pfalz
Börsborn;;66904;06383;Rheinland-Pfalz
Borsch;;36419;036967;Thüringen
Borsdorf;a d Parthe;04451;034291;Sachsen
Borsfleth;;25376;04824;Schlewig-Holstein
Börslingen;;89177;0731;Baden-Württemberg
Börßum;;38312;05334;Niedersachsen
Borstel;b Bad Oldesloe;23845;04537;Schlewig-Holstein
Borstel;b Neumünster;24616;04324;Schlewig-Holstein
Borstel;b Sulingen;27246;04276;Niedersachsen
Borstel-Hohenraden;;25494;04101;Schlewig-Holstein
Borstendorf;;09579;037294;Sachsen
Borstorf;;23881;04543;Schlewig-Holstein
Börtlingen;, Kr Göppingen;73104;07161;Baden-Württemberg
Borxleben;;06556;03466;Thüringen
Börzow;;23936;03881;Mecklenburg-Vorpommern
Bosau;;23715;04527;Schlewig-Holstein
Bosbüll;;25899;04663;Schlewig-Holstein
Bösby;;24354;04355;Schlewig-Holstein
Bösbyfeld;;24354;04355;Schlewig-Holstein
Bösdorf;b Plön;24306;04522;Schlewig-Holstein
Bösdorf;b Haldensleben;39359;039057;Sachsen-Anhalt
Böseckendorf;;37339;036071;Thüringen
Bösel;, Oldenburg;26219;04494;Niedersachsen
Bosenbach;;66887;06385;Rheinland-Pfalz
Bösenbrunn;;08606;037421;Sachsen
Bösingen;, Kr Rottweil;78662;07404;Baden-Württemberg
Bösleben-Wüllersleben;;99310;036200;Thüringen
Bösodenbacherhof;;67742;06364;Rheinland-Pfalz
Boßdorf;;06895;034929;Sachsen-Anhalt
Bössow;;23936;03881;Mecklenburg-Vorpommern
Botelsdorf;;19205;03886;Mecklenburg-Vorpommern
Bötersen;;27367;04268;Niedersachsen
Bothel;, Kr Rotenburg, Wümme;27386;04266;Niedersachsen
Bothenheilingen;;99947;036043;Thüringen
Bothkamp;;24250;04302;Schlewig-Holstein
Bottenbach;, Pfalz;66504;06339;Rheinland-Pfalz
Bottendorf;b Roßleben;06571;034672;Thüringen
Böttingen;, Kr Tuttlingen;78583;07429;Baden-Württemberg
Bottmersdorf;;39164;039209;Sachsen-Anhalt
Bottrop;;46236;02041;Nordrhein-Westfalen
Bottrop;;46238;02041;Nordrhein-Westfalen
Bottrop;;46240;02041;Nordrhein-Westfalen
Bottrop;;46242;02041;Nordrhein-Westfalen
Bottrop;;46244;02041;Nordrhein-Westfalen
Bötzingen;;79268;07663;Baden-Württemberg
Bötzow;;16727;03304;Brandenburg
Bous;;66359;06834;Saarland
Bovenau;;24796;04334;Schlewig-Holstein
Bovenden;;37120;05594;Niedersachsen
Bowiesen;;97957;09347;Baden-Württemberg
Boxberg;/O.L.;02943;035774;Sachsen
Boxberg;, Kr Daun;54552;02692;Rheinland-Pfalz
Boxberg;, Baden;97944;07930;Baden-Württemberg
Böxlund;;24994;04605;Schlewig-Holstein
Braak;b Hamburg;22145;04107;Schlewig-Holstein
Brachbach;, Sieg;57555;02745;Rheinland-Pfalz
Brachems;, Mühle;56727;;Rheinland-Pfalz
Brachstedt;;06188;034604;Sachsen-Anhalt
Brachtendorf;;56761;02653;Rheinland-Pfalz
Brachttal;;63636;06054;Hessen
Brachwitz;b Halle, Saale;06198;0345;Sachsen-Anhalt
Brachwitz;b Belzig;14822;033748;Brandenburg
Brackel;b Winsen, Luhe;21438;04185;Niedersachsen
Brackenberg;, Forsthaus;37127;05546;Niedersachsen
Brackenheim;;74336;07135;Baden-Württemberg
Braderup;b Niebüll;25923;04663;Schlewig-Holstein
Brädikow;;14641;033237;Brandenburg
Brahlstorf;;19273;038848;Mecklenburg-Vorpommern
Brahmenau;;07554;0365;Thüringen
Brahmow;;03096;035603;Brandenburg
Brake;(Unterweser);26919;04401;Niedersachsen
Brakel;, Westf;33034;05272;Nordrhein-Westfalen
Bralitz;;16259;033369;Brandenburg
Brambach;b Roßlau, Elbe;06862;034901;Sachsen-Anhalt
Brammer;b Rendsburg;24793;04392;Schlewig-Holstein
Bramsche;, Hase;49565;05461;Niedersachsen
Bramstedt;b Bremerhaven;27628;04746;Niedersachsen
Bramstedtlund;;25926;04666;Schlewig-Holstein
Brand;, Oberpf;95682;09236;Bayern
Brande-Hörnerkirchen;;25364;04127;Schlewig-Holstein
Brandenburg;an der Havel;14770;03381;Brandenburg
Brandenburg;an der Havel;14772;03381;Brandenburg
Brandenburg;an der Havel;14774;03381;Brandenburg
Brandenburg;an der Havel;14776;03381;Brandenburg
Brandenstein;b Genthin;39307;039223;Sachsen-Anhalt
Brand-Erbisdorf;;09618;037322;Sachsen
Branderoda;;06632;034632;Sachsen-Anhalt
Brandfeld;;89335;08223;Bayern
Brandhof;b Abtsgmünd;73453;07975;Baden-Württemberg
Brandhorst;, Sachs-Anh;06785;034904;Sachsen-Anhalt
Brandis;b Wurzen;04821;034292;Sachsen
Brandis;, Schweinitzer Fließ;04916;034292;Brandenburg
Brandmoor;, Gem Hennstedt;25791;;Schlewig-Holstein
Brandscheid;, Eifel;54608;06555;Rheinland-Pfalz
Brandscheid;, Westerw;56459;02663;Rheinland-Pfalz
Brandshagen;;18519;038328;Mecklenburg-Vorpommern
Brannenburg;;83098;08034;Bayern
Braschwitz;;06188;0345;Sachsen-Anhalt
Bräsen;;06862;034907;Sachsen-Anhalt
Brattendorf;;98673;036878;Thüringen
Braubach;;56338;02627;Rheinland-Pfalz
Brauneberg;;54472;06534;Rheinland-Pfalz
Braunfels;;35619;06442;Hessen
Braunichswalde;;07580;036608;Thüringen
Braunlage;;38700;05520;Niedersachsen
Bräunlesrain;;73569;07975;Baden-Württemberg
Bräunlingen;;78199;07707;Baden-Württemberg
Bräunrode;;06333;034781;Sachsen-Anhalt
Braunsbach;, Württ;74542;07906;Baden-Württemberg
Braunsbedra;;06242;034633;Sachsen-Anhalt
Braunsberg;b Neuruppin;16818;033929;Brandenburg
Braunschweig;;38100;0531;Niedersachsen
Braunschweig;;38102;0531;Niedersachsen
Braunschweig;;38104;0531;Niedersachsen
Braunschweig;;38106;0531;Niedersachsen
Braunschweig;;38108;0531;Niedersachsen
Braunschweig;;38110;0531;Niedersachsen
Braunschweig;;38112;0531;Niedersachsen
Braunschweig;;38114;0531;Niedersachsen
Braunschweig;;38116;0531;Niedersachsen
Braunschweig;;38118;0531;Niedersachsen
Braunschweig;;38120;0531;Niedersachsen
Braunschweig;;38122;0531;Niedersachsen
Braunschweig;;38124;0531;Niedersachsen
Braunschweig;;38126;0531;Niedersachsen
Braunschwende;;06543;034775;Sachsen-Anhalt
Braunsdorf;b Freital;01737;035203;Sachsen
Braunsdorf;b Zeulenroda;07955;036482;Thüringen
Braunsdorf;b Fürstenwalde, Spree;15518;033633;Brandenburg
Bräunsdorf;b Chemnitz, Sachs;09212;03722;Sachsen
Bräunsdorf;b Freiberg, Sachs;09603;037321;Sachsen
Braunshorn;;56288;0671;Rheinland-Pfalz
Braunsroda;b Artern;06577;034673;Thüringen
Braunsroda;b Naumburg, Saale;06648;034467;Sachsen-Anhalt
Braunweiler;;55595;06706;Rheinland-Pfalz
Brauweiler;b Kirn, Nahe;55606;06754;Rheinland-Pfalz
Brebel;;24392;04641;Schlewig-Holstein
Brebelmoor;;24888;;Schlewig-Holstein
Brechen;;65611;06438;Hessen
Brecht;, Eifel;54636;06527;Rheinland-Pfalz
Breckerfeld;;58339;02338;Nordrhein-Westfalen
Breddenberg;;26897;05954;Niedersachsen
Breddin;;16845;033972;Brandenburg
Breddorf;;27412;04285;Niedersachsen
Breddorfermoor;;27726;04794;Niedersachsen
Bredenbek;b Rendsburg;24796;04334;Schlewig-Holstein
Bredenfelde;b Malchin;17153;03964;Mecklenburg-Vorpommern
Bredereiche;;16775;033087;Brandenburg
Bredow;;14641;03321;Brandenburg
Bredstedt;;25821;04671;Schlewig-Holstein
Breechen;;17506;038353;Mecklenburg-Vorpommern
Breege;;18556;038391;Mecklenburg-Vorpommern
Breese;b Wittenberge, Prignitz;19322;03877;Brandenburg
Breesen;b Neubrandenburg;17091;039604;Mecklenburg-Vorpommern
Breesen;b Ribnitz-Damgarten;18334;038320;Mecklenburg-Vorpommern
Breesen;b Gadebusch;19205;038876;Mecklenburg-Vorpommern
Breest;;17089;03965;Mecklenburg-Vorpommern
Bregenstedt;;39343;039052;Sachsen-Anhalt
Brehme;;37339;036071;Thüringen
Brehna;;06796;034954;Sachsen-Anhalt
Breidenbach;b Biedenkopf;35236;06465;Hessen
Breiholz;;24797;04332;Schlewig-Holstein
Breisach;am Rhein;79206;07667;Baden-Württemberg
Breit;;54426;06509;Rheinland-Pfalz
Breitbrunn;a. Chiemsee;83254;08054;Bayern
Breitbrunn;, Unterfr;96151;09536;Bayern
Breitenau;, NL;03249;035323;Brandenburg
Breitenau;, Westerw;56237;02623;Rheinland-Pfalz
Breitenau;b Kaiserslautern;67661;06307;Rheinland-Pfalz
Breitenbach;b Roßla;06528;034658;Sachsen-Anhalt
Breitenbach;b Zeitz, Elster;06712;034658;Sachsen-Anhalt
Breitenbach;a. Herzberg;36287;06675;Hessen
Breitenbach;, Eichsfeld;37327;03605;Thüringen
Breitenbach;, Pfalz;66916;06386;Rheinland-Pfalz
Breitenbach;b Suhl;98553;036841;Thüringen
Breitenberg;b Itzehoe;25597;04822;Schlewig-Holstein
Breitenberg;, Niederbay;94139;08584;Bayern
Breitenbrunn;/Erzgeb.;08359;037756;Sachsen
Breitenbrunn;, Schwab;87739;08263;Bayern
Breitenbrunn;, Oberpf;92363;09495;Bayern
Breitenburg;;25524;04828;Schlewig-Holstein
Breitendeich;;21734;04753;Niedersachsen
Breitenfeld;b Klingenthal;08258;037422;Sachsen
Breitenfeld;, Altmark;39638;039085;Sachsen-Anhalt
Breitenfelde;, Kr Hzgt Lauenb;23881;04542;Schlewig-Holstein
Breitengüßbach;;96149;09544;Bayern
Breitenhagen;;39240;039294;Sachsen-Anhalt
Breitenhain;b Neustadt, Orla;07806;036481;Thüringen
Breitenheerda;;07407;036744;Thüringen
Breitenheim;;55592;06753;Rheinland-Pfalz
Breitenstein;b Roßla;06547;034654;Sachsen-Anhalt
Breitenstein;, Pfalz;67466;06328;Rheinland-Pfalz
Breitenthal;b Idar-Oberstein;55758;06785;Rheinland-Pfalz
Breitenthal;b Krumbach, Schwaben;86488;08282;Bayern
Breitenworbis;;37339;036074;Thüringen
Breitingen;;89183;07340;Baden-Württemberg
Breitnau;;79874;07652;Baden-Württemberg
Breitreute;;78733;;Baden-Württemberg
Breitscheid;, Hess;35767;02777;Hessen
Breitscheid;, Kr Neuwied;53547;02638;Rheinland-Pfalz
Breitscheid;, Hunsrück;55422;06743;Rheinland-Pfalz
Breitscheidt;, Sieg;57539;02682;Rheinland-Pfalz
Breitungen;b Roßla;06536;034651;Sachsen-Anhalt
Breitungen;, Werra;98597;036848;Thüringen
Breitwies;, Gem Tettenweis;94060;08531;Bayern
Brekendorf;;24811;04336;Schlewig-Holstein
Breklum;;25821;04671;Schlewig-Holstein
Bremberg;b Katzenelnbogen;56370;06439;Rheinland-Pfalz
Bremen;;28195;0421;Bremen
Bremen;;28197;0421;Bremen
Bremen;;28199;0421;Bremen
Bremen;;28201;0421;Bremen
Bremen;;28203;0421;Bremen
Bremen;;28205;0421;Bremen
Bremen;;28207;0421;Bremen
Bremen;;28209;0421;Bremen
Bremen;;28211;0421;Bremen
Bremen;;28213;0421;Bremen
Bremen;;28215;0421;Bremen
Bremen;;28217;0421;Bremen
Bremen;;28219;0421;Bremen
Bremen;;28237;0421;Bremen
Bremen;;28239;0421;Bremen
Bremen;;28259;0421;Bremen
Bremen;;28277;0421;Bremen
Bremen;;28279;0421;Bremen
Bremen;;28307;0421;Bremen
Bremen;;28309;0421;Bremen
Bremen;;28325;0421;Bremen
Bremen;;28327;0421;Bremen
Bremen;;28329;0421;Bremen
Bremen;;28355;0421;Bremen
Bremen;;28357;0421;Bremen
Bremen;;28359;0421;Bremen
Bremen;;28717;0421;Bremen
Bremen;;28719;0421;Bremen
Bremen;;28755;0421;Bremen
Bremen;;28757;0421;Bremen
Bremen;;28759;0421;Bremen
Bremen;;28777;0421;Bremen
Bremen;;28779;0421;Bremen
Bremen;b Vacha;36419;036967;Thüringen
Bremerhaven;;27568;0471;Bremen
Bremerhaven;;27570;0471;Bremen
Bremerhaven;;27572;0471;Bremen
Bremerhaven;;27574;0471;Bremen
Bremerhaven;;27576;0471;Bremen
Bremerhaven;;27578;0471;Bremen
Bremerhaven;;27580;0471;Bremen
Bremervörde;;27432;04761;Niedersachsen
Bremke;b Bodenwerder;37620;05533;Niedersachsen
Bremm;, Mosel;56814;02675;Rheinland-Pfalz
Bremricherhof;;67822;06362;Rheinland-Pfalz
Bremscheid;b Hausen, Wied;56588;02638;Rheinland-Pfalz
Bremsdorf;;15890;033654;Brandenburg
Bremsnitz;;07646;036426;Thüringen
Brenitz;b Sonnewalde;03253;035323;Brandenburg
Brenk;;56651;02655;Rheinland-Pfalz
Brennberg;, Oberpf;93179;09484;Bayern
Brensbach;;64395;06161;Hessen
Brenz;b Neustadt-Glewe;19306;038757;Mecklenburg-Vorpommern
Bresahn;;23883;04545;Schlewig-Holstein
Bresch;;19348;038782;Brandenburg
Bresegard;b Hagenow;19230;038751;Mecklenburg-Vorpommern
Bresegard;b Ludwigslust;19294;038755;Mecklenburg-Vorpommern
Bresewitz;, Darß;18356;038231;Mecklenburg-Vorpommern
Breslack;;15898;033657;Brandenburg
Brest;, Kr Stade;21698;04762;Niedersachsen
Bretleben;;06556;034673;Thüringen
Bretnig-Hauswalde;;01900;035952;Sachsen
Bretsch;;39606;039391;Sachsen-Anhalt
Bretten;, Baden;75015;07252;Baden-Württemberg
Bretthausen;b Rennerod, Westerw;56479;02667;Rheinland-Pfalz
Brettin;;39307;03933;Sachsen-Anhalt
Bretzenheim;, Nahe;55559;0671;Rheinland-Pfalz
Bretzfeld;;74626;07946;Baden-Württemberg
Breuberg;;64747;06165;Hessen
Breuna;;34479;05693;Hessen
Breunigweiler;;67725;06357;Rheinland-Pfalz
Brevörde;;37647;05535;Niedersachsen
Brey;;56321;02628;Rheinland-Pfalz
Breydin;;16230;033451;Brandenburg
Brickeln;;25712;04825;Schlewig-Holstein
Briedel;;56867;06542;Rheinland-Pfalz
Briedeler Heck;;56850;06543;Rheinland-Pfalz
Brieden;;56829;02672;Rheinland-Pfalz
Briedern;;56820;02673;Rheinland-Pfalz
Briefer;;83739;08064;Bayern
Brielow;;14778;033837;Brandenburg
Briescht;;15848;033674;Brandenburg
Brieselang;;14656;033232;Brandenburg
Briesen;, NL;03096;035606;Brandenburg
Briesen;(Mark);15518;033607;Brandenburg
Briesen;b Königs Wusterhausen;15757;033765;Brandenburg
Briesensee;;15913;03546;Brandenburg
Brieske;;01968;03573;Brandenburg
Brieskow-Finkenheerd;;15295;033609;Brandenburg
Briest;b Brandenburg an der Havel;14778;03381;Brandenburg
Briest;b Lübtheen;19249;038759;Mecklenburg-Vorpommern
Brietlingen;;21382;04133;Niedersachsen
Brietz;;29413;03901;Sachsen-Anhalt
Brietzig;;17309;03973;Mecklenburg-Vorpommern
Brigachtal;;78086;07721;Baden-Württemberg
Briggow;;17153;039955;Mecklenburg-Vorpommern
Brille;;37639;;Niedersachsen
Brilon;;59929;02961;Nordrhein-Westfalen
Brimingen;;54646;06522;Rheinland-Pfalz
Brinjahe;;24816;04875;Schlewig-Holstein
Brinkum;, Ostfriesl;26835;04950;Niedersachsen
Bristow;;17166;03996;Mecklenburg-Vorpommern
Britz;b Eberswalde;16230;03334;Brandenburg
Bröbberow;;18258;03844;Mecklenburg-Vorpommern
Brockau;;08491;03765;Sachsen
Bröckau;;06724;034423;Sachsen-Anhalt
Brockel;, Kr Rotenburg, Wümme;27386;04266;Niedersachsen
Bröckel;, Kr Celle;29356;05144;Niedersachsen
Brockscheid;;54552;06573;Rheinland-Pfalz
Brockum;;49448;05773;Niedersachsen
Brockwitz;;01561;035248;Sachsen
Brodau;b Delitzsch;04509;034202;Sachsen
Brodenbach;;56332;02605;Rheinland-Pfalz
Brodersby;b Kappeln, Schlei;24398;04644;Schlewig-Holstein
Brodersby;b Schleswig;24864;04622;Schlewig-Holstein
Brodersdorf;;24235;04343;Schlewig-Holstein
Broderstorf;;18184;038204;Mecklenburg-Vorpommern
Brodhagen;;18209;038203;Mecklenburg-Vorpommern
Brodowin;;16230;033362;Brandenburg
Brohl;, Eifel;56754;02672;Rheinland-Pfalz
Brohl-Lützing;;56656;02633;Rheinland-Pfalz
Brohm;;17099;03968;Mecklenburg-Vorpommern
Bröhsen;;04668;03437;Sachsen
Broichsdorf;;16259;;Brandenburg
Brokdorf;, Holst;25576;04829;Schlewig-Holstein
Brokdorferlandscheide;;25554;;Schlewig-Holstein
Brokstedt;;24616;04324;Schlewig-Holstein
Brombach;b Eberbach, Baden;69434;06272;Baden-Württemberg
Brombachtal;;64753;06063;Hessen
Brome;, Niedersachs;38465;05833;Niedersachsen
Brommelund;;25821;04672;Schlewig-Holstein
Brömsenberg;;19249;038855;Mecklenburg-Vorpommern
Bromskirchen;;59969;02984;Hessen
Bronkow;;03205;035435;Brandenburg
Bronnerlehe;;87757;08266;Bayern
Broock;b Lübz;19386;038732;Mecklenburg-Vorpommern
Brook;b Grevesmühlen;23948;038827;Mecklenburg-Vorpommern
Brookhusen;;18258;03844;Mecklenburg-Vorpommern
Brößnitz;;01561;035343;Sachsen
Bröthen;, Kr Hzgt Lauenb;21514;0451;Schlewig-Holstein
Brotterode;;98599;036840;Thüringen
Brottewitz;;04895;035342;Brandenburg
Brubbach;;57614;02684;Rheinland-Pfalz
Bruch;b Wittlich;54518;06578;Rheinland-Pfalz
Bruchertseifen;;57539;02742;Rheinland-Pfalz
Bruchhagen;b Angermünde;16278;033334;Brandenburg
Bruchhausen;, Westerw;53572;02224;Rheinland-Pfalz
Bruchhausen-Vilsen;;27305;04252;Niedersachsen
Bruchheim;;04643;034341;Sachsen
Bruchköbel;;63486;06181;Hessen
Bruchmühlbach-Miesau;;66892;06372;Rheinland-Pfalz
Bruchmühle;b Strausberg;15370;033439;Brandenburg
Bruchmühle;b Grünstadt;67281;;Rheinland-Pfalz
Bruchsal;;76646;07251;Baden-Württemberg
Bruchsiedlung;;76872;;Rheinland-Pfalz
Bruchstedt;;99955;036041;Thüringen
Bruchweiler;;55758;06786;Rheinland-Pfalz
Bruchweiler-Bärenbach;;76891;06394;Rheinland-Pfalz
Bruck;, Oberbay;85567;08092;Bayern
Bruck;, Oberpf;92436;09434;Bayern
Brück;, Mark;14822;033844;Brandenburg
Bruckberg;, Niederbay;84079;08765;Bayern
Bruckberg;, Mittelfr;91590;09824;Bayern
Brücken;(Helme);06528;034656;Sachsen-Anhalt
Brücken;, Birkenfeld;55767;06782;Rheinland-Pfalz
Brücken;(Pfalz);66904;06386;Rheinland-Pfalz
Brückenmühle;, Gem Mertloch;56754;;Rheinland-Pfalz
Bruckerhof;;82380;08867;Bayern
Brückermark;;14778;033833;Brandenburg
Bruckmaier;;82515;08171;Bayern
Bruckmühl;, Mangfall;83052;08062;Bayern
Brücktal;;53539;02692;Rheinland-Pfalz
Brudersdorf;b Dargun;17159;039959;Mecklenburg-Vorpommern
Brüel;;19412;038483;Mecklenburg-Vorpommern
Brügge;b Pritzwalk;16928;033986;Brandenburg
Brügge;, Holst;24582;04322;Schlewig-Holstein
Brüggen;, Leine;31033;05182;Niedersachsen
Brüggen;, Niederrhein;41379;02163;Nordrhein-Westfalen
Brüheim;;99869;036254;Thüringen
Brühl;, Rheinl;50321;02232;Nordrhein-Westfalen
Brühl;, Baden;68782;06202;Baden-Württemberg
Brühlhof;b Obermarchtal;89611;;Baden-Württemberg
Brumby;b Schönebeck, Elbe;39240;039291;Sachsen-Anhalt
Brunau;, Altmark;39624;039030;Sachsen-Anhalt
Brunkau;;39517;039361;Sachsen-Anhalt
Brünkendorf;b Pritzwalk;16928;03395;Brandenburg
Brunn;b Neustadt, Dosse;16845;033979;Brandenburg
Brunn;b Neubrandenburg;17039;039601;Mecklenburg-Vorpommern
Brunn;b Dorfen, Stadt;84405;08082;Bayern
Brunn;, Kr Regensburg;93164;09498;Bayern
Brünn;b Eisfeld;98673;036878;Thüringen
Brünndling-Alm;;83346;08662;Bayern
Brunne;b Fehrbellin;16833;033932;Brandenburg
Brunnen;b Schrobenhausen;86564;08454;Bayern
Brunnhartshausen;;36452;036964;Thüringen
Brunnstadt;;83362;08681;Bayern
Brunnthal;b Miltenberg;63925;09372;Bayern
Brunnthal;, Kr München;85649;08102;Bayern
Brunow;b Bad Freienwalde;16259;033451;Brandenburg
Brunow;b Parchim;19372;038721;Mecklenburg-Vorpommern
Brunsbek;;22946;04107;Schlewig-Holstein
Brunsbüttel;;25541;04852;Schlewig-Holstein
Brunsleberfeld;, Forsthaus;38154;05332;Niedersachsen
Brunsmark;;23883;04542;Schlewig-Holstein
Brunstorf;, Kr Hzgt Lauenb;21524;04151;Schlewig-Holstein
Brünzow;;17509;038354;Mecklenburg-Vorpommern
Bruschied;;55606;06544;Rheinland-Pfalz
Brusendorf;;15749;033764;Brandenburg
Brüsewitz;;19071;038874;Mecklenburg-Vorpommern
Brüssow;, Uckermark;17326;039742;Brandenburg
Bruttig-Fankel;;56814;02671;Rheinland-Pfalz
Brützkow;;19217;038872;Mecklenburg-Vorpommern
Bubach;b Kastellaun;56288;06766;Rheinland-Pfalz
Bubenheim;, Rheinhess;55270;06130;Rheinland-Pfalz
Bubenheim;, Pfalz;67308;06355;Rheinland-Pfalz
Bubenreuth;;91088;09131;Bayern
Bubesheim;;89347;08221;Bayern
Buborn;;67742;06382;Rheinland-Pfalz
Bubsheim;;78585;07429;Baden-Württemberg
Buch;b Tangerhütte;39517;039362;Sachsen-Anhalt
Buch;, Hunsrück;56290;06762;Rheinland-Pfalz
Buch;, Taunus;56357;06772;Rheinland-Pfalz
Buch;b Ilshofen;74532;07904;Baden-Württemberg
Buch;a. Erlbach;84172;08709;Bayern
Buch;a. Buchrain;85656;08124;Bayern
Buch;b Illertissen;89290;07343;Bayern
Buch a. Wald;;91592;09868;Bayern
Bucha;b Naumburg, Saale;06642;034465;Sachsen-Anhalt
Bucha;b Pößneck;07389;036484;Thüringen
Bucha;b Jena;07751;036454;Thüringen
Buchbach;, Oberbay;84428;08086;Bayern
Buchbach;b Gräfenthal;98743;036703;Thüringen
Buchberg;b Stein a d Traun;83371;08681;Bayern
Buchbrunn;;97320;09321;Bayern
Buchdorf;;86675;09009;Bayern
Büchel;b Cochem;56823;02678;Rheinland-Pfalz
Büchel;b Sömmerda;99638;036375;Thüringen
Büchelborn;b Dachsenhausen;56340;06776;Rheinland-Pfalz
Bücheloh;;98693;03677;Thüringen
Buchen;(Odenwald);74722;06281;Baden-Württemberg
Büchen;, Lauenb;21514;04155;Schlewig-Holstein
Buchenbach;, Breisgau;79256;07661;Baden-Württemberg
Büchenbach;, Mittelfr;91186;09171;Bayern
Buchenberg;b Bad Ems;56130;02603;Rheinland-Pfalz
Buchenberg;b Kempten, Allgäu;87474;08378;Bayern
Büchenbeuren;;55491;06543;Rheinland-Pfalz
Buchenhaus;;16818;033920;Brandenburg
Buchenhof;b Bützow;18249;038462;Mecklenburg-Vorpommern
Buchenhorst;b Ribnitz-Damgarten;18320;038324;Mecklenburg-Vorpommern
Buchet;, Eifel;54608;06555;Rheinland-Pfalz
Buchfart;;99438;03643;Thüringen
Buchhain;;03253;035327;Brandenburg
Buchheim;b Meßkirch;88637;07777;Baden-Württemberg
Buchhof;b Eschach;73569;07975;Baden-Württemberg
Buchhof;b Ruppertshofen;73577;;Baden-Württemberg
Buchhof;, Brenz;89522;;Baden-Württemberg
Buchhof, Gem Hardthausen;am Kocher;74249;07948;Baden-Württemberg
Buchhof, Gem Öhringen;;74249;07948;Baden-Württemberg
Buchhofen;b Osterhofen;94533;09936;Bayern
Buchholz;bei Beelitz;14547;033204;Brandenburg
Buchholz;b. Niemegk;14823;033843;Brandenburg
Buchholz;b Strausberg;15345;033438;Brandenburg
Buchholz;b Fürstenwalde, Spree;15518;033636;Brandenburg
Buchholz;b Pritzwalk;16928;03395;Brandenburg
Buchholz;b Röbel;17209;039923;Mecklenburg-Vorpommern
Buchholz;in der Nordheide;21244;04181;Niedersachsen
Buchholz;b Ratzeburg;23911;04541;Schlewig-Holstein
Buchholz;, Dithm;25712;04853;Schlewig-Holstein
Buchholz;(Aller);29690;05071;Niedersachsen
Buchholz;b Stadthagen;31710;05751;Niedersachsen
Buchholz;b Stendal;39579;039361;Sachsen-Anhalt
Buchholz;(Westerwald);53567;02683;Rheinland-Pfalz
Buchholz;, Rhein-Lahn-Kr;56379;;Rheinland-Pfalz
Buchholz;b Nordhausen;99762;03631;Thüringen
Buchholz, Gem Eckfeld;;54531;;Rheinland-Pfalz
Buchholz, Gem Pantenburg;;54531;;Rheinland-Pfalz
Buchholzbergerhof;;66500;06338;Rheinland-Pfalz
Buchhorst;b Pritzwalk;16928;03395;Brandenburg
Buchhorst;, Kr Hzgt Lauenb;21481;04153;Schlewig-Holstein
Büchlberg;;94124;08505;Bayern
Buchloe;;86807;08241;Bayern
Buchmühle;b Leinefelde;37339;;Thüringen
Buchmühle;b Fichtenau;74579;07962;Baden-Württemberg
Buchow-Karpzow;;14641;033234;Brandenburg
Buchwäldchen;;03229;035434;Brandenburg
Buchwäldchen Ziegelei;;03205;;Brandenburg
Buckau;b Herzberg, Elster;04895;035363;Brandenburg
Buckau;b Brandenburg an der Havel;14793;033830;Brandenburg
Bückeburg;;31675;05722;Niedersachsen
Bücken;b Hoya, Weser;27333;04251;Niedersachsen
Buckenhof;, Mittelfr;91054;09131;Bayern
Bücknitz;;14793;033830;Brandenburg
Buckow;, NL;03205;03541;Brandenburg
Buckow;b Jüterbog;14913;033745;Brandenburg
Buckow;, Märkische Schweiz;15377;033433;Brandenburg
Buckow;b Beeskow;15848;033675;Brandenburg
Buckow;b Eberswalde;16230;03334;Brandenburg
Buckow;b Pritzwalk;16945;033986;Brandenburg
Buckow b Großwudicke;;14715;033873;Brandenburg
Buckow b Nennhausen;;14715;033878;Brandenburg
Bückwitz;;16845;033979;Brandenburg
Buddenhagen;b Wolgast;17440;03836;Mecklenburg-Vorpommern
Büddenstedt;;38372;05352;Niedersachsen
Büdelsdorf;;24782;04331;Schlewig-Holstein
Büden;;39291;039224;Sachsen-Anhalt
Budenbach;;55469;0671;Rheinland-Pfalz
Budenheim;b Mainz;55257;06139;Rheinland-Pfalz
Büdesheim;, Eifel;54610;06558;Rheinland-Pfalz
Büdingen;, Hess;63654;06042;Hessen
Büdlich;;54426;06509;Rheinland-Pfalz
Bufleben;;99869;03621;Thüringen
Bugewitz;;17398;039726;Mecklenburg-Vorpommern
Buggenhagen;b Wolgast;17440;038374;Mecklenburg-Vorpommern
Buggingen;;79426;07631;Baden-Württemberg
Bugk;;15859;033678;Brandenburg
Bühl;, Baden;77815;07223;Baden-Württemberg
Buhla;;37339;036338;Thüringen
Buhlenberg;;55767;06782;Rheinland-Pfalz
Buhlendorf;;39264;039246;Sachsen-Anhalt
Bühlertal;;77830;07223;Baden-Württemberg
Bühlertann;;74424;07973;Baden-Württemberg
Bühlerzell;;74426;07974;Baden-Württemberg
Bühlerzimmern;;74523;0791;Baden-Württemberg
Bühne;b Halberstadt;38835;039421;Sachsen-Anhalt
Bühnsdorf;;23845;04550;Schlewig-Holstein
Bühren;b Göttingen;37127;05502;Niedersachsen
Bühren;, Gem Emstek;49685;04473;Niedersachsen
Buko;;06869;034903;Sachsen-Anhalt
Bülkau;;21782;04754;Niedersachsen
Bullay;;56859;06542;Rheinland-Pfalz
Bullendorf;b Pritzwalk;16928;03395;Brandenburg
Bullenkuhlen;;25355;0431;Schlewig-Holstein
Bülow;b Teterow;17166;03996;Mecklenburg-Vorpommern
Bülow;b Güstrow;18276;03843;Mecklenburg-Vorpommern
Bülow;b Schwerin, Meckl;19089;038488;Mecklenburg-Vorpommern
Bülow;b Gadebusch;19217;038872;Mecklenburg-Vorpommern
Bülstedt;;27412;04288;Niedersachsen
Bülstringen;;39345;039058;Sachsen-Anhalt
Bülzig;;06895;034924;Sachsen-Anhalt
Bunde;, Ostfriesl;26831;04953;Niedersachsen
Bünde;;32257;05223;Nordrhein-Westfalen
Bundenbach;;55626;06544;Rheinland-Pfalz
Bundenthal;;76891;06394;Rheinland-Pfalz
Bunderhee;;26831;04953;Niedersachsen
Bundorf;;97494;09763;Bayern
Bünsche;;14715;;Brandenburg
Bünsdorf;;24794;04356;Schlewig-Holstein
Bunsoh;, Dithm;25767;04835;Schlewig-Holstein
Burbach;, Eifel;54597;06553;Rheinland-Pfalz
Burbach;, Siegerl;57299;02736;Nordrhein-Westfalen
Bürchau;;79683;07629;Baden-Württemberg
Bürdenbach;;56593;02687;Rheinland-Pfalz
Büren;, Westf;33142;02951;Nordrhein-Westfalen
Burg;b Hoyerswerda;02979;035727;Sachsen
Burg;(Spreewald);03096;035603;Brandenburg
Burg;auf Fehmarn;23769;04371;Schlewig-Holstein
Burg;(Dithmarschen);25712;04825;Schlewig-Holstein
Burg;b Magdeburg;39288;03921;Sachsen-Anhalt
Burg;, Eifel;54646;06522;Rheinland-Pfalz
Burg;(Mosel);56843;06541;Rheinland-Pfalz
Burg Hohenzollern;, Gem Bisingen;72379;;Baden-Württemberg
Burg Stargard;;17094;039603;Mecklenburg-Vorpommern
Burgau;, Schwab;89331;08222;Bayern
Burgberg;i. Allgäu;87545;08321;Bayern
Burgbernheim;;91593;09843;Bayern
Burgbretzingen;;74523;0791;Baden-Württemberg
Burgbrohl;;56659;02636;Rheinland-Pfalz
Burgdorf;, Kr Hannover;31303;05136;Niedersachsen
Burgdorf;b Salzgitter;38272;05347;Niedersachsen
Burgebrach;;96138;09546;Bayern
Bürgel;, Thür;07616;036692;Thüringen
Burgen;b Bernkastel-Kues;54472;06534;Rheinland-Pfalz
Burgen;, Mosel;56332;02605;Rheinland-Pfalz
Burgerfeld;, Dithm;25712;;Schlewig-Holstein
Burggen;;86977;08868;Bayern
Burghagen;;19348;038784;Brandenburg
Burghammer;;02979;035727;Sachsen
Burghaslach;;96152;09552;Bayern
Burghaun;;36151;06653;Hessen
Burghausen;, Salzach;84489;08677;Bayern
Burgheim;, Oberbay;86666;08432;Bayern
Burgholzhausen;b Eckartsberga;06648;034467;Sachsen-Anhalt
Burgk;;07907;036640;Thüringen
Burgkemnitz;;06804;034955;Sachsen-Anhalt
Burgkirchen;a d Alz;84508;08679;Bayern
Burgkunstadt;;96224;09572;Bayern
Burglahr;;57632;02685;Rheinland-Pfalz
Burglauer;;97724;09733;Bayern
Burglemnitz;;07356;036643;Thüringen
Burglengenfeld;;93133;09471;Bayern
Burgliebenau;;06184;0345;Sachsen-Anhalt
Burgneudorf;;02979;035727;Sachsen
Burgoberbach;;91595;09805;Bayern
Burgpreppach;;97496;09534;Bayern
Burgrieden;;88483;07392;Baden-Württemberg
Burgsalach;;91790;09147;Bayern
Burgscheidungen;;06636;034462;Sachsen-Anhalt
Burgschwalbach;;65558;06430;Rheinland-Pfalz
Burgsdorf;b Lutherstadt Eisleben;06295;034773;Sachsen-Anhalt
Burgsinn;;97775;09356;Bayern
Burgsponheim;;55595;06758;Rheinland-Pfalz
Burgstädt;, Sachs;09217;03724;Sachsen
Bürgstadt;;63927;09371;Bayern
Burgstall;b Tangerhütte;39517;039364;Sachsen-Anhalt
Burgstall;b Waging a See;83329;08681;Bayern
Burgstein;b Plauen;08538;037433;Sachsen
Burgstetten;;71576;07131;Baden-Württemberg
Burgthann;;90559;09183;Bayern
Burgwald;, Eder;35099;06457;Hessen
Burgwalde;;37318;036083;Thüringen
Burgwall;;16775;033080;Brandenburg
Burgwedel;;30938;05139;Niedersachsen
Burgwerben;;06667;03443;Sachsen-Anhalt
Burgwindheim;;96154;09551;Bayern
Burgwitz;;07806;036481;Thüringen
Burig;;15537;03362;Brandenburg
Burk;, Mittelfr;91596;09852;Bayern
Burkardroth;;97705;09734;Bayern
Burkau;;01906;035953;Sachsen
Burkersdorf;b Rudolstadt;07422;036741;Thüringen
Burkersdorf;b Gera;07570;036603;Thüringen
Burkersdorf;b Neustadt, Orla;07819;036481;Thüringen
Burkersdorf, Mulde;;09629;037324;Sachsen
Burkersroda;;06647;034465;Sachsen-Anhalt
Burkhardtsdorf;;09235;03721;Sachsen
Burkhardtsmühle;;71111;;Baden-Württemberg
Burladingen;;72393;07475;Baden-Württemberg
Burlage;b Diepholz;49459;05447;Niedersachsen
Burow;b Altentreptow;17089;03965;Mecklenburg-Vorpommern
Burow;b Perleberg;19348;038785;Brandenburg
Burrweiler;;76835;06345;Rheinland-Pfalz
Burscheid;, Rheinl;51399;02174;Nordrhein-Westfalen
Burscheid;, Eifel;54673;06564;Rheinland-Pfalz
Bürstadt;;68642;06206;Hessen
Burtenbach;;89349;08285;Bayern
Burtscheid;;54424;06504;Rheinland-Pfalz
Burweg;;21709;04144;Niedersachsen
Burxdorf;;04895;035342;Brandenburg
Buschdorf;b Seelow;15328;033473;Brandenburg
Buschhaus;b Büddenstedt;38350;;Niedersachsen
Buschkuhnsdorf;;06926;035389;Sachsen-Anhalt
Buschow;;14715;033876;Brandenburg
Büschow;;19417;038482;Mecklenburg-Vorpommern
Buschvitz;;18528;03838;Mecklenburg-Vorpommern
Busdorf;b Schleswig;24866;04621;Schlewig-Holstein
Buseck;;35418;06408;Hessen
Busenberg;, Pfalz;76891;06391;Rheinland-Pfalz
Busendorf;b Beelitz, Mark;14547;033206;Brandenburg
Busenhausen;;57612;02681;Rheinland-Pfalz
Busenwurth;;25719;04857;Schlewig-Holstein
Büsingen;am Hochrhein;78266;07734;Baden-Württemberg
Buskow;;16816;03391;Brandenburg
Büßholz;;24398;04352;Schlewig-Holstein
Büßleben;;99198;0361;Thüringen
Büßleben;;99198;0361;Thüringen
Büste;;39624;039089;Sachsen-Anhalt
Büsum;;25761;04834;Schlewig-Holstein
Büsumer Deichhausen;;25761;04834;Schlewig-Holstein
Butjadingen;;26969;04733;Niedersachsen
Bütow;;17209;039931;Mecklenburg-Vorpommern
Büttel;, Westholst;25572;04858;Schlewig-Holstein
Büttelborn;;64572;06152;Hessen
Büttelkow;;18230;038294;Mecklenburg-Vorpommern
Buttelstedt;;99439;036451;Thüringen
Buttenheim;;96155;09545;Bayern
Buttenwiesen;;86647;08274;Bayern
Bütthard;;97244;09336;Bayern
Buttlar;;36419;036967;Thüringen
Büttlingen;;23936;03881;Mecklenburg-Vorpommern
Buttstädt;;99628;036373;Thüringen
Büttstedt;;37359;036075;Thüringen
Butzbach;;35510;06033;Hessen
Butzen;b Lübben;15913;035475;Brandenburg
Bützer;;14715;03386;Brandenburg
Butzow;b Brandenburg an der Havel;14778;033836;Brandenburg
Butzow;b Anklam;17392;03971;Mecklenburg-Vorpommern
Bützow;;18246;038461;Mecklenburg-Vorpommern
Buxheim;, Oberbay;85114;08458;Bayern
Buxheim;b Memmingen;87740;08331;Bayern
Buxtehude;;21614;04161;Niedersachsen
Byhleguhre;;15913;035603;Brandenburg
Byhlen;;15913;035475;Brandenburg
Caan;;56237;02601;Rheinland-Pfalz
Caaschwitz;;07586;036605;Thüringen
Cadenberge;;21781;04777;Niedersachsen
Cadolzburg;;90556;09103;Bayern
Cahnsdorf;;15926;03544;Brandenburg
Calau;;03205;03541;Brandenburg
Calbe;(Saale);39240;039291;Sachsen-Anhalt
Calberlah;;38547;05374;Niedersachsen
Calden;, Hess;34379;05677;Hessen
Callbach;;67829;06753;Rheinland-Pfalz
Callenberg;b Hohenstein-Ernstthal;09337;037608;Sachsen
Calmuth;;53424;02642;Rheinland-Pfalz
Calvörde;;39359;039051;Sachsen-Anhalt
Calw;;75365;07051;Baden-Württemberg
Cambs;;19067;03866;Mecklenburg-Vorpommern
Camburg;;07774;036421;Thüringen
Camin;;19246;038843;Mecklenburg-Vorpommern
Caminchen;;15913;035475;Brandenburg
Cammer;b Brück, Mark;14822;033835;Brandenburg
Cammin;b Neubrandenburg;17094;039603;Mecklenburg-Vorpommern
Cammin;b Rostock;18195;038209;Mecklenburg-Vorpommern
Campow;;19217;038875;Mecklenburg-Vorpommern
Capelle;, Hof;35043;;Hessen
Capelle;, Hof;35043;;Hessen
Cappel;b Bremerhaven;27632;04741;Niedersachsen
Cappeln;(Oldenburg);49692;04478;Niedersachsen
Caputh;;14548;033209;Brandenburg
Carlow;;19217;038873;Mecklenburg-Vorpommern
Carlsberg;, Pfalz;67316;06356;Rheinland-Pfalz
Carlsfeld;, Erzgeb;08325;037752;Sachsen
Carmzow;;17291;039854;Brandenburg
Carolinenhof;b Waren;17219;039928;Mecklenburg-Vorpommern
Carpin;;17237;039821;Mecklenburg-Vorpommern
Carzig;;15326;033602;Brandenburg
Casekirchen;;06618;036694;Sachsen-Anhalt
Casekow;;16306;033331;Brandenburg
Casel;;03229;035602;Brandenburg
Castell;, Unterfr;97355;09325;Bayern
Castrop-Rauxel;;44575;02305;Nordrhein-Westfalen
Castrop-Rauxel;;44577;02305;Nordrhein-Westfalen
Castrop-Rauxel;;44579;02305;Nordrhein-Westfalen
Castrop-Rauxel;;44581;02305;Nordrhein-Westfalen
Cattenstedt;;38889;03944;Sachsen-Anhalt
Catterfeld;;99887;036253;Thüringen
Cavertitz;;04758;034363;Sachsen
Celle;;29221;05141;Niedersachsen
Celle;;29223;05141;Niedersachsen
Celle;;29225;05141;Niedersachsen
Celle;;29227;05141;Niedersachsen
Celle;;29229;05141;Niedersachsen
Cham;, Oberpf;93413;09971;Bayern
Chamerau;;93466;09944;Bayern
Charlottenberg;b Holzappel, Rhein-Lahn-Kr;56379;06439;Rheinland-Pfalz
Charlottenfelde;b Wismar, Meckl;23992;038429;Mecklenburg-Vorpommern
Charlottenthal;b Krakow a See;18292;038457;Mecklenburg-Vorpommern
Chemnitz;, Sachs;09111;0371;Sachsen
Chemnitz;, Sachs;09112;0371;Sachsen
Chemnitz;, Sachs;09113;0371;Sachsen
Chemnitz;, Sachs;09114;0371;Sachsen
Chemnitz;, Sachs;09116;0371;Sachsen
Chemnitz;, Sachs;09117;0371;Sachsen
Chemnitz;, Sachs;09119;0371;Sachsen
Chemnitz;, Sachs;09120;0371;Sachsen
Chemnitz;, Sachs;09122;0371;Sachsen
Chemnitz;, Sachs;09123;0371;Sachsen
Chemnitz;, Sachs;09125;0371;Sachsen
Chemnitz;, Sachs;09126;0371;Sachsen
Chemnitz;, Sachs;09127;0371;Sachsen
Chemnitz;, Sachs;09128;0371;Sachsen
Chemnitz;, Sachs;09130;0371;Sachsen
Chemnitz;, Sachs;09131;0371;Sachsen
Chieming;;83339;08664;Bayern
Chiemsee;;83256;08054;Bayern
Chörau;;06386;034909;Sachsen-Anhalt
Choren;;04720;034325;Sachsen
Chorin;;16230;033366;Brandenburg
Christdorf;;16909;033965;Brandenburg
Christes;;98547;036844;Thüringen
Christiansholm;;24799;04339;Schlewig-Holstein
Christianslust;b St. Michaelisdonn;25693;04853;Schlewig-Holstein
Christinendorf;;15806;033731;Brandenburg
Christinenfeld;b Klütz;23948;038825;Mecklenburg-Vorpommern
Christinenthal;, Holst;25593;04892;Schlewig-Holstein
Christophruhe;;89522;;Baden-Württemberg
Chursdorf;b Schleiz;07907;036648;Thüringen
Chursdorf;b Rochlitz;09322;037381;Sachsen
Clausdorf;b Neubukow;18233;038294;Mecklenburg-Vorpommern
Clausdorf;b Bad Doberan;18239;038294;Mecklenburg-Vorpommern
Clausen;, Kr Pirmasens;66978;06333;Rheinland-Pfalz
Claußnitz;b Mittweida;09236;037202;Sachsen
Clausthal-Zellerfeld;;38678;05323;Niedersachsen
Cleebronn;;74389;07135;Baden-Württemberg
Clenze;;29459;05844;Niedersachsen
Clingen;;99718;03636;Thüringen
Cloppenburg;;49661;04471;Niedersachsen
Clöswitz;;07407;036743;Thüringen
Cobbel;;39517;03935;Sachsen-Anhalt
Cobbelsdorf;;06869;034923;Sachsen-Anhalt
Coburg;;96450;09561;Bayern
Cochem;;56812;02671;Rheinland-Pfalz
Cochstedt;;06449;039267;Sachsen-Anhalt
Coesfeld;;48653;02541;Nordrhein-Westfalen
Cölbe;;35091;06421;Hessen
Cölbe;;35091;06421;Hessen
Colbitz;;39326;039207;Sachsen-Anhalt
Colditz;;04680;034381;Sachsen
Collenberg;;97903;09376;Bayern
Collmen;b Grimma;04680;034381;Sachsen
Colmberg;;91598;09803;Bayern
Colmnitz;b Riesa;01612;035265;Sachsen
Colmnitz;b Freital;01738;035202;Sachsen
Colnrade;;27243;04434;Niedersachsen
Cölpin;;17094;03966;Mecklenburg-Vorpommern
Commichau;;04680;034381;Sachsen
Conow;b Feldberg, Meckl;17258;039831;Mecklenburg-Vorpommern
Contwig;;66497;06332;Rheinland-Pfalz
Coppenbrügge;;31863;05156;Niedersachsen
Coppengrave;;31091;05185;Niedersachsen
Cörmigk;;06408;034976;Sachsen-Anhalt
Cornberg;, Hess;36219;05650;Hessen
Cosa;b Köthen, Anh;06369;034978;Sachsen-Anhalt
Coschen;;15898;033657;Brandenburg
Cösitz;;06369;034978;Sachsen-Anhalt
Cospeda;;07751;036425;Thüringen
Cospeda;;07751;036425;Thüringen
Cossebaude;;01462;0351;Sachsen
Cossebaude;;01462;0351;Sachsen
Cossengrün;;07985;036621;Thüringen
Coswig;b Dresden;01640;03523;Sachsen
Coswig;(Anhalt);06869;034903;Sachsen-Anhalt
Cöthen;;16259;033458;Brandenburg
Cottbus;;03042;0355;Brandenburg
Cottbus;;03044;0355;Brandenburg
Cottbus;;03046;0355;Brandenburg
Cottbus;;03048;0355;Brandenburg
Cottbus;;03050;0355;Brandenburg
Cottbus;;03051;0355;Brandenburg
Cottbus;;03052;0355;Brandenburg
Cottbus;;03053;0355;Brandenburg
Cottbus;;03054;0355;Brandenburg
Cottbus;;03055;0355;Brandenburg
Cottendorf;;99326;03629;Thüringen
Crailsheim;;74564;07951;Baden-Württemberg
Cramberg;;65558;06439;Rheinland-Pfalz
Cramme;;38312;05341;Niedersachsen
Cramonshagen;;19071;038871;Mecklenburg-Vorpommern
Craupe;;03205;035435;Brandenburg
Crawinkel;;99330;03624;Thüringen
Creglingen;;97993;07933;Baden-Württemberg
Cremlingen;;38162;05306;Niedersachsen
Creußen;;95473;09270;Bayern
Creuzburg;;99831;036926;Thüringen
Criewen;;16306;033331;Brandenburg
Crimla;;07557;036603;Thüringen
Crimmitschau;;08451;03762;Sachsen
Crinitz;;03246;035324;Brandenburg
Crinitzberg;;08147;037602;Sachsen
Crispendorf;;07924;03663;Thüringen
Crivitz;;19089;03863;Mecklenburg-Vorpommern
Cröchern;;39517;039364;Sachsen-Anhalt
Crock;;98673;03686;Thüringen
Crölpa-Löbschütz;;06628;034466;Sachsen-Anhalt
Cronenberg;b Lauterecken;67744;06382;Rheinland-Pfalz
Crossen;an der Elster;07613;036693;Thüringen
Crostau;;02681;03592;Sachsen
Crösten;;07318;03671;Thüringen
Crostwitz;;01920;035796;Sachsen
Crottendorf;, Erzgeb;09474;037344;Sachsen
Crussow;;16278;033338;Brandenburg
Cumlosen;;19322;038794;Brandenburg
Cunewalde;;02733;035877;Sachsen
Cunnersdorf;b Freital;01728;0351;Sachsen
Cursdorf;;98744;036705;Thüringen
Cuxhaven;;27472;04721;Niedersachsen
Cuxhaven;;27474;04721;Niedersachsen
Cuxhaven;;27476;04721;Niedersachsen
Cuxhaven;;27478;04721;Niedersachsen
Czorneboh;;02733;;Sachsen
Daaden;;57567;02743;Rheinland-Pfalz
Daasdorf a Berge;;99428;03643;Thüringen
Daasdorf b Buttelstedt;;99439;036451;Thüringen
Dabel;;19406;038485;Mecklenburg-Vorpommern
Dabendorf;;15806;03377;Brandenburg
Dabergotz;;16818;033920;Brandenburg
Daberkow;;17129;039991;Mecklenburg-Vorpommern
Dabrun;;06888;03491;Sachsen-Anhalt
Dachau;;85221;08131;Bayern
Dachrieden;;99974;036023;Thüringen
Dachsbach;;91462;09163;Bayern
Dachsberg;(Südschwarzwald);79875;07672;Baden-Württemberg
Dachsbergau;;84371;;Bayern
Dachsborn;b Dachsenhausen;56340;06776;Rheinland-Pfalz
Dachsenhausen;;56340;06776;Rheinland-Pfalz
Dachshof;, Württ;75385;;Baden-Württemberg
Dachwig;;99100;036206;Thüringen
Dackenheim;;67273;06353;Rheinland-Pfalz
Dackscheid;b Großkampenberg;54619;;Rheinland-Pfalz
Dackscheid;b Waxweiler;54649;06554;Rheinland-Pfalz
Dadow;;19294;038755;Mecklenburg-Vorpommern
Dagebüll;;25899;04667;Schlewig-Holstein
Dägeling;;25578;04821;Schlewig-Holstein
Dahlem;, Niedersachs;21368;05851;Niedersachsen
Dahlem;b Kall;53949;02447;Nordrhein-Westfalen
Dahlem;b Bitburg;54636;06562;Rheinland-Pfalz
Dahlen;, Sachs;04774;034361;Sachsen
Dahlen;b Brandenburg an der Havel;14793;033833;Brandenburg
Dahlen;b Stendal;39579;03931;Sachsen-Anhalt
Dahlenburg;;21368;05851;Niedersachsen
Dahlenwarsleben;;39326;039202;Sachsen-Anhalt
Dahlewitz;;15827;033708;Brandenburg
Dahlhausen;b Pritzwalk;16928;033984;Brandenburg
Dahlheim;, Taunus;56348;06771;Rheinland-Pfalz
Dahlum;;38170;05332;Niedersachsen
Dahlwitz-Hoppegarten;;15366;03342;Brandenburg
Dahme;, Mark;15936;035451;Brandenburg
Dahme;, Holst;23747;04364;Schlewig-Holstein
Dahmen;;17166;039933;Mecklenburg-Vorpommern
Dahmker;;22946;04154;Schlewig-Holstein
Dahmsdorf;b Storkow, Mark;15864;033679;Brandenburg
Dahn;;66994;06391;Rheinland-Pfalz
Dahnen;;54689;06550;Rheinland-Pfalz
Dahnsdorf;;14806;033843;Brandenburg
Dähre;;29413;039031;Sachsen-Anhalt
Daisendorf;;88718;07532;Baden-Württemberg
Daiting;;86653;09091;Bayern
Dalberg;, Kr Bad Kreuznach;55595;06706;Rheinland-Pfalz
Dalberg-Wendelstorf;;19071;038871;Mecklenburg-Vorpommern
Daldorf;;24635;04328;Schlewig-Holstein
Daleiden;;54689;06550;Rheinland-Pfalz
Dalheim;b Mainz;55278;06249;Rheinland-Pfalz
Dalichow;;14913;033743;Brandenburg
Dalkendorf;;17166;039978;Mecklenburg-Vorpommern
Dalldorf;, Kr Hzgt Lauenb;21483;04155;Schlewig-Holstein
Dallgow;;14624;03322;Brandenburg
Dalliendorf;;23996;038424;Mecklenburg-Vorpommern
Dallmin;;19357;038783;Brandenburg
Dambach;, Kr Birkenfeld, Nahe;55765;06782;Rheinland-Pfalz
Dambeck;b Perleberg;19357;038783;Mecklenburg-Vorpommern
Dambeck;b Wismar, Meckl;23996;038424;Mecklenburg-Vorpommern
Dambeck;, Altmark;29416;039035;Sachsen-Anhalt
Damelang;;14822;033844;Brandenburg
Dämelow;;19417;038484;Mecklenburg-Vorpommern
Damendorf;;24361;04353;Schlewig-Holstein
Damerow;b Demmin;17121;;Mecklenburg-Vorpommern
Damerow;b Pasewalk;17309;039747;Mecklenburg-Vorpommern
Damerow;b Schönhausen, Elbe;39539;039387;Sachsen-Anhalt
Damflos;;54413;06503;Rheinland-Pfalz
Damlos;;23738;04363;Schlewig-Holstein
Damm;b Rostock;18196;038208;Mecklenburg-Vorpommern
Damm;b Parchim;19374;03871;Mecklenburg-Vorpommern
Dammbach;;63874;06092;Bayern
Damme;b Rathenow;14715;033878;Brandenburg
Damme;b Prenzlau;17291;039857;Brandenburg
Damme;, Dümmer;49401;05491;Niedersachsen
Dammendorf;b Eisenhüttenstadt;15299;033655;Brandenburg
Dammereez;;19273;038848;Mecklenburg-Vorpommern
Dammersbach;b Metten, Donau;94526;0991;Bayern
Dammeshof;;36326;06692;Hessen
Dammesmühle;;36326;06692;Hessen
Dammfleth;;25554;04823;Schlewig-Holstein
Dammsfeld;;25776;;Schlewig-Holstein
Dammühle;;56472;02661;Rheinland-Pfalz
Damnatz;;29472;05865;Niedersachsen
Damp;;24351;04352;Schlewig-Holstein
Damscheid;;55432;06744;Rheinland-Pfalz
Damsdorf;b Brandenburg an der Havel;14797;03382;Brandenburg
Damsdorf;, Kr Segeberg;23824;04323;Schlewig-Holstein
Damshagen;;23948;038825;Mecklenburg-Vorpommern
Danewitz;;16321;03337;Brandenburg
Dangelsdorf;b Görzke;14828;033847;Brandenburg
Dänischenhagen;;24229;04349;Schlewig-Holstein
Dankerath;;53520;02696;Rheinland-Pfalz
Dankerode;b Quedlinburg;06493;039484;Sachsen-Anhalt
Dankmarshausen;;99837;036922;Thüringen
Danna;;14913;033743;Brandenburg
Dannau;, Kr Plön;24329;04381;Schlewig-Holstein
Danndorf;, Niedersachs;38461;05364;Niedersachsen
Danneborth;;18236;038297;Mecklenburg-Vorpommern
Dannefeld;;39649;039004;Sachsen-Anhalt
Dannenberg;/Mark;16259;033458;Brandenburg
Dannenberg;(Elbe);29451;05861;Niedersachsen
Dannenfels;, Pfalz;67814;06357;Rheinland-Pfalz
Dannenreich;;15754;033767;Brandenburg
Dannenwalde;b Gransee;16775;033085;Brandenburg
Dannenwalde;b Neustadt, Dosse;16866;033975;Brandenburg
Dannewerk;;24867;04621;Schlewig-Holstein
Dannhof;;19348;038789;Brandenburg
Dannigkow;;39245;039200;Sachsen-Anhalt
Dannstadt-Schauernheim;;67125;06231;Rheinland-Pfalz
Danstedt;;38855;039458;Sachsen-Anhalt
Darchau;;19273;038841;Niedersachsen
Dardesheim;;38836;039422;Sachsen-Anhalt
Dargardt;;19357;038797;Brandenburg
Dargelin;;17498;038356;Mecklenburg-Vorpommern
Dargen;;17419;038372;Mecklenburg-Vorpommern
Dargow;;23883;04545;Schlewig-Holstein
Dargun;;17159;039959;Mecklenburg-Vorpommern
Darlingerode;;38871;03943;Sachsen-Anhalt
Darmstadt;;64283;06151;Hessen
Darmstadt;;64285;06151;Hessen
Darmstadt;;64287;06151;Hessen
Darmstadt;;64289;06151;Hessen
Darmstadt;;64291;06151;Hessen
Darmstadt;;64293;06151;Hessen
Darmstadt;;64295;06151;Hessen
Darmstadt;;64297;06151;Hessen
Darritz;;16818;033920;Brandenburg
Darsband;;18581;038301;Mecklenburg-Vorpommern
Darscheid;, Kr Daun;54552;06592;Rheinland-Pfalz
Darsikow;;16818;033924;Brandenburg
Darstein;, Pfalz;76848;06398;Rheinland-Pfalz
Darze;b Parchim;19372;038720;Mecklenburg-Vorpommern
Dasburg;, Eifel;54689;06550;Rheinland-Pfalz
Dasing;;86453;08205;Bayern
Daskow;;18320;03821;Mecklenburg-Vorpommern
Dassel;, Solling;37586;05564;Niedersachsen
Dassendorf;;21521;04104;Schlewig-Holstein
Dassow;;23942;038826;Mecklenburg-Vorpommern
Dätgen;;24589;04329;Schlewig-Holstein
Datteln;;45711;02363;Nordrhein-Westfalen
Dattenberg;b Linz a Rhein;53547;02644;Rheinland-Pfalz
Datzeroth;;56589;02638;Rheinland-Pfalz
Daubach;, Hunsrück;55566;06756;Rheinland-Pfalz
Daubach;, Westerw;56412;02602;Rheinland-Pfalz
Daubiansmühle;;53520;;Rheinland-Pfalz
Dauchingen;;78083;07720;Baden-Württemberg
Dauer;;17291;039851;Brandenburg
Daun;;54550;06592;Rheinland-Pfalz
Dausenau;;56132;02603;Rheinland-Pfalz
Dautmergen;;72356;07427;Baden-Württemberg
Dautphetal;;35232;06466;Hessen
Dauwelshausen;;54673;06524;Rheinland-Pfalz
Daxberg;b St Georgen, Chiemgau;83368;08621;Bayern
Daxweiler;;55442;06724;Rheinland-Pfalz
Dechow;;19217;038873;Mecklenburg-Vorpommern
Dechtow;;16833;033922;Brandenburg
Deckelstein;;93152;09404;Bayern
Deckenpfronn;;75392;07056;Baden-Württemberg
Dedeleben;;38836;039422;Sachsen-Anhalt
Dedelow;;17291;039853;Brandenburg
Dedelstorf;;29386;05832;Niedersachsen
Dedenbach;;53426;02646;Rheinland-Pfalz
Dederstedt;;06295;034773;Sachsen-Anhalt
Deensen;;37627;05532;Niedersachsen
Deersheim;;38835;039421;Sachsen-Anhalt
Deesbach;;98744;036705;Thüringen
Deesen;;56237;02626;Rheinland-Pfalz
Deetz;b Potsdam;14550;033207;Brandenburg
Deetz;b Zerbst;39264;039246;Sachsen-Anhalt
Deetz;b Stendal;39599;039246;Sachsen-Anhalt
Deggendorf;;94469;0991;Bayern
Deggenhausertal;;88693;07555;Baden-Württemberg
Deggingen;;73326;07334;Baden-Württemberg
Dehlitz;(Saale);06686;034446;Sachsen-Anhalt
Deibow;;19300;038781;Mecklenburg-Vorpommern
Deidesheim;;67146;06326;Rheinland-Pfalz
Deilingen;;78586;07426;Baden-Württemberg
Deimberg;;67742;06387;Rheinland-Pfalz
Deining;b Irschenberg;83739;08025;Bayern
Deining;, Oberpf;92364;09184;Bayern
Deiningen;;86738;09085;Bayern
Deinste;;21717;04149;Niedersachsen
Deinstedt;;27446;04284;Niedersachsen
Deisenhausen;;86489;08282;Bayern
Deisenhofen;b München;82041;089;Bayern
Deißlingen;, Neckar;78652;07420;Baden-Württemberg
Deizisau;;73779;07153;Baden-Württemberg
Delbrück;;33129;05250;Nordrhein-Westfalen
Delingsdorf;;22941;04532;Schlewig-Holstein
Delitz;am Berge;06246;0345;Sachsen-Anhalt
Delitzsch;;04509;034202;Sachsen
Dellfeld;;66503;06336;Rheinland-Pfalz
Dellien;;19273;038841;Niedersachsen
Delligsen;;31073;05187;Niedersachsen
Dellingen;;57537;02682;Rheinland-Pfalz
Dellstedt;;25786;04802;Schlewig-Holstein
Delmenhorst;;27749;04221;Niedersachsen
Delmenhorst;;27751;04221;Niedersachsen
Delmenhorst;;27753;04221;Niedersachsen
Delmenhorst;;27755;04221;Niedersachsen
Delve;, Holst;25788;04803;Schlewig-Holstein
Demen;;19089;038488;Mecklenburg-Vorpommern
Demerath;;54552;06573;Rheinland-Pfalz
Demern;;19217;038872;Mecklenburg-Vorpommern
Demerthin;;16866;033977;Brandenburg
Demitz-Thumitz;;01877;03594;Sachsen
Demker;;39579;039365;Sachsen-Anhalt
Demmin;, Hansestadt;17109;03998;Mecklenburg-Vorpommern
Demnitz;b Fürstenwalde, Spree;15518;033636;Brandenburg
Demsin;;39307;039348;Sachsen-Anhalt
Denkendorf;, Württ;73770;0711;Baden-Württemberg
Denkendorf;, Oberbay;85095;08466;Bayern
Denkingen;, Württ;78588;07424;Baden-Württemberg
Denklingen;, Oberbay;86920;08243;Bayern
Denkte;;38321;05337;Niedersachsen
Dennewitz;;14913;033741;Brandenburg
Dennheritz;;08393;03764;Sachsen
Dennweiler-Frohnbach;;66871;06381;Rheinland-Pfalz
Densborn;;54570;06594;Rheinland-Pfalz
Densow;;17268;03987;Brandenburg
Denterhof;;54597;06554;Rheinland-Pfalz
Dentlein;a. Forst;91599;09855;Bayern
Denzlingen;;79211;07666;Baden-Württemberg
Derben;;39317;039349;Sachsen-Anhalt
Derenburg;;38895;039453;Sachsen-Anhalt
Derental;;37691;05273;Niedersachsen
Dergenthin;;19348;038793;Brandenburg
Dermbach;, Thür;36466;036964;Thüringen
Dermsdorf;;99625;03635;Thüringen
Dernau;, Ahr;53507;02643;Rheinland-Pfalz
Dernbach;b Dierdorf;56307;02689;Rheinland-Pfalz
Dernbach;(Westerwald);56428;02602;Rheinland-Pfalz
Dernbach;, Pfalz;76857;06345;Rheinland-Pfalz
Dersau;, Kr Plön;24326;04526;Schlewig-Holstein
Derschen;;57520;02743;Rheinland-Pfalz
Dersekow;;17498;03834;Mecklenburg-Vorpommern
Dersenow;;19260;038848;Mecklenburg-Vorpommern
Dersum;;26906;04963;Niedersachsen
Derwitz;;14550;033207;Brandenburg
Desloch;;55592;06753;Rheinland-Pfalz
Despetal;;31035;05182;Niedersachsen
Dessau;, Anh;06842;0340;Sachsen-Anhalt
Dessau;, Anh;06844;0340;Sachsen-Anhalt
Dessau;, Anh;06846;0340;Sachsen-Anhalt
Dessau;, Anh;06847;0340;Sachsen-Anhalt
Dessau;, Anh;06849;0340;Sachsen-Anhalt
Dessighofen;;56357;06776;Rheinland-Pfalz
Dessin;;19406;038488;Mecklenburg-Vorpommern
Dessow;;16845;033974;Brandenburg
Detern;;26847;04957;Niedersachsen
Detershagen;b Burg b Magdeburg;39291;03921;Sachsen-Anhalt
Detmold;;32756;05231;Nordrhein-Westfalen
Detmold;;32758;05231;Nordrhein-Westfalen
Detmold;;32760;05231;Nordrhein-Westfalen
Dettelbach;;97337;09324;Bayern
Dettenhausen;, Württ;72135;07157;Baden-Württemberg
Dettenheim;, Baden;76706;07247;Baden-Württemberg
Dettighofen;;79802;07742;Baden-Württemberg
Dettingen;an der Erms;72581;07123;Baden-Württemberg
Dettingen;unter Teck;73265;07021;Baden-Württemberg
Dettingen;an der Iller;88451;07354;Baden-Württemberg
Dettmannsdorf;b Bad Sülze;18334;038228;Mecklenburg-Vorpommern
Dettum;;38173;05333;Niedersachsen
Detzel Gut;;39345;03904;Sachsen-Anhalt
Detzel Schloß;;39345;03904;Sachsen-Anhalt
Detzem;;54340;06507;Rheinland-Pfalz
Deuben;b Wurzen;04828;03425;Sachsen
Deuben;b Weißenfels;06682;034443;Sachsen-Anhalt
Deudesfeld;;54570;06599;Rheinland-Pfalz
Deuerling;;93180;09498;Bayern
Deuna;;37355;036076;Thüringen
Deuselbach;;54411;06504;Rheinland-Pfalz
Deutenried;;86989;08862;Bayern
Deutsch Bork;;14822;033748;Brandenburg
Deutsch Evern;;21407;04131;Niedersachsen
Deutschhof;b Fehrbellin;16818;033922;Brandenburg
Deutschneudorf;;09548;037368;Sachsen
Deutzen;;04574;03433;Sachsen
Dexheim;;55278;06133;Rheinland-Pfalz
Deyelsdorf;;18513;038334;Mecklenburg-Vorpommern
Dhronecken;;54426;06504;Rheinland-Pfalz
Dichtelbach;;55494;06764;Rheinland-Pfalz
Dickdamm;;15859;;Brandenburg
Dickel;, Kr Diepholz;49453;05445;Niedersachsen
Dickendorf;;57520;02747;Rheinland-Pfalz
Dickenschied;;55483;06763;Rheinland-Pfalz
Dickesbach;;55758;06784;Rheinland-Pfalz
Dickte;;14728;033235;Brandenburg
Didderse;;38530;05373;Niedersachsen
Diebach;, Mittelfr;91583;09868;Bayern
Dieblich;;56332;02607;Rheinland-Pfalz
Dieburg;;64807;06071;Hessen
Diebzig;;06369;034979;Sachsen-Anhalt
Diedenberg;;57548;;Rheinland-Pfalz
Diedersdorf;b Seelow;15306;03346;Brandenburg
Diedersdorf;b Zossen b Berlin;15831;03379;Brandenburg
Diedorf;b Bad Salzungen;36452;036966;Thüringen
Diedorf;, Schwab;86420;08238;Bayern
Diedorf;b Mühlhausen, Thür;99988;036024;Thüringen
Diedrichshagen;b Greifswald;17498;03834;Mecklenburg-Vorpommern
Diedrichshagen;b Grevesmühlen;23936;038822;Mecklenburg-Vorpommern
Diefenbach;b Wittlich;54538;06574;Rheinland-Pfalz
Diekhof;b Laage;18299;038455;Mecklenburg-Vorpommern
Diekholzen;;31199;05121;Niedersachsen
Diekhusen-Fahrstedt;;25709;04851;Schlewig-Holstein
Dieler;b Halsenbach;56283;06747;Rheinland-Pfalz
Dielheim;;69234;06222;Baden-Württemberg
Dielkirchen;;67811;06361;Rheinland-Pfalz
Dielmissen;;37633;05534;Niedersachsen
Diemelsee;;34519;05633;Hessen
Diemelstadt;;34474;05694;Hessen
Diemitz;b Mirow, Meckl;17252;039833;Mecklenburg-Vorpommern
Dienethal;;56379;02604;Rheinland-Pfalz
Dienheim;;55276;06133;Rheinland-Pfalz
Diensdorf-Radlow;;15864;033677;Brandenburg
Dienstedt-Hettstedt;;99326;03629;Thüringen
Dienstweiler;;55765;06782;Rheinland-Pfalz
Diepenau;;31603;05775;Niedersachsen
Diepensee;;15831;033762;Brandenburg
Diepholz;;49356;05441;Niedersachsen
Diepoldsburg;;73266;07023;Baden-Württemberg
Diepoltstatt;;83364;08662;Bayern
Diera;;01665;03521;Sachsen
Dierbach;;76889;06340;Rheinland-Pfalz
Dierberg;;16835;033933;Brandenburg
Dierdorf;;56269;02689;Rheinland-Pfalz
Dierfeld;;54533;06572;Rheinland-Pfalz
Dierhagen;, Ostseebad;18347;038226;Mecklenburg-Vorpommern
Dierscheid;;54523;06508;Rheinland-Pfalz
Diesbar-Seußlitz;;01612;035267;Sachsen
Diesburgerhof;;54668;06523;Rheinland-Pfalz
Diesdorf;, Altmark;29413;03902;Sachsen-Anhalt
Dieskau;;06184;0345;Sachsen-Anhalt
Diespeck;;91456;09161;Bayern
Dießen;a. Ammersee;86911;08807;Bayern
Diestelow;;19399;038736;Mecklenburg-Vorpommern
Dietenborn;;99713;036330;Thüringen
Dietenheim;, Iller;89165;07347;Baden-Württemberg
Dietenhofen;, Mittelfr;90599;09824;Bayern
Dieterode;;37318;036082;Thüringen
Dietersburg;;84378;08565;Bayern
Dietersdorf;b Roßla;06536;034658;Sachsen-Anhalt
Dietersdorf;b Jüterbog;14913;033747;Brandenburg
Dietersheim;, Mittelfr;91463;09161;Bayern
Dieterskirchen;;92542;09671;Bayern
Dietfurt;a d Altmühl;92345;08464;Bayern
Diethardt;;56355;06772;Rheinland-Pfalz
Dietingen;b Rottweil;78661;0741;Baden-Württemberg
Dietlas;;36460;036963;Thüringen
Dietmannsried;;87463;08374;Bayern
Dietramszell;;83623;08027;Bayern
Dietrichingen;;66484;06338;Rheinland-Pfalz
Dietrichsdorf;b Lutherstadt Wittenberg;06888;034922;Sachsen-Anhalt
Dietzenbach;;63128;06074;Hessen
Dietzenrode/Vatterode;;37318;036087;Thüringen
Dietzhausen;;98530;036846;Thüringen
Dietzhausen;;98530;036846;Thüringen
Dietzhölztal;;35716;02774;Hessen
Diez;;65582;06432;Rheinland-Pfalz
Dill;;55487;06763;Rheinland-Pfalz
Dillenburg;;35683;02771;Hessen
Dillenburg;;35684;02771;Hessen
Dillenburg;;35685;02771;Hessen
Dillenburg;;35686;02771;Hessen
Dillenburg;;35687;02771;Hessen
Dillenburg;;35688;02771;Hessen
Dillenburg;;35689;02771;Hessen
Dillenburg;;35690;02771;Hessen
Dillendorf;, Hunsrück;55481;06763;Rheinland-Pfalz
Dillingen;, Saar;66763;06831;Saarland
Dillingen;a d Donau;89407;09071;Bayern
Dillstädt;;98530;036846;Thüringen
Dimbach;, Pfalz;76848;06392;Rheinland-Pfalz
Dingdorf;, Eifel;54614;06553;Rheinland-Pfalz
Dingelstädt;, Eichsfeld;37351;036075;Thüringen
Dingelstedt;am Huy;38838;039425;Sachsen-Anhalt
Dingen;, Dithm;25715;04855;Schlewig-Holstein
Dingolfing;;84130;08731;Bayern
Dingolshausen;;97497;09382;Bayern
Dingsleben;;98646;036873;Thüringen
Dingwatt;;24891;04623;Schlewig-Holstein
Dinkelsbühl;;91550;09851;Bayern
Dinkelscherben;;86424;08292;Bayern
Dinklage;;49413;04443;Niedersachsen
Dinnies;;19406;038485;Mecklenburg-Vorpommern
Dinslaken;;46535;02064;Nordrhein-Westfalen
Dinslaken;;46537;02064;Nordrhein-Westfalen
Dinslaken;;46539;02064;Nordrhein-Westfalen
Dintesheim;;55234;06735;Rheinland-Pfalz
Dippach;b Berka, Werra;99837;036922;Thüringen
Dipperz;;36160;06657;Hessen
Dippmannsdorf;;14806;033846;Brandenburg
Dippoldiswalde;;01744;03504;Sachsen
Dirlewang;;87742;08267;Bayern
Dirmstein;;67246;06238;Rheinland-Pfalz
Dirrfelden;;89264;;Bayern
Dischingen;;89561;07327;Baden-Württemberg
Dissen;, NL;03096;035606;Brandenburg
Dissen;am Teutoburger Wald;49201;05421;Niedersachsen
Ditfurt;;06484;03946;Sachsen-Anhalt
Ditscheid;b Mayen;56729;02656;Rheinland-Pfalz
Dittelbrunn;;97456;09721;Bayern
Dittelsdorf;;02788;035843;Sachsen
Dittelsheim-Heßloch;;67596;06244;Rheinland-Pfalz
Dittenheim;;91723;09834;Bayern
Dittersdorf;b Rudolstadt;07422;036741;Thüringen
Dittersdorf;b Schleiz;07907;036648;Thüringen
Dittmannsdorf;b Rochlitz;09326;037382;Sachsen
Dittmannsdorf;b Flöha;09573;037382;Sachsen
Dittmannsdorf;b Freiberg, Sachs;09629;037324;Sachsen
Dittrichshütte;;07422;036741;Thüringen
Dittweiler;, Pfalz;66903;06386;Rheinland-Pfalz
Ditzingen;;71254;07156;Baden-Württemberg
Divitz-Spoldershagen;;18314;038231;Mecklenburg-Vorpommern
Dixförda;;06928;035384;Sachsen-Anhalt
Dobberkau;;39606;039089;Sachsen-Anhalt
Döbbersen;;19243;038853;Mecklenburg-Vorpommern
Dobbertin;;19399;0385;Mecklenburg-Vorpommern
Dobbin;b Güstrow;18292;038457;Mecklenburg-Vorpommern
Dobbrun;;39606;03937;Sachsen-Anhalt
Dobel;, Württ;75335;07083;Baden-Württemberg
Dobelgraben;;83329;;Bayern
Döbeln;;04720;03431;Sachsen
Doberburg;;15868;033671;Brandenburg
Doberenz;;09306;03737;Sachsen
Döberitz;;14727;03386;Brandenburg
Doberlug-Kirchhain;;03253;035322;Brandenburg
Döbern;, NL;03159;035600;Brandenburg
Dobernitz;b Riesa;01594;035268;Sachsen
Döbernitz;;04509;034202;Sachsen
Doberschau;;02692;03591;Sachsen
Doberschütz;;04838;034244;Sachsen
Dobersdorf;, Holst;24232;04303;Schlewig-Holstein
Dobian;;07389;03647;Thüringen
Dobitschen;;04626;034495;Thüringen
Döblitz;b Halle, Saale;06198;034607;Sachsen-Anhalt
Dobra;b Großenhain, Sachs;01561;035240;Sachsen
Dobra;b Bad Liebenwerda;04924;035341;Brandenburg
Dobra Bad;b Großenhain, Sachs;01561;035240;Sachsen
Döbrichau;b Torgau;04886;035365;Sachsen
Döbris;;06727;034441;Sachsen-Anhalt
Döbritschen;b Weimar, Thür;99441;036454;Thüringen
Dobritz;;39264;039248;Sachsen-Anhalt
Döbritz;;07381;03647;Thüringen
Dockendorf;;54636;06568;Rheinland-Pfalz
Dockweiler;;54552;06595;Rheinland-Pfalz
Dodenburg;;54518;06508;Rheinland-Pfalz
Dodendorf;;39171;0391;Sachsen-Anhalt
Dodow;;19243;038852;Mecklenburg-Vorpommern
Dogern;;79804;07751;Baden-Württemberg
Döhlau;, Kr Hof, Saale;95182;09281;Bayern
Döhlen;b Saalfeld, Saale;07330;036731;Thüringen
Dohma;;01796;03501;Sachsen
Dohm-Lammersdorf;;54576;06593;Rheinland-Pfalz
Dohna;, Sachs;01809;03529;Sachsen
Dohndorf;;06369;034976;Sachsen-Anhalt
Dohnsen;b Bodenwerder;37620;05533;Niedersachsen
Dohr;b Cochem;56812;02671;Rheinland-Pfalz
Dohren;, Nordheide;21255;04182;Niedersachsen
Dohren;, Emsl;49770;05962;Niedersachsen
Döhren;b Haldensleben;39356;039061;Sachsen-Anhalt
Dölbau;;06184;034602;Sachsen-Anhalt
Dolgelin;;15306;03346;Brandenburg
Dolgen;, Meckl;17258;039820;Mecklenburg-Vorpommern
Dolgenbrodt;;15754;033767;Brandenburg
Dolgesheim;;55278;06733;Rheinland-Pfalz
Dolglas;;18239;038295;Mecklenburg-Vorpommern
Dollart;, Ostfriesl;26831;04959;Niedersachsen
Dolle;;39517;039364;Sachsen-Anhalt
Döllen;b Neustadt, Dosse;16866;033977;Brandenburg
Dollenchen;;03238;035329;Brandenburg
Dollern;;21739;04163;Niedersachsen
Dollerup;;24989;04636;Schlewig-Holstein
Dollgen;;15913;035471;Brandenburg
Dollgow;;16775;033082;Brandenburg
Döllingen;;04928;03533;Brandenburg
Döllnitz;b Halle, Saale;06184;0345;Sachsen-Anhalt
Dollnstein;;91795;08422;Bayern
Dollrottfeld;;24392;04641;Schlewig-Holstein
Döllstädt;;99100;036206;Thüringen
Dombühl;;91601;09868;Bayern
Domersleben;;39164;0391;Sachsen-Anhalt
Dömitz;;19303;038758;Mecklenburg-Vorpommern
Dommershausen;;56290;02605;Rheinland-Pfalz
Dommitzsch;;04880;034223;Sachsen
Domnitz;;06420;034691;Sachsen-Anhalt
Domsdorf;b Drebkau;03116;035602;Brandenburg
Domsdorf;b Bad Liebenwerda;04924;035341;Brandenburg
Domsühl;;19374;038728;Mecklenburg-Vorpommern
Donaueschingen;;78166;0771;Baden-Württemberg
Donaustauf;;93093;09403;Bayern
Donauwörth;;86609;0906;Bayern
Dönges;;36460;036963;Thüringen
Dönitz;;38486;039008;Sachsen-Anhalt
Dönkendorf;;23942;038827;Mecklenburg-Vorpommern
Donndorf;, Unstrut;06571;034672;Thüringen
Donnersdorf;;97499;09528;Bayern
Donsieders;;66978;06333;Rheinland-Pfalz
Donzdorf;;73072;07162;Baden-Württemberg
Dörentrup;;32694;05265;Nordrhein-Westfalen
Dorf Gutow;;23948;038825;Mecklenburg-Vorpommern
Dorf Mecklenburg;;23972;03841;Mecklenburg-Vorpommern
Dorf Reppenhagen;;23948;038825;Mecklenburg-Vorpommern
Dorf Wahrsow;;23923;;Mecklenburg-Vorpommern
Dorf Zechlin;;16837;033923;Brandenburg
Dorfchemnitz;b. Sayda;09619;037320;Sachsen
Dorfen;, Stadt;84405;08081;Bayern
Dorfhain;;01738;035055;Sachsen
Dorfilm;;07338;036734;Thüringen
Dörfles-Esbach;;96487;09561;Bayern
Dorfprozelten;;97904;09392;Bayern
Döringsdorf;;37308;036082;Thüringen
Dormagen;;41539;02133;Nordrhein-Westfalen
Dormagen;;41540;02133;Nordrhein-Westfalen
Dormagen;;41541;02133;Nordrhein-Westfalen
Dormagen;;41542;02133;Nordrhein-Westfalen
Dormettingen;;72358;07427;Baden-Württemberg
Dormitz;;91077;09134;Bayern
Dorna;b Lutherstadt Wittenberg;06901;034927;Sachsen-Anhalt
Dörna;;99976;03601;Thüringen
Dörnberg;b Holzappel, Rhein-Lahn-Kr;56379;06439;Rheinland-Pfalz
Dornbock;;06369;034979;Sachsen-Anhalt
Dornburg;, Saale;07778;036427;Thüringen
Dornburg;, Elbe;39264;039242;Sachsen-Anhalt
Dornburg;, Westerw;65599;06436;Hessen
Dorndorf;b Rudolstadt;07407;036742;Thüringen
Dorndorf;, Rhön;36460;036963;Thüringen
Dorndorf-Steudnitz;;07778;036427;Thüringen
Dorn-Dürkheim;;67585;06733;Rheinland-Pfalz
Dörnfeld a d Ilm;;99326;03629;Thüringen
Dornhan;;72175;07455;Baden-Württemberg
Dornhecken;;83546;08073;Bayern
Dornheim;b Arnstadt;99310;03628;Thüringen
Dornholzhausen;, Rhein-Lahn-Kr;56357;02604;Rheinland-Pfalz
Dörnick;;24326;04526;Schlewig-Holstein
Dörnitz;;39291;039225;Sachsen-Anhalt
Dornreichenbach;;04808;034262;Sachsen
Dornstadt;, Württ;89160;07348;Baden-Württemberg
Dornstedt;;06179;034636;Sachsen-Anhalt
Dornstetten;, Württ;72280;07443;Baden-Württemberg
Dornswalde;;15837;033704;Brandenburg
Dornum;, Ostfriesl;26553;04933;Niedersachsen
Dorotheenhof;b Velten;16766;033055;Brandenburg
Dörpen;;26892;04963;Niedersachsen
Dörphof;;24398;04644;Schlewig-Holstein
Dörpling;;25794;04803;Schlewig-Holstein
Dörpstedt;b Schleswig;24869;04627;Schlewig-Holstein
Dörpum;;25821;04671;Schlewig-Holstein
Dörpumfeld;;25821;04672;Schlewig-Holstein
Dörrebach;, Hunsrück;55444;06724;Rheinland-Pfalz
Dörrenbach;, Pfalz;76889;06343;Rheinland-Pfalz
Dörrmoschel;;67806;06361;Rheinland-Pfalz
Dörrwalde;;01983;035753;Brandenburg
Dörscheid;;56348;06774;Rheinland-Pfalz
Dörsdorf;, Taunus;56370;06486;Rheinland-Pfalz
Dorsel;;53533;02693;Rheinland-Pfalz
Dorsheim;;55452;06721;Rheinland-Pfalz
Dorst;;39638;039058;Sachsen-Anhalt
Dorstadt;;38312;05337;Niedersachsen
Dorsten;;46282;02362;Nordrhein-Westfalen
Dorsten;;46284;02362;Nordrhein-Westfalen
Dorsten;;46286;02362;Nordrhein-Westfalen
Dörth;;56281;06747;Rheinland-Pfalz
Dortmund;;44135;0231;Nordrhein-Westfalen
Dortmund;;44137;0231;Nordrhein-Westfalen
Dortmund;;44139;0231;Nordrhein-Westfalen
Dortmund;;44141;0231;Nordrhein-Westfalen
Dortmund;;44143;0231;Nordrhein-Westfalen
Dortmund;;44145;0231;Nordrhein-Westfalen
Dortmund;;44147;0231;Nordrhein-Westfalen
Dortmund;;44149;0231;Nordrhein-Westfalen
Dortmund;;44225;0231;Nordrhein-Westfalen
Dortmund;;44227;0231;Nordrhein-Westfalen
Dortmund;;44229;0231;Nordrhein-Westfalen
Dortmund;;44263;0231;Nordrhein-Westfalen
Dortmund;;44265;0231;Nordrhein-Westfalen
Dortmund;;44267;0231;Nordrhein-Westfalen
Dortmund;;44269;0231;Nordrhein-Westfalen
Dortmund;;44287;0231;Nordrhein-Westfalen
Dortmund;;44289;0231;Nordrhein-Westfalen
Dortmund;;44309;0231;Nordrhein-Westfalen
Dortmund;;44319;0231;Nordrhein-Westfalen
Dortmund;;44328;0231;Nordrhein-Westfalen
Dortmund;;44329;0231;Nordrhein-Westfalen
Dortmund;;44339;0231;Nordrhein-Westfalen
Dortmund;;44357;0231;Nordrhein-Westfalen
Dortmund;;44359;0231;Nordrhein-Westfalen
Dortmund;;44369;0231;Nordrhein-Westfalen
Dortmund;;44379;0231;Nordrhein-Westfalen
Dortmund;;44388;0231;Nordrhein-Westfalen
Dorum;b Bremerhaven;27632;04742;Niedersachsen
Dörverden;;27313;04234;Niedersachsen

Dörzbach;;74677;07937;Baden-Württemberg
Döschnitz;;07429;036730;Thüringen
Döschwitz;;06712;034425;Sachsen-Anhalt
Dösitz;;01594;035268;Sachsen
Dößel;;06198;034607;Sachsen-Anhalt
Dossenheim;;69221;06221;Baden-Württemberg
Dossow;;16909;03394;Brandenburg
Dötlingen;;27801;04433;Niedersachsen
Dotternhausen;;72359;07427;Baden-Württemberg
Döttesfeld;;56305;02684;Rheinland-Pfalz
Döttinger Höhe;;53520;;Rheinland-Pfalz
Drachhausen;;03185;035609;Brandenburg
Drachselsried;;94256;09945;Bayern
Drackendorf;;07751;03641;Thüringen
Drackendorf;;07751;03641;Thüringen
Drackenstedt;;39365;039293;Sachsen-Anhalt
Drackenstein;, Schwäb Alb;73345;07335;Baden-Württemberg
Drage;, Elbe;21423;04177;Niedersachsen
Drage;b Hohenaspe;25582;04893;Schlewig-Holstein
Drage;, Nordfriesl;25878;04881;Schlewig-Holstein
Dragensdorf;;07907;036648;Thüringen
Dragun;;19205;038871;Mecklenburg-Vorpommern
Drahendorf;;15848;033672;Brandenburg
Drahnsdorf;;15938;035453;Brandenburg
Drakenburg;;31623;05024;Niedersachsen
Drangstedt;;27624;04704;Niedersachsen
Dranse;;16909;033966;Brandenburg
Dransfeld;;37127;05502;Niedersachsen
Dranske;;18556;038391;Mecklenburg-Vorpommern
Drantum;, Gem Emstek;49685;04473;Niedersachsen
Draschwitz;b Grimma;04668;034386;Sachsen
Draschwitz;b Zeitz, Elster;06712;034424;Sachsen-Anhalt
Drasdo;;04938;035365;Brandenburg
Dreba;;07806;036484;Thüringen
Drebach;;09430;037341;Sachsen
Drebber;;49457;05445;Niedersachsen
Drebkau;;03116;035602;Brandenburg
Drebsdorf;;06528;034656;Sachsen-Anhalt
Drechow;;18465;038320;Mecklenburg-Vorpommern
Dreeker Fladder;;49457;05445;Niedersachsen
Drees;;53520;02691;Rheinland-Pfalz
Dreetz;b Neustadt, Dosse;16845;033970;Brandenburg
Dreetz;b Bützow;18249;038450;Mecklenburg-Vorpommern
Drefahl;;19372;038721;Mecklenburg-Vorpommern
Dreggers;;23845;04550;Schlewig-Holstein
Drehfeld;;09629;037324;Sachsen
Drehnow;;03185;035601;Brandenburg
Drei Annen Hohne;;38875;039455;Sachsen-Anhalt
Dreieich;;63303;06103;Hessen
Dreifaltigkeitsberg, Gem Weng;;84164;08733;Bayern
Dreifelden;;57629;02666;Rheinland-Pfalz
Dreihof;;76879;06348;Rheinland-Pfalz
Dreikirchen;;56414;06435;Rheinland-Pfalz
Dreileben;;39365;039293;Sachsen-Anhalt
Dreilützow;;19243;038852;Mecklenburg-Vorpommern
Dreis;b Wittlich;54518;06578;Rheinland-Pfalz
Dreisbach;, Westerw;56472;02661;Rheinland-Pfalz
Dreis-Brück;;54552;06595;Rheinland-Pfalz
Dreisen;;67816;06357;Rheinland-Pfalz
Dreißig;;04720;034325;Sachsen
Dreitzsch;;07819;036481;Thüringen
Drelsdorf;;25853;04673;Schlewig-Holstein
Drenkow;;19376;038729;Mecklenburg-Vorpommern
Drense;;17291;039857;Brandenburg
Drensteinfurt;;48317;02508;Nordrhein-Westfalen
Drentwede;;49406;04246;Niedersachsen
Dreschvitz;;18573;038306;Mecklenburg-Vorpommern
Dresden;;01067;0351;Sachsen
Dresden;;01069;0351;Sachsen
Dresden;;01097;0351;Sachsen
Dresden;;01099;0351;Sachsen
Dresden;;01108;0351;Sachsen
Dresden;;01109;0351;Sachsen
Dresden;;01127;0351;Sachsen
Dresden;;01129;0351;Sachsen
Dresden;;01139;0351;Sachsen
Dresden;;01156;0351;Sachsen
Dresden;;01157;0351;Sachsen
Dresden;;01159;0351;Sachsen
Dresden;;01169;0351;Sachsen
Dresden;;01187;0351;Sachsen
Dresden;;01189;0351;Sachsen
Dresden;;01217;0351;Sachsen
Dresden;;01219;0351;Sachsen
Dresden;;01237;0351;Sachsen
Dresden;;01239;0351;Sachsen
Dresden;;01257;0351;Sachsen
Dresden;;01259;0351;Sachsen
Dresden;;01277;0351;Sachsen
Dresden;;01279;0351;Sachsen
Dresden;;01307;0351;Sachsen
Dresden;;01309;0351;Sachsen
Dresden;;01324;0351;Sachsen
Dresden;;01326;0351;Sachsen
Dresden;;01328;0351;Sachsen
Drestedt;;21279;04186;Niedersachsen
Drestedt-Valzik;, Siedlung;21255;;Niedersachsen
Dretzen;;14793;033830;Brandenburg
Drewelow;;17392;039727;Mecklenburg-Vorpommern
Drewen;;16866;033976;Brandenburg
Drewitz;, NL;03197;035607;Brandenburg
Drewitz;b Burg b Magdeburg;39291;039225;Sachsen-Anhalt
Driedorf;;35759;02775;Hessen
Drieschnitz-Kahsel;;03130;035605;Brandenburg
Driftsethe;;27628;04746;Niedersachsen
Drispeth;;19069;03867;Mecklenburg-Vorpommern
Dröbischau;;07426;036738;Thüringen
Drochow;;01994;035754;Brandenburg
Drochtersen;;21706;04143;Niedersachsen
Drogen;;04626;034491;Thüringen
Drognitz;;07338;036737;Thüringen
Drohndorf;;06456;03473;Sachsen-Anhalt
Drolshagen;;57489;02761;Nordrhein-Westfalen
Dromigt;;54619;;Rheinland-Pfalz
Drönnewitz;b Hagenow;19243;038853;Mecklenburg-Vorpommern
Drosa;;06369;034979;Sachsen-Anhalt
Droschka;;07616;036692;Thüringen
Droßdorf;b Zeitz, Elster;06712;03441;Sachsen-Anhalt
Droßdorf;b Oelsnitz, Vogtl;08606;037421;Sachsen
Droyßig;;06722;034425;Sachsen-Anhalt
Drübeck;;38871;039452;Sachsen-Anhalt
Drüsedau;;39615;039386;Sachsen-Anhalt
Drüsen;;23879;04542;Schlewig-Holstein
Druxberge;;39365;039293;Sachsen-Anhalt
Duben;;15926;035456;Brandenburg
Düben;;06869;034903;Sachsen-Anhalt
Dubrau;b Forst, Lausitz;03149;035694;Brandenburg
Dubrau;, NL;03205;035433;Brandenburg
Dübrichen;;03253;035327;Brandenburg
Dubro;;04916;035362;Brandenburg
Düchelsdorf;;23847;04501;Schlewig-Holstein
Ducherow;;17398;039726;Mecklenburg-Vorpommern
Duchroth;;55585;06755;Rheinland-Pfalz
Duckow;;17139;039957;Mecklenburg-Vorpommern
Dudeldorf;;54647;06565;Rheinland-Pfalz
Dudenbacherhof;;67731;06301;Rheinland-Pfalz
Düdenbüttel;;21709;04144;Niedersachsen
Dudenhofen;, Pfalz;67373;06232;Rheinland-Pfalz
Dudenroth;;56288;06746;Rheinland-Pfalz
Duderstadt;, Niedersachs;37115;05527;Niedersachsen
Duggendorf;;93182;09473;Bayern
Duingen;;31089;05185;Niedersachsen
Duisburg;;47051;0203;Nordrhein-Westfalen
Duisburg;;47053;0203;Nordrhein-Westfalen
Duisburg;;47055;0203;Nordrhein-Westfalen
Duisburg;;47057;0203;Nordrhein-Westfalen
Duisburg;;47058;0203;Nordrhein-Westfalen
Duisburg;;47059;0203;Nordrhein-Westfalen
Duisburg;;47119;0203;Nordrhein-Westfalen
Duisburg;;47137;0203;Nordrhein-Westfalen
Duisburg;;47138;0203;Nordrhein-Westfalen
Duisburg;;47139;0203;Nordrhein-Westfalen
Duisburg;;47166;0203;Nordrhein-Westfalen
Duisburg;;47167;0203;Nordrhein-Westfalen
Duisburg;;47169;0203;Nordrhein-Westfalen
Duisburg;;47178;0203;Nordrhein-Westfalen
Duisburg;;47179;0203;Nordrhein-Westfalen
Duisburg;;47198;0203;Nordrhein-Westfalen
Duisburg;;47199;0203;Nordrhein-Westfalen
Duisburg;;47226;0203;Nordrhein-Westfalen
Duisburg;;47228;0203;Nordrhein-Westfalen
Duisburg;;47229;0203;Nordrhein-Westfalen
Duisburg;;47239;0203;Nordrhein-Westfalen
Duisburg;;47249;0203;Nordrhein-Westfalen
Duisburg;;47259;0203;Nordrhein-Westfalen
Duisburg;;47269;0203;Nordrhein-Westfalen
Duisburg;;47279;0203;Nordrhein-Westfalen
Dülmen;;48249;02594;Nordrhein-Westfalen
Dümmer;;19073;03869;Mecklenburg-Vorpommern
Dummerstorf;;18196;038208;Mecklenburg-Vorpommern
Dümpelfeld;;53520;02695;Rheinland-Pfalz
Dünfus;;56754;02672;Rheinland-Pfalz
Düngenheim;;56761;02653;Rheinland-Pfalz
Dunningen;, Württ;78655;07403;Baden-Württemberg
Dünsen;b Bassum;27243;04244;Niedersachsen
Dunsum;;25938;04683;Schlewig-Holstein
Dunum;;26427;04971;Niedersachsen
Dunzweiler;;66916;06373;Rheinland-Pfalz
Düpow;;19348;03876;Brandenburg
Duppach;;54597;06558;Rheinland-Pfalz
Durach;, Allgäu;87471;0831;Bayern
Dürach;;89165;07347;Baden-Württemberg
Durbach;;77770;0781;Baden-Württemberg
Dürbheim;, Kr Tuttlingen;78589;07424;Baden-Württemberg
Durchhausen;;78591;07464;Baden-Württemberg
Düren;, Rheinl;52349;02421;Nordrhein-Westfalen
Düren;, Rheinl;52351;02421;Nordrhein-Westfalen
Düren;, Rheinl;52353;02421;Nordrhein-Westfalen
Düren;, Rheinl;52355;02421;Nordrhein-Westfalen
Durlangen;;73568;07176;Baden-Württemberg
Dürmentingen;;88525;07371;Baden-Württemberg
Durmersheim;;76448;07245;Baden-Württemberg
Dürnau;, Kr Göppingen;73105;07164;Baden-Württemberg
Dürnau;b Bad Buchau;88422;07582;Baden-Württemberg
Dürnberg;b Furth i Wald;93437;09973;Bayern
Dürrenhofe;;15910;035472;Brandenburg
Dürrenuhlsdorf;;08396;037609;Sachsen
Durrhausen;;83552;08075;Bayern
Dürrhennersdorf;;02708;035872;Sachsen
Dürrholz;;56307;02684;Rheinland-Pfalz
Dürrlauingen;;89350;08222;Bayern
Dürrröhrsdorf-Dittersbach;;01833;035026;Sachsen
Dürrwangen;, Mittelfr;91602;09856;Bayern
Dürrweitzschen;b Grimma;04668;034386;Sachsen
Düsedau;;39606;03937;Sachsen-Anhalt
Dusenbrücken;;66989;06336;Rheinland-Pfalz
Düsseldorf;;40210;0211;Nordrhein-Westfalen
Düsseldorf;;40211;0211;Nordrhein-Westfalen
Düsseldorf;;40212;0211;Nordrhein-Westfalen
Düsseldorf;;40213;0211;Nordrhein-Westfalen
Düsseldorf;;40215;0211;Nordrhein-Westfalen
Düsseldorf;;40217;0211;Nordrhein-Westfalen
Düsseldorf;;40219;0211;Nordrhein-Westfalen
Düsseldorf;;40221;0211;Nordrhein-Westfalen
Düsseldorf;;40223;0211;Nordrhein-Westfalen
Düsseldorf;;40225;0211;Nordrhein-Westfalen
Düsseldorf;;40227;0211;Nordrhein-Westfalen
Düsseldorf;;40229;0211;Nordrhein-Westfalen
Düsseldorf;;40231;0211;Nordrhein-Westfalen
Düsseldorf;;40233;0211;Nordrhein-Westfalen
Düsseldorf;;40235;0211;Nordrhein-Westfalen
Düsseldorf;;40237;0211;Nordrhein-Westfalen
Düsseldorf;;40239;0211;Nordrhein-Westfalen
Düsseldorf;;40468;0211;Nordrhein-Westfalen
Düsseldorf;;40470;0211;Nordrhein-Westfalen
Düsseldorf;;40472;0211;Nordrhein-Westfalen
Düsseldorf;;40474;0211;Nordrhein-Westfalen
Düsseldorf;;40476;0211;Nordrhein-Westfalen
Düsseldorf;;40477;0211;Nordrhein-Westfalen
Düsseldorf;;40479;0211;Nordrhein-Westfalen
Düsseldorf;;40489;0211;Nordrhein-Westfalen
Düsseldorf;;40545;0211;Nordrhein-Westfalen
Düsseldorf;;40547;0211;Nordrhein-Westfalen
Düsseldorf;;40549;0211;Nordrhein-Westfalen
Düsseldorf;;40589;0211;Nordrhein-Westfalen
Düsseldorf;;40591;0211;Nordrhein-Westfalen
Düsseldorf;;40593;0211;Nordrhein-Westfalen
Düsseldorf;;40595;0211;Nordrhein-Westfalen
Düsseldorf;;40597;0211;Nordrhein-Westfalen
Düsseldorf;;40599;0211;Nordrhein-Westfalen
Düsseldorf;;40625;0211;Nordrhein-Westfalen
Düsseldorf;;40627;0211;Nordrhein-Westfalen
Düsseldorf;;40629;0211;Nordrhein-Westfalen
Düsseldorf;;40721;0211;Nordrhein-Westfalen
Dußlingen;;72144;07072;Baden-Württemberg
Düßnitz;;06917;035388;Sachsen-Anhalt
Duten;, Gem Alt Duvenstedt;24782;;Schlewig-Holstein
Dütschow;;19372;038726;Mecklenburg-Vorpommern
Dutzenthal;;91438;09165;Bayern
Dutzow;;19205;038876;Mecklenburg-Vorpommern
Duvennest;;23923;038821;Mecklenburg-Vorpommern
Duvensee;;23898;04543;Schlewig-Holstein
Düvier;;17121;039998;Mecklenburg-Vorpommern
Dwergte;, Gem Molbergen;49696;04475;Niedersachsen
Dyrotz;;14641;033234;Brandenburg
Ebeleben;;99713;036020;Thüringen
Ebelsbach;;97500;09522;Bayern
Ebendorf;;39179;039203;Sachsen-Anhalt
Ebenhausen;, Isartal;82067;08178;Bayern
Ebenheiderhof;;97896;09378;Bayern
Ebenheim;;99869;036254;Thüringen
Ebensfeld;;96250;09573;Bayern
Ebenshausen;;99826;036924;Thüringen
Ebenweiler;;88370;07584;Baden-Württemberg
Ebenwies;;93152;09404;Bayern
Eberbach;, Baden;69412;06271;Baden-Württemberg
Eberdingen;;71735;07042;Baden-Württemberg
Ebereschenhof;;14641;033230;Brandenburg
Eberfing;;82390;08802;Bayern
Ebergötzen;;37136;05507;Niedersachsen
Eberhardzell;;88436;07358;Baden-Württemberg
Eberholzen;;31079;05065;Niedersachsen
Ebermannsdorf;;92263;09624;Bayern
Ebermannstadt;;91320;09194;Bayern
Ebern;;96106;09531;Bayern
Ebernhahn;;56424;02623;Rheinland-Pfalz
Ebersbach;b Großenhain, Sachs;01561;035208;Sachsen
Ebersbach;/Sa.;02730;03586;Sachsen
Ebersbach;b Döbeln;04720;03431;Sachsen
Ebersbach;an der Fils;73061;07163;Baden-Württemberg
Ebersbach-Musbach;;88371;07584;Baden-Württemberg
Ebersberg;, Oberbay;85560;08092;Bayern
Ebersbrunn;, Sachs;08144;037607;Sachsen
Ebersburg;;36157;06656;Hessen
Ebersdorf;, Thür;07368;036651;Thüringen
Ebersdorf;b Bremervörde;27432;04765;Niedersachsen
Ebersdorf;b. Coburg;96237;09562;Bayern
Ebershausen;, Schwab;86491;08282;Bayern
Ebersroda;;06632;034632;Sachsen-Anhalt
Eberstadt;, Württ;74246;07134;Baden-Württemberg
Eberstedt;;99518;036464;Thüringen
Ebersteinerhof;;78083;;Baden-Württemberg
Eberswalde;;16225;03334;Brandenburg
Eberswalde;;16227;03334;Brandenburg
Ebertshausen;, Rhein-Lahn-Kr;56370;06486;Rheinland-Pfalz
Ebertsheim;, Pfalz;67280;06359;Rheinland-Pfalz
Ebhausen;;72224;07458;Baden-Württemberg
Ebing;b Petting;83367;08686;Bayern
Ebnath;, Oberpf;95683;09234;Bayern
Ebnisee;;73667;07184;Baden-Württemberg
Eborn;;55765;;Rheinland-Pfalz
Ebrach;, Oberfr;96157;09553;Bayern
Ebringen;, Breisgau;79285;07664;Baden-Württemberg
Ebsdorfergrund;;35085;06424;Hessen
Ebstorf;;29574;05822;Niedersachsen
Echem;;21379;04153;Niedersachsen
Eching;a. Ammersee;82279;08143;Bayern
Eching;, Niederbay;84174;08709;Bayern
Eching;, Kr Freising;85386;08143;Bayern
Echternacherbrück;;54668;06525;Rheinland-Pfalz
Echtershausen;;54636;06569;Rheinland-Pfalz
Echzell;;61209;06008;Hessen
Eck;b Evenhausen, Oberbay;83552;08075;Bayern
Eckardtshausen;;99819;036925;Thüringen
Eckardtsleben;;99958;03603;Thüringen
Eckarrot;;74597;07967;Baden-Württemberg
Eckartsberg;b Zittau;02763;03583;Sachsen
Eckartsberga;;06648;034467;Sachsen-Anhalt
Eckelsheim;;55599;06703;Rheinland-Pfalz
Eckenroth;;55444;06724;Rheinland-Pfalz
Eckental;;90542;09126;Bayern
Eckernförde;;24340;04351;Schlewig-Holstein
Eckersdorf;;95488;0921;Bayern
Eckersweiler;;55777;06783;Rheinland-Pfalz
Eckfeld;;54531;06572;Rheinland-Pfalz
Eckhütt;;94374;09905;Bayern
Ecklak;;25572;04825;Schlewig-Holstein
Ecklingerode;;37339;036071;Thüringen
Eckmannsdorf;;14913;033743;Brandenburg
Eckolstädt;;99510;036421;Thüringen
Eckstedt;;99195;036371;Thüringen
Eddelak;;25715;04855;Schlewig-Holstein
Edderitz;;06388;034976;Sachsen-Anhalt
Edelsfeld;;92265;09665;Bayern
Edemissen;b Peine;31234;05176;Niedersachsen
Edenkoben;;67480;06323;Rheinland-Pfalz
Eder;b Thalhausen;83362;08666;Bayern
Ederheim;;86739;09081;Bayern
Edermünde;;34295;05665;Hessen
Edersleben;;06528;03464;Sachsen-Anhalt
Edertal;;34549;05623;Hessen
Edesheim;, Pfalz;67483;06323;Rheinland-Pfalz
Edewecht;;26188;04405;Niedersachsen
Ediger-Eller;;56814;02675;Rheinland-Pfalz
Edingen-Neckarhausen;;68535;06203;Baden-Württemberg
Edlau;b Könnern;06420;034691;Sachsen-Anhalt
Edling;b Wasserburg a Inn;83533;08071;Bayern
Effelder;b Leinefelde;37359;036075;Thüringen
Effelder;b Sonneberg, Thür;96528;036766;Thüringen
Effeltrich;;91090;09133;Bayern
Efringen-Kirchen;;79588;07628;Baden-Württemberg
Egelinde;;14806;033847;Brandenburg
Egeln;;39435;039268;Sachsen-Anhalt
Egelsbach;, Hess;63329;06103;Hessen
Egenhausen;, Württ;72227;07453;Baden-Württemberg
Egenhofen;, Kr Fürstenfeldbruck;82281;08145;Bayern
Egerdach;;83379;08681;Bayern
Egesheim;;78592;07429;Baden-Württemberg
Egestorf;, Nordheide;21272;04175;Niedersachsen
Egg;a d Günz;87743;08333;Bayern
Eggebek;;24852;04609;Schlewig-Holstein
Eggenfelden;;84307;08721;Bayern
Eggenstedt;;39365;039407;Sachsen-Anhalt
Eggenstein-Leopoldshafen;;76344;0721;Baden-Württemberg
Eggenthal;, Schwab;87653;08347;Bayern
Eggerdach;;83119;08075;Bayern
Eggerkamp;;21734;04753;Niedersachsen
Eggermühlen;;49577;05462;Niedersachsen
Eggersdorf;b Strausberg;15345;033439;Brandenburg
Eggersdorf;b Fürstenwalde, Spree;15518;033432;Brandenburg
Eggersdorf;b Schönebeck, Elbe;39221;03928;Sachsen-Anhalt
Eggersdorf Siedlung;;15374;033432;Brandenburg
Eggesin;;17367;039779;Mecklenburg-Vorpommern
Eggingen;, Kr Waldshut;79805;07746;Baden-Württemberg
Egglham;b Aidenbach, Niederbay;84385;08543;Bayern
Egglkofen;;84546;08639;Bayern
Eggolsheim;;91330;09545;Bayern
Eggstätt;, Kr Rosenheim, Oberbay;83125;08056;Bayern
Eggstedt;, Holst;25721;04830;Schlewig-Holstein
Eging;a. See;94535;08544;Bayern
Eglfing;, Oberbay;82436;08847;Bayern
Egling;b Wolfratshausen;82544;08176;Bayern
Egling;a d Paar;86492;08206;Bayern
Egloffstein;;91349;09197;Bayern
Eglsee;b Waging a See;83329;08681;Bayern
Egmating;;85658;08095;Bayern
Egonmühle;;75059;;Baden-Württemberg
Egsdorf;b Königs Wusterhausen;15755;033766;Brandenburg
Egsdorf;, NL;15926;03544;Brandenburg
Egstedt a Steiger;;99102;0361;Thüringen
Egstedt a Steiger;;99102;0361;Thüringen
Egweil;;85116;08424;Bayern
Ehbruch;;18211;;Mecklenburg-Vorpommern
Ehekirchen;, Oberbay;86676;08435;Bayern
Ehgarten;b Wörnsmühl;83739;08025;Bayern
Ehingen;b Wertingen;86678;08273;Bayern
Ehingen;a. Ries;86741;09082;Bayern
Ehingen;(Donau);89584;07391;Baden-Württemberg
Ehingen;, Mittelfr;91725;09835;Bayern
Ehlenz;;54636;06569;Rheinland-Pfalz
Ehlscheid;;56581;02634;Rheinland-Pfalz
Ehndorf;;24647;04321;Schlewig-Holstein
Ehningen;, Kr Böblingen;71139;07034;Baden-Württemberg
Ehr;b Nastätten;56357;06776;Rheinland-Pfalz
Ehra-Lessien;;38468;05377;Niedersachsen
Ehrenberg;(Rhön);36115;06683;Hessen
Ehrenberg;b Hildburghausen;98660;036873;Thüringen
Ehrenburg;b Sulingen;27248;04275;Niedersachsen
Ehrenfriedersdorf;;09427;037341;Sachsen
Ehrenkirchen;;79238;07633;Baden-Württemberg
Ehrensberg;b Ilmmünster;85304;;Bayern
Ehrenstein;b Stadtilm;99326;03629;Thüringen
Ehringshausen;, Dill;35630;06443;Hessen
Ehweiler;;66871;06384;Rheinland-Pfalz
Eibau;;02739;03586;Sachsen
Eibau-Neueibau;;02739;03586;Sachsen
Eibau-Walddorf;;02739;03586;Sachsen
Eibelstadt;;97246;09303;Bayern
Eibenhof;;73642;07182;Baden-Württemberg
Eibenstock;;08309;037752;Sachsen
Eich;, Sachs;08233;037468;Sachsen
Eich;, Rheinhess;67575;06246;Rheinland-Pfalz
Eichbichl;b Frauenneuharting;83565;08092;Bayern
Eiche;b Bernau b Berlin;16356;030;Brandenburg
Eichelborn;;99198;036209;Thüringen
Eichelhardt;;57612;02681;Rheinland-Pfalz
Eichen;, Kr Altenkirchen, Westerwald;57632;02685;Rheinland-Pfalz
Eichenau;b München;82223;08141;Bayern
Eichenbach;;53533;02694;Rheinland-Pfalz
Eichenbachermühle;;67680;06303;Rheinland-Pfalz
Eichenbarleben;;39167;039206;Sachsen-Anhalt
Eichenberg;b Jena;07768;036423;Thüringen
Eichenberg;b Suhl;98553;036873;Thüringen
Eichenbühl;, Unterfr;63928;09371;Bayern
Eichendorf;, Niederbay;94428;09952;Bayern
Eichenzell;;36124;06659;Hessen
Eichholz-Drößig;;03238;03531;Brandenburg
Eichhorst;b Eberswalde;16244;033363;Brandenburg
Eichhorst;b Neubrandenburg;17099;039606;Mecklenburg-Vorpommern
Eichigt;;08626;037430;Sachsen
Eichstädt;;16727;03304;Brandenburg
Eichstätt;, Bay;85072;08421;Bayern
Eichstedt;(Altmark);39596;039388;Sachsen-Anhalt
Eichstegen;;88361;07584;Baden-Württemberg
Eichstetten;am Kaiserstuhl;79356;07663;Baden-Württemberg
Eichstruth;;37318;036087;Thüringen
Eichwalde;;15732;030;Brandenburg
Eickelberg;;18249;038462;Mecklenburg-Vorpommern
Eickeloh;;29693;05164;Niedersachsen
Eickendorf;b Schönebeck, Elbe;39221;039297;Sachsen-Anhalt
Eickendorf;b Haldensleben;39359;039057;Sachsen-Anhalt
Eickhof;b Bützow;18249;038462;Mecklenburg-Vorpommern
Eickhof;b Ribnitz-Damgarten;18320;038225;Mecklenburg-Vorpommern
Eicklingen;;29358;05144;Niedersachsen
Eickstedt;;17291;039857;Brandenburg
Eigeltingen;;78253;07774;Baden-Württemberg
Eigenrode;;99976;036023;Thüringen
Eilenburg;;04838;03423;Sachsen
Eilenstedt;;38838;039425;Sachsen-Anhalt
Eilscheid;;54649;06554;Rheinland-Pfalz
Eilsdorf;b Halberstadt;38838;039425;Sachsen-Anhalt
Eilsleben;;39365;039409;Sachsen-Anhalt
Eime;;31036;05182;Niedersachsen
Eimeldingen;;79591;07621;Baden-Württemberg
Eimen;;37632;05565;Niedersachsen
Eimersleben;;39343;039052;Sachsen-Anhalt
Eimke;;29578;05873;Niedersachsen
Eimsheim;;55278;06249;Rheinland-Pfalz
Einbeck;;37574;05561;Niedersachsen
Eineborn;;07646;036426;Thüringen
Einemhof;;21444;;Niedersachsen
Einhaus;b Ratzeburg;23911;04541;Schlewig-Holstein
Einhausen;, Hess;64683;06251;Hessen
Einhausen;b Meiningen;98617;036949;Thüringen
Einhusen;b Bad Doberan;18236;038292;Mecklenburg-Vorpommern
Einig;;56751;02654;Rheinland-Pfalz
Einkorn;;74523;0791;Baden-Württemberg
Einöllen;;67753;06304;Rheinland-Pfalz
Einsbach;, Kr Dachau;85254;08135;Bayern
Einselthum;;67308;06355;Rheinland-Pfalz
Eisdorf;, Harz;37534;05522;Niedersachsen
Eiselfing;;83549;08071;Bayern
Eisenach;b Trier;54298;06506;Rheinland-Pfalz
Eisenach;, Thür;99817;03691;Thüringen
Eisenbach;(Hochschwarzwald);79871;07657;Baden-Württemberg
Eisenberg;, Thür;07607;036691;Thüringen
Eisenberg;(Pfalz);67304;06351;Rheinland-Pfalz
Eisenberg;, Allgäu;87637;08364;Bayern
Eisendorf;, Holst;24589;04392;Schlewig-Holstein
Eisenhammer;b Neustadt, Orla;07806;;Thüringen
Eisenheim;, Unterfr;97247;09386;Bayern
Eisenhüttenstadt;;15890;03364;Brandenburg
Eisenschmelz;b Trippstadt;67705;;Rheinland-Pfalz
Eisenschmitt;;54533;06567;Rheinland-Pfalz
Eiserhof;;61203;;Hessen
Eisfeld;;98673;03686;Thüringen
Eishausen;;98646;03685;Thüringen
Eisighofen;;56370;06486;Rheinland-Pfalz
Eisingen;, Baden;75239;07232;Baden-Württemberg
Eisingen;, Kr Würzburg;97249;09306;Bayern
Eislingen;, Fils;73054;07161;Baden-Württemberg
Eitelborn;;56337;02620;Rheinland-Pfalz
Eitensheim;;85117;08458;Bayern
Eiterfeld;;36132;06672;Hessen
Eitleshof;;73037;07161;Baden-Württemberg
Eitorf;;53783;02243;Nordrhein-Westfalen
Eitting;, Kr Erding;85462;08122;Bayern
Eixen;;18334;038222;Mecklenburg-Vorpommern
Ekebergkrug;, Gem Uelsby;24891;04623;Schlewig-Holstein
Ekenis;;24392;04641;Schlewig-Holstein
Elbe;;38274;05345;Niedersachsen
Elben;, Westerw;57580;02747;Rheinland-Pfalz
Elbenschwand;;79692;07629;Baden-Württemberg
Elbingen;, Westerw;56459;06435;Rheinland-Pfalz
Elbingerode;, Niedersachs;37412;05521;Niedersachsen
Elbingerode;(Harz);38875;039454;Sachsen-Anhalt
Elbisbach;;04651;034345;Sachsen
Elbrechting;;83546;08073;Bayern
Elbtal;, Westerw;65627;06436;Hessen
Elchesheim-Illingen;;76477;07245;Baden-Württemberg
Elchingen;, Donau;89275;07308;Bayern
Elchweiler;;55765;06782;Rheinland-Pfalz
Eldena;a d Elde;19294;038755;Mecklenburg-Vorpommern
Eldenburg;;19309;03991;Brandenburg
Eldingen;;29351;05148;Niedersachsen
Elend;, Harz;38875;039455;Sachsen-Anhalt
Elende;;99759;036338;Thüringen
Elfershausen;, Unterfr;97725;09704;Bayern
Elgersburg;;98716;03677;Thüringen
Eliasbrunn;;07356;036651;Thüringen
Elisabethhöhe;;14542;03327;Brandenburg
Elisabeth-Sophien-Koog;;25845;04842;Schlewig-Holstein
Elkenroth;;57578;02747;Rheinland-Pfalz
Elleben;;99334;036200;Thüringen
Ellefeld;;08236;03745;Sachsen
Ellenberg;, Altmark;29413;039033;Sachsen-Anhalt
Ellenberg;b Birkenfeld, Nahe;55765;06782;Rheinland-Pfalz
Ellenberg;, Württ;73488;07962;Baden-Württemberg
Ellenhausen;;56242;02626;Rheinland-Pfalz
Ellenz-Poltersdorf;;56821;02673;Rheinland-Pfalz
Ellerau;, Holst;25479;04106;Schlewig-Holstein
Ellerbek;, Kr Pinneberg;25474;040;Schlewig-Holstein
Ellerdorf;;24589;04392;Schlewig-Holstein
Ellerhoop;;25373;04120;Schlewig-Holstein
Ellern;(Hunsrück);55497;06764;Rheinland-Pfalz
Ellershagen;;16928;033989;Brandenburg
Ellersleben;;99628;036372;Thüringen
Ellerstadt;;67158;06237;Rheinland-Pfalz
Ellgau;;86679;08273;Bayern
Ellhofen;, Württ;74248;07134;Baden-Württemberg
Ellhöft;;25923;04663;Schlewig-Holstein
Ellingen;, Bay;91792;09141;Bayern
Ellingshausen;b Meiningen;98617;036949;Thüringen
Ellingstedt;;24870;04627;Schlewig-Holstein
Ellrich;;99755;036332;Thüringen
Ellrichsbronn;;73485;07966;Baden-Württemberg
Ellscheid;;54552;06573;Rheinland-Pfalz
Ellwangen;(Jagst);73479;07961;Baden-Württemberg
Ellweiler;;55765;06782;Rheinland-Pfalz
Ellzee;;89352;08223;Bayern
Elmau;;82493;08823;Bayern
Elmenhorst;, Vorpommern;18510;038327;Mecklenburg-Vorpommern
Elmenhorst;, Kr Hzgt Lauenb;21493;04156;Schlewig-Holstein
Elmenhorst;, Kr Stormarn;23869;04532;Schlewig-Holstein
Elmenhorst;b Grevesmühlen;23948;038825;Mecklenburg-Vorpommern
Elmenhorst/Lichtenhagen;;18107;0381;Mecklenburg-Vorpommern
Elmlohe;;27624;04704;Niedersachsen
Elmshorn;;25335;04121;Schlewig-Holstein
Elmshorn;;25336;04121;Schlewig-Holstein
Elmshorn;;25337;04121;Schlewig-Holstein
Elmstein;;67471;06328;Rheinland-Pfalz
Elpersbüttel;;25704;04832;Schlewig-Holstein
Elschbacherhof;;66892;06372;Rheinland-Pfalz
Elsdorf;b Köthen, Anh;06369;03496;Sachsen-Anhalt
Elsdorf;, Niedersachs;27404;0421;Niedersachsen
Elsdorf;, Rheinl;50189;02274;Nordrhein-Westfalen
Elsdorf-Westermühlen;;24800;04332;Schlewig-Holstein
Elsendorf;i d Hallertau;84094;08753;Bayern
Elsenfeld;;63820;06022;Bayern
Elsfleth;;26931;04404;Niedersachsen
Elsholz;;14547;033204;Brandenburg
Elskop;;25361;04824;Schlewig-Holstein
Elslaake;;14715;033872;Brandenburg
Elsnig;;04880;034223;Sachsen
Elsnigk;;06386;034973;Sachsen-Anhalt
Elsoff;(Westerwald);56479;02664;Rheinland-Pfalz
Elstal;;14627;033234;Brandenburg
Elster;(Elbe);06918;035383;Sachsen-Anhalt
Elsterberg;, Vogtl;07985;036621;Sachsen
Elsterheide;;02979;;Sachsen
Elstertrebnitz;;04523;034296;Sachsen
Elsterwerda;;04910;03533;Brandenburg
Elstra;;01920;035793;Sachsen
Elterlein;;09481;037349;Sachsen
Eltmann;;97483;09522;Bayern
Eltville;am Rhein;65343;06123;Hessen
Eltville;am Rhein;65344;06123;Hessen
Eltville;am Rhein;65345;06123;Hessen
Eltville;am Rhein;65346;06123;Hessen
Eltville;am Rhein;65347;06123;Hessen
Elxleben;a d Gera;99189;036200;Thüringen
Elxleben;b Arnstadt;99334;036200;Thüringen
Elz;, Westerw;65604;06431;Hessen
Elzach;;79215;07682;Baden-Württemberg
Elze;, Leine;31008;05068;Niedersachsen
Elztal;;74834;06261;Baden-Württemberg
Elzweiler;;66887;06387;Rheinland-Pfalz
Embsen;, Kr Lüneburg;21409;04134;Niedersachsen
Embühren;;24819;04875;Schlewig-Holstein
Emden;, Ostfriesland;26721;04921;Niedersachsen
Emden;, Ostfriesland;26723;04921;Niedersachsen
Emden;, Ostfriesland;26725;04921;Niedersachsen
Emden;b Haldensleben;39343;039052;Sachsen-Anhalt
Emeringen;;88499;07373;Baden-Württemberg
Emerkingen;;89607;07393;Baden-Württemberg
Emersacker;;86494;08293;Bayern
Emersleben;;38822;039424;Sachsen-Anhalt
Emishalden;;88430;07568;Baden-Württemberg
Emkendorf;b Rendsburg;24802;04330;Schlewig-Holstein
Emleben;;99869;03621;Thüringen
Emlichheim;;49824;05943;Niedersachsen
Emmelbaum;;54673;06564;Rheinland-Pfalz
Emmelsbüll-Horsbüll;;25924;04665;Schlewig-Holstein
Emmelshausen;;56281;06747;Rheinland-Pfalz
Emmendingen;;79312;07641;Baden-Württemberg
Emmendorf;;29579;05875;Niedersachsen
Emmerich;am Rhein;46446;02822;Nordrhein-Westfalen
Emmering;, Kr Fürstenfeldbruck;82275;08141;Bayern
Emmering;b Wasserburg a Inn;83550;08039;Bayern
Emmerthal;, Niedersachs;31860;05155;Niedersachsen
Emmerting;;84547;08679;Bayern
Emmerzhausen;;57520;02743;Rheinland-Pfalz
Emmingen-Liptingen;;78576;07465;Baden-Württemberg
Empfertshausen;;36452;036964;Thüringen
Empfingen;;72186;07485;Baden-Württemberg
Emsbüren;;48488;05903;Niedersachsen
Emsdetten;;48282;02572;Nordrhein-Westfalen
Emseloh;;06528;034659;Sachsen-Anhalt
Emskirchen;;91448;09104;Bayern
Emstal;b Brandenburg an der Havel;14797;03382;Brandenburg
Emstek;;49685;04473;Niedersachsen
Emtinghausen;;27321;04295;Niedersachsen
Emtmannsberg;;95517;09209;Bayern
Endingen;am Kaiserstuhl;79346;07642;Baden-Württemberg
Endlichhofen;;56355;06772;Rheinland-Pfalz
Endorf;am Harz;06333;0391;Sachsen-Anhalt
Endschütz;;07570;036603;Thüringen
Engden;;48465;05926;Niedersachsen
Engedey;;83471;08652;Bayern
Engelbrechtsche Wildnis;;25348;04124;Schlewig-Holstein
Engelhof;b Lenningen, Württ;73266;07023;Baden-Württemberg
Engeln;b Bruchhausen-Vilsen;27305;04247;Niedersachsen
Engelsbach;;99898;03623;Thüringen
Engelsberg;, Eifel;54538;06532;Rheinland-Pfalz
Engelsberg;, Oberbay;84549;08634;Bayern
Engelsbrand;;75331;07082;Baden-Württemberg
Engelschoff;;21710;04144;Niedersachsen
Engelskirchen;;51766;02263;Nordrhein-Westfalen
Engelstadt;;55270;06130;Rheinland-Pfalz
Engelthal;, Mittelfr;91238;09158;Bayern
Engen;, Hegau;78234;07733;Baden-Württemberg
Enger;, Westf;32130;05224;Nordrhein-Westfalen
Engerda;;07407;036743;Thüringen
Engersen;;39638;039085;Sachsen-Anhalt
Enge-Sande;;25917;04662;Schlewig-Holstein
Englertshofen;;82282;08145;Bayern
Englstetten;;83552;08075;Bayern
Engstingen;;72829;07129;Baden-Württemberg
Eningen;unter Achalm;72800;07121;Baden-Württemberg
Enkenbach-Alsenborn;;67677;06303;Rheinland-Pfalz
Enkirch;;56850;06541;Rheinland-Pfalz
Enklarn;;93437;09973;Bayern
Ennepetal;;58256;02333;Nordrhein-Westfalen
Ennepetal;;58256;02333;Nordrhein-Westfalen
Ennigerloh;;59320;02524;Nordrhein-Westfalen
Ensch;;54340;06507;Rheinland-Pfalz
Ensdorf;, Saar;66806;06831;Saarland
Ensdorf;, Gem Gars a Inn;83546;08073;Bayern
Ensdorf;, Oberpf;92266;09624;Bayern
Ense;;59469;02938;Nordrhein-Westfalen
Ensheim;, Rheinhess;55232;06732;Rheinland-Pfalz
Enspel;;57647;02661;Rheinland-Pfalz
Entrischenbrunn;;85307;08444;Bayern
Enzen;, Eifel;54646;06522;Rheinland-Pfalz
Enzersdorf;b Waging a See;83329;08681;Bayern
Enzklösterle;;75337;07085;Baden-Württemberg
Epenwöhrden;;25704;04832;Schlewig-Holstein
Epfach;, Gem Denklingen;86920;08869;Bayern
Epfenbach;;74925;07263;Baden-Württemberg
Epfendorf;;78736;07404;Baden-Württemberg
Eppelborn;;66571;06827;Saarland
Eppelheim;, Baden;69214;06221;Baden-Württemberg
Eppelsheim;, Rheinhess;55234;06735;Rheinland-Pfalz
Eppenberg;, Eifel;56759;02653;Rheinland-Pfalz
Eppenbrunn;;66957;06335;Rheinland-Pfalz
Eppendorf;, Sachs;09575;037293;Sachsen
Eppenrod;;65558;06485;Rheinland-Pfalz
Eppenschlag;;94536;09928;Bayern
Eppertshausen;;64859;06071;Hessen
Eppingen;;75031;07262;Baden-Württemberg
Eppishausen;;87745;08266;Bayern
Eppstein;, Taunus;65817;06198;Hessen
Erbach;, Hunsrück;55494;06764;Rheinland-Pfalz
Erbach;, Odenw;64711;06062;Hessen
Erbach;, Donau;89155;07305;Baden-Württemberg
Erbendorf;;92681;09682;Bayern
Erbenhausen;b Meiningen;98634;036966;Thüringen
Erbes-Büdesheim;;55234;06731;Rheinland-Pfalz
Erbmühle;;92345;08460;Bayern
Erdeborn;;06317;034774;Sachsen-Anhalt
Erden;;54492;06532;Rheinland-Pfalz
Erdesbach;;66887;06381;Rheinland-Pfalz
Erdfunkstelle Usingen;;61250;;Hessen
Erding;;85435;08122;Bayern
Erdmannhausen;;71729;07144;Baden-Württemberg
Erdweg;;85253;08206;Bayern
Eresing;;86922;08193;Bayern
Erfde;;24803;04333;Schlewig-Holstein
Erfderdamm;, Gem Süderstapel;25868;;Schlewig-Holstein
Erfenstein;;67466;06325;Rheinland-Pfalz
Erftstadt;;50374;02235;Nordrhein-Westfalen
Erfurt;;99084;0361;Thüringen
Erfurt;;99085;0361;Thüringen
Erfurt;;99086;0361;Thüringen
Erfurt;;99087;0361;Thüringen
Erfurt;;99089;0361;Thüringen
Erfurt;;99091;0361;Thüringen
Erfurt;;99092;0361;Thüringen
Erfurt;;99094;0361;Thüringen
Erfurt;;99096;0361;Thüringen
Erfurt;;99097;0361;Thüringen
Erfurt;;99098;0361;Thüringen
Erfurt;;99099;0361;Thüringen
Erfweiler;;66996;06391;Rheinland-Pfalz
Ergersheim;;91465;09847;Bayern
Ergeshausen;;56368;06486;Rheinland-Pfalz
Ergolding;;84030;08784;Bayern
Ergoldsbach;;84061;08771;Bayern
Erharting;;84513;08631;Bayern
Ering;;94140;08573;Bayern
Eriskirch;;88097;07541;Baden-Württemberg
Erkelenz;;41812;02431;Nordrhein-Westfalen
Erkelshäuserhof;;66851;06307;Rheinland-Pfalz
Erkenbrechtsweiler;;73268;07026;Baden-Württemberg
Erkerode;;38173;05305;Niedersachsen
Erkheim;;87746;08336;Bayern
Erkner;;15537;03362;Brandenburg
Erkrath;;40699;0211;Nordrhein-Westfalen
Erlabrunn;, Erzgeb;08349;03773;Sachsen
Erlabrunn;, Unterfr;97250;09364;Bayern
Erlachskirchen;;91469;09101;Bayern
Erlangen;;91052;09131;Bayern
Erlangen;;91054;09131;Bayern
Erlangen;;91056;09131;Bayern
Erlangen;;91058;09131;Bayern
Erlau;b Rochlitz;09306;03727;Sachsen
Erlau;b Suhl;98553;036841;Thüringen
Erlbach;, Vogtl;08265;037422;Sachsen
Erlbach;b Rochlitz;09326;037382;Sachsen
Erlbach;, Kr Altötting;84567;08670;Bayern
Erlbach-Kirchberg;;09385;037295;Sachsen
Erlenbach;a. Main;63906;09372;Bayern
Erlenbach;, Kr Heilbronn, Neckar;74235;07132;Baden-Württemberg
Erlenbach;bei Kandel;76872;07275;Rheinland-Pfalz
Erlenbach;bei Dahn;76891;06398;Rheinland-Pfalz
Erlenbach;b. Marktheidenfeld;97837;09391;Bayern
Erlenbacher Forsthaus;;67468;;Rheinland-Pfalz
Erlenborn;b Dachsenhausen;56340;06776;Rheinland-Pfalz
Erlenfurt;;97840;09352;Bayern
Erlenhof;b Wernau, Neckar;73249;;Baden-Württemberg
Erlenmoos;;88416;07352;Baden-Württemberg
Erlenmühle;b Landstuhl;66917;06375;Rheinland-Pfalz
Erlenmühle;, Württ;78554;;Baden-Württemberg
Erlensee;;63526;06183;Hessen
Erlenwasen;;73087;;Baden-Württemberg
Erlhof;b Rathsmannsdorf;94565;08544;Bayern
Erligheim;;74391;07143;Baden-Württemberg
Ermershausen;, Unterfr;96126;09532;Bayern
Ermke;, Gem Molbergen;49696;04475;Niedersachsen
Ermlitz;;06184;034204;Sachsen-Anhalt
Ermsleben;;06463;034743;Sachsen-Anhalt
Ermstedt;;99192;036208;Thüringen
Ermstedt;;99192;036208;Thüringen
Erndtebrück;;57339;02753;Nordrhein-Westfalen
Ernsgaden;;85119;08452;Bayern
Ernst;b Cochem;56814;02671;Rheinland-Pfalz
Ernstroda;;99894;03623;Thüringen
Ernstthal;;98724;036702;Thüringen
Ernzen;;54668;06525;Rheinland-Pfalz
Erolzheim;, Kr Biberach an der Riß;88453;07354;Baden-Württemberg
Erpel;, Rhein;53579;02644;Rheinland-Pfalz
Erpolzheim;b Ludwigshafen am Rhein;67167;06353;Rheinland-Pfalz
Ersfeld;;57635;02686;Rheinland-Pfalz
Ertingen;;88521;07371;Baden-Württemberg
Erwitte;;59597;02943;Nordrhein-Westfalen
Erxleben;b Haldensleben;39343;039052;Sachsen-Anhalt
Erxleben;b Osterburg;39606;03937;Sachsen-Anhalt
Erzenhausen;;67685;06374;Rheinland-Pfalz
Erzhausen;, Hess;64390;06150;Hessen
Esch;b Wittlich;54518;06508;Rheinland-Pfalz
Esch;b Gerolstein;54585;06597;Rheinland-Pfalz
Eschach;b Schwäbisch Gmünd;73569;07175;Baden-Württemberg

Eschau;, Unterfr;63863;09374;Bayern
Eschbach;b Nastätten;56357;06771;Rheinland-Pfalz
Eschbach;, Pfalz;76831;06345;Rheinland-Pfalz
Eschbach;, Markgräflerl;79427;07634;Baden-Württemberg
Eschborn;, Taunus;65760;06196;Hessen
Eschbronn;, Württ;78664;07403;Baden-Württemberg
Eschdorf;b Rudolstadt;07407;036744;Thüringen
Esche;;49828;05941;Niedersachsen
Escheburg;;21039;04152;Schlewig-Holstein
Eschede;;29348;05142;Niedersachsen
Eschelbronn;;74927;06226;Baden-Württemberg
Eschenbach;, Württ;73107;07161;Baden-Württemberg
Eschenbach;, Oberpf;92676;09645;Bayern
Eschenbergen;;99869;036258;Thüringen
Eschenburg;;35713;02770;Hessen
Eschenlohe;, Loisach;82438;08824;Bayern
Eschenlohe;, Gem Frauenneuharting;83565;08092;Bayern
Eschenrode;;39356;039055;Sachsen-Anhalt
Eschenthal;;96523;036762;Thüringen
Eschershausen;, Ith;37632;05534;Niedersachsen
Eschfeld;;54619;06559;Rheinland-Pfalz
Eschlhof;;93449;09978;Bayern
Eschlkam;;93458;09948;Bayern
Eschwege;;37269;05651;Hessen
Eschweiler;, Rheinl;52249;02403;Nordrhein-Westfalen
Eselhöfe;;73349;07335;Baden-Württemberg
Esens;;26427;04971;Niedersachsen
Esgrus;;24402;04637;Schlewig-Holstein
Eslarn;;92693;09653;Bayern
Eslingholz;b Struxdorf;24891;04646;Schlewig-Holstein
Eslohe;(Sauerland);59889;02973;Nordrhein-Westfalen
Espelkamp;;32339;05772;Nordrhein-Westfalen
Espenau;;34314;05673;Hessen
Espenhain;;04579;03433;Sachsen
Esperstedt;b Querfurt;06279;034774;Sachsen-Anhalt
Esperstedt;b Artern;06567;034671;Thüringen
Eßbach;;07924;036483;Thüringen
Eßbaum;b Evenhausen, Oberbay;83552;08032;Bayern
Essel;, Aller;29690;05071;Niedersachsen
Esselbach;;97839;09394;Bayern
Esselborn;;55234;06731;Rheinland-Pfalz
Essen;, Ruhr;45127;0201;Nordrhein-Westfalen
Essen;, Ruhr;45128;0201;Nordrhein-Westfalen
Essen;, Ruhr;45130;0201;Nordrhein-Westfalen
Essen;, Ruhr;45131;0201;Nordrhein-Westfalen
Essen;, Ruhr;45133;0201;Nordrhein-Westfalen
Essen;, Ruhr;45134;0201;Nordrhein-Westfalen
Essen;, Ruhr;45136;0201;Nordrhein-Westfalen
Essen;, Ruhr;45138;0201;Nordrhein-Westfalen
Essen;, Ruhr;45139;0201;Nordrhein-Westfalen
Essen;, Ruhr;45141;0201;Nordrhein-Westfalen
Essen;, Ruhr;45143;0201;Nordrhein-Westfalen
Essen;, Ruhr;45144;0201;Nordrhein-Westfalen
Essen;, Ruhr;45145;0201;Nordrhein-Westfalen
Essen;, Ruhr;45147;0201;Nordrhein-Westfalen
Essen;, Ruhr;45149;0201;Nordrhein-Westfalen
Essen;, Ruhr;45219;0201;Nordrhein-Westfalen
Essen;, Ruhr;45239;0201;Nordrhein-Westfalen
Essen;, Ruhr;45257;0201;Nordrhein-Westfalen
Essen;, Ruhr;45259;0201;Nordrhein-Westfalen
Essen;, Ruhr;45276;0201;Nordrhein-Westfalen
Essen;, Ruhr;45277;0201;Nordrhein-Westfalen
Essen;, Ruhr;45279;0201;Nordrhein-Westfalen
Essen;, Ruhr;45289;0201;Nordrhein-Westfalen
Essen;, Ruhr;45307;0201;Nordrhein-Westfalen
Essen;, Ruhr;45309;0201;Nordrhein-Westfalen
Essen;, Ruhr;45326;0201;Nordrhein-Westfalen
Essen;, Ruhr;45327;0201;Nordrhein-Westfalen
Essen;, Ruhr;45329;0201;Nordrhein-Westfalen
Essen;, Ruhr;45355;0201;Nordrhein-Westfalen
Essen;, Ruhr;45356;0201;Nordrhein-Westfalen
Essen;, Ruhr;45357;0201;Nordrhein-Westfalen
Essen;, Ruhr;45359;0201;Nordrhein-Westfalen
Essen;(Oldenburg);49632;05434;Niedersachsen
Essenbach;, Niederbay;84051;08703;Bayern
Essenheim;;55270;06136;Rheinland-Pfalz
Essing;;93343;09447;Bayern
Essingen;, Württ;73457;07365;Baden-Württemberg
Essingen;, Pfalz;76879;06347;Rheinland-Pfalz
Eßleben;b Buttstädt;99628;036373;Thüringen
Eßlingen;, Eifel;54636;06568;Rheinland-Pfalz
Esslingen;am Neckar;73728;0711;Baden-Württemberg
Esslingen;am Neckar;73730;0711;Baden-Württemberg
Esslingen;am Neckar;73732;0711;Baden-Württemberg
Esslingen;am Neckar;73733;0711;Baden-Württemberg
Esslingen;am Neckar;73734;0711;Baden-Württemberg
Eßweiler;;67754;06304;Rheinland-Pfalz
Estedt;;39638;03907;Sachsen-Anhalt
Estenfeld;;97230;09305;Bayern
Esterwegen;;26897;05955;Niedersachsen
Esthal;;67472;06325;Rheinland-Pfalz
Estorf;, Kr Stade;21727;04140;Niedersachsen
Estorf;, Weser;31629;05025;Niedersachsen
Etgersleben;;39448;039268;Sachsen-Anhalt
Etgert;;54424;06504;Rheinland-Pfalz
Etingen;;39359;039059;Sachsen-Anhalt
Etschberg;;66871;06381;Rheinland-Pfalz
Ettal;;82488;08822;Bayern
Etteldorf;;54655;06563;Rheinland-Pfalz
Ettendorf;;83359;0861;Bayern
Ettenhausen a d Nesse;;99819;036920;Thüringen
Ettenhausen a d Suhl;;99819;036925;Thüringen
Ettenheim;;77955;07822;Baden-Württemberg
Ettenstatt;;91796;09148;Bayern
Ettersburg;;99439;03643;Thüringen
Ettinghausen;;56244;06435;Rheinland-Pfalz
Ettlingen;;76275;07243;Baden-Württemberg
Ettringen;, Eifel;56729;02651;Rheinland-Pfalz
Ettringen;, Wertach;86833;08249;Bayern
Etzbach;;57539;02682;Rheinland-Pfalz
Etzelsrode;;99735;036337;Thüringen
Etzelwang;;92268;09663;Bayern
Etzenbachermühle;;66894;06337;Rheinland-Pfalz
Etzenricht;;92694;0961;Bayern
Etzin;;14641;033233;Brandenburg
Etzleben;;06577;034673;Thüringen
Etzoldshain;b Zeitz, Elster;06729;034424;Sachsen-Anhalt
Euerbach;;97502;09726;Bayern
Euerdorf;;97717;09704;Bayern
Eulau;;06618;03445;Sachsen-Anhalt
Eulenberg;, Westerw;57632;02685;Rheinland-Pfalz
Eulenbis;;67685;06374;Rheinland-Pfalz
Eulenhof;b Plüderhausen;73655;;Baden-Württemberg
Eulenkrug;b Schönberg, Meckl;23923;;Mecklenburg-Vorpommern
Eulenkrug;b Grevesmühlen;23948;038825;Mecklenburg-Vorpommern
Eulenmühle;b Jagstzell;74597;07967;Baden-Württemberg
Eulgem;;56761;02653;Rheinland-Pfalz
Eulowitz;;02692;035938;Sachsen
Eurasburg;b Wolfratshausen;82547;08179;Bayern
Eurasburg;b Friedberg, Bay;86495;08208;Bayern
Eurastetten;;82282;08145;Bayern
Euscheid;;54597;06556;Rheinland-Pfalz
Euskirchen;;53879;02251;Nordrhein-Westfalen
Euskirchen;;53881;02251;Nordrhein-Westfalen
Eußenheim;;97776;09353;Bayern
Eußerthal;;76857;06345;Rheinland-Pfalz
Euteneuen;;57555;02741;Rheinland-Pfalz
Eutin;;23701;04521;Schlewig-Holstein
Eutingen;im Gäu;72184;07459;Baden-Württemberg
Eutzsch;;06888;034921;Sachsen-Anhalt
Evenhausen;, Oberbay;83552;08075;Bayern
Everingen;;39359;039057;Sachsen-Anhalt
Everode;;31085;05184;Niedersachsen
Eversdorf;b Salzwedel;29416;03901;Sachsen-Anhalt
Evershorst;b Diepholz;49459;05447;Niedersachsen
Eversmeer;;26556;04975;Niedersachsen
Everstorf;;23936;03881;Mecklenburg-Vorpommern
Everswinkel;;48351;02582;Nordrhein-Westfalen
Evessen;;38173;05333;Niedersachsen
Ewighausen;;56244;02666;Rheinland-Pfalz
Exdorf;;98631;036947;Thüringen
Extertal;;32699;05262;Nordrhein-Westfalen
Eyba;;07318;036736;Thüringen
Eydelstedt;;49406;05442;Niedersachsen
Eyendorf;, Lüneburger Heide;21376;0451;Niedersachsen
Eyrain;;83739;08064;Bayern
Eystrup;;27324;04254;Niedersachsen
Fach;;73453;07975;Baden-Württemberg
Fachbach;;56133;02603;Rheinland-Pfalz
Fachingen;;65626;06432;Rheinland-Pfalz
Fahlhorst;;14532;033200;Brandenburg
Fahrbinde;;19288;038753;Mecklenburg-Vorpommern
Fahrdorf;;24857;04621;Schlewig-Holstein
Fahren;b Wismar, Meckl;23992;038422;Mecklenburg-Vorpommern
Fahren;, Holst;24253;04344;Schlewig-Holstein
Fahrenbach;, Baden;74864;06267;Baden-Württemberg
Fahrendorf;, Kr Hzgt Lauenb;21039;04152;Schlewig-Holstein
Fahrenhorst;b Gettorf;24214;0431;Schlewig-Holstein
Fahrenkamp;b Barth;18356;;Mecklenburg-Vorpommern
Fahrenkrug;;23795;04551;Schlewig-Holstein
Fahrenwalde;;17309;039747;Mecklenburg-Vorpommern
Fahrenzhausen;;85777;08133;Bayern
Fahrland;;14476;033208;Brandenburg
Fahrnpoint;;83250;08641;Bayern
Faid;;56814;02671;Rheinland-Pfalz
Falken;b Eisenach, Thür;99830;036923;Thüringen
Falkenau;, Sachs;09569;03726;Sachsen
Falkenauel;;54673;06550;Rheinland-Pfalz
Falkenbach;b Wolkenstein, Sachs;09429;03735;Sachsen
Falkenberg;, Elster;04895;035365;Brandenburg
Falkenberg;b Fürstenwalde, Spree;15518;033607;Brandenburg
Falkenberg;b Beeskow;15848;033675;Brandenburg
Falkenberg;b Uckro;15926;035454;Brandenburg
Falkenberg;, Mark;16259;033458;Brandenburg
Falkenberg;, Altmark;39615;039386;Sachsen-Anhalt
Falkenberg;, Niederbay;84326;08727;Bayern
Falkenberg;, Oberpf;95685;09637;Bayern
Falkenfels;;94350;09961;Bayern
Falkenhagen;b Seelow;15306;033603;Brandenburg
Falkenhagen;b Pritzwalk;16928;033986;Brandenburg
Falkenhagen;b Prenzlau;17291;039853;Brandenburg
Falkenhain;b Wurzen;04808;034262;Sachsen
Falkenhain;, NL;15938;035453;Brandenburg
Falkenmühle;;76891;06394;Rheinland-Pfalz
Falkenrehde;;14641;033233;Brandenburg
Falkensee;;14612;03322;Brandenburg
Falkenstein;, Vogtl;08223;03745;Sachsen
Falkenstein;, Pfalz;67808;06302;Rheinland-Pfalz
Falkenstein;, Oberpf;93167;09462;Bayern
Falkenthal;;16775;033088;Brandenburg
Falkenwalde;;17291;039858;Brandenburg
Fallingbostel;;29683;05162;Niedersachsen
Fambach;;98597;036848;Thüringen
Farchant;;82490;08821;Bayern
Farchau;;23909;04510;Schlewig-Holstein
Farchauermühle;;23909;;Schlewig-Holstein
Fargau-Pratjau;;24256;04303;Schlewig-Holstein
Farnstädt;;06279;034776;Sachsen-Anhalt
Farschweiler;;54317;06500;Rheinland-Pfalz
Farsleben;;39326;039201;Sachsen-Anhalt
Farven;;27446;04762;Niedersachsen
Fasanenhof;b Bruchsal;76694;07251;Baden-Württemberg
Fasanenjäger;;83371;08621;Bayern
Fasanerie;b Großenhain, Sachs;01561;;Sachsen
Faßberg;;29328;05055;Niedersachsen
Fattigsmühle;;95189;09295;Bayern
Faulbach;, Westerw;56235;02624;Rheinland-Pfalz
Faulbach;, Unterfr;97906;09392;Bayern
Faulenrost;;17139;039951;Mecklenburg-Vorpommern
Faulungen;;99976;036024;Thüringen
Faustenbach;, Odenw;64658;;Hessen
Faustermühle;;66482;;Rheinland-Pfalz
Fedderingen;;25779;04836;Schlewig-Holstein
Feddersdeich;;25840;;Schlewig-Holstein
Fehl-Ritzhausen;;56472;02661;Rheinland-Pfalz
Fehrbellin;;16833;033932;Brandenburg
Fehrow;;03096;035606;Brandenburg
Feichten;, Gem Amerang;83552;08074;Bayern
Feichten;a d Alz;84550;08623;Bayern
Feilbingert;;67824;06708;Rheinland-Pfalz
Feilitzsch;;95183;09281;Bayern
Feilsdorf;;54636;06527;Rheinland-Pfalz
Felchow;;16278;033335;Brandenburg
Feldafing;;82340;08157;Bayern
Feldatal;;36325;06637;Hessen
Feldberg;, Meckl;17258;039831;Mecklenburg-Vorpommern
Feldberg;(Schwarzwald);79868;07676;Baden-Württemberg
Felde;, Holst;24242;04340;Schlewig-Holstein
Feldengel;;99718;03636;Thüringen
Feldheim;b Jüterbog;14913;033747;Brandenburg
Feldhorst;;23858;04533;Schlewig-Holstein
Feldhusen;;23942;038826;Mecklenburg-Vorpommern
Feldkirchen;, Kr München;85622;089;Bayern
Feldkirchen;, Niederbay;94351;09420;Bayern
Feldkirchen-Westerham;;83620;08063;Bayern
Feldmühle;b Pfaffenhofen a d Ilm;85276;08441;Bayern
Fell;;54341;06502;Rheinland-Pfalz
Fellbach;, Württ;70734;0711;Baden-Württemberg
Fellbach;, Württ;70736;0711;Baden-Württemberg
Fellen;;97778;09356;Bayern
Fellheim;;87748;08335;Bayern
Felm;;24244;04349;Schlewig-Holstein
Fels;, Oberfr;95131;;Bayern
Felsberg;, Hess;34587;05662;Hessen
Felsbergerhof;;67806;06363;Rheinland-Pfalz
Felsenhagen;;16928;033986;Brandenburg
Fensdorf;;57580;02742;Rheinland-Pfalz
Fensterbach;;92269;09438;Bayern
Ferbitz;;19309;038780;Brandenburg
Ferch;;14548;033209;Brandenburg
Ferchels;;14715;039389;Sachsen-Anhalt
Ferchesar;;14715;033874;Brandenburg
Ferchland;;39317;039349;Sachsen-Anhalt
Ferdinandshof;, Vorpommern;17379;039778;Mecklenburg-Vorpommern
Ferdinandshorst;;17291;039859;Brandenburg
Fermerswalde;;04895;035363;Brandenburg
Ferna;;37339;036071;Thüringen
Fernwald;;35463;06404;Hessen
Ferschweiler;;54668;06523;Rheinland-Pfalz
Feucht;;90537;09128;Bayern
Feuchtwangen;;91555;09852;Bayern
Feuerscheid;;54597;06553;Rheinland-Pfalz
Feusdorf;;54584;06597;Rheinland-Pfalz
Fichtelberg;;95686;09272;Bayern
Fichtenau;;74579;07962;Baden-Württemberg
Fichtenberg;, Elbe;04931;035342;Brandenburg
Fichtenberg;, Württ;74427;07971;Baden-Württemberg
Fichtenhusen;;23996;038423;Mecklenburg-Vorpommern
Fichtenwalde;;14547;033206;Brandenburg
Fiefbergen;;24217;04344;Schlewig-Holstein
Fienstedt;;06198;034609;Sachsen-Anhalt
Fiersbach;;57635;02686;Rheinland-Pfalz
Filderstadt;;70794;0711;Baden-Württemberg
Filsen;;56341;06773;Rheinland-Pfalz
Filsum;;26849;04957;Niedersachsen
Filz;;56766;02677;Rheinland-Pfalz
Fincken;;17209;039922;Mecklenburg-Vorpommern
Finkenbach-Gersweiler;;67822;06362;Rheinland-Pfalz
Finkenberg;b Fichtenau;74579;07962;Baden-Württemberg
Finkenhaus;;74579;;Baden-Württemberg
Finkenthal;;17179;039971;Mecklenburg-Vorpommern
Finnentrop;;57413;02721;Nordrhein-Westfalen
Finning;;86923;08806;Bayern
Finningen;b Dillingen a d Donau;89435;09074;Bayern
Finowfurt;;16244;03335;Brandenburg
Finsing;, Oberbay;85464;08121;Bayern
Finsterbergen;;99898;036252;Thüringen
Finsterbrunnertal;;67705;06306;Rheinland-Pfalz
Finsterwalde;;03238;03531;Brandenburg
Fintel;;27389;04265;Niedersachsen
Firrel;;26835;04946;Niedersachsen
Fisch;;54439;06581;Rheinland-Pfalz
Fischach;, Schwab;86850;08236;Bayern
Fischbach;b Dresden;01477;035200;Sachsen
Fischbach;b Bad Salzungen;36452;036966;Thüringen
Fischbach;b Idar-Oberstein;55743;06784;Rheinland-Pfalz
Fischbach;bei Dahn;66996;06393;Rheinland-Pfalz
Fischbach;, Kr Kaiserslautern;67693;06305;Rheinland-Pfalz
Fischbach;b Gotha, Thür;99891;036259;Thüringen
Fischbachau;;83730;08028;Bayern
Fischbach-Oberraden;;54675;06564;Rheinland-Pfalz
Fischbachtal;, Odenw;64405;06166;Hessen
Fischbeck;(Elbe);39524;039323;Sachsen-Anhalt
Fischen;i. Allgäu;87538;08326;Bayern
Fischerbach;;77716;07832;Baden-Württemberg
Fischingen;, Baden;79592;07628;Baden-Württemberg
Fischwasser;;03238;035322;Brandenburg
Fischweier;;76359;07248;Baden-Württemberg
Fißmühle;b Kobern-Gondorf;56330;02607;Rheinland-Pfalz
Fitzbek;;25579;04877;Schlewig-Holstein
Fitzen;;21514;04155;Schlewig-Holstein
Flachslanden;, Mittelfr;91604;09829;Bayern
Flacht;, Rhein-Lahn-Kr;65558;06432;Rheinland-Pfalz
Fladungen;;97650;09778;Bayern
Flammersfeld;;57632;02685;Rheinland-Pfalz
Flarchheim;;99986;036028;Thüringen
Flarupholz;;24392;04641;Schlewig-Holstein
Flatow;b Velten;16766;033922;Brandenburg
Flechtingen;;39345;039054;Sachsen-Anhalt
Fleckeby;;24357;04354;Schlewig-Holstein
Flecken Zechlin;;16837;033923;Brandenburg
Fleckenbacher Sägmühle;;73494;07959;Baden-Württemberg
Fleethof;;17099;039607;Mecklenburg-Vorpommern
Fleetmark;;29416;039034;Sachsen-Anhalt
Flein;b Heilbronn, Neckar;74223;07131;Baden-Württemberg
Fleischwangen;;88373;07505;Baden-Württemberg
Flemlingen;;76835;06323;Rheinland-Pfalz
Flemsdorf;;16306;033335;Brandenburg
Flensburg;;24937;0461;Schlewig-Holstein
Flensburg;;24939;0461;Schlewig-Holstein
Flensburg;;24941;0461;Schlewig-Holstein
Flensburg;;24943;0461;Schlewig-Holstein
Flensburg;;24944;0461;Schlewig-Holstein
Fleringen;;54597;06558;Rheinland-Pfalz
Flessau;;39606;039392;Sachsen-Anhalt
Flieden;;36103;06655;Hessen
Fliegenberg;b Berkenthin;23919;;Schlewig-Holstein
Fließem;;54636;06569;Rheinland-Pfalz
Flieth;;17268;039887;Brandenburg
Flintbek;;24220;04347;Schlewig-Holstein
Flintsbach;a. Inn;83126;08034;Bayern
Flögeln;;27624;04745;Niedersachsen
Flöha;;09557;03726;Sachsen
Flohkraut;;87671;;Bayern
Floh-Seligenthal;;98593;03683;Thüringen
Flomborn;;55234;06735;Rheinland-Pfalz
Flonheim;;55237;06734;Rheinland-Pfalz
Flörsbachtal;;63639;06057;Hessen
Flörsheim;am Main;65439;06145;Hessen
Flörsheim-Dalsheim;;67592;06243;Rheinland-Pfalz
Florstadt;;61197;06035;Hessen
Floß;;92685;09603;Bayern
Flößberg;;04651;034345;Sachsen
Flossenbürg;;92696;09603;Bayern
Flöthe;;38312;05341;Niedersachsen
Fluorn-Winzeln;;78737;07402;Baden-Württemberg
Flurstedt;;99510;03644;Thüringen
Flußbach;;54516;06571;Rheinland-Pfalz
Fluterschen;;57614;02681;Rheinland-Pfalz
Fockbek;;24787;04331;Schlewig-Holstein
Föckelberg;;66887;06385;Rheinland-Pfalz
Fockendorf;b Altenburg, Thür;04617;034343;Thüringen
Fohrde;;14798;033834;Brandenburg
Föhrden-Barl;;25563;04822;Schlewig-Holstein
Föhren;b Trier;54343;06502;Rheinland-Pfalz
Fohren-Linden;;55777;06783;Rheinland-Pfalz
Forbach;, Baden;76596;07228;Baden-Württemberg
Forchheim;, Breisgau;79362;07642;Baden-Württemberg
Forchheim;, Oberfr;91301;09191;Bayern
Forchtenberg;;74670;07947;Baden-Württemberg
Förderstedt;;39443;039266;Sachsen-Anhalt
Forheim;;86735;09089;Bayern
Föritz;;96524;03675;Thüringen
Forst;(Lausitz);03149;03562;Brandenburg
Forst;(Eifel);56754;02672;Rheinland-Pfalz
Forst;(Hunsrück);56858;06545;Rheinland-Pfalz
Forst;b Wissen, Sieg;57537;02742;Rheinland-Pfalz
Forst;an der Weinstraße;67147;06326;Rheinland-Pfalz
Forst;, Baden;76694;07251;Baden-Württemberg
Forstern;, Oberbay;85659;08124;Bayern
Forsthaus Heide;b Lampertheim, Hess;68623;;Hessen
Forsthaus Ivendorf;;18211;;Mecklenburg-Vorpommern
Forsthof Satow;;18239;;Mecklenburg-Vorpommern
Forstinning;;85661;08121;Bayern
Forstmehren;;57635;02686;Rheinland-Pfalz
Forstwolfersdorf;;07570;036607;Thüringen
Förtha;;99819;036925;Thüringen
Förthen;;07937;036628;Thüringen
Framersheim;;55234;06733;Rheinland-Pfalz
Frammersbach;;97833;09355;Bayern
Frankelbach;;67737;06308;Rheinland-Pfalz
Frankena;;03253;035322;Brandenburg
Frankenau;, Hess;35110;06455;Hessen
Frankenberg;, Sachs;09669;037206;Sachsen
Frankenberg;(Eder);35066;06451;Hessen
Frankendorf;b Neuruppin;16818;033924;Brandenburg
Frankendorf;b Weimar, Thür;99441;036453;Thüringen
Frankeneck;;67468;06325;Rheinland-Pfalz
Frankenfeld;, Aller;27336;05165;Niedersachsen
Frankenhain;b Geithain;04643;034341;Sachsen
Frankenhain;b Schlieben;04936;035361;Brandenburg
Frankenhain;, Thür;99330;036205;Thüringen
Frankenhardt;;74586;07959;Baden-Württemberg
Frankenheim;b Meiningen;98634;036946;Thüringen
Frankenroda;;99826;036924;Thüringen
Frankenstein;, Sachs;09569;037321;Sachsen
Frankenstein;, Pfalz;67468;06329;Rheinland-Pfalz
Frankenthal;b Bischofswerda;01909;035954;Sachsen
Frankenthal;(Pfalz);67227;06233;Rheinland-Pfalz
Frankenwinheim;;97447;09382;Bayern
Frankfurt;(Oder);15230;069;Brandenburg
Frankfurt;(Oder);15232;069;Brandenburg
Frankfurt;(Oder);15234;069;Brandenburg
Frankfurt;(Oder);15236;069;Brandenburg
Frankfurt;am Main;60308;069;Hessen
Frankfurt;am Main;60311;069;Hessen
Frankfurt;am Main;60313;069;Hessen
Frankfurt;am Main;60314;069;Hessen
Frankfurt;am Main;60316;069;Hessen
Frankfurt;am Main;60318;069;Hessen
Frankfurt;am Main;60320;069;Hessen
Frankfurt;am Main;60322;069;Hessen
Frankfurt;am Main;60323;069;Hessen
Frankfurt;am Main;60325;069;Hessen
Frankfurt;am Main;60326;069;Hessen
Frankfurt;am Main;60327;069;Hessen
Frankfurt;am Main;60329;069;Hessen
Frankfurt;am Main;60385;069;Hessen
Frankfurt;am Main;60386;069;Hessen
Frankfurt;am Main;60388;069;Hessen
Frankfurt;am Main;60389;069;Hessen
Frankfurt;am Main;60431;069;Hessen
Frankfurt;am Main;60433;069;Hessen
Frankfurt;am Main;60435;069;Hessen
Frankfurt;am Main;60437;069;Hessen
Frankfurt;am Main;60438;069;Hessen
Frankfurt;am Main;60439;069;Hessen
Frankfurt;am Main;60486;069;Hessen
Frankfurt;am Main;60487;069;Hessen
Frankfurt;am Main;60488;069;Hessen
Frankfurt;am Main;60489;069;Hessen
Frankfurt;am Main;60528;069;Hessen
Frankfurt;am Main;60529;069;Hessen
Frankfurt;am Main;60549;069;Hessen
Frankfurt;am Main;60594;069;Hessen
Frankfurt;am Main;60596;069;Hessen
Frankfurt;am Main;60598;069;Hessen
Frankfurt;am Main;60599;069;Hessen
Frankfurt;am Main;65929;069;Hessen
Frankfurt;am Main;65931;069;Hessen
Frankfurt;am Main;65933;069;Hessen
Frankfurt;am Main;65934;069;Hessen
Frankfurt;am Main;65936;069;Hessen
Fränkisch-Crumbach;;64407;06164;Hessen
Frankleben;;06259;034637;Sachsen-Anhalt
Frankweiler;, Pfalz;76833;06345;Rheinland-Pfalz
Franzburg;;18461;05108;Mecklenburg-Vorpommern
Franzenheim;;54316;06588;Rheinland-Pfalz
Franzenmühle;b Burgen, Mosel;56332;;Rheinland-Pfalz
Frasdorf;;83112;08052;Bayern
Fraßdorf;;06386;034977;Sachsen-Anhalt
Frauenau;, Bayerischer Wald;94258;09926;Bayern
Frauenberg;b Idar-Oberstein;55776;06787;Rheinland-Pfalz
Frauenberg;, Kr Fürstenfeldbruck;82216;08141;Bayern
Frauenchiemsee;;83256;08054;Bayern
Frauendorf;, OL;01945;035755;Brandenburg
Frauendorf;, NL;03058;0355;Brandenburg
Frauendorf;b Grimma;04668;034386;Sachsen
Frauenhagen;b Angermünde;16278;03331;Brandenburg
Frauenholz;b Donzdorf;73072;;Baden-Württemberg
Frauenhurt;;83374;08669;Bayern
Frauenmark;b Parchim;19374;038723;Mecklenburg-Vorpommern
Frauenneuharting;;83553;08092;Bayern
Frauenprießnitz;;07774;036421;Thüringen
Frauensee;;36460;036963;Thüringen
Frauenstein;, Sachs;09623;037326;Sachsen
Frauenwald;;98711;036782;Thüringen
Fräulein-Steinfort;;23936;038871;Mecklenburg-Vorpommern
Fraulund;, Gem Saustrup;24405;04646;Schlewig-Holstein
Fraunberg;, Oberbay;85447;08084;Bayern
Fraureuth;;08427;03761;Sachsen
Frauwalde;b Ruhland;01990;035755;Brandenburg
Frechen;;50226;02234;Nordrhein-Westfalen
Freckenfeld;;76872;06340;Rheinland-Pfalz
Freckleben;;06456;034785;Sachsen-Anhalt
Fredeburg;b Ratzeburg;23909;04541;Schlewig-Holstein
Freden;(Leine);31084;05184;Niedersachsen
Fredenbeck;;21717;04149;Niedersachsen
Fredersdorf;b Belzig;14806;033846;Brandenburg
Fredersdorf;b Berlin;15370;033439;Brandenburg
Fredersdorf;b Angermünde;16306;039861;Brandenburg
Fredesdorf;;23826;04558;Schlewig-Holstein
Freesdorf;;15926;03544;Brandenburg
Frehne;;16945;033968;Brandenburg
Freiamt;;79348;07645;Baden-Württemberg
Freiberg;, Sachs;09599;03731;Sachsen
Freiberg;am Neckar;71691;07141;Baden-Württemberg
Freiberg;b Deggendorf;94469;0991;Bayern
Freiburg;(Elbe);21729;04779;Niedersachsen
Freiburg;im Breisgau;79098;0761;Baden-Württemberg
Freiburg;im Breisgau;79100;0761;Baden-Württemberg
Freiburg;im Breisgau;79102;0761;Baden-Württemberg
Freiburg;im Breisgau;79104;0761;Baden-Württemberg
Freiburg;im Breisgau;79106;0761;Baden-Württemberg
Freiburg;im Breisgau;79108;0761;Baden-Württemberg
Freiburg;im Breisgau;79110;0761;Baden-Württemberg
Freiburg;im Breisgau;79111;0761;Baden-Württemberg
Freiburg;im Breisgau;79112;0761;Baden-Württemberg
Freiburg;im Breisgau;79114;0761;Baden-Württemberg
Freiburg;im Breisgau;79115;0761;Baden-Württemberg
Freiburg;im Breisgau;79117;0761;Baden-Württemberg
Freidorf;b Königs Wusterhausen;15757;033765;Brandenburg
Freienbessingen;;99713;036043;Thüringen
Freienhagen;b Oranienburg;16515;033051;Brandenburg
Freienhagen;b Heilbad Heiligenstadt;37318;036083;Thüringen
Freienhufen;;01983;035753;Brandenburg
Freienorla;;07768;036423;Thüringen
Freiensteinau;;36399;06666;Hessen
Freienthal;;14822;033844;Brandenburg
Freienwill;b Flensburg;24991;04602;Schlewig-Holstein
Freigericht;;63579;06055;Hessen
Freihung;;92271;09646;Bayern
Freilassing;;83395;08654;Bayern
Frei-Laubersheim;;55546;06709;Rheinland-Pfalz
Freileben;;04936;035364;Brandenburg
Freilingen;, Westerw;56244;02666;Rheinland-Pfalz
Freimersheim;, Rheinhess;55234;06731;Rheinland-Pfalz
Freimersheim;(Pfalz);67482;06347;Rheinland-Pfalz
Freinberg;b Evenhausen, Oberbay;83552;;Bayern
Freinsheim;;67251;06353;Rheinland-Pfalz
Freirachdorf;;56244;02680;Rheinland-Pfalz
Freisbach;;67361;06344;Rheinland-Pfalz
Freisen;;66629;06855;Saarland
Freising;, Oberbay;85354;08161;Bayern
Freising;, Oberbay;85356;08161;Bayern
Freist;;06347;034783;Sachsen-Anhalt
Freistatt;b Sulingen;27259;05448;Niedersachsen
Freital;;01705;0351;Sachsen
Freiwalde;;15910;035474;Brandenburg
Frellstedt;;38373;05355;Niedersachsen
Frelsdorf;;27616;04749;Niedersachsen
Fremdingen;;86742;09086;Bayern
Frensdorf;, Oberfr;96158;09502;Bayern
Freren;;49832;05902;Niedersachsen
Fresdorf;;14552;033205;Brandenburg
Fresenboje;;24358;04353;Schlewig-Holstein
Fresenbrügge;;19300;038756;Mecklenburg-Vorpommern
Fresenburg;, Emsl;49762;05933;Niedersachsen
Fresendelf;;25876;04884;Schlewig-Holstein
Frestedt;;25727;04830;Schlewig-Holstein
Frettenheim;;67596;06733;Rheinland-Pfalz
Fretterode;;37318;036087;Thüringen
Fretzdorf;;16909;033964;Brandenburg
Freudenberg;b Bad Freienwalde;16259;033451;Brandenburg
Freudenberg;, Gem Nienborstel;24816;;Schlewig-Holstein
Freudenberg;, Westf;57258;02734;Nordrhein-Westfalen
Freudenberg;, Oberpf;92272;09627;Bayern
Freudenberg;, Baden;97896;09375;Baden-Württemberg
Freudenburg;;54450;06582;Rheinland-Pfalz
Freudenheim;;94501;08535;Bayern
Freudenstadt;;72250;07441;Baden-Württemberg
Freudental;, Württ;74392;07143;Baden-Württemberg
Freyburg;(Unstrut);06632;034464;Sachsen-Anhalt
Freyenstein;;16918;033967;Brandenburg
Freystadt;, Oberpf;92342;09179;Bayern
Freyung;, Niederbay;94078;08551;Bayern
Frickenhausen;, Württ;72636;07022;Baden-Württemberg
Frickenhausen;a. Main;97252;09331;Bayern
Frickingen;, Baden;88699;07554;Baden-Württemberg
Friderikenhof;b Ludwigsfelde;14979;;Brandenburg
Fridingen;an der Donau;78567;07463;Baden-Württemberg
Fridolfing;;83413;08684;Bayern
Friedberg;(Hessen);61169;06031;Hessen
Friedberg;, Bay;86316;0821;Bayern
Friedbüsch;;54655;;Rheinland-Pfalz
Friedebach;b Pößneck;07381;03647;Thüringen
Friedeburg;b Hettstedt, Sachs-Anh;06347;034783;Sachsen-Anhalt
Friedeburg;, Ostfriesl;26446;04465;Niedersachsen
Friedeburgerhütte;;06347;034783;Sachsen-Anhalt
Friedelshausen;;98634;036940;Thüringen
Friedelsheim;;67159;06322;Rheinland-Pfalz
Friedenfelde;;17268;039887;Brandenburg
Friedenfels;;95688;09683;Bayern
Friedensau;;39291;03921;Sachsen-Anhalt
Friedensdorf;b Merseburg;06254;03461;Sachsen-Anhalt
Friedenthal;b Pracht;57589;02682;Rheinland-Pfalz
Friedenweiler;;79877;07654;Baden-Württemberg
Friedersdorf;b Dippoldiswalde;01744;037326;Sachsen
Friedersdorf;b Löbau;02742;035872;Sachsen
Friedersdorf;, NL;03253;035323;Brandenburg
Friedersdorf;b Herzberg, Elster;04916;03535;Brandenburg
Friedersdorf;b Bitterfeld;06749;03493;Sachsen-Anhalt
Friedersdorf;, Oderbruch;15306;03346;Brandenburg
Friedersdorf;b Königs Wusterhausen;15754;033767;Brandenburg
Friedersdorf;, Thür;98701;036781;Thüringen
Friedewald;, Hess;36289;06674;Hessen
Friedewald;, Westerw;57520;02743;Rheinland-Pfalz
Friedland;b Freiberg, Sachs;09634;;Sachsen
Friedland;, Mark;15848;033676;Brandenburg
Friedland;b Neubrandenburg;17098;039601;Mecklenburg-Vorpommern
Friedland;, Kr Göttingen;37133;05504;Niedersachsen
Friedrichroda;;99894;03623;Thüringen
Friedrichsaue;b Aschersleben, Sachs-Anh;06449;034741;Sachsen-Anhalt
Friedrichsaue;b Seelow;15328;033473;Brandenburg
Friedrichsbrunn;;06507;039487;Sachsen-Anhalt
Friedrichsdorf;b Köthen, Anh;06386;034977;Sachsen-Anhalt
Friedrichsdorf;, Taunus;61381;06175;Hessen
Friedrichsgabekoog;;25764;04839;Schlewig-Holstein
Friedrichsgraben;;24799;04339;Schlewig-Holstein
Friedrichshafen;;88045;07541;Baden-Württemberg
Friedrichshafen;;88046;07541;Baden-Württemberg
Friedrichshafen;;88048;07541;Baden-Württemberg
Friedrichshagen;b Grevesmühlen;23936;03841;Mecklenburg-Vorpommern
Friedrichshain;, NL;03130;035600;Brandenburg
Friedrichshof;b Königs Wusterhausen;15754;033767;Brandenburg
Friedrichshof;b Bad Freienwalde;16259;033457;Brandenburg
Friedrichshof;b Schönhausen, Meckl;17337;039971;Mecklenburg-Vorpommern
Friedrichshof;b Bützow;18246;038461;Mecklenburg-Vorpommern
Friedrichshof;, Oberfr;95460;09273;Bayern
Friedrichshöhe;b Neuhaus a Rennweg;98749;036704;Thüringen
Friedrichsholm;;24799;04339;Schlewig-Holstein
Friedrichskoog;, Dithm;25718;04854;Schlewig-Holstein
Friedrichsrode;;99713;036338;Thüringen
Friedrichsruh;b Hamburg;21521;04104;Schlewig-Holstein
Friedrichsruhe;b Parchim;19374;038723;Mecklenburg-Vorpommern
Friedrichstadt;, Eider;25840;04881;Schlewig-Holstein
Friedrichsthal;b Angermünde;16306;033332;Brandenburg
Friedrichsthal;b Oranienburg;16515;03301;Brandenburg
Friedrichsthal;, Saar;66299;06897;Saarland
Friedrichsthal;b Nordhausen;99735;036337;Thüringen
Friedrichswalde;b Eberswalde;16247;033367;Brandenburg
Friedrichswalde;b Glöwen;19339;038787;Brandenburg
Friedrichswalde;b Sternberg;19412;038482;Mecklenburg-Vorpommern
Friedrichswerth;b Gotha, Thür;99869;036254;Thüringen
Friedrich-Wilhelm-Lübke-Koog;;25924;04668;Schlewig-Holstein
Frielendorf;;34621;05684;Hessen
Friemar;;99869;036258;Thüringen
Frienstedt;;99192;036208;Thüringen
Frienstedt;;99192;036208;Thüringen
Friesack;;14662;033235;Brandenburg
Friesdorf;b Wippra;06543;034775;Sachsen-Anhalt
Friesenhagen;;51598;02294;Rheinland-Pfalz
Friesenheim;, Rheinhess;55278;06737;Rheinland-Pfalz
Friesenheim;, Baden;77948;07821;Baden-Württemberg
Friesenried;;87654;08347;Bayern
Friesoythe;;26169;04491;Niedersachsen
Frießnitz;;07570;036603;Thüringen
Frimberg;;83546;08073;Bayern
Friolzheim;;71292;07044;Baden-Württemberg
Frittlingen;;78665;07426;Baden-Württemberg
Fritzenweng;;83379;08681;Bayern
Fritzlar;;34560;05622;Hessen
Frohburg;;04654;034348;Sachsen
Fröhden;;14913;03372;Brandenburg
Fröhnd;, Schwarzw;79677;07673;Baden-Württemberg
Frohndorf;;99610;03634;Thüringen
Frohnhofen;, Pfalz;66903;06386;Rheinland-Pfalz
Frohnloh;;82349;089;Bayern
Frohnsdorf;b Altenburg, Thür;04618;034497;Thüringen
Frohnsdorf;b Jüterbog;14929;033748;Brandenburg
Froitshub;;83552;08074;Bayern
Frömmstedt;;99638;036375;Thüringen
Fröndenberg;;58730;02373;Nordrhein-Westfalen
Fronderode;;99735;036335;Thüringen
Fronhausen;, Lahn;35112;06426;Hessen
Fronhofen;b Simmern, Hunsrück;55471;06761;Rheinland-Pfalz
Fronreute;;88273;07505;Baden-Württemberg
Frontenhausen;;84160;08732;Bayern
Frose;;06464;034741;Sachsen-Anhalt
Fröttstädt;;99880;03622;Thüringen
Frücht;;56132;02603;Rheinland-Pfalz
Fuchsberg;, Kr Fürstenfeldbruck;85254;08134;Bayern
Fuchsenmühle;b Marktheidenfeld;97828;09391;Bayern
Fuchshain;;04683;034297;Sachsen
Fuchshäusle;b Hüttlingen, Württ;73453;07366;Baden-Württemberg
Fuchshof;, Gem Ahorntal;91344;09202;Bayern
Fuchshofen;;53533;02693;Rheinland-Pfalz
Fuchsmühl;;95689;09634;Bayern
Fuchsmühle;, Queich;76877;;Rheinland-Pfalz
Fuchsstadt;, Unterfr;97727;09732;Bayern
Fuchssteig;;83364;08666;Bayern
Fuchstal;b Landsberg a Lech;86925;08243;Bayern
Fuhlendorf;, Darß;18356;038231;Mecklenburg-Vorpommern
Fuhlendorf;b Wiemersdorf;24649;04192;Schlewig-Holstein
Fuhlenhagen;;21493;04156;Schlewig-Holstein
Fulda;;36037;0661;Hessen
Fulda;;36039;0661;Hessen
Fulda;;36041;0661;Hessen
Fulda;;36043;0661;Hessen
Fuldabrück;;34277;0561;Hessen
Fuldatal;;34233;05607;Hessen
Fulgenkoppel;;18209;038203;Mecklenburg-Vorpommern
Fünfeichen;b Eisenhüttenstadt;15890;033654;Brandenburg
Fünfmühlen;;24861;04885;Schlewig-Holstein
Fünfstetten;;86681;09092;Bayern
Fürfeld;, Kr Bad Kreuznach;55546;06709;Rheinland-Pfalz
Fürnsbach;;85465;08762;Bayern
Fürst;b Waging a See;83329;08681;Bayern
Fürstenau;b Dippoldiswalde;01778;035054;Sachsen
Fürstenau;b Bramsche, Hase;49584;05901;Niedersachsen
Fürstenberg;/Havel;16798;033093;Brandenburg
Fürstenberg;, Weser;37699;05271;Niedersachsen
Fürsteneck;;94142;08555;Bayern
Fürstenfeldbruck;;82256;08141;Bayern
Fürstenstein;, Niederbay;94538;08504;Bayern
Fürstental;, Gem Waldeck;34516;;Hessen
Fürstenwalde;b Dippoldiswalde;01778;035054;Sachsen
Fürstenwalde;/Spree;15517;03361;Brandenburg
Fürstenwerder;;17291;039859;Brandenburg
Fürstenzell;;94081;08502;Bayern
Fürstlich Drehna;;03246;035324;Brandenburg
Furt;b Irschenberg;83739;08064;Bayern
Furth;, Kr München;82041;089;Bayern
Furth;, Gem Amerang;83552;08074;Bayern
Furth;, Kr Landshut;84095;08704;Bayern
Fürth;, Odenw;64658;06253;Hessen
Fürth;, Bay;90762;0911;Bayern
Fürth;, Bay;90763;0911;Bayern
Fürth;, Bay;90765;0911;Bayern
Fürth;, Bay;90766;0911;Bayern
Fürth;, Bay;90768;0911;Bayern
Furth im Wald;;93437;09973;Bayern
Fürthen;;57539;02682;Rheinland-Pfalz
Furtwangen;im Schwarzwald;78120;07723;Baden-Württemberg
Füssen;;87629;08362;Bayern
Fußgönheim;;67136;06237;Rheinland-Pfalz
Fußstall;;83714;08025;Bayern
Gaarz;b Lenzen, Elbe;19309;038758;Brandenburg
Gaarzerhof;;18230;038296;Mecklenburg-Vorpommern
Gabermühle;;91077;09134;Bayern
Gaberndorf;;99428;03643;Thüringen
Gaberndorf;;99428;03643;Thüringen
Gablenz;, OL;02953;03576;Sachsen
Gablenz;, NL;03058;035605;Brandenburg
Gablenz-Kromlau;;02953;03576;Sachsen
Gablingen;;86456;08230;Bayern
Gabsheim;;55288;06732;Rheinland-Pfalz
Gachenbach;;86565;08259;Bayern
Gackenbach;;56412;06439;Rheinland-Pfalz
Gädebehn;b Schwerin, Meckl;19089;0385;Mecklenburg-Vorpommern
Gadebusch;;19205;03886;Mecklenburg-Vorpommern
Gadegast;;06918;035387;Sachsen-Anhalt
Gädheim;;97503;09727;Bayern
Gadow;b Wittstock, Dosse;16909;033964;Brandenburg
Gadsdorf;b Zossen b Berlin;15806;033703;Brandenburg
Gagel;;39606;039391;Sachsen-Anhalt
Gägelow;b Sternberg;19406;038485;Mecklenburg-Vorpommern
Gägelow;b Wismar, Meckl;23968;03841;Mecklenburg-Vorpommern
Gager;;18586;038308;Mecklenburg-Vorpommern
Gaggenau;;76571;07225;Baden-Württemberg
Gagzow;;23974;03841;Mecklenburg-Vorpommern
Gahlenz;;09569;037292;Sachsen
Gahma;;07356;036643;Thüringen
Gahro;;03246;035324;Brandenburg
Gahry;;03149;035695;Brandenburg
Gaiberg;, Baden;69251;06223;Baden-Württemberg
Gaienhofen;;78343;07735;Baden-Württemberg
Gaildorf;;74405;07971;Baden-Württemberg
Gailingen;am Hochrhein;78262;07734;Baden-Württemberg
Gaimersheim;;85080;08458;Bayern
Gaimersheim;;85080;08458;Bayern
Gaisberg;b Au a Inn;83546;08073;Bayern
Gaißach;, Oberbay;83674;08041;Bayern
Galenbeck;b Malchin;17153;039602;Mecklenburg-Vorpommern
Galenbeck;b Schönhausen, Meckl;17337;039607;Mecklenburg-Vorpommern
Galenberg;;56651;02655;Rheinland-Pfalz
Gallentin;;23996;038423;Mecklenburg-Vorpommern
Gallin;b Boizenburg;19258;038842;Mecklenburg-Vorpommern
Gallin;b Lübz;19386;038732;Mecklenburg-Vorpommern
Gallinchen;;03058;0355;Brandenburg
Gallmersgarten;;91605;09843;Bayern
Gallschütz;b Meißen, Sachs;01665;035244;Sachsen
Gallun;;15749;033764;Brandenburg
Galm;;14715;;Brandenburg
Galmsbüll;;25899;04667;Schlewig-Holstein
Galow;;16278;033338;Brandenburg
Gamlen;;56761;02653;Rheinland-Pfalz
Gammelby;b Eckernförde;24340;04351;Schlewig-Holstein
Gammelin;;19230;038850;Mecklenburg-Vorpommern
Gammelsdorf;;85408;08766;Bayern
Gammelshausen;, Württ;73108;07164;Baden-Württemberg
Gammertingen;;72501;07574;Baden-Württemberg
Gamstädt;;99192;036202;Thüringen
Gandenitz;;17268;03987;Brandenburg
Ganderkesee;;27777;04222;Niedersachsen
Gandesbergen;;27324;04254;Niedersachsen
Gangelt;;52538;02454;Nordrhein-Westfalen
Gangkofen;;84140;08722;Bayern
Gangloffsömmern;;99634;036376;Thüringen
Gänsberg;b Waging a See;83329;08681;Bayern
Gantenbeck;;23948;03881;Mecklenburg-Vorpommern
Ganzer;;16845;033974;Brandenburg
Ganzlin;;19395;038737;Mecklenburg-Vorpommern
Gapel;;14727;03386;Brandenburg
Gappenach;;56294;02654;Rheinland-Pfalz
Garbsen;;30823;05131;Niedersachsen
Garbsen;;30826;05131;Niedersachsen
Garbsen;;30827;05131;Niedersachsen
Garching;a d Alz;84518;08634;Bayern
Garching;b. München;85748;089;Bayern
Gardelegen;;39638;03907;Sachsen-Anhalt
Garding;;25836;04862;Schlewig-Holstein
Garding, Kirchspiel;;25836;04862;Schlewig-Holstein
Garlin;;19357;038797;Brandenburg
Garlipp;;39579;039324;Sachsen-Anhalt
Garlitz;b Rathenow;14715;033878;Brandenburg
Garlitz;b Hagenow;19249;038855;Mecklenburg-Vorpommern
Garlstorf;am Walde;21376;040;Niedersachsen
Garmisch-Partenkirchen;;82467;08821;Bayern
Garnbach;;06571;034672;Thüringen
Garrel;;49681;04474;Niedersachsen
Garrey;;14823;033843;Brandenburg
Gars;a. Inn;83536;08073;Bayern
Gars Bahnhof;;83555;08073;Bayern
Garsedow;;19322;03877;Brandenburg
Garstedt;, Winsener Geest;21441;04173;Niedersachsen
Garthe;, Gem Emstek;49685;04435;Niedersachsen
Gartow;b Neustadt, Dosse;16845;033979;Brandenburg
Gartow;, Elbe;29471;05846;Niedersachsen
Gärtringen;;71116;07034;Baden-Württemberg
Gartz;;16307;033332;Brandenburg
Garvensdorf;;18233;038294;Mecklenburg-Vorpommern
Garvsmühlen;;18230;038296;Mecklenburg-Vorpommern
Garz;b Neustadt, Dosse;16845;033928;Brandenburg
Garz;, Usedom;17419;038376;Mecklenburg-Vorpommern
Garz;, Rügen;18574;038304;Mecklenburg-Vorpommern
Garz;b Schönhausen, Elbe;39524;039382;Sachsen-Anhalt
Garzau;;15345;033435;Brandenburg
Garzin;;15345;033435;Brandenburg
Gastemühle;;56332;;Rheinland-Pfalz
Gasteyers Mühle;;56379;06439;Rheinland-Pfalz
Gasthafen;;25899;04661;Schlewig-Holstein
Gastrose-Kerkwitz;;03172;035692;Brandenburg
Gatersleben;;06466;039482;Sachsen-Anhalt
Gatow;b Angermünde;16306;03332;Brandenburg
Gattendorf;, Oberfr;95185;09281;Bayern
Gatterstädt;;06268;034771;Sachsen-Anhalt
Gau-Algesheim;;55435;06725;Rheinland-Pfalz
Gau-Bickelheim;;55599;06701;Rheinland-Pfalz
Gau-Bischofsheim;;55296;06135;Rheinland-Pfalz
Gauchshausen;;73494;07967;Baden-Württemberg
Gauern;;07580;036608;Thüringen
Gauernitz;;01665;0351;Sachsen
Gauersheim;;67294;06355;Rheinland-Pfalz
Gäufelden;;71126;07032;Baden-Württemberg
Gaugrehweiler;;67822;06362;Rheinland-Pfalz
Gau-Heppenheim;;55234;06731;Rheinland-Pfalz
Gaukönigshofen;;97253;09337;Bayern
Gau-Odernheim;;55239;06733;Rheinland-Pfalz
Gaushorn;;25782;04838;Schlewig-Holstein
Gaußig;;02633;035930;Sachsen
Gauting;;82131;08153;Bayern
Gau-Weinheim;;55578;06732;Rheinland-Pfalz
Gaymühle, Gem Bauler;b Neuerburg;54673;;Rheinland-Pfalz
Gaymühle, Gem Berscheid;;54673;;Rheinland-Pfalz
Gaymühle, Gem Biesdorf;, Eifel;54675;;Rheinland-Pfalz
Gaymühle, Gem Wallendorf;, Eifel;54675;;Rheinland-Pfalz
Gebenbach;;92274;09622;Bayern
Gebersdorf;, Mark;15936;035451;Brandenburg
Gebersdorf;b Neuhaus a Rennweg;98743;036703;Thüringen
Gebesee;;99189;036201;Thüringen
Gebhardshain;;57580;02747;Rheinland-Pfalz
Gebhardshütte;, Odenw;64711;;Hessen
Gebroth;;55595;06756;Rheinland-Pfalz
Gebsattel;;91607;09861;Bayern
Gebstedt;;99510;036463;Thüringen
Gechingen;, Kr Calw;75391;07056;Baden-Württemberg
Gedern;;63688;06045;Hessen
Geelbek;;24882;;Schlewig-Holstein
Geesow;;16307;033333;Brandenburg
Geeste;;49744;05907;Niedersachsen
Geestgottberg;;39615;039397;Sachsen-Anhalt
Geesthacht;;21502;04152;Schlewig-Holstein
Gefell;b Schleiz;07926;036649;Thüringen
Gefell;, Kr Daun;54552;02692;Rheinland-Pfalz
Gefell;b Sonneberg, Thür;96524;036764;Thüringen
Gefrees;;95482;09254;Bayern
Gehaus;;36404;036965;Thüringen
Geheege;b Neustadt, Orla;07819;036482;Thüringen
Gehlberg;;98559;036845;Thüringen
Gehlberg;;98559;036845;Thüringen
Gehlert;;57627;02662;Rheinland-Pfalz
Gehlmühle;;76887;;Rheinland-Pfalz
Gehlweiler;;55490;06765;Rheinland-Pfalz
Gehofen;;06571;03466;Thüringen
Gehrde;b Bersenbrück;49596;05439;Niedersachsen
Gehrden;, Han;30989;05108;Niedersachsen
Gehrden;b Zerbst;39264;039247;Sachsen-Anhalt
Gehren;, NL;15926;035455;Brandenburg
Gehren;, Thür;98708;036738;Thüringen
Gehrendorf;;39359;039002;Sachsen-Anhalt
Gehrhof;, Holst;25361;04824;Schlewig-Holstein
Gehringswalde;;09429;037369;Sachsen
Gehrweiler;, Pfalz;67724;06302;Rheinland-Pfalz
Geichlingen;;54675;06566;Rheinland-Pfalz
Geiersthal;;94244;09923;Bayern
Geigant;;93449;09975;Bayern
Geilenkirchen;;52511;02451;Nordrhein-Westfalen
Geilnau;;56379;06439;Rheinland-Pfalz
Geilsdorf;, Thür;99326;03629;Thüringen
Geisa;;36419;036967;Thüringen
Geisbüschhof;;56729;02651;Rheinland-Pfalz
Geiselbach;;63826;06024;Bayern
Geiselberg;, Pfalz;67715;06307;Rheinland-Pfalz
Geiselhöring;;94333;09423;Bayern
Geiselwind;;96160;09556;Bayern
Geisenfeld;;85290;08452;Bayern
Geisenhain;;07646;036428;Thüringen
Geisenhausen;, Niederbay;84144;08743;Bayern
Geisenheim;, Rheingau;65366;06722;Hessen
Geisenhofen;b Unterschweinbach;82282;08145;Bayern
Geisfeld;b Hermeskeil;54413;06586;Rheinland-Pfalz
Geisig;;56357;06776;Rheinland-Pfalz
Geising;;01778;035056;Sachsen
Geisingen;, Baden;78187;07704;Baden-Württemberg
Geisleden;;37308;036084;Thüringen
Geislingen;b Balingen;72351;07433;Baden-Württemberg
Geislingen;an der Steige;73312;07331;Baden-Württemberg
Geislinger Weg;;73345;;Baden-Württemberg
Geismar;b Vacha;36419;036967;Thüringen
Geismar;, Eichsfeld;37308;036082;Thüringen
Geitersdorf;;07407;03672;Thüringen
Geithain;;04643;034341;Sachsen
Gelbensande;;18182;038201;Mecklenburg-Vorpommern
Gelchsheim;;97255;09335;Bayern
Geldern;;47608;02831;Nordrhein-Westfalen
Geldersheim;;97505;09721;Bayern
Geleitshäuser;;04720;034325;Sachsen
Gelenau;, Erzgeb;09423;03721;Sachsen
Gelenberg;;53539;02692;Rheinland-Pfalz
Gellmersdorf;;16278;033338;Brandenburg
Gelmeroda;;99428;03643;Thüringen
Gelmeroda;;99428;03643;Thüringen
Gelnhausen;;63571;06051;Hessen
Gelsenkirchen;;45879;0209;Nordrhein-Westfalen
Gelsenkirchen;;45881;0209;Nordrhein-Westfalen
Gelsenkirchen;;45883;0209;Nordrhein-Westfalen
Gelsenkirchen;;45884;0209;Nordrhein-Westfalen
Gelsenkirchen;;45886;0209;Nordrhein-Westfalen
Gelsenkirchen;;45888;0209;Nordrhein-Westfalen
Gelsenkirchen;;45889;0209;Nordrhein-Westfalen
Gelsenkirchen;;45891;0209;Nordrhein-Westfalen
Gelsenkirchen;;45892;0209;Nordrhein-Westfalen
Gelsenkirchen;;45894;0209;Nordrhein-Westfalen
Gelsenkirchen;;45896;0209;Nordrhein-Westfalen
Gelsenkirchen;;45897;0209;Nordrhein-Westfalen
Gelsenkirchen;;45899;0209;Nordrhein-Westfalen
Geltendorf;;82269;08193;Bayern
Gelting;, Angeln;24395;04643;Schlewig-Holstein
Geltorf;;24884;04621;Schlewig-Holstein
Geltow;;14542;033209;Brandenburg
Gemmerich;;56357;06776;Rheinland-Pfalz
Gemmingen;;75050;07267;Baden-Württemberg
Gemmrigheim;;74376;07143;Baden-Württemberg
Gemünd;a d Our;54673;06524;Rheinland-Pfalz
Gemünden;(Wohra);35285;06453;Hessen
Gemünden;(Felda);35329;06634;Hessen
Gemünden;, Hunsrück;55490;06765;Rheinland-Pfalz
Gemünden;, Westerw;56459;02663;Rheinland-Pfalz
Gemünden;a. Main;97737;09351;Bayern
Genderkingen;;86682;09090;Bayern
Gengenbach;;77723;07803;Baden-Württemberg
Genschmar;;15328;033472;Brandenburg
Genshagen;;14974;03378;Brandenburg
Gensingen;;55457;06727;Rheinland-Pfalz
Gentha;;06918;035387;Sachsen-Anhalt
Genthin;;39307;03933;Sachsen-Anhalt
Gentingen;;54675;06566;Rheinland-Pfalz
Genzkow;;17099;039601;Mecklenburg-Vorpommern
Georgenberg;, Oberpf;92697;09658;Bayern
Georgensgmünd;;91166;09172;Bayern
Georgenthal;/Thür. Wald;99887;036253;Thüringen
Georgenzell;b Schmalkalden;98590;036968;Thüringen
Georgsdorf;;49828;05946;Niedersachsen
Georgsmarienhütte;;49124;05401;Niedersachsen
Gera;;07545;0365;Thüringen
Gera;;07546;0365;Thüringen
Gera;;07548;0365;Thüringen
Gera;;07549;0365;Thüringen
Gera;;07551;0365;Thüringen
Gera;;07552;0365;Thüringen
Gera;;07554;0365;Thüringen
Gera;;07557;0365;Thüringen
Geraberg;;98716;03677;Thüringen
Gerabronn;, Württ;74582;07952;Baden-Württemberg
Gerach;b Idar-Oberstein;55743;06785;Rheinland-Pfalz
Gerach;, Oberfr;96161;09544;Bayern
Geratskirchen;;84552;08728;Bayern
Gerbach;, Pfalz;67813;06361;Rheinland-Pfalz
Gerbershausen;;37318;036081;Thüringen
Gerbisbach;;06922;03537;Sachsen-Anhalt
Gerbitz;;06429;034721;Sachsen-Anhalt
Gerblinghausen;;82041;08170;Bayern
Gerbrunn;;97218;0931;Bayern
Gerbstedt;;06347;034783;Sachsen-Anhalt
Gerdau;;29581;05808;Niedersachsen
Gerdshagen;b Pritzwalk;16928;033986;Brandenburg
Gerdshagen;b Satow b Bad Doberan;18239;038295;Mecklenburg-Vorpommern
Gerer;b Au a Inn;83546;08073;Bayern
Geretsried;;82538;08171;Bayern
Gerhardsbrunn;;66894;06375;Rheinland-Pfalz
Gerhardshofen;;91466;09163;Bayern
Gerichshain;;04827;034292;Sachsen
Gerichtsmannsmühle;;55743;;Rheinland-Pfalz
Gering;;56751;02654;Rheinland-Pfalz
Geringswalde;;09326;037382;Sachsen
Gerlebogk;;06420;034691;Sachsen-Anhalt
Gerlingen;, Württ;70839;07156;Baden-Württemberg
Germaringen;;87656;08341;Bayern
Germendorf;;16767;03301;Brandenburg
Germering;, Oberbay;82110;089;Bayern
Germersheim;;76726;07274;Rheinland-Pfalz
Germerswang;;82216;08141;Bayern
Gernrode;, Harz;06507;039485;Sachsen-Anhalt
Gernrode;b Leinefelde;37339;036076;Thüringen
Gernsbach;;76593;07224;Baden-Württemberg
Gernsheim;, Rhein;64579;06258;Hessen
Gernstedt;;06648;034467;Sachsen-Anhalt
Geroda;b Neustadt, Orla;07819;036482;Thüringen
Geroda;, Unterfr;97779;09747;Bayern
Gerold;;82493;08823;Bayern
Geroldsgrün;;95179;09288;Bayern
Geroldshausen;, Unterfr;97256;09366;Bayern
Gerolfingen;;91726;09854;Bayern
Gerolsbach;;85302;08445;Bayern
Gerolsheim;;67229;06238;Rheinland-Pfalz
Gerolstein;;54568;06591;Rheinland-Pfalz
Gerolzhofen;;97447;09382;Bayern
Gerschweiler;;89537;;Baden-Württemberg
Gersdorf;b. Leisnig;04703;034328;Sachsen
Gersdorf;b Hohenstein-Ernstthal;09355;037203;Sachsen
Gersdorf;b Bad Doberan;18230;038294;Mecklenburg-Vorpommern
Gersdorf;, Gem Frauenneuharting;83565;08092;Bayern
Gersdorf-Möhrsdorf;;01920;03578;Sachsen
Gersfeld;(Rhön);36129;06654;Hessen
Gersheim;;66453;06843;Saarland
Gersten;;49838;05904;Niedersachsen
Gerstenberg;;04617;03447;Thüringen
Gerstengrund;;36419;036967;Thüringen
Gerstetten;, Württ;89547;07323;Baden-Württemberg
Gersthofen;;86368;0821;Bayern
Gerstungen;;99834;036922;Thüringen
Gerswalde;;17268;039887;Brandenburg
Gerterode;b Niederorschel;37355;036076;Thüringen
Gertewitz;;07389;03647;Thüringen
Gerthausen;;98617;036943;Thüringen
Gertitzsch;;04741;034325;Sachsen
Gerwisch;;39175;039292;Sachsen-Anhalt
Gerzen;, Vils;84175;08744;Bayern
Geschendorf;;23815;04553;Schlewig-Holstein
Gescher;;48712;02542;Nordrhein-Westfalen
Geschwenda;;98716;036205;Thüringen
Gesees;b Bayreuth;95494;09201;Bayern
Geseke;;59590;02942;Nordrhein-Westfalen
Geslau;;91608;09867;Bayern
Gesotz, Gem Feuerscheid;;54597;;Rheinland-Pfalz
Gesotz, Gem Hargarten;;54597;;Rheinland-Pfalz
Gesotz, Gem Plütscheid;;54597;;Rheinland-Pfalz
Gessertshausen;;86459;08238;Bayern
Gestecke;;34305;;Hessen
Gestratz;;88167;08383;Bayern
Getelo;;49843;05942;Niedersachsen
Gettengrün;;08626;037430;Sachsen
Gettorf;;24214;04346;Schlewig-Holstein
Geusa;;06217;03461;Sachsen-Anhalt
Geußnitz;;06712;034423;Sachsen-Anhalt
Gevelsberg;;58285;02332;Nordrhein-Westfalen
Gevelsberg;;58285;02332;Nordrhein-Westfalen
Gevenich;, Eifel;56825;02678;Rheinland-Pfalz
Gevensleben;;38384;05354;Niedersachsen
Geversdorf;, Niederelbe;21784;04752;Niedersachsen
Geyer;;09468;037346;Sachsen
Giebelstadt;;97232;09334;Bayern
Gieboldehausen;;37434;05528;Niedersachsen
Gieckau;;06618;034445;Sachsen-Anhalt
Giekau;;24321;04385;Schlewig-Holstein
Gielde;;38315;05339;Niedersachsen
Gieleroth;;57610;02681;Rheinland-Pfalz
Gielert;;54424;06504;Rheinland-Pfalz
Gielow;;17139;039957;Mecklenburg-Vorpommern
Gielsdorf;b Strausberg;15345;03341;Brandenburg
Giengen;an der Brenz;89537;07322;Baden-Württemberg
Gierschnach;;56294;02605;Rheinland-Pfalz
Giershausen;b Flammersfeld;57632;02685;Rheinland-Pfalz
Giersleben;;06449;034746;Sachsen-Anhalt
Gierstädt;;99100;036206;Thüringen
Gierstling;;83364;08666;Bayern
Giesdorf;;54614;06551;Rheinland-Pfalz
Gieselauschleuse;;24797;04332;Schlewig-Holstein
Giesen;b Hildesheim;31180;05121;Niedersachsen
Giesenhagen;b Pritzwalk;16928;033986;Brandenburg
Giesenhausen;;57612;02688;Rheinland-Pfalz
Giesenhorst;;16845;033875;Brandenburg
Giesensdorf;b Beeskow;15848;033675;Brandenburg
Giesensdorf;b Pritzwalk;16928;03395;Brandenburg
Giesensdorf;b Ratzeburg;23909;04541;Schlewig-Holstein
Gieseritz;;29413;039033;Sachsen-Anhalt
Gieshof-Zelliner Loose;;15324;033478;Brandenburg
Gieshübel;;56377;02604;Rheinland-Pfalz
Gießen;, Lahn;35390;0641;Hessen
Gießen;, Lahn;35392;0641;Hessen
Gießen;, Lahn;35394;0641;Hessen
Gießen;, Lahn;35396;0641;Hessen
Gießen;, Lahn;35398;0641;Hessen
Gießhübelmühle;;75031;;Baden-Württemberg
Gießmannsdorf;;15926;03544;Brandenburg
Gießübel;b Hildburghausen;98667;036874;Thüringen
Gifhorn;;38518;05371;Niedersachsen
Gifizenmoos;;78713;07422;Baden-Württemberg
Giggenhausen;;85376;08165;Bayern
Gigling;b St Georgen, Chiemgau;83368;08621;Bayern
Gilching;;82205;08105;Bayern
Gillenbeuren;;56825;02677;Rheinland-Pfalz
Gillenfeld;;54558;06573;Rheinland-Pfalz
Gillersdorf;;98701;036781;Thüringen
Gilserberg;;34630;06696;Hessen
Gilten;;29690;05071;Niedersachsen
Gilzem;;54298;06506;Rheinland-Pfalz
Gimbsheim;;67578;06249;Rheinland-Pfalz
Gimbweiler;;55767;06782;Rheinland-Pfalz
Gimritz;;06198;034607;Sachsen-Anhalt
Gindorf;, Eifel;54657;06565;Rheinland-Pfalz
Gingen;an der Fils;73333;07162;Baden-Württemberg
Gingst;;18569;038305;Mecklenburg-Vorpommern
Ginsheim-Gustavsburg;;65462;06144;Hessen
Ginsweiler;;67742;06364;Rheinland-Pfalz
Gipperath;;54533;06574;Rheinland-Pfalz
Girgenrath;;53547;02638;Rheinland-Pfalz
Girkenroth;;56459;06435;Rheinland-Pfalz
Girod;;56412;06485;Rheinland-Pfalz
Gischau;;29416;039035;Sachsen-Anhalt
Gischow;;19386;038731;Mecklenburg-Vorpommern
Gittelde;;37534;05327;Niedersachsen
Gladau;;39307;039342;Sachsen-Anhalt
Gladbach;b Wittlich;54518;06508;Rheinland-Pfalz
Gladbeck;;45964;02043;Nordrhein-Westfalen
Gladbeck;;45966;02043;Nordrhein-Westfalen
Gladbeck;;45968;02043;Nordrhein-Westfalen
Gladenbach;;35075;06462;Hessen
Gladigau;;39606;039392;Sachsen-Anhalt
Glaisin;;19288;038754;Mecklenburg-Vorpommern
Glaitenhof;;71549;07191;Baden-Württemberg
Glambeck;b Gransee;16775;033933;Brandenburg
Glambeck;b Bützow;18246;038462;Mecklenburg-Vorpommern
Glanbrücken;;66887;06387;Rheinland-Pfalz
Glandorf;;49219;05426;Niedersachsen
Glan-Münchweiler;;66907;06383;Rheinland-Pfalz
Glasau;;23719;04525;Schlewig-Holstein
Glasehausen;;37308;036085;Thüringen
Glasewitz;;18276;03843;Mecklenburg-Vorpommern
Glashagen;b Bad Doberan;18209;038203;Mecklenburg-Vorpommern
Glashagen;b Wismar, Meckl;23996;038423;Mecklenburg-Vorpommern
Glashütte;, Sachs;01768;035053;Sachsen
Glashütte;b Belzig;14827;033849;Brandenburg
Glashütte;, Holst;23812;04320;Schlewig-Holstein
Glashütte;b Pressath;92690;09644;Bayern
Glashütte-Dittersdorf;;01768;035053;Sachsen
Glashütte-Johnsbach;;01768;035053;Sachsen
Glashütte-Luchau;;01768;035053;Sachsen
Glashütten;, Taunus;61479;06174;Hessen
Glashütten;, Oberfr;95496;09279;Bayern
Glashütte-Schlottwitz;;01768;035053;Sachsen
Glasin;;23992;038429;Mecklenburg-Vorpommern
Glasmühle;, Württ;75385;07055;Baden-Württemberg
Glasow;, Vorpommern;17322;039749;Mecklenburg-Vorpommern
Glattbach;, Unterfr;63864;06021;Bayern
Glatten;;72293;07443;Baden-Württemberg
Glattenzainbach;;71540;;Baden-Württemberg
Glau;;14959;033731;Brandenburg
Glaubitz;;01612;035265;Sachsen
Glauburg;;63695;06041;Hessen
Glauchau;;08371;03763;Sachsen
Glauzig;;06369;034975;Sachsen-Anhalt
Glebitzsch;;06794;034954;Sachsen-Anhalt
Glees;;56653;02636;Rheinland-Pfalz
Gleichamberg;;98646;03685;Thüringen
Gleichen;, Kr Göttingen;37130;05508;Niedersachsen
Gleima;;07356;036643;Thüringen
Gleina;b Riesa;01594;035268;Sachsen
Gleina;b Naumburg, Saale;06632;034464;Sachsen-Anhalt
Gleiritsch;;92723;09655;Bayern
Gleißenberg;, Oberpf;93477;09975;Bayern
Gleisweiler;;76835;06345;Rheinland-Pfalz
Gleiszellen-Gleishorbach;;76889;06343;Rheinland-Pfalz
Gletzow;;19217;038872;Mecklenburg-Vorpommern
Glewitz;b Grimmen;18513;038334;Mecklenburg-Vorpommern
Glien;b Rathenow;14715;033230;Brandenburg
Glienecke;;14793;033830;Brandenburg
Glienick;b Zossen b Berlin;15806;03377;Brandenburg
Glienicke;b Beeskow;15864;033677;Brandenburg
Glienicke;, Nordbahn;16548;033056;Brandenburg
Glienicke;b Wittstock, Dosse;16909;03394;Brandenburg
Glienig;;15936;035452;Brandenburg
Glienke;;17099;039606;Mecklenburg-Vorpommern
Glietz;;15913;035471;Brandenburg
Glinde;, Kr Stormarn;21509;040;Schlewig-Holstein
Glinde;b Schönebeck, Elbe;39249;039298;Sachsen-Anhalt
Glindenberg;;39326;039201;Sachsen-Anhalt
Glindow;;14542;03327;Brandenburg
Globig-Bleddin;;06901;034927;Sachsen-Anhalt
Glonn;, Kr Ebersberg, Oberbay;85625;08093;Bayern
Glöthe;;39240;039266;Sachsen-Anhalt
Glött;, Schwab;89353;09075;Bayern
Glottertal;;79286;07684;Baden-Württemberg
Glövzin;;19357;038797;Brandenburg
Glowe;, Rügen;18551;038302;Mecklenburg-Vorpommern
Glöwen;;19339;038787;Brandenburg
Glöwitz;;18356;038231;Mecklenburg-Vorpommern
Glücksburg;(Ostsee);24960;04631;Schlewig-Holstein
Glücksburg;, Nordfriesl;25821;;Schlewig-Holstein
Glückstadt;;25348;04124;Schlewig-Holstein
Glüsing;, Dithm;25779;04836;Schlewig-Holstein
Gmund;a. Tegernsee;83703;08022;Bayern
Gnadau;;39249;03928;Sachsen-Anhalt
Gnarrenburg;;27442;04763;Niedersachsen
Gnaschwitz;;02692;035930;Sachsen
Gnemern;;18246;038464;Mecklenburg-Vorpommern
Gnetsch;b Köthen, Anh;06369;034978;Sachsen-Anhalt
Gneus;;07646;036428;Thüringen
Gneven;;19065;03860;Mecklenburg-Vorpommern
Gnevezow;;17111;039994;Mecklenburg-Vorpommern
Gnevkow;;17089;039993;Mecklenburg-Vorpommern
Gnevsdorf;a d Elbe;19322;038791;Brandenburg
Gnevsdorf;b Lübz;19395;038737;Mecklenburg-Vorpommern
Gnewikow;;16818;03391;Brandenburg
Gnewitz;b Rostock;18195;038228;Mecklenburg-Vorpommern
Gnoien;;17179;039971;Mecklenburg-Vorpommern
Gnölbzig;;06420;034691;Sachsen-Anhalt
Gnotzheim;;91728;09833;Bayern
Gnutz;;24622;04392;Schlewig-Holstein
Göbitz;;06712;03441;Sachsen-Anhalt
Goch;;47574;02823;Nordrhein-Westfalen
Gochsheim;, Unterfr;97469;09721;Bayern
Göcklingen;;76831;06349;Rheinland-Pfalz
Göda;;02633;035930;Sachsen
Goddert;;56244;02626;Rheinland-Pfalz
Goddin;b Gadebusch;19205;038871;Mecklenburg-Vorpommern
Godendorf;b Neustrelitz;17237;039825;Mecklenburg-Vorpommern
Gödenroth;;56290;06762;Rheinland-Pfalz
Gödenstorf;;21376;0451;Niedersachsen
Godern;;19065;03860;Mecklenburg-Vorpommern
Gödnitz;;39264;039247;Sachsen-Anhalt
Göggenhofen;;85655;08095;Bayern
Göggingen;, Württ;73571;07175;Baden-Württemberg
Göhl;;23758;04361;Schlewig-Holstein
Göhlen;b Eisenhüttenstadt;15898;033656;Brandenburg
Göhlen;b Ludwigslust;19288;038751;Mecklenburg-Vorpommern
Gohlis;b Dresden;01462;0351;Sachsen
Gohlis;b Dresden;01462;0351;Sachsen
Gohlis;b Riesa;01619;03525;Sachsen
Gohlitz;;14641;033239;Brandenburg
Göhlsdorf;;14542;033207;Brandenburg
Gohrau;;06786;034905;Sachsen-Anhalt
Göhrde;;29473;05862;Niedersachsen
Göhren;b Altenburg, Thür;04603;03447;Thüringen
Göhren;b Strasburg;17348;03963;Mecklenburg-Vorpommern
Göhren;, Rügen;18586;038308;Mecklenburg-Vorpommern
Göhren;b Schwerin, Meckl;19089;03863;Mecklenburg-Vorpommern
Göhren;b Ludwigslust;19294;038755;Mecklenburg-Vorpommern
Göhren-Döhlen;;07950;036622;Thüringen
Göhren-Lebbin;;17213;039932;Mecklenburg-Vorpommern
Gohrisch;;01824;035021;Sachsen
Gokels;;25557;04872;Schlewig-Holstein
Golbitz;;06420;034691;Sachsen-Anhalt
Golchen;b Altentreptow;17089;03965;Mecklenburg-Vorpommern
Golchen;b Sternberg;19412;038483;Mecklenburg-Vorpommern
Goldbach;, Unterfr;63773;06021;Bayern
Goldbach;b Gotha, Thür;99869;036255;Thüringen
Goldbeck;b Neustadt, Dosse;16845;033973;Brandenburg
Goldbeck;b Wittstock, Dosse;16909;03394;Brandenburg
Goldbeck;b Grevesmühlen;23948;038825;Mecklenburg-Vorpommern
Goldbeck;, Altm;39596;039388;Sachsen-Anhalt
Goldberg;, Meckl;19399;038736;Mecklenburg-Vorpommern
Goldberg;b Wismar, Meckl;23992;038429;Mecklenburg-Vorpommern
Goldebek;, Nordfriesl;25862;04673;Schlewig-Holstein
Goldelund;;25862;04673;Schlewig-Holstein
Goldenbow;b Boizenburg;19260;038848;Mecklenburg-Vorpommern
Goldenbow;b Parchim;19374;038723;Mecklenburg-Vorpommern
Goldenerhahn;;23619;04553;Schlewig-Holstein
Goldenitz;;19230;038856;Mecklenburg-Vorpommern
Göldenitz;b Bützow;18258;03844;Mecklenburg-Vorpommern
Göldenitz;b Berkenthin;23919;04544;Schlewig-Holstein
Goldenstädt;;19079;0385;Mecklenburg-Vorpommern
Goldenstedt;, Kr Vechta;49424;04444;Niedersachsen
Goldisthal;;98746;03681;Thüringen
Goldkronach;;95497;09273;Bayern
Goldschau;b Zeitz, Elster;06721;036694;Sachsen-Anhalt
Gölenkamp;;49843;05941;Niedersachsen
Gollenberg;;55767;06782;Rheinland-Pfalz
Gollensdorf;;39615;039395;Sachsen-Anhalt
Göllheim;;67307;06351;Rheinland-Pfalz
Gollhofen;;97258;09848;Bayern
Gollin;;17268;039882;Brandenburg
Göllin;;18246;038462;Mecklenburg-Vorpommern
Göllingen;b Bad Frankenhausen;06567;034671;Thüringen
Gollmitz;, NL;03205;035435;Brandenburg
Gollmitz;b Prenzlau;17291;039856;Brandenburg
Göllnitz;, NL;03238;035329;Brandenburg
Göllnitz;b Schmölln, Thür;04626;034495;Thüringen
Gollwitz;b Brandenburg an der Havel;14778;033839;Brandenburg
Golm;b Potsdam;14476;0331;Brandenburg
Golm;b Angermünde;16306;033336;Brandenburg
Golmbach;;37640;05532;Niedersachsen
Golmsdorf;;07751;036427;Thüringen
Golschow;;03116;035602;Brandenburg
Gölsdorf;b Jüterbog;14913;033741;Brandenburg
Golßen;;15938;035452;Brandenburg
Goltoft;;24864;04622;Schlewig-Holstein
Golzen;;06636;034462;Sachsen-Anhalt
Golzow;b Brandenburg an der Havel;14778;033835;Brandenburg
Golzow;, Oderbruch;15328;033472;Brandenburg
Golzow;b Eberswalde;16230;03334;Brandenburg
Gomadingen;;72532;07385;Baden-Württemberg
Gomaringen;;72810;07072;Baden-Württemberg
Gommern;;39245;039200;Sachsen-Anhalt
Gommersheim;;67377;06327;Rheinland-Pfalz
Gömnigk;;14822;033844;Brandenburg
Gompertshausen;;98663;036875;Thüringen
Gonbach;;67724;06302;Rheinland-Pfalz
Gondelsheim;, Baden;75053;07252;Baden-Württemberg
Gondenbrett;;54595;06551;Rheinland-Pfalz
Gondershausen;;56283;06745;Rheinland-Pfalz
Gondorf;b Bitburg;54647;06565;Rheinland-Pfalz
Gonna;;06528;03464;Sachsen-Anhalt
Gönnebek;;24610;04323;Schlewig-Holstein
Gönnersdorf;, Kr Ahrweiler;53498;02633;Rheinland-Pfalz
Gönnersdorf;b Gerolstein;54584;06597;Rheinland-Pfalz
Gönnheim;;67161;06322;Rheinland-Pfalz
Goosefeld;;24340;04356;Schlewig-Holstein
Göpfersdorf;;04618;037608;Thüringen
Goppeln;;01728;0351;Sachsen
Göppingen;;73033;07161;Baden-Württemberg
Göppingen;;73035;07161;Baden-Württemberg
Göppingen;;73037;07161;Baden-Württemberg
Gorden-Staupitz;;03238;035325;Brandenburg
Gorenzen;;06343;034782;Sachsen-Anhalt
Gorgast;;15328;033472;Brandenburg
Görgeshausen;;56412;06485;Rheinland-Pfalz
Görike;;16866;033977;Brandenburg
Görisried;;87657;08302;Bayern
Göritz;b Vetschau;03226;035433;Brandenburg
Göritz;b Dessau, Anh;06869;034907;Sachsen-Anhalt
Göritz;b Prenzlau;17291;039851;Brandenburg
Görkwitz;;07907;03663;Thüringen
Gorleben;;29475;05882;Niedersachsen
Görlitz;, Neiße;02826;03581;Sachsen
Görlitz;, Neiße;02827;03581;Sachsen
Görlitz;, Neiße;02828;03581;Sachsen
Gorlosen;;19294;038755;Mecklenburg-Vorpommern
Görlsdorf;, NL;15926;03544;Brandenburg
Görlsdorf;b Angermünde;16278;033334;Brandenburg
Görmin;;17121;039992;Mecklenburg-Vorpommern
Gornau;;09405;0371;Sachsen
Görne;;14728;033235;Brandenburg
Gornhausen;;54472;06531;Rheinland-Pfalz
Görnitz;b Ludwigslust;19294;038755;Mecklenburg-Vorpommern
Gornsdorf;;09390;03721;Sachsen
Gorow;;18239;038207;Mecklenburg-Vorpommern
Görsbach;;99765;036333;Thüringen
Görschen;;06618;034445;Sachsen-Anhalt
Gorschendorf;;17139;03994;Mecklenburg-Vorpommern
Görsdorf;b. Beeskow;15848;033678;Brandenburg
Görsdorf;b Storkow, Mark;15859;033678;Brandenburg
Görsdorf;, NL;15936;035453;Brandenburg
Gorsdorf-Hemsendorf;;06917;03537;Sachsen-Anhalt
Gorsleben;b Artern;06577;034673;Thüringen
Görslow;;19065;03860;Mecklenburg-Vorpommern
Gortz;;14778;033836;Brandenburg
Görwihl;;79733;07754;Baden-Württemberg
Gorxheimertal;;69517;06201;Hessen
Görzig;b Großenhain, Sachs;01561;035263;Sachsen
Görzig;b Köthen, Anh;06369;034975;Sachsen-Anhalt
Görzig;b Beeskow;15848;033672;Brandenburg
Görzke;;14828;033847;Brandenburg
Goschen;;15868;033671;Brandenburg
Göschitz;;07907;036648;Thüringen
Gosda;, Lausitz;03149;035694;Brandenburg
Gosda;b Calau;03205;035694;Brandenburg
Goseck;;06667;03443;Sachsen-Anhalt
Gosedahl;;19357;038781;Brandenburg
Gosen;b Berlin;15537;03362;Brandenburg
Gösen;b Eisenberg, Thür;07607;036691;Thüringen
Gösenroth;;55624;06544;Rheinland-Pfalz
Gosheim;, Württ;78559;07426;Baden-Württemberg
Goslar;;38640;05321;Niedersachsen
Goslar;;38642;05321;Niedersachsen
Goslar;;38644;05321;Niedersachsen
Gospiteroda;;99880;03622;Thüringen
Gossa;;06773;034955;Sachsen-Anhalt
Gossel;;99338;036207;Thüringen
Gösselborn;;99326;03629;Thüringen
Gössenheim;;97780;09358;Bayern
Gossersweiler-Stein;;76857;06346;Rheinland-Pfalz
Gössitz;;07389;036483;Thüringen
Gößlow;;19249;038854;Mecklenburg-Vorpommern
Goßmar;b Sonnewalde;03249;035323;Brandenburg
Goßmar;b Luckau, NL;15926;035455;Brandenburg
Gößnitz;, Thür;04639;034493;Thüringen
Gößweinstein;;91327;09242;Bayern
Goßwitz;b Saalfeld, Saale;07334;03671;Thüringen
Gostewitz;;01594;03525;Sachsen
Gostewitz;;01594;03525;Sachsen
Gostorf;;23936;03881;Mecklenburg-Vorpommern
Gotha;, Thür;99867;03621;Thüringen
Gottberg;;16845;033928;Brandenburg
Gottenheim;;79288;07665;Baden-Württemberg
Gottesgabe;b Gadebusch;19209;038874;Mecklenburg-Vorpommern
Gotteskoogdeich;, Gem Emmelsbüll-Horsbüll;25899;04665;Schlewig-Holstein
Gotteszell;;94239;09929;Bayern
Gottfrieding;;84177;08731;Bayern
Gottholling;;94529;08546;Bayern
Gotthun;;17207;039931;Mecklenburg-Vorpommern
Göttin;b Potsdam;14476;;Brandenburg
Göttin;, Kr Hzgt Lauenburg;21514;04158;Schlewig-Holstein
Göttingen;, Niedersachs;37073;0551;Niedersachsen
Göttingen;, Niedersachs;37075;0551;Niedersachsen
Göttingen;, Niedersachs;37077;0551;Niedersachsen
Göttingen;, Niedersachs;37079;0551;Niedersachsen
Göttingen;, Niedersachs;37081;0551;Niedersachsen
Göttingen;, Niedersachs;37083;0551;Niedersachsen
Göttingen;, Niedersachs;37085;0551;Niedersachsen
Gottmadingen;;78244;07731;Baden-Württemberg
Göttnitz;;06780;034956;Sachsen-Anhalt
Gottschdorf;;01936;035797;Sachsen
Gottstedt;;99192;036208;Thüringen
Gottstedt;;99192;036208;Thüringen
Götz;;14778;033207;Brandenburg
Goyatz;;15913;035478;Brandenburg
Graach;an der Mosel;54470;06531;Rheinland-Pfalz
Graal-Müritz;, Ostseeheilbad;18181;038206;Mecklenburg-Vorpommern
Graal-Müritz;, Ostseeheilbad;18181;038206;Mecklenburg-Vorpommern
Grabau;b Schwarzenbek;21493;04151;Schlewig-Holstein
Grabau;, Kr Stormarn;23845;04537;Schlewig-Holstein
Graben;b Neukirchen a Teisenberg;83364;08666;Bayern
Graben;, Lechfeld;86836;08232;Bayern
Gräben;b Ziesar;14793;033833;Brandenburg
Gräbendorf;b Königs Wusterhausen;15741;033763;Brandenburg
Grabenhäusl;;83364;;Bayern
Graben-Neudorf;;76676;07255;Baden-Württemberg
Grabenstätt;, Chiemsee;83355;08661;Bayern
Grabenstetten;;72582;07382;Baden-Württemberg
Grabenstoffl;;83626;08024;Bayern
Grabko;;03172;035697;Brandenburg
Grabow;b Brandenburg an der Havel;14778;033836;Brandenburg
Grabow;b Belzig;14823;033843;Brandenburg
Grabow;b Wittstock, Dosse;16909;033984;Brandenburg
Grabow;b Pritzwalk;16945;033968;Brandenburg
Grabow;b Röbel;17209;039925;Mecklenburg-Vorpommern
Grabow;, Meckl;19300;038756;Mecklenburg-Vorpommern
Grabow;b Burg b Magdeburg;39291;039223;Sachsen-Anhalt
Grabowhöfe;;17194;039926;Mecklenburg-Vorpommern
Grabsleben;;99869;036202;Thüringen
Gräfelfing;;82166;089;Bayern
Grafenanger;;83358;08667;Bayern
Grafenau;, Württ;71120;07033;Baden-Württemberg
Grafenau;, Niederbay;94481;08552;Bayern
Grafenberg;, Württ;72661;07123;Baden-Württemberg
Gräfenberg;, Oberfr;91322;09192;Bayern
Gräfendhron;;54426;06504;Rheinland-Pfalz
Gräfendorf;b Herzberg, Elster;04916;03535;Brandenburg
Gräfendorf;b Pößneck;07387;03647;Thüringen
Gräfendorf;b Jüterbog;14913;033746;Brandenburg
Gräfendorf;, Unterfr;97782;09357;Bayern
Grafengehaig;;95356;09255;Bayern
Gräfenhain;, Thür;99887;03624;Thüringen
Gräfenhainichen;;06773;034953;Sachsen-Anhalt
Grafenhausen;, Hochschwarzw;79865;07748;Baden-Württemberg
Grafenrheinfeld;;97506;09723;Bayern
Grafenried;b Nittendorf;93161;09404;Bayern
Gräfenroda;;99330;036205;Thüringen
Gräfenthal;;98743;036703;Thüringen
Grafenwiesen;;93479;09941;Bayern
Grafenwöhr;;92655;09641;Bayern
Grafhorst;;38462;05364;Niedersachsen
Gräfinau-Angstedt;;98704;036785;Thüringen
Grafing;b. München;85567;08092;Bayern
Grafling;, Niederbay;94539;0991;Bayern
Grafrath;, Amper;82284;08144;Bayern
Grafschaft;b Bad Neuenahr-Ahrweiler;53501;02641;Rheinland-Pfalz
Grainau;;82491;08821;Bayern
Grainet;;94143;08585;Bayern
Graitschen b Bürgel;;07616;036692;Thüringen
Grambek;;23883;04542;Schlewig-Holstein
Grambin;;17375;039774;Mecklenburg-Vorpommern
Grambow;b Pasewalk;17322;039749;Mecklenburg-Vorpommern
Grambow;b Schwerin, Meckl;19071;0385;Mecklenburg-Vorpommern
Gramkow;;23968;040;Mecklenburg-Vorpommern
Grammendorf;;18513;038334;Mecklenburg-Vorpommern
Grammentin;;17153;039952;Mecklenburg-Vorpommern
Grammow;;18195;038205;Mecklenburg-Vorpommern
Gramnitz;;19230;03883;Mecklenburg-Vorpommern
Gramzow;b Gransee;16775;039861;Brandenburg
Gramzow;, Uckermark;17291;039861;Brandenburg
Gramzow;b Perleberg;19348;03876;Brandenburg
Grana;;06712;03441;Sachsen-Anhalt
Grande;;22946;04154;Schlewig-Holstein
Gräningen;;14715;033878;Brandenburg
Granitwerk Sormitztal;;07343;;Thüringen
Granschütz;;06679;03443;Sachsen-Anhalt
Gransdorf;;54533;06567;Rheinland-Pfalz
Gransebieth;;18513;038334;Mecklenburg-Vorpommern
Gransee;;16775;03306;Brandenburg
Granzin;b Hagenow;19230;03883;Mecklenburg-Vorpommern
Granzin;b Boizenburg;19258;038843;Mecklenburg-Vorpommern
Granzin;b Lübz;19386;038731;Mecklenburg-Vorpommern
Granzow;b Neustadt, Dosse;16866;033977;Brandenburg
Grapen-Stieten;;23996;038424;Mecklenburg-Vorpommern
Grapzow;;17089;03961;Mecklenburg-Vorpommern
Grasberg;b Bremen;28879;04208;Niedersachsen
Grasbrunn;;85630;089;Bayern
Grasellenbach;;64689;06207;Hessen
Grasleben;;38368;05357;Niedersachsen
Graß;b Großhelfendorf;85655;08095;Bayern
Grassau;b Schönewalde;04916;035362;Brandenburg
Grassau;b Stendal;39579;039324;Sachsen-Anhalt
Grassau;, Chiemgau;83224;08641;Bayern
Grattersdorf;;94541;09904;Bayern
Gratze;;16259;033451;Brandenburg
Grauel;;24594;04871;Schlewig-Holstein
Grauingen;;39359;039059;Sachsen-Anhalt
Graustein;;03130;03563;Brandenburg
Grävenwiesbach;;61279;06086;Hessen
Grebbin;;19374;038720;Mecklenburg-Vorpommern
Grebenau;, Hess;36323;06646;Hessen
Grebenhain;;36355;06644;Hessen
Grebenstein;;34393;05674;Hessen
Grebin;;24329;04383;Schlewig-Holstein
Grebs;b Ziesar;14793;;Brandenburg
Grebs;b Lehnin;14797;033830;Brandenburg
Grebs;b Ludwigslust;19294;038754;Mecklenburg-Vorpommern
Greding;;91171;08463;Bayern
Greese;;23970;03841;Mecklenburg-Vorpommern
Grefrath;, Niederrhein;47929;02158;Nordrhein-Westfalen
Greifenberg;, Ammersee;86926;08192;Bayern
Greifenhagen;;06333;034781;Sachsen-Anhalt
Greifenhain;, NL;03116;035602;Brandenburg
Greifenstein;, Hess;35753;06478;Hessen
Greiffenberg;;16278;033334;Brandenburg
Greifswald;, Hansestadt;17489;03834;Mecklenburg-Vorpommern
Greifswald;, Hansestadt;17491;03834;Mecklenburg-Vorpommern
Greifswald;, Hansestadt;17493;03834;Mecklenburg-Vorpommern
Greiling;;83677;08042;Bayern
Greimerath;b Trier;54314;06587;Rheinland-Pfalz
Greimerath;, Eifel;54533;06574;Rheinland-Pfalz
Greimersburg;;56814;02671;Rheinland-Pfalz
Greinach;b Waging a See;83329;08681;Bayern
Greinach;b Weibhausen;83379;08681;Bayern
Greiz;;07973;03661;Thüringen
Gremersdorf;, Holst;23758;04361;Schlewig-Holstein
Gremersdorf-Buchholz;;18461;038320;Mecklenburg-Vorpommern
Gremsdorf;;91350;09193;Bayern
Grenderich;;56858;02673;Rheinland-Pfalz
Grenz;;17291;039857;Brandenburg
Grenzach-Wyhlen;;79639;07624;Baden-Württemberg
Grenzhausen;;23936;03881;Mecklenburg-Vorpommern
Grenzheim;b Perleberg;19348;038785;Brandenburg
Grenzloch;;56130;02603;Rheinland-Pfalz
Greppin;;06803;03494;Sachsen-Anhalt
Greschendorf;;23936;038824;Mecklenburg-Vorpommern
Gresse;;19258;038842;Mecklenburg-Vorpommern
Gressow;;23968;03841;Mecklenburg-Vorpommern
Grethem;;29690;05164;Niedersachsen
Grettstadt;;97508;09729;Bayern
Greußen;;99718;03636;Thüringen
Greußenheim;;97259;09369;Bayern
Greven;b Boizenburg;19258;038842;Mecklenburg-Vorpommern
Greven;, Westf;48268;02571;Nordrhein-Westfalen
Grevenbroich;;41515;02181;Nordrhein-Westfalen
Grevenbroich;;41516;02181;Nordrhein-Westfalen
Grevenbroich;;41517;02181;Nordrhein-Westfalen
Grevenkop;;25361;04824;Schlewig-Holstein
Grevenkrug;;24241;04322;Schlewig-Holstein
Grevenstein;b Grevesmühlen;23936;038824;Mecklenburg-Vorpommern
Grevesmühlen;;23936;03881;Mecklenburg-Vorpommern
Gribbohm;;25596;04827;Schlewig-Holstein
Gribow;;17506;038355;Mecklenburg-Vorpommern
Griebelschied;;55608;06752;Rheinland-Pfalz
Grieben;b Gransee;16775;033086;Brandenburg
Grieben;, Hiddensee;18565;038300;Mecklenburg-Vorpommern
Grieben;b Grevesmühlen;23936;038828;Mecklenburg-Vorpommern
Grieben;b Tangerhütte;39517;039362;Sachsen-Anhalt
Griebo;;06869;034903;Sachsen-Anhalt
Griefstedt;;99638;036375;Thüringen
Gries;, Pfalz;66903;06373;Rheinland-Pfalz
Griesen;, Sachs-Anh;06786;034905;Sachsen-Anhalt
Griesheim;, Hess;64347;06155;Hessen
Griesheim;, Hess;64347;06155;Hessen
Griesheim;b Arnstadt;99326;03629;Thüringen
Griesingen;;89608;07391;Baden-Württemberg
Grießbach;;09435;03725;Sachsen
Grießen;, NL;03172;035696;Brandenburg
Griesstätt;b Wasserburg a Inn;83556;08039;Bayern
Griesstätt;b Großhelfendorf;85655;08095;Bayern
Grillenberg;b Sangerhausen;06528;03464;Sachsen-Anhalt
Grimburg;;54413;06589;Rheinland-Pfalz
Grimma;;04668;03437;Sachsen
Grimme;b Zerbst;39264;039248;Sachsen-Anhalt
Grimmelsberg;, Gem Kirchnüchel;24327;04381;Schlewig-Holstein
Grimmelshausen;;98660;036873;Thüringen
Grimmen;;18507;038326;Mecklenburg-Vorpommern
Grinau;;23847;04501;Schlewig-Holstein
Grischow;b Altentreptow;17089;03969;Mecklenburg-Vorpommern
Gröben;b Weißenfels;06682;034443;Sachsen-Anhalt
Gröben;b Ludwigsfelde;14974;03378;Brandenburg
Gröben;, Gem Amerang;83119;08075;Bayern
Grobengereuth;;07389;036484;Thüringen
Gröbenzell;;82194;08142;Bayern
Gröbern;b Bitterfeld;06773;034955;Sachsen-Anhalt
Gröbers;;06184;034605;Sachsen-Anhalt
Gröbitz;, NL;03238;03531;Brandenburg
Gröbitz;b Weißenfels;06667;034445;Sachsen-Anhalt
Grobleben;;39579;039322;Sachsen-Anhalt
Gröbzig;;06388;034976;Sachsen-Anhalt
Grockstädt;;06268;034461;Sachsen-Anhalt
Gröde;, Hallig;25869;04674;Schlewig-Holstein
Gröden;;04932;035343;Brandenburg
Grödersby;;24376;04642;Schlewig-Holstein
Gröditsch;;15910;035471;Brandenburg
Gröditz;b Riesa;01609;035263;Sachsen
Groitzsch;b Pegau;04539;034296;Sachsen
Grollenhof;;74196;;Baden-Württemberg
Grolsheim;;55459;06727;Rheinland-Pfalz
Grömbach;;72294;07453;Baden-Württemberg
Grömitz;;23743;04562;Schlewig-Holstein
Gröna;;06408;03471;Sachsen-Anhalt
Gronau;(Leine);31028;05182;Niedersachsen
Gronau;(Westf.);48599;02562;Nordrhein-Westfalen
Grönheim;, Gem Molbergen;49696;04479;Niedersachsen
Gröningen;b Oschersleben;39397;039403;Sachsen-Anhalt
Grönwohld;, Kr Stormarn;22956;04154;Schlewig-Holstein
Groptitz;;01594;03525;Sachsen
Groß Ammensleben;;39326;039202;Sachsen-Anhalt
Groß Behnitz;;14641;033239;Brandenburg
Groß Belitz;;18246;038466;Mecklenburg-Vorpommern
Groß Bengerstorf;;19258;038843;Mecklenburg-Vorpommern
Groß Berßen;;49777;05965;Niedersachsen
Groß Beuchow;;03222;03542;Brandenburg
Groß Boden;;23847;04539;Schlewig-Holstein
Groß Bölkow;;18239;038207;Mecklenburg-Vorpommern
Groß Börnecke;;39435;039267;Sachsen-Anhalt
Groß Breese;b Wittenberge, Prignitz;19322;03877;Brandenburg
Groß Briesen;b Belzig;14806;033846;Brandenburg
Groß Brütz;;19071;038874;Mecklenburg-Vorpommern
Groß Buchholz;;19348;03876;Brandenburg
Groß Buchwald;;24582;04322;Schlewig-Holstein
Groß Bünsdorf;;23923;038828;Mecklenburg-Vorpommern
Groß Chüden;;29416;03901;Sachsen-Anhalt
Groß Daberkow;;17348;03967;Mecklenburg-Vorpommern
Groß Disnack;;23911;04544;Schlewig-Holstein
Groß Döbbern;;03058;035608;Brandenburg
Groß Dölln;;17268;039883;Brandenburg
Groß Dratow;;17192;039934;Mecklenburg-Vorpommern
Groß Düben;;02959;035773;Sachsen
Groß Eichholz;;15859;033760;Brandenburg
Groß Eichsen;;19205;038871;Mecklenburg-Vorpommern
Groß Ernsthof;;17440;03836;Mecklenburg-Vorpommern
Groß Flotow;;17219;039928;Mecklenburg-Vorpommern
Groß Fredenwalde;;17268;039887;Brandenburg
Groß Gaglow;;03058;0355;Brandenburg
Groß Garz;;39615;039398;Sachsen-Anhalt
Groß Germersleben;;39398;039408;Sachsen-Anhalt
Groß Gievitz;;17192;039934;Mecklenburg-Vorpommern
Groß Glienicke;;14476;033201;Brandenburg
Groß Godems;;19372;038725;Mecklenburg-Vorpommern
Groß Görnow;;19406;03847;Mecklenburg-Vorpommern
Groß Gottschow;;19348;038784;Brandenburg
Groß Grönau;;23627;04509;Schlewig-Holstein
Groß Haßlow;;16909;03394;Brandenburg
Groß Hauskreuz;;26441;;Niedersachsen
Groß Hundorf;;19217;038872;Mecklenburg-Vorpommern
Groß Ippener;;27243;04224;Niedersachsen
Groß Jehser;;03205;035439;Brandenburg
Groß Kelle;;17207;039931;Mecklenburg-Vorpommern
Groß Kienitz;;15831;033708;Brandenburg
Groß Kiesow;;17495;038353;Mecklenburg-Vorpommern
Groß Kölpin;;17268;039886;Brandenburg
Groß Kölzig;;03159;035600;Brandenburg
Groß Kordshagen;;18442;038231;Mecklenburg-Vorpommern
Groß Köris;;15746;033766;Brandenburg
Groß Krams;;19230;038854;Mecklenburg-Vorpommern
Groß Krankow;;23966;03841;Mecklenburg-Vorpommern
Groß Kreutz;;14550;033207;Brandenburg
Groß Kühren;;19273;038841;Niedersachsen
Groß Kummerfeld;;24626;04393;Schlewig-Holstein
Groß Laasch;;19288;03874;Mecklenburg-Vorpommern
Groß Labenz;;19417;038482;Mecklenburg-Vorpommern
Groß Langerwisch;;16928;03395;Brandenburg
Groß Leine;;15913;03546;Brandenburg
Groß Leppin;;19339;038787;Brandenburg
Groß Leuthen;;15913;035471;Brandenburg
Groß Linde;;19348;03876;Brandenburg
Groß Lindow;;15295;033609;Brandenburg
Groß Lübbenau;;03226;03542;Brandenburg
Groß Lüben;;19336;038791;Brandenburg
Groß Luckow;;17337;039752;Mecklenburg-Vorpommern
Groß Luja;;03130;03563;Brandenburg
Groß Lüsewitz;;18190;038209;Mecklenburg-Vorpommern
Groß Machnow;;15806;03377;Brandenburg
Groß Marzehns;;14823;033848;Brandenburg
Groß Meckelsen;;27419;04282;Niedersachsen
Groß Miltzow;b Oertzenhof, Meckl;17349;03967;Mecklenburg-Vorpommern
Groß Mohrdorf;;18445;038323;Mecklenburg-Vorpommern
Groß Molzahn;;19217;038875;Mecklenburg-Vorpommern
Groß Muckrow;;15868;033673;Brandenburg
Groß Naundorf;b Annaburg;06925;035385;Sachsen-Anhalt
Groß Nemerow;;17094;039605;Mecklenburg-Vorpommern
Groß Neuendorf;, Oderbruch;15324;033478;Brandenburg
Groß Neuleben;;23923;038821;Mecklenburg-Vorpommern
Groß Nieköhr;;17179;039971;Mecklenburg-Vorpommern
Groß Niendorf;b Parchim;19374;0385;Mecklenburg-Vorpommern
Groß Niendorf;, Holst;23816;04552;Schlewig-Holstein
Groß Nienhagen;;18239;038295;Mecklenburg-Vorpommern
Groß Nordende;;25436;04122;Schlewig-Holstein
Groß Oesingen;;29393;05838;Niedersachsen
Groß Offenseth-Aspern;;25355;04123;Schlewig-Holstein
Groß Oßnig;;03058;035608;Brandenburg
Groß Pampau;;21493;04151;Schlewig-Holstein
Groß Pankow;b Pritzwalk;16928;033983;Brandenburg
Groß Pinnow;;16306;033331;Brandenburg
Groß Plasten;;17192;039934;Mecklenburg-Vorpommern
Groß Polzin;;17390;039724;Mecklenburg-Vorpommern
Groß Pravtshagen;;23936;03881;Mecklenburg-Vorpommern
Groß Quenstedt;;38822;039424;Sachsen-Anhalt
Groß Raden;;19406;03847;Mecklenburg-Vorpommern
Groß Rheide;;24872;04627;Schlewig-Holstein
Groß Ridsenow;;18299;038459;Mecklenburg-Vorpommern
Groß Rietz;;15848;033672;Brandenburg
Groß Rode;, Forsthaus;38154;05332;Niedersachsen
Groß Rodensleben;;39167;039293;Sachsen-Anhalt
Groß Roge;;17166;039978;Mecklenburg-Vorpommern
Groß Rönnau;;23795;04551;Schlewig-Holstein
Groß Rosenburg;;39240;039294;Sachsen-Anhalt
Groß Rünz;;19217;038873;Mecklenburg-Vorpommern
Groß Salitz;;19205;038876;Mecklenburg-Vorpommern
Groß Santersleben;;39343;0391;Sachsen-Anhalt
Groß Sarau;;23627;04509;Schlewig-Holstein
Groß Schacksdorf;;03149;035695;Brandenburg
Groß Schauen;;15859;033678;Brandenburg
Groß Schenkenberg;;23860;04508;Schlewig-Holstein
Groß Schierstedt;;06449;03473;Sachsen-Anhalt
Groß Schönebeck;(Schorfheide);16348;033393;Brandenburg
Groß Schoritz;;18574;03838;Mecklenburg-Vorpommern
Groß Schulzendorf;;15806;03378;Brandenburg
Groß Schwansee;;23942;038827;Mecklenburg-Vorpommern
Groß Schwechten;;39579;039328;Sachsen-Anhalt
Groß Schwiesow;;18276;03843;Mecklenburg-Vorpommern
Groß Siemen;;18236;038292;Mecklenburg-Vorpommern
Groß Siemz;;23923;038828;Mecklenburg-Vorpommern
Groß Spiegelberg;;17337;039752;Mecklenburg-Vorpommern
Groß Stieten;;23972;03841;Mecklenburg-Vorpommern
Groß Teetzleben;;17091;03961;Mecklenburg-Vorpommern
Groß Tessin;b Wismar, Meckl;23992;038429;Mecklenburg-Vorpommern
Groß Thurow;;19205;038876;Mecklenburg-Vorpommern
Groß Timkenberg;;19273;038844;Mecklenburg-Vorpommern
Groß Twülpstedt;;38464;05365;Niedersachsen
Groß Vielen;;17217;039921;Mecklenburg-Vorpommern
Groß Vollstedt;;24802;04305;Schlewig-Holstein
Groß Walmstorf;;23948;038428;Mecklenburg-Vorpommern
Groß Warnow;;19357;038788;Brandenburg
Groß Wasserburg;;15910;035473;Brandenburg
Groß Welle;;16866;033977;Brandenburg
Groß Welzin;;19209;038874;Mecklenburg-Vorpommern
Groß Werzin;;19336;038796;Brandenburg
Groß Wieblitz;;29413;039033;Sachsen-Anhalt
Groß Wittensee;;24361;04356;Schlewig-Holstein
Groß Woltersdorf;, Prignitz;16928;03395;Brandenburg
Groß Wüstenfelde;;17168;039975;Mecklenburg-Vorpommern
Groß Ziescht;;15837;033704;Brandenburg
Großaitingen;;86845;08203;Bayern
Großalmerode;;37247;05604;Hessen
Großalsleben;;39387;03949;Sachsen-Anhalt
Großbadegast;;06369;03496;Sachsen-Anhalt
Großbardau;;04668;03437;Sachsen
Großbardorf;;97633;09766;Bayern
Großbarkau;;24245;04302;Schlewig-Holstein
Großbartloff;;37359;036027;Thüringen
Großbeeren;;14979;033701;Brandenburg
Großberndten;;99713;036330;Thüringen
Großbettlingen;;72663;07022;Baden-Württemberg
Großbeuthen;;14974;033731;Brandenburg
Groß-Bieberau;;64401;06162;Hessen
Großbockedra;;07646;036428;Thüringen
Großbodungen;;37345;036077;Thüringen
Großbothen;;04668;034384;Sachsen
Großbottwar;;71723;07148;Baden-Württemberg
Großbreitenbach;;98701;036781;Thüringen
Großbrembach;;99610;036451;Thüringen
Großbrüchter;;99713;036330;Thüringen
Großbundenbach;;66501;06337;Rheinland-Pfalz
Großburschla;, Thür;99830;05655;Thüringen
Großderschau;;16845;033875;Brandenburg
Großdittmannsdorf;;01471;035208;Sachsen
Großdubrau;;02694;035934;Sachsen
Großebersdorf;;07589;036607;Thüringen
Großefehn;;26629;04943;Niedersachsen
Großeibstadt;;97633;09761;Bayern
Grosselfingen;b Hechingen;72415;07476;Baden-Württemberg
Großenaspe;;24623;04327;Schlewig-Holstein
Großenbrode;;23775;04367;Schlewig-Holstein
Großenehrich;;99718;036370;Thüringen
Großengottern;;99991;036022;Thüringen
Großenhain;, Sachs;01558;03522;Sachsen
Großenhof;;23948;03881;Mecklenburg-Vorpommern
Großenkneten;;26197;04435;Niedersachsen
Großen-Luckow;;17166;039933;Mecklenburg-Vorpommern
Großenlüder;;36137;06648;Hessen
Großenlupnitz;;99819;036920;Thüringen
Großenrade;;25712;04825;Schlewig-Holstein
Großensee;, Kr Stormarn;22946;04154;Schlewig-Holstein
Großensee;b Eisenach, Thür;99837;036922;Thüringen
Großenseebach;;91091;09135;Bayern
Großenstein;;07580;036602;Thüringen
Großenwiehe;;24969;04604;Schlewig-Holstein
Großenwörden;;21712;04775;Niedersachsen
Großerkmannsdorf;;01454;03528;Sachsen
Großerlach;;71577;07903;Baden-Württemberg
Großeutersdorf;;07768;036424;Thüringen
Großfahner;;99100;036206;Thüringen
Großfischlingen;;67483;06323;Rheinland-Pfalz
Großfriesen;;08541;03741;Sachsen
Großfriesen;;08541;03741;Sachsen
Groß-Gerau;;64521;06152;Hessen
Großgeschwenda;;07338;036735;Thüringen
Großgörschen;;06686;034444;Sachsen-Anhalt
Großgrimma;;06679;034441;Sachsen-Anhalt
Großhabersdorf;;90613;09105;Bayern
Großhansdorf;;22927;04102;Schlewig-Holstein
Großharrie;;24625;04394;Schlewig-Holstein
Großharthau;;01909;035954;Sachsen
Großharthau-Bühlau;;01909;035954;Sachsen
Großharthau-Schmiedefeld;;01909;035954;Sachsen
Großharthau-Seeligstadt;;01909;035200;Sachsen
Großhartmannsdorf;;09618;037329;Sachsen
Großheide;, Ostfriesl;26532;04936;Niedersachsen
Großheirath;;96269;09565;Bayern
Großhelfendorf;;85655;08095;Bayern
Großhennersdorf;;02747;035873;Sachsen
Großheringen;;99518;036461;Thüringen
Großhesselohe;, Isartal;82049;089;Bayern
Großheubach;;63920;09371;Bayern
Großholbach;;56412;02602;Rheinland-Pfalz
Großhub;;83739;08064;Bayern
Großkampenberg;;54619;06559;Rheinland-Pfalz
Großkarlbach;;67229;06238;Rheinland-Pfalz
Großkarolinenfeld;;83109;08031;Bayern
Großkayna;;06242;034633;Sachsen-Anhalt
Groß-Klessow;;03222;03542;Brandenburg
Großkmehlen;;01990;035755;Brandenburg
Großkochberg;;07407;036743;Thüringen
Großkönigsförde;;24214;04346;Schlewig-Holstein
Großkönigsförderwohld;;24214;04346;Schlewig-Holstein
Großkorbetha;;06688;034446;Sachsen-Anhalt
Großkorga;;06926;035389;Sachsen-Anhalt
Großkoschen;;01968;03573;Brandenburg
Großkrausnik;;03249;035323;Brandenburg
Großkrotzenburg;;63538;06186;Hessen
Großkugel;;06184;0345;Sachsen-Anhalt
Großkundorf;;07980;036623;Thüringen
Großlangenfeld;;54608;06555;Rheinland-Pfalz
Großlangheim;;97320;09325;Bayern
Großlehna;;04420;034205;Sachsen
Großleinungen;;06528;034656;Sachsen-Anhalt
Großliebringen;;99326;03629;Thüringen
Großlittgen;;54534;06575;Rheinland-Pfalz
Großlöbichau;;07751;03641;Thüringen
Großlohra;;99759;036338;Thüringen
Großmaischeid;;56276;02689;Rheinland-Pfalz
Großmannshof;;73345;;Baden-Württemberg
Großmehlra;;99996;036021;Thüringen
Großmehring;;85098;08407;Bayern
Groß-Mehßow;;03205;035435;Brandenburg
Großmölsen;;99198;036203;Thüringen
Großmonra;;99625;03635;Thüringen
Großmühlingen;;39221;039297;Sachsen-Anhalt
Großmutz;;16775;033084;Brandenburg
Großnaundorf;;01936;035955;Sachsen
Großneuhausen;;99625;036372;Thüringen
Großneundorf;;98743;036703;Thüringen
Großniedesheim;;67259;06239;Rheinland-Pfalz
Größnitz;;06632;034464;Sachsen-Anhalt
Großobringen;;99439;03643;Thüringen
Großolbersdorf;;09432;037369;Sachsen
Großörner;;06348;03476;Sachsen-Anhalt
Großostheim;;63762;06026;Bayern
Großpaschleben;;06369;03496;Sachsen-Anhalt
Großpösna;;04463;034297;Sachsen
Großpostwitz;;02692;035938;Sachsen
Großpürschütz;;07751;036424;Thüringen
Großräschen;;01983;035753;Brandenburg
Großrehmühle;;95356;09255;Bayern
Großrheider Ausbau;;24848;;Schlewig-Holstein
Großrheiderfeld;;24848;04624;Schlewig-Holstein
Großrinderfeld;;97950;09344;Baden-Württemberg
Großröda;;04617;03448;Thüringen
Groß-Rohrheim;;68649;06245;Hessen
Großröhrsdorf;, OL;01900;035952;Sachsen
Großromstedt;;99510;036425;Thüringen
Großrosseln;;66352;06809;Saarland
Großrössen;;04895;03535;Brandenburg
Großrückerswalde;;09518;037369;Sachsen
Großrudestedt;;99195;036204;Thüringen
Großschirma;;09603;037328;Sachsen
Großschönau;, Sachs;02779;035841;Sachsen
Großschönau;b Irschenberg;83739;08025;Bayern
Großschwabhausen;;99441;036454;Thüringen
Großschweidnitz;;02708;03585;Sachsen
Großseifen;;56472;02661;Rheinland-Pfalz
Großsolt;;24991;04633;Schlewig-Holstein
Großsteinhausen;;66484;06339;Rheinland-Pfalz
Großthiemig;;04932;035343;Brandenburg
Großtreben-Zwethau;;04886;035386;Sachsen
Groß-Umstadt;;64823;06078;Hessen
Großvargula;;99958;036042;Thüringen
Großwallstadt;;63868;06022;Bayern
Großwechsungen;;99735;036335;Thüringen
Großweil;;82439;08851;Bayern
Großweitzschen;;04720;03431;Sachsen
Großwelsbach;;99947;036043;Thüringen
Großwiesenhof;;92348;09181;Bayern
Großwig;b Torgau;04860;03421;Sachsen
Groß-Wokern;;17166;039978;Mecklenburg-Vorpommern
Großwoltersdorf;;16775;033082;Brandenburg
Großwudicke;;14715;033873;Brandenburg
Großzerlang;;16831;033921;Brandenburg
Großziethen;b Königs Wusterhausen;15831;03379;Brandenburg
Groß-Ziethen;b Eberswalde;16247;033364;Brandenburg
Groß-Ziethen;b Velten;16766;033055;Brandenburg
Groß-Zimmern;;64846;06071;Hessen
Großzöberitz;;06780;034956;Sachsen-Anhalt
Gröst;;06632;034633;Sachsen-Anhalt
Grotenknöll;;24819;;Schlewig-Holstein
Grothusenkoog;;25836;04862;Schlewig-Holstein
Grötsch;;03185;035601;Brandenburg
Grove;;21493;04151;Schlewig-Holstein
Grovehörn;;26802;;Niedersachsen
Groven;, Dithm;25774;04882;Schlewig-Holstein
Grub;b Siegsdorf;83313;;Bayern
Grub;b Neukirchen a Teisenberg;83364;08666;Bayern
Grub;, Gem Amerang;83552;08074;Bayern
Grub;b Irschenberg;83739;08025;Bayern
Grub;a. Forst;96271;09560;Bayern
Grub;b Suhl;98530;036846;Thüringen
Grube;b Perleberg;19336;038791;Brandenburg
Grube;, Holst;23749;04364;Schlewig-Holstein
Grube Alfred;;39240;039291;Sachsen-Anhalt
Grübelsberg;;53547;02683;Rheinland-Pfalz
Gruberhagen;, Gem Grube;23747;04364;Schlewig-Holstein
Grubersmühle;;07343;;Thüringen
Grubnitz;b Wurzen;04828;03425;Sachsen
Grubo;;14823;033849;Brandenburg
Gruel;;18320;038225;Mecklenburg-Vorpommern
Gruibingen;;73344;07335;Baden-Württemberg
Grumbach;b Freital;01723;035204;Sachsen
Grumbach;, Erzgeb;09477;037343;Sachsen
Grumbach;, Glan;67745;06382;Rheinland-Pfalz
Grumbach;b Schmalkalden;98574;03683;Thüringen
Grümpen;;96528;036766;Thüringen
Gruna;b Meißen, Sachs;01683;035242;Sachsen
Grüna;, Sachs;09224;0371;Sachsen
Grüna;, Sachs;09224;0371;Sachsen
Grüna;b Jüterbog;14913;03372;Brandenburg
Grünau;b Saalfeld, Saale;07338;036734;Thüringen
Grünaue;;14712;;Brandenburg
Grünbach;, Vogtl;08223;03745;Sachsen
Grünbach-Muldenberg;;08223;037465;Sachsen
Grunbachsägmühle;;73489;;Baden-Württemberg
Grünberg;b Pasewalk;17326;039742;Brandenburg
Grünberg;, Hess;35305;06401;Hessen
Grund;b Irschenberg;83739;08064;Bayern
Gründau;;63584;06058;Hessen
Grundbach;;83739;08064;Bayern
Grundhof;b Flensburg;24977;04636;Schlewig-Holstein
Grundhof;b Renningen;71272;;Baden-Württemberg
Grundmühle;, Dill;35630;;Hessen
Grundshagen;;23948;038825;Mecklenburg-Vorpommern
Grundsheim;;89613;07357;Baden-Württemberg
Grüne Mühle;b Brodenbach;56332;02605;Rheinland-Pfalz
Grünebach;;57520;02741;Rheinland-Pfalz
Grüneberg;;16775;033094;Brandenburg
Grünefeld;;14641;033230;Brandenburg
Grüneiche;;14778;033835;Brandenburg
Grünenbach;, Allgäu;88167;08383;Bayern
Grünendeich;, Niederelbe;21720;04141;Niedersachsen
Grünenplan;, Flecken Delligsen;31073;05187;Niedersachsen
Grünental;b Albersdorf, Holst;25767;04835;Schlewig-Holstein
Grunewald;, Uckermark;17268;039883;Brandenburg
Grünewald;, OL;01945;035756;Brandenburg
Grünewalde;b Ruhland;01979;03574;Brandenburg
Grünhain;;08358;03774;Sachsen
Grünhainichen;;09579;037294;Sachsen
Grünheide;(Mark);15537;03362;Brandenburg
Grünhof;b Hagenow;19230;03883;Mecklenburg-Vorpommern
Grünhof;, Hessen;34576;05681;Hessen
Grüningen;b Brandenburg an der Havel;14778;033833;Brandenburg
Grünkraut;;88287;0751;Baden-Württemberg
Grunow;, NL;15299;033655;Brandenburg
Grunow;, Märkische Schweiz;15377;033436;Brandenburg
Grünow;b Angermünde;16278;033336;Brandenburg
Grünow;b Neustrelitz;17237;039821;Mecklenburg-Vorpommern
Grünow;b Prenzlau;17291;039857;Brandenburg
Grünsfeld;;97947;09346;Baden-Württemberg
Grünstadt;;67269;06359;Rheinland-Pfalz
Grünwald;, Kr München;82031;089;Bayern
Grünz;;17328;039751;Mecklenburg-Vorpommern
Grüssow;b Malchow, Meckl;17213;039932;Mecklenburg-Vorpommern
Gschwend;b Gaildorf;74417;07972;Baden-Württemberg
Gschwend;b Neukirchen a Teisenberg;83364;08666;Bayern
Gstadt;a. Chiemsee;83257;08054;Bayern
Guben;;03172;03561;Brandenburg
Gubkow;;18190;038209;Mecklenburg-Vorpommern
Gübs;;39175;039292;Sachsen-Anhalt
Güby;;24357;04354;Schlewig-Holstein
Guckheim;;56459;06435;Rheinland-Pfalz
Gückingen;;65558;06432;Rheinland-Pfalz
Gudendorf;, Holst;25693;04859;Schlewig-Holstein
Gudensberg;;34281;05603;Hessen
Guderhandviertel;;21720;04142;Niedersachsen
Gudersleben;;99755;036332;Thüringen
Gudow;b Lübtheen;19249;038855;Mecklenburg-Vorpommern
Gudow;, Kr Hzgt Lauenb;23899;04547;Schlewig-Holstein
Guggenhausen;;88379;07587;Baden-Württemberg
Güglingen;;74363;07135;Baden-Württemberg
Guhlen;;15913;035478;Brandenburg
Gühlen Glienicke;;16818;033929;Brandenburg
Guhlsdorf;;19348;033983;Brandenburg
Guhrow;;03096;035606;Brandenburg
Guldental;;55452;06707;Rheinland-Pfalz
Gülitz;b Perleberg;19348;038782;Brandenburg
Güllesheim;;56593;02687;Rheinland-Pfalz
Gulow;;19348;038782;Brandenburg
Gülpe;;14715;033875;Brandenburg
Gülstorf;;19273;038841;Niedersachsen
Gültz;;17089;03965;Mecklenburg-Vorpommern
Gülze;;19273;038844;Mecklenburg-Vorpommern
Gülzow;b Malchin;17153;039954;Mecklenburg-Vorpommern
Gülzow;b Güstrow;18276;038450;Mecklenburg-Vorpommern
Gülzow;, Kr Hzgt Lauenb;21483;04151;Schlewig-Holstein
Gumbsheim;;55597;06703;Rheinland-Pfalz
Gummersbach;;51643;02261;Nordrhein-Westfalen
Gummersbach;;51645;02261;Nordrhein-Westfalen
Gummersbach;;51647;02261;Nordrhein-Westfalen
Gumpener Kreuz;, Odenwald;64385;;Hessen
Gumpenweiler;b Kreßberg;74594;07957;Bayern
Gumperda;;07768;036422;Thüringen
Gumtow;;16866;033977;Brandenburg
Gundelfingen;, Breisgau;79194;0761;Baden-Württemberg
Gundelfingen;a d Donau;89423;09073;Bayern
Gundelsheim;, Württ;74831;06269;Baden-Württemberg
Gundelsheim;, Oberfr;96163;0951;Bayern
Gunderath;;56767;02657;Rheinland-Pfalz
Gundersheim;, Rheinhess;67598;06244;Rheinland-Pfalz
Gundersleben;;99713;036020;Thüringen
Gundersweiler;;67724;06361;Rheinland-Pfalz
Gundheim;;67599;06244;Rheinland-Pfalz
Gundremmingen;;89355;08224;Bayern
Gunkelrode;;36179;06627;Hessen
Gunningen;;78594;07424;Baden-Württemberg
Günserode;;06567;034671;Thüringen
Gunsleben;;39393;039401;Sachsen-Anhalt
Günstedt;;99631;036374;Thüringen
Günterberg;;16278;033334;Brandenburg
Güntersberge;;06507;039488;Sachsen-Anhalt
Guntersblum;;67583;06249;Rheinland-Pfalz
Güntersleben;, Kr Würzburg;97261;09365;Bayern
Günthersdorf;b Merseburg;06254;034638;Sachsen-Anhalt
Günthersleben;;99869;036256;Thüringen
Günzach;;87634;08372;Bayern
Günzburg;;89312;08221;Bayern
Gunzenhausen;am Altmühlsee;91710;09831;Bayern
Günzerode;;99735;036335;Thüringen
Güritz Försterei;;19294;038755;Mecklenburg-Vorpommern
Gusborn;;29476;05865;Niedersachsen
Güsen;;39317;039344;Sachsen-Anhalt
Gusenburg;;54413;06503;Rheinland-Pfalz
Gusow;;15306;03346;Brandenburg
Güssefeld;;39624;039009;Sachsen-Anhalt
Gussow;;15754;033768;Brandenburg
Gustävel;;19412;038486;Mecklenburg-Vorpommern
Güstebieser Loose;;16259;033452;Brandenburg
Güsten;, Anh;39439;039262;Sachsen-Anhalt
Güster;;21514;04158;Schlewig-Holstein
Gusterath;;54317;06588;Rheinland-Pfalz
Gustow;;18574;038307;Mecklenburg-Vorpommern
Güstow;, Uckermark;17291;03984;Brandenburg
Güstrow;;18273;03843;Mecklenburg-Vorpommern
Gut Werder;;06217;;Sachsen-Anhalt
Gutach;(Schwarzwaldbahn);77793;07833;Baden-Württemberg
Gutach;im Breisgau;79261;07685;Baden-Württemberg
Gute Mühle;;56346;;Rheinland-Pfalz
Guteborn;, OL;01945;035752;Brandenburg
Gutenacker;;56370;0641;Rheinland-Pfalz
Gütenbach;;78148;07723;Baden-Württemberg
Gutenbacherhof;;67822;06358;Rheinland-Pfalz
Gutenberg;b Halle, Saale;06193;034606;Sachsen-Anhalt
Gutenberg;, Kr Bad Kreuznach;55595;06706;Rheinland-Pfalz
Gutendorf;b Bad Berka;99438;036209;Thüringen
Guteneck;, Oberpf;92543;09675;Bayern
Gutengermendorf;;16775;033084;Brandenburg
Gutenpaaren;;14669;033233;Brandenburg
Gutenstetten;;91468;09163;Bayern
Gutenswegen;;39326;039202;Sachsen-Anhalt
Gutenzell-Hürbel;;88484;07352;Baden-Württemberg
Güterfelde;;14532;03329;Brandenburg
Güterglück;;39264;039247;Sachsen-Anhalt
Gütersloh;;33330;05241;Nordrhein-Westfalen
Gütersloh;;33332;05241;Nordrhein-Westfalen
Gütersloh;;33334;05241;Nordrhein-Westfalen
Gütersloh;;33335;05241;Nordrhein-Westfalen
Gutglück;;18356;038231;Mecklenburg-Vorpommern
Guthmannshausen;;99628;036373;Thüringen
Gutow;;18276;038458;Mecklenburg-Vorpommern
Gutsbezirk Münsingen;;72525;07389;Baden-Württemberg
Gutshof Lechfeld;;86836;08232;Bayern
Guttau;, Sachs;02694;035932;Sachsen
Guttenberg;, Oberfr;95358;09225;Bayern
Gutweiler;;54317;06588;Rheinland-Pfalz
Gützkow;b Greifswald;17506;038353;Mecklenburg-Vorpommern
Guxhagen;;34302;05665;Hessen
Gyhum;;27404;04286;Niedersachsen
Haag;, Oberbay;83527;08072;Bayern
Haag;a d Amper;85410;08167;Bayern
Haag;, Oberfr;95473;09201;Bayern
Haage;b Nauen;14662;033238;Brandenburg
Haagwiesen;;73107;;Baden-Württemberg
Haale;;24819;04874;Schlewig-Holstein
Haan;, Rheinl;42781;02129;Nordrhein-Westfalen
Haar;b Neuhaus, Elbe;19273;038841;Niedersachsen
Haar;, Kr München;85540;089;Bayern
Haar;, Kr München;85540;089;Bayern
Haarbach;, Niederbay;94542;08535;Bayern
Haarberg;b Erfurt;99102;0361;Thüringen
Haarberg;b Erfurt;99102;0361;Thüringen
Haaren;b Bad Wilsnack;19336;038791;Brandenburg
Haasow;;03058;0355;Brandenburg
Habach;, Oberbay;82392;08856;Bayern
Habaching;;82392;08847;Bayern
Habel;b Oland, Hallig;25869;;Schlewig-Holstein
Haberhof;, Württ;74632;07942;Baden-Württemberg
Habichtswald;;34317;05606;Hessen
Habighorst;;29359;05142;Niedersachsen
Habscheid;;54597;06556;Rheinland-Pfalz
Haby;;24361;04356;Schlewig-Holstein
Hachelbich;;99706;03632;Thüringen
Hachenburg;;57627;02662;Rheinland-Pfalz
Hachtsee;;82392;08847;Bayern
Hackenheim;;55546;0671;Rheinland-Pfalz
Hackermoos;, Gem Hebertshausen;85764;089;Bayern
Hackpfüffel;;06528;034656;Sachsen-Anhalt
Hadamar;, Westerw;65589;06433;Hessen
Hädchenshof;;23996;038423;Mecklenburg-Vorpommern
Hademstorf;;29693;05071;Niedersachsen
Hadenfeld;;25560;04892;Schlewig-Holstein
Hadmersleben;;39398;039408;Sachsen-Anhalt
Hafenlohr;;97840;09391;Bayern
Haferungen;;99735;036335;Thüringen
Haftelhof;;76889;06342;Rheinland-Pfalz
Hage;, Ostfriesl;26524;04938;Niedersachsen
Hagebök;;23974;038426;Mecklenburg-Vorpommern
Häg-Ehrsberg;;79685;07625;Baden-Württemberg
Hagelberg;;14806;033841;Brandenburg
Hagelstadt;;93095;09453;Bayern
Hagen;b Bad Bramstedt;24576;04192;Schlewig-Holstein
Hagen;b Bremerhaven;27628;04746;Niedersachsen
Hagen;am Teutoburger Wald;49170;05401;Niedersachsen
Hagen;, Westf;58089;02331;Nordrhein-Westfalen
Hagen;, Westf;58091;02331;Nordrhein-Westfalen
Hagen;, Westf;58093;02331;Nordrhein-Westfalen
Hagen;, Westf;58095;02331;Nordrhein-Westfalen
Hagen;, Westf;58097;02331;Nordrhein-Westfalen
Hagen;, Westf;58099;02331;Nordrhein-Westfalen
Hagen;, Westf;58119;02331;Nordrhein-Westfalen
Hagen;, Westf;58135;02331;Nordrhein-Westfalen
Hägen;;25779;04836;Schlewig-Holstein
Hagenbach;, Pfalz;76767;07273;Rheinland-Pfalz
Hagenberg;b Frauenneuharting;83565;08092;Bayern
Hagenbüchach;;91469;09101;Bayern
Hagenburg;b Wunstorf;31558;05033;Niedersachsen
Hagenow;;19230;03883;Mecklenburg-Vorpommern
Hagenow Heide;;19230;03883;Mecklenburg-Vorpommern
Hagermarsch;;26524;04938;Niedersachsen
Hagnau;am Bodensee;88709;07532;Baden-Württemberg
Hahausen;;38729;05383;Niedersachsen
Hahn;am See;56244;06435;Rheinland-Pfalz
Hahn;bei Marienberg;56472;02661;Rheinland-Pfalz
Hahn;, Hunsrück;56850;06543;Rheinland-Pfalz
Hahnbach;, Oberpf;92256;09664;Bayern
Hähnen;, Gem Dattenberg;53562;;Rheinland-Pfalz
Hahnenbach;;55606;06752;Rheinland-Pfalz
Hahnenhorst;;19395;038738;Mecklenburg-Vorpommern
Hahnenkrug;, Gem Lottorf;24884;;Schlewig-Holstein
Hahnenmühle;b Fichtenau;74579;;Baden-Württemberg
Hahn-Flughafen;, Gem Lautzenhausen;55483;;Rheinland-Pfalz
Hahnheim;;55278;06737;Rheinland-Pfalz
Hähnichen;;02923;035894;Sachsen
Hahnstätten;;65623;06430;Rheinland-Pfalz
Hahnweiler;;55776;06789;Rheinland-Pfalz
Haibach;, Unterfr;63808;06021;Bayern
Haibach;, Niederbay;94353;09963;Bayern
Haida;b Elsterwerda;04910;03533;Brandenburg
Haidemühl;;03130;035751;Brandenburg
Haiden;b Gars Bahnhof;83555;08073;Bayern
Haidmühle;, Niederbay;94145;08556;Bayern
Haiger;;35708;02773;Hessen
Haigerloch;;72401;07474;Baden-Württemberg
Haimhausen;, Oberbay;85778;08133;Bayern
Haiming;, Kr Altötting;84533;08678;Bayern
Hain;b Zeulenroda;07957;036625;Thüringen
Haina;(Kloster);35114;06456;Hessen
Haina;b Meiningen;98631;036948;Thüringen
Haina;b Gotha, Thür;99869;036254;Thüringen
Hainau;;56357;06772;Rheinland-Pfalz
Hainburg;, Hess;63512;06182;Hessen
Hainewalde;;02779;035841;Sachsen
Hainfeld;, Pfalz;76835;06323;Rheinland-Pfalz
Hainhof;b Landstuhl;66916;06386;Rheinland-Pfalz
Hainichen;b Jena;07778;036427;Thüringen
Hainichen;, Sachs;09661;037207;Sachsen
Hainrode;b Sangerhausen;06528;034656;Sachsen-Anhalt
Hainrode;b Nordhausen;99735;036334;Thüringen
Hainsfarth;;86744;09082;Bayern
Hainspitz;;07607;036691;Thüringen
Haiterbach;, Württ;72221;07456;Baden-Württemberg
Hakeborn;;39448;039268;Sachsen-Anhalt
Hakenberg;b Fehrbellin;16833;033927;Brandenburg
Hakendorf;b Zarrentin, Meckl;19246;038858;Mecklenburg-Vorpommern
Hakenhof;;25840;04881;Schlewig-Holstein
Hakenstedt;;39343;039409;Sachsen-Anhalt
Halbe;;15757;033765;Brandenburg
Halbemond;;26524;04934;Niedersachsen
Halbendorf;b Weißwasser;02953;035773;Sachsen
Halberstadt;;38820;03941;Sachsen-Anhalt
Halbertshof;;89165;07347;Baden-Württemberg
Halblech;;87642;08368;Bayern
Halbs;;56457;02663;Rheinland-Pfalz
Haldenhof;b Plüderhausen;73655;07182;Baden-Württemberg
Haldensleben;;39340;03904;Sachsen-Anhalt
Haldenwang;, Allgäu;87490;08304;Bayern
Haldenwang;, Kr Günzburg;89356;08222;Bayern
Halen;, Gem Emstek;49685;04474;Niedersachsen
Halenbeck;;16945;033986;Brandenburg
Halfing;;83128;08055;Bayern
Hallabruck;;83359;0861;Bayern
Hallbergmoos;;85399;0811;Bayern
Halle;(Saale);06108;0345;Sachsen-Anhalt
Halle;(Saale);06110;0345;Sachsen-Anhalt
Halle;(Saale);06112;0345;Sachsen-Anhalt
Halle;(Saale);06114;0345;Sachsen-Anhalt
Halle;(Saale);06116;0345;Sachsen-Anhalt
Halle;(Saale);06118;0345;Sachsen-Anhalt
Halle;(Saale);06120;0345;Sachsen-Anhalt
Halle;(Saale);06122;0345;Sachsen-Anhalt
Halle;(Saale);06124;0345;Sachsen-Anhalt
Halle;(Saale);06126;0345;Sachsen-Anhalt
Halle;(Saale);06128;0345;Sachsen-Anhalt
Halle;(Saale);06130;0345;Sachsen-Anhalt
Halle;(Saale);06132;0345;Sachsen-Anhalt
Halle;(Westf.);33790;05201;Nordrhein-Westfalen
Halle;, Kr Holzminden;37620;05533;Niedersachsen
Halle;b Neuenhaus, Dinkel;49843;05942;Niedersachsen
Hallenberg;;59969;02984;Nordrhein-Westfalen
Hallerndorf;;91352;09545;Bayern
Hallgarten;, Pfalz;67826;06362;Rheinland-Pfalz
Hallschlag;;54611;06557;Rheinland-Pfalz
Hallstadt;;96103;0951;Bayern
Hallungen;;99826;036924;Thüringen
Halmannsöd;;83552;08075;Bayern
Hals;, Gem Wüstenrot;71577;07903;Baden-Württemberg
Halsbach;, Kr Altötting;84553;08623;Bayern
Halsbrücke;;09633;03731;Sachsen
Halsdorf;, Eifel;54646;06522;Rheinland-Pfalz
Halsenbach;;56283;06747;Rheinland-Pfalz
Halstenbek;, Holst;25469;04101;Schlewig-Holstein
Haltern am See;;45721;02364;Nordrhein-Westfalen
Halver;;58553;02353;Nordrhein-Westfalen
Halvesbostel;;21646;0451;Niedersachsen
Hambach;b Diez;65582;06432;Rheinland-Pfalz
Hamberge;, Holst;23619;0451;Schlewig-Holstein
Hamberge;b Grevesmühlen;23936;03881;Mecklenburg-Vorpommern
Hambergen;b Bremen;27729;04793;Niedersachsen
Hambrücken;;76707;07255;Baden-Württemberg
Hambuch;, Eifel;56761;02653;Rheinland-Pfalz
Hambühren;;29313;05084;Niedersachsen
Hamburg;;20095;040;Hamburg
Hamburg;;20097;040;Hamburg
Hamburg;;20099;040;Hamburg
Hamburg;;20144;040;Hamburg
Hamburg;;20146;040;Hamburg
Hamburg;;20148;040;Hamburg
Hamburg;;20149;040;Hamburg
Hamburg;;20249;040;Hamburg
Hamburg;;20251;040;Hamburg
Hamburg;;20253;040;Hamburg
Hamburg;;20255;040;Hamburg
Hamburg;;20257;040;Hamburg
Hamburg;;20259;040;Hamburg
Hamburg;;20354;040;Hamburg
Hamburg;;20355;040;Hamburg
Hamburg;;20357;040;Hamburg
Hamburg;;20359;040;Hamburg
Hamburg;;20457;040;Hamburg
Hamburg;;20459;040;Hamburg
Hamburg;;20535;040;Hamburg
Hamburg;;20537;040;Hamburg
Hamburg;;20539;040;Hamburg
Hamburg;;21029;040;Hamburg
Hamburg;;21031;040;Hamburg
Hamburg;;21033;040;Hamburg
Hamburg;;21035;040;Hamburg
Hamburg;;21037;040;Hamburg
Hamburg;;21039;040;Hamburg
Hamburg;;21073;040;Hamburg
Hamburg;;21075;040;Hamburg
Hamburg;;21077;040;Hamburg
Hamburg;;21079;040;Hamburg
Hamburg;;21107;040;Hamburg
Hamburg;;21109;040;Hamburg
Hamburg;;21129;040;Hamburg
Hamburg;;21147;040;Hamburg
Hamburg;;21149;040;Hamburg
Hamburg;;22041;040;Hamburg
Hamburg;;22043;040;Hamburg
Hamburg;;22045;040;Hamburg
Hamburg;;22047;040;Hamburg
Hamburg;;22049;040;Hamburg
Hamburg;;22081;040;Hamburg
Hamburg;;22083;040;Hamburg
Hamburg;;22085;040;Hamburg
Hamburg;;22087;040;Hamburg
Hamburg;;22089;040;Hamburg
Hamburg;;22111;040;Hamburg
Hamburg;;22113;040;Hamburg
Hamburg;;22115;040;Hamburg
Hamburg;;22117;040;Hamburg
Hamburg;;22119;040;Hamburg
Hamburg;;22143;040;Hamburg
Hamburg;;22145;040;Hamburg
Hamburg;;22147;040;Hamburg
Hamburg;;22149;040;Hamburg
Hamburg;;22159;040;Hamburg
Hamburg;;22175;040;Hamburg
Hamburg;;22177;040;Hamburg
Hamburg;;22179;040;Hamburg
Hamburg;;22297;040;Hamburg
Hamburg;;22299;040;Hamburg
Hamburg;;22301;040;Hamburg
Hamburg;;22303;040;Hamburg
Hamburg;;22305;040;Hamburg
Hamburg;;22307;040;Hamburg
Hamburg;;22309;040;Hamburg
Hamburg;;22335;040;Hamburg
Hamburg;;22337;040;Hamburg
Hamburg;;22339;040;Hamburg
Hamburg;;22359;040;Hamburg
Hamburg;;22391;040;Hamburg
Hamburg;;22393;040;Hamburg
Hamburg;;22395;040;Hamburg
Hamburg;;22397;040;Hamburg
Hamburg;;22399;040;Hamburg
Hamburg;;22415;040;Hamburg
Hamburg;;22417;040;Hamburg
Hamburg;;22419;040;Hamburg
Hamburg;;22453;040;Hamburg
Hamburg;;22455;040;Hamburg
Hamburg;;22457;040;Hamburg
Hamburg;;22459;040;Hamburg
Hamburg;;22523;040;Hamburg
Hamburg;;22525;040;Hamburg
Hamburg;;22527;040;Hamburg
Hamburg;;22529;040;Hamburg
Hamburg;;22547;040;Hamburg
Hamburg;;22549;040;Hamburg
Hamburg;;22559;040;Hamburg
Hamburg;;22587;040;Hamburg
Hamburg;;22589;040;Hamburg
Hamburg;;22605;040;Hamburg
Hamburg;;22607;040;Hamburg
Hamburg;;22609;040;Hamburg
Hamburg;;22761;040;Hamburg
Hamburg;;22763;040;Hamburg
Hamburg;;22765;040;Hamburg
Hamburg;;22767;040;Hamburg
Hamburg;;22769;040;Hamburg
Hamburg-Insel Neuwerk;;27499;04721;Hamburg
Hamburg-Insel Neuwerk;;27499;04721;Hamburg
Hamdorf;b Rendsburg;24805;04332;Schlewig-Holstein
Hämelhausen;;27324;04254;Niedersachsen
Hameln;;31785;05151;Niedersachsen
Hameln;;31787;05151;Niedersachsen
Hameln;;31789;05151;Niedersachsen
Hamersen;;27419;04282;Niedersachsen
Hamersleben;;39393;039401;Sachsen-Anhalt
Hämerten;;39590;039322;Sachsen-Anhalt
Hamfelde, Kr Hzgt Lauenburg;;22929;04154;Schlewig-Holstein
Hamfelde, Kr Stormarn;;22929;04154;Schlewig-Holstein
Hamm;, Eifel;54636;06569;Rheinland-Pfalz
Hamm;(Sieg);57577;02682;Rheinland-Pfalz
Hamm;, Westf;59063;02381;Nordrhein-Westfalen
Hamm;, Westf;59065;02381;Nordrhein-Westfalen
Hamm;, Westf;59067;02381;Nordrhein-Westfalen
Hamm;, Westf;59069;02381;Nordrhein-Westfalen
Hamm;, Westf;59071;02381;Nordrhein-Westfalen
Hamm;, Westf;59073;02381;Nordrhein-Westfalen
Hamm;, Westf;59075;02381;Nordrhein-Westfalen
Hamm;, Westf;59077;02381;Nordrhein-Westfalen
Hamm;, Rheinhess;67580;06246;Rheinland-Pfalz
Hamma;;99765;036333;Thüringen
Hammah;, Niederelbe;21714;04144;Niedersachsen
Hammelburg;;97762;09732;Bayern
Hammelspring;;17268;03987;Brandenburg
Hammer;b Oranienburg;16559;033393;Brandenburg
Hammer;a.d. Uecker;17358;03976;Mecklenburg-Vorpommern
Hammer;, Württ;78580;;Baden-Württemberg
Hammerbirkenfeld;;55758;06786;Rheinland-Pfalz
Hammerbrücke;;08269;037465;Sachsen
Hammermühle;b Selters, Westerwald;56242;02626;Rheinland-Pfalz
Hammermühle;b Westerburg, Westerw;56459;02663;Rheinland-Pfalz
Hammersbach;, Hess;63546;06185;Hessen
Hammersfeld;;99326;03629;Thüringen
Hammerstedt;;99441;036453;Thüringen
Hammerstein;, Rhein;56598;02635;Rheinland-Pfalz
Hamminkeln;;46499;02852;Nordrhein-Westfalen
Hammoor;b Ahrensburg;22941;04532;Schlewig-Holstein
Hamwarde;;21502;04152;Schlewig-Holstein
Hamweddel;;24816;04875;Schlewig-Holstein
Hanau;;63450;06181;Hessen
Hanau;;63452;06181;Hessen
Hanau;;63454;06181;Hessen
Hanau;;63456;06181;Hessen
Hanau;;63457;06181;Hessen
Hanchesmühle;, Gem Birstein;63699;;Hessen
Handeloh;;21256;04188;Niedersachsen
Handewitt;;24983;04608;Schlewig-Holstein
Handorf;, Winsener Marsch;21447;0451;Niedersachsen
Handrup;;49838;05904;Niedersachsen
Hanerau-Hademarschen;;25557;04872;Schlewig-Holstein
Hanffabrik;;14641;03321;Brandenburg
Hangelsberg;;15518;033632;Brandenburg
Hangen-Weisheim;;55234;06735;Rheinland-Pfalz
Hanhofen;;67374;06344;Rheinland-Pfalz
Hänichen;b Freital;01728;0351;Sachsen
Hankensbüttel;;29386;05832;Niedersachsen
Hann. Münden;;34346;05545;Niedersachsen
Hannover;;30159;0511;Niedersachsen
Hannover;;30161;0511;Niedersachsen
Hannover;;30163;0511;Niedersachsen
Hannover;;30165;0511;Niedersachsen
Hannover;;30167;0511;Niedersachsen
Hannover;;30169;0511;Niedersachsen
Hannover;;30171;0511;Niedersachsen
Hannover;;30173;0511;Niedersachsen
Hannover;;30175;0511;Niedersachsen
Hannover;;30177;0511;Niedersachsen
Hannover;;30179;0511;Niedersachsen
Hannover;;30419;0511;Niedersachsen
Hannover;;30449;0511;Niedersachsen
Hannover;;30451;0511;Niedersachsen
Hannover;;30453;0511;Niedersachsen
Hannover;;30455;0511;Niedersachsen
Hannover;;30457;0511;Niedersachsen
Hannover;;30459;0511;Niedersachsen
Hannover;;30519;0511;Niedersachsen
Hannover;;30521;0511;Niedersachsen
Hannover;;30539;0511;Niedersachsen
Hannover;;30559;0511;Niedersachsen
Hannover;;30625;0511;Niedersachsen
Hannover;;30627;0511;Niedersachsen
Hannover;;30629;0511;Niedersachsen
Hannover;;30655;0511;Niedersachsen
Hannover;;30657;0511;Niedersachsen
Hannover;;30659;0511;Niedersachsen
Hannover;;30669;0511;Niedersachsen
Hanroth;;56316;02684;Rheinland-Pfalz
Hanshagen;b Greifswald;17509;038352;Mecklenburg-Vorpommern
Hanshagen;b Grevesmühlen;23936;038822;Mecklenburg-Vorpommern
Hanstedt;, Nordheide;21271;04184;Niedersachsen
Hanstedt;, Kr Uelzen;29582;05822;Niedersachsen
Hanstorf;b Bad Doberan;18239;038207;Mecklenburg-Vorpommern
Hanstorf;b Grevesmühlen;23936;038824;Mecklenburg-Vorpommern
Hanum;;38489;039003;Sachsen-Anhalt
Happurg;;91230;09151;Bayern
Harbach;b Betzdorf, Sieg;57572;02734;Rheinland-Pfalz
Harbarnsen;;31097;05183;Niedersachsen
Harbke;;39365;039406;Sachsen-Anhalt
Harburg;(Schwaben);86655;09003;Bayern
Hardebek;;24616;04324;Schlewig-Holstein
Hardegsen;;37181;05505;Niedersachsen
Hardert;;56579;02634;Rheinland-Pfalz
Hardheim;, Odenw;74736;06283;Baden-Württemberg
Hardisleben;;99628;036377;Thüringen
Hardt;, Westerw;56472;02661;Rheinland-Pfalz
Hardt;b Schramberg;78739;07422;Baden-Württemberg
Hardt;b Sinzing;93152;09404;Bayern
Hardthausen;am Kocher;74239;07139;Baden-Württemberg
Hardthof;b Markgröningen;71706;07145;Baden-Württemberg
Hardtmühle;b Schwalmtal, Hess;36304;;Hessen
Haren;(Ems);49733;05932;Niedersachsen
Harfenmühle;;55758;06786;Rheinland-Pfalz
Hargarten;, Eifel;54649;06554;Rheinland-Pfalz
Hargesheim;;55595;0671;Rheinland-Pfalz
Harkensee;;23942;038826;Mecklenburg-Vorpommern
Harkerode;;06543;034742;Sachsen-Anhalt
Harmsdorf;, Holst;23738;04363;Schlewig-Holstein
Harmsdorf;b Ratzeburg;23911;04541;Schlewig-Holstein
Harmshagen;;23936;038822;Mecklenburg-Vorpommern
Harmstorf;, Kr Harburg;21228;0451;Niedersachsen
Harnekop;;16269;033436;Brandenburg
Harpstedt;;27243;04244;Niedersachsen
Harra;;07366;036642;Thüringen
Harras;b Eisfeld;98673;03686;Thüringen
Harrasmühle;;07806;036481;Thüringen
Harraß;b Wörnsmühl;83739;08025;Bayern
Harres;, Gem Hettenshausen;85304;08444;Bayern
Harrislee;;24955;0461;Schlewig-Holstein
Harschbach;;56307;02684;Rheinland-Pfalz
Harschbacherfeld;;56459;02664;Rheinland-Pfalz
Harscheid;b Adenau;53520;02695;Rheinland-Pfalz
Harsdorf;, Oberfr;95499;09203;Bayern
Harsefeld;;21698;04164;Niedersachsen
Harsewinkel;;33428;05247;Nordrhein-Westfalen
Harsleben;;38829;03941;Sachsen-Anhalt
Harspelt;;54617;06559;Rheinland-Pfalz
Harst;;19243;038852;Mecklenburg-Vorpommern
Harsum;;31177;05127;Niedersachsen
Hartenfels;;56244;02626;Rheinland-Pfalz
Hartenholm;;24628;04195;Schlewig-Holstein
Hartensdorf;;15864;033672;Brandenburg
Hartenstein;b Zwickau;08118;037605;Sachsen
Hartenstein;, Mittelfr;91235;09152;Bayern
Hartha;b Döbeln;04746;034328;Sachsen
Harthausen;, Pfalz;67376;06344;Rheinland-Pfalz
Hartheim;, Breisgau;79258;07633;Baden-Württemberg
Harthhaus;;99947;;Thüringen
Harthof;b Eichstätt, Bay;85072;08421;Bayern
Härtlingen;;56459;02663;Rheinland-Pfalz
Hartmannsdorf;b Gera;07586;036605;Thüringen
Hartmannsdorf;b Eisenberg, Thür;07613;036693;Thüringen
Hartmannsdorf;b Kirchberg, Sachs;08107;037602;Sachsen
Hartmannsdorf;b Chemnitz, Sachs;09232;03722;Sachsen
Hartmannsdorf;b Fürstenwalde, Spree;15528;033633;Brandenburg
Hartmannsdorf-Reichenau;;01762;037326;Sachsen
Hartmannsgrün;b Auerbach, Vogtl;08233;037468;Sachsen
Harxheim;, Rheinhess;55296;06138;Rheinland-Pfalz
Harzgerode;;06493;039484;Sachsen-Anhalt
Harzungen;;99762;036331;Thüringen
Hasbergen;, Kr Osnabrück;49205;05405;Niedersachsen
Hasborn;, Eifel;54533;06574;Rheinland-Pfalz
Haschaberg;;93449;09975;Bayern

Haschbach;am Remigiusberg;66871;06381;Rheinland-Pfalz
Hasel;;79686;07762;Baden-Württemberg
Haselau;;25489;04129;Schlewig-Holstein
Haselbach;b Altenburg, Thür;04617;034343;Thüringen
Haselbach;b Mitterfels;94354;09961;Bayern
Haselbach;b Sonneberg, Thür;96523;036762;Thüringen
Haselbach-Söldhaus;;73553;07171;Baden-Württemberg
Haselbach-Söldhaus;;73553;07171;Baden-Württemberg
Haseldell;;66904;06383;Rheinland-Pfalz
Haseldorf;;25489;04129;Schlewig-Holstein
Haselhof;b Plüderhausen;73655;07182;Baden-Württemberg
Haseloff;;14823;033843;Brandenburg
Haselund;;25855;04843;Schlewig-Holstein
Haselünne;;49740;05961;Niedersachsen
Häsen;;16775;033084;Brandenburg
Haseneck;b Rathsmannsdorf;94565;;Bayern
Hasenfelde;;15518;033635;Brandenburg
Hasenkrug;b Brokstedt;24616;04324;Schlewig-Holstein
Hasenmoor;;24640;04195;Schlewig-Holstein
Hasenmühle;, Rhein-Lahn-Kr;56357;06772;Rheinland-Pfalz
Hasenmühle;, Taunus;65510;;Hessen
Hasenthal;;96523;03681;Thüringen
Hasenwinkel;b Pritzwalk;16928;03395;Brandenburg
Hasenwinkel;b Sternberg;19417;03847;Mecklenburg-Vorpommern
Haserich;;56858;06545;Rheinland-Pfalz
Haslach;im Kinzigtal;77716;07832;Baden-Württemberg
Hasloch;, Main;97907;09342;Bayern
Hasloh;;25474;04106;Schlewig-Holstein
Haßbergen;;31626;05024;Niedersachsen
Hassel;(Weser);27324;04254;Niedersachsen
Hassel;b Stendal;39596;039321;Sachsen-Anhalt
Hasselbach;, Hunsrück;56288;06762;Rheinland-Pfalz
Hasselbach;, Westerw;57635;02686;Rheinland-Pfalz
Hasselberg;, Ostsee;24376;04642;Schlewig-Holstein
Hasselfelde;;38899;039459;Sachsen-Anhalt
Hasselroth;;63594;06055;Hessen
Hassendorf;b Rotenburg, Wümme;27367;04264;Niedersachsen
Haßfurt;;97437;09521;Bayern
Haßleben;, Thür;99189;036201;Thüringen
Haßloch;, Pfalz;67454;06324;Rheinland-Pfalz
Haßmersheim;;74855;06266;Baden-Württemberg
Haßmoor;;24790;04331;Schlewig-Holstein
Haste;b Wunstorf;31559;05723;Niedersachsen
Hastorf;b Bad Doberan;18239;038207;Mecklenburg-Vorpommern
Hastrungsfeld-Burla;;99848;03622;Thüringen
Hathenow;;15328;033601;Brandenburg
Hatten;, Oldb;26209;04482;Niedersachsen
Hattengehau;;99831;;Thüringen
Hattenhofen;, Württ;73110;07164;Baden-Württemberg
Hattenhofen;, Kr Fürstenfeldbruck;82285;08202;Bayern
Hattersbüllhallig;b Niebüll;25923;04664;Schlewig-Holstein
Hattersheim;am Main;65795;06190;Hessen
Hattert;;57644;02662;Rheinland-Pfalz
Hattgenstein;;55767;06782;Rheinland-Pfalz
Hatthal;;83552;08074;Bayern
Hattingen;, Ruhr;45525;02324;Nordrhein-Westfalen
Hattingen;, Ruhr;45527;02324;Nordrhein-Westfalen
Hattingen;, Ruhr;45529;02324;Nordrhein-Westfalen
Hattorf;am Harz;37197;05584;Niedersachsen
Hattstedt;;25856;04846;Schlewig-Holstein
Hattstedtermarsch;;25856;04846;Schlewig-Holstein
Hatzenbühl;;76770;07275;Rheinland-Pfalz
Hatzenport;;56332;02605;Rheinland-Pfalz
Hatzfeld;(Eder);35116;06467;Hessen
Hatzl;;83739;08064;Bayern
Haubitz;b Grimma;04668;03437;Sachsen
Hauenstein;, Pfalz;76846;06392;Rheinland-Pfalz
Haufeld;;07407;036743;Thüringen
Hauheck;;24376;;Schlewig-Holstein
Haundorf;am Brombachsee;91729;09837;Bayern
Hauneck;;36282;06621;Hessen
Haunetal;;36166;06673;Hessen
Haunsheim;;89437;09073;Bayern
Hauptstuhl;;66851;06372;Rheinland-Pfalz
Hauroth;;56761;02653;Rheinland-Pfalz
Hausach;, Schwarzwaldbahn;77756;07831;Baden-Württemberg
Hausbay;;56291;06746;Rheinland-Pfalz
Hausdorf;b Rochlitz;09306;034381;Sachsen
Hausen;, Eichsfeld;37327;03605;Thüringen
Hausen;(Wied);53547;02638;Rheinland-Pfalz
Hausen;, Hunsrück;55608;06544;Rheinland-Pfalz
Hausen;b Aschaffenburg;63840;06022;Bayern
Hausen;am Tann;72361;07436;Baden-Württemberg
Hausen;ob Verena;78595;07424;Baden-Württemberg
Hausen;im Wiesental;79688;07622;Baden-Württemberg
Hausen;am Bussen;89597;07393;Baden-Württemberg
Hausen;, Oberfr;91353;09191;Bayern
Hausen;, Niederbay;93345;09448;Bayern
Hausen;b. Würzburg;97262;09367;Bayern
Hausen;, Rhön;97647;09778;Bayern
Häusern;, Schwarzw;79837;07672;Baden-Württemberg
Hausham;;83734;08026;Bayern
Häuslarn;;93449;09975;Bayern
Häuslingen;, Aller;27336;05165;Niedersachsen
Hausneindorf;;06458;039481;Sachsen-Anhalt
Haussömmern;;99955;036041;Thüringen
Hausten;;56745;02655;Rheinland-Pfalz
Hausweiler;b Lauterecken;67742;06382;Rheinland-Pfalz
Hauteroda;;06577;034673;Thüringen
Hauzenberg;, Niederbay;94051;08586;Bayern
Havekost;b Schwarzenbek;21493;04151;Schlewig-Holstein
Havelberg;;39539;039387;Sachsen-Anhalt
Häven;b Brüel;19412;038483;Mecklenburg-Vorpommern
Haverlah;;38275;05341;Niedersachsen
Havetoft;;24873;04603;Schlewig-Holstein
Havetoftloit;;24875;04603;Schlewig-Holstein
Havixbeck;;48329;02507;Nordrhein-Westfalen
Hawangen;;87749;08332;Bayern
Hayingen;;72534;07386;Baden-Württemberg
Hayn;(Harz);06536;034658;Sachsen-Anhalt
Hayn;, Thür;99198;036209;Thüringen
Haynrode;;37339;036077;Thüringen
Haynsburg;;06712;034425;Sachsen-Anhalt
Hebertsfelden;;84332;08721;Bayern
Hebertshausen;;85241;089;Bayern
Hechendorf;b Wiehe;06571;034672;Thüringen
Hechingen;;72379;07471;Baden-Württemberg
Hechthausen;;21755;04774;Niedersachsen
Heckelberg;;16259;033451;Brandenburg
Hecken;, Hunsrück;55481;06763;Rheinland-Pfalz
Heckenbach;;53506;02647;Rheinland-Pfalz
Heckenhof;, Eifel;56826;;Rheinland-Pfalz
Heckenhof;b Hamm, Sieg;57539;02682;Rheinland-Pfalz
Heckenmünster;;54518;06508;Rheinland-Pfalz
Heckhuscheid;;54619;06559;Rheinland-Pfalz
Hecklingen;b Staßfurt;39444;03925;Sachsen-Anhalt
Heddert;;54429;06589;Rheinland-Pfalz
Heddesbach;;69434;06272;Baden-Württemberg
Heddesheim;, Baden;68542;06203;Baden-Württemberg
Hedeper;;38322;05336;Niedersachsen
Hedersleben;b Lutherstadt Eisleben;06295;03475;Sachsen-Anhalt
Hedersleben;b Aschersleben, Sachs-Anh;06458;039481;Sachsen-Anhalt
Hedwigenkoog;;25761;04833;Schlewig-Holstein
Heede;b Barmstedt;25355;04123;Schlewig-Holstein
Heede;, Ems;26892;04963;Niedersachsen
Heeg;, Westerw;53547;02638;Rheinland-Pfalz
Heek;;48619;02568;Nordrhein-Westfalen
Heemsen;;31622;05024;Niedersachsen
Heere;;38277;05345;Niedersachsen
Heeren;;39590;03931;Sachsen-Anhalt
Heerhof;;73441;07362;Baden-Württemberg
Heerstedt;;27616;04747;Niedersachsen
Heeslingen;;27404;04287;Niedersachsen
Heeßen;;31707;05722;Niedersachsen
Hefersweiler;;67753;06363;Rheinland-Pfalz
Hegebuchenbusch;, Gem Heidmühlen, Holst;24576;04195;Schlewig-Holstein
Hegerhof;;56581;02638;Rheinland-Pfalz
Hehlen;b Holzminden;37619;05533;Niedersachsen
Hehm;;25776;04882;Schlewig-Holstein
Heichelheim;;99439;03643;Thüringen
Heide;b Boizenburg;19258;038847;Mecklenburg-Vorpommern
Heide;, Holst;25746;0481;Schlewig-Holstein
Heidebrink;;18510;;Mecklenburg-Vorpommern
Heideck;, Mittelfr;91180;09177;Bayern
Heidehäuser;b Riesa;01609;;Sachsen
Heidehof;b Simmern, Hunsrück;55469;06761;Rheinland-Pfalz
Heidehof;b Idar-Oberstein;55743;;Rheinland-Pfalz
Heidekamp;;23858;04533;Schlewig-Holstein
Heidekrug;b Boizenburg;19258;038842;Mecklenburg-Vorpommern
Heideland;b Eisenberg, Thür;07613;036691;Thüringen
Heidelberg;b Pritzwalk;16928;033984;Brandenburg
Heidelberg;, Neckar;69115;06221;Baden-Württemberg
Heidelberg;, Neckar;69117;06221;Baden-Württemberg
Heidelberg;, Neckar;69118;06221;Baden-Württemberg
Heidelberg;, Neckar;69120;06221;Baden-Württemberg
Heidelberg;, Neckar;69121;06221;Baden-Württemberg
Heidelberg;, Neckar;69123;06221;Baden-Württemberg
Heidelberg;, Neckar;69124;06221;Baden-Württemberg
Heidelberg;, Neckar;69126;06221;Baden-Württemberg
Heideloh;, Sachs-Anh;06780;034956;Sachsen-Anhalt
Heidelsburg;, Forsthaus;67714;;Rheinland-Pfalz
Heiden;, Kr Borken, Westf;46359;02867;Nordrhein-Westfalen
Heidenau;, Sachs;01809;03529;Sachsen
Heidenau;, Nordheide;21258;04182;Niedersachsen
Heidenburg;;54426;06509;Rheinland-Pfalz
Heidenheim;an der Brenz;89518;07321;Baden-Württemberg
Heidenheim;an der Brenz;89520;07321;Baden-Württemberg
Heidenheim;an der Brenz;89522;07321;Baden-Württemberg
Heidenheim;, Mittelfr;91719;09833;Bayern
Heidenrod;;65321;06120;Hessen
Heidersbacher Mühle;;74834;06293;Baden-Württemberg
Heidersdorf;;09526;037362;Sachsen
Heidesheim;am Rhein;55262;06132;Rheinland-Pfalz
Heidgraben;;25436;04122;Schlewig-Holstein
Heidhof;b Dömitz;19303;038758;Mecklenburg-Vorpommern
Heidkaten;b Geesthacht;21502;04152;Schlewig-Holstein
Heidkrug;, Gem Osterrönfeld;24790;04331;Schlewig-Holstein
Heidmoor;, Holst;24632;04192;Schlewig-Holstein
Heidmühlen;, Holst;24598;04320;Schlewig-Holstein
Heidweiler;b Wittlich;54518;06508;Rheinland-Pfalz
Heigenbrücken;;63869;06020;Bayern
Heikendorf;;24226;0431;Schlewig-Holstein
Heilbach;, Eifel;54673;06564;Rheinland-Pfalz
Heilbad Heiligenstadt;;37308;036082;Thüringen
Heilberscheid;;56412;06485;Rheinland-Pfalz
Heilbronn;, Neckar;74072;07131;Baden-Württemberg
Heilbronn;, Neckar;74074;07131;Baden-Württemberg
Heilbronn;, Neckar;74076;07131;Baden-Württemberg
Heilbronn;, Neckar;74078;07131;Baden-Württemberg
Heilbronn;, Neckar;74080;07131;Baden-Württemberg
Heilbronn;, Neckar;74081;07131;Baden-Württemberg
Heilenbach;;54636;06569;Rheinland-Pfalz
Heiligenberg;, Baden;88633;07554;Baden-Württemberg
Heiligenbruck;;73577;;Baden-Württemberg
Heiligenfelde;, Altmark;39606;039399;Sachsen-Anhalt
Heiligengrabe;;16909;033962;Brandenburg
Heiligenhafen;, Holst;23774;04362;Schlewig-Holstein
Heiligenhagen;;18239;038295;Mecklenburg-Vorpommern
Heiligenhaus;b Velbert;42579;02056;Nordrhein-Westfalen
Heiligenhaus;b Velbert;42579;02056;Nordrhein-Westfalen
Heiligenkreuz;b Naumburg, Saale;06618;034466;Sachsen-Anhalt
Heiligenmoschel;;67699;06363;Rheinland-Pfalz
Heiligenroth;;56412;02602;Rheinland-Pfalz
Heiligenstadt;, Oberfr;91332;09198;Bayern
Heiligenstedten;;25524;04821;Schlewig-Holstein
Heiligenstedtenerkamp;;25524;04821;Schlewig-Holstein
Heiligenthal;b Hettstedt, Sachs-Anh;06347;034783;Sachsen-Anhalt
Heiligkreuzsteinach;;69253;06220;Baden-Württemberg
Heilingen;;07407;036742;Thüringen
Heilsberg;b Rudolstadt;07407;036744;Thüringen
Heilsbronn;;91560;09872;Bayern
Heilshoop;;23619;04506;Schlewig-Holstein
Heimatshofen;;85655;08095;Bayern
Heimbach;, Eifel;52396;02446;Nordrhein-Westfalen
Heimbach;, Nahe;55779;06789;Rheinland-Pfalz
Heimborn;;57629;02688;Rheinland-Pfalz
Heimbuch;b Soltau;29640;05198;Niedersachsen
Heimbuchenthal;;63872;06092;Bayern
Heimburg;;38889;03944;Sachsen-Anhalt
Heimenkirch;;88178;08381;Bayern
Heimertingen;;87751;08335;Bayern
Heimgarten;, Gem Eglfing;82449;08846;Bayern
Heimgarten;, Gem Frauenneuharting;83565;08092;Bayern
Heimsheim;;71296;07044;Baden-Württemberg
Heimweiler;;55606;06757;Rheinland-Pfalz
Heinade;;37627;05532;Niedersachsen
Heinbockel;;21726;04144;Niedersachsen
Heinersbrück;;03185;035601;Brandenburg
Heinersdorf;b Ludwigsfelde;14979;033701;Brandenburg
Heinersdorf;b Fürstenwalde, Spree;15518;033432;Brandenburg
Heinersdorf;b Sonneberg, Thür;96515;03675;Thüringen
Heinersreuth;, Kr Bayreuth;95500;0921;Bayern
Heiningen;b Wolfenbüttel;38312;05334;Niedersachsen
Heiningen;, Kr Göppingen;73092;07161;Baden-Württemberg
Heinkenborstel;;24594;04873;Schlewig-Holstein
Heinrichsberg;b Wolmirstedt;39326;039208;Sachsen-Anhalt
Heinrichsdorf;b Neuruppin;16831;033931;Brandenburg
Heinrichsdorf;b Waging a See;83329;08681;Bayern
Heinrichsegen;;35630;;Hessen
Heinrichshof;b Bad Ems;56130;02603;Rheinland-Pfalz
Heinrichsruh;, Vorpommern;17379;039778;Mecklenburg-Vorpommern
Heinrichsthal;, Unterfr;63871;06020;Bayern
Heinrichswalde;b Torgelow, Vorpommern;17379;039772;Mecklenburg-Vorpommern
Heinsberg;, Rheinl;52525;02452;Nordrhein-Westfalen
Heinsdorf;;14913;033744;Brandenburg
Heinsdorfergrund;;08468;03765;Sachsen
Heinsen;, Weser;37649;05535;Niedersachsen
Heinzenbach;;55483;06763;Rheinland-Pfalz
Heinzenberg;b Kirn, Nahe;55606;06754;Rheinland-Pfalz
Heinzenberger-Gesellschaftsmühle;;55606;06754;Rheinland-Pfalz
Heinzenhausen;;67742;06382;Rheinland-Pfalz
Heisdorf;;54614;06553;Rheinland-Pfalz
Heißkistler;;83739;;Bayern
Heist;;25492;04122;Schlewig-Holstein
Heistenbach;, Rhein-Lahn-Kr;65558;06432;Rheinland-Pfalz
Heitersheim;;79423;07634;Baden-Württemberg
Helbigsdorf;b Freiberg, Sachs;09619;037320;Sachsen
Helbigsdorf-Blankenstein;;01723;035209;Sachsen
Helbra;;06311;034772;Sachsen-Anhalt
Heldenmühle;;74564;07951;Baden-Württemberg
Heldenstein;, Oberbay;84431;08636;Bayern
Heldrungen;;06577;034673;Thüringen
Helfendorf;;85655;08093;Bayern
Helferskirchen;;56244;02626;Rheinland-Pfalz
Helgoland;;27498;04725;Schlewig-Holstein
Hellbornerhof;, Gem Schmitshausen;66917;06375;Rheinland-Pfalz
Hellburg;;19348;038789;Brandenburg
Helle;b Pritzwalk;16928;03395;Brandenburg
Hellenhahn-Schellenberg;;56479;02664;Rheinland-Pfalz
Hellenthal;, Eifel;53940;02482;Nordrhein-Westfalen
Hellertshausen;;55758;06786;Rheinland-Pfalz
Hellingen;b Hildburghausen;98663;036871;Thüringen
Hellmannsberg;b Waging a See;83329;08681;Bayern
Hellschen-Heringsand-Unterschaar;;25764;04833;Schlewig-Holstein
Hellwege;b Rotenburg, Wümme;27367;04264;Niedersachsen
Helmbrechts;, Oberfr;95233;09252;Bayern
Helmenzen;;57612;02681;Rheinland-Pfalz
Helmeroth;;57612;02682;Rheinland-Pfalz
Helmershausen;;98617;036943;Thüringen
Helmsdorf;b Leinefelde;37351;036075;Thüringen
Helmsgrün;b Lobenstein;07356;036651;Thüringen
Helmsgrün;b Plauen;08543;037439;Sachsen
Helmstadt;, Unterfr;97264;09369;Bayern
Helmstadt-Bargen;;74921;07263;Baden-Württemberg
Helmstedt;;38350;05351;Niedersachsen
Helmstorf;b Lütjenburg;24321;04381;Schlewig-Holstein
Helpsen;;31691;05724;Niedersachsen
Helpt;;17349;0395;Mecklenburg-Vorpommern
Helsa;;34298;05605;Hessen
Helse;;25709;04851;Schlewig-Holstein
Heltersberg;;67716;06333;Rheinland-Pfalz
Helvesiek;;27389;04267;Niedersachsen
Hemau;;93155;09491;Bayern
Hemdingen;;25485;04123;Schlewig-Holstein
Hemer;;58675;02372;Nordrhein-Westfalen
Hemhofen;;91334;09195;Bayern
Hemleben;;06577;034673;Thüringen
Hemme;, Holst;25774;04837;Schlewig-Holstein
Hemmelzen;;57612;02681;Rheinland-Pfalz
Hemmersheim;;97258;09848;Bayern
Hemmingen;, Han;30966;0511;Niedersachsen
Hemmingen;, Württ;71282;07150;Baden-Württemberg
Hemmingstedt;;25770;0481;Schlewig-Holstein
Hemmoor;;21745;04771;Niedersachsen
Hemsbach;, Bergstr;69502;06201;Baden-Württemberg
Hemsbünde;;27386;04266;Niedersachsen
Hemslingen;;27386;04266;Niedersachsen
Hemsloh;;49453;05446;Niedersachsen
Hemstedt;;39638;03907;Sachsen-Anhalt
Henau;, Hunsrück;55490;06765;Rheinland-Pfalz
Hendungen;;97640;09776;Bayern
Henfenfeld;;91239;09151;Bayern
Henfstädt;;98660;036873;Thüringen
Hengelbach;;07422;036739;Thüringen
Hengersberg;b Deggendorf;94491;09901;Bayern
Hengstbacherhof;;67822;06362;Rheinland-Pfalz
Henkensägmühle;;73489;07967;Baden-Württemberg
Henne;;06618;03445;Sachsen-Anhalt
Henneberg;b Meiningen;98617;036945;Thüringen
Hennef;(Sieg);53773;02242;Nordrhein-Westfalen
Hennersdorf;b Doberlug-Kirchhain;03253;035322;Brandenburg
Hennickendorf;b Strausberg;15378;033638;Brandenburg
Hennigsdorf;;16761;03302;Brandenburg
Henningen;b Salzwedel;29413;039038;Sachsen-Anhalt
Hennstedt;b Itzehoe;25581;04877;Schlewig-Holstein
Hennstedt;, Dithm;25779;04836;Schlewig-Holstein
Hennweiler;;55619;06752;Rheinland-Pfalz
Henschleben;;99634;036376;Thüringen
Henschtal;;66909;06383;Rheinland-Pfalz
Henstedt-Ulzburg;;24558;04193;Schlewig-Holstein
Hentern;;54314;06587;Rheinland-Pfalz
Henzendorf;;15898;033656;Brandenburg
Hepberg;;85120;08456;Bayern
Heppenheim;(Bergstraße);64646;06252;Hessen
Hepstedt;;27412;04283;Niedersachsen
Herbelsmühle;;56377;;Rheinland-Pfalz
Herbersdorf;b Jüterbog;14913;033744;Brandenburg
Herbertingen;;88518;07586;Baden-Württemberg
Herbolzheim;, Breisgau;79336;07643;Baden-Württemberg
Herborn;, Hess;35745;02772;Hessen
Herborn;, Hess;35745;02772;Hessen
Herborn;b Idar-Oberstein;55758;06781;Rheinland-Pfalz
Herbrechtingen;;89542;07324;Baden-Württemberg
Herbsleben;;99955;036041;Thüringen
Herbstadt;;97633;09761;Bayern
Herbstein;;36358;06643;Hessen
Herbstmühle;, Eifel;54673;06524;Rheinland-Pfalz
Herchweiler;;66871;06384;Rheinland-Pfalz
Herdecke;, Ruhr;58313;02330;Nordrhein-Westfalen
Herdorf;, Sieg;57562;02744;Rheinland-Pfalz
Herdwangen-Schönach;;88634;07557;Baden-Württemberg
Heretsried;;86465;08293;Bayern
Herfingerhof;;67816;;Rheinland-Pfalz
Herford;;32049;05221;Nordrhein-Westfalen
Herford;;32051;05221;Nordrhein-Westfalen
Herford;;32052;05221;Nordrhein-Westfalen
Herforst;;54662;06562;Rheinland-Pfalz
Hergatz;;88145;08385;Bayern
Hergenfeld;;55452;06706;Rheinland-Pfalz
Hergenroth;;56457;02663;Rheinland-Pfalz
Hergensweiler;;88138;08388;Bayern
Hergersweiler;;76872;06349;Rheinland-Pfalz
Hergisdorf;;06313;034772;Sachsen-Anhalt
Hergolding;;85599;089;Bayern
Heringen;(Werra);36266;06624;Hessen
Heringen;, Helme;99765;036333;Thüringen
Heringsdorf;, Holst;23777;04365;Schlewig-Holstein
Herl;;54317;06500;Rheinland-Pfalz
Herlasgrün;;08543;03765;Sachsen
Herleshausen;;37293;05654;Hessen
Hermannsburg;;29320;05052;Niedersachsen
Hermannsfeld;b Meiningen;98617;036945;Thüringen
Hermannshagen Dorf;;18317;038223;Mecklenburg-Vorpommern
Hermannshagen Hof;;18317;;Mecklenburg-Vorpommern
Hermannshof;b Wittenberge;19322;;Brandenburg
Hermaringen;;89568;07322;Baden-Württemberg
Hermerode;;06543;034775;Sachsen-Anhalt
Hermersberg;, Pfalz;66919;06333;Rheinland-Pfalz
Hermersdorf/Obersdorf;;15374;03342;Brandenburg
Hermeskeil;;54411;06503;Rheinland-Pfalz
Hermsdorf;/Erzgeb.;01776;035057;Sachsen
Hermsdorf;b Ruhland;01945;035752;Brandenburg
Hermsdorf;, Thür;07629;036601;Thüringen
Hermsdorf;b Königs Wusterhausen;15748;033765;Brandenburg
Hermsdorf;b Wolmirstedt;39326;0391;Sachsen-Anhalt
Hermsdorf-Seyde;;01776;035057;Sachsen
Hermsgrün;;08626;037423;Sachsen
Hermshof;;19339;;Brandenburg
Hermstedt;;99510;03644;Thüringen
Herne;, Westf;44623;02323;Nordrhein-Westfalen
Herne;, Westf;44625;02323;Nordrhein-Westfalen
Herne;, Westf;44627;02323;Nordrhein-Westfalen
Herne;, Westf;44628;02323;Nordrhein-Westfalen
Herne;, Westf;44629;02323;Nordrhein-Westfalen
Herne;, Westf;44649;02323;Nordrhein-Westfalen
Herne;, Westf;44651;02323;Nordrhein-Westfalen
Herne;, Westf;44652;02323;Nordrhein-Westfalen
Herne;, Westf;44653;02323;Nordrhein-Westfalen
Herold;, Rhein-Lahn-Kr;56368;06486;Rheinland-Pfalz
Heroldishausen;;99991;036022;Thüringen
Heroldsbach;;91336;09190;Bayern
Heroldsberg;;90562;0911;Bayern
Heroldstatt;;72535;07389;Baden-Württemberg
Herpf;;98617;036949;Thüringen
Herreden;;99735;03631;Thüringen
Herren Steinfeld;;19071;0385;Mecklenburg-Vorpommern
Herrenberg;im Gäu;71083;07032;Baden-Württemberg
Herrenbranden;;23619;04553;Schlewig-Holstein
Herrenchiemsee;;83209;08051;Bayern
Herrengosserstedt;;06648;034467;Sachsen-Anhalt
Herrenhof;b Neuhaus, Elbe;19273;038845;Niedersachsen
Herrenhof;b Gotha, Thür;99887;036253;Thüringen
Herrenhölzer;;14789;;Brandenburg
Herren-Sulzbach;;67742;06788;Rheinland-Pfalz
Herresbach;;56729;02656;Rheinland-Pfalz
Herrgottsau;;57539;;Rheinland-Pfalz
Herrieden;;91567;09825;Bayern
Herrischried;;79737;07764;Baden-Württemberg
Herrmannsacker;;99762;03631;Thüringen
Herrnburg;;23923;038821;Mecklenburg-Vorpommern
Herrngiersdorf;;84097;09452;Bayern
Herrnhut;;02747;035873;Sachsen
Herrnschwende;;99631;036374;Thüringen
Herrsching;a. Ammersee;82211;08152;Bayern
Herrstein;;55756;06785;Rheinland-Pfalz
Hersbruck;;91217;09151;Bayern
Herschbach;b Selters, Westerwald;56249;02626;Rheinland-Pfalz
Herschberg;, Pfalz;66919;06375;Rheinland-Pfalz
Herschbroich;;53518;02691;Rheinland-Pfalz
Herschdorf;b Pößneck;07381;03647;Thüringen
Herschdorf;b Ilmenau;98701;036738;Thüringen
Herscheid;, Westf;58849;02357;Nordrhein-Westfalen
Herscheid;, Westf;58849;02357;Nordrhein-Westfalen
Herschweiler-Pettersheim;;66909;06384;Rheinland-Pfalz
Hersdorf;;54597;06553;Rheinland-Pfalz
Hertefeld;b Nauen;14641;03321;Brandenburg
Hertefeld;b Oranienburg;16515;;Brandenburg
Herten;, Westf;45699;02366;Nordrhein-Westfalen
Herten;, Westf;45701;02366;Nordrhein-Westfalen
Herxheim;bei Landau, Pfalz;76863;07276;Rheinland-Pfalz
Herxheim am Berg;;67273;06353;Rheinland-Pfalz
Herxheimweyher;b Landau, Pfalz;76863;07276;Rheinland-Pfalz
Herzberg;, Elster;04916;03535;Brandenburg
Herzberg;b Beeskow;15864;033677;Brandenburg
Herzberg;(Mark);16835;033926;Brandenburg
Herzberg;b Parchim;19374;038720;Mecklenburg-Vorpommern
Herzberg;am Harz;37412;05521;Niedersachsen
Herzebrock-Clarholz;;33442;05245;Nordrhein-Westfalen
Herzfeld;b Parchim;19372;038725;Mecklenburg-Vorpommern
Herzfeld;, Eifel;54619;06559;Rheinland-Pfalz
Herzfelde;b Strausberg;15378;033434;Brandenburg
Herzfelde;b Templin;17268;039885;Brandenburg
Herzhorn;, Holst;25379;04124;Schlewig-Holstein
Herzlake;;49770;05962;Niedersachsen
Herzogenaurach;;91074;09132;Bayern
Herzogenaurach;;91074;09132;Bayern
Herzogenrath;;52134;02406;Nordrhein-Westfalen
Herzogstand;;82432;08851;Bayern
Herzsprung;b Angermünde;16278;03331;Brandenburg
Herzsprung;b Wittstock, Dosse;16909;033965;Brandenburg
Hesel;, Ostfriesl;26835;04950;Niedersachsen
Hespe;b Stadthagen;31693;05721;Niedersachsen
Heßberg;;98646;03685;Thüringen
Heßdorf;, Mittelfr;91093;09135;Bayern
Hessen;b Halberstadt;38835;039426;Sachsen-Anhalt
Hessenburg;;18317;038227;Mecklenburg-Vorpommern
Hesseneck;;64754;06276;Hessen
Hessenhütte;;67294;;Rheinland-Pfalz
Hessenmühle;b Marktheidenfeld;97828;;Bayern
Hesserode;b Nordhausen, Thür;99735;03631;Thüringen
Heßheim;;67258;06233;Rheinland-Pfalz
Hessigheim;;74394;07143;Baden-Württemberg
Hessisch Lichtenau;;37235;05602;Hessen
Hessisch Oldendorf;;31840;05152;Niedersachsen
Heßles;;98597;03683;Thüringen
Hesweiler;;56865;06545;Rheinland-Pfalz
Heteborn;;06458;039481;Sachsen-Anhalt
Hetlingen;;25491;04103;Schlewig-Holstein
Hetlinger Neuerkoog;;25492;;Schlewig-Holstein
Hetschburg;;99438;036458;Thüringen
Hettenhausen;, Pfalz;66919;06375;Rheinland-Pfalz
Hettenleidelheim;;67310;06351;Rheinland-Pfalz
Hettenrodt;;55758;06781;Rheinland-Pfalz
Hettenshausen;;85276;08441;Bayern
Hettingen;, Kr Sigmaringen;72513;07574;Baden-Württemberg
Hettstadt;;97265;0931;Bayern
Hettstedt;, Sachs-Anh;06333;03476;Sachsen-Anhalt
Hetzdorf;b Eisenberg, Thür;07616;036692;Thüringen
Hetzenhausen;;85376;08165;Bayern
Hetzerath;, Mosel;54523;06508;Rheinland-Pfalz
Hetzles;;91077;09134;Bayern
Heubach;, Württ;73540;07173;Baden-Württemberg
Heubelsburg;;89335;08223;Bayern
Heuberg;, Westerw;57635;02686;Rheinland-Pfalz
Heubergerhof;;67292;;Rheinland-Pfalz
Heubisch;;96524;036761;Thüringen
Heuchelheim;, Kr Gießen;35452;0641;Hessen
Heuchelheim;bei Frankenthal;67259;06238;Rheinland-Pfalz
Heuchelheim-Klingen;;76831;06349;Rheinland-Pfalz
Heuchlingen;, Lein;73572;07174;Baden-Württemberg
Heuckewalde;;06712;034423;Sachsen-Anhalt
Heudeber;;38855;039458;Sachsen-Anhalt
Heuersdorf;;04574;034342;Sachsen
Heuerßen;;31700;05725;Niedersachsen
Heukewalde;;04626;034496;Thüringen
Heunenhof;;56729;02653;Rheinland-Pfalz
Heupelzen;;57612;02681;Rheinland-Pfalz
Heusenstamm;;63150;06104;Hessen
Heusenstamm;;63150;06104;Hessen
Heustreu;;97618;09773;Bayern
Heusweiler;;66265;06806;Saarland
Heuthen;;37308;036084;Thüringen
Heuweiler;;79194;07666;Baden-Württemberg
Heuwinkl;b Mittergars;83559;08073;Bayern
Heuzert;;57627;02688;Rheinland-Pfalz
Heyda;b Ilmenau;98693;03677;Thüringen
Heyen;, Kr Holzminden;37619;05533;Niedersachsen
Heyerhof;;67297;06355;Rheinland-Pfalz
Heyerode;, Thür;99988;036024;Thüringen
Heyersdorf;;04626;03762;Thüringen
Heygendorf;;06556;03466;Thüringen
Heynitz;;01683;035244;Sachsen
Hiddenhausen;;32120;05221;Nordrhein-Westfalen
Hierankl;b Hufschlag, Oberbay;83359;0861;Bayern
Hilbersdorf;b Gera;07580;036602;Thüringen
Hilbersdorf;b Freiberg, Sachs;09627;03731;Sachsen
Hilchenbach;, Siegerland;57271;02733;Nordrhein-Westfalen
Hildburghausen;;98646;03685;Thüringen
Hildebrandshausen;;99976;036027;Thüringen
Hilden;;40721;02103;Nordrhein-Westfalen
Hilden;;40723;02103;Nordrhein-Westfalen
Hilden;;40724;02103;Nordrhein-Westfalen
Hilders;;36115;06681;Hessen
Hildesheim;;31134;05121;Niedersachsen
Hildesheim;;31135;05121;Niedersachsen
Hildesheim;;31137;05121;Niedersachsen
Hildesheim;;31139;05121;Niedersachsen
Hildesheim;;31141;05121;Niedersachsen
Hildrizhausen;;71157;07034;Baden-Württemberg
Hilgendorf;;23936;03881;Mecklenburg-Vorpommern
Hilgenrain;;83739;08025;Bayern
Hilgenroth;, Westerw;57612;02682;Rheinland-Pfalz
Hilgermissen;;27318;04256;Niedersachsen
Hilgert;b Höhr-Grenzhausen;56206;02624;Rheinland-Pfalz
Hilgertshausen-Tandern;;86567;08250;Bayern
Hilkenbrook;;26897;04493;Niedersachsen
Hille;;32479;05703;Nordrhein-Westfalen
Hillerse;, Kr Gifhorn;38543;05373;Niedersachsen
Hillersleben;;39343;039202;Sachsen-Anhalt
Hillesheim;, Eifel;54576;06593;Rheinland-Pfalz
Hillesheim;, Rheinhess;67586;06733;Rheinland-Pfalz
Hillgroven;;25764;04833;Schlewig-Holstein
Hillmersdorf;;04936;035361;Brandenburg
Hillscheid;, Westerw;56204;02624;Rheinland-Pfalz
Hilmersdorf;;09429;037369;Sachsen
Hilpischmühle;;56462;02661;Rheinland-Pfalz
Hilpoltstein;, Mittelfr;91161;09174;Bayern
Hilscheid;;54426;06504;Rheinland-Pfalz
Hilst;;66957;06335;Rheinland-Pfalz
Hiltenfingen;;86856;08249;Bayern
Hilter;am Teutoburger Wald;49176;05409;Niedersachsen
Hiltpoltstein;, Oberfr;91355;09192;Bayern
Hilzingen;;78247;07739;Baden-Württemberg
Himbergen;, Göhrde;29584;05828;Niedersachsen
Himmelgarten;b Nordhausen, Thür;99735;03631;Thüringen
Himmelkron;;95502;09227;Bayern
Himmelpfort;;16798;033089;Brandenburg
Himmelpforten;, Niederelbe;21709;04144;Niedersachsen
Himmelsberg;b Ebeleben;99713;036330;Thüringen
Himmelstadt;;97267;09364;Bayern
Himmelweiler;;89160;07348;Baden-Württemberg
Himmelweiler;;89160;07348;Baden-Württemberg
Himmighofen;;56357;06772;Rheinland-Pfalz
Hindelang;;87541;08324;Bayern
Hindenberg;b Calau;03222;035456;Brandenburg
Hindenberg;b Neuruppin;16835;033933;Brandenburg
Hindenberg;b Gadebusch;19205;03886;Mecklenburg-Vorpommern
Hindenburg;b Stendal;39596;039394;Sachsen-Anhalt
Hingstheide;;25563;04822;Schlewig-Holstein
Hinrichshagen;b Waren;17194;039953;Mecklenburg-Vorpommern
Hinrichshagen;b Greifswald;17498;03963;Mecklenburg-Vorpommern
Hinsdorf;;06386;034977;Sachsen-Anhalt
Hinte;;26759;04925;Niedersachsen
Hinterbirkenhof;;71711;07144;Baden-Württemberg
Hinterbug;;95236;;Bayern
Hinterdeich;;21635;04162;Niedersachsen
Hinterer Hessenhof;;89143;;Baden-Württemberg
Hinterhagen;;19273;038844;Mecklenburg-Vorpommern
Hinterhermsdorf;;01855;035974;Sachsen
Hinterholz;b Wörnsmühl;83739;08025;Bayern
Hinterholzmühle;;83552;08074;Bayern
Hinterleiten;b Neukirchen a Teisenberg;83364;0861;Bayern
Hinterloh;b Neukirchen a Teisenberg;83364;08666;Bayern
Hinternah;;98553;036841;Thüringen
Hinterschmiding;;94146;08551;Bayern
Hintersee;b Ueckermünde;17375;039776;Mecklenburg-Vorpommern
Hinterste Wache;;24306;;Schlewig-Holstein
Hintertiefenbach;;55743;06784;Rheinland-Pfalz
Hinterweidenthal;;66999;06396;Rheinland-Pfalz
Hinterweiler;, Eifel;54570;06595;Rheinland-Pfalz
Hinterzarten;;79856;07652;Baden-Württemberg
Hinterziegelhalden;;74545;;Baden-Württemberg
Hinzdorf;;19322;03877;Brandenburg
Hinzenburg;;54316;06588;Rheinland-Pfalz
Hinzert-Pölert;;54421;06586;Rheinland-Pfalz
Hinzweiler;;67756;06304;Rheinland-Pfalz
Hippelsbach;;64395;06161;Hessen
Hipstedt;;27432;04768;Niedersachsen
Hirrlingen;, Kr Tübingen;72145;07478;Baden-Württemberg
Hirschaid;;96114;09543;Bayern
Hirschau;b Lauter, Oberbay;83362;08681;Bayern
Hirschau;, Oberpf;92242;09622;Bayern
Hirschbach;, Oberpf;92275;09665;Bayern
Hirschbach;b Suhl;98553;03681;Thüringen
Hirschberg;, Saale;07927;036644;Thüringen
Hirschberg;, Rhein-Lahn-Kr;65558;06439;Rheinland-Pfalz
Hirschberg;an der Bergstraße;69493;06201;Baden-Württemberg
Hirschendorf;b Eisfeld;98678;03686;Thüringen
Hirschfeld;b Elsterwerda;04932;035343;Brandenburg
Hirschfeld;b Gera;07554;036695;Thüringen
Hirschfeld;b Zwickau;08144;037607;Sachsen
Hirschfeld;b Freiberg, Sachs;09634;037324;Sachsen
Hirschfeld;(Hunsrück);55483;06543;Rheinland-Pfalz
Hirschfelde;b Zittau;02788;035843;Sachsen
Hirschfelde;b Werneuchen;16356;033398;Brandenburg
Hirschhof;b Zweibrücken, Pfalz;66482;06339;Rheinland-Pfalz
Hirschhöf;;93449;09972;Bayern
Hirschhorn;, Pfalz;67732;06308;Rheinland-Pfalz
Hirschhorn;(Neckar);69434;06272;Hessen
Hirschroda;b Naumburg, Saale;06636;034462;Sachsen-Anhalt
Hirschstein;b Riesa;01594;035266;Sachsen
Hirschthal;, Kr Pirmasens;66996;06393;Rheinland-Pfalz
Hirten;, Eifel;56729;02656;Rheinland-Pfalz
Hirtscheid;;57647;02661;Rheinland-Pfalz
Hirzbach;b Leutenberg, Thür;07338;036734;Thüringen
Hirzenhain;, Wetteraukr;63697;06045;Hessen
Hirzlei;;54470;06534;Rheinland-Pfalz
Hirz-Maulsbach;;57635;02686;Rheinland-Pfalz
Hisel;, Eifel;54646;06527;Rheinland-Pfalz
Hitscherhof;;66482;06336;Rheinland-Pfalz
Hittbergen;;21522;04153;Niedersachsen
Hitzacker;(Elbe);29456;05862;Niedersachsen
Hitzhofen;;85122;08458;Bayern
Hitzhusen;;24576;04192;Schlewig-Holstein
Hobbersdorf;;23689;04504;Schlewig-Holstein
Hobeck;;39279;039245;Sachsen-Anhalt
Höchberg;;97204;0931;Bayern
Hochborn;;55234;06735;Rheinland-Pfalz
Hochbuch;;89290;07300;Bayern
Hochdonn;;25712;04825;Schlewig-Holstein
Hochdorf;b Plochingen;73269;07153;Baden-Württemberg
Hochdorf;, Riß;88454;07355;Baden-Württemberg
Hochdorf-Assenheim;;67126;06231;Rheinland-Pfalz
Höchenschwand;;79862;07672;Baden-Württemberg
Hochfellnhaus;;83346;;Bayern
Hochgernhaus;;83250;;Bayern
Hochhalden;b Rottweil;78628;;Baden-Württemberg
Hochheim;am Main;65239;06146;Hessen
Hochheim;b Gotha, Thür;99869;036255;Thüringen
Höchheim;;97633;09764;Bayern
Hochhorn;;83364;08666;Bayern
Hochkirch;;02627;035939;Sachsen
Hochreit;b St Georgen, Chiemgau;83368;08669;Bayern
Hochscheid;, Hunsrück;54472;06536;Rheinland-Pfalz
Hochspeyer;;67691;06305;Rheinland-Pfalz
Höchst;i Odw;64739;06163;Hessen
Hochstadt;(Pfalz);76879;06347;Rheinland-Pfalz
Hochstadt;a. Main;96272;09574;Bayern
Höchstadt;a d Aisch;91315;09193;Bayern
Höchstädt;a d Donau;89420;09074;Bayern
Höchstädt;i. Fichtelgebirge;95186;09235;Bayern
Hochstätten;, Kr Bad Kreuznach;55585;06362;Rheinland-Pfalz
Hochstatter Hof;;73450;07326;Baden-Württemberg
Höchstberg;, Eifel;56767;02657;Rheinland-Pfalz
Hochstedt;, Thür;99198;036203;Thüringen
Hochstedt;, Thür;99198;036203;Thüringen
Hochstedt;b Nordhausen;99735;036335;Thüringen
Höchstenbach;;57629;02680;Rheinland-Pfalz
Hochstetten-Dhaun;;55606;06752;Rheinland-Pfalz
Hochstraß;b Gars Bahnhof;83555;08073;Bayern
Hochwald;, Württ;78667;07403;Baden-Württemberg
Höckendorf;b Dippoldiswalde;01774;035055;Sachsen
Hockenheim;;68766;06205;Baden-Württemberg
Hockweiler;;54316;06588;Rheinland-Pfalz
Hodenhagen;;29693;05164;Niedersachsen
Hödeshof;;56841;06541;Rheinland-Pfalz
Hödingen;b Haldensleben;39356;039061;Sachsen-Anhalt
Hodorf;;25569;04821;Schlewig-Holstein
Hoecker, Haus;;76857;;Rheinland-Pfalz
Hof;b Oschatz;04758;035268;Sachsen
Hof;, Westerw;56472;02661;Rheinland-Pfalz
Hof;, Saale;95028;09281;Bayern
Hof;, Saale;95030;09281;Bayern
Hof;, Saale;95032;09281;Bayern
Hof Eckeberg;;63505;;Hessen
Hof Grabow;;19374;038723;Mecklenburg-Vorpommern
Hof Gutow;;23948;038825;Mecklenburg-Vorpommern
Hof Mondschirm;, Gem Ahnatal;34246;;Hessen
Hof Mummendorf;;23936;038824;Mecklenburg-Vorpommern
Hof Redentin;;23974;03841;Mecklenburg-Vorpommern
Hof Reppenhagen;;23948;038825;Mecklenburg-Vorpommern
Hof Selmsdorf;;23923;038823;Mecklenburg-Vorpommern
Hof Wahrsow;;23923;038821;Mecklenburg-Vorpommern
Hofbieber;;36145;06657;Hessen
Höfen;an der Enz;75339;07081;Baden-Württemberg
Höfer;, Kr Celle;29361;05145;Niedersachsen
Hoffeld;b Bordesholm;24582;04322;Schlewig-Holstein
Hoffeld;, Eifel;53534;02693;Rheinland-Pfalz
Hoffnungstal;, Gem Loit;24894;;Schlewig-Holstein
Hofgeismar;;34369;05671;Hessen
Höfgen;b Lommatzsch;01623;035246;Sachsen
Höfgen;b Jüterbog;14913;033746;Brandenburg
Hofheim;am Taunus;65719;06192;Hessen
Hofheim;, Gem Spatzenhausen;82418;08847;Bayern
Hofheim;, Unterfr;97461;09523;Bayern
Hofkirchen;, Bay;94544;08545;Bayern
Hofreuth;b Wörnsmühl;83739;08025;Bayern
Hofstätt;b Evenhausen, Oberbay;83552;;Bayern
Hofstetten;, Kinzigtal;77716;07832;Baden-Württemberg
Hofstetten;, Kr Landsberg a Lech;86928;08196;Bayern
Hofswald;;54597;;Rheinland-Pfalz
Hofzumfelde;;23948;038825;Mecklenburg-Vorpommern
Högel;;25858;04671;Schlewig-Holstein
Högersdorf;;23795;04551;Schlewig-Holstein
Hoggen;;83329;08681;Bayern
Högsdorf;;24327;04523;Schlewig-Holstein
Höhbeck;;29478;05846;Niedersachsen
Hohberg;b Offenburg;77749;07808;Baden-Württemberg
Hohburg;b Wurzen;04808;034263;Sachsen
Hoheging;, Gem Emstek;49685;04474;Niedersachsen
Höheinöd;;66989;06333;Rheinland-Pfalz
Höheischweiler;;66989;06331;Rheinland-Pfalz
Hohen Luckow;;18239;038295;Mecklenburg-Vorpommern
Hohen Neuendorf;;16540;03302;Brandenburg
Hohen Niendorf;;18230;;Mecklenburg-Vorpommern
Hohen Pritz;;19406;0385;Mecklenburg-Vorpommern
Hohen Schönberg;;23948;038827;Mecklenburg-Vorpommern
Hohen Sprenz;;18299;038454;Mecklenburg-Vorpommern
Hohen Viecheln;;23996;038423;Mecklenburg-Vorpommern
Hohen Wangelin;;17194;03991;Mecklenburg-Vorpommern
Hohenahlsdorf;;14913;033746;Brandenburg
Hohenahr;;35644;06446;Hessen
Hohenaltheim;;86745;09088;Bayern
Hohenaspe;;25582;04893;Schlewig-Holstein
Hohenau;, Niederbay;94545;08558;Bayern
Hohenberg;a d Eger;95691;09233;Bayern
Höhenberg;b Stein a d Traun;83371;08621;Bayern
Hohenbergen;b Schlotheim;99994;036021;Thüringen
Hohenberg-Krusemark;;39596;039394;Sachsen-Anhalt
Hohenbocka;;01945;035756;Brandenburg
Hohenbollentin;;17111;039996;Mecklenburg-Vorpommern
Hohenbruch;;16515;033055;Brandenburg
Hohenbrück-Neu Schadow;;15910;035473;Brandenburg
Hohenbrunn;;85662;089;Bayern
Hohenbrünzow;;17111;039993;Mecklenburg-Vorpommern
Hohenbucko;;04936;035364;Brandenburg
Hohenburg;, Oberpf;92277;09626;Bayern
Hohen-Demzin;;17166;03996;Mecklenburg-Vorpommern
Hohendodeleben;;39167;039204;Sachsen-Anhalt
Hohendorf;b Eisenberg, Thür;07616;036692;Thüringen
Hohendorf;b Wolgast;17440;038373;Mecklenburg-Vorpommern
Hohendubrau;;02906;;Sachsen
Hohenebra;;99713;03632;Thüringen
Hohenentringen;;72070;;Baden-Württemberg
Hohenerxleben;;39443;03925;Sachsen-Anhalt
Hohenester;;83371;08621;Bayern
Hohenfelde;b Angermünde;16306;03332;Brandenburg
Hohenfelde;b Bad Doberan;18209;038203;Mecklenburg-Vorpommern
Hohenfelde;b Sternberg;19406;038485;Mecklenburg-Vorpommern
Hohenfelde;, Kr Stormarn;22946;04154;Schlewig-Holstein
Hohenfelde;b Kiel;24257;04385;Schlewig-Holstein
Hohenfelde;b Elmshorn;25358;04127;Schlewig-Holstein
Hohenfelden;;99448;036450;Thüringen
Hohenfels;b Stockach;78355;07557;Baden-Württemberg
Hohenfels;, Oberpf;92366;09472;Bayern
Hohenfels-Essingen;;54570;06595;Rheinland-Pfalz
Hohenferchesar;;14798;033834;Brandenburg
Hohenfinow;;16248;033458;Brandenburg
Hohenfurch;;86978;08861;Bayern
Hohengandern;;37318;036081;Thüringen
Hohengöhren;;39524;039323;Sachsen-Anhalt
Hohengörsdorf;;14913;033746;Brandenburg
Hohengüstow;;17291;039861;Brandenburg
Hohenhameln;;31249;05128;Niedersachsen
Hohenhenningen;;38486;03909;Sachsen-Anhalt
Hohenhorn;;21526;04152;Schlewig-Holstein
Hohenkammer;;85411;08137;Bayern
Hohenkirchen;b Gotha, Thür;99887;036253;Thüringen
Höhenkirchen-Siegertsbrunn;;85635;08102;Bayern
Hohenleimbach;;56746;02655;Rheinland-Pfalz
Hohenleipisch;;04934;03533;Brandenburg
Hohenlepte;;39264;039247;Sachsen-Anhalt
Hohenleuben;;07958;036622;Thüringen
Hohenlinden;;85664;08124;Bayern
Hohenlobbese;;14828;033847;Brandenburg
Hohenlockstedt;;25551;04826;Schlewig-Holstein
Hohenlohe;b Bopfingen;73441;07362;Baden-Württemberg
Hohenmocker;;17111;039993;Mecklenburg-Vorpommern
Hohenmölsen;;06679;034441;Sachsen-Anhalt
Hohennauen;;14715;033872;Brandenburg
Hohenöd;b Evenhausen, Oberbay;83552;08075;Bayern
Hohenofen;b Neustadt, Dosse;16845;033970;Brandenburg
Hohenöllen;;67744;06382;Rheinland-Pfalz
Hohenölsen;;07570;036603;Thüringen
Hohenpeißenberg;;82383;08805;Bayern
Hohenpolding;;84432;08084;Bayern
Hohenreinkendorf;;16307;033332;Brandenburg
Hohenroda;, Hess;36284;06676;Hessen
Hohenroth;b Bad Neustadt a d Saale;97618;09771;Bayern
Hohensaaten;;16248;033368;Brandenburg
Hohenschäftlarn;;82069;08178;Bayern
Hohenseeden;;39307;039344;Sachsen-Anhalt
Hohenseefeld;;14913;033744;Brandenburg
Hohenselchow;;16306;033331;Brandenburg
Hohenspringe;;14806;033847;Brandenburg
Hohenstadt;b Geislingen an der Steige;73345;07335;Baden-Württemberg
Hohenstein;b Strausberg;15345;03341;Brandenburg
Hohenstein;, Untertaunus;65329;06120;Hessen
Hohenstein;, Württ;72531;07387;Baden-Württemberg
Hohenstein;b Nordhausen;99755;036336;Thüringen
Höhenstein;b Konzell;94357;;Bayern
Hohenstein-Ernstthal;;09337;03723;Sachsen
Hohen-Sülzen;;67591;06243;Rheinland-Pfalz
Hohentengen;am Hochrhein;79801;07742;Baden-Württemberg
Hohentengen;b Bad Saulgau;88367;07572;Baden-Württemberg
Hohenthann;b Landshut, Isar;84098;08784;Bayern
Hohenthurm;;06188;034602;Sachsen-Anhalt
Hohentramm;;38489;039000;Sachsen-Anhalt
Hohenunkel;;53572;02224;Rheinland-Pfalz
Hohenvier;;19348;038789;Brandenburg
Hohenwarsleben;;39326;039204;Sachsen-Anhalt
Hohenwart;, Paar;86558;08443;Bayern
Hohenwarte;;07338;036733;Thüringen
Hohenwarth;b Kötzting;93480;09946;Bayern
Hohenwarthe;;39291;039222;Sachsen-Anhalt
Hohenweiden;;06179;03461;Sachsen-Anhalt
Hohenwerbig;;14823;033843;Brandenburg
Hohenwestedt;;24594;04871;Schlewig-Holstein
Hohenwinkel;;14715;;Brandenburg
Hohenwulsch;;39606;039089;Sachsen-Anhalt
Hohenwutzen;;16259;033368;Brandenburg
Hohenziatz;;39291;039226;Sachsen-Anhalt
Hohenzieritz;;17237;039824;Mecklenburg-Vorpommern
Hohes Kreuz;;99310;03629;Thüringen
Hohestein;;56850;06543;Rheinland-Pfalz
Höhfröschen;;66989;06334;Rheinland-Pfalz
Höhjunk;;54298;06525;Rheinland-Pfalz
Hohlstedt;b Weimar, Thür;99441;036425;Thüringen
Hohn;b Rendsburg;24806;04335;Schlewig-Holstein
Höhn;, Westerw;56462;02661;Rheinland-Pfalz
Hohndorf;b Naumburg, Saale;06647;034463;Sachsen-Anhalt
Hohndorf;b Greiz;07985;036621;Thüringen
Hohndorf;b Stollberg, Erzgeb;09394;037298;Sachsen
Hohndorf;b Zschopau;09434;03725;Sachsen
Höhndorf;;24217;04344;Schlewig-Holstein
Hohne;b Celle;29362;05083;Niedersachsen
Hohnhorst;b Wunstorf;31559;05723;Niedersachsen
Höhnstedt;;06179;034601;Sachsen-Anhalt
Hohnstein;;01848;035975;Sachsen
Hohnstorf;(Elbe);21522;04153;Niedersachsen
Höhr-Grenzhausen;;56203;02624;Rheinland-Pfalz
Hohwacht;(Ostsee);24321;04381;Schlewig-Holstein
Hohwald;;01844;03596;Sachsen
Hoikendorf;;23936;03881;Mecklenburg-Vorpommern
Hoisdorf;;22955;04107;Schlewig-Holstein
Holdenstedt;b Sangerhausen;06528;034659;Sachsen-Anhalt
Holdorf;b Gadebusch;19217;03886;Mecklenburg-Vorpommern
Holdorf;b Sternberg;19412;03866;Mecklenburg-Vorpommern
Holdorf;, Niedersachs;49451;05494;Niedersachsen
Holenberg;;37642;05532;Niedersachsen
Hollbrunn;;15868;;Brandenburg
Holldorf;;17094;039603;Mecklenburg-Vorpommern
Holle;b Hildesheim;31188;05062;Niedersachsen
Holleben;;06179;0345;Sachsen-Anhalt
Hollen;b Bremerhaven;27616;04748;Niedersachsen
Hollenbach;b Aichach;86568;08257;Bayern
Hollenbach;b Mühlhausen, Thür;99976;03601;Thüringen
Hollenbek;, Kr Hzgt Lauenb;23883;04545;Schlewig-Holstein
Höllenmühle;;76744;;Rheinland-Pfalz
Hollenstedt;, Nordheide;21279;04165;Niedersachsen
Holler;, Westerw;56412;02602;Rheinland-Pfalz
Hollerdeich;, Niederelbe;21734;;Niedersachsen
Hollermühle;b Katzenelnbogen;56357;;Rheinland-Pfalz
Hollern-Twielenfleth;;21723;04142;Niedersachsen
Hollertal;, Jugendheim;67714;;Rheinland-Pfalz
Hollfeld;;96142;09274;Bayern
Höllhammer;;63874;06092;Bayern
Höllhof;b Schwäbisch Gmünd;74417;07176;Baden-Württemberg
Hollingstedt;b Schleswig;24876;04627;Schlewig-Holstein
Hollingstedt;b Delve, Holst;25788;04836;Schlewig-Holstein
Hollmühle;, Gem Uelsby;24891;04623;Schlewig-Holstein
Hollnich;b Kastellaun;56288;06762;Rheinland-Pfalz
Hollnseth;;21769;04773;Niedersachsen
Höllriegelskreuth;;82049;089;Bayern
Hollstadt;;97618;09773;Bayern
Höllthal;b Truchtlaching;83376;08621;Bayern
Höllthal;, Gem Gars a Inn;83546;08073;Bayern
Holm;, Kr Pinneberg;25488;04103;Schlewig-Holstein
Holm;b Süderlügum;25923;04663;Schlewig-Holstein
Holste;;27729;04748;Niedersachsen
Holstenniendorf;;25584;04827;Schlewig-Holstein
Holsthum;;54668;06523;Rheinland-Pfalz
Holt;b Medelby;24994;04605;Schlewig-Holstein
Holtgast;, Ostfriesl;26427;04974;Niedersachsen
Holthusen;b Schwerin, Meckl;19075;03865;Mecklenburg-Vorpommern
Höltinghausen;, Gem Emstek;49685;04473;Niedersachsen
Höltingsdorf;;23992;038429;Mecklenburg-Vorpommern
Holtland;;26835;04950;Niedersachsen
Holtsee;;24363;04357;Schlewig-Holstein
Holungen;;37345;036077;Thüringen
Holzappel;, Rhein-Lahn-Kr;56379;06439;Rheinland-Pfalz
Holzbach;, Hunsrück;55469;06761;Rheinland-Pfalz
Holzbunge;;24361;04356;Schlewig-Holstein
Holzdorf;, Elster;06926;035389;Sachsen-Anhalt
Holzdorf;b Eckernförde;24364;04355;Schlewig-Holstein
Holzen;, Kr Holzminden;37632;05534;Niedersachsen
Holzendorf;b Prenzlau;17291;039853;Brandenburg
Holzendorf;, Gem Wendorf;19412;038485;Mecklenburg-Vorpommern
Holzendorf b Sternberg;;19406;;Mecklenburg-Vorpommern
Holzengel;;99718;036379;Thüringen
Holzerath;;54316;06588;Rheinland-Pfalz
Holzgerlingen;;71088;07031;Baden-Württemberg
Holzgünz;;87752;08393;Bayern
Holzhausen;b Rochlitz;09326;037382;Sachsen
Holzhausen;b Neustadt, Dosse;16845;033973;Brandenburg
Holzhausen;b Kalbe, Milde;39629;039089;Sachsen-Anhalt
Holzhausen;an der Haide;56357;06772;Rheinland-Pfalz
Holzhauser Eck;;84503;;Bayern
Holzheim;, Rhein-Lahn-Kr;65558;06432;Rheinland-Pfalz
Holzheim;b Rain, Lech;86684;08276;Bayern
Holzheim;b Neu-Ulm;89291;07302;Bayern
Holzheim;, Kr Dillingen a d Donau;89438;09075;Bayern
Holzheim;a. Forst;93183;09473;Bayern
Holzkirch;;89183;07340;Baden-Württemberg
Holzkirchen;, Oberbay;83607;08024;Bayern
Holzkirchen;, Unterfr;97292;09369;Bayern
Holzkoppel;b Gelting, Angeln;24395;04643;Schlewig-Holstein
Hölzl;b Traunwalchen;83374;;Bayern
Holzländerhof;;16928;;Brandenburg
Holzmaden;;73271;07023;Baden-Württemberg
Holzminden;;37603;05531;Niedersachsen
Holzmühl;b Unterschweinbach;82282;08145;Bayern
Holzmühle;, Oberpf;93167;09462;Bayern
Holzmühle;b Uettingen;97292;09369;Bayern
Holzreit;b Stein a d Traun;83371;08621;Bayern
Holzsußra;;99713;036020;Thüringen
Holzthaleben;;99713;036029;Thüringen
Holzweißig;;06808;03493;Sachsen-Anhalt
Holzwickede;;59439;02301;Nordrhein-Westfalen
Homberg;(Efze);34576;05681;Hessen
Homberg;(Ohm);35315;06633;Hessen
Homberg;, Westerw;56479;02664;Rheinland-Pfalz
Homberg;b Lauterecken;67744;06788;Rheinland-Pfalz
Hömberg;;56379;02604;Rheinland-Pfalz
Homburg;, Saar;66424;06841;Saarland
Hommerdingen;;54675;06522;Rheinland-Pfalz
Honerath;b Adenau;53518;02691;Rheinland-Pfalz
Honigsee;;24211;04302;Schlewig-Holstein
Hönningen;, Ahr;53506;02643;Rheinland-Pfalz
Hönow;;15366;03342;Brandenburg
Hontheim;b Wittlich;54538;02674;Rheinland-Pfalz
Hooge;, Hallig;25859;04849;Schlewig-Holstein
Hoogstede;;49846;05944;Niedersachsen
Hoort;;19230;038859;Mecklenburg-Vorpommern
Hopferau;;87659;08364;Bayern
Hopfgarten;b Geithain;04651;034345;Sachsen
Hopfgarten;b Zschopau;09429;037369;Sachsen
Hopfgarten;, Gem Gars a Inn;83546;08073;Bayern
Hopfgarten;b Weimar, Thür;99428;03643;Thüringen
Höpfingen;;74746;06283;Baden-Württemberg
Hoppegarten b Müncheberg;;15345;033432;Brandenburg
Hoppenrade;b Nauen;14641;033234;Brandenburg
Hoppenrade;b Pritzwalk;16928;033982;Brandenburg
Hoppenrade;b Güstrow;18292;038451;Mecklenburg-Vorpommern
Hoppenrade;b Bad Kleinen;23996;038423;Mecklenburg-Vorpommern
Hoppenwalde;;17375;039779;Mecklenburg-Vorpommern
Hoppstädten;b Lauterecken;67744;06788;Rheinland-Pfalz
Hoppstädten-Weiersbach;;55768;06782;Rheinland-Pfalz
Hopsten;;48496;05458;Nordrhein-Westfalen
Horath;;54497;06504;Rheinland-Pfalz
Horb;am Neckar;72160;07451;Baden-Württemberg
Horbach;b Simmertal;55606;06754;Rheinland-Pfalz
Horbach;, Westerw;56412;06439;Rheinland-Pfalz
Horbach;, Pfalz;66851;06333;Rheinland-Pfalz
Horben;, Breisgau;79289;0761;Baden-Württemberg
Hörbering;b Neukirchen a Teisenberg;83364;08666;Bayern
Horbruch;;55483;06543;Rheinland-Pfalz
Horburg-Maßlau;;06254;034204;Sachsen-Anhalt
Hörden;, Harz;37412;05521;Niedersachsen
Hordorf;b Oschersleben;39387;03949;Sachsen-Anhalt
Hördt;, Pfalz;76771;07272;Rheinland-Pfalz
Horgau;;86497;08294;Bayern
Horgenzell;;88263;07504;Baden-Württemberg
Hörgertshausen;;85413;08764;Bayern
Horhausen;, Rhein-Lahn-Kr;56379;02687;Rheinland-Pfalz
Horhausen;(Westerwald);56593;02687;Rheinland-Pfalz
Höringen;;67724;06302;Rheinland-Pfalz
Horka;, OL;02923;035892;Sachsen
Horla;;06528;034658;Sachsen-Anhalt
Hörlitz;;01968;03573;Brandenburg
Hormersdorf;, Erzgeb;09395;03721;Sachsen
Horn;, Hunsrück;55469;06766;Rheinland-Pfalz
Horn;, Ostalbkr;73575;07175;Baden-Württemberg
Horn;b Waging a See;83329;08681;Bayern
Hornbach;, Pfalz;66500;06338;Rheinland-Pfalz
Horn-Bad Meinberg;;32805;05234;Nordrhein-Westfalen
Hornbek;;21514;04158;Schlewig-Holstein
Hornberg;, Schwarzwaldbahn;78132;07833;Baden-Württemberg
Hornberger Reute;;71540;07184;Baden-Württemberg
Hornburg;b Lutherstadt Eisleben;06295;034776;Sachsen-Anhalt
Hornburg;, Kr Wolfenbüttel;38315;05334;Niedersachsen
Horneburg;, Niederelbe;21640;04163;Niedersachsen
Horner Mühle;;73575;;Baden-Württemberg
Hornhausen;;39387;03949;Sachsen-Anhalt
Hörningen;;99735;03631;Thüringen
Horno;;03172;035696;Brandenburg
Hornow;;03130;035698;Brandenburg
Hornschänke;b Kreischa;01731;0351;Sachsen
Hornsömmern;;99955;036041;Thüringen
Hornstorf;b Wismar, Meckl;23974;03841;Mecklenburg-Vorpommern
Hörnum;(Sylt);25997;04651;Schlewig-Holstein
Horperath;;56766;02692;Rheinland-Pfalz
Horrweiler;;55457;06727;Rheinland-Pfalz
Horschbach;;66887;06387;Rheinland-Pfalz
Hörscheid;;54552;06592;Rheinland-Pfalz
Hörschhausen;;54552;02692;Rheinland-Pfalz
Horschhof;;74585;07952;Baden-Württemberg
Hörselgau;;99880;03622;Thüringen
Hörsingen;;39356;039055;Sachsen-Anhalt
Horsmar;;99976;036023;Thüringen
Horst;b Pritzwalk;16928;033984;Brandenburg
Horst;b Satow b Bad Doberan;18239;038295;Mecklenburg-Vorpommern
Horst;b Grimmen;18519;038333;Mecklenburg-Vorpommern
Horst;b Boizenburg;19258;038847;Mecklenburg-Vorpommern
Horst;b Perleberg;19348;038789;Brandenburg
Horst;, Kr Hzgt Lauenb;23883;04542;Schlewig-Holstein
Horst;(Holstein);25358;04126;Schlewig-Holstein
Horstdorf;;06785;034904;Sachsen-Anhalt
Horstedt;b Husum, Nordsee;25860;04846;Schlewig-Holstein
Horstedt;, Kr Rotenburg, Wümme;27367;04288;Niedersachsen
Hörstel;;48477;05459;Nordrhein-Westfalen
Hörsten;b Rendsburg;24797;04332;Schlewig-Holstein
Horstfelde;;15806;03377;Brandenburg
Horstmar;, Westf;48612;02558;Nordrhein-Westfalen
Horstwalde;;15837;033704;Brandenburg
Horterhof;;67699;06301;Rheinland-Pfalz
Hörup;;24980;04639;Schlewig-Holstein
Hörzing;b Traunwalchen;83374;08669;Bayern
Hösbach;;63768;06021;Bayern
Hosena;;01996;035756;Brandenburg
Hosenfeld;;36154;06650;Hessen
Höslwang;;83129;08055;Bayern
Hoßkirch;;88374;07587;Baden-Württemberg
Hosten;;54664;06562;Rheinland-Pfalz
Hötensleben;;39393;039405;Sachsen-Anhalt
Hottelstedt;;99439;036452;Thüringen
Hottenbach;;55758;06785;Rheinland-Pfalz
Hottendorf;;39638;039086;Sachsen-Anhalt
Hottenmühle;;55606;;Rheinland-Pfalz
Höttingen;, Mittelfr;91798;09141;Bayern
Hövede;;25782;04838;Schlewig-Holstein
Hövelhof;;33161;05257;Nordrhein-Westfalen
Hövels;;57537;02742;Rheinland-Pfalz
Höwisch;;39615;039384;Sachsen-Anhalt
Höxter;;37671;05271;Nordrhein-Westfalen
Hoya;, Weser;27318;04251;Niedersachsen
Hoyerhagen;;27318;04251;Niedersachsen
Hoyershausen;;31093;05185;Niedersachsen
Hoyerswerda;;02977;03571;Sachsen
Hoym;;06467;034741;Sachsen-Anhalt
Hub;, Gem Amerang;83552;;Bayern
Hübelhof;;66500;06338;Rheinland-Pfalz
Huberhof;b Pirmasens;66989;06336;Rheinland-Pfalz
Hübingen;, Westerw;56412;06439;Rheinland-Pfalz
Hübitz;;06308;03476;Sachsen-Anhalt
Hüblingen;;56479;02664;Rheinland-Pfalz
Hückelhoven;;41836;02433;Nordrhein-Westfalen
Hückeswagen;;42499;02192;Nordrhein-Westfalen
Hude;b Husum, Nordsee;25876;04884;Schlewig-Holstein
Hude;(Oldenburg);27798;04408;Niedersachsen
Hüde;b Diepholz;49448;05447;Niedersachsen
Huderfähre;;25876;;Schlewig-Holstein
Hüffelsheim;;55595;0671;Rheinland-Pfalz
Hüffenhardt;;74928;06268;Baden-Württemberg
Hüffler;;66909;06384;Rheinland-Pfalz
Hüfingen;;78183;0771;Baden-Württemberg
Hufschlag;, Oberbay;83359;0861;Bayern
Hugelitz;b Lindau, Bodensee;88147;08380;Bayern
Hügelsheim;;76549;07229;Baden-Württemberg
Huglfing;;82386;08802;Bayern
Hugoldsdorf;;18465;038320;Mecklenburg-Vorpommern
Hühnerkirche;;65510;;Hessen
Huisheim;;86685;09003;Bayern
Huje;;25588;04827;Schlewig-Holstein
Hülben;;72584;07125;Baden-Württemberg
Hüll;b Gilching;82205;089;Bayern
Hüllhorst;;32609;05744;Nordrhein-Westfalen
Hülsebeck;;16949;033981;Brandenburg
Hülseburg;;19230;038850;Mecklenburg-Vorpommern
Hülsede;;31867;05043;Niedersachsen
Hümmel;;53520;02694;Rheinland-Pfalz
Hummelfeld;;24357;04353;Schlewig-Holstein
Hummelshain;;07768;036424;Thüringen
Hummelshalden;;73550;07171;Baden-Württemberg
Hummelshalden;;73550;07171;Baden-Württemberg
Hummeltal;;95503;09201;Bayern
Hümmerich;;53547;02687;Rheinland-Pfalz
Hümpfershausen;;98634;036940;Thüringen
Humptrup;;25923;04663;Schlewig-Holstein
Hundebusch;;23909;04541;Schlewig-Holstein
Hundeluft;;06862;034907;Sachsen-Anhalt
Hunderdorf;b Bogen, Niederbay;94336;09422;Bayern
Hundertmorgen;, Odenwald;64395;06162;Hessen
Hundeshagen;;37339;036071;Thüringen
Hundhaupten;;07557;0365;Thüringen
Hundheim;b Kastellaun;56288;06762;Rheinland-Pfalz
Hunding;;94551;09904;Bayern
Hundisburg;;39343;03904;Sachsen-Anhalt
Hundsangen;;56414;06435;Rheinland-Pfalz
Hundsbach;b Kirn, Nahe;55621;06757;Rheinland-Pfalz
Hundsdorf;b Ransbach-Baumbach;56235;02623;Rheinland-Pfalz
Hundshübel;;08318;037462;Sachsen
Hundsrück;, Unterfr;63863;09392;Bayern
Hundsweihersägemühle;;67714;06333;Rheinland-Pfalz
Hünenfeld;b Waldesch;56323;02628;Rheinland-Pfalz
Hünfeld;;36088;06652;Hessen
Hünfelden;;65597;06438;Hessen
Hungen;;35410;06402;Hessen
Hungenroth;;56281;06746;Rheinland-Pfalz
Hünstetten;;65510;06126;Hessen
Hünxe;;46569;02858;Nordrhein-Westfalen
Hunzel;;56355;06772;Rheinland-Pfalz
Hunzen;;37620;05534;Niedersachsen
Hupperath;;54518;06571;Rheinland-Pfalz
Hüpstedt;;99976;036076;Thüringen
Hurlach;, Oberbay;86857;08248;Bayern
Hurt;;83374;08669;Bayern
Hürtgenwald;;52393;02429;Nordrhein-Westfalen
Hürth;, Rheinl;50354;02233;Nordrhein-Westfalen
Hurtingöd;;95689;09634;Bayern
Hürup;;24975;04634;Schlewig-Holstein
Husby;b Flensburg;24975;04634;Schlewig-Holstein
Hüsby;;24850;04621;Schlewig-Holstein
Hüselitz;;39579;039365;Sachsen-Anhalt
Husum;, Nordsee;25813;04841;Schlewig-Holstein
Husum;, Kr Nienburg, Weser;31632;05027;Niedersachsen
Hütschenhausen;;66882;06372;Rheinland-Pfalz
Hüttblek;;24641;04194;Schlewig-Holstein
Hütte, Gem Dörnberg;;56379;06439;Rheinland-Pfalz
Hüttelngesäß;;63776;06029;Hessen
Hütten;b Bad Doberan;18209;038203;Mecklenburg-Vorpommern
Hütten;b Ascheffel;24358;04353;Schlewig-Holstein
Hütten;b Haldensleben;39345;03904;Sachsen-Anhalt
Hütten;, Eifel;54673;06564;Rheinland-Pfalz
Hüttenberg;, Hess;35625;06403;Hessen
Hüttengrund;b Sonneberg, Thür;96515;036762;Thüringen
Hüttenrode;b Blankenburg, Harz;38889;03944;Sachsen-Anhalt
Hütterscheid;;54636;06527;Rheinland-Pfalz
Hüttgeswasen;;55743;06782;Rheinland-Pfalz
Hutthurm;;94116;08505;Bayern
Hüttingen;an der Kyll;54636;06565;Rheinland-Pfalz
Hüttingen;bei Lahr, Eifel;54675;06566;Rheinland-Pfalz
Hüttisheim;;89185;07305;Baden-Württemberg
Hüttlingen;, Württ;73460;07361;Baden-Württemberg
Hüven;;49751;05952;Niedersachsen
Huy-Neinstedt;;38836;039425;Sachsen-Anhalt
Hye;b Süderbrarup;24392;04641;Schlewig-Holstein
Ibach;, Schwarzw;79837;07672;Baden-Württemberg
Ibanitz;;01594;035268;Sachsen
Ibbenbüren;;49477;05451;Nordrhein-Westfalen
Ibbenbüren;;49479;05451;Nordrhein-Westfalen
Ichenhausen;;89335;08223;Bayern
Ichstedt;;06556;03466;Thüringen
Ichtershausen;;99334;03628;Thüringen
Icking;, Isartal;82057;08178;Bayern
Idar-Oberstein;;55743;06781;Rheinland-Pfalz
Idelberg;;57612;02688;Rheinland-Pfalz
Iden;;39606;039390;Sachsen-Anhalt
Idenheim;;54636;06506;Rheinland-Pfalz
Idesheim;;54636;06506;Rheinland-Pfalz
Idstedt;;24879;04625;Schlewig-Holstein
Idstein;;65510;06126;Hessen
Iffeldorf;;82393;08856;Bayern
Iffezheim;;76473;07229;Baden-Württemberg
Ifta;;99831;036926;Thüringen
Igel;;54298;06501;Rheinland-Pfalz
Igelsbach;b Eberbach, Baden;69412;06276;Hessen
Igensdorf;;91338;09192;Bayern
Igersheim;;97999;07931;Baden-Württemberg
Iggensbach;;94547;09903;Bayern
Iggingen;;73574;07175;Baden-Württemberg
Igling;;86859;08241;Bayern
Ihleburg;;39291;03921;Sachsen-Anhalt
Ihlewitz;;06347;034783;Sachsen-Anhalt
Ihlienworth;;21775;04755;Niedersachsen
Ihlow;b Jüterbog;14913;033744;Brandenburg
Ihlow;, Märkische Schweiz;15377;033437;Brandenburg
Ihlow;, Ostfriesl;26632;04929;Niedersachsen
Ihringen;;79241;07668;Baden-Württemberg
Ihrlerstein;;93346;09441;Bayern
Ilberstedt;;06408;03471;Sachsen-Anhalt
Ilbesheim;b Kirchheimbolanden;67294;06355;Rheinland-Pfalz
Ilbesheim;bei Landau in der Pfalz;76831;06341;Rheinland-Pfalz
Ilfeld;, Südharz;99768;036331;Thüringen
Ilgenhof;b Plüderhausen;73655;;Baden-Württemberg
Illeben;;99958;03603;Thüringen
Illerich;;56814;02653;Rheinland-Pfalz
Illerkirchberg;;89171;07346;Baden-Württemberg
Illerrieden;;89186;07306;Baden-Württemberg
Illertissen;;89257;07303;Bayern
Illesheim;;91471;09841;Bayern
Illingen;, Saar;66557;06825;Saarland
Illingen;, Württ;75428;07042;Baden-Württemberg
Illmensee;;88636;07558;Baden-Württemberg
Illmersdorf;b Neupetershain;03116;035602;Brandenburg
Illmersdorf;b Jüterbog;14913;033744;Brandenburg
Illschwang;;92278;09666;Bayern
Ilmenau;, Thür;98693;03677;Thüringen
Ilmmünster;;85304;08441;Bayern
Ilsede;;31241;05172;Niedersachsen
Ilsenburg;;38871;039452;Sachsen-Anhalt
Ilsfeld;;74360;07062;Baden-Württemberg
Ilshofen;;74532;07904;Baden-Württemberg
Iltishof;;73037;07161;Baden-Württemberg
Ilvesheim;;68549;0621;Baden-Württemberg
Im Hengstrain;;72138;;Baden-Württemberg
Immekath;;38486;03909;Sachsen-Anhalt
Immelborn;;36433;03695;Thüringen
Immendingen;;78194;07462;Baden-Württemberg
Immenhausen;, Hess;34376;05673;Hessen
Immenhof;b St Goarshausen;56346;;Rheinland-Pfalz
Immenreuth;;95505;09642;Bayern
Immenrode;b Sondershausen;99713;036330;Thüringen
Immenrode;b Nordhausen;99735;036335;Thüringen
Immenstaad;am Bodensee;88090;07545;Baden-Württemberg
Immenstadt;i. Allgäu;87509;08323;Bayern
Immenstedt;b Albersdorf, Holst;25767;04835;Schlewig-Holstein
Immenstedt;, Nordfriesl;25885;04843;Schlewig-Holstein
Immerath;, Kr Daun;54552;06573;Rheinland-Pfalz
Immert;;54426;06504;Rheinland-Pfalz
Immesheim;;67308;06355;Rheinland-Pfalz
Impflingen;;76831;06341;Rheinland-Pfalz
Imsbach;;67817;06302;Rheinland-Pfalz
Imsweiler;;67808;06361;Rheinland-Pfalz
Inchenhofen;;86570;08257;Bayern
Inden;b Jülich;52459;02465;Nordrhein-Westfalen
Ingelbach;;57610;02688;Rheinland-Pfalz
Ingelbach-Bahnhof;;57612;02688;Rheinland-Pfalz
Ingeleben;;38385;05354;Niedersachsen
Ingelfingen;;74653;07940;Baden-Württemberg
Ingelheim;am Rhein;55218;06132;Rheinland-Pfalz
Ingendorf;, Eifel;54636;06568;Rheinland-Pfalz
Ingenried;b Schongau;86980;08868;Bayern
Ingersheim;, Württ;74379;07143;Baden-Württemberg
Ingersleben;;99192;036202;Thüringen
Ingoldingen;;88456;07583;Baden-Württemberg
Ingolstadt;, Donau;85049;0841;Bayern
Ingolstadt;, Donau;85051;0841;Bayern
Ingolstadt;, Donau;85053;0841;Bayern
Ingolstadt;, Donau;85055;0841;Bayern
Ingolstadt;, Donau;85057;0841;Bayern
Innernzell;;94548;08554;Bayern
Inning;a. Ammersee;82266;08143;Bayern
Inning;a. Holz;84416;08084;Bayern
Insel;b Stendal;39599;039329;Sachsen-Anhalt
Insel Koos;;17493;038351;Mecklenburg-Vorpommern
Insel Koos;;17493;038351;Mecklenburg-Vorpommern
Insel Mainau;;78465;07531;Baden-Württemberg
Insel Mainau;;78465;07531;Baden-Württemberg
Insel Poel;;23999;038425;Mecklenburg-Vorpommern
Insel Riems;;17498;038351;Mecklenburg-Vorpommern
Insel Riems;;17498;038351;Mecklenburg-Vorpommern
Insel Vilm;;18581;038301;Mecklenburg-Vorpommern
Inselhof;;24794;04331;Schlewig-Holstein
Insheim;;76865;06341;Rheinland-Pfalz
Insingen;;91610;09869;Bayern
Insul;, Ahr;53520;02695;Rheinland-Pfalz
Inzell;;83334;08665;Bayern
Inzigkofen;;72514;07571;Baden-Württemberg
Inzlingen;;79594;07621;Baden-Württemberg
Iphofen;;97346;09323;Bayern
Ippenschied;;55566;06756;Rheinland-Pfalz
Ippesheim;, Mittelfr;97258;09339;Bayern
Ipsheim;;91472;09846;Bayern
Irchenrieth;;92699;09659;Bayern
Irlbach;a d Donau;94342;09424;Bayern
Irmannsweiler;;73566;07173;Baden-Württemberg
Irmenach;;56843;06541;Rheinland-Pfalz
Irmtraut;;56479;06436;Rheinland-Pfalz
Irndorf;;78597;07466;Baden-Württemberg
Irrel;;54666;06525;Rheinland-Pfalz
Irrhausen;;54689;06550;Rheinland-Pfalz
Irsch;, Saar;54451;06581;Rheinland-Pfalz
Irschenberg;;83737;08025;Bayern
Irsee;;87660;08341;Bayern
Irxleben;;39167;039204;Sachsen-Anhalt
Isen;, Oberbay;84424;08083;Bayern
Isenburg;, Westerw;56271;02601;Rheinland-Pfalz
Isenbüttel;;38550;05374;Niedersachsen
Iserlohn;;58636;02371;Nordrhein-Westfalen
Iserlohn;;58638;02371;Nordrhein-Westfalen
Iserlohn;;58640;02371;Nordrhein-Westfalen
Iserlohn;;58642;02371;Nordrhein-Westfalen
Iserlohn;;58644;02371;Nordrhein-Westfalen
Isernhagen;;30916;05139;Niedersachsen
Isert;;57612;02681;Rheinland-Pfalz
Ismaning;;85737;08123;Bayern
Isny;im Allgäu;88316;07562;Baden-Württemberg
Ispringen;;75228;07231;Baden-Württemberg
Isselbach;;65558;06439;Rheinland-Pfalz
Isselburg;;46419;02874;Nordrhein-Westfalen
Isseroda;;99428;03643;Thüringen
Issersheilingen;;99947;036043;Thüringen
Isserstedt;;07751;036425;Thüringen
Isserstedt;;07751;036425;Thüringen
Issigau;;95188;09293;Bayern
Issum;;47661;02835;Nordrhein-Westfalen
Isterberg;;48465;05922;Niedersachsen
Itterbeck;;49847;05948;Niedersachsen
Ittlingen;;74930;07266;Baden-Württemberg
Itzehoe;;25524;04821;Schlewig-Holstein
Itzgrund;;96274;09533;Bayern
Itzstedt;;23845;04535;Schlewig-Holstein
Iven;;17391;039723;Mecklenburg-Vorpommern
Ivenack;;17153;039600;Mecklenburg-Vorpommern
Ivendorf;b Bad Doberan;18211;038203;Mecklenburg-Vorpommern
Ivenrode;;39343;039055;Sachsen-Anhalt
Jabel;b Wittstock, Dosse;16909;03394;Brandenburg
Jabel;b Waren;17194;039929;Mecklenburg-Vorpommern
Jabelitz;b Bützow;18246;0385;Mecklenburg-Vorpommern
Jachenau;;83676;08043;Bayern
Jacobsdorf;, Mark;15236;033608;Brandenburg
Jade;;26349;04454;Niedersachsen
Jaebetz;;17209;039924;Mecklenburg-Vorpommern
Jagdschloß Thiergarten;, Gem Altenthann;93093;09403;Bayern
Jagdshof;;96515;03675;Thüringen
Jagel;b Ludwigslust;19309;038780;Brandenburg
Jagel;b Schleswig;24878;04624;Schlewig-Holstein
Jägerhaus;b Reichenbach, Vogtl;08491;;Sachsen
Jägerlust, Forsthaus;b Rockenhausen;67808;;Rheinland-Pfalz
Jagsal;;04936;035361;Brandenburg
Jagsthausen;b Heilbronn, Neckar;74249;07943;Baden-Württemberg
Jagstzell;;73489;07967;Baden-Württemberg
Jahn;b Hufschlag, Oberbay;83359;;Bayern
Jahnberge;;16833;033237;Brandenburg
Jahnhöhe;;85276;08441;Bayern
Jahnishausen;;01594;03525;Sachsen
Jahnishausen;;01594;03525;Sachsen
Jahnsdorf;/Erzgeb.;09387;03721;Sachsen
Jahnsfelde;;15320;033477;Brandenburg
Jahrsdorf;, Holst;24594;04871;Schlewig-Holstein
Jahrstedt;;38486;039008;Sachsen-Anhalt
Jakobsberg;b Gaildorf;74417;07176;Baden-Württemberg
Jakobsdorf;b Stralsund;18442;038327;Mecklenburg-Vorpommern
Jakobsweiler;;67814;06357;Rheinland-Pfalz
Jamel;b Wismar, Meckl;23968;03841;Mecklenburg-Vorpommern
Jameln;;29479;05864;Niedersachsen
Jamlitz;;15868;033671;Brandenburg
Jämlitz;;03130;035771;Brandenburg
Jandelsbrunn;;94118;08583;Bayern
Jänickendorf;b Fürstenwalde, Spree;15518;033637;Brandenburg
Janisroda;;06618;034466;Sachsen-Anhalt
Janneby;;24992;04607;Schlewig-Holstein
Jännersdorf;;16949;033969;Brandenburg
Jannowitz;, OL;01945;035752;Brandenburg
Jänschwalde;;03197;035607;Brandenburg
Jänschwalde Ost;;03197;035607;Brandenburg
Japenzin;;17392;039727;Mecklenburg-Vorpommern
Jarchau;;39596;03931;Sachsen-Anhalt
Jarchow;;19376;038729;Mecklenburg-Vorpommern
Jardelund;;24994;04605;Schlewig-Holstein
Jarmen;;17126;039997;Mecklenburg-Vorpommern
Jarplund-Weding;;24941;0461;Schlewig-Holstein
Jasnitz;;19230;038751;Mecklenburg-Vorpommern
Jatzke;;17099;03968;Mecklenburg-Vorpommern
Jatznick;;17309;039741;Mecklenburg-Vorpommern
Jävenitz;;39638;039086;Sachsen-Anhalt
Jeber-Bergfrieden;;06862;034907;Sachsen-Anhalt
Jeckenbach;;55592;06753;Rheinland-Pfalz
Jederitz;;39524;039387;Sachsen-Anhalt
Jeeben;;38489;039000;Sachsen-Anhalt
Jeese;;23936;03881;Mecklenburg-Vorpommern
Jeetze;;39624;039030;Sachsen-Anhalt
Jeggau;;39649;039082;Sachsen-Anhalt
Jeggeleben;;29416;039009;Sachsen-Anhalt
Jehserig;;03116;035602;Brandenburg
Jellen b Dobbertin;;19399;038736;Mecklenburg-Vorpommern
Jellen b Goldberg;, Meckl;19399;;Mecklenburg-Vorpommern
Jelmstorf;;29585;05821;Niedersachsen
Jembke;;38477;05366;Niedersachsen
Jemgum;;26844;04958;Niedersachsen
Jena;;07743;03641;Thüringen
Jena;;07745;03641;Thüringen
Jena;;07747;03641;Thüringen
Jena;;07749;03641;Thüringen
Jenalöbnitz;;07751;03641;Thüringen
Jenaprießnitz;;07751;03641;Thüringen
Jenaprießnitz;;07751;03641;Thüringen
Jengen;;86860;08241;Bayern
Jennewitz;;18230;038295;Mecklenburg-Vorpommern
Jeppern;;25878;;Schlewig-Holstein
Jerchel;b Rathenow;14715;033877;Brandenburg
Jerchel;b Tangerhütte;39517;039362;Sachsen-Anhalt
Jerchel;, Altmark;39638;039087;Sachsen-Anhalt
Jeremiasmühle;;56457;02663;Rheinland-Pfalz
Jerichow;;39319;039343;Sachsen-Anhalt
Jerischke;;03159;035600;Brandenburg
Jerrisbekfeld;b Jörl;24992;;Schlewig-Holstein
Jerrishoe;;24963;04638;Schlewig-Holstein
Jersbek;;22941;04532;Schlewig-Holstein
Jersleben;;39326;039201;Sachsen-Anhalt
Jerxheim;;38381;05354;Niedersachsen
Jesberg;;34632;06695;Hessen
Jesendorf;b Warin;19417;0385;Mecklenburg-Vorpommern
Jesenwang;;82287;08146;Bayern
Jeserig;b Brandenburg an der Havel;14778;033207;Brandenburg
Jeserig;b Brück;14822;033843;Brandenburg
Jeserig;, Fläming;14827;033849;Brandenburg
Jeserigerhütten;;14827;033849;Brandenburg
Jeseritz;;39638;039087;Sachsen-Anhalt
Jesewitz;;04838;034241;Sachsen
Jeshop;;23623;;Schlewig-Holstein
Jessen;(Elster);06917;03537;Sachsen-Anhalt
Jessenitz;b Lübtheen;19249;038855;Mecklenburg-Vorpommern
Jessenitz-Werk;;19249;038855;Mecklenburg-Vorpommern
Jessern;;15913;035478;Brandenburg
Jeßnigk;;04916;03535;Brandenburg
Jeßnitz;, Anh;06800;03494;Sachsen-Anhalt
Jesteburg;;21266;04183;Niedersachsen
Jestetten;;79798;07745;Baden-Württemberg
Jethe;;03149;035695;Brandenburg
Jetsch;;15938;035453;Brandenburg
Jettenbach;, Pfalz;66887;06385;Rheinland-Pfalz
Jettenbach;, Oberbay;84555;08638;Bayern
Jettenhausen;;82064;08170;Bayern
Jettingen;, Württ;71131;07452;Baden-Württemberg
Jettingen-Scheppach;;89343;08225;Bayern
Jetzendorf;;85305;08137;Bayern
Jevenberg;;24797;04331;Schlewig-Holstein
Jevenstedt;;24808;04337;Schlewig-Holstein
Jever;;26441;04461;Niedersachsen
Joachimsthal;;16247;033361;Brandenburg
Jocketa;;08543;037439;Sachsen
Jockgrim;;76751;07271;Rheinland-Pfalz
Jocksdorf;;03149;035695;Brandenburg
Johannesberg;, Unterfr;63867;06021;Bayern
Johanngeorgenstadt;;08349;03773;Sachsen
Johannisberg;b Strasburg;17337;039607;Mecklenburg-Vorpommern
Johanniskirchen;;84381;08564;Bayern
Johannstorf;;23942;038826;Mecklenburg-Vorpommern
Jöhstadt;;09477;037343;Sachsen
Joldelund;;25862;04673;Schlewig-Holstein
Jonaswalde;;04626;036608;Thüringen
Jördenstorf;;17168;039977;Mecklenburg-Vorpommern
Jork;, Niederelbe;21635;04162;Niedersachsen
Jörl;;24992;04607;Schlewig-Holstein
Jörnstorf;;18233;038294;Mecklenburg-Vorpommern
Josefsmühle;;67294;;Rheinland-Pfalz
Jossgrund;;63637;06059;Hessen
Jößnitz;;08547;03741;Sachsen
Jößnitz;;08547;03741;Sachsen
Jübar;;38489;039003;Sachsen-Anhalt
Jübek;;24855;04625;Schlewig-Holstein
Jüchen;;41363;02165;Nordrhein-Westfalen
Juchhöh;b Döbeln;04720;034325;Sachsen
Jüchsen;;98631;036947;Thüringen
Jückelberg;;04618;034497;Thüringen
Jucken;;54689;06550;Rheinland-Pfalz
Judenbach;;96515;03675;Thüringen
Jüdenberg;;06773;034904;Sachsen-Anhalt
Jugendherberge Hormersdorf;;09468;037346;Sachsen
Jugendseeheim Kassel;;25992;04651;Schlewig-Holstein
Jugenheim;in Rheinhessen;55270;06130;Rheinland-Pfalz
Jühnde;;37127;05502;Niedersachsen
Jühnsdorf;;15831;03379;Brandenburg
Juist;;26571;04935;Niedersachsen
Julbach;, Niederbay;84387;08571;Bayern
Jülchendorf;;19412;038483;Mecklenburg-Vorpommern
Jülich;;52428;02461;Nordrhein-Westfalen
Juliusburg;;21483;04153;Schlewig-Holstein
Junghof;, Pfalz;67316;;Rheinland-Pfalz
Jungingen;b Hechingen;72417;07477;Baden-Württemberg
Jünkerath;;54584;06597;Rheinland-Pfalz
Jürgenshagen;b Bützow;18246;038466;Mecklenburg-Vorpommern
Jürgenstorf;b Reuterstadt Stavenhagen;17153;039955;Mecklenburg-Vorpommern
Jüterbog;;14913;03372;Brandenburg
Jütrichau;;39264;03923;Sachsen-Anhalt
Jützenbach;;37345;036072;Thüringen
Kaaks;;25582;04893;Schlewig-Holstein
Kaakstedt;;17268;039887;Brandenburg
Kaarßen;;19273;038845;Niedersachsen
Kaarst;;41564;02131;Nordrhein-Westfalen
Kaarz;;19412;038483;Mecklenburg-Vorpommern
Kabelhorst;;23738;04363;Schlewig-Holstein
Kablow;;15758;03375;Brandenburg
Kablow Ziegelei;;15758;03375;Brandenburg
Käbschütztal;;01665;035244;Sachsen
Kachstedt;;06556;03466;Thüringen
Kade;;39307;039347;Sachsen-Anhalt
Kadelhof;;15848;;Brandenburg
Kaden;, Westerw;56459;02663;Rheinland-Pfalz
Kadenbach;;56337;02620;Rheinland-Pfalz
Kadow;b Parchim;19374;038727;Mecklenburg-Vorpommern
Kaeselow;;19205;038874;Mecklenburg-Vorpommern
Kagar;;16837;033923;Brandenburg
Kagel;;15345;033434;Brandenburg
Kägsdorf;;18230;038293;Mecklenburg-Vorpommern
Kahl;a. Main;63796;06188;Bayern
Kahla;, Elster;04928;03533;Brandenburg
Kahla;, Thür;07768;036424;Thüringen
Kählen;;24232;;Schlewig-Holstein
Kahlenberg;b Eberswalde;16230;;Brandenburg
Kahlenberg;b Wismar, Meckl;23992;038422;Mecklenburg-Vorpommern
Kahler Berg;;34225;;Hessen
Kahlkamp;;24211;04303;Schlewig-Holstein
Kahlwinkel;;06647;036377;Sachsen-Anhalt
Kahnstieg;;39646;039004;Sachsen-Anhalt
Kahrstedt;;39624;039080;Sachsen-Anhalt
Kaifenheim;;56761;02653;Rheinland-Pfalz
Kail;;56829;02672;Rheinland-Pfalz
Kaisborstel;;25560;04892;Schlewig-Holstein
Kaisermühle;, Eifel;54518;06575;Rheinland-Pfalz
Kaisermühle;b Annweiler am Trifels;76855;;Rheinland-Pfalz
Kaisersbach;im Welzheimer Wald;73667;07184;Baden-Württemberg
Kaisersesch;;56759;02653;Rheinland-Pfalz
Kaisershagen;;99974;036023;Thüringen
Kaiserslautern;;67655;0631;Rheinland-Pfalz
Kaiserslautern;;67657;0631;Rheinland-Pfalz
Kaiserslautern;;67659;0631;Rheinland-Pfalz
Kaiserslautern;;67661;0631;Rheinland-Pfalz
Kaiserslautern;;67663;0631;Rheinland-Pfalz
Kaiser-Wilhelm-Koog;;25709;04856;Schlewig-Holstein
Kaisheim;;86687;09009;Bayern
Kakau;b Bitterfeld;06785;034904;Sachsen-Anhalt
Kakenstorf;;21255;04186;Niedersachsen
Kakerbeck;, Altmark;39624;039081;Sachsen-Anhalt
Kalbach;, Rhön;36148;09742;Hessen
Kalbe;b Sittensen;27419;04282;Niedersachsen
Kalbe;(Milde);39624;039080;Sachsen-Anhalt
Kälberfeld;;99848;03622;Thüringen
Kalbergerhof;;54523;;Rheinland-Pfalz
Kalbitz;b Riesa;01594;035268;Sachsen
Kalbsrieth;;06556;03466;Thüringen
Kalchreuth;;90562;0911;Bayern
Kalefeld;;37589;05553;Niedersachsen
Kalenborn;, Kr Ahrweiler;53505;02643;Rheinland-Pfalz
Kalenborn;b Kaisersesch;56759;02653;Rheinland-Pfalz
Kalenborn-Scheuern;;54570;06591;Rheinland-Pfalz
Kalkar;, Niederrhein;47546;02824;Nordrhein-Westfalen
Kalkhorst;;23942;038827;Mecklenburg-Vorpommern
Kalkofen;, Pfalz;67822;06362;Rheinland-Pfalz
Kalkofen, Gem Dörnberg;;56377;06439;Rheinland-Pfalz
Kall;;53925;02441;Nordrhein-Westfalen
Kalletal;;32689;05264;Nordrhein-Westfalen
Kallinchen;;15806;033769;Brandenburg
Kallmerode;;37327;03605;Thüringen
Kallmünz;;93183;09473;Bayern
Kallstadt;, Pfalz;67169;06322;Rheinland-Pfalz
Kalt;;56294;02605;Rheinland-Pfalz
Kaltau;;57537;02742;Rheinland-Pfalz
Kaltbuch;;96317;;Bayern
Kalten;;83739;;Bayern
Kaltenborn;b Rochlitz;09306;034381;Sachsen
Kaltenborn;b Jüterbog;14913;033741;Brandenburg
Kaltenborn;b Adenau;53520;02691;Rheinland-Pfalz
Kaltenbrunn;b Großhelfendorf;85655;08095;Bayern
Kaltenbrunn;, Oberpf;92700;09646;Bayern
Kaltenengers;;56220;02630;Rheinland-Pfalz
Kaltenhof;b Perleberg;19348;038797;Brandenburg
Kaltenholzhausen;;65558;06430;Rheinland-Pfalz
Kaltenkirchen;, Holst;24568;04191;Schlewig-Holstein
Kaltenlengsfeld;;36452;036966;Thüringen
Kaltennordheim;;36452;036966;Thüringen
Kaltensundheim;;98634;036946;Thüringen
Kaltental;, Schwab;87662;08345;Bayern
Kaltenwanghof;;73235;;Baden-Württemberg
Kaltenwestheim;;98634;036946;Thüringen
Kaltohmfeld;;37339;036074;Thüringen
Kalübbe;, Holst;24326;04326;Schlewig-Holstein
Kambs;b Röbel;17207;039922;Mecklenburg-Vorpommern
Kambs;b Bützow;18258;03844;Mecklenburg-Vorpommern
Kamen;, Westf;59174;02307;Nordrhein-Westfalen
Kamenz;;01917;03578;Sachsen
Kamerlanderdeich;;25379;;Schlewig-Holstein
Kamern;b Schönhausen, Elbe;39524;039382;Sachsen-Anhalt
Kamin;;18233;038297;Mecklenburg-Vorpommern
Kammeltal;;89358;08223;Bayern
Kammerermühle;;92681;09682;Bayern
Kammerforst;, Westerw;56206;02624;Rheinland-Pfalz
Kammerforst;b Mühlhausen, Thür;99986;036028;Thüringen
Kämmeritze;;07318;;Thüringen
Kammermark;;16928;033986;Brandenburg
Kammerstein;;91126;09122;Bayern
Kammin;b Greifswald;17506;038356;Mecklenburg-Vorpommern
Kamminke;;17419;038376;Mecklenburg-Vorpommern
Kammlach;;87754;08261;Bayern
Kamp-Bornhofen;, Rhein;56341;06773;Rheinland-Pfalz
Kampen;(Sylt);25999;04651;Schlewig-Holstein
Kämpfelbach;;75236;07231;Baden-Württemberg
Kamp-Lintfort;;47475;02842;Nordrhein-Westfalen
Kamsdorf;;07334;03671;Thüringen
Kandel;, Pfalz;76870;07275;Rheinland-Pfalz
Kandern;;79400;07626;Baden-Württemberg
Kändler;;09247;03722;Sachsen
Kankelau;;21514;0451;Schlewig-Holstein
Kannawurf;;06578;036375;Thüringen
Kantow;;16845;033974;Brandenburg
Kanzach;b Bad Buchau;88422;07582;Baden-Württemberg
Kanzem;;54441;06501;Rheinland-Pfalz
Kapellendorf;;99510;036425;Thüringen
Kapellen-Drusweiler;;76889;06343;Rheinland-Pfalz
Kaperich;;56767;02657;Rheinland-Pfalz
Kappe;;16775;03307;Brandenburg
Kappel;, Hunsrück;55483;06763;Rheinland-Pfalz
Kappel-Grafenhausen;;77966;07822;Baden-Württemberg
Kappeln;, Schlei;24376;04642;Schlewig-Holstein
Kappeln;b Lauterecken;67744;06382;Rheinland-Pfalz
Kappelrodeck;;77876;07842;Baden-Württemberg
Kaps;, Gem Aying;85655;;Bayern
Kapsweyer;;76889;06340;Rheinland-Pfalz
Karbach;, Hunsrück;56281;06747;Rheinland-Pfalz
Karbach;, Unterfr;97842;09391;Bayern
Karben;;61184;06039;Hessen
Karbow-Vietlübbe;;19386;038733;Mecklenburg-Vorpommern
Karby;, Schwansen;24398;04644;Schlewig-Holstein
Karcha;;01683;035244;Sachsen
Karche-Zaacko;;15926;03544;Brandenburg
Karenz;;19294;038750;Mecklenburg-Vorpommern
Karft;;19243;038852;Mecklenburg-Vorpommern
Kargow;;17192;03991;Mecklenburg-Vorpommern
Karith;;39291;039200;Sachsen-Anhalt
Karl;;54534;06575;Rheinland-Pfalz
Kärlingerhaus;;83471;;Bayern
Karlsbad;;76307;07202;Baden-Württemberg
Karlsburg;b Greifswald;17495;038355;Mecklenburg-Vorpommern
Karlsburgerholz;;24398;04644;Schlewig-Holstein
Karlsdorf;b Stadtroda;07646;036426;Thüringen
Karlsdorf-Neuthard;;76689;07251;Baden-Württemberg
Karlsfeld;b München;85757;08131;Bayern
Karlshagen;;17449;038371;Mecklenburg-Vorpommern
Karlshausen;;54673;06564;Rheinland-Pfalz
Karlshof;b Ederheim;86739;09081;Bayern
Karlshof;, Gem Ehingen, Donau;89601;;Baden-Württemberg
Karlshöhe;b Rockenhausen;67806;06363;Rheinland-Pfalz
Karlshöhe;b Bischbrunn;97836;;Bayern
Karlshorst;b Perleberg;19348;038784;Brandenburg
Karlshuld;;86668;08454;Bayern
Karlskron;;85123;08450;Bayern
Karlsruhe;b Wittenberge;19322;038787;Brandenburg
Karlsruhe;, Baden;76131;0721;Baden-Württemberg
Karlsruhe;, Baden;76133;0721;Baden-Württemberg
Karlsruhe;, Baden;76135;0721;Baden-Württemberg
Karlsruhe;, Baden;76137;0721;Baden-Württemberg
Karlsruhe;, Baden;76139;0721;Baden-Württemberg
Karlsruhe;, Baden;76149;0721;Baden-Württemberg
Karlsruhe;, Baden;76185;0721;Baden-Württemberg
Karlsruhe;, Baden;76187;0721;Baden-Württemberg
Karlsruhe;, Baden;76189;0721;Baden-Württemberg
Karlsruhe;, Baden;76199;0721;Baden-Württemberg
Karlsruhe;, Baden;76227;0721;Baden-Württemberg
Karlsruhe;, Baden;76228;0721;Baden-Württemberg
Karlsruhe;, Baden;76229;0721;Baden-Württemberg
Karlstadt;, Main;97753;09353;Bayern
Karlstein;a. Main;63791;06188;Bayern
Karlsthal Bahnhof;b Kaiserslautern;67707;;Rheinland-Pfalz
Karlum;;25926;04666;Schlewig-Holstein
Karnin;b Stralsund;18469;038324;Mecklenburg-Vorpommern
Karnitz;, Rügen;18574;03838;Mecklenburg-Vorpommern
Karolinenkoog;;25774;04882;Schlewig-Holstein
Karow;b Lübz;19395;038738;Mecklenburg-Vorpommern
Karow;b Wismar, Meckl;23966;03841;Mecklenburg-Vorpommern
Karow;b Genthin;39307;039347;Sachsen-Anhalt
Karrenzin;;19372;038725;Mecklenburg-Vorpommern
Karsbach;;97783;09358;Bayern
Karschau;, Gem Ekenis;24407;;Schlewig-Holstein
Karsdorf;, Unstrut;06638;034461;Sachsen-Anhalt
Karstädt;b Ludwigslust;19294;038797;Mecklenburg-Vorpommern
Karstädt;, Prignitz;19357;038797;Brandenburg
Karthan;;19336;038791;Brandenburg
Karthause Grünau;;97907;;Bayern
Kartzow;;14476;033208;Brandenburg
Karwe;b Neuruppin;16818;033925;Brandenburg
Karwe;b Perleberg;19357;038783;Brandenburg
Karwesee;;16833;033922;Brandenburg
Karwitz;;29481;05861;Niedersachsen
Kasbach-Ohlenberg;;53547;02644;Rheinland-Pfalz
Kaschenbach;;54668;06568;Rheinland-Pfalz
Kasdorf;;56357;06772;Rheinland-Pfalz
Kasel;, Ruwer;54317;0651;Rheinland-Pfalz
Kasel-Golzig;;15938;035453;Brandenburg
Kasendorf;b Rehna;19217;038872;Mecklenburg-Vorpommern
Kasendorf;, Oberfr;95359;09228;Bayern
Käshofen;;66894;06337;Rheinland-Pfalz
Kasseburg;b Trittau;22929;0451;Schlewig-Holstein
Kasseedorf;;23717;04528;Schlewig-Holstein
Kassel;, Hess;34117;0561;Hessen
Kassel;, Hess;34119;0561;Hessen
Kassel;, Hess;34121;0561;Hessen
Kassel;, Hess;34123;0561;Hessen
Kassel;, Hess;34125;0561;Hessen
Kassel;, Hess;34127;0561;Hessen
Kassel;, Hess;34128;0561;Hessen
Kassel;, Hess;34130;0561;Hessen
Kassel;, Hess;34131;0561;Hessen
Kassel;, Hess;34132;0561;Hessen
Kassel;, Hess;34134;0561;Hessen
Kassel;, Hess;34233;0561;Hessen
Kassel;, Hess;34355;0561;Hessen
Kassieck;;39638;039084;Sachsen-Anhalt
Kassow;;18258;038453;Mecklenburg-Vorpommern
Kastahn;;23936;038822;Mecklenburg-Vorpommern
Kastellaun;;56288;06762;Rheinland-Pfalz
Kastel-Staadt;;54441;06582;Rheinland-Pfalz
Kasten;b Gauting;82131;;Bayern
Kastl;, Kr Altötting;84556;08671;Bayern
Kastl;b Amberg, Oberpf;92280;09625;Bayern
Kastl;b Kemnath, Stadt;95506;09642;Bayern
Kastorf;b Ludwigslust;19300;038755;Mecklenburg-Vorpommern
Kastorf;, Holst;23847;04501;Schlewig-Holstein
Katelbogen;;18249;038462;Mecklenburg-Vorpommern
Katerbow;;16818;033924;Brandenburg
Käterhagen;;18246;038462;Mecklenburg-Vorpommern
Katharinenheerd;;25836;04862;Schlewig-Holstein
Katharinenhof;b Oppenweiler;71570;07191;Baden-Württemberg
Katharinenrieth;;06542;034652;Sachsen-Anhalt
Katharinenthalerhof;;75177;07231;Baden-Württemberg
Käthen;;39599;039325;Sachsen-Anhalt
Kathendorf;;39359;039057;Sachsen-Anhalt
Kathlow;;03058;035694;Brandenburg
Katlenburg-Lindau;;37191;05552;Niedersachsen
Katschow;;17429;038376;Mecklenburg-Vorpommern
Kattendorf;;24568;04191;Schlewig-Holstein
Katzenbach;b Rockenhausen;67806;06361;Rheinland-Pfalz
Katzenelnbogen;;56368;06486;Rheinland-Pfalz
Katzhörn;;25899;04665;Schlewig-Holstein
Katzhütte;;98746;036781;Thüringen
Katzow;;17509;038373;Mecklenburg-Vorpommern
Katzweiler;;67734;06301;Rheinland-Pfalz
Katzwinkel;, Eifel;54552;02692;Rheinland-Pfalz
Katzwinkel;(Sieg);57581;02741;Rheinland-Pfalz
Kaub;;56349;06774;Rheinland-Pfalz
Kauern;b Gera;07554;036602;Thüringen
Kaufbeuren;;87600;08341;Bayern
Kaufering;;86916;08191;Bayern
Kaufungen;, Hess;34260;05605;Hessen
Kaulitz;;29416;039036;Sachsen-Anhalt
Kaulsdorf;, Saale;07338;036733;Thüringen
Kausche;;03130;035751;Brandenburg
Kauschwitz;;08525;03741;Sachsen
Kauschwitz;;08525;03741;Sachsen
Kausen;b Betzdorf, Sieg;57520;02747;Rheinland-Pfalz
Kauxdorf;;04924;035341;Brandenburg
Kavelstorf;;18196;038208;Mecklenburg-Vorpommern
Kayhude;;23863;04535;Schlewig-Holstein
Kayna;;06724;034426;Sachsen-Anhalt
Keez;;19412;038483;Mecklenburg-Vorpommern
Kefenrod;;63699;06049;Hessen
Kefferhausen;;37351;036075;Thüringen
Kehl;, Rhein;77694;07851;Baden-Württemberg
Kehlbach;b Nastätten;56355;06776;Rheinland-Pfalz
Kehlenmühle;;56291;;Rheinland-Pfalz
Kehmstedt;;99752;036338;Thüringen
Kehnert;;39517;039366;Sachsen-Anhalt
Kehrberg;;16928;033975;Brandenburg
Kehrig;b Mayen;56729;02651;Rheinland-Pfalz
Kehrigk;;15859;033678;Brandenburg
Keidelheim;;55471;06761;Rheinland-Pfalz
Keila;;07389;036483;Thüringen
Keindorf;;39345;039059;Sachsen-Anhalt
Keiselwitz;;04668;034386;Sachsen
Kelberg;;53539;02692;Rheinland-Pfalz
Kelbra;(Kyffhäuser);06537;034651;Sachsen-Anhalt
Kelheim;;93309;09441;Bayern
Kelkheim;(Taunus);65779;06195;Hessen
Kell;am See;54427;06589;Rheinland-Pfalz
Kella;;37308;05651;Thüringen
Kellenbach;;55606;06765;Rheinland-Pfalz
Kellenhusen;(Ostsee);23746;04364;Schlewig-Holstein
Keller;b Gransee;16775;033083;Brandenburg
Keller;, Gem Aasbüttel;25557;;Schlewig-Holstein
Kellershof;, Westerw;57645;;Rheinland-Pfalz
Kellinghusen;;25548;04822;Schlewig-Holstein
Kellmünz;a d Iller;89293;08337;Bayern
Kelsterbach;;65451;06107;Hessen
Keltern;;75210;07236;Baden-Württemberg
Kemberg;;06901;034921;Sachsen-Anhalt
Kemlitz;, NL;15926;035454;Brandenburg
Kemmen;;03205;03541;Brandenburg
Kemmenau;;56132;02603;Rheinland-Pfalz
Kemmern;;96164;09544;Bayern
Kemnath;, Stadt;95478;09642;Bayern
Kemnitz;b Potsdam;14542;03327;Brandenburg
Kemnitz;b Pritzwalk;16928;03395;Brandenburg
Kemnitz;b Greifswald;17509;038352;Mecklenburg-Vorpommern
Kempen;, Niederrhein;47906;02152;Nordrhein-Westfalen
Kempenich;;56746;02655;Rheinland-Pfalz
Kempfeld;;55758;06786;Rheinland-Pfalz
Kempten;(Allgäu);87435;0831;Bayern
Kempten;(Allgäu);87437;0831;Bayern
Kempten;(Allgäu);87439;0831;Bayern
Kendl;b Neukirchen a Teisenberg;83364;08666;Bayern
Kenn;;54344;06502;Rheinland-Pfalz
Kenzingen;;79341;07644;Baden-Württemberg
Kenz-Küstrow;;18314;038231;Mecklenburg-Vorpommern
Keppeshausen;;54673;06524;Rheinland-Pfalz
Kerben;;56295;02654;Rheinland-Pfalz
Kerkau;;29416;039034;Sachsen-Anhalt
Kerken;;47647;02833;Nordrhein-Westfalen
Kerkow;;16278;03331;Brandenburg
Kernen;im Remstal;71394;07151;Baden-Württemberg
Kerpen;, Rheinl;50169;02237;Nordrhein-Westfalen
Kerpen;, Rheinl;50170;02237;Nordrhein-Westfalen
Kerpen;, Rheinl;50171;02237;Nordrhein-Westfalen
Kerpen;(Eifel);54578;06593;Rheinland-Pfalz
Kerschbaum;b Windorf, Niederbay;94529;08546;Bayern
Kerschenbach;;54589;06597;Rheinland-Pfalz
Kerspleben;;99198;036203;Thüringen
Kerspleben;;99198;036203;Thüringen
Kerzendorf;b Ludwigsfelde;14974;03378;Brandenburg
Kerzenheim;;67304;06351;Rheinland-Pfalz
Kerzlin;;16845;033928;Brandenburg
Kescheid;;57632;02685;Rheinland-Pfalz
Kesfeld;;54619;06559;Rheinland-Pfalz
Kesseling;;53506;02647;Rheinland-Pfalz
Kesselsdorf;;01723;035204;Sachsen
Kessin;b Rostock;18196;038208;Mecklenburg-Vorpommern
Kesten;;54518;06535;Rheinland-Pfalz
Kestert;;56348;06773;Rheinland-Pfalz
Ketsch;, Rhein;68775;0621;Baden-Württemberg
Kettemers Mühle;;56271;;Rheinland-Pfalz
Ketten;;36419;036967;Thüringen
Kettenhausen;;57612;02681;Rheinland-Pfalz
Kettenheim;, Rheinhess;55234;06731;Rheinland-Pfalz
Kettenkamp;;49577;05436;Niedersachsen
Kettershausen;;86498;08333;Bayern
Kettig;b Koblenz am Rhein;56220;02637;Rheinland-Pfalz
Ketzin;;14669;033233;Brandenburg
Ketzür;;14778;033836;Brandenburg
Keula;b Sondershausen;99713;036029;Thüringen
Kevelaer;;47623;02832;Nordrhein-Westfalen
Kevelaer;;47624;02832;Nordrhein-Westfalen
Kevelaer;;47625;02832;Nordrhein-Westfalen
Kevelaer;;47626;02832;Nordrhein-Westfalen
Kevelaer;;47627;02832;Nordrhein-Westfalen
Kickeshausen;;54689;06550;Rheinland-Pfalz
Kiebitzberg, Gem Beveringen;;16928;;Brandenburg
Kiebitzreihe;;25368;04121;Schlewig-Holstein
Kiedrich;, Rheingau;65399;06123;Hessen
Kiefersfelden;;83088;08033;Bayern
Kiehnwerder;;15324;033475;Brandenburg
Kiekebusch;, NL;03058;0355;Brandenburg
Kiekebusch;b Königs Wusterhausen;15749;033762;Brandenburg
Kiel;, Ostsee;24103;0431;Schlewig-Holstein
Kiel;, Ostsee;24105;0431;Schlewig-Holstein
Kiel;, Ostsee;24106;0431;Schlewig-Holstein
Kiel;, Ostsee;24107;0431;Schlewig-Holstein
Kiel;, Ostsee;24109;0431;Schlewig-Holstein
Kiel;, Ostsee;24111;0431;Schlewig-Holstein
Kiel;, Ostsee;24113;0431;Schlewig-Holstein
Kiel;, Ostsee;24114;0431;Schlewig-Holstein
Kiel;, Ostsee;24116;0431;Schlewig-Holstein
Kiel;, Ostsee;24118;0431;Schlewig-Holstein
Kiel;, Ostsee;24143;0431;Schlewig-Holstein
Kiel;, Ostsee;24145;0431;Schlewig-Holstein
Kiel;, Ostsee;24146;0431;Schlewig-Holstein
Kiel;, Ostsee;24147;0431;Schlewig-Holstein
Kiel;, Ostsee;24148;0431;Schlewig-Holstein
Kiel;, Ostsee;24149;0431;Schlewig-Holstein
Kiel;, Ostsee;24159;0431;Schlewig-Holstein
Kienbaum;;15345;033434;Brandenburg
Kienberg;b Nauen;14641;033230;Brandenburg
Kienberg;, Oberbay;83361;08628;Bayern
Kienitz;;15324;033478;Brandenburg
Kienwerder;b Potsdam;14532;03329;Brandenburg
Kierspe;;58566;02359;Nordrhein-Westfalen
Kiesby;;24392;04641;Schlewig-Holstein
Kieselbach;b Bad Salzungen;36460;036963;Thüringen
Kieselbronn;;75249;07231;Baden-Württemberg
Kieselwitz;;15890;033654;Brandenburg
Kietz;b Rhinow;14728;033875;Brandenburg
Kieve;;17209;039925;Mecklenburg-Vorpommern
Kiliansroda;;99441;036453;Thüringen
Kindelbrück;;99638;036375;Thüringen
Kindenheim;;67271;06359;Rheinland-Pfalz
Kinderbeuern;;54538;06532;Rheinland-Pfalz
Kinding;;85125;08467;Bayern
Kindsbach;, Pfalz;66862;06371;Rheinland-Pfalz
Kindsbach, Forsthaus;b Kaiserslautern;67661;;Rheinland-Pfalz
Kindshagen;;18314;038324;Mecklenburg-Vorpommern
Kinheim;;54538;06532;Rheinland-Pfalz
Kinsau;;86981;08869;Bayern
Kinzenburg;;54597;06554;Rheinland-Pfalz
Kipfenberg;, Oberbayern;85110;08465;Bayern
Kippenheim;;77971;07825;Baden-Württemberg
Kirburg;;57629;02661;Rheinland-Pfalz
Kirch Jesar;;19230;03883;Mecklenburg-Vorpommern
Kirch Mulsow;;18233;038297;Mecklenburg-Vorpommern
Kirchanschöring;;83417;08685;Bayern
Kirchardt;;74912;07266;Baden-Württemberg
Kirchbarkau;;24245;04302;Schlewig-Holstein
Kirchberg;, Sachs;08107;037602;Sachsen
Kirchberg;(Hunsrück);55481;06763;Rheinland-Pfalz
Kirchberg;an der Murr;71737;07144;Baden-Württemberg
Kirchberg;an der Jagst;74592;07954;Baden-Württemberg
Kirchberg;, Holzland;84434;08762;Bayern
Kirchberg;an der Iller;88486;07354;Baden-Württemberg
Kirchberg i. Wald;;94259;09927;Bayern
Kirchbrak;;37619;05534;Niedersachsen
Kirchdorf;b Grimmen;18519;038351;Mecklenburg-Vorpommern
Kirchdorf;b Sulingen;27245;04273;Niedersachsen
Kirchdorf;b Haag, Oberbay;83527;08072;Bayern
Kirchdorf;a. Inn;84375;08571;Bayern
Kirchdorf;a d Amper;85414;08166;Bayern
Kirchdorf;an der Iller;88457;07354;Baden-Württemberg
Kirchdorf;, Kr Kelheim;93348;09444;Bayern
Kirchdorf;i. Wald;94261;09928;Bayern
Kirchehrenbach;;91356;09191;Bayern
Kircheib;;57635;02683;Rheinland-Pfalz
Kirchen;(Sieg);57548;02741;Rheinland-Pfalz
Kirchendemenreuth;;92665;09681;Bayern
Kirchengel;;99718;036379;Thüringen
Kirchenlamitz;;95158;09285;Bayern
Kirchenpingarten;;95466;09278;Bayern
Kirchensittenbach;;91241;09151;Bayern
Kirchensur;;83552;08074;Bayern
Kirchentellinsfurt;;72138;07121;Baden-Württemberg
Kirchenthumbach;;91281;09647;Bayern
Kirchgandern;;37318;036081;Thüringen
Kirchgellersen;;21394;04135;Niedersachsen
Kirchhain;;35274;06422;Hessen
Kirchhalling;;83329;08681;Bayern
Kirchham;, Niederbay;94148;08533;Bayern
Kirchhasel;b Rudolstadt;07407;03672;Thüringen
Kirchhaslach;;87755;08333;Bayern
Kirchheilingen;;99947;036043;Thüringen
Kirchheim;, Hess;36275;06625;Hessen
Kirchheim;an der Weinstraße;67281;06359;Rheinland-Pfalz
Kirchheim;unter Teck;73230;07021;Baden-Württemberg
Kirchheim;am Ries;73467;07021;Baden-Württemberg
Kirchheim;am Neckar;74366;07143;Baden-Württemberg
Kirchheim;b. München;85551;089;Bayern
Kirchheim;, Schwab;87757;08266;Bayern
Kirchheim;, Unterfr;97268;09366;Bayern
Kirchheim;b Arnstadt;99334;036200;Thüringen
Kirchheimbolanden;;67292;06352;Rheinland-Pfalz
Kirchhundem;;57399;02723;Nordrhein-Westfalen
Kirchlauter;;96166;09536;Bayern
Kirchlengern;;32278;05223;Nordrhein-Westfalen
Kirchlinteln;;27308;04236;Niedersachsen
Kirch-Mummendorf;;23936;;Mecklenburg-Vorpommern
Kirchnüchel;;23714;04528;Schlewig-Holstein
Kirchohmfeld;;37339;036074;Thüringen
Kirchroth;;94356;09428;Bayern
Kirchsahr;;53505;02643;Rheinland-Pfalz
Kirchscheidungen;;06636;034462;Sachsen-Anhalt
Kirchseelte;;27243;04206;Niedersachsen
Kirchseeon;;85614;08091;Bayern
Kirchstätt;b Traunwalchen;83374;08669;Bayern
Kirchtimke;;27412;04289;Niedersachsen
Kirchwald;b Mayen;56729;02651;Rheinland-Pfalz
Kirchwalsede;;27386;04269;Niedersachsen
Kirchweidach;;84558;08623;Bayern
Kirchweiler;;54570;06595;Rheinland-Pfalz
Kirchwistedt;;27616;04747;Niedersachsen
Kirchworbis;;37339;036074;Thüringen
Kirchzarten;, Breisgau;79199;07661;Baden-Württemberg
Kirchzell;;63931;09373;Bayern
Kirf;;54441;06582;Rheinland-Pfalz
Kirkel;;66459;06849;Saarland
Kirmutscheid;;53534;02691;Rheinland-Pfalz
Kirn;, Nahe;55606;06752;Rheinland-Pfalz
Kirrweiler;(Pfalz);67489;06321;Rheinland-Pfalz
Kirrweiler;b Lauterecken;67744;06387;Rheinland-Pfalz
Kirsbach;;53539;02692;Rheinland-Pfalz
Kirschau;;02681;03592;Sachsen
Kirschbacherhof;;66482;06339;Rheinland-Pfalz
Kirschbachermühle;;66482;06339;Rheinland-Pfalz
Kirschfurt;;97896;09375;Bayern
Kirschkau;;07919;03663;Thüringen
Kirschroth;;55566;06751;Rheinland-Pfalz
Kirschweiler;;55743;06781;Rheinland-Pfalz
Kirtorf;;36320;06635;Hessen
Kisdorf;, Holst;24629;04193;Schlewig-Holstein
Kisselbach;, Hunsrück;56291;06766;Rheinland-Pfalz
Kissenbrück;;38324;05337;Niedersachsen
Kissing;;86438;08233;Bayern
Kißleberfeld;;38350;05355;Niedersachsen
Kißlegg;;88353;07563;Baden-Württemberg
Kist;;97270;09306;Bayern
Kittelsthal;;99843;036929;Thüringen
Kittendorf;;17153;039955;Mecklenburg-Vorpommern
Kittlitz;b Löbau;02708;03585;Sachsen
Kittlitz;b Lübbenau;03222;03542;Brandenburg
Kittlitz;b Ratzeburg;23911;04546;Schlewig-Holstein
Kitzen;b Leipzig;04460;034296;Sachsen
Kitzingen;;97318;09321;Bayern
Kitzscher;;04567;035828;Sachsen
Kläden;b Stendal;39579;039324;Sachsen-Anhalt
Kläden;b Arendsee, Altmark;39619;039324;Sachsen-Anhalt
Kladrum;;19374;038723;Mecklenburg-Vorpommern
Klaftertalerhof;;67691;06305;Rheinland-Pfalz
Klais;;82493;08823;Bayern
Klamp;;24321;04381;Schlewig-Holstein
Klanxbüll;;25924;04668;Schlewig-Holstein
Klappholz;;24860;04603;Schlewig-Holstein
Klasdorf;;15837;033704;Brandenburg
Klaubmühle;;97486;09536;Bayern
Klausdorf;b Jüterbog;14929;033748;Brandenburg
Klausdorf;b Zossen b Berlin;15838;033703;Brandenburg
Klausdorf;b Stralsund;18445;038323;Mecklenburg-Vorpommern
Klausdorf;, Schwentine;24147;0431;Schlewig-Holstein
Klausen;, Mosel;54524;06578;Rheinland-Pfalz
Kleekamp;b Warin;19417;038484;Mecklenburg-Vorpommern
Kleemeisterei;, Ostalbkr;73575;;Baden-Württemberg
Kleeste;;19348;038785;Brandenburg
Kleesten;;19399;038736;Mecklenburg-Vorpommern
Klein Ammensleben;;39326;039202;Sachsen-Anhalt
Klein Barkau;;24245;04302;Schlewig-Holstein
Klein Behnitz;;14641;033239;Brandenburg
Klein Belitz;;18246;038466;Mecklenburg-Vorpommern
Klein Bengerstorf;;19258;038847;Mecklenburg-Vorpommern
Klein Bennebek;;24848;04624;Schlewig-Holstein
Klein Berßen;;49777;05965;Niedersachsen
Klein Bölkow;;18239;038207;Mecklenburg-Vorpommern
Klein Briesen;b Belzig;14806;033846;Brandenburg
Klein Bünsdorf;;23923;038828;Mecklenburg-Vorpommern
Klein Bünzow;;17390;039724;Mecklenburg-Vorpommern
Klein Chüden;;29410;;Sachsen-Anhalt
Klein Döbbern;;03058;035608;Brandenburg
Klein Düben;;03130;035600;Brandenburg
Klein Gartz;;29416;039037;Sachsen-Anhalt
Klein Gischow;;18246;038466;Mecklenburg-Vorpommern
Klein Gladebrügge;;23795;04551;Schlewig-Holstein
Klein Gnemern;;18246;038464;Mecklenburg-Vorpommern
Klein Görnow;;19406;03847;Mecklenburg-Vorpommern
Klein Gottschow;;19348;033983;Brandenburg
Klein Grönau;;23627;;Schlewig-Holstein
Klein Grönau;;23627;;Schlewig-Holstein
Klein Haßlow;;16909;03394;Brandenburg
Klein Heikeland;;26759;04925;Niedersachsen
Klein Jarchow;;19412;;Mecklenburg-Vorpommern
Klein Kampen;, Gem Dammfleth;25573;04823;Schlewig-Holstein
Klein Kölzig;;03159;035600;Brandenburg
Klein Krams;;19288;038754;Mecklenburg-Vorpommern
Klein Kreutz;;14778;03381;Brandenburg
Klein Kreutz;;14778;03381;Brandenburg
Klein Kussewitz;;18184;038202;Mecklenburg-Vorpommern
Klein Labenz;;19417;038482;Mecklenburg-Vorpommern
Klein Langerwisch;;16928;;Brandenburg
Klein Leine;;15913;035471;Brandenburg
Klein Leppin;;19322;038787;Brandenburg
Klein Linde;;19348;03876;Brandenburg
Klein Loitz;;03130;035698;Brandenburg
Klein Lüben;;19322;038791;Brandenburg
Klein Luckow;b Strasburg;17337;039752;Mecklenburg-Vorpommern
Klein Lukow;;17217;03962;Mecklenburg-Vorpommern
Klein Marzehns;;14823;033848;Brandenburg
Klein Meckelsen;;27419;04282;Niedersachsen
Klein Molzahn;;19217;038875;Mecklenburg-Vorpommern
Klein Neuleben;;23923;038821;Mecklenburg-Vorpommern
Klein Nordende;;25336;04121;Schlewig-Holstein
Klein Offenseth-Sparrieshoop;;25365;04121;Schlewig-Holstein
Klein Oschersleben;;39398;039408;Sachsen-Anhalt
Klein Oßnig;;03058;0355;Brandenburg
Klein Pampau;;21514;04155;Schlewig-Holstein
Klein Pravtshagen;;23948;038827;Mecklenburg-Vorpommern
Klein Pritz;;19406;038485;Mecklenburg-Vorpommern
Klein Quenstedt;;38822;03941;Sachsen-Anhalt
Klein Radden;;03222;035456;Brandenburg
Klein Raden;;18249;038462;Mecklenburg-Vorpommern
Klein Rheide;;24848;04624;Schlewig-Holstein
Klein Rodensleben;;39167;039204;Sachsen-Anhalt
Klein Rogahn;;19073;0385;Mecklenburg-Vorpommern
Klein Rönnau;;23795;04551;Schlewig-Holstein
Klein Salitz;;19205;038876;Mecklenburg-Vorpommern
Klein Schierstedt;;06449;034746;Sachsen-Anhalt
Klein Schulzendorf;;14959;033731;Brandenburg
Klein Schwansee;;23942;038827;Mecklenburg-Vorpommern
Klein Schwarzlosen;;39517;039365;Sachsen-Anhalt
Klein Schwechten;;39579;039388;Sachsen-Anhalt
Klein Siemz;;23923;038828;Mecklenburg-Vorpommern
Klein Sien;;18246;038464;Mecklenburg-Vorpommern
Klein Stieten;;23972;;Mecklenburg-Vorpommern
Klein Tessin;b Krakow a See;18292;;Mecklenburg-Vorpommern
Klein Thurow;;19205;038876;Mecklenburg-Vorpommern
Klein Trebbow;b Schwerin, Meckl;19069;0385;Mecklenburg-Vorpommern
Klein Upahl;;18276;038458;Mecklenburg-Vorpommern
Klein Vielen;;17237;039824;Mecklenburg-Vorpommern
Klein Wanzleben;;39164;039209;Sachsen-Anhalt
Klein Warin;;23992;038422;Mecklenburg-Vorpommern
Klein Warin Lager;;23992;038422;Mecklenburg-Vorpommern
Klein Warnow;;19357;038788;Brandenburg
Klein Welzin;;19209;038874;Mecklenburg-Vorpommern
Klein Wesenberg;;23860;04533;Schlewig-Holstein
Klein Wieblitz;;29413;03901;Sachsen-Anhalt
Klein Wittensee;;24361;04356;Schlewig-Holstein
Klein Woltersdorf;b Pritzwalk;16928;03395;Brandenburg
Klein Zecher;;23883;04545;Schlewig-Holstein
Klein Ziethen;;16247;033364;Brandenburg
Kleinaitingen;;86507;08203;Bayern
Kleinalsleben;;39398;039408;Sachsen-Anhalt
Kleinaschau;b Frauenneuharting;83565;08039;Bayern
Kleinau;;39606;039399;Sachsen-Anhalt
Kleinbartloff;;37355;036076;Thüringen
Kleinberndten;;99713;036330;Thüringen
Kleinblittersdorf;;66271;06805;Saarland
Kleinbockedra;;07646;036428;Thüringen
Kleinbodungen;;99752;036338;Thüringen
Kleinbrembach;;99610;036372;Thüringen
Kleinbrüchter;;99713;036330;Thüringen
Kleinbundenbach;;66501;06337;Rheinland-Pfalz
Kleindröben;;06917;03537;Sachsen-Anhalt
Kleinebersdorf;;07646;036426;Thüringen
Kleineutersdorf;;07768;036424;Thüringen
Kleinfeld;b Schönberg, Meckl;23923;038828;Mecklenburg-Vorpommern
Kleinfischlingen;;67483;06347;Rheinland-Pfalz
Kleinfurra;;99735;036334;Thüringen
Kleingeschwenda;b Arnsgereuth;07318;036736;Thüringen
Kleingießhübel;;01814;035028;Sachsen
Kleinhelfendorf;;85655;08095;Bayern
Kleinhelmsdorf;;06722;036694;Sachsen-Anhalt
Kleinheubach;;63924;09371;Bayern
Kleinhub;b Wörnsmühl;83739;08064;Bayern
Kleinich;;54483;06536;Rheinland-Pfalz
Kleinjena;;06618;03445;Sachsen-Anhalt
Kleinkahl;;63828;06024;Bayern
Kleinkarlbach;;67271;06359;Rheinland-Pfalz
Kleinkarolinenfeld;;85655;08095;Bayern
Kleinkeula;;99976;036029;Thüringen
Kleinkmehlen;;01990;035755;Brandenburg
Kleinkochberg;;07407;036743;Thüringen
Kleinkoogsdeich;;25899;04665;Schlewig-Holstein
Kleinkorga;;06926;035384;Sachsen-Anhalt
Kleinkrausnik;;03249;035323;Brandenburg
Kleinlangenfeld;;54597;06552;Rheinland-Pfalz
Kleinlangheim;;97355;09325;Bayern
Kleinleinungen;;06528;034656;Sachsen-Anhalt
Kleinmachnow;;14532;033203;Brandenburg
Kleinmaischeid;;56271;02689;Rheinland-Pfalz
Kleinmockritz;;04720;034325;Sachsen
Kleinmölsen;;99198;036203;Thüringen
Kleinmühle;, Pfalz;67308;;Rheinland-Pfalz
Kleinmühlingen;;39221;039291;Sachsen-Anhalt
Klein-Mutz;;16775;03307;Brandenburg
Kleinnaundorf;;01561;035240;Sachsen
Kleinneuhausen;;99625;036372;Thüringen
Kleinniedesheim;;67259;06239;Rheinland-Pfalz
Kleinobringen;;99439;03643;Thüringen
Kleinopitz;;01737;035203;Sachsen
Kleinostheim;;63801;06027;Bayern
Kleinow;, Uckermark;17291;039858;Brandenburg
Kleinow;, Prignitz;19348;038784;Brandenburg
Kleinpaschleben;;06369;034979;Sachsen-Anhalt
Kleinrehmühle;;95356;09255;Bayern
Kleinreinsdorf;;07989;036624;Thüringen
Kleinrinderfeld;;97271;09366;Bayern
Kleinromstedt;;99510;036425;Thüringen
Kleinrössen;;04895;03535;Brandenburg
Kleinrudestedt;;99195;036371;Thüringen
Kleinschmalkalden;;98593;036849;Thüringen
Kleinschwabhausen;b Mellingen;99441;036454;Thüringen
Kleinsendelbach;;91077;09126;Bayern
Kleinsteinfeld;;76889;06340;Rheinland-Pfalz
Kleinsteinhausen;;66484;06339;Rheinland-Pfalz
Kleinthiemig;;01561;03522;Sachsen
Kleinwallstadt;;63839;06022;Bayern
Kleinwasserburg;;15748;033765;Brandenburg
Kleinwechsungen;;99735;036335;Thüringen
Kleinwelsbach;;99947;036043;Thüringen
Klein-Winternheim;;55270;06136;Rheinland-Pfalz
Kleinwolmsdorf;;01477;035200;Sachsen
Kleinzerbst;b Köthen, Anh;06386;034909;Sachsen-Anhalt
Kleinzerlang;;16831;033921;Brandenburg
Kleinziethen;b Königs Wusterhausen;15831;03379;Brandenburg
Klempau;;23628;04508;Schlewig-Holstein
Klenzenhof;;16928;033982;Brandenburg
Klepzig;b Belzig;14823;033848;Brandenburg
Kleßen;;14728;033235;Brandenburg
Klessener Zootzen;;14662;033235;Brandenburg
Klessig;;01623;035242;Sachsen
Kletkamp;;24327;04523;Schlewig-Holstein
Klettbach;;99102;036209;Thüringen
Klettgau;;79771;07742;Baden-Württemberg
Klettstedt;;99955;036041;Thüringen
Klettwitz;;01998;035754;Brandenburg
Kletzen-Zschölkau;;04519;034295;Sachsen
Kletzin;b Demmin;17111;039998;Mecklenburg-Vorpommern
Kletzin;b Wismar, Meckl;23966;03841;Mecklenburg-Vorpommern
Kletzke;;19336;038796;Brandenburg
Kleve;b Itzehoe;25554;04823;Schlewig-Holstein
Kleve;, Dithm;25779;04836;Schlewig-Holstein
Kleve;, Niederrhein;47533;02821;Nordrhein-Westfalen
Kleverhof;;17179;039973;Mecklenburg-Vorpommern
Kliding;;56825;02677;Rheinland-Pfalz
Klieken;;06869;034903;Sachsen-Anhalt
Kliestow;;14959;033731;Brandenburg
Klietz;;39524;039327;Sachsen-Anhalt
Klingelbach;;56368;06486;Rheinland-Pfalz
Klingenberg;, Sachs;01738;035202;Sachsen
Klingenberg;a. Main;63911;09372;Bayern
Klingenmühlhöfle;;73642;07182;Baden-Württemberg
Klingenmünster;;76889;06349;Rheinland-Pfalz
Klingenthal;;08248;037467;Sachsen
Klings;;36452;036966;Thüringen
Klink;b Waren;17192;03991;Mecklenburg-Vorpommern
Klinke;b Stendal;39599;039325;Sachsen-Anhalt
Klinken;;19374;038722;Mecklenburg-Vorpommern
Klinkow;;17291;03984;Brandenburg
Klinkrade;, Kr Hzgt Lauenb;23898;04536;Schlewig-Holstein
Klipphausen;;01665;035204;Sachsen
Klippmühle;, Hess;34513;05634;Hessen
Klitsche;;39307;039348;Sachsen-Anhalt
Klitten;;02906;035895;Sachsen
Klitzschen;;04860;03421;Sachsen
Klixbüll;;25899;04661;Schlewig-Holstein
Klobikau;;06246;034635;Sachsen-Anhalt
Klockow;b Waren;17219;039921;Mecklenburg-Vorpommern
Klockow;b Perleberg;19357;038782;Brandenburg
Klocksin;;17194;039933;Mecklenburg-Vorpommern
Kloddram;;19260;038848;Mecklenburg-Vorpommern
Klöden;;06917;035388;Sachsen-Anhalt
Kloschwitz;b Halle, Saale;06198;034607;Sachsen-Anhalt
Klossa;;06928;03537;Sachsen-Anhalt
Kloster;, Hiddensee;18565;038300;Mecklenburg-Vorpommern
Kloster Neuendorf;;39638;03907;Sachsen-Anhalt
Kloster Schäftlarn;;82067;08178;Bayern
Kloster St Ludwig;;97509;;Bayern
Kloster Veßra;;98660;036841;Thüringen
Kloster Zinna;;14913;03372;Brandenburg
Klosterberg;b Zeltingen-Rachtig;54492;;Rheinland-Pfalz
Klosterbrücke;;16248;;Brandenburg
Klosterdorf;b Strausberg;15345;03341;Brandenburg
Klosterfelde;;16348;033396;Brandenburg
Klosterhäseler;;06647;034463;Sachsen-Anhalt
Klosterheide;b Lindow, Mark;16835;033933;Brandenburg
Klosterkumbd;;55469;06761;Rheinland-Pfalz
Klosterlechfeld;;86836;08232;Bayern
Klostermansfeld;;06308;034772;Sachsen-Anhalt
Klosterwalde;;17268;039885;Brandenburg
Klotten;;56818;02671;Rheinland-Pfalz
Klötze;;38486;03909;Sachsen-Anhalt
Klüden;;39638;039056;Sachsen-Anhalt
Kludenbach;;55481;06763;Rheinland-Pfalz
Kluis;;18569;03838;Mecklenburg-Vorpommern
Kluse;, Emsl;26892;04963;Niedersachsen
Klüß;;19357;038783;Mecklenburg-Vorpommern
Klüssendorf;;23972;03841;Mecklenburg-Vorpommern
Klüsserath;;54340;06507;Rheinland-Pfalz
Klütz;;23948;038825;Mecklenburg-Vorpommern
Knapendorf;;06246;034635;Sachsen-Anhalt
Knappenfeld, Gem Surberg;;83364;08662;Bayern
Knappensee-Groß Särchen;;02999;035726;Sachsen
Knappensee-Koblenz;;02999;035726;Sachsen
Knappensee-Wartha;;02999;035726;Sachsen
Knau;b Pößneck;07389;036484;Thüringen
Kneese;b Gadebusch;19205;038876;Mecklenburg-Vorpommern
Knegendorf;;18276;038455;Mecklenburg-Vorpommern
Kneispermühle;;66917;06375;Rheinland-Pfalz
Kneitlingen;;38170;05332;Niedersachsen
Knetzgau;;97478;09527;Bayern
Kniepenberg;;24398;04352;Schlewig-Holstein
Knittelsheim;;76879;06348;Rheinland-Pfalz
Knittlingen;;75438;07043;Baden-Württemberg
Knoblauch;;14715;033877;Brandenburg
Knochenmühle;, Pfalz;76855;;Rheinland-Pfalz
Knoppermühle;;66917;06375;Rheinland-Pfalz
Knopp-Labach;;66917;06375;Rheinland-Pfalz
Knöringen;, Pfalz;76833;06341;Rheinland-Pfalz
Knorrendorf;;17091;039742;Mecklenburg-Vorpommern
Knorrenhof;;19348;;Brandenburg
Knüllwald;;34593;05685;Hessen
Knyphauserwald;, Forsthaus;26409;;Niedersachsen
Kobbeln;;15890;033652;Brandenburg
Koberg;, Kr Hzgt Lauenb;23881;04543;Schlewig-Holstein
Kobern-Gondorf;;56330;02607;Rheinland-Pfalz
Koblentz;;17309;039743;Mecklenburg-Vorpommern
Koblenz;am Rhein;56068;0261;Rheinland-Pfalz
Koblenz;am Rhein;56070;0261;Rheinland-Pfalz
Koblenz;am Rhein;56072;0261;Rheinland-Pfalz
Koblenz;am Rhein;56073;0261;Rheinland-Pfalz
Koblenz;am Rhein;56075;0261;Rheinland-Pfalz
Koblenz;am Rhein;56076;0261;Rheinland-Pfalz
Koblenz;am Rhein;56077;0261;Rheinland-Pfalz
Kobrow;b Sternberg;19406;03847;Mecklenburg-Vorpommern
Kochel;a. See;82431;08851;Bayern
Köchelstorf;;19217;038872;Mecklenburg-Vorpommern
Köck;;83739;08064;Bayern
Köckte;b Gardelegen;39649;039004;Sachsen-Anhalt
Ködderitzsch;;99518;036463;Thüringen
Kodersdorf;;02923;035825;Sachsen
Köditz;, Oberfr;95189;09281;Bayern
Köditzberg;;07426;;Thüringen
Ködnitz;;95361;09221;Bayern
Köfeln;;07557;036603;Thüringen
Köfering;b Regensburg;93096;09406;Bayern
Kogel;b Röbel;17213;039924;Mecklenburg-Vorpommern
Kogel;b Zarrentin, Meckl;19246;038851;Mecklenburg-Vorpommern
Kogel;b Miesbach;83714;08025;Bayern
Kohlbacher Hof;;64395;;Hessen
Kohlberg;, Württ;72664;07025;Baden-Württemberg
Kohlberg;, Oberpf;92702;09608;Bayern
Köhlen;b Bremerhaven;27624;04708;Niedersachsen
Kohlhof;b Rathenow;14715;;Brandenburg
Kohlmühle;, Oberfr;96358;09268;Bayern
Kohlsdorf;;15848;03366;Brandenburg
Köhn;;24257;04385;Schlewig-Holstein
Köhnerbrücke;;24257;04385;Schlewig-Holstein
Kohren-Sahlis;;04655;034344;Sachsen
Koitzsch;;01936;035795;Sachsen
Kolbenmühle;;67806;06361;Rheinland-Pfalz
Kolberg;b Prieros;15752;033768;Brandenburg
Kolbermoor;;83059;08031;Bayern
Kolbermoor;;83059;08031;Bayern
Kolbingen;;78600;07463;Baden-Württemberg
Kölbingen;;56459;02663;Rheinland-Pfalz
Koldenbüttel;;25840;04881;Schlewig-Holstein
Koldenhof;;17237;039820;Mecklenburg-Vorpommern
Kolitzheim;;97509;09385;Bayern
Kolka;;04643;034341;Sachsen
Kolkerheide;;25862;04673;Schlewig-Holstein
Kolkwitz;, NL;03099;0355;Brandenburg
Kölleda;;99625;03635;Thüringen
Kölleshof;;73349;07335;Baden-Württemberg
Kollig;;56751;02654;Rheinland-Pfalz
Kollmar;;25377;04128;Schlewig-Holstein
Kollmoor;b Itzehoe;25524;04821;Schlewig-Holstein
Kollnburg;;94262;09942;Bayern
Kölln-Reisiek;;25337;04121;Schlewig-Holstein
Kollow;, Kr Hzgt Lauenb;21527;04151;Schlewig-Holstein
Kollow, Kurheim;b Schwarzenbek;21493;04151;Schlewig-Holstein
Kollweiler;;66879;06385;Rheinland-Pfalz
Kolmberg;b Wörnsmühl;83739;08025;Bayern
Köln;;50667;0221;Nordrhein-Westfalen
Köln;;50668;0221;Nordrhein-Westfalen
Köln;;50670;0221;Nordrhein-Westfalen
Köln;;50672;0221;Nordrhein-Westfalen
Köln;;50674;0221;Nordrhein-Westfalen
Köln;;50676;0221;Nordrhein-Westfalen
Köln;;50677;0221;Nordrhein-Westfalen
Köln;;50678;0221;Nordrhein-Westfalen
Köln;;50679;0221;Nordrhein-Westfalen
Köln;;50733;0221;Nordrhein-Westfalen
Köln;;50735;0221;Nordrhein-Westfalen
Köln;;50737;0221;Nordrhein-Westfalen
Köln;;50739;0221;Nordrhein-Westfalen
Köln;;50765;0221;Nordrhein-Westfalen
Köln;;50767;0221;Nordrhein-Westfalen
Köln;;50769;0221;Nordrhein-Westfalen
Köln;;50823;0221;Nordrhein-Westfalen
Köln;;50825;0221;Nordrhein-Westfalen
Köln;;50827;0221;Nordrhein-Westfalen
Köln;;50829;0221;Nordrhein-Westfalen
Köln;;50858;0221;Nordrhein-Westfalen
Köln;;50859;0221;Nordrhein-Westfalen
Köln;;50931;0221;Nordrhein-Westfalen
Köln;;50933;0221;Nordrhein-Westfalen
Köln;;50935;0221;Nordrhein-Westfalen
Köln;;50937;0221;Nordrhein-Westfalen
Köln;;50939;0221;Nordrhein-Westfalen
Köln;;50968;0221;Nordrhein-Westfalen
Köln;;50969;0221;Nordrhein-Westfalen
Köln;;50996;0221;Nordrhein-Westfalen
Köln;;50997;0221;Nordrhein-Westfalen
Köln;;50999;0221;Nordrhein-Westfalen
Köln;;51061;0221;Nordrhein-Westfalen
Köln;;51063;0221;Nordrhein-Westfalen
Köln;;51065;0221;Nordrhein-Westfalen
Köln;;51067;0221;Nordrhein-Westfalen
Köln;;51069;0221;Nordrhein-Westfalen
Köln;;51103;0221;Nordrhein-Westfalen
Köln;;51105;0221;Nordrhein-Westfalen
Köln;;51107;0221;Nordrhein-Westfalen
Köln;;51109;0221;Nordrhein-Westfalen
Köln;;51143;0221;Nordrhein-Westfalen
Köln;;51145;0221;Nordrhein-Westfalen
Köln;;51147;0221;Nordrhein-Westfalen
Köln;;51149;0221;Nordrhein-Westfalen
Köln;;51467;0221;Nordrhein-Westfalen
Kölnische Höfe;;56767;02657;Rheinland-Pfalz
Kolochau;;04936;035361;Brandenburg
Kolpin;;15518;033678;Brandenburg
Kolrep;;16866;033975;Brandenburg
Kölsa;b Falkenberg, Elster;04895;035365;Brandenburg
Koltzschen;;09326;034381;Sachsen
Kolverath;;56767;02692;Rheinland-Pfalz
Kölzin;b Greifswald;17506;038353;Mecklenburg-Vorpommern
Kölzin;b Hagenow;19246;038851;Mecklenburg-Vorpommern
Kommen;;54472;06536;Rheinland-Pfalz
Komptendorf;;03058;035605;Brandenburg
Konau;;19273;038841;Niedersachsen
Könderitz;;06712;034424;Sachsen-Anhalt
Köngen;;73257;07024;Baden-Württemberg
Köngernheim;;55278;06737;Rheinland-Pfalz
Königerode;b Quedlinburg;06493;039484;Sachsen-Anhalt
Königheim;;97953;09340;Baden-Württemberg
Königreich;, Pfalz;66851;06372;Rheinland-Pfalz
Königs Wusterhausen;;15711;03375;Brandenburg
Königsau;b Kirn, Nahe;55606;06765;Rheinland-Pfalz
Königsbach-Stein;;75203;07232;Baden-Württemberg
Königsberg;b Wittstock, Dosse;16909;033965;Brandenburg
Königsberg;i Bay;97486;09525;Bayern
Königsborn;b Magdeburg;39175;039292;Sachsen-Anhalt
Königsbronn;, Württ;89551;07328;Baden-Württemberg
Königsbrück;;01936;035795;Sachsen
Königsbrunn;b Augsburg;86343;08231;Bayern
Königsdorf;, Oberbay;82549;08179;Bayern
Königsee;;07426;036738;Thüringen
Königseggwald;;88376;07587;Baden-Württemberg
Königsfeld;b Rochlitz;09306;03737;Sachsen
Königsfeld;, Eifel;53426;02646;Rheinland-Pfalz
Königsfeld;im Schwarzwald;78126;07725;Baden-Württemberg
Königsfeld;, Oberfr;96167;09207;Bayern
Königshain;b Görlitz, Neiße;02829;035826;Sachsen
Königshain-Wiederau;;09306;037202;Sachsen
Königsheim;;78598;07429;Baden-Württemberg
Königshof;;16269;;Brandenburg
Königshorst;b Fehrbellin;16833;033922;Brandenburg
Königshügel;;24799;04339;Schlewig-Holstein
Königshütte;b Rathenow;14715;;Brandenburg
Königshütte;(Harz);38875;039454;Sachsen-Anhalt
Königslutter;am Elm;38154;05353;Niedersachsen
Königsmark;, Altmark;39606;03937;Sachsen-Anhalt
Königsmoor;, Nordheide;21255;04180;Niedersachsen
Königsmoos;;86669;08433;Bayern
Königstein;/Sächs. Schw.;01824;035021;Sachsen
Königstein;im Taunus;61462;06174;Hessen
Königstein;, Oberpf;92281;09665;Bayern
Königswalde;b Werdau, Sachs;08412;03761;Sachsen
Königswalde;, Erzgeb;09471;037343;Sachsen
Königswartha;;02699;035931;Sachsen
Königswill;;24837;;Schlewig-Holstein
Königswinter;;53639;02223;Nordrhein-Westfalen
Könitz;;07336;036732;Thüringen
Konken;;66871;06384;Rheinland-Pfalz
Könkendorf;;16928;033989;Brandenburg
Könnern;;06420;034691;Sachsen-Anhalt
Konnersreuth;, Oberpf;95692;09632;Bayern
Könnigde;;39629;039089;Sachsen-Anhalt
Konow;;18239;038207;Mecklenburg-Vorpommern
Konradsmühle;;66917;06375;Rheinland-Pfalz
Konradsreuth;, Oberfr;95176;09292;Bayern
Konstanz;;78462;07531;Baden-Württemberg
Konstanz;;78464;07531;Baden-Württemberg
Konstanz;;78465;07531;Baden-Württemberg
Konstanz;;78467;07531;Baden-Württemberg
Konz;;54329;06501;Rheinland-Pfalz
Konzell;;94357;09963;Bayern
Köpernitz;b Brandenburg an der Havel;14793;033830;Brandenburg
Köpfelsberg;b Waging a See;83329;08681;Bayern
Kopitzsch;;07819;036482;Thüringen
Kopp;;54574;06594;Rheinland-Pfalz
Koppatz;;03058;035605;Brandenburg
Köppelmühle;;85570;08121;Bayern
Koppenbrück;;16845;03391;Brandenburg
Koppenkreut;;73577;07176;Baden-Württemberg
Korb;, Remstal;71404;07151;Baden-Württemberg
Körba;;04936;035364;Brandenburg
Korbach;;34497;05631;Hessen
Körbelitz;;39175;039222;Sachsen-Anhalt
Korbetha;;06258;0345;Sachsen-Anhalt
Körbitz;;14913;033746;Brandenburg
Körborn;;66871;06381;Rheinland-Pfalz
Korbußen;;07554;036602;Thüringen
Körchow;b Bad Doberan;18230;038294;Mecklenburg-Vorpommern
Körchow;b Hagenow;19243;038852;Mecklenburg-Vorpommern
Kordel;;54306;06505;Rheinland-Pfalz
Kördorf;;56370;06486;Rheinland-Pfalz
Korgau;;06905;034925;Sachsen-Anhalt
Körle;;34327;05665;Hessen
Korleput;;18299;038459;Mecklenburg-Vorpommern
Korlingen;;54317;06588;Rheinland-Pfalz
Kornbach;b Mehltheuer , Vogtl;08539;036645;Sachsen
Körner;;99998;036025;Thüringen
Korntal-Münchingen;;70825;07150;Baden-Württemberg
Kornwestheim;;70806;07154;Baden-Württemberg
Körperich;, Eifel;54675;06566;Rheinland-Pfalz
Korschenbroich;;41352;02161;Nordrhein-Westfalen
Korswandt;;17419;038378;Mecklenburg-Vorpommern
Korweiler;;56288;06762;Rheinland-Pfalz
Körzin;;14547;033204;Brandenburg
Kösching;;85092;08456;Bayern
Köschinger Waldhaus;;85092;08456;Bayern
Kosel;b Niesky;02923;035894;Sachsen
Kosel;b Eckernförde;24354;04354;Schlewig-Holstein
Köselitz;;06869;034923;Sachsen-Anhalt
Koserow;;17459;038375;Mecklenburg-Vorpommern
Köshof;;73642;07182;Baden-Württemberg
Kosilenzien;;04931;035341;Brandenburg
Kösnitz;;99510;036464;Thüringen
Kospoda;;07806;036481;Thüringen
Kossa;;04849;034243;Sachsen
Kossau;b Bad Blankenburg;07422;;Thüringen
Koßdorf;;04895;035365;Brandenburg
Kossebau;;39606;039391;Sachsen-Anhalt
Kossenblatt;;15848;033674;Brandenburg
Kossin;;14913;033744;Brandenburg
Kößlarn;;94149;08536;Bayern
Koßwig;;03226;035433;Brandenburg
Kostebrau;;01979;03574;Brandenburg
Kotelow;;17099;039607;Mecklenburg-Vorpommern
Köthel, Kr Hzgt Lauenburg;;22929;04159;Schlewig-Holstein
Köthel, Kr Stormarn;;22929;04159;Schlewig-Holstein
Köthen;(Anhalt);06366;03496;Sachsen-Anhalt
Kötschau;;99441;036425;Thüringen
Kötschlitz;;06254;034638;Sachsen-Anhalt
Kottenborn;;53518;02691;Rheinland-Pfalz
Kottenbornermühle;;53518;;Rheinland-Pfalz
Kottenheim;;56736;02651;Rheinland-Pfalz
Kötterichen;;56767;02657;Rheinland-Pfalz
Kottgeisering;;82288;08144;Bayern
Kottweiler-Schwanden;;66879;06371;Rheinland-Pfalz
Köttwitzsch;;09306;03737;Sachsen
Kötz;;89359;08221;Bayern
Kotzen;;14715;033874;Brandenburg
Kotzenbüll;;25832;04861;Schlewig-Holstein
Kötzlin;;16866;033972;Brandenburg
Kötzschau;b Merseburg;06231;03461;Sachsen-Anhalt
Kötzting;;93444;09941;Bayern
Köwerich;;54340;06507;Rheinland-Pfalz
Koxhausen;;54673;06564;Rheinland-Pfalz
Kraak;;19077;03868;Mecklenburg-Vorpommern
Kraam;;57635;02686;Rheinland-Pfalz
Kraatz;b Prenzlau;17291;039859;Brandenburg
Kraatz-Buberow;;16775;03306;Brandenburg
Krachtsheide;;15864;033672;Brandenburg
Krackow;;17329;039746;Mecklenburg-Vorpommern
Kradenbach;;54552;06592;Rheinland-Pfalz
Kraftisried;;87647;08377;Bayern
Kraftsdorf;;07586;036606;Thüringen
Kragelund;, Gem Stoltebüll;24407;;Schlewig-Holstein
Krähenberg;, Pfalz;66894;06337;Rheinland-Pfalz
Krahne;;14778;033835;Brandenburg
Krahnepuhl;;14798;03381;Brandenburg
Kraiburg;a. Inn;84559;08638;Bayern
Kraichtal;;76703;07250;Baden-Württemberg
Krailling;;82152;089;Bayern
Krainwinkl;;83364;08666;Bayern
Kraja;;99752;036338;Thüringen
Krakow;a See;18292;038458;Mecklenburg-Vorpommern
Krambergsmühle;;57644;;Rheinland-Pfalz
Kramerhof;b Stralsund;18445;038323;Mecklenburg-Vorpommern
Krampfer;;19348;03876;Brandenburg
Krampnitz;;14476;0331;Brandenburg
Kranenburg;, Niederelbe;21726;04140;Niedersachsen
Kranenburg;, Niederrhein;47559;02826;Nordrhein-Westfalen
Kranepuhl;;14806;033841;Brandenburg
Krangen;;16827;03391;Brandenburg
Kranichborn;;99195;036371;Thüringen
Kranichfeld;;99448;036450;Thüringen
Krankenthal;;67466;;Rheinland-Pfalz
Kranlucken;;36419;036967;Thüringen
Kranzbach;;82493;08823;Bayern
Kranzberg;, Kr Freising;85402;08166;Bayern
Kränzlin;;16818;03391;Brandenburg
Krassow;;23992;03841;Mecklenburg-Vorpommern
Krastel;;56288;06762;Rheinland-Pfalz
Kratzeburg;;17237;039822;Mecklenburg-Vorpommern
Kratzenburg;;56283;06747;Rheinland-Pfalz
Kratzerhöfle;;73072;;Baden-Württemberg
Kratzerhöfle;;73072;;Baden-Württemberg
Krauchenwies;;72505;07576;Baden-Württemberg
Kraupa;;04910;03533;Brandenburg
Krauschütz;;01561;03522;Sachsen
Krauschwitz;, OL;02957;035771;Sachsen
Krauschwitz;b Weißenfels;06682;034443;Sachsen-Anhalt
Krausnick;;15910;035472;Brandenburg
Kraußnitz;;01561;035755;Sachsen
Krautenberg;b Markt Rettenbach;87724;08332;Bayern
Krauthausen;b Eisenach, Thür;99819;03691;Thüringen
Krautheim;, Jagst;74238;06294;Baden-Württemberg
Krautheim;b Weimar, Thür;99439;036451;Thüringen
Krautscheid;, Eifel;54673;06554;Rheinland-Pfalz
Kreba-Neudorf;;02906;035893;Sachsen
Krebeck;;37434;05507;Niedersachsen
Kreblitz;;15926;03544;Brandenburg
Krebsbach Talsperre;;07973;;Thüringen
Krefeld;;47798;02151;Nordrhein-Westfalen
Krefeld;;47799;02151;Nordrhein-Westfalen
Krefeld;;47800;02151;Nordrhein-Westfalen
Krefeld;;47802;02151;Nordrhein-Westfalen
Krefeld;;47803;02151;Nordrhein-Westfalen
Krefeld;;47804;02151;Nordrhein-Westfalen
Krefeld;;47805;02151;Nordrhein-Westfalen
Krefeld;;47807;02151;Nordrhein-Westfalen
Krefeld;;47809;02151;Nordrhein-Westfalen
Krefeld;;47829;02151;Nordrhein-Westfalen
Krefeld;;47839;02151;Nordrhein-Westfalen
Kreien;;19386;038733;Mecklenburg-Vorpommern
Kreiensen;;37547;05563;Niedersachsen
Kreimbach-Kaulbach;;67757;06308;Rheinland-Pfalz
Kreischa;b Dresden;01731;035206;Sachsen
Kreißa;;01623;035246;Sachsen
Krelau;;25878;;Schlewig-Holstein
Krelinger Bruch;;29693;;Niedersachsen
Krembz;;19205;03886;Mecklenburg-Vorpommern
Kremkau;;39624;039080;Sachsen-Anhalt
Kremmen;;16766;033055;Brandenburg
Kremmin;;19300;038756;Mecklenburg-Vorpommern
Krempdorf;;25376;04824;Schlewig-Holstein
Krempe;, Holst;25361;04824;Schlewig-Holstein
Krempel;, Holst;25774;04882;Schlewig-Holstein
Krempendorf;;16945;033968;Brandenburg
Kremperheide;;25569;04821;Schlewig-Holstein
Krempermoor;;25569;04824;Schlewig-Holstein
Krempin;;18233;038294;Mecklenburg-Vorpommern
Krems II;;23827;04557;Schlewig-Holstein
Krenzliner Hütte;;19288;038751;Mecklenburg-Vorpommern
Kreßberg;;74594;07957;Baden-Württemberg
Kressbronn;am Bodensee;88079;07543;Baden-Württemberg
Krettenhof;;73116;07161;Baden-Württemberg
Krettenhof;;73116;07161;Baden-Württemberg
Kretz;;56630;02632;Rheinland-Pfalz
Kretzhaus;, Gem Linz a Rhein;53560;02645;Rheinland-Pfalz
Kretzschau;;06712;03441;Sachsen-Anhalt
Kreut;, Gem Schlehdorf;82439;08841;Bayern
Kreuth;b Tegernsee;83708;08029;Bayern
Kreuzau;;52372;02422;Nordrhein-Westfalen
Kreuzbruch;;16559;033054;Brandenburg
Kreuzburg;;19348;038789;Brandenburg
Kreuzebra;;37351;036075;Thüringen
Kreuzhof;b Rockenhausen;67806;06363;Rheinland-Pfalz
Kreuzpullach;;82041;;Bayern
Kreuzquelle;;61209;;Hessen
Kreuztal;, Westf;57223;02732;Nordrhein-Westfalen
Kreuzwertheim;;97892;09342;Bayern
Krevese;;39606;03937;Sachsen-Anhalt
Krewelin;;16775;03307;Brandenburg
Kreypau;;06231;03462;Sachsen-Anhalt
Kribbe;;19357;038783;Brandenburg
Krickenbach;;67706;06307;Rheinland-Pfalz
Kriebitzsch;;04617;034498;Thüringen
Kriebstein;;09648;034327;Sachsen
Kriegsfeld;;67819;06358;Rheinland-Pfalz
Kriele;;14715;033874;Brandenburg
Krielow;;14550;033207;Brandenburg
Krien;;17391;039723;Mecklenburg-Vorpommern
Kriesow;;17091;039600;Mecklenburg-Vorpommern
Kriftel;;65830;06192;Hessen
Krina;;06774;034955;Sachsen-Anhalt
Krinitz;b Ludwigslust;19294;038755;Mecklenburg-Vorpommern
Krippendorf;;07751;036425;Thüringen
Krippendorf;;07751;036425;Thüringen
Kritzenast;;93449;09978;Bayern
Kritzmow;;18198;038207;Mecklenburg-Vorpommern
Kritzow;b Schwerin, Meckl;19065;03866;Mecklenburg-Vorpommern
Kritzow;b Lübz;19386;038733;Mecklenburg-Vorpommern
Kritzow;b Wismar, Meckl;23970;03841;Mecklenburg-Vorpommern
Kröbeln;;04924;035341;Brandenburg
Krogaspe;;24644;04392;Schlewig-Holstein
Krohnhorst;;17268;039886;Brandenburg
Krokau;;24217;04344;Schlewig-Holstein
Krölpa;b Pößneck;07387;036626;Thüringen
Krombach;b Heilbad Heiligenstadt;37308;036082;Thüringen
Krombach;, Unterfr;63829;06024;Bayern
Kromsdorf;;99441;03643;Thüringen
Kronach;, Oberfr;96317;09261;Bayern
Kronau;, Baden;76709;07253;Baden-Württemberg
Kronberg;im Taunus;61476;06173;Hessen
Kronberg;, Gem Gars a Inn;83546;08073;Bayern
Kronburg;;87758;08394;Bayern
Kröning;;84178;08702;Bayern
Kronprinzenkoog;;25709;04856;Schlewig-Holstein
Kronsgaard;;24395;04643;Schlewig-Holstein
Kronshagen;;24119;0431;Schlewig-Holstein
Kronsmoor;b Westermoor, Holst;25597;04828;Schlewig-Holstein
Kronweiler;;55767;06787;Rheinland-Pfalz
Kröpelin;;18236;038292;Mecklenburg-Vorpommern
Kropp;b Schleswig;24848;04624;Schlewig-Holstein
Kroppach;, Westerw;57612;02688;Rheinland-Pfalz
Kröppelshagen-Fahrendorf;;21529;04104;Schlewig-Holstein
Kroppen;;01945;035755;Brandenburg
Kröppen;, Pfalz;66957;06335;Rheinland-Pfalz
Kroppenstedt;;39397;039264;Sachsen-Anhalt
Kropstädt;;06895;034920;Sachsen-Anhalt
Krosigk;;06193;034603;Sachsen-Anhalt
Kröslin;;17440;038370;Mecklenburg-Vorpommern
Krostitz;;04509;0341;Sachsen
Krottelbach;;66909;06386;Rheinland-Pfalz
Krottorf;b Oschersleben;39387;039424;Sachsen-Anhalt
Kröv;;54536;06541;Rheinland-Pfalz
Kruchten;;54675;06566;Rheinland-Pfalz
Krücklham;;83559;08073;Bayern
Kruckow;, Vorpommern;17129;039999;Mecklenburg-Vorpommern
Krüden;;39615;039386;Sachsen-Anhalt
Kruft;;56642;02652;Rheinland-Pfalz
Krugau;;15913;035471;Brandenburg
Kruge;;16259;033451;Brandenburg
Krügersdorf;;15848;03366;Brandenburg
Krugland;;18246;038466;Mecklenburg-Vorpommern
Krugsdorf;;17309;039743;Mecklenburg-Vorpommern
Krukow;, Meckl;17217;03962;Mecklenburg-Vorpommern
Krukow;, Kr Hzgt Lauenb;21483;04153;Schlewig-Holstein
Krumbach;(Schwaben);86381;08282;Bayern
Krumhermersdorf;;09434;03735;Sachsen
Krummbek;, Holst;24217;04344;Schlewig-Holstein
Krümmel;, Westerw;56244;02626;Rheinland-Pfalz
Krummenau;, Hunsrück;55483;06543;Rheinland-Pfalz
Krummendeich;;21732;04753;Niedersachsen
Krummendiek;;25554;04823;Schlewig-Holstein
Krummennaab;;92703;09682;Bayern
Krummensee;b Königs Wusterhausen;15711;03375;Brandenburg
Krummensee;b Werneuchen;16356;033438;Brandenburg
Krummesse;;23628;04508;Schlewig-Holstein
Krummesse;;23628;04508;Schlewig-Holstein
Krummhörn;;26736;04923;Niedersachsen
Krummin;;17440;03836;Mecklenburg-Vorpommern
Krummland;b Eckernförde;24357;;Schlewig-Holstein
Krummwisch;;24796;04351;Schlewig-Holstein
Krumpa;;06242;034633;Sachsen-Anhalt
Krumscheid;b Linz a Rhein;53547;02638;Rheinland-Pfalz
Krumstedt;;25727;04830;Schlewig-Holstein
Krün;;82494;08825;Bayern
Krunkel;;56593;02687;Rheinland-Pfalz
Krusendorf;b Neuhaus, Elbe;19273;038841;Niedersachsen
Krusenfelde;;17391;039723;Mecklenburg-Vorpommern
Krusenhagen;;23974;03841;Mecklenburg-Vorpommern
Krüssau;;39291;039223;Sachsen-Anhalt
Krüzen;;21483;04153;Schlewig-Holstein
Kublank;;17349;03968;Mecklenburg-Vorpommern
Kubschütz;;02627;03591;Sachsen
Kuchelmiß;;18292;038457;Mecklenburg-Vorpommern
Kuchen;, Fils;73329;07331;Baden-Württemberg
Kuckeland;;04668;034386;Sachsen
Kuckenburg;b Querfurt;06268;034771;Sachsen-Anhalt
Kückenshagen;;18317;038223;Mecklenburg-Vorpommern
Kuckuck;b Pritzwalk;16928;033989;Brandenburg
Kuddewörde;;22958;04154;Schlewig-Holstein
Kuden;;25712;04855;Schlewig-Holstein
Kudensee;;25572;04858;Schlewig-Holstein
Küdow;;16845;033928;Brandenburg
Kuhardt;;76773;07272;Rheinland-Pfalz
Kühbach;, Schwab;86556;08251;Bayern
Kuhberg;b Wittenberge;19322;;Brandenburg
Kuhbier;;16928;033983;Brandenburg
Kuhblank;;19322;03877;Brandenburg
Kühdorf;;07980;036625;Thüringen
Kuhfelde;;29416;039035;Sachsen-Anhalt
Kuhhorst;;16818;033922;Brandenburg
Kuhlen;b Brüel;19412;038486;Mecklenburg-Vorpommern
Kuhlenbusch;;18317;;Mecklenburg-Vorpommern
Kuhlenfeld;;19258;038844;Mecklenburg-Vorpommern
Kühlenstein;;23948;038825;Mecklenburg-Vorpommern
Kühlenthal;;86707;08273;Bayern
Kühler Morgen;b Lobenstein;07356;;Thüringen
Kuhlhausen;;39524;039382;Sachsen-Anhalt
Kuhlhorst;;49459;05447;Niedersachsen
Kuhlowitz;;14806;033841;Brandenburg
Kuhlrade;b Gadebusch;19217;038873;Mecklenburg-Vorpommern
Kühlungsborn;, Ostseebad;18225;038293;Mecklenburg-Vorpommern
Kühndorf;b Suhl;98547;036844;Thüringen
Kühnhaide;b Marienberg;09496;037364;Sachsen
Kühnhausen;, Thür;99189;036201;Thüringen
Kühnhausen;, Thür;99189;036201;Thüringen
Kuhnhöfen;;56244;02666;Rheinland-Pfalz
Kühnitzsch;;04808;034262;Sachsen
Kühren;b Preetz, Holst;24211;04342;Schlewig-Holstein
Kühren-Burkartshain;;04808;034261;Sachsen
Kührstedt;;27624;04708;Niedersachsen
Kuhs;;18276;038454;Mecklenburg-Vorpommern
Kuhsdorf;;16928;03395;Brandenburg
Kühsen;;23898;04543;Schlewig-Holstein
Kuhstorf;;19230;03883;Mecklenburg-Vorpommern
Kuhwalk;;19386;038733;Mecklenburg-Vorpommern
Kükels;;23829;04552;Schlewig-Holstein
Kulkwitz;;04420;0341;Sachsen
Kulkwitz;;04420;0341;Sachsen
Küllstedt;;37359;036075;Thüringen
Kulmain;;95508;09642;Bayern
Kulmbach;;95326;09221;Bayern
Kulpin;b Ratzeburg;23911;04541;Schlewig-Holstein
Külsheim;, Baden;97900;09345;Baden-Württemberg
Külz;(Hunsrück);55471;06761;Rheinland-Pfalz
Kümbdchen;;55471;06761;Rheinland-Pfalz
Kumhausen;;84036;0871;Bayern
Kummer;b Ludwigslust;19288;038751;Mecklenburg-Vorpommern
Kummerfeld;b Pinneberg;25495;04101;Schlewig-Holstein
Kümmernitz;;39539;039387;Sachsen-Anhalt
Kummerow;b Angermünde;16306;033336;Brandenburg
Kummerow;b Malchin;17139;039952;Mecklenburg-Vorpommern
Kummerow;b Stralsund;18442;038321;Mecklenburg-Vorpommern
Kümmersbruck;;92245;09621;Bayern
Kummersdorf;b Storkow, Mark;15859;033678;Brandenburg
Kummersdorf Gut;;15838;033703;Brandenburg
Kummersdorf-Alexanderdorf;;15806;033703;Brandenburg
Kümmritz;;15938;035453;Brandenburg
Kumpfmühle;b Unterschweinbach;82282;08145;Bayern
Kundert;;57629;02662;Rheinland-Pfalz
Kunersdorf;b Beeskow;15848;033672;Brandenburg
Kunitz;;07751;03641;Thüringen
Kunitz;;07751;03641;Thüringen
Kunow;b Angermünde;16306;033331;Brandenburg
Kunow;b Neustadt, Dosse;16866;033977;Brandenburg
Kunrau;;38486;039008;Sachsen-Anhalt
Kunreuth;b Forchheim, Oberfr;91358;09199;Bayern
Künzell;;36093;0661;Hessen
Künzelsau;;74653;07940;Baden-Württemberg
Künzing;;94550;08549;Bayern
Kupferberg;, Oberfr;95362;09227;Bayern
Kupferzell;;74635;07944;Baden-Württemberg
Kuppenheim;;76456;07222;Baden-Württemberg
Kuppentin;;19386;038732;Mecklenburg-Vorpommern
Küps;;96328;09264;Bayern
Kürnach;;97273;09367;Bayern
Kürnbach;, Baden;75057;07258;Baden-Württemberg
Kurort Berggießhübel;;01819;035023;Sachsen
Kurort Hartha;;01737;035203;Sachsen
Kurort Jonsdorf;;02796;035844;Sachsen
Kurort Oberwiesenthal;;09484;037347;Sachsen
Kurort Oybin;;02797;035844;Sachsen
Kurort Rathen;, Sachs;01824;035024;Sachsen
Kurort Seiffen;, Erzgeb;09548;037361;Sachsen
Kurort Volkersdorf;;01468;035207;Sachsen
Kürten;;51515;02268;Nordrhein-Westfalen
Kurtscheid;;56581;02634;Rheinland-Pfalz
Kurtschlag;;16792;039883;Brandenburg
Kurzen Trechow;;18246;038461;Mecklenburg-Vorpommern
Kurzlipsdorf;;14913;033743;Brandenburg
Kuschkow;;15910;035476;Brandenburg
Kusel;;66869;06381;Rheinland-Pfalz
Küsel;;39291;039223;Sachsen-Anhalt
Kusey;;38486;039005;Sachsen-Anhalt
Küssaberg;;79790;07741;Baden-Württemberg
Kussow;b Grevesmühlen;23948;03881;Mecklenburg-Vorpommern
Küsten;;29482;05841;Niedersachsen
Kusterdingen;;72127;07071;Baden-Württemberg
Küstrin-Kietz;;15328;033479;Brandenburg
Kutenholz;;27449;04762;Niedersachsen
Kütten;;06193;034600;Sachsen-Anhalt
Kutzenhausen;, Kr Augsburg;86500;08238;Bayern
Kützin;;19243;038852;Mecklenburg-Vorpommern
Kützkow;;14798;033877;Brandenburg
Kutzleben;;99955;036041;Thüringen
Kyllburg;;54655;06563;Rheinland-Pfalz
Kyllburgweiler;;54655;06563;Rheinland-Pfalz
Kyritz;, Prignitz;16866;033971;Brandenburg
Laaber;, Oberpf;93164;09498;Bayern
Laage;;18299;038459;Mecklenburg-Vorpommern
Laake;b Neuhaus, Elbe;19273;038845;Niedersachsen
Laar;, Vechte;49824;05947;Niedersachsen
Laasdorf;;07646;036428;Thüringen
Laase;b Bützow;18249;038462;Mecklenburg-Vorpommern
Laasen;b Saalfeld, Saale;07330;036731;Thüringen
Laaslich;;19348;038793;Brandenburg
Laasow;b Calau;03205;035436;Brandenburg
Laasow;, Spreewald;15913;035475;Brandenburg
Laatzen;b Hannover;30880;05102;Niedersachsen
Laave;;19273;038845;Niedersachsen
Labenz;;23898;04536;Schlewig-Holstein
Laberweinting;;84082;08772;Bayern
Laboe;;24235;04343;Schlewig-Holstein
Labrun;;06922;035386;Sachsen-Anhalt
Lachen;b Memmingen;87760;08332;Bayern
Lachendorf;, Kr Celle;29331;05145;Niedersachsen
Lachnitzmühle;;56170;02601;Rheinland-Pfalz
Lachstedt;;99518;036461;Thüringen
Lacken;b Evenhausen, Oberbay;83552;08075;Bayern
Ladbergen;;49549;05485;Nordrhein-Westfalen
Ladeburg;b Bernau b Berlin;16321;03338;Brandenburg
Ladeburg;b Zerbst;39279;039241;Sachsen-Anhalt
Ladelund;;25926;04666;Schlewig-Holstein
Ladenburg;;68526;06203;Baden-Württemberg
Ladenthin;;17329;039749;Mecklenburg-Vorpommern
Laer;, Kr Steinfurt;48366;02554;Nordrhein-Westfalen
Lage;, Lippe;32791;05232;Nordrhein-Westfalen
Lage;, Dinkel;49828;05941;Niedersachsen
Lagendorf;;29413;039039;Sachsen-Anhalt
Lägerdorf;;25566;04828;Schlewig-Holstein
Lähden;;49774;05964;Niedersachsen
Lahn;, Hümmling;49757;05951;Niedersachsen
Lahnau;;35633;06441;Hessen
Lahnstein;;56112;02621;Rheinland-Pfalz
Lahntal;;35094;06420;Hessen
Lahr;, Eifel;54675;06566;Rheinland-Pfalz
Lahr;, Hunsrück;56288;02672;Rheinland-Pfalz
Lahr;, Schwarzwald;77933;07821;Baden-Württemberg
Lahstedt;;31246;05174;Niedersachsen
Laichingen;;89150;07333;Baden-Württemberg
Lalendorf;;18279;038452;Mecklenburg-Vorpommern
Lalling;, Niederbay;94551;09904;Bayern
Lam;, Oberpf;93462;09943;Bayern
Lambertsberg;;54649;06554;Rheinland-Pfalz
Lambertskreuz;;67468;;Rheinland-Pfalz
Lambrecht;(Pfalz);67466;06325;Rheinland-Pfalz
Lambrechtshagen;;18069;0381;Mecklenburg-Vorpommern
Lambsborn;;66894;06372;Rheinland-Pfalz
Lambsheim;;67245;06233;Rheinland-Pfalz
Lamerdingen;;86862;08248;Bayern
Lammershagen;, Holst;24238;04384;Schlewig-Holstein
Lampaden;;54316;06588;Rheinland-Pfalz
Lampertheim;, Hess;68623;06206;Hessen
Lampertswalde;b Großenhain, Sachs;01561;035248;Sachsen
Lamsfeld-Groß Liebitz;;15913;033671;Brandenburg
Lamspringe;;31195;05183;Niedersachsen
Lamstedt;;21769;04773;Niedersachsen
Lancken;b Parchim;19372;038731;Mecklenburg-Vorpommern
Lancken-Granitz;;18586;038393;Mecklenburg-Vorpommern
Landau;in der Pfalz;76829;06341;Rheinland-Pfalz
Landau;a d Isar;94405;09951;Bayern
Landeck;, Gem Teningen;79312;07641;Baden-Württemberg
Landensberg;;89361;08222;Bayern
Landesbergen;;31628;05025;Niedersachsen
Landgemeinde;;06528;;Sachsen-Anhalt
Landin;b Rathenow;14715;033874;Brandenburg
Landin;b Angermünde;16278;033335;Brandenburg
Landkern;;56814;02653;Rheinland-Pfalz
Landkirchen;auf Fehmarn;23769;04371;Schlewig-Holstein
Ländlerhof;;67706;06307;Rheinland-Pfalz
Landolfshausen;;37136;05507;Niedersachsen
Landrecht;;25554;04823;Schlewig-Holstein
Landsberg;b Halle, Saale;06188;034602;Sachsen-Anhalt
Landsberg;a. Lech;86899;08191;Bayern
Landsberied;;82290;089;Bayern
Landscheid;, Eifel;54526;06575;Rheinland-Pfalz
Landscheide;b Sankt Margarethen;25572;04858;Schlewig-Holstein
Landsendorf;;07338;036734;Thüringen
Landshut;, Isar;84028;0871;Bayern
Landshut;, Isar;84030;0871;Bayern
Landshut;, Isar;84032;0871;Bayern
Landshut;, Isar;84034;0871;Bayern
Landshut;, Isar;84036;0871;Bayern
Landstuhl;;66849;06371;Rheinland-Pfalz
Landturm;b Wolpertshausen;74532;07904;Baden-Württemberg
Landwehr;b Alfeld, Leine;31087;05181;Niedersachsen
Landwüst;;08258;037422;Sachsen
Langballig;;24977;04636;Schlewig-Holstein
Langdorf;b Regen;94264;09921;Bayern
Langebrück;;01465;035201;Sachsen
Langebrück;;01465;035201;Sachsen
Langeln;, Kr Pinneberg;25485;04123;Schlewig-Holstein
Langeln;, Harz;38871;039458;Sachsen-Anhalt
Langelsheim;;38685;05326;Niedersachsen
Langen;b Neuruppin;16818;033932;Brandenburg
Langen;b Bremerhaven;27607;04743;Niedersachsen
Langen;, Emsl;49838;05904;Niedersachsen
Langen;(Hessen);63225;06103;Hessen
Langen Brütz;;19067;03866;Mecklenburg-Vorpommern
Langen Jarchow;;19412;038483;Mecklenburg-Vorpommern
Langen Trechow;;18246;038461;Mecklenburg-Vorpommern
Langenaltheim;;91799;09145;Bayern
Langenapel;;29413;039038;Sachsen-Anhalt
Langenargen;;88085;07543;Baden-Württemberg
Langenau;b Brand-Erbisdorf;09636;037322;Sachsen
Langenau;, Württ;89129;07345;Baden-Württemberg
Langenbach;b Schleiz;07919;036645;Sachsen
Langenbach;bei Kirburg;57520;02661;Rheinland-Pfalz
Langenbach;b Wissen, Sieg;57537;02742;Rheinland-Pfalz
Langenbach;, Pfalz;66909;06384;Rheinland-Pfalz
Langenbach;, Kr Freising;85416;08761;Bayern
Langenbach;b Hildburghausen;98667;036874;Thüringen
Langenberg;, Kr Gütersloh;33449;05248;Nordrhein-Westfalen
Langenberg;, Pfalz;76744;07275;Rheinland-Pfalz
Langenbernsdorf;;08428;03761;Sachsen
Langenbogen;;06179;034601;Sachsen-Anhalt
Langenbrettach;;74243;07131;Baden-Württemberg
Langenburg;, Württ;74595;07905;Baden-Württemberg
Langendorf;b Weißenfels;06667;034424;Sachsen-Anhalt
Langendorf;b Zeitz, Elster;06729;03443;Sachsen-Anhalt
Langendorf;, Elbe;29484;05865;Niedersachsen
Langeneichstädt;;06268;034636;Sachsen-Anhalt
Langenenslingen;;88515;07376;Baden-Württemberg
Langeneß;;25863;04684;Schlewig-Holstein
Langenfeld;(Rheinland);40764;02173;Nordrhein-Westfalen
Langenfeld;, Eifel;56729;02655;Rheinland-Pfalz
Langenfeld;, Mittelfr;91474;09164;Bayern
Langengrassau;;15926;035454;Brandenburg
Langenhagen;b Lübz;19399;038736;Mecklenburg-Vorpommern
Langenhagen;, Han;30851;0511;Niedersachsen
Langenhagen;, Han;30853;0511;Niedersachsen
Langenhagen;, Han;30855;0511;Niedersachsen
Langenhahn;, Westerw;56459;02663;Rheinland-Pfalz
Langenhain;b Gotha, Thür;99880;03622;Thüringen
Langenhalde;;73453;07366;Baden-Württemberg
Langenhalde;;73453;07366;Baden-Württemberg
Langenhanshagen;;18320;038225;Mecklenburg-Vorpommern
Langenhanshagen Heide;;18320;038225;Mecklenburg-Vorpommern
Langenheide;b Neuhaus, Elbe;19273;038855;Mecklenburg-Vorpommern
Langenhennersdorf;;01819;035032;Sachsen
Langenhessen;;08439;03761;Sachsen
Langenhorn;b Husum, Nordsee;25842;04672;Schlewig-Holstein
Langenhorst;b Gettorf;24214;04346;Schlewig-Holstein
Langenlehsten;;21514;04155;Schlewig-Holstein
Langenleuba-Niederhain;;04618;034497;Thüringen
Langenlipsdorf;;14913;033742;Brandenburg
Langenlonsheim;;55450;06704;Rheinland-Pfalz
Langenmosen;;86571;08433;Bayern
Langennaundorf;;04938;035365;Brandenburg
Langenneufnach;;86863;08239;Bayern
Langenorla;;07381;03647;Thüringen
Langenpreising;;85465;08762;Bayern
Langenreichenbach;;04889;034221;Sachsen
Langenrieth;;04931;035342;Brandenburg
Langenroda;;06571;034672;Thüringen
Langensalzwedel;;39590;039322;Sachsen-Anhalt
Langenscheid;, Rhein-Lahn-Kr;65558;06439;Rheinland-Pfalz
Langenselbold;;63505;06184;Hessen
Langensendelbach;;91094;09133;Bayern
Langenstein;, Harz;38895;03941;Sachsen-Anhalt
Langensteinbach;, Sachs;04657;037381;Sachsen
Langenstück;;23992;038429;Mecklenburg-Vorpommern
Langenthal;, Hunsrück;55569;06754;Rheinland-Pfalz
Langenweddingen;;39171;039205;Sachsen-Anhalt
Langenweißbach;;08134;037603;Sachsen
Langenwetzendorf;;07957;036625;Thüringen
Langenwolschendorf;;07937;036628;Thüringen
Langenzenn;;90579;09101;Bayern
Langeoog;;26465;04972;Niedersachsen
Langerringen;;86853;08232;Bayern
Langerwehe;;52379;02423;Nordrhein-Westfalen
Langerwisch;;14557;033205;Brandenburg
Langerwisch Süd;;14552;;Brandenburg
Langethalsmühle;;07616;;Thüringen
Langewahl;;15518;03361;Brandenburg
Langewiesen;;98704;036785;Thüringen
Langfurth;, Mittelfr;91731;09856;Bayern
Langgöns;;35428;06403;Hessen
Langhagen;b Güstrow;18279;038456;Mecklenburg-Vorpommern
Langhardt;;56746;;Rheinland-Pfalz
Langheckerhof;b Rockenhausen;67808;06302;Rheinland-Pfalz
Langhennersdorf;;09603;037328;Sachsen
Langlingen;b Celle;29364;05082;Niedersachsen
Langnow;;16928;033984;Brandenburg
Langquaid;;84085;09452;Bayern
Langscheid;b Mayen;56729;02655;Rheinland-Pfalz
Langsdorf;b Bad Sülze;18334;038320;Mecklenburg-Vorpommern
Langstedt;;24852;04609;Schlewig-Holstein
Langsur;;54308;06501;Rheinland-Pfalz
Langula;;99986;03601;Thüringen
Langwedel;, Holst;24631;04329;Schlewig-Holstein
Langwedel;, Kr Verden, Aller;27299;04232;Niedersachsen
Langweid;a. Lech;86462;08271;Bayern
Langweiler;b Idar-Oberstein;55758;06786;Rheinland-Pfalz
Langweiler;b Lauterecken;67746;06788;Rheinland-Pfalz
Langwieden;;66894;06372;Rheinland-Pfalz
Langwiederberg;;83512;08071;Bayern
Langwitz;b Malchin;17139;039953;Mecklenburg-Vorpommern
Lank;b Lübtheen;19249;038855;Mecklenburg-Vorpommern
Lankau;;23881;04542;Schlewig-Holstein
Lanke;;16359;033397;Brandenburg
Lansen;;17192;039934;Mecklenburg-Vorpommern
Lanz;b Lenzen, Elbe;19309;038780;Brandenburg
Lanze;, Kr Hzgt Lauenb;21483;04153;Schlewig-Holstein
Lapitz;;17217;03962;Mecklenburg-Vorpommern
Lappersdorf;, Oberpf;93138;0941;Bayern
Lärz;;17248;039833;Mecklenburg-Vorpommern
Lasbek;, Kr Stormarn;23847;04534;Schlewig-Holstein
Lascheid;, Eifel;54597;06554;Rheinland-Pfalz
Lasel;;54612;06553;Rheinland-Pfalz
Lassahn;;19246;038858;Mecklenburg-Vorpommern
Lassan;, Vorpommern;17440;038374;Mecklenburg-Vorpommern
Lastrup;, Kr Cloppenburg;49688;04472;Niedersachsen
Latdorf;;06408;03471;Sachsen-Anhalt
Latendorf;;24598;04393;Schlewig-Holstein
Lathen;;49762;05933;Niedersachsen
Laubach;, Hess;35321;06405;Hessen
Laubach;b Kastellaun;56288;06762;Rheinland-Pfalz
Laubach;, Eifel;56759;02653;Rheinland-Pfalz
Lauben;, Oberallgäu;87493;08374;Bayern
Lauben;b Memmingen;87761;08336;Bayern
Laubenheim;, Nahe;55452;06704;Rheinland-Pfalz
Lauberhof;, Pfalz;67319;06356;Rheinland-Pfalz
Laubsdorf;;03058;035605;Brandenburg
Laubst;;03116;035602;Brandenburg
Laubusch;b Hoyerswerda;02991;035722;Sachsen
Laucha;an der Unstrut;06636;034462;Sachsen-Anhalt
Laucha;, Thür;99880;03622;Thüringen
Lauchhammer;;01979;03574;Brandenburg
Lauchheim;;73466;07363;Baden-Württemberg
Lauchringen;;79787;07741;Baden-Württemberg
Lauchröden;;99819;05654;Thüringen
Lauda-Königshofen;;97922;09343;Baden-Württemberg
Laudenbach;, Unterfr;63925;09372;Bayern
Laudenbach;, Bergstr;69514;06201;Baden-Württemberg
Laudert;;56291;06746;Rheinland-Pfalz
Lauen;;23923;038823;Mecklenburg-Vorpommern
Lauenau;;31867;05043;Niedersachsen
Lauenbrück;;27389;04267;Niedersachsen
Lauenburg;, Elbe;21481;04153;Schlewig-Holstein
Lauenförde;;37697;05273;Niedersachsen
Lauenhagen;b Stadthagen;31714;05721;Niedersachsen
Lauenstein;, Sachs;01778;035054;Sachsen
Lauf;, Baden;77886;07841;Baden-Württemberg
Lauf;a d Pegnitz;91207;09123;Bayern
Laufach;;63846;06093;Bayern
Laufeld;;54533;06572;Rheinland-Pfalz
Laufen;, Salzach;83410;08682;Bayern
Laufenbacherhof;;53533;02693;Rheinland-Pfalz
Laufenburg;(Baden);79725;07763;Baden-Württemberg
Laufersweiler;;55487;06543;Rheinland-Pfalz
Lauffen;am Neckar;74348;07133;Baden-Württemberg
Laufmühle;;94526;09905;Bayern
Laufzorn;;82041;089;Bayern
Laugna;;86502;08272;Bayern
Lauingen;(Donau);89415;09072;Bayern
Laumersheim;;67229;06238;Rheinland-Pfalz
Lauperath;;54649;06554;Rheinland-Pfalz
Laupheim;;88471;07392;Baden-Württemberg
Laurenburg;;56379;06439;Rheinland-Pfalz
Lauscha;;98724;036702;Thüringen
Lauschgrün;;08491;03765;Sachsen
Lauschied;;55568;06753;Rheinland-Pfalz
Lausitz;;04924;035341;Brandenburg
Lausnitz;b. Pößneck;07387;03647;Thüringen
Lausnitz;b Neustadt, Orla;07806;036481;Thüringen
Laußig;;04838;034243;Sachsen
Laußnitz;;01936;035795;Sachsen
Lauta;b Hoyerswerda;02991;035722;Sachsen
Lautenbach;, Renchtal;77794;07802;Baden-Württemberg
Lautenbachhof;;75385;07053;Baden-Württemberg
Lauter;, Sachs;08312;03774;Sachsen
Lauter;, Oberbay;83362;0861;Bayern
Lauter;, Oberfr;96169;09544;Bayern
Lauterach;, Württ;89584;07375;Baden-Württemberg
Lauterbach;(Hessen);36341;06641;Hessen
Lauterbach;, Schwarzw;78730;07422;Baden-Württemberg
Lauterbach;b Eisenach, Thür;99826;036924;Thüringen
Lauterecken;;67742;06382;Rheinland-Pfalz
Lauterhofen;, Oberpf;92283;09186;Bayern
Lautersheim;;67308;06351;Rheinland-Pfalz
Lauterstein;;73111;07332;Baden-Württemberg
Lautert;;56355;06772;Rheinland-Pfalz
Lautertal;(Vogelsberg);36369;06643;Hessen
Lautertal;(Odenwald);64686;06254;Hessen
Lautertal;, Oberfr;96486;09561;Bayern
Lautrach;;87763;08394;Bayern
Lautzenbrücken;;56472;02661;Rheinland-Pfalz
Lautzenhausen;;55483;06543;Rheinland-Pfalz
Lawalde;;02708;03585;Sachsen
Lawalde-Kleindehsa;;02708;03585;Sachsen
Lawalde-Lauba;;02708;035877;Sachsen
Lawitz;;15898;033652;Brandenburg
Läwitz;;07937;036628;Thüringen
Lebach;;66822;06881;Saarland
Lebecksmühle;;66904;06383;Rheinland-Pfalz
Lebehn;;17322;039749;Mecklenburg-Vorpommern
Lebendorf;;06420;034691;Sachsen-Anhalt
Lebien;;06922;035386;Sachsen-Anhalt
Lebrade;;24306;04522;Schlewig-Holstein
Lebus;;15326;033604;Brandenburg
Lebusa;;04936;035364;Brandenburg
Lechbruck;am See;86983;08862;Bayern
Lechenbach;;57537;02742;Rheinland-Pfalz
Leck;;25917;04662;Schlewig-Holstein
Leddin;;16845;03391;Brandenburg
Lederhose;;07589;036604;Thüringen
Leegebruch;;16767;033052;Brandenburg
Leer;(Ostfriesland);26789;0491;Niedersachsen
Leese;, Weser;31633;05761;Niedersachsen
Leeskow;b Lieberose;15868;033671;Brandenburg
Leest;b Potsdam;14476;033202;Brandenburg
Leetza;;06895;034924;Sachsen-Anhalt
Leezdorf;;26529;04936;Niedersachsen
Leezen;b Schwerin, Meckl;19067;03866;Mecklenburg-Vorpommern
Leezen;, Holst;23816;04552;Schlewig-Holstein
Legau;;87764;08330;Bayern
Legde;;19336;038791;Brandenburg
Legden;;48739;02566;Nordrhein-Westfalen
Legefeld;;99438;03643;Thüringen
Legefeld;;99438;03643;Thüringen
Lehe;, Dithm;25774;04882;Schlewig-Holstein
Lehe;, Ems;26892;04962;Niedersachsen
Lehen in Surberg;;83364;08666;Bayern
Lehenfeld;, Württ;71732;;Baden-Württemberg
Leherfeld;;25776;04882;Schlewig-Holstein
Lehermann;;83739;08025;Bayern
Lehesten;, Thüringer W;07349;036653;Thüringen
Lehesten;b Jena;07778;036653;Thüringen
Lehma;;04617;034343;Thüringen
Lehmberg;, Gem Deggendorf;94526;0991;Bayern
Lehmen;, Mosel;56332;02607;Rheinland-Pfalz
Lehmkuhlen;b Preetz, Holst;24211;04522;Schlewig-Holstein
Lehmrade;, Holst;23883;04542;Schlewig-Holstein
Lehmsiek;, Gem Holtsee;24361;04351;Schlewig-Holstein
Lehner;b Miesbach;83714;;Bayern
Lehnin;;14797;03382;Brandenburg
Lehnitz;;16565;03301;Brandenburg
Lehnsdorf;;14823;033848;Brandenburg
Lehnstedt;b Mellingen;99441;036453;Thüringen
Lehrberg;;91611;09820;Bayern
Lehre;;38165;05308;Niedersachsen
Lehrensteinsfeld;;74251;07134;Baden-Württemberg
Lehrte;b Hannover;31275;05132;Niedersachsen
Lehsen;;19243;038852;Mecklenburg-Vorpommern
Leibchel;;15913;035471;Brandenburg
Leibertingen;;88637;07466;Baden-Württemberg
Leiblfing;;94339;09427;Bayern
Leibsch;;15910;035473;Brandenburg
Leichlingen;(Rheinland);42799;02175;Nordrhein-Westfalen
Leidenborn;;54619;06559;Rheinland-Pfalz
Leidersbach;;63849;06028;Bayern
Leienkaul;;56759;02653;Rheinland-Pfalz
Leiferde;, Kr Gifhorn;38542;05373;Niedersachsen
Leimbach;b Querfurt;06268;034771;Sachsen-Anhalt
Leimbach;b Bad Salzungen;36433;03695;Thüringen
Leimbach;b Adenau;53518;02691;Rheinland-Pfalz
Leimbach;b Neuerburg;54673;06564;Rheinland-Pfalz
Leimbach;b Nordhausen;99735;03631;Thüringen
Leimen;, Pfalz;66978;06397;Rheinland-Pfalz
Leimen;, Baden;69181;06224;Baden-Württemberg
Leimersheim;;76774;07272;Rheinland-Pfalz
Leina;b Gotha, Thür;99880;03622;Thüringen
Leinach;, Unterfr;97274;09364;Bayern
Leinburg;;91227;09120;Bayern
Leinefelde;;37327;03605;Thüringen
Leinfelden-Echterdingen;;70771;0711;Baden-Württemberg
Leingarten;;74211;07131;Baden-Württemberg
Leinhäusle;;73568;07176;Baden-Württemberg
Leiningen;b Greiz;07985;036621;Thüringen
Leiningen;, Hunsrück;56291;06746;Rheinland-Pfalz
Leiningerhof;;67822;06362;Rheinland-Pfalz
Leinsweiler;;76829;06345;Rheinland-Pfalz
Leinzell;;73575;07175;Baden-Württemberg
Leipa;;06917;03537;Sachsen-Anhalt
Leipe;;03226;03542;Brandenburg
Leipheim;;89340;08221;Bayern
Leipnitz;;04668;034386;Sachsen
Leippen;;01665;035246;Sachsen
Leippe-Torno;;02991;035722;Sachsen
Leipzig;;04103;0341;Sachsen
Leipzig;;04105;0341;Sachsen
Leipzig;;04107;0341;Sachsen
Leipzig;;04109;0341;Sachsen
Leipzig;;04129;0341;Sachsen
Leipzig;;04155;0341;Sachsen
Leipzig;;04157;0341;Sachsen
Leipzig;;04158;0341;Sachsen
Leipzig;;04159;0341;Sachsen
Leipzig;;04177;0341;Sachsen
Leipzig;;04178;0341;Sachsen
Leipzig;;04179;0341;Sachsen
Leipzig;;04205;0341;Sachsen
Leipzig;;04207;0341;Sachsen
Leipzig;;04209;0341;Sachsen
Leipzig;;04229;0341;Sachsen
Leipzig;;04249;0341;Sachsen
Leipzig;;04275;0341;Sachsen
Leipzig;;04277;0341;Sachsen
Leipzig;;04279;0341;Sachsen
Leipzig;;04288;0341;Sachsen
Leipzig;;04289;0341;Sachsen
Leipzig;;04299;0341;Sachsen
Leipzig;;04315;0341;Sachsen
Leipzig;;04316;0341;Sachsen
Leipzig;;04317;0341;Sachsen
Leipzig;;04318;0341;Sachsen
Leipzig;;04319;0341;Sachsen
Leipzig;;04328;0341;Sachsen
Leipzig;;04329;0341;Sachsen
Leipzig;;04347;0341;Sachsen
Leipzig;;04349;0341;Sachsen
Leipzig;;04356;0341;Sachsen
Leipzig;;04357;0341;Sachsen
Leisel;;55767;06787;Rheinland-Pfalz
Leislau;;06618;0365;Sachsen-Anhalt
Leisnig;;04703;034321;Sachsen
Leißling;;06667;03443;Sachsen-Anhalt
Leisterförde;;19258;038842;Mecklenburg-Vorpommern
Leiten;, Gem Hettenshausen;85304;08441;Bayern
Leiteneck;;83552;08074;Bayern
Leithof;;67292;06352;Rheinland-Pfalz
Leitlitz;;07937;036628;Thüringen
Leitzkau;;39279;039241;Sachsen-Anhalt
Leitzweiler;b Baumholder;55779;06789;Rheinland-Pfalz
Leiwen;;54340;06507;Rheinland-Pfalz
Leizen;;17209;039922;Mecklenburg-Vorpommern
Lelkendorf;;17168;039977;Mecklenburg-Vorpommern
Lemberg;, Pfalz;66969;06331;Rheinland-Pfalz
Lembruch;;49459;05447;Niedersachsen
Lemförde;;49448;05443;Niedersachsen
Lemgo;;32657;05261;Nordrhein-Westfalen
Lemgow;, Niedersachs;29485;05883;Niedersachsen
Lemnitz;;07819;036482;Thüringen
Lemwerder;;27809;0421;Niedersachsen
Lengdorf;, Oberbay;84435;08083;Bayern
Lengede;;38268;05344;Niedersachsen
Lengefeld;b Sangerhausen;06528;03464;Sachsen-Anhalt
Lengefeld;, Erzgeb;09514;037367;Sachsen
Lengefeld;b Mühlhausen, Thür;99976;036023;Thüringen
Lengenbostel;;27419;04282;Niedersachsen
Lengenfeld;, Vogtl;08485;037606;Sachsen
Lengenfeld unterm Stein;;99976;036027;Thüringen
Lengenwang;;87663;08364;Bayern
Lengerich;, Westf;49525;05904;Nordrhein-Westfalen
Lengerich;, Emsl;49838;05904;Niedersachsen
Lengersau;b Röthenbach, Allgäu;88167;08383;Bayern
Lengfeld;b Hildburghausen;98660;036873;Thüringen
Lenggries;;83661;08042;Bayern
Lenne;am Solling;37627;05532;Niedersachsen
Lennestadt;;57368;02721;Nordrhein-Westfalen
Lennewitz;b Wittenberge;19336;038791;Brandenburg
Lenningen;, Württ;73252;07026;Baden-Württemberg
Lensahn;;23738;04363;Schlewig-Holstein
Lenschow;b Parchim;19374;038727;Mecklenburg-Vorpommern
Lenterode;;37318;036083;Thüringen
Lentföhrden;;24632;04192;Schlewig-Holstein
Lenting;;85101;08456;Bayern
Lentzke;;16833;033932;Brandenburg
Lenzen;(Elbe);19309;038792;Brandenburg
Lenzen;b Sternberg;19406;038785;Mecklenburg-Vorpommern
Lenzersilge;;19348;038780;Brandenburg
Lenzkirch;;79853;07653;Baden-Württemberg
Leonberg;, Württ;71229;07152;Baden-Württemberg
Leonberg;, Oberpf;95666;09633;Bayern
Leopoldshagen;;17375;039774;Mecklenburg-Vorpommern
Leopoldshöhe;;33818;05208;Nordrhein-Westfalen
Leppin;b Parchim;19376;038729;Mecklenburg-Vorpommern
Leppin;, Altmark;39615;039384;Sachsen-Anhalt
Leps;;39264;03923;Sachsen-Anhalt
Lerchen;b Neukirchen a Teisenberg;83364;08666;Bayern
Leschen;;04720;034325;Sachsen
Letschin;;15324;033475;Brandenburg
Lettweiler;;67823;06755;Rheinland-Pfalz
Letzlingen;;39638;039088;Sachsen-Anhalt
Leuben-Schleinitz;;01623;035241;Sachsen
Leubetha;;08626;037423;Sachsen
Leubingen;;99610;03634;Thüringen
Leubnitz;, Sachs;08412;03761;Sachsen
Leubnitz;b Plauen;08539;03761;Sachsen
Leubsdorf;b Neustadt, Orla;07819;036482;Thüringen
Leubsdorf;, Sachs;09573;037291;Sachsen
Leubsdorf;, Rhein;53547;02644;Rheinland-Pfalz
Leuchtenberg;;92705;09659;Bayern
Leuenberg;;16259;033451;Brandenburg
Leun;, Lahn;35638;06473;Hessen
Leuna;;06237;03462;Sachsen-Anhalt
Leupahn;;09306;034381;Sachsen
Leupoldsgrün;;95191;09292;Bayern
Leussow;b Ludwigslust;19288;038754;Mecklenburg-Vorpommern
Leutenbach;, Württ;71397;07195;Baden-Württemberg
Leutenbach;, Oberfr;91359;09197;Bayern
Leutenberg;, Thür;07338;036734;Thüringen
Leutenhain;;09306;03737;Sachsen
Leutenthal;;99439;036451;Thüringen
Leuterod;;56244;02602;Rheinland-Pfalz
Leutersdorf;, OL;02794;03586;Sachsen
Leutersdorf;b Meiningen;98617;036949;Thüringen
Leutershausen;, Mittelfr;91578;09823;Bayern
Leutesdorf;, Rhein;56599;02631;Rheinland-Pfalz
Leutewitz;b Riesa;01594;035266;Sachsen
Leutewitz;b Riesa;01594;035266;Sachsen
Leuthen;, NL;03058;035602;Brandenburg
Leutkirch;im Allgäu;88299;07561;Baden-Württemberg
Leutnitz;;07422;036739;Thüringen
Levenhagen;;17498;038373;Mecklenburg-Vorpommern
Levensau;;24161;0431;Schlewig-Holstein
Levenstorf;;17192;039953;Mecklenburg-Vorpommern
Leverkusen;;51371;0214;Nordrhein-Westfalen
Leverkusen;;51373;0214;Nordrhein-Westfalen
Leverkusen;;51375;0214;Nordrhein-Westfalen
Leverkusen;;51377;0214;Nordrhein-Westfalen
Leverkusen;;51379;0214;Nordrhein-Westfalen
Leverkusen;;51381;0214;Nordrhein-Westfalen
Levetzow;;23970;03841;Mecklenburg-Vorpommern
Levitzow;;17168;039975;Mecklenburg-Vorpommern
Lexgaard;;25923;04663;Schlewig-Holstein
Lexow;;17213;039932;Mecklenburg-Vorpommern
Libbenichen;;15306;033602;Brandenburg
Libbesdorf;;06386;034977;Sachsen-Anhalt
Libehna;;06369;034978;Sachsen-Anhalt
Lich;, Hess;35423;06404;Hessen
Lichte;;98739;036701;Thüringen
Lichtenau;b Aue, Sachs;08318;037462;Sachsen
Lichtenau;b Chemnitz, Sachs;09244;037208;Sachsen
Lichtenau;, Westf;33165;05295;Nordrhein-Westfalen
Lichtenau;, Baden;77839;07227;Baden-Württemberg
Lichtenau;, Mittelfr;91586;09827;Bayern
Lichtenau;, Unterfr;97840;09352;Bayern
Lichtenberg;b Bischofswerda;01896;035955;Sachsen
Lichtenberg;/Erzgeb.;09638;037323;Sachsen
Lichtenberg;b Neuruppin;16818;03391;Brandenburg
Lichtenberg;b Neustrelitz;17259;039831;Mecklenburg-Vorpommern
Lichtenberg;b Solnhofen;91807;;Bayern
Lichtenberg;, Oberfr;95192;09288;Bayern
Lichtenborn;, Eifel;54619;06554;Rheinland-Pfalz
Lichtenbrunn;;07366;036651;Thüringen
Lichtenfels;, Hess;35104;05636;Hessen
Lichtenfels;, Bay;96215;09571;Bayern
Lichtenhain;b Sebnitz;01855;035971;Sachsen
Lichtenhain b Gräfenthal;;98743;036703;Thüringen
Lichtenhain Bergbahn;;98744;036705;Thüringen
Lichtenow;;15345;033434;Brandenburg
Lichtensee;b Riesa;01609;035263;Sachsen
Lichtenstein;/ Sa.;09350;037204;Sachsen
Lichtenstein;, Württ;72805;07129;Baden-Württemberg
Lichtentanne;b Saalfeld, Saale;07338;036734;Thüringen
Lichtentanne;, Sachs;08115;0375;Sachsen
Lichtenwald;, Württ;73669;07153;Baden-Württemberg
Lichterfeld;;03238;03531;Brandenburg
Lichterfelde;b Jüterbog;14913;033746;Brandenburg
Lichterfelde;b Eberswalde;16230;03334;Brandenburg
Lichterfelde;, Altmark;39615;039396;Sachsen-Anhalt
Liebenau;b Dippoldiswalde;01778;035054;Sachsen
Liebenau;, Kr Nienburg, Weser;31618;05023;Niedersachsen
Liebenau;, Hess;34396;05676;Hessen
Liebenburg;;38704;05346;Niedersachsen
Liebengrün;;07368;036640;Thüringen
Liebenhof;;15377;033433;Brandenburg
Liebenscheid;;56479;02667;Rheinland-Pfalz
Liebenstein;b Arnstadt;99330;036205;Thüringen
Liebenthal;b Oranienburg;16559;033054;Brandenburg
Liebenthal;b Wittstock, Dosse;16909;033962;Brandenburg
Liebenwalde;;16559;033054;Brandenburg
Lieberose;;15868;033671;Brandenburg
Lieblingshof;;18196;038204;Mecklenburg-Vorpommern
Liebschütz;b Lobenstein;07368;036640;Thüringen
Liebschützberg;;04758;03435;Sachsen
Liebshausen;;55494;06764;Rheinland-Pfalz
Liebstadt;;01825;035025;Sachsen
Liebstedt;;99510;036462;Thüringen
Liederbach;am Taunus;65835;069;Hessen
Liedersdorf;;06528;034659;Sachsen-Anhalt
Lieg;;56290;02672;Rheinland-Pfalz
Liega;;01561;035248;Sachsen
Liegau-Augustusbad;;01465;03528;Sachsen
Lienen;, Westf;49536;05483;Nordrhein-Westfalen
Liepe;b Rathenow;14715;033876;Brandenburg
Liepe;b Jüterbog;14913;033745;Brandenburg
Liepe;b Eberswalde;16248;033362;Brandenburg
Liepe;b Ludwigslust;19294;038755;Mecklenburg-Vorpommern
Liepen;b Anklam;17391;039721;Mecklenburg-Vorpommern
Liepgarten;;17375;039771;Mecklenburg-Vorpommern
Lierfeld;;54597;0651;Rheinland-Pfalz
Lierschied;;56357;06771;Rheinland-Pfalz
Liesenich;;56858;06545;Rheinland-Pfalz
Lieser;;54470;06531;Rheinland-Pfalz
Lieshöfe;, Gem Veringenstadt;72419;07577;Baden-Württemberg
Lieskau;b Spremberg, NL;03130;03563;Brandenburg
Lieskau;, NL;03238;035329;Brandenburg
Lieskau;b Halle, Saale;06120;0345;Sachsen-Anhalt
Ließem;, Eifel;54636;06569;Rheinland-Pfalz
Ließen;;14913;033745;Brandenburg
Liessow;b Güstrow;18299;038459;Mecklenburg-Vorpommern
Liesten;;29416;039032;Sachsen-Anhalt
Lieth;;25770;0481;Schlewig-Holstein
Lietzen;;15306;033470;Brandenburg
Lietzow;b Nauen;14641;03321;Brandenburg
Lietzow;, Rügen;18528;038302;Mecklenburg-Vorpommern
Lilienthal;b Bremen;28865;04298;Niedersachsen
Limbach;b Reichenbach, Vogtl;08491;03765;Sachsen
Limbach;b Kirn, Nahe;55606;06757;Rheinland-Pfalz
Limbach;, Westerw;57629;02662;Rheinland-Pfalz
Limbach;, Baden;74838;06287;Baden-Württemberg
Limbach-Oberfrohna;;09212;03722;Sachsen
Limburg;a d Lahn;65549;06431;Hessen
Limburg;a d Lahn;65550;06431;Hessen
Limburg;a d Lahn;65551;06431;Hessen
Limburg;a d Lahn;65552;06431;Hessen
Limburg;a d Lahn;65553;06431;Hessen
Limburg;a d Lahn;65554;06431;Hessen
Limburg;a d Lahn;65555;06431;Hessen
Limburg;a d Lahn;65556;06431;Hessen
Limburgerhof;;67117;06236;Rheinland-Pfalz
Limeshain;;63694;06047;Hessen
Limsdorf;;15864;033677;Brandenburg
Linau;;22959;04154;Schlewig-Holstein
Lind;, Kr Ahrweiler;53506;02643;Rheinland-Pfalz
Lind;b Mayen;56729;02656;Rheinland-Pfalz
Linda;(Elster);06928;035384;Sachsen-Anhalt
Linda;b. Weida;07580;036608;Thüringen
Linda;b. Neustadt an der Orla;07819;036481;Thüringen
Lindach;, Gem Gars a Inn;83552;08075;Bayern
Lindau;b Kiel;24214;04346;Schlewig-Holstein
Lindau;, Anh;39264;039246;Sachsen-Anhalt
Lindau;(Bodensee);88131;08382;Bayern
Lindberg;, Kr Regen;94227;09922;Bayern
Lindchen;;03103;035751;Brandenburg
Lindemannsruhe;;67098;;Rheinland-Pfalz
Linden;, Holst;25791;04836;Schlewig-Holstein
Linden;, Hessen;35440;06403;Hessen
Linden;, Westerw;57629;02666;Rheinland-Pfalz
Linden;, Pfalz;66851;06307;Rheinland-Pfalz
Linden;, Kr Landshut;84032;;Bayern
Linden;b Spiegelau;94518;08553;Bayern
Linden;b Hildburghausen;98646;036875;Thüringen
Lindenau;, OL;01945;035755;Brandenburg
Lindenbach;, Gem Nievern;56130;;Rheinland-Pfalz
Lindenberg;b Naumburg, Saale;06647;;Sachsen-Anhalt
Lindenberg;b Beeskow;15864;033677;Brandenburg
Lindenberg;b Bernau b Berlin;16321;030;Brandenburg
Lindenberg;, Prignitz;16928;033982;Brandenburg
Lindenberg;, Vorpommern;17111;039996;Mecklenburg-Vorpommern
Lindenberg;b Wittenberge;19322;03877;Brandenburg
Lindenberg;, Pfalz;67473;06325;Rheinland-Pfalz
Lindenberg;i. Allgäu;88161;08381;Bayern
Lindenbrück;;15838;033702;Brandenburg
Lindenfels;, Odenw;64678;06255;Hessen
Lindenhof;b Sternberg;19417;;Mecklenburg-Vorpommern
Lindenhof;b Landstuhl;66903;06386;Rheinland-Pfalz
Lindenhof;, Oberfr;95460;09273;Bayern
Lindenkreuz;;07589;036604;Thüringen
Lindenschied;;55481;06763;Rheinland-Pfalz
Linderbach;;99198;0361;Thüringen
Linderbach;;99198;0361;Thüringen
Linderhof;, Gem Straßberg, Hohenz;72458;;Baden-Württemberg
Lindern;(Oldenburg);49699;05957;Niedersachsen
Lindetal;;17349;;Mecklenburg-Vorpommern
Lindewerra;;37318;05652;Thüringen
Lindewitt;;24969;04604;Schlewig-Holstein
Lindhorst;b Stadthagen;31698;05725;Niedersachsen
Lindig;b Jena;07768;036424;Thüringen
Lindigt;b Meißen, Sachs;01665;;Sachsen
Lindlar;;51789;02266;Nordrhein-Westfalen
Lindow;b Jüterbog;14913;033741;Brandenburg
Lindow;(Mark);16835;033933;Brandenburg
Lindow;b Schönberg, Meckl;23923;038828;Mecklenburg-Vorpommern
Lindstedt;;39638;039084;Sachsen-Anhalt
Lindthal;;03238;03531;Brandenburg
Lindtorf;;39596;039388;Sachsen-Anhalt
Lindwedel;;29690;05073;Niedersachsen
Lindwerder;;06928;035384;Sachsen-Anhalt
Lingen;(Ems);49808;0591;Niedersachsen
Lingen;(Ems);49809;0591;Niedersachsen
Lingen;(Ems);49811;0591;Niedersachsen
Lingenfeld;;67360;06344;Rheinland-Pfalz
Lingerhahn;;56291;06746;Rheinland-Pfalz
Linkenbach;, Westerw;56317;02684;Rheinland-Pfalz
Linkenheim-Hochstetten;;76351;07247;Baden-Württemberg
Linkersbaindt;;91717;09832;Bayern
Linnich;;52441;02462;Nordrhein-Westfalen
Linow;;16831;033931;Brandenburg
Linsburg;;31636;05027;Niedersachsen
Linsengericht;;63589;06051;Hessen
Linstow;;18292;038457;Mecklenburg-Vorpommern
Linthe;;14822;033844;Brandenburg
Lintig;;27624;04745;Niedersachsen
Linum;;16833;033927;Brandenburg
Linumhorst;;16766;;Brandenburg
Linz;a Rhein;53545;02644;Rheinland-Pfalz
Lippelsdorf;;98743;036701;Thüringen
Lippersdorf-Erdmannsdorf;;07646;036426;Thüringen
Lippetal;;59510;02923;Nordrhein-Westfalen
Lipporn;;56357;06775;Rheinland-Pfalz
Lipprechterode;;99752;036338;Thüringen
Lippstadt;;59555;02941;Nordrhein-Westfalen
Lippstadt;;59556;02941;Nordrhein-Westfalen
Lippstadt;;59557;02941;Nordrhein-Westfalen
Lippstadt;;59558;02941;Nordrhein-Westfalen
Lipsa;;01945;035752;Brandenburg
Lipten;;03205;035329;Brandenburg
Lirstal;;56767;02657;Rheinland-Pfalz
Lisberg;;96170;09549;Bayern
Lissendorf;;54587;06597;Rheinland-Pfalz
List;, Sylt;25992;04651;Schlewig-Holstein
Listerfehrda;;06918;035383;Sachsen-Anhalt
Litzendorf;;96123;09505;Bayern
Löbau;;02708;03585;Sachsen
Lobbach;, Baden;74931;06226;Baden-Württemberg
Lobbese;;14913;033747;Brandenburg
Löbejün;;06193;034603;Sachsen-Anhalt
Löben;, Elster;06925;035389;Sachsen-Anhalt
Lobenstein;, Thür;07356;036651;Thüringen
Löberitz;;06780;034956;Sachsen-Anhalt
Löberschütz;;07751;036427;Thüringen
Lobetal;;16321;03338;Brandenburg
Löbichau;;04626;034496;Thüringen
Löbitz;;06618;034422;Sachsen-Anhalt
Löbnitz;b Delitzsch;04509;034208;Sachsen
Löbnitz;b Ribnitz-Damgarten;18314;038324;Mecklenburg-Vorpommern
Löbnitz;(Bode);39443;;Sachsen-Anhalt
Löbnitz an der Linde;;06369;03496;Sachsen-Anhalt
Lobstädt;;04552;03433;Sachsen
Loburg;b Magdeburg;39279;039245;Sachsen-Anhalt
Loch;b Neukirchen a Teisenberg;83364;;Bayern
Lochau;b Halle, Saale;06184;0345;Sachsen-Anhalt
Löchgau;;74369;07143;Baden-Württemberg
Lochmühle;b Eisenberg, Thür;07616;;Thüringen
Lochmühle;b Kirn, Nahe;55606;;Rheinland-Pfalz
Lochum;;57629;02666;Rheinland-Pfalz
Locking;;83552;08075;Bayern
Löcknitz;, Vorpommern;17321;039754;Mecklenburg-Vorpommern
Löcknitz;b Parchim;19372;;Mecklenburg-Vorpommern
Lockstedt;, Holst;25551;04877;Schlewig-Holstein
Lockstedt;b Haldensleben;39359;039057;Sachsen-Anhalt
Locktow;;14806;033843;Brandenburg
Lockwisch;;23923;038828;Mecklenburg-Vorpommern
Lödderitz;;39240;039294;Sachsen-Anhalt
Loddin;;17459;038375;Mecklenburg-Vorpommern
Loder;b Wörnsmühl;83739;;Bayern
Löderburg;;39446;039265;Sachsen-Anhalt
Lödla;;04617;034498;Thüringen
Löf;;56332;02605;Rheinland-Pfalz
Loffenau;;76597;07083;Baden-Württemberg
Löffingen;;79843;07654;Baden-Württemberg
Lögow;;16845;033974;Brandenburg
Lohbarbek;;25551;04826;Schlewig-Holstein
Lohberg;b Lam, Oberpf;93470;09943;Bayern
Lohe-Föhrden;;24806;04335;Schlewig-Holstein
Lohen;b Mittergars;83559;08073;Bayern
Lohe-Rickelshof;;25746;0481;Schlewig-Holstein
Lohfelden;;34253;05605;Hessen
Lohheide, gemfr. Bezirk;;29303;;Niedersachsen
Lohkirchen;, Oberbay;84494;08637;Bayern
Lohm;;16845;033973;Brandenburg
Löhma;b Schleiz;07907;03663;Thüringen
Lohmar;, Rheinl;53797;02246;Nordrhein-Westfalen
Lohme;, Rügen;18551;038302;Mecklenburg-Vorpommern
Löhme;;16356;033398;Brandenburg
Lohmen;, Sachs;01847;03501;Sachsen
Lohmen;b Güstrow;18276;038458;Mecklenburg-Vorpommern
Lohmühle;b Kirn, Nahe;55606;;Rheinland-Pfalz
Löhnberg;;35792;06471;Hessen
Lohne;(Oldenburg);49393;04442;Niedersachsen
Löhne;;32584;05732;Nordrhein-Westfalen
Lohnsfeld;;67727;06302;Rheinland-Pfalz
Lohnweiler;;67744;06382;Rheinland-Pfalz
Lohr;a. Main;97816;09352;Bayern
Lohra;;35102;06462;Hessen
Lohrheim;;65558;06430;Rheinland-Pfalz
Lohsa;;02999;035724;Sachsen
Löhsten;;04895;035363;Brandenburg
Loibersdorf;b Großhelfendorf;85655;08093;Bayern
Loiching;;84180;08731;Bayern
Loissin;;17509;038352;Mecklenburg-Vorpommern
Loit;;24888;04641;Schlewig-Holstein
Loitsche;;39326;039208;Sachsen-Anhalt
Loitz;b Demmin;17121;039998;Mecklenburg-Vorpommern
Loitzendorf;;94359;09964;Bayern
Loiz;;19406;03847;Mecklenburg-Vorpommern
Lollar;;35457;06406;Hessen
Löllbach;;67744;06753;Rheinland-Pfalz
Lollschied;;56357;06772;Rheinland-Pfalz
Lommatzsch;;01623;035241;Sachsen
Longen;;54338;06502;Rheinland-Pfalz
Longkamp;;54472;06531;Rheinland-Pfalz
Longuich;;54340;06502;Rheinland-Pfalz
Löningen;;49624;05432;Niedersachsen
Lonnerstadt;;91475;09193;Bayern
Lonnig;;56295;02625;Rheinland-Pfalz
Lonsee;;89173;07336;Baden-Württemberg
Lonsheim;;55237;06734;Rheinland-Pfalz
Looft;;25582;04892;Schlewig-Holstein
Looganlage;;67435;;Rheinland-Pfalz
Loop;, Holst;24644;04322;Schlewig-Holstein
Loose;b Eckernförde;24366;04358;Schlewig-Holstein
Loosemühle;, Gem Birstein;63699;;Hessen
Löpten;;15757;033766;Brandenburg
Löptin;;24250;04302;Schlewig-Holstein
Lorch;, Rheingau;65391;06726;Hessen
Lorch;, Württ;73547;07172;Baden-Württemberg
Loreley;;56346;06771;Rheinland-Pfalz
Lörrach;;79539;07621;Baden-Württemberg
Lörrach;;79540;07621;Baden-Württemberg
Lörrach;;79541;07621;Baden-Württemberg
Lorsch;, Hess;64653;06251;Hessen
Lorscheid;b Trier;54317;06500;Rheinland-Pfalz
Lorup;;26901;05954;Niedersachsen
Lörzweiler;;55296;06138;Rheinland-Pfalz
Losenrade;;39615;039397;Sachsen-Anhalt
Losheim;am See;66679;06872;Saarland
Lositz-Jehmichen;;07318;036736;Thüringen
Lösnich;;54492;06532;Rheinland-Pfalz
Lossa;b Naumburg, Saale;06647;036377;Sachsen-Anhalt
Loßburg;;72290;07446;Baden-Württemberg
Losse;;39615;039386;Sachsen-Anhalt
Lößnitz;;08294;037754;Sachsen
Lostau;;39291;039222;Sachsen-Anhalt
Losten;;23996;038423;Mecklenburg-Vorpommern
Lösten;b Meißen, Sachs;01665;035246;Sachsen
Lotenberg;, Gem Heiningen;73107;07161;Baden-Württemberg
Lothra;;07356;036737;Thüringen
Lotte;;49504;05404;Nordrhein-Westfalen
Lottengrün;;08606;03741;Sachsen
Lottorf;;24878;04621;Schlewig-Holstein
Lottschesee;;16348;;Brandenburg
Lottstetten;;79807;07745;Baden-Württemberg
Lötzbeuren;;56843;06543;Rheinland-Pfalz
Louisenberg;;24340;04351;Schlewig-Holstein
Louisenhof;, Siedlung;21493;04151;Schlewig-Holstein
Löwenberg;b Gransee;16775;033094;Brandenburg
Löwenbruch;;14974;03378;Brandenburg
Löwenstedt;;25864;04843;Schlewig-Holstein
Löwenstein;, Württ;74245;07130;Baden-Württemberg
Löwitz;b Anklam;17398;039726;Mecklenburg-Vorpommern
Löwitz;b Gadebusch;19217;038872;Mecklenburg-Vorpommern
Loxstedt;;27612;04744;Niedersachsen
Lübars;b Burg b Magdeburg;39291;039225;Sachsen-Anhalt
Lübbecke;, Westf;32312;05741;Nordrhein-Westfalen
Lübben;(Spreewald);15907;03546;Brandenburg
Lübbenau;/Spreewald;03222;03542;Brandenburg
Lübbendorf;;19249;038855;Mecklenburg-Vorpommern
Lübberstedt;, Kr Osterholz;27729;04793;Niedersachsen
Lübberstorf;;23992;038422;Mecklenburg-Vorpommern
Lübbow;;29488;05843;Niedersachsen
Lübeck;;23552;0451;Schlewig-Holstein
Lübeck;;23554;0451;Schlewig-Holstein
Lübeck;;23556;0451;Schlewig-Holstein
Lübeck;;23558;0451;Schlewig-Holstein
Lübeck;;23560;0451;Schlewig-Holstein
Lübeck;;23562;0451;Schlewig-Holstein
Lübeck;;23564;0451;Schlewig-Holstein
Lübeck;;23566;0451;Schlewig-Holstein
Lübeck;;23568;0451;Schlewig-Holstein
Lübeck;;23569;0451;Schlewig-Holstein
Lübeck;;23570;0451;Schlewig-Holstein
Lübesse;;19077;0385;Mecklenburg-Vorpommern
Lüblow;;19288;038753;Mecklenburg-Vorpommern
Lubmin;;17509;038354;Mecklenburg-Vorpommern
Lübnitz;;14806;09254;Brandenburg
Lubochow;;03103;035751;Brandenburg
Lübow;;23972;03841;Mecklenburg-Vorpommern
Lübs;b Torgelow, Vorpommern;17379;039777;Mecklenburg-Vorpommern
Lübs;b Zerbst;39264;039242;Sachsen-Anhalt
Lübschützer Teiche;;04827;03425;Sachsen
Lübstorf;;19069;03867;Mecklenburg-Vorpommern
Lübtheen;;19249;038855;Mecklenburg-Vorpommern
Lübz;;19386;038731;Mecklenburg-Vorpommern
Lübzin-Rosenow;;18249;038481;Mecklenburg-Vorpommern
Lübzow;;19348;03876;Brandenburg
Lübzow Ausbau;;19348;03876;Brandenburg
Lüchfeld;;16845;03391;Brandenburg
Luchle;;79875;;Baden-Württemberg
Lüchow;b Sandesneben;23898;04536;Schlewig-Holstein
Lüchow;(Wendland);29439;05841;Niedersachsen
Lüchtoft;;24395;04632;Schlewig-Holstein
Lucka;, Thür;04613;034492;Thüringen
Luckau;, NL;15926;03544;Brandenburg
Luckau;(Wendland);29487;05844;Niedersachsen
Luckenau;;06727;03441;Sachsen-Anhalt
Luckenbach;;57629;02662;Rheinland-Pfalz
Lückenburg;;54424;06504;Rheinland-Pfalz
Luckenwalde;;14943;03371;Brandenburg
Luckow;b Torgelow, Vorpommern;17375;039775;Mecklenburg-Vorpommern
Luckow-Petershagen;;16306;033331;Brandenburg
Lucksfleiß;;14778;033835;Brandenburg
Lückstedt;;39606;039391;Sachsen-Anhalt
Luckwitz;;19243;038852;Mecklenburg-Vorpommern
Lüdelsen;;38489;039003;Sachsen-Anhalt
Lüdendorf;;14913;033748;Brandenburg
Lüdenscheid;;58507;02351;Nordrhein-Westfalen
Lüdenscheid;;58509;02351;Nordrhein-Westfalen
Lüdenscheid;;58511;02351;Nordrhein-Westfalen
Lüdenscheid;;58513;02351;Nordrhein-Westfalen
Lüdenscheid;;58515;02351;Nordrhein-Westfalen
Lüder;;29394;05824;Niedersachsen
Lüderitz;;39517;039361;Sachsen-Anhalt
Lüdersburg;;21379;04153;Niedersachsen
Lüdersdorf;b Luckenwalde;14943;033731;Brandenburg
Lüdersdorf;b Eberswalde;16248;033365;Brandenburg
Lüdersdorf;, Meckl;23923;038821;Mecklenburg-Vorpommern
Lüdersdorf;b Neukloster;23992;038422;Mecklenburg-Vorpommern
Lüdersfeld;;31702;05725;Niedersachsen
Lüdershagen;b Ribnitz-Damgarten;18314;038227;Mecklenburg-Vorpommern
Lüdinghausen;;59348;02591;Nordrhein-Westfalen
Ludorf;;17207;039931;Mecklenburg-Vorpommern
Ludwigsau;, Hess;36251;06670;Hessen
Ludwigsburg;b Prenzlau;17291;039854;Brandenburg
Ludwigsburg;, Württ;71634;07141;Baden-Württemberg
Ludwigsburg;, Württ;71636;07141;Baden-Württemberg
Ludwigsburg;, Württ;71638;07141;Baden-Württemberg
Ludwigsburg;, Württ;71640;07141;Baden-Württemberg
Ludwigsburg;, Württ;71642;07141;Baden-Württemberg
Ludwigschorgast;;95364;09227;Bayern
Ludwigsfelde;;14974;03378;Brandenburg
Ludwigshafen;am Rhein;67059;0621;Rheinland-Pfalz
Ludwigshafen;am Rhein;67061;0621;Rheinland-Pfalz
Ludwigshafen;am Rhein;67063;0621;Rheinland-Pfalz
Ludwigshafen;am Rhein;67065;0621;Rheinland-Pfalz
Ludwigshafen;am Rhein;67067;0621;Rheinland-Pfalz
Ludwigshafen;am Rhein;67069;0621;Rheinland-Pfalz
Ludwigshafen;am Rhein;67071;0621;Rheinland-Pfalz
Ludwigshöhe;, Rheinhess;55278;06249;Rheinland-Pfalz
Ludwigslust;, Meckl;19288;03874;Mecklenburg-Vorpommern
Ludwigsstadt;;96337;09263;Bayern
Ludwigswinkel;;66996;06393;Rheinland-Pfalz
Lüerdissen;am Ith;37635;05534;Niedersachsen
Luftkurort Lückendorf;;02797;035844;Sachsen
Lug;, NL;03205;035329;Brandenburg
Lug;, Pfalz;76848;06392;Rheinland-Pfalz
Lug;, Oberbay;83377;08661;Bayern
Lugau;, NL;03238;035322;Brandenburg
Lugau;, Erzgeb;09385;037295;Sachsen
Lügde;;32676;05283;Nordrhein-Westfalen
Luggendorf;;16928;033983;Brandenburg
Lühburg;;17179;039972;Mecklenburg-Vorpommern
Luhden;;31711;05722;Niedersachsen
Luhe-Wildenau;;92706;09607;Bayern
Lühmannsdorf;;17495;038373;Mecklenburg-Vorpommern
Luhme;;16837;033923;Brandenburg
Lühnsdorf;;14823;033843;Brandenburg
Luhnstedt;;24816;04875;Schlewig-Holstein
Luhnvie;;24816;04337;Schlewig-Holstein
Luhrbach;, Forsthaus;67466;06325;Rheinland-Pfalz
Lühsdorf;;14943;033204;Brandenburg
Luisenhof;b Erfurt;99192;;Thüringen
Luisenhof;b Erfurt;99192;;Thüringen
Luisenthal;, Thür;99885;036257;Thüringen
Luko;;06869;034901;Sachsen-Anhalt
Lülsfeld;;97511;09382;Bayern
Lumpzig;;04626;034495;Thüringen
Lunden;, Holst;25774;04882;Schlewig-Holstein
Lünebach;;54597;06556;Rheinland-Pfalz
Lüneburg;;21335;04131;Niedersachsen
Lüneburg;;21337;04131;Niedersachsen
Lüneburg;;21339;04131;Niedersachsen
Lüneburg;;21391;04131;Niedersachsen
Lünen;;44532;02306;Nordrhein-Westfalen
Lünen;;44534;02306;Nordrhein-Westfalen
Lünen;;44536;02306;Nordrhein-Westfalen
Lunestedt;;27616;04748;Niedersachsen
Lüningshagen;;18239;038295;Mecklenburg-Vorpommern
Lünne;;48480;05906;Niedersachsen
Lunow;;16248;033365;Brandenburg
Lünow;;14778;033831;Brandenburg
Lunzenau;;09328;037383;Sachsen
Lunzig;;07980;036625;Thüringen
Lupburg;;92331;09492;Bayern
Lupendorf;;17194;039953;Mecklenburg-Vorpommern
Luppenau;;06254;03461;Sachsen-Anhalt
Luppertsseifen, Gem Burscheid;;54649;;Rheinland-Pfalz
Lürschau;;24850;04625;Schlewig-Holstein
Luschendorf;;23689;04504;Schlewig-Holstein
Luschendorferhof;;23689;04504;Schlewig-Holstein
Luso;;39264;03923;Sachsen-Anhalt
Lüsse;;14806;033841;Brandenburg
Lüssow;b Greifswald;17506;038353;Mecklenburg-Vorpommern
Lüssow;b Güstrow;18276;03843;Mecklenburg-Vorpommern
Lüssow;b Stralsund;18442;03831;Mecklenburg-Vorpommern
Lustadt;;67363;06347;Rheinland-Pfalz
Lütau;;21483;04153;Schlewig-Holstein
Lütetsburg;;26524;04931;Niedersachsen
Lutheran;;19386;038731;Mecklenburg-Vorpommern
Lutherstadt Eisleben;;06295;03475;Sachsen-Anhalt
Lutherstadt Wittenberg;;06886;03491;Sachsen-Anhalt
Lütjenburg;, Holst;24321;04381;Schlewig-Holstein
Lütjenheide;;19322;03877;Brandenburg
Lütjenholm;;25842;04672;Schlewig-Holstein
Lütjensee;;22952;04154;Schlewig-Holstein
Lütjenwestedt;;25585;04872;Schlewig-Holstein
Lütow;;17440;038377;Mecklenburg-Vorpommern
Lüttchendorf;;06317;03475;Sachsen-Anhalt
Lütte;;14806;033846;Brandenburg
Lüttenhagen;;17258;03964;Mecklenburg-Vorpommern
Lüttenmark;;19258;038842;Mecklenburg-Vorpommern
Lutter;b Heilbad Heiligenstadt;37318;036083;Thüringen
Lutter;am Barenberge;38729;05383;Niedersachsen
Lutterbek;;24235;04343;Schlewig-Holstein
Lutterstorf;;23996;038424;Mecklenburg-Vorpommern
Lüttewitz b Döbeln;;04720;034325;Sachsen
Lüttgenrode;;38835;039421;Sachsen-Anhalt
Lüttholm;;24891;;Schlewig-Holstein
Lüttow;;19246;038851;Mecklenburg-Vorpommern
Lütz;;56290;02672;Rheinland-Pfalz
Lützelbach;, Odenw;64750;06165;Hessen
Lützelsoon;, Forsthaus;55606;;Rheinland-Pfalz
Lützen;;06686;034444;Sachsen-Anhalt
Lutzerath;;56826;02677;Rheinland-Pfalz
Lutzhorn;;25355;04123;Schlewig-Holstein
Lutzingen;;89440;09089;Bayern
Lützkampen;;54617;06559;Rheinland-Pfalz
Lutzketal;;03172;03561;Brandenburg
Lützlow;;17291;039861;Brandenburg
Lützow;;19209;038874;Mecklenburg-Vorpommern
Luxem;;56729;02656;Rheinland-Pfalz
Lychen;;17279;039888;Brandenburg
Lykershausen;;56346;06773;Rheinland-Pfalz
Maasbüll;b Flensburg;24975;04634;Schlewig-Holstein
Maasdorf;b Falkenberg, Elster;04924;035341;Brandenburg
Maasdorf;b Köthen, Anh;06388;03496;Sachsen-Anhalt
Maasen;;27249;04272;Niedersachsen
Maasholm;;24404;04642;Schlewig-Holstein
Machern;b Wurzen;04827;034292;Sachsen
Machern;, Mosel;54492;;Rheinland-Pfalz
Machtemesmühle;;54673;06524;Rheinland-Pfalz
Macken;;56290;02605;Rheinland-Pfalz
Mackenbach;, Kr Kaiserslautern;67686;06374;Rheinland-Pfalz
Mackenrode;b Heilbad Heiligenstadt;37318;036087;Thüringen
Mackenrodt;;55758;06781;Rheinland-Pfalz
Madreut;;83329;08681;Bayern
Magdala;;99441;036454;Thüringen
Magdeburg;;39104;0391;Sachsen-Anhalt
Magdeburg;;39106;0391;Sachsen-Anhalt
Magdeburg;;39108;0391;Sachsen-Anhalt
Magdeburg;;39110;0391;Sachsen-Anhalt
Magdeburg;;39112;0391;Sachsen-Anhalt
Magdeburg;;39114;0391;Sachsen-Anhalt
Magdeburg;;39116;0391;Sachsen-Anhalt
Magdeburg;;39118;0391;Sachsen-Anhalt
Magdeburg;;39120;0391;Sachsen-Anhalt
Magdeburg;;39122;0391;Sachsen-Anhalt
Magdeburg;;39124;0391;Sachsen-Anhalt
Magdeburg;;39126;0391;Sachsen-Anhalt
Magdeburg;;39128;0391;Sachsen-Anhalt
Magdeburg;;39130;0391;Sachsen-Anhalt
Magdeburgerforth;;39291;039225;Sachsen-Anhalt
Magstadt;;71106;07159;Baden-Württemberg
Mahdel;;04916;03535;Brandenburg
Mahlberg;, Baden;77972;07825;Baden-Württemberg
Mahlenzien;;14789;033832;Brandenburg
Mahlenzien;;14789;033832;Brandenburg
Mahlitz;;14715;039389;Sachsen-Anhalt
Mahlow;;15831;03379;Brandenburg
Mahlsdorf;b Belzig;14827;;Brandenburg
Mahlsdorf;, Altmark;29416;039032;Sachsen-Anhalt
Mahlstetten;;78601;07429;Baden-Württemberg
Mahlwinkel;;39517;03935;Sachsen-Anhalt
Mahndorf;, Harz;38822;03941;Sachsen-Anhalt
Mähren;;56459;0641;Rheinland-Pfalz
Mähring;, Oberpf;95695;09639;Bayern
Maierhof;b Biberach an der Riß;88441;07357;Baden-Württemberg
Maierhöfen;, Allgäu;88167;08383;Bayern
Maiermund;;56850;06543;Rheinland-Pfalz
Maihingen;;86747;09087;Bayern
Maihof;, Pfalz;67317;06356;Rheinland-Pfalz
Maikammer;;67487;06321;Rheinland-Pfalz
Mailham;b Gars Bahnhof;83555;08073;Bayern
Mainaschaff;;63814;06021;Bayern
Mainbernheim;;97350;09323;Bayern
Mainburg;;84048;08751;Bayern
Mainhardt;;74535;07903;Baden-Württemberg
Mainhausen;;63533;06182;Hessen
Mainleus;;95336;09229;Bayern
Mainstockheim;;97320;09321;Bayern
Maintal;;63477;06181;Hessen
Mainz;a Rhein;55116;06131;Rheinland-Pfalz
Mainz;a Rhein;55118;06131;Rheinland-Pfalz
Mainz;a Rhein;55120;06131;Rheinland-Pfalz
Mainz;a Rhein;55122;06131;Rheinland-Pfalz
Mainz;a Rhein;55124;06131;Rheinland-Pfalz
Mainz;a Rhein;55126;06131;Rheinland-Pfalz
Mainz;a Rhein;55127;06131;Rheinland-Pfalz
Mainz;a Rhein;55128;06131;Rheinland-Pfalz
Mainz;a Rhein;55129;06131;Rheinland-Pfalz
Mainz;a Rhein;55130;06131;Rheinland-Pfalz
Mainz;a Rhein;55131;06131;Rheinland-Pfalz
Mainz-Kastel;;55252;06134;Hessen
Mainz-Kastel;;55252;06134;Hessen
Mainz-Kostheim;;55246;06134;Hessen
Mainz-Kostheim;;55246;06134;Hessen
Mairing;;94501;08549;Bayern
Mais;b St Georgen, Chiemgau;83368;08669;Bayern
Mais;, Gem Amerang;83552;08075;Bayern
Maisach;, Oberbay;82216;08141;Bayern
Maisborn;;56291;06746;Rheinland-Pfalz
Maitenbeth;;83558;08076;Bayern
Maitzborn;;55481;06763;Rheinland-Pfalz
Makenhof;;71672;07144;Baden-Württemberg
Makenhof;;71672;07144;Baden-Württemberg
Malberg;, Eifel;54655;06563;Rheinland-Pfalz
Malberg;, Westerw;57629;02747;Rheinland-Pfalz
Malbergweich;;54655;06563;Rheinland-Pfalz
Malborn;;54426;06504;Rheinland-Pfalz
Malchin;;17139;03994;Mecklenburg-Vorpommern
Malching;, Gem Maisach, Oberbay;82216;08142;Bayern
Malching;, Niederbay;94094;08573;Bayern
Malchow;, Meckl;17213;039932;Mecklenburg-Vorpommern
Malente;;23714;04523;Schlewig-Holstein
Malgersdorf;;84333;09954;Bayern
Malitschkendorf;;04936;035361;Brandenburg
Malk;;19294;038755;Mecklenburg-Vorpommern
Malleichen;;88167;08383;Bayern
Mallentin;;23936;038824;Mecklenburg-Vorpommern
Mallersdorf-Pfaffenberg;;84066;08772;Bayern
Mallin;;17217;03962;Mecklenburg-Vorpommern
Malliß;;19294;038750;Mecklenburg-Vorpommern
Mallnow;;15326;033602;Brandenburg
Malsburg-Marzell;;79429;07626;Baden-Württemberg
Malsch;b Wiesloch;69254;07253;Baden-Württemberg
Malsch;, Kr Karlsruhe;76316;07246;Baden-Württemberg
Malschwitz;;02694;035932;Sachsen
Malse;, Forsthaus;27432;;Niedersachsen
Malsfeld;;34323;05661;Hessen
Malter;;01744;03504;Sachsen
Malterdingen;;79364;07644;Baden-Württemberg
Malterhausen;;14913;033741;Brandenburg
Maltitz;b Döbeln;04720;034325;Sachsen
Malz;;16515;03301;Brandenburg
Malzow;;23923;038828;Mecklenburg-Vorpommern
Mamerow;;18279;038452;Mecklenburg-Vorpommern
Mammelzen;;57636;02681;Rheinland-Pfalz
Mammendorf;, Oberbay;82291;08145;Bayern
Mamming;;94437;09955;Bayern
Manching;;85077;08459;Bayern
Mandel;;55595;0671;Rheinland-Pfalz
Mandelbachtal;;66399;06804;Saarland
Mandelshagen;;18184;038202;Mecklenburg-Vorpommern
Mandern;b Hermeskeil;54429;06589;Rheinland-Pfalz
Manderscheid;, Eifel;54531;06572;Rheinland-Pfalz
Manderscheid;b Waxweiler;54649;06554;Rheinland-Pfalz
Manebach;;98693;03677;Thüringen
Mangelsdorf;, Jerichower Land;39524;039323;Sachsen-Anhalt
Manhagen;b Lensahn;23738;04363;Schlewig-Holstein
Manker;;16845;033928;Brandenburg
Mankmoos;;19417;038482;Mecklenburg-Vorpommern
Mankmuß;;19357;038797;Brandenburg
Mannebach;b Saarburg, Saar;54441;06581;Rheinland-Pfalz
Mannebach;, Eifel;56769;02657;Rheinland-Pfalz
Mannhausen;b Haldensleben;39359;039059;Sachsen-Anhalt
Mannheim;;68159;0621;Baden-Württemberg
Mannheim;;68161;0621;Baden-Württemberg
Mannheim;;68163;0621;Baden-Württemberg
Mannheim;;68165;0621;Baden-Württemberg
Mannheim;;68167;0621;Baden-Württemberg
Mannheim;;68169;0621;Baden-Württemberg
Mannheim;;68199;0621;Baden-Württemberg
Mannheim;;68219;0621;Baden-Württemberg
Mannheim;;68229;0621;Baden-Württemberg
Mannheim;;68239;0621;Baden-Württemberg
Mannheim;;68259;0621;Baden-Württemberg
Mannheim;;68305;0621;Baden-Württemberg
Mannheim;;68307;0621;Baden-Württemberg
Mannheim;;68309;0621;Baden-Württemberg
Mannstedt;;99628;036373;Thüringen
Mannweiler-Cölln;;67822;06362;Rheinland-Pfalz
Manschnow;;15328;033474;Brandenburg
Mansfeld;, Südharz;06343;034782;Sachsen-Anhalt
Mantel;b Weiden, Oberpf;92708;09605;Bayern
Manubach;;55413;06743;Rheinland-Pfalz
Marbach;, Odenwald;64743;06068;Hessen
Marbach;am Neckar;71672;07144;Baden-Württemberg
Marburg;;35037;06421;Hessen
Marburg;;35039;06421;Hessen
Marburg;;35041;06421;Hessen
Marburg;;35043;06421;Hessen
March;, Breisgau;79232;07665;Baden-Württemberg
Margarethenthal;;19348;;Brandenburg
Margetshöchheim;;97276;0931;Bayern
Marggraffshof;;14532;;Brandenburg
Maria Laach;, Eifel;56653;02652;Rheinland-Pfalz
Maria Martental;, Kloster;56759;;Rheinland-Pfalz
Mariaposching;;94553;09906;Bayern
Marienberg;, Erzgeb;09496;03735;Sachsen
Marienborn;, Börde;39365;0341;Sachsen-Anhalt
Marieney;;08626;037464;Sachsen
Marienfeld;, Stadt Harsewinkel;33428;05247;Nordrhein-Westfalen
Marienfels;;56357;06772;Rheinland-Pfalz
Mariengrund;;07381;;Thüringen
Marienhafe;;26529;04934;Niedersachsen
Marienhagen;b Alfeld, Leine;31094;05185;Niedersachsen
Marienhausen;;56269;02689;Rheinland-Pfalz
Marienheide;;51709;02264;Nordrhein-Westfalen
Marienhof;b Ludwigslust;19300;;Mecklenburg-Vorpommern
Marienhof;, Schlei;24399;;Schlewig-Holstein
Marienmünster;;37696;05276;Nordrhein-Westfalen
Marienrachdorf;;56242;02626;Rheinland-Pfalz
Marienstatt;, Kloster;57629;02662;Rheinland-Pfalz
Marienstedt;, Waldhaus b Klein Zecher;23883;;Schlewig-Holstein
Mariental;b Helmstedt;38368;05356;Niedersachsen
Marienthal;b Gransee;16775;033080;Brandenburg
Marienthal;, Vorpommern;17309;039748;Mecklenburg-Vorpommern
Marienthal;b Gadebusch;19205;038876;Mecklenburg-Vorpommern
Marienthal;b Bad Neuenahr-Ahrweiler;53507;02641;Rheinland-Pfalz
Marienthal;, Westerw;57612;02682;Rheinland-Pfalz
Marienthal;b Erfurt;99192;;Thüringen
Marienthal;b Erfurt;99192;;Thüringen
Marienwalde;;16348;;Brandenburg
Marienwerder;b Bernau b Berlin;16348;03335;Brandenburg
Marihn;;17219;03962;Mecklenburg-Vorpommern
Maring-Noviand;;54484;06535;Rheinland-Pfalz
Marisfeld;;98530;036846;Thüringen
Markau;b Nauen;14641;03321;Brandenburg
Markdorf;, Baden;88677;07544;Baden-Württemberg
Marke;, Anh;06779;034906;Sachsen-Anhalt
Markee;;14641;03321;Brandenburg
Markendorf;b Jüterbog;14913;03372;Brandenburg
Markersbach;;08352;03774;Sachsen
Markersdorf;b Görlitz, Neiße;02829;035829;Sachsen
Markertsgrün;;97514;09529;Bayern
Markgrafpieske;;15528;033633;Brandenburg
Markgröningen;;71706;07145;Baden-Württemberg
Märkisch Buchholz;;15748;033765;Brandenburg
Märkisch Wilmersdorf;;14974;033731;Brandenburg
Markkleeberg;;04416;0341;Sachsen
Marklkofen;;84163;08732;Bayern
Marklohe;;31608;05021;Niedersachsen
Markneukirchen;;08258;037422;Sachsen
Markranstädt;;04420;034205;Sachsen
Markritz;;04720;034325;Sachsen
Marksuhl;;99819;036925;Thüringen
Markt Berolzheim;;91801;09146;Bayern
Markt Bibart;;91477;09162;Bayern
Markt Einersheim;;97348;09326;Bayern
Markt Erlbach;;91459;09106;Bayern
Markt Indersdorf;;85229;08136;Bayern
Markt Nordheim;;91478;09842;Bayern
Markt Rettenbach;;87733;08392;Bayern
Markt Schwaben;;85570;08121;Bayern
Markt Taschendorf;;91480;09552;Bayern
Markt Wald;;86865;08262;Bayern
Marktbergel;;91613;09843;Bayern
Marktbreit;;97340;09332;Bayern
Marktgölitz;;07330;036736;Thüringen
Marktgraitz;;96257;09574;Bayern
Marktheidenfeld;;97828;09391;Bayern
Marktl;, Inn;84533;08678;Bayern
Marktleugast;;95352;09255;Bayern
Marktleuthen;;95168;09285;Bayern
Marktoberdorf;;87616;08342;Bayern
Marktoffingen;;86748;09087;Bayern
Marktredwitz;;95615;09231;Bayern
Marktrodach;;96364;09261;Bayern
Marktschellenberg;;83487;08650;Bayern
Marktschorgast;;95509;09227;Bayern
Marktsteft;;97342;09332;Bayern
Marktzeuln;;96275;09574;Bayern
Markvippach;;99195;036371;Thüringen
Markwerben;;06667;03443;Sachsen-Anhalt
Marl;, Westf;45768;02365;Nordrhein-Westfalen
Marl;, Westf;45770;02365;Nordrhein-Westfalen
Marl;, Westf;45772;02365;Nordrhein-Westfalen
Marl;, Dümmer;49448;05443;Niedersachsen
Marloffstein;;91080;09131;Bayern
Marlow;b Ribnitz-Damgarten;18337;038221;Mecklenburg-Vorpommern
Marne;, Holst;25709;04851;Schlewig-Holstein
Marnerdeich;;25709;04851;Schlewig-Holstein
Marnheim;, Pfalz;67297;06352;Rheinland-Pfalz
Marnitz;;19376;038729;Mecklenburg-Vorpommern
Maroldsweisach;;96126;09532;Bayern
Marolterode;;99994;036021;Thüringen
Maroth;, Westerw;56271;02689;Rheinland-Pfalz
Marpingen;;66646;06853;Saarland
Marquardt;;14476;033208;Brandenburg
Marquartstein;;83250;08641;Bayern
Marquede;;14715;03386;Brandenburg
Marsberg;, Sauerland;34431;02992;Nordrhein-Westfalen
Marschacht;;21436;04176;Niedersachsen
Marsow;;19260;038848;Mecklenburg-Vorpommern
Martensdorf;b Wismar, Meckl;23972;03841;Mecklenburg-Vorpommern
Martensrade;;24238;04384;Schlewig-Holstein
Martfeld;;27327;04255;Niedersachsen
Marth;b Uder;37318;036083;Thüringen
Martinroda;b Vacha;36404;036963;Thüringen
Martinroda;b Ilmenau;98693;03677;Thüringen
Martinsheim;;97340;09332;Bayern
Martinshöhe;, Pfalz;66894;06372;Rheinland-Pfalz
Martinskirchen;, Elbe;04895;035342;Brandenburg
Martinsrieth;;06528;03464;Sachsen-Anhalt
Martinstein;;55627;06754;Rheinland-Pfalz
Marwitz;;16727;03304;Brandenburg
Marxdorf;b Falkenberg, Elster;04924;035365;Brandenburg
Marxdorf;b Seelow;15306;033477;Brandenburg
Marxen;, Auetal;21439;04185;Niedersachsen
Marxheim;;86688;09007;Bayern
Marxzell;;76359;07248;Baden-Württemberg
Marzahna;;14913;033747;Brandenburg
Marzahne;;14778;033834;Brandenburg
Marzauermühle;;57644;02680;Rheinland-Pfalz
Marzhausen;, Westerw;57627;02688;Rheinland-Pfalz
Marzling;;85417;08161;Bayern
Masburg;;56761;02653;Rheinland-Pfalz
Maschenhorst;;39340;039059;Sachsen-Anhalt
Maselheim;;88437;07351;Baden-Württemberg
Maßbach;;97711;09735;Bayern
Massen;b Finsterwalde;03238;03531;Brandenburg
Massenbachhausen;;74252;07138;Baden-Württemberg
Massenhausen;, Oberbay;85376;08165;Bayern
Massenhausen;b Hildburghausen;98646;03685;Thüringen
Masserberg;;98666;036870;Thüringen
Massing;im Rottal;84323;08724;Bayern
Maßlow;;23972;03841;Mecklenburg-Vorpommern
Massow;;17209;039925;Mecklenburg-Vorpommern
Maßweiler;;66506;06334;Rheinland-Pfalz
Mastershausen;;56869;06545;Rheinland-Pfalz
Masthorn;;54597;06556;Rheinland-Pfalz
Matersen;;18239;038207;Mecklenburg-Vorpommern
Matgendorf;;17168;039976;Mecklenburg-Vorpommern
Mattendorf;;03159;035695;Brandenburg
Mattstedt;;99510;03644;Thüringen
Matzenbach;, Pfalz;66909;06383;Rheinland-Pfalz
Matzerath;, Eifel;54597;06556;Rheinland-Pfalz
Matzlow-Garwitz;;19372;038726;Mecklenburg-Vorpommern
Maua;;07751;03641;Thüringen
Maua;;07751;03641;Thüringen
Mauchenheim;;67294;06352;Rheinland-Pfalz
Mauden;;57520;02743;Rheinland-Pfalz
Mauderode;;99735;036332;Thüringen
Mauel;b Waxweiler;54649;06554;Rheinland-Pfalz
Mauer;, Baden;69256;06226;Baden-Württemberg
Mauern;, Kr Freising;85419;08764;Bayern
Mauerreuten;;83364;08666;Bayern
Mauerstetten;b Kaufbeuren;87665;08341;Bayern
Maulbeerwalde;;16909;033962;Brandenburg
Maulbronn;;75433;07043;Baden-Württemberg
Maulburg;;79689;07622;Baden-Württemberg
Mauschbach;;66500;06338;Rheinland-Pfalz
Mauth;, Niederbay;94151;08557;Bayern
Mauthaus;, Oberfr;96349;09267;Bayern
Mautitz;;01594;03525;Sachsen
Mautitz;;01594;03525;Sachsen
Maxberg;;91807;09145;Bayern
Maxdorf;, Pfalz;67133;06237;Rheinland-Pfalz
Maxhütte-Haidhof;;93142;09471;Bayern
Maxsain;;56244;02626;Rheinland-Pfalz
Mayen;;56727;02651;Rheinland-Pfalz
Mayschoß;;53508;02643;Rheinland-Pfalz
Mechau;;29416;039036;Sachsen-Anhalt
Mechelgrün;;08541;037463;Sachsen
Mechelroda;;99441;036453;Thüringen
Mechelsdorf;;18230;038296;Mecklenburg-Vorpommern
Mechernich;;53894;02443;Nordrhein-Westfalen
Mechow;b Ratzeburg;23909;04541;Schlewig-Holstein
Mechtersen;;21358;0451;Niedersachsen
Mechterstädt;;99880;03622;Thüringen
Meckel;;54636;06568;Rheinland-Pfalz
Meckenbach;b Kirn, Nahe;55606;06752;Rheinland-Pfalz
Meckenbach;, Kr Birkenfeld, Nahe;55767;06782;Rheinland-Pfalz
Meckenbeuren;;88074;07542;Baden-Württemberg
Meckenbeuren;;88074;07542;Baden-Württemberg
Meckenheim;, Rheinl;53340;02225;Nordrhein-Westfalen
Meckenheim;, Pfalz;67149;06326;Rheinland-Pfalz
Meckesheim;;74909;06226;Baden-Württemberg
Mecklinge;;49459;05447;Niedersachsen
Medard;;67744;06382;Rheinland-Pfalz
Meddersheim;;55566;06751;Rheinland-Pfalz
Meddewade;;23847;04531;Schlewig-Holstein
Medebach;;59964;02982;Nordrhein-Westfalen
Medelby;;24994;04605;Schlewig-Holstein
Medewitz;b Belzig;14827;033849;Brandenburg
Medewitzerhütten;;14827;033849;Brandenburg
Medlingen;;89441;09073;Bayern
Medow;b Anklam;17391;039728;Mecklenburg-Vorpommern
Meeder;;96484;09566;Bayern
Meerane;;08393;03764;Sachsen
Meerbeck;b Stadthagen;31715;05721;Niedersachsen
Meerbusch;;40667;02150;Nordrhein-Westfalen
Meerbusch;;40668;02150;Nordrhein-Westfalen
Meerbusch;;40670;02150;Nordrhein-Westfalen
Meerfeld;;54531;06572;Rheinland-Pfalz
Meersburg;, Bodensee;88709;07532;Baden-Württemberg
Meesiger;;17111;039994;Mecklenburg-Vorpommern
Meetzen;;19205;03886;Mecklenburg-Vorpommern
Meezen;;24594;04877;Schlewig-Holstein
Megesheim;;86750;09093;Bayern
Meggerdorf;;24799;04339;Schlewig-Holstein
Mehlbach;, Pfalz;67735;06301;Rheinland-Pfalz
Mehlbek;;25588;04827;Schlewig-Holstein
Mehlingen;;67678;06303;Rheinland-Pfalz
Mehlmeisel;;95694;09272;Bayern
Mehlsdorf;;15936;035451;Brandenburg
Mehltheuer;, Vogtl;08539;037431;Sachsen
Mehmels;;98634;036941;Thüringen
Mehmke;;29413;039007;Sachsen-Anhalt
Mehna;;04626;034495;Thüringen
Mehren;, Kr Daun;54552;06592;Rheinland-Pfalz
Mehren;, Westerw;57635;02686;Rheinland-Pfalz
Mehring;, Mosel;54346;06502;Rheinland-Pfalz
Mehring;, Kr Altötting;84561;08677;Bayern
Mehringen;b Aschersleben, Sachs-Anh;06456;03473;Sachsen-Anhalt
Mehrow;;16356;033394;Brandenburg
Mehrstedt;;99994;036021;Thüringen
Mehrstetten;b Münsingen, Württ;72537;07381;Baden-Württemberg
Meichow;;17291;039861;Brandenburg
Meiersberg;b Ueckermünde;17375;039778;Mecklenburg-Vorpommern
Meierstorf;b Parchim;19372;038721;Mecklenburg-Vorpommern
Meierstorf;b Grevesmühlen;23936;03841;Mecklenburg-Vorpommern
Meilbrück;;54636;06561;Rheinland-Pfalz
Meilendorf;;06386;034977;Sachsen-Anhalt
Meilitz;b Neustadt, Orla;07806;036481;Thüringen
Meinborn;;56584;02639;Rheinland-Pfalz
Meine;;38527;05304;Niedersachsen
Meinersen;;38536;05372;Niedersachsen
Meinerzhagen;;58540;02354;Nordrhein-Westfalen
Meineweh;;06721;034425;Sachsen-Anhalt
Meinhard;b Eschwege;37276;05651;Hessen
Meinheim;;91802;09146;Bayern
Meiningen;;98617;03693;Thüringen
Meinsdorf;b Jüterbog;14913;033744;Brandenburg
Meisburg;;54570;06599;Rheinland-Pfalz
Meisdorf;;06463;034743;Sachsen-Anhalt
Meisenheim;, Glan;55590;06753;Rheinland-Pfalz
Meißen;, Sachs;01662;03521;Sachsen
Meißenheim;, Baden;77974;07824;Baden-Württemberg
Meißner;b Eschwege;37290;05657;Hessen
Meitingen;;86405;08271;Bayern
Meitzendorf;;39326;0391;Sachsen-Anhalt
Melbeck;;21406;04134;Niedersachsen
Melchhof;;54538;06532;Rheinland-Pfalz
Melchow;;16230;03337;Brandenburg
Meldorf;;25704;04832;Schlewig-Holstein
Melkof;;19273;038848;Mecklenburg-Vorpommern
Melle;, Wiehengeb;49324;05422;Niedersachsen
Melle;, Wiehengeb;49326;05422;Niedersachsen
Melle;, Wiehengeb;49328;05422;Niedersachsen
Mellen;b Lenzen, Elbe;19309;038781;Brandenburg
Mellenbach-Glasbach;;98746;036705;Thüringen
Mellensee;;15806;03377;Brandenburg
Mellenthin;;17429;038379;Mecklenburg-Vorpommern
Mellin;;38489;039007;Sachsen-Anhalt
Mellingen;;99441;036453;Thüringen
Mellinghausen;;27249;04272;Niedersachsen
Mellnitz;b Jessen, Elster;06918;035387;Sachsen-Anhalt
Mellnsdorf;;14913;033743;Brandenburg
Mellrichstadt;;97638;09776;Bayern
Melpers;;98634;036946;Thüringen
Melsbach;;56581;02634;Rheinland-Pfalz
Melsdorf;;24109;04340;Schlewig-Holstein
Melsungen;;34212;05661;Hessen
Meltewitz;;04808;034262;Sachsen
Melusinental;;21493;04151;Schlewig-Holstein
Melz;;17209;039923;Mecklenburg-Vorpommern
Memleben;;06642;0361;Sachsen-Anhalt
Memmelsdorf;;96117;0951;Bayern
Memmingen;;87700;08331;Bayern
Memmingerberg;;87766;08331;Bayern
Menden;(Sauerland);58706;02373;Nordrhein-Westfalen
Menden;(Sauerland);58708;02373;Nordrhein-Westfalen
Menden;(Sauerland);58710;02373;Nordrhein-Westfalen
Mendhausen;;98631;036948;Thüringen
Mendig;;56743;02652;Rheinland-Pfalz
Mengelrode;;37308;03606;Thüringen
Mengen;, Württ;88512;07572;Baden-Württemberg
Mengerschied;;55490;06765;Rheinland-Pfalz
Mengersgereuth-Hämmern;;96529;03675;Thüringen
Mengerskirchen;;35794;06476;Hessen
Mengkofen;;84152;08733;Bayern
Menglers;;36219;05650;Hessen
Menkin;;17326;039742;Brandenburg
Menningen;a d Sauer;54310;06525;Rheinland-Pfalz
Menslage;;49637;05437;Niedersachsen
Menteroda;;99996;036029;Thüringen
Mentin;;19376;038729;Mecklenburg-Vorpommern
Menz;b Gransee;16775;033082;Brandenburg
Menz;b Magdeburg;39175;039292;Sachsen-Anhalt
Menzendorf;b Schönberg, Meckl;23923;038828;Mecklenburg-Vorpommern
Meppen;;49716;05931;Niedersachsen
Merbelsrod;;98673;036878;Thüringen
Merching;;86504;08233;Bayern
Merchweiler;;66589;06825;Saarland
Merdingen;;79291;07668;Baden-Württemberg
Merenberg;;35799;06471;Hessen
Mering;, Schwab;86415;08233;Bayern
Merkelbach;, Westerw;57629;02662;Rheinland-Pfalz
Merkendorf;, Thür;07950;036626;Thüringen
Merkendorf;, Mittelfr;91732;09826;Bayern
Merkers;;36460;036969;Thüringen
Merkeshausen;;54636;;Rheinland-Pfalz
Merklingen;, Alb;89188;07337;Baden-Württemberg
Merlscheid;;54597;06556;Rheinland-Pfalz
Mermuth;;56283;06745;Rheinland-Pfalz
Merschbach;;54426;06504;Rheinland-Pfalz
Merseburg;;06217;03461;Sachsen-Anhalt
Mertendorf;b Naumburg, Saale;06618;03445;Sachsen-Anhalt
Mertendorf;b Eisenberg, Thür;07619;036694;Thüringen
Mertesdorf;;54318;0651;Rheinland-Pfalz
Mertesheim;;67271;06359;Rheinland-Pfalz
Mertingen;;86690;09006;Bayern
Mertloch;;56753;02654;Rheinland-Pfalz
Merxheim;, Nahe;55627;06754;Rheinland-Pfalz
Merxleben;;99947;03603;Thüringen
Merz;;15848;03366;Brandenburg
Merzalben;;66978;06395;Rheinland-Pfalz
Merzdorf;b Elsterwerda;04932;03533;Brandenburg
Merzdorf;b Jüterbog;14913;033745;Brandenburg
Merzen;;49586;05466;Niedersachsen
Merzenich;, Kr Düren;52399;02421;Nordrhein-Westfalen
Merzhausen;, Breisgau;79249;0761;Baden-Württemberg
Merzien;;06369;03496;Sachsen-Anhalt
Merzig;;66663;06861;Saarland
Merzkirchen;;54439;06581;Rheinland-Pfalz
Merzweiler;;67746;06788;Rheinland-Pfalz
Meschede;;59872;0291;Nordrhein-Westfalen
Meschendorf;;18230;038296;Mecklenburg-Vorpommern
Meschendorf Zeltplatz;;18230;038296;Mecklenburg-Vorpommern
Mescherin;;16307;033332;Brandenburg
Meseberg;b Gransee;16775;03306;Brandenburg
Meseberg;b Wolmirstedt;39326;039202;Sachsen-Anhalt
Meseberg;, Altmark;39606;03937;Sachsen-Anhalt
Mesekenhagen;;17498;038351;Mecklenburg-Vorpommern
Mesekow;;19357;038797;Brandenburg
Mesendorf;;16928;03395;Brandenburg
Mesenich;b Cochem;56820;02673;Rheinland-Pfalz
Mespelbrunn;;63875;06092;Bayern
Meßdorf;;39624;039083;Sachsen-Anhalt
Meßdunk;;14778;033835;Brandenburg
Messel;;64409;06159;Hessen
Messel;;64409;06159;Hessen
Messenkamp;;31867;05043;Niedersachsen
Messerich;;54636;06568;Rheinland-Pfalz
Messersbacherhof;;67724;06363;Rheinland-Pfalz
Messingen;;49832;05906;Niedersachsen
Meßkirch;;88605;07575;Baden-Württemberg
Meßstetten;;72469;07436;Baden-Württemberg
Mestlin;;19374;038727;Mecklenburg-Vorpommern
Metebach;;99880;036254;Thüringen
Metelen;;48629;02556;Nordrhein-Westfalen
Metelsdorf;;23972;03841;Mecklenburg-Vorpommern
Metschow;;17111;039994;Mecklenburg-Vorpommern
Metten;, Donau;94526;0991;Bayern
Mettendorf;, Eifel;54675;06522;Rheinland-Pfalz
Mettenheim;, Rheinhess;67582;06242;Rheinland-Pfalz
Mettenheim;, Kr Mühldorf a Inn;84562;08631;Bayern
Metterich;;54634;06565;Rheinland-Pfalz
Mettingen;, Westf;49497;05452;Nordrhein-Westfalen
Mettlach;;66693;06864;Saarland
Mettmann;;40822;02104;Nordrhein-Westfalen
Mettweiler;;55777;06783;Rheinland-Pfalz
Metzels;;98639;03693;Thüringen
Metzenhausen;;55481;06763;Rheinland-Pfalz
Metzingen;, Württ;72555;07123;Baden-Württemberg
Meudt;;56414;06435;Rheinland-Pfalz
Meura;;98744;036701;Thüringen
Meuro;, NL;01994;035754;Brandenburg
Meuro;b Lutherstadt Wittenberg;06905;034925;Sachsen-Anhalt
Meuschau;;06217;03461;Sachsen-Anhalt
Meusebach;;07646;036428;Thüringen
Meusegast;;01809;035027;Sachsen
Meuselbach-Schwarzmühle;;98746;036705;Thüringen
Meuselwitz;, Thür;04610;03448;Thüringen
Meuselwitz;b Grimma;04680;03448;Sachsen
Meuspath;;53520;02691;Rheinland-Pfalz
Mewegen;b Pasewalk;17322;039744;Mecklenburg-Vorpommern
Meyenburg;, Prignitz;16945;033968;Brandenburg
Meyn;;24980;04639;Schlewig-Holstein
Michaelsdorf;b Barth;18356;038231;Mecklenburg-Vorpommern
Michelau;, Oberfr;96247;09571;Bayern
Michelau;i. Steigerwald;97513;09382;Bayern
Michelbach;, Hunsrück;56288;06761;Rheinland-Pfalz
Michelbach;(Westerwald);57610;02681;Rheinland-Pfalz
Michelbach an der Bilz;;74544;0791;Baden-Württemberg
Michelbacher Mühle;;35094;;Hessen
Michelbacher Mühle;;35094;;Hessen
Michelfeld;, Kr Schwäbisch Hall;74545;0791;Baden-Württemberg
Michelhölzle;;78554;;Baden-Württemberg
Micheln;;06386;03496;Sachsen-Anhalt
Michelsdorf;b Lehnin;14797;03382;Brandenburg
Michelsneukirchen;;93185;09467;Bayern
Michelstadt;;64720;06061;Hessen
Michendorf;;14552;033205;Brandenburg
Mickhausen;;86866;08204;Bayern
Middelhagen;, Rügen;18586;038308;Mecklenburg-Vorpommern
Midlum;, Föhr;25938;04681;Schlewig-Holstein
Midlum;b Bremerhaven;27632;04741;Niedersachsen
Miehlen;, Taunus;56357;06772;Rheinland-Pfalz
Miekenhagen;;18239;038295;Mecklenburg-Vorpommern
Mielberg;, Gem Jagel;24848;04624;Schlewig-Holstein
Miele;;29303;05054;Niedersachsen
Mielkendorf;;24247;04347;Schlewig-Holstein
Miellen;;56132;02603;Rheinland-Pfalz
Miersdorfer Werder;;15751;03375;Brandenburg
Miesbach;;83714;08025;Bayern
Miesitz;;07819;036482;Thüringen
Mieste;;39649;039082;Sachsen-Anhalt
Miesterhorst;;39649;039006;Sachsen-Anhalt
Mieswarz;;36404;036967;Thüringen
Mietingen;;88487;0751;Baden-Württemberg
Mihla;;99826;036924;Thüringen
Milbitz b Rottenbach;;07422;036739;Thüringen
Milbitz b Teichel;;07407;036743;Thüringen
Milda;;07751;036422;Thüringen
Mildenau;;09456;03733;Sachsen
Mildenberg;b Gransee;16775;03307;Brandenburg
Mildenitz;;17348;03963;Mecklenburg-Vorpommern
Mildstedt;;25866;04861;Schlewig-Holstein
Mildterhof;;25840;04881;Schlewig-Holstein
Millienhagen;;18461;038322;Mecklenburg-Vorpommern
Milmersdorf;;17268;039886;Brandenburg
Milow;b Rathenow;14715;03386;Brandenburg
Milow;b Ludwigslust;19300;038781;Mecklenburg-Vorpommern
Miltach;;93468;09944;Bayern
Miltenberg;;63897;09371;Bayern
Miltern;;39590;039322;Sachsen-Anhalt
Miltzow;;18519;038328;Mecklenburg-Vorpommern
Milz;;98631;036948;Thüringen
Milzau;;06246;034635;Sachsen-Anhalt
Mimbach;b Boos, Eifel;56729;02656;Rheinland-Pfalz
Mindelheim;;87719;08261;Bayern
Mindelstetten;;93349;08404;Bayern
Minden;, Westf;32423;0571;Nordrhein-Westfalen
Minden;, Westf;32425;0571;Nordrhein-Westfalen
Minden;, Westf;32427;0571;Nordrhein-Westfalen
Minden;, Westf;32429;0571;Nordrhein-Westfalen
Minden;, Sauer;54310;06525;Rheinland-Pfalz
Minderleinsmühle;;91077;09126;Bayern
Minderlittgen;;54518;06571;Rheinland-Pfalz
Minfeld;;76872;07275;Rheinland-Pfalz
Minheim;, Mosel;54518;06507;Rheinland-Pfalz
Minkwitz;b Zeitz, Elster;06729;034424;Sachsen-Anhalt
Mintraching;, Kr Regensburg;93098;09406;Bayern
Minzow;;17209;039922;Mecklenburg-Vorpommern
Mirow;, Meckl;17252;039833;Mecklenburg-Vorpommern
Misselberg;;56377;02604;Rheinland-Pfalz
Misselwarden;;27632;04742;Niedersachsen
Missen;, NL;03205;035436;Brandenburg
Missen-Wilhams;;87547;08320;Bayern
Mistelbach;, Oberfr;95511;09201;Bayern
Mistelgau;;95490;09279;Bayern
Mistorf;;18276;038453;Mecklenburg-Vorpommern
Mittelbach;b Chemnitz, Sachs;09224;0371;Sachsen
Mittelbach;b Chemnitz, Sachs;09224;0371;Sachsen
Mittelbiberach;;88441;07357;Baden-Württemberg
Mittelbrunn;;66851;06371;Rheinland-Pfalz
Mitteleschenbach;;91734;09871;Bayern
Mittelfischbach;, Rhein-Lahn-Kr;56370;06486;Rheinland-Pfalz
Mittelfischbach;, Gem Großerlach;71560;07193;Baden-Württemberg
Mittelhausen;b Sangerhausen;06542;034652;Sachsen-Anhalt
Mittelhausen;, Thür;99195;0361;Thüringen
Mittelhausen;, Thür;99195;0361;Thüringen
Mittelherwigsdorf;;02763;03583;Sachsen
Mittelhof;, Sieg;57537;02742;Rheinland-Pfalz
Mittelmühle;, Württ;74597;07957;Baden-Württemberg
Mittelndorf;;01855;035022;Sachsen
Mittelneufnach;;86868;08262;Bayern
Mittelnkirchen;;21720;04142;Niedersachsen
Mittelpöllnitz;;07819;036482;Thüringen
Mittelreidenbach;;55758;06784;Rheinland-Pfalz
Mittelschieda;, Gem Schwarzenbach a.d.Saale;95158;09284;Bayern
Mittelschmalkalden;;98590;03683;Thüringen
Mittelsinn;;97785;09356;Bayern
Mittelsömmern;;99955;036041;Thüringen
Mittelstenahe;;21770;04773;Niedersachsen
Mittelstetten;, Kr Fürstenfeldbruck;82293;08202;Bayern
Mittelstille;;98574;03683;Thüringen
Mittelstrimmig;;56858;06545;Rheinland-Pfalz
Mittenaar;;35756;02772;Hessen
Mittenwald;;82481;08823;Bayern
Mittenwalde;, Mark;15749;033764;Brandenburg
Mittenwalde;b Templin;17268;039887;Brandenburg
Mitterfels;;94360;09961;Bayern
Mittergars;;83559;08073;Bayern
Mitterleiten;;83364;08057;Bayern
Mitterskirchen;;84335;08725;Bayern
Mitterstatt;;83364;08662;Bayern
Mitterteich;;95666;09633;Bayern
Mittweida;;09648;03727;Sachsen
Mittweide;;15848;033674;Brandenburg
Mitwitz;;96268;09266;Bayern
Mixdorf;;15299;033655;Brandenburg
Mlode;;03205;03541;Brandenburg
Möbiskruge;;15890;033652;Brandenburg
Mochau;b Döbeln;04720;03431;Sachsen
Mochau;b Lutherstadt Wittenberg;06888;03491;Sachsen-Anhalt
Mochow;;15913;035478;Brandenburg
Möckern;b Stadtroda;07646;036428;Thüringen
Möckern;b Burg b Magdeburg;39291;039221;Sachsen-Anhalt
Möckers;;98590;03683;Thüringen
Möckmühl;;74219;06298;Baden-Württemberg
Mockrehna;;04838;034244;Sachsen
Modautal;;64397;06167;Hessen
Modenbacherhof;;76829;06323;Rheinland-Pfalz
Mödingen;;89426;09076;Bayern
Moers;;47441;02841;Nordrhein-Westfalen
Moers;;47443;02841;Nordrhein-Westfalen
Moers;;47445;02841;Nordrhein-Westfalen
Moers;;47447;02841;Nordrhein-Westfalen
Mögelin;;14715;03386;Brandenburg
Mogendorf;;56424;02623;Rheinland-Pfalz
Mögglingen;;73563;07174;Baden-Württemberg
Möglenz;;04931;035341;Brandenburg
Möglingen;, Kr Ludwigsburg, Württ;71696;07141;Baden-Württemberg
Möhlau;;06791;034953;Sachsen-Anhalt
Mohlsdorf;;07987;03661;Thüringen
Möhnesee;;59519;02924;Nordrhein-Westfalen
Möhnsen;;21493;04159;Schlewig-Holstein
Mohorn;;01723;035209;Sachsen
Möhrenbach;;98708;036783;Thüringen
Möhrendorf;;91096;09131;Bayern
Mohrenmühle;b Burgen, Mosel;56332;;Rheinland-Pfalz
Mohrkirch;;24405;04646;Schlewig-Holstein
Moidentin;;23966;03841;Mecklenburg-Vorpommern
Moisall;;18246;038464;Mecklenburg-Vorpommern
Moisburg;;21647;0451;Niedersachsen
Molau;;06618;0365;Sachsen-Anhalt
Molbergen;;49696;04475;Niedersachsen
Mölbis;;04579;034347;Sachsen
Molchow;;16827;03391;Brandenburg
Molfsee;;24113;04347;Schlewig-Holstein
Molkenberg;b Rathenow;14715;039389;Sachsen-Anhalt
Möllenbeck;b Neustrelitz;17237;039826;Mecklenburg-Vorpommern
Möllenbeck;b Ludwigslust;19300;038752;Mecklenburg-Vorpommern
Möllendorf;b Hettstedt, Sachs-Anh;06343;034782;Sachsen-Anhalt
Möllendorf;b Stendal;39579;039388;Sachsen-Anhalt
Möllenhagen;;17219;039928;Mecklenburg-Vorpommern
Möllensdorf;;06869;034903;Sachsen-Anhalt
Möllensee;;15345;033434;Brandenburg
Mollenstorf;;17217;03962;Mecklenburg-Vorpommern
Möllern;;06628;034463;Sachsen-Anhalt
Mölln;b Altentreptow;17091;039602;Mecklenburg-Vorpommern
Mölln;, Kr Hzgt Lauenb;23879;04542;Schlewig-Holstein
Mollnitz;;19348;038782;Brandenburg
Molmerswende;;06543;034779;Sachsen-Anhalt
Molsberg;, Westerw;56414;06435;Rheinland-Pfalz
Molschleben;;99869;036258;Thüringen
Mölschow;;17449;038377;Mecklenburg-Vorpommern
Molsdorf;;99192;0361;Thüringen
Molsdorf;;99192;0361;Thüringen
Mölsheim;;67591;06243;Rheinland-Pfalz
Moltenow;b Bützow;18246;038464;Mecklenburg-Vorpommern
Moltenow;b Schwerin, Meckl;19069;03867;Mecklenburg-Vorpommern
Moltkestein;;24797;04331;Schlewig-Holstein
Moltow;;23996;038423;Mecklenburg-Vorpommern
Moltzow;;17194;039953;Mecklenburg-Vorpommern
Molzhain;;57520;02747;Rheinland-Pfalz
Mömbris;;63776;06029;Bayern
Mömlingen;;63853;06022;Bayern
Mommenheim;, Rheinhess;55278;06138;Rheinland-Pfalz
Mönchberg;, Spessart;63933;09374;Bayern
Mönchengladbach;;41061;02161;Nordrhein-Westfalen
Mönchengladbach;;41063;02161;Nordrhein-Westfalen
Mönchengladbach;;41065;02161;Nordrhein-Westfalen
Mönchengladbach;;41066;02161;Nordrhein-Westfalen
Mönchengladbach;;41068;02161;Nordrhein-Westfalen
Mönchengladbach;;41069;02161;Nordrhein-Westfalen
Mönchengladbach;;41169;02161;Nordrhein-Westfalen
Mönchengladbach;;41179;02161;Nordrhein-Westfalen
Mönchengladbach;;41189;02161;Nordrhein-Westfalen
Mönchengladbach;;41199;02161;Nordrhein-Westfalen
Mönchengladbach;;41236;02161;Nordrhein-Westfalen
Mönchengladbach;;41238;02161;Nordrhein-Westfalen
Mönchengladbach;;41239;02161;Nordrhein-Westfalen
Mönchenhöfe;;06926;035389;Sachsen-Anhalt
Mönchenholzhausen;;99198;036203;Thüringen
Mönchhagen;;18182;038202;Mecklenburg-Vorpommern
Mönchpfiffel-Nikolausrieth;;06556;0345;Thüringen
Mönchsdeggingen;;86751;09088;Bayern
Mönchshof;b Bad Mergentheim;97980;;Baden-Württemberg
Mönchsroth;;91614;09853;Bayern
Mönchweiler;;78087;07721;Baden-Württemberg
Mönchwinkel;;15528;033632;Brandenburg
Monheim;am Rhein;40789;02173;Nordrhein-Westfalen
Monheim;, Schwab;86653;09091;Bayern
Mönkeberg;;24248;0431;Schlewig-Holstein
Mönkebude;;17375;039774;Mecklenburg-Vorpommern
Mönkhagen;;23619;04506;Schlewig-Holstein
Mönkloh;;24576;04192;Schlewig-Holstein
Monreal;, Eifel;56729;02651;Rheinland-Pfalz
Monschau;;52156;02472;Nordrhein-Westfalen
Monsheim;, Rheinhess;67590;06243;Rheinland-Pfalz
Mönsheim;;71297;07044;Baden-Württemberg
Monstab;;04617;034498;Thüringen
Montabaur;;56410;02602;Rheinland-Pfalz
Möntenich;;56754;02672;Rheinland-Pfalz
Montforterhof;;67826;06755;Rheinland-Pfalz
Monzelfeld;;54472;06531;Rheinland-Pfalz
Monzernheim;;55234;06244;Rheinland-Pfalz
Monzingen;;55569;06751;Rheinland-Pfalz
Moor;b Grevesmühlen;23948;038824;Mecklenburg-Vorpommern
Moordiek;b Westermoor, Holst;25597;04822;Schlewig-Holstein
Moordorf;b Westermoor;25597;04828;Schlewig-Holstein
Moorenweis;;82272;08146;Bayern
Moorgrund;;36433;03695;Thüringen
Moorhusen;b Wilster;25554;04823;Schlewig-Holstein
Moormerland;;26802;04954;Niedersachsen
Moorrege;b Uetersen;25436;04122;Schlewig-Holstein
Moorweg;;26427;04977;Niedersachsen
Moos;, Bodensee;78345;07732;Baden-Württemberg
Moos;, Oberbay;82390;;Bayern
Moos;b Irschenberg;83739;;Bayern
Moos;, Niederbay;94554;09938;Bayern
Moosach;b Grafing b München;85665;08091;Bayern
Moosbach;b Vohenstrauß;92709;09656;Bayern
Moosburg;a d Isar;85368;08761;Bayern
Moosburg;b Bad Buchau;88422;07582;Baden-Württemberg
Moosen;b Frauenneuharting;83565;08092;Bayern
Mooshäusl;b Weibhausen;83379;08681;Bayern
Moosinning;;85452;08123;Bayern
Moosmühle;, Oberbay;85376;08165;Bayern
Moospoint;b Weibhausen;83379;08681;Bayern
Moosthenning;;84164;08731;Bayern
Moosweber;;83739;08025;Bayern
Mooswiese;, Ostalb;73565;07176;Baden-Württemberg
Moraas;;19230;03883;Mecklenburg-Vorpommern
Morbach;, Hunsrück;54497;06533;Rheinland-Pfalz
Mörbach;;99735;036334;Thüringen
Mörel;b Hohenwestedt;24594;04871;Schlewig-Holstein
Morest;;23743;04366;Schlewig-Holstein
Mörfelden-Walldorf;;64546;06105;Hessen
Morgenitz;;17406;038372;Mecklenburg-Vorpommern
Morgenröthe-Rautenkranz;;08262;037465;Sachsen
Moringen;;37186;05554;Niedersachsen
Möringen;;39599;039329;Sachsen-Anhalt
Moritz;b Zerbst;39264;039247;Sachsen-Anhalt
Moritzburg;b Dresden;01468;035207;Sachsen
Moritzheim;;56865;06545;Rheinland-Pfalz
Morl;;06193;034606;Sachsen-Anhalt
Mörlen;;57583;02661;Rheinland-Pfalz
Mörlenbach;;69509;06209;Hessen
Mörnsheim;;91804;09145;Bayern
Morsbach;, Sieg;51597;02294;Nordrhein-Westfalen
Mörsbach;, Westerw;57629;02688;Rheinland-Pfalz
Mörschbach;;55494;06764;Rheinland-Pfalz
Morscheid;, Ruwer;54317;06500;Rheinland-Pfalz
Morschen;;34326;05664;Hessen
Morschheim;;67294;06352;Rheinland-Pfalz
Mörschied;;55758;06785;Rheinland-Pfalz
Mörsdorf;b Stadtroda;07646;036428;Thüringen
Mörsdorf;, Hunsrück;56290;06762;Rheinland-Pfalz
Mörsfeld;;67808;06358;Rheinland-Pfalz
Morshausen;;56283;02605;Rheinland-Pfalz
Morsleben;;39343;039050;Sachsen-Anhalt
Mörstadt;;67591;06247;Rheinland-Pfalz
Morsum;, Kr Verden, Aller;27321;04204;Niedersachsen
Mörtitz;;04838;03423;Sachsen
Morungen;;06528;03464;Sachsen-Anhalt
Morxdorf;;06918;035387;Sachsen-Anhalt
Mörz;b Belzig;14806;033843;Brandenburg
Mosbach;, Baden;74821;06261;Baden-Württemberg
Mosbruch;;56767;02692;Rheinland-Pfalz
Moschheim;;56424;02602;Rheinland-Pfalz
Möschwitz;;08543;037439;Sachsen
Mose;;39326;039201;Sachsen-Anhalt
Moselkern;;56254;02672;Rheinland-Pfalz
Möser;;39291;039222;Sachsen-Anhalt
Mossautal;;64756;06062;Hessen
Moßbach;;07907;036648;Thüringen
Mössingen;;72116;07473;Baden-Württemberg
Mösthinsdorf;;06193;034600;Sachsen-Anhalt
Möthlitz;;14715;033877;Brandenburg
Möthlow;;14715;033876;Brandenburg
Motrich;;19322;038794;Brandenburg
Motten;;97786;09748;Bayern
Motterwitz;;04668;034386;Sachsen
Möttingen;;86753;09083;Bayern
Motzen;b Mittenwalde, Mark;15741;033769;Brandenburg
Mötzing;;93099;09480;Bayern
Mötzingen;;71159;07452;Baden-Württemberg
Motzlar;;36419;036967;Thüringen
Mötzow;;14778;033836;Brandenburg
Moxa;;07381;036483;Thüringen
Mözen;;23795;04551;Schlewig-Holstein
Much;;53804;02245;Nordrhein-Westfalen
Mucheln;;24238;04383;Schlewig-Holstein
Mücheln;(Geiseltal);06249;034636;Sachsen-Anhalt
Muchelndorf;, Gem Neuenbrook;25361;04824;Schlewig-Holstein
Muchow;;19300;038752;Mecklenburg-Vorpommern
Mücka;;02906;035893;Sachsen
Mücke;;35325;06400;Hessen
Mückeln;;54558;06574;Rheinland-Pfalz
Mückendorf;;15837;033704;Brandenburg
Mückenfang;;23974;;Mecklenburg-Vorpommern
Mückenhof;;67685;06374;Rheinland-Pfalz
Mückenmühle;;67685;06374;Rheinland-Pfalz
Muckwar;;03229;035434;Brandenburg
Mudau;;69427;06284;Baden-Württemberg
Müden;(Aller);38539;05375;Niedersachsen
Müden;(Mosel);56254;02672;Rheinland-Pfalz
Mudenbach;;57614;02688;Rheinland-Pfalz
Mudersbach;, Sieg;57555;02745;Rheinland-Pfalz
Mudershausen;;65623;06430;Rheinland-Pfalz
Mügeln;b Oschatz;04769;034362;Sachsen
Mügeln;b Jessen, Elster;06928;035384;Sachsen-Anhalt
Müggendorf;b Wittenberge, Prignitz;19322;038794;Brandenburg
Muggensturm;;76461;07222;Baden-Württemberg
Muggerkuhl;;19348;038785;Brandenburg
Müglitz;;01778;035054;Sachsen
Müglitztal;;01809;035027;Sachsen
Mühbrook;;24582;04322;Schlewig-Holstein
Mühl Rosin;;18276;03843;Mecklenburg-Vorpommern
Mühlacker;;75417;07041;Baden-Württemberg
Mühlanger;;06888;034922;Sachsen-Anhalt
Mühlau;b Chemnitz, Sachs;09241;03722;Sachsen
Mühlbeck;;06774;03493;Sachsen-Anhalt
Mühlberg;, Elbe;04931;035342;Brandenburg
Mühlberg;b Gotha, Thür;99869;036256;Thüringen
Mühlbusch;;67816;;Rheinland-Pfalz
Mühldorf;a. Inn;84453;08631;Bayern
Mühlen Eichsen;;19205;038871;Mecklenburg-Vorpommern
Mühlenbach;, Baden;77796;07832;Baden-Württemberg
Mühlenbarbek;;25548;04822;Schlewig-Holstein
Mühlenbeck;b Oranienburg;16567;033056;Brandenburg
Mühlenbeck;b Schwerin, Meckl;19075;038850;Mecklenburg-Vorpommern
Mühlenrade;;21493;04159;Schlewig-Holstein
Mühlenthal;, Sieg;57587;02742;Rheinland-Pfalz
Mühlhausen;, Kraichgau;69242;06222;Baden-Württemberg
Mühlhausen;im Täle;73347;07335;Baden-Württemberg
Mühlhausen;, Oberpf;92360;09185;Bayern
Mühlhausen;, Mittelfr;96172;09548;Bayern
Mühlhausen;/Thüringen;99974;03601;Thüringen
Mühlhausen-Ehingen;;78259;07733;Baden-Württemberg
Mühlheim;am Main;63165;06108;Hessen
Mühlheim;an der Donau;78570;07463;Baden-Württemberg
Mühlholz;b Abtsgmünd;73453;;Baden-Württemberg
Mühlhölzle;;73574;;Baden-Württemberg
Mühlingen;, Baden;78357;07775;Baden-Württemberg
Mühlpfad;;56291;06746;Rheinland-Pfalz
Mühlpoint;;83364;08666;Bayern
Mühlscheid;b Hermeskeil;54427;;Rheinland-Pfalz
Mühlstedt;;06862;034901;Sachsen-Anhalt
Mühltal;, Hess;64367;06151;Hessen
Mühltroff;;07919;036645;Sachsen
Mühlwand;;08468;03765;Sachsen
Muhr;a. See;91735;09831;Bayern
Mülbach;, Eifel;54636;06527;Rheinland-Pfalz
Mulda;;09619;037320;Sachsen
Muldenstein;;06804;03493;Sachsen-Anhalt
Mulfingen;, Ostalbkr;73575;07175;Baden-Württemberg
Mulfingen;, Jagst;74673;07938;Baden-Württemberg
Mülheim;an der Ruhr;45468;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45470;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45472;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45473;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45475;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45476;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45478;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45479;0208;Nordrhein-Westfalen
Mülheim;an der Ruhr;45481;0208;Nordrhein-Westfalen
Mülheim;(Mosel);54486;06534;Rheinland-Pfalz
Mülheim-Kärlich;;56218;02630;Rheinland-Pfalz
Müllenbach;b Adenau;53520;02692;Rheinland-Pfalz
Müllenbach;b Mayen;56761;02653;Rheinland-Pfalz
Müllheim;, Baden;79379;07631;Baden-Württemberg
Müllrose;;15299;033606;Brandenburg
Mülsen;;08132;037601;Sachsen
Mulsum;b Bremerhaven;27632;04742;Niedersachsen
Mülverstedt;;99947;036022;Thüringen
Münchberg;, Oberfr;95213;09251;Bayern
Müncheberg;;15374;033432;Brandenburg
Münchehofe;b Königs Wusterhausen;15748;033760;Brandenburg
Münchehofe, Mark;;15366;03342;Brandenburg
München;;80331;089;Bayern
München;;80333;089;Bayern
München;;80335;089;Bayern
München;;80336;089;Bayern
München;;80337;089;Bayern
München;;80339;089;Bayern
München;;80469;089;Bayern
München;;80538;089;Bayern
München;;80539;089;Bayern
München;;80634;089;Bayern
München;;80636;089;Bayern
München;;80637;089;Bayern
München;;80638;089;Bayern
München;;80639;089;Bayern
München;;80686;089;Bayern
München;;80687;089;Bayern
München;;80689;089;Bayern
München;;80796;089;Bayern
München;;80797;089;Bayern
München;;80798;089;Bayern
München;;80799;089;Bayern
München;;80801;089;Bayern
München;;80802;089;Bayern
München;;80803;089;Bayern
München;;80804;089;Bayern
München;;80805;089;Bayern
München;;80807;089;Bayern
München;;80809;089;Bayern
München;;80933;089;Bayern
München;;80935;089;Bayern
München;;80937;089;Bayern
München;;80939;089;Bayern
München;;80992;089;Bayern
München;;80993;089;Bayern
München;;80995;089;Bayern
München;;80997;089;Bayern
München;;80999;089;Bayern
München;;81241;089;Bayern
München;;81243;089;Bayern
München;;81245;089;Bayern
München;;81247;089;Bayern
München;;81249;089;Bayern
München;;81369;089;Bayern
München;;81371;089;Bayern
München;;81373;089;Bayern
München;;81375;089;Bayern
München;;81377;089;Bayern
München;;81379;089;Bayern
München;;81475;089;Bayern
München;;81476;089;Bayern
München;;81477;089;Bayern
München;;81479;089;Bayern
München;;81539;089;Bayern
München;;81541;089;Bayern
München;;81543;089;Bayern
München;;81545;089;Bayern
München;;81547;089;Bayern
München;;81549;089;Bayern
München;;81667;089;Bayern
München;;81669;089;Bayern
München;;81671;089;Bayern
München;;81673;089;Bayern
München;;81675;089;Bayern
München;;81677;089;Bayern
München;;81679;089;Bayern
München;;81735;089;Bayern
München;;81737;089;Bayern
München;;81739;089;Bayern
München;;81825;089;Bayern
München;;81827;089;Bayern
München;;81829;089;Bayern
München;;81925;089;Bayern
München;;81927;089;Bayern
München;;81929;089;Bayern
Münchenbernsdorf;;07589;036604;Thüringen
München-Flughafen;;85356;0811;Bayern
München-Flughafen;;85356;0811;Bayern
Münchengosserstädt;;99510;036421;Thüringen
Münchenlohra;;99759;036338;Thüringen
Münchenroda;;07751;03641;Thüringen
Münchenroda;;07751;03641;Thüringen
Münchhausen;, NL;03238;035323;Brandenburg
Münchhausen;am Christenberg;35117;06457;Hessen
Münchhofe;;15868;033671;Brandenburg
Münchsmünster;;85126;08402;Bayern
Münchsteinach;;91481;09166;Bayern
Münchwald;;55595;06706;Rheinland-Pfalz
Münchweiler;an der Rodalb;66981;06395;Rheinland-Pfalz
Münchweiler;an der Alsenz;67728;06302;Rheinland-Pfalz
Münchweiler;am Klingbach;76857;06346;Rheinland-Pfalz
Mundelsheim;;74395;07143;Baden-Württemberg
Munderkingen;;89597;07393;Baden-Württemberg
Mündersbach;;56271;02680;Rheinland-Pfalz
Mundhardterhof;;67098;06322;Rheinland-Pfalz
Münk;;56729;02656;Rheinland-Pfalz
Munkbrarup;;24960;04631;Schlewig-Holstein
Münnerstadt;;97702;09733;Bayern
Munningen;;86754;09092;Bayern
Munschwitz;;07338;036734;Thüringen
Münsing;, Starnberger See;82541;08177;Bayern
Münsingen;, Württ;72525;07381;Baden-Württemberg
Munster;, Örtze;29633;05192;Niedersachsen
Münster;, Westf;48143;0251;Nordrhein-Westfalen
Münster;, Westf;48145;0251;Nordrhein-Westfalen
Münster;, Westf;48147;0251;Nordrhein-Westfalen
Münster;, Westf;48149;0251;Nordrhein-Westfalen
Münster;, Westf;48151;0251;Nordrhein-Westfalen
Münster;, Westf;48153;0251;Nordrhein-Westfalen
Münster;, Westf;48155;0251;Nordrhein-Westfalen
Münster;, Westf;48157;0251;Nordrhein-Westfalen
Münster;, Westf;48159;0251;Nordrhein-Westfalen
Münster;, Westf;48161;0251;Nordrhein-Westfalen
Münster;, Westf;48163;0251;Nordrhein-Westfalen
Münster;, Westf;48165;0251;Nordrhein-Westfalen
Münster;, Westf;48167;0251;Nordrhein-Westfalen
Münster;b Dieburg;64839;06071;Hessen
Münster;, Lech;86692;08276;Bayern
Münsterappel;;67822;06362;Rheinland-Pfalz
Münsterdorf;;25587;04828;Schlewig-Holstein
Münsterhausen;;86505;08281;Bayern
Münstermaifeld;;56294;02605;Rheinland-Pfalz
Münster-Sarmsheim;;55424;06721;Rheinland-Pfalz
Münsterschwarzach Abtei;;97359;09324;Bayern
Münstertal;, Schwarzwald;79244;07636;Baden-Württemberg
Münzenberg;;35516;06033;Hessen
Mupperg;;96524;036761;Thüringen
Murchin;;17390;03971;Mecklenburg-Vorpommern
Murg;, Baden;79730;07763;Baden-Württemberg
Mürlenbach;;54570;06594;Rheinland-Pfalz
Murnau;a. Staffelsee;82418;08841;Bayern
Mürow;;16278;033335;Brandenburg
Mürowsche Kavelheide;, Gem Mürow;16306;033336;Brandenburg
Murr;, Württ;71711;07144;Baden-Württemberg
Murrhardt;;71540;07192;Baden-Württemberg
Müsch;b Adenau;53533;02693;Rheinland-Pfalz
Muschau;b Grimma;04668;034386;Sachsen
Müschen;, NL;03096;035603;Brandenburg
Müschenbach;;57629;02662;Rheinland-Pfalz
Muschwitz;;06679;034441;Sachsen-Anhalt
Müsselmow;;19412;038486;Mecklenburg-Vorpommern
Müssen;, Kr Hzgt Lauenb;21516;04155;Schlewig-Holstein
Mustin;b Sternberg;19406;038485;Mecklenburg-Vorpommern
Mustin;b Ratzeburg;23911;04546;Schlewig-Holstein
Musweiler;;54534;06575;Rheinland-Pfalz
Mutlangen;;73557;07171;Baden-Württemberg
Muttenhaus;;88373;07505;Baden-Württemberg
Mutterschied;;55469;06761;Rheinland-Pfalz
Mutterstadt;;67112;06234;Rheinland-Pfalz
Mützdorf;;14823;033848;Brandenburg
Mützel;;39307;03933;Sachsen-Anhalt
Mützenich;b Prüm;54608;06555;Rheinland-Pfalz
Mützlitz;;14715;033878;Brandenburg
Mutzschen;;04688;034385;Sachsen
Mutzschwitz;;01623;035246;Sachsen
Muxerath;;54673;06564;Rheinland-Pfalz
Mylau;;08499;03765;Sachsen
Nabburg;;92507;09433;Bayern
Nachrodt-Wiblingwerde;;58769;02352;Nordrhein-Westfalen
Nachrodt-Wiblingwerde;;58769;02352;Nordrhein-Westfalen
Nächst Neuendorf;;15806;03377;Brandenburg
Nachterstedt;;06469;034741;Sachsen-Anhalt
Nachtsheim;;56729;02656;Rheinland-Pfalz
Nack;, Rheinhess;55234;06736;Rheinland-Pfalz
Nackel;;16845;033978;Brandenburg
Nackenheim;;55299;06135;Rheinland-Pfalz
Nackterhof;;67311;06351;Rheinland-Pfalz
Nadrensee;;17329;039746;Mecklenburg-Vorpommern
Nagel;, Oberfr;95697;09236;Bayern
Nägelstedt;;99958;036042;Thüringen
Nagold;;72202;07452;Baden-Württemberg
Nagoldtal;;75399;07452;Baden-Württemberg
Nahe;;23866;04535;Schlewig-Holstein
Näherhof;, Gem Süßen;73054;;Baden-Württemberg
Nahmitz;;14797;03382;Brandenburg
Nahrendorf;;21369;05855;Niedersachsen
Nahrstedt;;39599;03931;Sachsen-Anhalt
Nahwinden;;99326;03629;Thüringen
Naila;;95119;09282;Bayern
Nakenstorf;;23992;038422;Mecklenburg-Vorpommern
Nalbach;;66809;06838;Saarland
Namborn;;66640;06857;Saarland
Nandlstadt;;85405;08756;Bayern
Nannhausen;;55469;06761;Rheinland-Pfalz
Nanzdietschweiler;;66909;06383;Rheinland-Pfalz
Narsdorf;;04657;034346;Sachsen
Naschendorf;;23936;03881;Mecklenburg-Vorpommern
Nasingen;;54673;06564;Rheinland-Pfalz
Nassau;, Lahn;56377;02604;Rheinland-Pfalz
Nasseböhla;;01561;03522;Sachsen
Nassenfels;;85128;08424;Bayern
Nassenheide;;16515;033051;Brandenburg
Naßkamping;;94565;08541;Bayern
Nastätten;;56355;06772;Rheinland-Pfalz
Natendorf;;29587;05806;Niedersachsen
Nattenheim;;54636;06569;Rheinland-Pfalz
Nattheim;;89564;07321;Baden-Württemberg
Nauberg;;04668;034386;Sachsen
Naudin;;23996;038424;Mecklenburg-Vorpommern
Nauen;, Havelland;14641;03321;Brandenburg
Nauendorf;b Halle, Saale;06193;034603;Sachsen-Anhalt
Nauendorf;b Kranichfeld;99448;036209;Thüringen
Naugarten;;17291;039852;Brandenburg
Nauheim;, Kr Groß-Gerau;64569;06152;Hessen
Naumburg;(Saale);06618;03445;Sachsen-Anhalt
Naumburg;, Hess;34311;05625;Hessen
Naundorf;b Bischofswerda;01877;035930;Sachsen
Naundorf;b Vetschau;03226;035433;Brandenburg
Naundorf;b Altenburg, Thür;04617;034495;Thüringen
Naundorf;b Oschatz;04769;034362;Sachsen
Naundorf;b Schlieben;04936;035361;Brandenburg
Naundorf;bei Seyda;06918;035387;Sachsen-Anhalt
Naunheim;, Maifeld;56753;0261;Rheinland-Pfalz
Naunhof;b Grimma;04683;034293;Sachsen
Nauort;;56237;02601;Rheinland-Pfalz
Naurath;(Eifel);54340;06508;Rheinland-Pfalz
Naurath;(Wald);54426;06509;Rheinland-Pfalz
Nauroth;, Westerw;57583;02747;Rheinland-Pfalz
Nausiß;;99631;036374;Thüringen
Nausitz;;06571;03466;Thüringen
Nausnitz;;07616;036692;Thüringen
Nauwalde;;01609;035263;Sachsen
Nazza;;99826;036924;Thüringen
Nebel;, Amrum;25946;04863;Schlewig-Holstein
Nebelin;;19348;038793;Brandenburg
Nebelschütz;;01920;03578;Sachsen
Nebra;(Unstrut);06642;034461;Sachsen-Anhalt
Neckarbischofsheim;;74924;07263;Baden-Württemberg
Neckargemünd;;69151;06223;Baden-Württemberg
Neckargerach;;69437;06263;Baden-Württemberg
Neckarsteinach;;69239;06229;Hessen
Neckarsulm;;74172;07132;Baden-Württemberg
Neckartailfingen;;72666;07127;Baden-Württemberg
Neckartenzlingen;;72654;07127;Baden-Württemberg
Neckarwestheim;;74382;07133;Baden-Württemberg
Neckarzimmern;;74865;06261;Baden-Württemberg
Neddemin;;17039;0395;Mecklenburg-Vorpommern
Nedderhagen;;23948;;Mecklenburg-Vorpommern
Nedlitz;b Zerbst;39264;039243;Sachsen-Anhalt
Nedlitz;b Burg b Magdeburg;39291;039224;Sachsen-Anhalt
Neef;;56858;06542;Rheinland-Pfalz
Neehausen;;06295;034773;Sachsen-Anhalt
Neenstetten;;89189;07340;Baden-Württemberg
Neese;;19300;038756;Mecklenburg-Vorpommern
Neese Ausbau;;19300;038756;Mecklenburg-Vorpommern
Neetze;, Kr Lüneburg;21398;05850;Niedersachsen
Neetzka;;17349;03966;Mecklenburg-Vorpommern
Neetzow;;17391;039721;Mecklenburg-Vorpommern
Negenborn;b Holzminden;37643;05532;Niedersachsen
Negenharrie;;24625;04322;Schlewig-Holstein
Negernbötel;;23795;04551;Schlewig-Holstein
Nehlitz;;06193;034606;Sachsen-Anhalt
Nehms;;23813;04557;Schlewig-Holstein
Nehmten;;24326;04526;Schlewig-Holstein
Nehren;, Mosel;56820;02673;Rheinland-Pfalz
Nehren;, Württ;72147;07473;Baden-Württemberg
Neichen;, Kr Daun;54552;02692;Rheinland-Pfalz
Neidenbach;;54657;06563;Rheinland-Pfalz
Neidenberga;;07338;036737;Thüringen
Neidenfels;, Pfalz;67468;06325;Rheinland-Pfalz
Neidenstein;, Elsenzgau;74933;07263;Baden-Württemberg
Neidhardtsthal;, Stadt Eibenstock;08318;037752;Sachsen
Neidhartshausen;;36452;036964;Thüringen
Neidhof;, Eifel;54538;06532;Rheinland-Pfalz
Neidlingen;;73272;07023;Baden-Württemberg
Neindorf;b Oschersleben;39387;03949;Sachsen-Anhalt
Neinstedt;;06502;03947;Sachsen-Anhalt
Neißeaue;;02829;035820;Sachsen
Neitersen;, Westerw;57638;02681;Rheinland-Pfalz
Nellingen;, Alb;89191;07337;Baden-Württemberg
Nempitz;;06231;03462;Sachsen-Anhalt
Nemsdorf-Göhrendorf;;06268;034771;Sachsen-Anhalt
Nenndorf;, Ostfriesl;26556;04975;Niedersachsen
Nennhausen;;14715;033878;Brandenburg
Nennslingen;;91790;09147;Bayern
Nentershausen;, Hess;36214;06627;Hessen
Nentershausen;, Westerw;56412;06485;Rheinland-Pfalz
Neperstorf;;19417;038484;Mecklenburg-Vorpommern
Neppermin;;17429;038379;Mecklenburg-Vorpommern
Nepperwitz;;04828;03425;Sachsen
Nerchau;;04685;034382;Sachsen
Nerdin;;17391;039727;Mecklenburg-Vorpommern
Nerdlen;;54552;06592;Rheinland-Pfalz
Nerenstetten;;89129;07345;Baden-Württemberg
Neresheim;;73450;07326;Baden-Württemberg
Neritz;b Bad Oldesloe;23843;04531;Schlewig-Holstein
Nermsdorf;;99439;036451;Thüringen
Neroth;;54570;06591;Rheinland-Pfalz
Nersingen;;89278;07308;Bayern
Nerzweiler;;67749;06304;Rheinland-Pfalz
Neschholz;;14806;033841;Brandenburg
Neschow;;19217;038873;Mecklenburg-Vorpommern
Neschwitz;;02699;035933;Sachsen
Nesow;;19217;038872;Mecklenburg-Vorpommern
Nessa;;06682;034443;Sachsen-Anhalt
Nesselwang;;87484;08361;Bayern
Netphen;;57250;02738;Nordrhein-Westfalen
Nettelsee;;24250;04302;Schlewig-Holstein
Nettemühle;b Mayen;56753;;Rheinland-Pfalz
Nettersheim;;53947;02486;Nordrhein-Westfalen
Nettetal;;41334;02153;Nordrhein-Westfalen
Nettgau;;38489;039003;Sachsen-Anhalt
Netzbach;;65623;06430;Rheinland-Pfalz
Netzeband;b Neuruppin;16818;033924;Brandenburg
Netzen;;14797;03382;Brandenburg
Netzow;b Glöwen;19339;038787;Brandenburg
Netzschkau;;08491;03765;Sachsen
Neu Babst;;23992;;Mecklenburg-Vorpommern
Neu Bartelshagen;;18442;038321;Mecklenburg-Vorpommern
Neu Bleckede;;19273;038844;Niedersachsen
Neu Bochow;;14550;033207;Brandenburg
Neu Boltenhagen;;17509;038352;Mecklenburg-Vorpommern
Neu Darchau;;29490;05853;Niedersachsen
Neu Degtow;;23936;03881;Mecklenburg-Vorpommern
Neu Dessau;;14715;;Brandenburg
Neu Duvenstedt;;24794;04331;Schlewig-Holstein
Neu Duvenstedt-Nord;;24791;04338;Schlewig-Holstein
Neu Fahrland;;14476;0331;Brandenburg
Neu Fahrland;;14476;0331;Brandenburg
Neu Falkenrehde;;14641;033233;Brandenburg
Neu Gaarz;b Waren;17194;039933;Mecklenburg-Vorpommern
Neu Gaarz;b Ostseebad Rerik;18230;038296;Mecklenburg-Vorpommern
Neu Garge;;19273;038841;Niedersachsen
Neu Glienicke;;16818;033929;Brandenburg
Neu Göhren;;19294;038755;Mecklenburg-Vorpommern
Neu Golm;;15526;033631;Brandenburg
Neu Gülze;;19258;038847;Mecklenburg-Vorpommern
Neu Heinde;;17168;039976;Mecklenburg-Vorpommern
Neu Hohenfelde;;18209;038203;Mecklenburg-Vorpommern
Neu Hohenvier;;19348;038789;Brandenburg
Neu Jabel;;19303;038759;Mecklenburg-Vorpommern
Neu Käbelich;;17348;03966;Mecklenburg-Vorpommern
Neu Kaliß;;19294;038758;Mecklenburg-Vorpommern
Neu Karin;;18236;038297;Mecklenburg-Vorpommern
Neu Käterhagen;;18246;038462;Mecklenburg-Vorpommern
Neu Kemnitz;;16928;03395;Brandenburg
Neu Königsaue;;06449;034741;Sachsen-Anhalt
Neu Kosenow;;17398;039726;Mecklenburg-Vorpommern
Neu Krüssow;;16928;033989;Brandenburg
Neu Lübbenau;;15910;035473;Brandenburg
Neu Lüblow;;19288;038753;Mecklenburg-Vorpommern
Neu Lübtheen;;19249;038855;Mecklenburg-Vorpommern
Neu Lutterow;;16837;033923;Brandenburg
Neu Mahlisch;;15306;033470;Brandenburg
Neu Necheln;;19412;038483;Mecklenburg-Vorpommern
Neu Pastin;;19406;03847;Mecklenburg-Vorpommern
Neu Pennewitt;;19417;;Mecklenburg-Vorpommern
Neu Pinnow;;19357;038788;Brandenburg
Neu Plötzin;;14542;03327;Brandenburg
Neu Poorstorf;;23992;;Mecklenburg-Vorpommern
Neu Poserin;;19399;038736;Mecklenburg-Vorpommern
Neu Premslin;;19357;038797;Brandenburg
Neu Redlin;;16928;033969;Brandenburg
Neu Rethwisch;;18211;;Mecklenburg-Vorpommern
Neu Runstedt;;38822;03941;Sachsen-Anhalt
Neu Ruthenbeck;;19374;038723;Mecklenburg-Vorpommern
Neu Saunstorf;;23996;038424;Mecklenburg-Vorpommern
Neu Schlagsdorf;;19067;03866;Mecklenburg-Vorpommern
Neu Schloen;, Meckl;17192;039934;Mecklenburg-Vorpommern
Neu Stieten;;23966;03841;Mecklenburg-Vorpommern
Neu Thurow;;19205;038876;Mecklenburg-Vorpommern
Neu Viecheln;;23996;038423;Mecklenburg-Vorpommern
Neu Virneburg;;56729;;Rheinland-Pfalz
Neu Vitense;;19217;038872;Mecklenburg-Vorpommern
Neu Weitendorf;;23968;038428;Mecklenburg-Vorpommern
Neu Wendischthun;;19273;038844;Niedersachsen
Neu Woserin;;19406;038485;Mecklenburg-Vorpommern
Neu Wulmstorf;, Niederelbe;21629;04168;Niedersachsen
Neu Zachun;;19230;038859;Mecklenburg-Vorpommern
Neu Zauche;;15913;035475;Brandenburg
Neu Zittau;;15537;03362;Brandenburg
Neualbenreuth;;95698;09638;Bayern
Neu-Anspach;;61267;06081;Hessen
Neuasbach;;89522;07321;Baden-Württemberg
Neu-Bamberg;;55546;06703;Rheinland-Pfalz
Neubarnim;;15324;033452;Brandenburg
Neubau;b Rockenhausen;67808;06361;Rheinland-Pfalz
Neubensdorf;;14789;033839;Brandenburg
Neuberend;;24879;04621;Schlewig-Holstein
Neuberg;, Hess;63543;06185;Hessen
Neubeuern;;83115;08035;Bayern
Neubiberg;;85579;089;Bayern
Neubodenbach;;01683;035242;Sachsen
Neubörger;;26909;04966;Niedersachsen
Neubrandenburg;, Meckl;17033;0395;Mecklenburg-Vorpommern
Neubrandenburg;, Meckl;17034;0395;Mecklenburg-Vorpommern
Neubrandenburg;, Meckl;17036;0395;Mecklenburg-Vorpommern
Neubraunshain;;04617;034498;Thüringen
Neubrück;(Spree);15848;033672;Brandenburg
Neubrunn;b Würzburg;97277;09307;Bayern
Neubrunn;b Meiningen;98617;036947;Thüringen
Neubukow;;18233;038294;Mecklenburg-Vorpommern
Neubulach;;75387;07053;Baden-Württemberg
Neuburg;am Rhein;76776;07273;Rheinland-Pfalz
Neuburg;a d Kammel;86476;08283;Bayern
Neuburg;a d Donau;86633;08283;Bayern
Neuburg;a. Inn;94127;08507;Bayern
Neuburg-Steinhausen;;23974;038426;Mecklenburg-Vorpommern
Neuburxdorf;;04931;035342;Brandenburg
Neuching;;85467;08123;Bayern
Neudeck;, Elster;04895;035365;Brandenburg
Neudenau;;74861;06264;Baden-Württemberg
Neu-Diesburgerhof;;54668;06523;Rheinland-Pfalz
Neudietendorf;;99192;036202;Thüringen
Neudorf;b Quedlinburg;06493;039484;Sachsen-Anhalt
Neudorf;b Pritzwalk;16928;03395;Brandenburg
Neudorf-Bornstein;;24214;04351;Schlewig-Holstein
Neudörfchen;b Bernau b Berlin;16348;;Brandenburg
Neudrossenfeld;;95512;09203;Bayern
Neue Mühle;b Saalfeld, Saale;07338;;Thüringen
Neue Mühle;b Büchen;21514;;Schlewig-Holstein
Neuehütte;;16230;03334;Brandenburg
Neuehütten;;14827;033849;Brandenburg
Neu-Eichenberg;;37249;05504;Hessen
Neuekrug;b Salzwedel;29413;03902;Sachsen-Anhalt
Neuenbau;;96515;03675;Thüringen
Neuenbeuthen;;07338;036737;Thüringen
Neuenbrook;, Holst;25578;04824;Schlewig-Holstein
Neuenburg;am Rhein;79395;07634;Baden-Württemberg
Neuenbürg;, Württ;75305;07082;Baden-Württemberg
Neuendeich;b Uetersen;25436;04122;Schlewig-Holstein
Neuendettelsau;;91564;09874;Bayern
Neuendorf;b Königs Wusterhausen;15755;033766;Brandenburg
Neuendorf;b Beeskow;15848;03366;Brandenburg
Neuendorf;b Oranienburg;16515;033051;Brandenburg
Neuendorf;b Neustadt, Dosse;16845;033973;Brandenburg
Neuendorf;b Bützow;18246;038461;Mecklenburg-Vorpommern
Neuendorf;b Ribnitz-Damgarten;18317;038223;Mecklenburg-Vorpommern
Neuendorf;b. Elmshorn;25335;04121;Schlewig-Holstein
Neuendorf;b. Wilster;25554;04823;Schlewig-Holstein
Neuendorf;b Leinefelde;37339;036071;Thüringen
Neuendorf;, Altmark;38486;03909;Sachsen-Anhalt
Neuendorf;, Eifel;54597;06552;Rheinland-Pfalz
Neuendorf;, Main;97788;09351;Bayern
Neuendorf A;b Torgelow, Vorpommern;17379;039777;Mecklenburg-Vorpommern
Neuendorf am Damm;;39624;039080;Sachsen-Anhalt
Neuendorf am See;;15910;035473;Brandenburg
Neuendorf B;;17391;039723;Mecklenburg-Vorpommern
Neuendorf b Brück;;14822;033844;Brandenburg
Neuendorf b Niemegk;;14823;033843;Brandenburg
Neuendorf Heide;;18317;038223;Mecklenburg-Vorpommern
Neuendorf im Sande;;15518;03361;Brandenburg
Neuendorf, Hiddensee;;18565;038300;Mecklenburg-Vorpommern
Neuengönna;;07778;036427;Thüringen
Neuengörs;;23818;04550;Schlewig-Holstein
Neuenhagen;b Berlin;15366;03342;Brandenburg
Neuenhagen;b Bad Freienwalde;16259;033369;Brandenburg
Neuenhagen;b Grevesmühlen;23942;038827;Mecklenburg-Vorpommern
Neuenhaus;, Dinkel;49828;05941;Niedersachsen
Neuenhofe;;39345;03904;Sachsen-Anhalt
Neuenkirchen;b Neubrandenburg;17039;0395;Mecklenburg-Vorpommern
Neuenkirchen;b Anklam;17392;039727;Mecklenburg-Vorpommern
Neuenkirchen;b Greifswald;17498;0395;Mecklenburg-Vorpommern
Neuenkirchen;, Rügen;18569;038309;Mecklenburg-Vorpommern
Neuenkirchen;b Zarrentin;19246;038853;Mecklenburg-Vorpommern
Neuenkirchen;b Horneburg, Niederelbe;21640;04163;Niedersachsen
Neuenkirchen;, Land Hadeln;21763;04751;Niedersachsen
Neuenkirchen;, Dithm;25792;04837;Schlewig-Holstein
Neuenkirchen;b Bassum;27251;04245;Niedersachsen
Neuenkirchen;, Lüneburger Heide;29643;05192;Niedersachsen
Neuenkirchen;, Kr Steinfurt;48485;05973;Nordrhein-Westfalen
Neuenkirchen;b Bramsche, Hase;49586;05465;Niedersachsen
Neuenkirchen-Vörden;;49434;05495;Niedersachsen
Neuenlübke;;18320;038225;Mecklenburg-Vorpommern
Neuenmarkt;;95339;09227;Bayern
Neuenrade;;58809;02392;Nordrhein-Westfalen
Neuenrode;b Hagenow;19230;038854;Mecklenburg-Vorpommern
Neuenrost;;18320;038225;Mecklenburg-Vorpommern
Neuensalz;;08541;03741;Sachsen
Neuenstadt;am Kocher;74196;07139;Baden-Württemberg
Neuenstein;, Hess;36286;06677;Hessen
Neuenstein;, Württ;74632;07942;Baden-Württemberg
Neuental;;34599;06693;Hessen
Neuenweg;;79691;07673;Baden-Württemberg
Neuerburg;, Eifel;54673;06564;Rheinland-Pfalz
Neuerkirch;;55471;06761;Rheinland-Pfalz
Neuermark-Lübars;;39524;039327;Sachsen-Anhalt
Neuerstadt;;06926;035384;Sachsen-Anhalt
Neues Wiesenhaus;;08309;;Sachsen
Neufahrn;, Niederbay;84088;08773;Bayern
Neufahrn;b. Freising;85375;08165;Bayern
Neufarn;;85646;08121;Bayern
Neufeld;, Dithm;25724;04851;Schlewig-Holstein
Neufelderkoog;;25724;04856;Schlewig-Holstein
Neufeldhof;;76767;07273;Rheinland-Pfalz
Neuferchau;;38486;039008;Sachsen-Anhalt
Neuffen;;72639;07025;Baden-Württemberg
Neufra;, Hohenz;72419;07577;Baden-Württemberg
Neufraunhofen;;84181;08742;Bayern
Neugattersleben;;06429;034721;Sachsen-Anhalt
Neugernsdorf;;07980;036625;Thüringen
Neugersdorf;, Sachs;02727;03586;Sachsen
Neugersdorf;b Bad Freienwalde;16259;033451;Brandenburg
Neuglobsow;;16775;033082;Brandenburg
Neugrimnitz;;16247;033361;Brandenburg
Neugüster;;21514;04158;Schlewig-Holstein
Neuhardenberg;;15320;033476;Brandenburg
Neuharlingersiel;;26427;04974;Niedersachsen
Neuhaus;b Sangerhausen;06528;034658;Sachsen-Anhalt
Neuhaus;, Elbe;19273;038841;Niedersachsen
Neuhaus;(Oste);21785;04752;Niedersachsen
Neuhaus;, Württ;78733;;Baden-Württemberg
Neuhaus;a d Pegnitz;91284;09156;Bayern
Neuhaus;a. Inn;94152;08503;Bayern
Neuhaus;am Rennweg;98724;03679;Thüringen
Neuhäusel;, Westerw;56335;02620;Rheinland-Pfalz
Neuhausen;, NL;03058;035605;Brandenburg
Neuhausen;/Erzgeb.;09544;037361;Sachsen
Neuhausen;b Pritzwalk;16928;03395;Brandenburg
Neuhausen;b Perleberg;19348;038785;Brandenburg
Neuhausen;auf den Fildern;73765;07158;Baden-Württemberg
Neuhausen;, Enzkr;75242;07234;Baden-Württemberg
Neuhausen;ob Eck;78579;07467;Baden-Württemberg
Neuhaus-Schierschnitz;;96524;036764;Thüringen
Neuheilenbach;;54597;06563;Rheinland-Pfalz
Neuheim;b Jüterbog;14913;03372;Brandenburg
Neuhemsbach;;67680;06303;Rheinland-Pfalz
Neuhof;b Zossen b Berlin;15838;033702;Brandenburg
Neuhof;b Pritzwalk;16928;03395;Brandenburg
Neuhof;b Bad Doberan;18209;038203;Mecklenburg-Vorpommern
Neuhof;b Hagenow;19246;038853;Mecklenburg-Vorpommern
Neuhof;b Perleberg;19357;038783;Brandenburg
Neuhof;b Sternberg;19417;038484;Mecklenburg-Vorpommern
Neuhof;, Holst;23669;04503;Schlewig-Holstein
Neuhof;b Neukloster;23992;038422;Mecklenburg-Vorpommern
Neuhof;b Wismar, Meckl;23996;;Mecklenburg-Vorpommern
Neuhof;b Lamspringe;31195;05183;Niedersachsen
Neuhof;, Kr Fulda;36119;06655;Hessen
Neuhof;b Züsch;54422;06782;Rheinland-Pfalz
Neuhof;b Kaisersesch;56759;02653;Rheinland-Pfalz
Neuhof;b Grünstadt;67316;;Rheinland-Pfalz
Neuhof;b Kaiserslautern;67705;06307;Rheinland-Pfalz
Neuhof;b Eppingen;75031;07262;Baden-Württemberg
Neuhof;a d Zenn;90616;09107;Bayern
Neuhof b Zinna;;14913;03372;Brandenburg
Neuhofen;, Pfalz;67141;06236;Rheinland-Pfalz
Neuholland;b Oranienburg;16515;033054;Brandenburg
Neuhütten;, Hunsrück;54422;06503;Rheinland-Pfalz
Neuhütten;, Unterfr;97843;06020;Bayern
Neu-Isenburg;;63263;06102;Hessen
Neu-Isenburg;;63263;06102;Hessen
Neukalen;;17154;039956;Mecklenburg-Vorpommern
Neukamperfehn;;26835;04946;Niedersachsen
Neu-Kentzlin;;17111;039952;Mecklenburg-Vorpommern
Neukieritzsch;;04575;034342;Sachsen
Neukietz b Wriezen;;16259;;Brandenburg
Neukirch;, Lausitz;01904;035951;Sachsen
Neukirch;b Königsbrück;01936;035795;Sachsen
Neukirch;b Tettnang;88099;07528;Baden-Württemberg
Neukirchen;/Pleiße;08459;03762;Sachsen
Neukirchen;/Erzgeb.;09221;0371;Sachsen
Neukirchen;b Freiberg, Sachs;09629;037324;Sachsen
Neukirchen;b Bützow;18246;038466;Mecklenburg-Vorpommern
Neukirchen;b Oldenburg in Holstein;23779;04365;Schlewig-Holstein
Neukirchen;b Niebüll;25927;04664;Schlewig-Holstein
Neukirchen;, Knüllgeb;34626;06694;Hessen
Neukirchen;(Altmark);39615;039396;Sachsen-Anhalt
Neukirchen;a Teisenberg;83364;08666;Bayern
Neukirchen;b Sulzbach-Rosenberg;92259;09663;Bayern
Neukirchen;b Hl Blut;93453;09947;Bayern
Neukirchen;vorm Wald;94154;08504;Bayern
Neukirchen;b Bogen, Niederbay;94362;09961;Bayern
Neukirchen-Adorf;, Erzgeb;09221;03721;Sachsen
Neukirchen-Balbini;;92445;09465;Bayern
Neukirchen-Vluyn;;47506;02845;Nordrhein-Westfalen
Neukirchen-Vluyn;;47506;02845;Nordrhein-Westfalen
Neukloster;, Meckl;23992;038422;Mecklenburg-Vorpommern
Neukünkendorf;;16278;03331;Brandenburg
Neuküstrinchen;;16259;033457;Brandenburg
Neukyhna;;04509;034954;Sachsen
Neulehe;;26909;04968;Niedersachsen
Neuleiningen;;67271;06359;Rheinland-Pfalz
Neuleiningen-Tal;;67271;06359;Rheinland-Pfalz
Neuler;;73491;07961;Baden-Württemberg
Neulewin;;16259;033452;Brandenburg
Neulietzegöricke;;16259;033457;Brandenburg
Neulingen;, Altmark;39615;039384;Sachsen-Anhalt
Neulingen;, Enzkr;75245;07237;Baden-Württemberg
Neulögow;;16775;033085;Brandenburg
Neulöwenberg;;16775;033094;Brandenburg
Neulußheim;;68809;06205;Baden-Württemberg
Neumädewitz;;16259;033456;Brandenburg
Neumagen-Dhron;;54347;06507;Rheinland-Pfalz
Neumark;, Sachs;08496;037600;Sachsen
Neumark;b Weimar, Thür;99439;036452;Thüringen
Neumarkt;, Oberpf;92318;09181;Bayern
Neumarkt-Sankt Veit;;84494;08639;Bayern
Neu-Maschine;;67466;;Rheinland-Pfalz
Neumolkenberg;;14715;;Sachsen-Anhalt
Neumühle;b Wurzbach;07343;036652;Thüringen
Neumühle;b Greiz;07980;03661;Thüringen
Neumühle;b Wismar, Meckl;23992;038422;Mecklenburg-Vorpommern
Neumühle;b Hütterscheid;54636;06527;Rheinland-Pfalz
Neumühle;b Fichtenau;74579;07962;Baden-Württemberg
Neumühle;, Queich;76857;06346;Rheinland-Pfalz
Neumühlen;, Kr Lüneburg;21379;05850;Niedersachsen
Neumünster;, Holst;24534;04321;Schlewig-Holstein
Neumünster;, Holst;24536;04321;Schlewig-Holstein
Neumünster;, Holst;24537;04321;Schlewig-Holstein
Neumünster;, Holst;24539;04321;Schlewig-Holstein
Neunburg;vorm Wald;92431;09672;Bayern
Neundorf;(bei Lobenstein);07356;036651;Thüringen
Neundorf;b Gera;07570;036651;Thüringen
Neundorf;b Schleiz;07924;03663;Thüringen
Neundorf;b Plauen;08527;03741;Sachsen
Neundorf;b Plauen;08527;03741;Sachsen
Neundorf;b Annaberg-Buchholz;09488;03733;Sachsen
Neundorf;(Anhalt);39418;03925;Sachsen-Anhalt
Neunhäuser;, Jagdhaus;54314;;Rheinland-Pfalz
Neunheilingen;;99947;036043;Thüringen
Neunkhausen;;57520;02661;Rheinland-Pfalz
Neunkirchen;, Hunsrück;54426;06504;Rheinland-Pfalz
Neunkirchen;, Westerw;56479;06436;Rheinland-Pfalz
Neunkirchen;, Siegerl;57290;02735;Nordrhein-Westfalen
Neunkirchen;, Unterfr;63930;09378;Bayern
Neunkirchen;, Saar;66538;06821;Saarland
Neunkirchen;, Saar;66539;06821;Saarland
Neunkirchen;, Saar;66540;06821;Saarland
Neunkirchen;am Potzberg;66887;06385;Rheinland-Pfalz
Neunkirchen;b Mosbach, Baden;74867;06262;Baden-Württemberg
Neunkirchen;a. Brand;91077;09134;Bayern
Neunkirchen;a. Sand;91233;09134;Bayern
Neunkirchen-Seelscheid;;53819;02247;Nordrhein-Westfalen
Neuötting;;84524;08671;Bayern
Neupetershain;;03103;035751;Brandenburg
Neuplatendorf;;06333;0391;Sachsen-Anhalt
Neupotz;;76777;07272;Rheinland-Pfalz
Neuradthal;;83739;08025;Bayern
Neureetz;;16259;033457;Brandenburg
Neureichenau;;94089;08583;Bayern
Neuried;, Ortenaukreis;77743;07807;Baden-Württemberg
Neuried;, Kr München;82061;089;Bayern
Neurochlitz;;16307;033333;Brandenburg
Neurüdnitz;;16259;033457;Brandenburg
Neuruppin;;16816;03391;Brandenburg
Neusalza-Spremberg;;02742;035872;Sachsen
Neusäß;;86356;0821;Bayern
Neuschlade;, Gem Racksen;57539;02682;Rheinland-Pfalz
Neuschollene;;14715;039389;Sachsen-Anhalt
Neuschönau;;94556;08558;Bayern
Neuschoo;;26487;04977;Niedersachsen
Neuseegalendorf;;23779;04361;Schlewig-Holstein
Neusiß;;99338;036207;Thüringen
Neusitz;, Mittelfr;91616;09861;Bayern
Neusorg;i Fichtelgeb;95700;09234;Bayern
Neuss;;41460;02131;Nordrhein-Westfalen
Neuss;;41462;02131;Nordrhein-Westfalen
Neuss;;41464;02131;Nordrhein-Westfalen
Neuss;;41466;02131;Nordrhein-Westfalen
Neuss;;41468;02131;Nordrhein-Westfalen
Neuss;;41469;02131;Nordrhein-Westfalen
Neuss;;41470;02131;Nordrhein-Westfalen
Neuss;;41472;02131;Nordrhein-Westfalen
Neustadt;i. Sa.;01844;03596;Sachsen
Neustadt;, Spree;02979;035727;Sachsen
Neustadt;an der Orla;07806;036481;Thüringen
Neustadt;, Vogtl;08223;03745;Sachsen
Neustadt;(Dosse);16845;033970;Brandenburg
Neustadt;in Holstein;23730;04561;Schlewig-Holstein
Neustadt;am Rübenberge;31535;05032;Niedersachsen
Neustadt;(Hessen);35279;06692;Hessen
Neustadt;b Leinefelde;37345;036077;Thüringen
Neustadt;(Wied);53577;02683;Rheinland-Pfalz
Neustadt;, Westerwald;56479;02664;Rheinland-Pfalz
Neustadt;an der Weinstraße;67433;06321;Rheinland-Pfalz
Neustadt;an der Weinstraße;67434;06321;Rheinland-Pfalz
Neustadt;an der Weinstraße;67435;06321;Rheinland-Pfalz
Neustadt;a d Aisch;91413;09161;Bayern
Neustadt;a d Waldnaab;92660;09602;Bayern
Neustadt;a d Donau;93333;09445;Bayern
Neustadt;am Kulm;95514;09648;Bayern
Neustadt;b. Coburg;96465;09568;Bayern
Neustadt;a. Main;97845;09393;Bayern
Neustadt;am Rennsteig;98701;036781;Thüringen
Neustadt;/Harz;99762;036331;Thüringen
Neustädt;;99834;036922;Thüringen
Neustadt-Glewe;;19306;038757;Mecklenburg-Vorpommern
Neustetten;, Württ;72149;07472;Baden-Württemberg
Neustrelitz;;17235;03981;Mecklenburg-Vorpommern
Neutraubling;;93073;09401;Bayern
Neutrebbin;;15320;033474;Brandenburg
Neutz-Lettewitz;;06198;034603;Sachsen-Anhalt
Neu-Ulm;;89231;0731;Bayern
Neu-Ulm;;89233;0731;Bayern
Neu-Vehlefanz;;16766;03304;Brandenburg
Neuwartensleben;;14715;039389;Sachsen-Anhalt
Neuwegersleben;;39387;039401;Sachsen-Anhalt
Neuweiler;, Kr Calw;75389;07055;Baden-Württemberg
Neuwerben;;39539;039387;Sachsen-Anhalt
Neuwerder;b Rathenow;14728;03737;Brandenburg
Neuwied;;56564;02631;Rheinland-Pfalz
Neuwied;;56566;02631;Rheinland-Pfalz
Neuwied;;56567;02631;Rheinland-Pfalz
Neuwittenbek;;24214;04346;Schlewig-Holstein
Neuwoog;;67316;;Rheinland-Pfalz
Neuwühren;;24223;04307;Schlewig-Holstein
Neuwustrow;;16259;033457;Brandenburg
Neuzelle;;15898;033652;Brandenburg
Neu-Ziddorf;;17166;039933;Mecklenburg-Vorpommern
Neverin;;17039;039608;Mecklenburg-Vorpommern
Nevern;;23992;038422;Mecklenburg-Vorpommern
Neversdorf;;23816;04552;Schlewig-Holstein
Newel;;54309;06505;Rheinland-Pfalz
Nexdorf;;03253;035327;Brandenburg
Ney;;56283;06747;Rheinland-Pfalz
Nichel;;14822;033843;Brandenburg
Nickenich;;56645;02632;Rheinland-Pfalz
Nickritz;;01594;03525;Sachsen
Nickritz;;01594;03525;Sachsen
Nidda;;63667;06043;Hessen
Niddatal;;61194;06034;Hessen
Nidderau;, Hess;61130;06187;Hessen
Nideggen;;52385;02427;Nordrhein-Westfalen
Niebede;;14641;033239;Brandenburg
Niebel;;14929;033748;Brandenburg
Niebelhorst;;14929;033748;Brandenburg
Niebendorf;;14913;033744;Brandenburg
Nieblum;;25938;04681;Schlewig-Holstein
Niebüll;;25899;04661;Schlewig-Holstein
Nieby;b Gelting, Angeln;24395;04643;Schlewig-Holstein
Nieden;;17309;039740;Mecklenburg-Vorpommern
Niedenstein;;34305;05624;Hessen
Nieder Kostenz;;55481;06763;Rheinland-Pfalz
Niederahr;;56414;02602;Rheinland-Pfalz
Niederähren;;56305;02684;Rheinland-Pfalz
Niederaichbach;;84100;08702;Bayern
Niederalben;;66887;06387;Rheinland-Pfalz
Niederalteich;;94557;09901;Bayern
Niederau;b Meißen, Sachs;01689;035243;Sachsen
Niederaula;;36272;06625;Hessen
Niederbachheim;;56357;06776;Rheinland-Pfalz
Niederbergkirchen;;84494;08639;Bayern
Niederböhmersdorf;;07950;036622;Thüringen
Niederbösa;;99718;036379;Thüringen
Niederbreitbach;;56589;02638;Rheinland-Pfalz
Niederbrombach;;55767;06787;Rheinland-Pfalz
Niederburg;, Hunsrück;55432;06744;Rheinland-Pfalz
Niedercrinitz;;08107;037602;Sachsen
Niedercunnersdorf;;02708;035875;Sachsen
Niederdorf;b Stollberg, Erzgeb;09366;037296;Sachsen
Niederdorfelden;;61138;06101;Hessen
Niederdorla;;99986;03601;Thüringen
Niederdreisbach;, Westerw;57520;02743;Rheinland-Pfalz
Niederdürenbach;;56651;02636;Rheinland-Pfalz
Niederelbert;;56412;02602;Rheinland-Pfalz
Niedererbach;b Montabaur;56412;06485;Rheinland-Pfalz
Niedereschach;;78078;07728;Baden-Württemberg
Niederfelde;;16306;033331;Brandenburg
Niederfell;, Mosel;56332;02607;Rheinland-Pfalz
Niederfinow;;16248;033362;Brandenburg
Niederfischbach;;57572;02741;Rheinland-Pfalz
Niederfrohna;;09243;037381;Sachsen
Niederfüllbach;;96489;09565;Bayern
Niedergebra;;99759;036338;Thüringen
Niedergeckler;;54675;06522;Rheinland-Pfalz
Niedergörsdorf;;14913;033741;Brandenburg
Niedergrunstedt;;99428;03643;Thüringen
Niedergrunstedt;;99428;03643;Thüringen
Niederhagen;;18146;038202;Mecklenburg-Vorpommern
Niederhambach;;55767;06787;Rheinland-Pfalz
Niederhausen;, Nahe;55585;06758;Rheinland-Pfalz
Niederhausen;an der Appel;67822;06362;Rheinland-Pfalz
Niederheimbach;b Bingen, am Rhein;55413;06743;Rheinland-Pfalz
Nieder-Hilbersheim;;55437;06728;Rheinland-Pfalz
Niederhofen;, Westerw;56316;02684;Rheinland-Pfalz
Niederhorbach;;76889;06343;Rheinland-Pfalz
Niederhosenbach;;55758;06785;Rheinland-Pfalz
Niederirsen;b Windeck, Sieg;57589;02682;Rheinland-Pfalz
Niederjesar;;15306;033602;Brandenburg
Niederkassel;, Rhein;53859;02208;Nordrhein-Westfalen
Niederkirchen;b Deidesheim;67150;06326;Rheinland-Pfalz
Niederkirchen;, Kr Kaiserslautern;67700;06363;Rheinland-Pfalz
Niederklütz;;23948;038825;Mecklenburg-Vorpommern
Niederkrossen;;07407;036742;Thüringen
Niederkrüchten;;41372;02163;Nordrhein-Westfalen
Niederkumbd;;55469;06761;Rheinland-Pfalz
Niederlangen;;49779;05939;Niedersachsen
Niederlauch;;54614;06553;Rheinland-Pfalz
Niederlauer;;97618;09771;Bayern
Niederlehme;;15751;03375;Brandenburg
Niedermohr;;66879;06383;Rheinland-Pfalz
Niedermoschel;;67822;06362;Rheinland-Pfalz
Niedermurach;;92545;09671;Bayern
Niedernberg;, Unterfr;63843;06028;Bayern
Niederndodeleben;;39167;039204;Sachsen-Anhalt
Niederneisen;;65629;06432;Rheinland-Pfalz
Niedernhall;;74676;07940;Baden-Württemberg
Niedernhausen;, Taunus;65527;06127;Hessen
Niedernissa;;99102;0361;Thüringen
Niedernissa;;99102;0361;Thüringen
Niedernwöhren;;31712;05721;Niedersachsen
Niederöfflingen;;54533;06574;Rheinland-Pfalz
Nieder-Olm;;55268;06136;Rheinland-Pfalz
Niederorschel;;37355;036076;Thüringen
Niederotterbach;;76889;06340;Rheinland-Pfalz
Niederpickenhain;;04643;034346;Sachsen
Niederpierscheid;;54649;06554;Rheinland-Pfalz
Niederpöllnitz;;07570;036607;Thüringen
Niederraden;, Eifel;54675;06522;Rheinland-Pfalz
Niederreißen;;99510;036373;Thüringen
Niederrieden;;87767;08335;Bayern
Niederröblingen;(Helme);06542;034652;Sachsen-Anhalt
Niederroßbach;, Westerw;56479;02664;Rheinland-Pfalz
Niederroßla;;99510;03644;Thüringen
Niedersachswerfen;;99762;036331;Thüringen
Niedersayn;;56244;02666;Rheinland-Pfalz
Niederscheidweiler;;54533;06574;Rheinland-Pfalz
Niederschlettenbach;;76891;06394;Rheinland-Pfalz
Niederschöna;;09600;035209;Sachsen
Niederschönenfeld;;86694;09002;Bayern
Niedersohren;;55487;06543;Rheinland-Pfalz
Niederspier;;99713;036370;Thüringen
Niederstadtfeld;;54570;06596;Rheinland-Pfalz
Niederstaufenbach;;66879;06385;Rheinland-Pfalz
Niederstedem;;54634;06568;Rheinland-Pfalz
Niedersteinebach;;56593;02687;Rheinland-Pfalz
Niederstetten;, Württ;97996;07932;Baden-Württemberg
Niederstotzingen;;89168;07325;Baden-Württemberg
Niederstriegis;;04741;034322;Sachsen
Niedert;;56291;06746;Rheinland-Pfalz
Niedertaufkirchen;;84494;08639;Bayern
Niedertiefenbach;, Rhein-Lahn-Kr;56368;06772;Rheinland-Pfalz
Niedertrebra;;99518;036461;Thüringen
Niederviehbach;;84183;08702;Bayern
Niederwallmenach;;56357;06772;Rheinland-Pfalz
Niederwambach;;57614;02684;Rheinland-Pfalz
Niederwartha;;01462;0351;Sachsen
Niederwartha;;01462;0351;Sachsen
Niederweiler;, Eifel;54636;06569;Rheinland-Pfalz
Niederweiler;, Hunsrück;55491;06543;Rheinland-Pfalz
Niederweis;;54668;06568;Rheinland-Pfalz
Niederwerbig;;14822;033843;Brandenburg
Niederwerrn;;97464;09721;Bayern
Niederwerth;;56179;0261;Rheinland-Pfalz
Niederwiesa;;09577;03726;Sachsen
Nieder-Wiesen;, Rheinhess;55234;06736;Rheinland-Pfalz
Niederwillingen;;99326;03629;Thüringen
Niederwinkling;;94559;09962;Bayern
Niederwörresbach;;55758;06785;Rheinland-Pfalz
Niederwürschnitz;;09399;037296;Sachsen
Niederzier;;52382;02428;Nordrhein-Westfalen
Niederzimmern;;99428;036203;Thüringen
Niederzissen;;56651;02636;Rheinland-Pfalz
Niedling;;83374;08669;Bayern
Niefern-Öschelbronn;;75223;07233;Baden-Württemberg
Niegeroda;;01561;035248;Sachsen
Niegripp;;39291;039222;Sachsen-Anhalt
Nieheim;;33039;05274;Nordrhein-Westfalen
Niehl;, Eifel;54646;06522;Rheinland-Pfalz
Nieklitz;;19258;038843;Mecklenburg-Vorpommern
Niekrenz;;18190;038209;Mecklenburg-Vorpommern
Nielebock;;39319;039341;Sachsen-Anhalt
Niemberg;;06188;034604;Sachsen-Anhalt
Niemegk;;14823;033843;Brandenburg
Niemerlang;;16909;033967;Brandenburg
Niemetal;;37127;05502;Niedersachsen
Niemtsch;;01968;03573;Brandenburg
Nienborstel;;24819;04874;Schlewig-Holstein
Nienburg;(Saale);06429;034721;Sachsen-Anhalt
Nienburg;(Weser);31582;05021;Niedersachsen
Nienbüttel;b Wacken;25596;04827;Schlewig-Holstein
Niendorf;b Dahme, Mark;15936;035451;Brandenburg
Niendorf;b Neuhaus, Elbe;19273;038841;Niedersachsen
Niendorf;, Rögnitz;19294;038750;Mecklenburg-Vorpommern
Niendorf;, Stecknitz;23881;04156;Schlewig-Holstein
Niendorf;bei Berkenthin;23919;04544;Schlewig-Holstein
Niendorf;b Schönberg, Meckl;23923;038828;Mecklenburg-Vorpommern
Niendorf;b Grevesmühlen;23948;038428;Mecklenburg-Vorpommern
Niendorf;, Meckl;23996;038423;Mecklenburg-Vorpommern
Nienhagen;b Celle;29336;05144;Niedersachsen
Nienhagen;b Oschersleben;39397;039403;Sachsen-Anhalt
Nienstädt;b Stadthagen;31688;05721;Niedersachsen
Nienstedt;b Sangerhausen;06542;034652;Sachsen-Anhalt
Nienwohld;;23863;04537;Schlewig-Holstein
Niepars;;18442;038326;Mecklenburg-Vorpommern
Nierow;;14715;039389;Sachsen-Anhalt
Niersbach;;54518;06575;Rheinland-Pfalz
Nierstein;;55283;06133;Rheinland-Pfalz
Niesgrau;;24395;04643;Schlewig-Holstein
Niesky;;02906;03588;Sachsen
Nieste;, Kr Kassel;34329;05605;Hessen
Niestetal;;34266;05543;Hessen
Nietwerder;;16816;03391;Brandenburg
Nievern;;56132;02603;Rheinland-Pfalz
Niewitz;;15910;035474;Brandenburg
Niklasreuth;;83739;08064;Bayern
Nikolaushof;;67317;06351;Rheinland-Pfalz
Nimritz;;07381;03647;Thüringen
Nimshuscheid;;54612;06553;Rheinland-Pfalz
Nimsreuland;;54614;06553;Rheinland-Pfalz
Nindorf;b Neumünster;24594;04871;Schlewig-Holstein
Nindorf;b Meldorf;25704;04832;Schlewig-Holstein
Nirmsdorf;;99510;036463;Thüringen
Nisbill;;19417;038482;Mecklenburg-Vorpommern
Nister;;57645;02662;Rheinland-Pfalz
Nisterau;;56472;02661;Rheinland-Pfalz
Nisterberg;, Westerw;56472;02661;Rheinland-Pfalz
Nister-Möhrendorf;;56477;02667;Rheinland-Pfalz
Nistertal;, Westerw;57647;02661;Rheinland-Pfalz
Nittel;;54453;06584;Rheinland-Pfalz
Nittenau;;93149;09436;Bayern
Nittendorf;;93152;09404;Bayern
Nitz;;56729;02656;Rheinland-Pfalz
Nitzahn;;14715;033877;Brandenburg
Nitzow;;39539;039387;Sachsen-Anhalt
Nöbdenitz;;04626;034496;Thüringen
Nobitz;;04603;034494;Thüringen
Nochern;;56357;06771;Rheinland-Pfalz
Nöda;;99195;036204;Thüringen
Noer;;24214;04346;Schlewig-Holstein
Nohen;;55767;06789;Rheinland-Pfalz
Nohfelden;;66625;06852;Saarland
Nohn;, Eifel;54578;02696;Rheinland-Pfalz
Nohra;b Weimar, Thür;99428;03643;Thüringen
Nohra;b Nordhausen;99735;036334;Thüringen
Nomborn;;56412;06485;Rheinland-Pfalz
Nonnendorf;b Jüterbog;14913;033744;Brandenburg
Nonnenholz;, Forsthaus;37217;;Hessen
Nonnenholz;, Forsthaus;37217;;Hessen
Nonnenhorn;;88149;08382;Bayern
Nonnenmiß;;75337;07085;Baden-Württemberg
Nonnental;;67466;;Rheinland-Pfalz
Nonnewitz;;06727;03441;Sachsen-Anhalt
Nonnweiler;;66620;06873;Saarland
Norath;;56291;06746;Rheinland-Pfalz
Norddeich;, Dithm;25764;04833;Schlewig-Holstein
Norddorf;, Amrum;25946;04682;Schlewig-Holstein
Norden;, Ostfriesl;26506;04931;Niedersachsen
Nordendorf;;86695;08273;Bayern
Nordenham;;26954;04731;Niedersachsen
Norderbrarup;;24392;04641;Schlewig-Holstein
Norderfriedrichskoog;;25870;04864;Schlewig-Holstein
Nordergeilwang;;24896;04626;Schlewig-Holstein
Norderheistedt;;25779;04836;Schlewig-Holstein
Nordermeldorf;;25704;04832;Schlewig-Holstein
Norderney;;26548;04932;Niedersachsen
Norderstapel;;25868;04883;Schlewig-Holstein
Norderstedt;;22844;040;Schlewig-Holstein
Norderstedt;;22846;040;Schlewig-Holstein
Norderstedt;;22848;040;Schlewig-Holstein
Norderstedt;;22850;040;Schlewig-Holstein
Norderstedt;;22851;040;Schlewig-Holstein
Norderwöhrden;;25746;0481;Schlewig-Holstein
Nordfeld;, Dithm;25776;04882;Schlewig-Holstein
Nordgermersleben;;39343;039062;Sachsen-Anhalt
Nordhackstedt;;24980;04639;Schlewig-Holstein
Nordhalben;;96365;09267;Bayern
Nordhastedt;;25785;04804;Schlewig-Holstein
Nordhausen;, Thür;99734;03631;Thüringen
Nordheim;, Württ;74226;07133;Baden-Württemberg
Nordheim;a. Main;97334;09381;Bayern
Nordheim;v d Rhön;97647;09779;Bayern
Nordheim;b Meiningen;98631;036944;Thüringen
Nordhofen;, Westerw;56242;02626;Rheinland-Pfalz
Nordholz;b Bremerhaven;27637;04741;Niedersachsen
Nordhorn;;48527;05921;Niedersachsen
Nordhorn;;48529;05921;Niedersachsen
Nordhorn;;48531;05921;Niedersachsen
Nordkirchen;;59394;02596;Nordrhein-Westfalen
Nordleda;;21765;04758;Niedersachsen
Nördlingen;;86720;09081;Bayern
Nordrach;;77787;07838;Baden-Württemberg
Nordseeinsel Memmert;;26571;;Niedersachsen
Nordsehl;;31717;05721;Niedersachsen
Nordstemmen;;31171;05069;Niedersachsen
Nordstrand;;25845;04842;Schlewig-Holstein
Nordstrandischmoor;;25845;04842;Schlewig-Holstein
Nordwalde;;48356;02573;Nordrhein-Westfalen
Norheim;, Nahe;55585;0671;Rheinland-Pfalz
Norken;;57629;02661;Rheinland-Pfalz
Norstedt;;25884;04843;Schlewig-Holstein
Nörten-Hardenberg;;37176;05503;Niedersachsen
Nörtershausen;;56283;02605;Rheinland-Pfalz
Northeim;;37154;05551;Niedersachsen
Nortmoor;;26845;04950;Niedersachsen
Nortorf;b Neumünster;24589;04392;Schlewig-Holstein
Nortorf;b Wilster;25554;04823;Schlewig-Holstein
Nortrup;;49638;05436;Niedersachsen
Nörvenich;;52388;02426;Nordrhein-Westfalen
Nossen;;01683;035242;Sachsen
Nossendorf;;17111;039995;Mecklenburg-Vorpommern
Nossentiner Hütte;;17214;039927;Mecklenburg-Vorpommern
Noßlitz;;01623;035242;Sachsen
Nostorf;;19258;038847;Mecklenburg-Vorpommern
Nothweiler;;76891;06394;Rheinland-Pfalz
Nottensdorf;;21640;04163;Niedersachsen
Nottfeld;;24392;04641;Schlewig-Holstein
Nottleben;;99192;036208;Thüringen
Nottuln;;48301;02502;Nordrhein-Westfalen
Notzingen;;73274;07161;Baden-Württemberg
Nübbel;, Lotsenstation;24768;;Schlewig-Holstein
Nübbel;b Rendsburg;24809;04331;Schlewig-Holstein
Nübel;b Schleswig;24881;04621;Schlewig-Holstein
Nudersdorf;;06896;034929;Sachsen-Anhalt
Nüdlingen;;97720;0971;Bayern
Nudow;;14532;033200;Brandenburg
Nufringen;;71154;07032;Baden-Württemberg
Nümbrecht;;51588;02293;Nordrhein-Westfalen
Nünchritz;;01612;035265;Sachsen
Nünschweiler;;66989;06336;Rheinland-Pfalz
Nunsdorf;;15806;033731;Brandenburg
Nürburg;;53520;02691;Rheinland-Pfalz
Nürnberg;, Mittelfr;90402;0911;Bayern
Nürnberg;, Mittelfr;90403;0911;Bayern
Nürnberg;, Mittelfr;90408;0911;Bayern
Nürnberg;, Mittelfr;90409;0911;Bayern
Nürnberg;, Mittelfr;90411;0911;Bayern
Nürnberg;, Mittelfr;90419;0911;Bayern
Nürnberg;, Mittelfr;90425;0911;Bayern
Nürnberg;, Mittelfr;90427;0911;Bayern
Nürnberg;, Mittelfr;90429;0911;Bayern
Nürnberg;, Mittelfr;90431;0911;Bayern
Nürnberg;, Mittelfr;90439;0911;Bayern
Nürnberg;, Mittelfr;90441;0911;Bayern
Nürnberg;, Mittelfr;90443;0911;Bayern
Nürnberg;, Mittelfr;90449;0911;Bayern
Nürnberg;, Mittelfr;90451;0911;Bayern
Nürnberg;, Mittelfr;90453;0911;Bayern
Nürnberg;, Mittelfr;90455;0911;Bayern
Nürnberg;, Mittelfr;90459;0911;Bayern
Nürnberg;, Mittelfr;90461;0911;Bayern
Nürnberg;, Mittelfr;90469;0911;Bayern
Nürnberg;, Mittelfr;90471;0911;Bayern
Nürnberg;, Mittelfr;90473;0911;Bayern
Nürnberg;, Mittelfr;90475;0911;Bayern
Nürnberg;, Mittelfr;90478;0911;Bayern
Nürnberg;, Mittelfr;90480;0911;Bayern
Nürnberg;, Mittelfr;90482;0911;Bayern
Nürnberg;, Mittelfr;90489;0911;Bayern
Nürnberg;, Mittelfr;90491;0911;Bayern
Nürtingen;;72622;07022;Baden-Württemberg
Nusbaum;;54675;06522;Rheinland-Pfalz
Nusplingen;, Württ;72362;07429;Baden-Württemberg
Nußbach;, Pfalz;67759;06364;Rheinland-Pfalz
Nußbaum;, Kr Bad Kreuznach;55569;06751;Rheinland-Pfalz
Nußdorf;a. Inn;83131;08034;Bayern
Nußdorf;, Chiemgau;83365;08669;Bayern
Nusse;;23896;04543;Schlewig-Holstein
Nußloch;;69226;06224;Baden-Württemberg
Nustrow;;18195;038205;Mecklenburg-Vorpommern
Nüsttal;;36167;06684;Hessen
Nutha;;39264;039247;Sachsen-Anhalt
Nuthe-Urstromtal;;14947;03371;Brandenburg
Nutteln;b Sternberg;19412;038486;Mecklenburg-Vorpommern
Nutteln;, Holst;25594;04827;Schlewig-Holstein
Nützen;;24568;04191;Schlewig-Holstein
Ober Kostenz;;55481;06763;Rheinland-Pfalz
Oberachthal;b Neukirchen a Teisenberg;83364;;Bayern
Oberahr;;56414;02602;Rheinland-Pfalz
Oberalben;;66871;06381;Rheinland-Pfalz
Oberammergau;;82487;08822;Bayern
Oberarnbach;, Pfalz;66851;06371;Rheinland-Pfalz
Oberasbach;b Nürnberg;90522;0911;Bayern
Oberau;, Loisach;82496;08824;Bayern
Oberau;b Weibhausen;83379;08681;Bayern
Oberaudorf;;83080;08033;Bayern
Oberaula;;36280;06628;Hessen
Oberaurach;;97514;09529;Bayern
Oberbachheim;;56355;06776;Rheinland-Pfalz
Oberbergkirchen;;84564;08637;Bayern
Oberbettingen;;54578;06593;Rheinland-Pfalz
Oberbiberg;;82041;089;Bayern
Oberbillig;;54331;06501;Rheinland-Pfalz
Oberbodnitz;;07646;036424;Thüringen
Oberboihingen;;72644;07022;Baden-Württemberg
Oberbösa;;99718;036379;Thüringen
Oberbrombach;b Idar-Oberstein;55767;06787;Rheinland-Pfalz
Obercarsdorf;;01762;03504;Sachsen
Obercunnersdorf;b Löbau;02708;035875;Sachsen
Oberdachstetten;;91617;09845;Bayern
Oberderdingen;;75038;07045;Baden-Württemberg
Oberdiebach;;55413;06743;Rheinland-Pfalz
Oberdill;b München;82064;089;Bayern
Oberding;;85445;08122;Bayern
Oberdischingen;;89610;07305;Baden-Württemberg
Oberdoblmühle;;83329;08681;Bayern
Oberdolling;;85129;08404;Bayern
Oberdorla;;99986;03601;Thüringen
Oberdreis;, Westerw;57639;02684;Rheinland-Pfalz
Oberdürenbach;;56651;02646;Rheinland-Pfalz
Obere Kleinmichelesmühle;;71111;07157;Baden-Württemberg
Obere Ölmühle;;71720;;Baden-Württemberg
Obere Rauhmühle;;71111;;Baden-Württemberg
Obere Roggenmühle;;73312;;Baden-Württemberg
Oberehe-Stroheich;;54578;06595;Rheinland-Pfalz
Obereinöd;b Au a Inn;83546;08073;Bayern
Oberelbert;;56412;02608;Rheinland-Pfalz
Oberellen;;99819;036925;Thüringen
Oberelsbach;;97656;09774;Bayern
Oberelz;;56767;02657;Rheinland-Pfalz
Oberer Etzberg;;73113;07162;Baden-Württemberg
Oberer Eutinger Talhof;;72160;07451;Baden-Württemberg
Oberer Lindenhof;;72813;07121;Baden-Württemberg
Obererbach;b Montabaur;56414;06435;Rheinland-Pfalz
Obererbach;(Westerwald);57612;02681;Rheinland-Pfalz
Oberfell;, Mosel;56332;02605;Rheinland-Pfalz
Oberfischbach;, Rhein-Lahn-Kr;56370;06486;Rheinland-Pfalz
Ober-Flörsheim;;55234;06735;Rheinland-Pfalz
Oberführbuch;;89165;07347;Baden-Württemberg
Obergebra;;99759;036338;Thüringen
Obergeckler;;54675;06522;Rheinland-Pfalz
Obergriesbach;;86573;08251;Bayern
Obergröningen;;73569;07975;Baden-Württemberg
Obergünzburg;;87634;08372;Bayern
Obergurig;;02692;035938;Sachsen
Oberhaching;b München;82041;08170;Bayern
Oberhaid;, Westerw;56237;02626;Rheinland-Pfalz
Oberhaid;, Oberfr;96173;09503;Bayern
Oberhain;;07426;036738;Thüringen
Oberhalling;;83329;08681;Bayern
Oberhambach;b Birkenfeld, Nahe;55765;06782;Rheinland-Pfalz
Oberharmersbach;;77784;07837;Baden-Württemberg
Oberhäslich;;01744;03504;Sachsen
Oberhaus;b Stein a d Traun;83371;08621;Bayern
Oberhausen;, Rheinl;46045;0208;Nordrhein-Westfalen
Oberhausen;, Rheinl;46047;0208;Nordrhein-Westfalen
Oberhausen;, Rheinl;46049;0208;Nordrhein-Westfalen
Oberhausen;, Rheinl;46117;0208;Nordrhein-Westfalen
Oberhausen;, Rheinl;46119;0208;Nordrhein-Westfalen
Oberhausen;, Rheinl;46145;0208;Nordrhein-Westfalen
Oberhausen;, Rheinl;46147;0208;Nordrhein-Westfalen
Oberhausen;, Rheinl;46149;0208;Nordrhein-Westfalen
Oberhausen;an der Nahe;55585;06755;Rheinland-Pfalz
Oberhausen;b Kirn, Nahe;55606;06752;Rheinland-Pfalz
Oberhausen;an der Appel;67822;06362;Rheinland-Pfalz
Oberhausen;b Bad Bergzabern;76887;06343;Rheinland-Pfalz
Oberhausen;, Oberbay;82386;08802;Bayern
Oberhausen;b Neuburg a d Donau;86697;08431;Bayern
Oberhausen-Rheinhausen;;68794;07254;Baden-Württemberg
Oberheimbach;b Bingen am Rhein;55413;06743;Rheinland-Pfalz
Oberheldrungen;;06577;034673;Thüringen
Oberhermsdorf;;01737;0351;Sachsen
Ober-Hilbersheim;;55437;06728;Rheinland-Pfalz
Oberhof;, Meckl;23948;038825;Mecklenburg-Vorpommern
Oberhof;, Neckar;72160;;Baden-Württemberg
Oberhof;, Gem Kreuth;83700;08022;Bayern
Oberhof;, Thür;98559;036842;Thüringen
Oberhöhenwald;;83364;08666;Bayern
Oberholzhäuseln;;85395;08168;Bayern
Oberhonnefeld-Gierend;;56587;02634;Rheinland-Pfalz
Oberhosenbach;;55758;06785;Rheinland-Pfalz
Oberickelsheim;;97258;09339;Bayern
Oberirsen;;57635;02686;Rheinland-Pfalz
Oberjünne;;14778;033835;Brandenburg
Oberkail;;54533;06567;Rheinland-Pfalz
Oberkatz;;98634;036966;Thüringen
Oberkirch;, Baden;77704;07802;Baden-Württemberg
Oberkirn;;55624;06544;Rheinland-Pfalz
Oberklütz;;23948;;Mecklenburg-Vorpommern
Oberkochen;;73447;07364;Baden-Württemberg
Oberkotzau;;95145;09286;Bayern
Oberkretzach;;83739;08025;Bayern
Oberkutterau;;79837;07672;Baden-Württemberg
Oberlahr;, Westerw;57641;02685;Rheinland-Pfalz
Oberlangen;;49779;05933;Niedersachsen
Oberlascheid;;54608;06555;Rheinland-Pfalz
Oberlauch;;54614;06553;Rheinland-Pfalz
Oberlauterbach;, Vogtl;08239;03745;Sachsen
Oberleichtersbach;;97789;09741;Bayern
Oberlemnitz;;07356;036651;Thüringen
Oberlichtenau;, Lausitz;01936;035955;Sachsen
Oberlindberg;;94374;09962;Bayern
Oberloquitz;;07330;036732;Thüringen
Oberlungwitz;;09353;0371;Sachsen
Obermaiselstein;;87538;08326;Bayern
Obermarchtal;;89611;07375;Baden-Württemberg
Obermaßfeld-Grimmenthal;;98617;036949;Thüringen
Obermehler;;99996;036021;Thüringen
Obermeitingen;;86836;08232;Bayern
Obermichelbach;b Fürth, Bay;90587;0911;Bayern
Obermittweilerhof;;67806;06361;Rheinland-Pfalz
Obermoosen;b Waging a See;83329;08681;Bayern
Ober-Mörlen;;61239;06002;Hessen
Obermoschel;;67823;06362;Rheinland-Pfalz
Obernach;, Oberbay;82432;08858;Bayern
Obernau;, Westerw;57638;02685;Rheinland-Pfalz
Obernbreit;;97342;09332;Bayern
Obernburg;a. Main;63785;06022;Bayern
Oberndorf;, Oste;21787;04772;Niedersachsen
Oberndorf;, Pfalz;67821;06362;Rheinland-Pfalz
Oberndorf;am Neckar;78727;07423;Baden-Württemberg
Oberndorf;a. Lech;86698;09090;Bayern
Oberneisen;;65558;06430;Rheinland-Pfalz
Oberneukirchen;;84565;08630;Bayern
Obernfeld;;37434;05527;Niedersachsen
Obernheim;, Württ;72364;07436;Baden-Württemberg
Obernheim-Kirchenarnbach;;66919;06371;Rheinland-Pfalz
Obernhof;, Lahn;56379;02604;Rheinland-Pfalz
Obernholz;;29386;05832;Niedersachsen
Obernissa;;99198;036203;Thüringen
Obernkirchen;;31683;05724;Niedersachsen
Obernzell;;94130;08591;Bayern
Obernzenn;;91619;09844;Bayern
Oberöfflingen;;54533;06572;Rheinland-Pfalz
Ober-Olm;;55270;06136;Rheinland-Pfalz
Oberoppurg;;07381;03647;Thüringen
Oberostendorf;;86869;08344;Bayern
Oberotterbach;, Pfalz;76889;06342;Rheinland-Pfalz
Oberottmarshausen;;86507;08231;Bayern
Oberpframmern;;85667;08093;Bayern
Oberpierscheid;;54649;06554;Rheinland-Pfalz
Oberpleichfeld;;97241;09367;Bayern
Oberpöring;;94562;09937;Bayern
Oberpörlitz;;98693;03677;Thüringen
Oberraden;;56587;02634;Rheinland-Pfalz
Ober-Ramstadt;;64372;06154;Hessen
Oberreichenbach;, Württ;75394;07051;Baden-Württemberg
Oberreichenbach;b Herzogenaurach;91097;09104;Bayern
Oberreidenbach;;55758;06788;Rheinland-Pfalz
Oberreißen;;99510;036377;Thüringen
Oberreut;b Neukirchen a Teisenberg;83364;08666;Bayern
Oberreute;, Allgäu;88179;08387;Bayern
Oberried;, Breisgau;79254;07602;Baden-Württemberg
Oberrieden;, Schwab;87769;08265;Bayern
Oberriexingen;;71739;07042;Baden-Württemberg
Oberröblingen;;06528;03464;Sachsen-Anhalt
Oberrod;, Westerw;56479;02664;Rheinland-Pfalz
Oberrohn;;36469;03695;Thüringen
Oberroßbach;, Westerw;56479;02667;Rheinland-Pfalz
Oberrot;b Gaildorf;74420;07977;Baden-Württemberg
Oberroth;b Illertissen;89294;08333;Bayern
Oberscheidweiler;;54533;06574;Rheinland-Pfalz
Oberscheinfeld;;91483;09167;Bayern
Oberschleißheim;;85764;089;Bayern
Oberschlettenbach;;76889;06398;Rheinland-Pfalz
Oberschneiding;;94363;09426;Bayern
Oberschöna;;09600;037321;Sachsen
Oberschönau;, Thür;98587;036847;Thüringen
Oberschönegg;;87770;08333;Bayern
Oberschops;;85655;08095;Bayern
Oberschulenberg;;38678;05323;Niedersachsen
Oberschwarzach;, Unterfr;97516;09382;Bayern
Oberschweinbach;;82294;08145;Bayern
Obersdorf;b Sangerhausen;06528;03464;Sachsen-Anhalt
Oberseifersdorf;;02763;03583;Sachsen
Obersiegenbühl;;73453;07366;Baden-Württemberg
Obersimten;;66957;06331;Rheinland-Pfalz
Obersinn;;97791;09356;Bayern
Obersöchering;;82395;08847;Bayern
Obersontheim;;74423;07973;Baden-Württemberg
Oberstadion;;89613;07357;Baden-Württemberg
Oberstadt;;98530;036846;Thüringen
Oberstadtfeld;;54570;06596;Rheinland-Pfalz
Oberstaufen;;87534;08386;Bayern
Oberstaufenbach;;66879;06385;Rheinland-Pfalz
Oberstdorf;;87561;08322;Bayern
Oberstedem;;54634;06568;Rheinland-Pfalz
Obersteinebach;;56593;02687;Rheinland-Pfalz
Obersteinenberg;;73635;07183;Baden-Württemberg
Oberstenfeld;;71720;07062;Baden-Württemberg
Oberstenwehr;;25572;04825;Schlewig-Holstein
Oberstößwitz;;01623;035246;Sachsen
Oberstreit;;55596;06758;Rheinland-Pfalz
Oberstreu;;97640;09776;Bayern
Obersulm;;74182;07130;Baden-Württemberg
Obersülzen;;67271;06359;Rheinland-Pfalz
Obersüßbach;;84101;08708;Bayern
Obertaufkirchen;;84419;08082;Bayern
Oberteuringen;;88094;07546;Baden-Württemberg
Oberthal;, Saar;66649;06854;Saarland
Oberthierwasen;;67292;06352;Rheinland-Pfalz
Oberthulba;;97723;09736;Bayern
Obertiefenbach;, Taunus;56357;06772;Rheinland-Pfalz
Obertraubling;;93083;09453;Bayern
Obertrebra;;99510;03644;Thüringen
Obertrubach;;91286;09245;Bayern
Obertshausen;;63179;06104;Hessen
Oberursel;(Taunus);61440;06171;Hessen
Oberviechtach;;92526;09671;Bayern
Oberwallmenach;;56357;06772;Rheinland-Pfalz
Oberwambach;, Westerw;57614;02681;Rheinland-Pfalz
Oberweid;;98634;036946;Thüringen
Oberweikertshofen;;82282;08145;Bayern
Oberweiler;, Eifel;54636;06569;Rheinland-Pfalz
Oberweiler im Tal;;67756;06304;Rheinland-Pfalz
Oberweiler-Tiefenbach;;67752;06304;Rheinland-Pfalz
Oberweis;, Eifel;54636;06527;Rheinland-Pfalz
Oberweißbach;;98744;036705;Thüringen
Oberwendling;b Waging a See;83329;08681;Bayern
Oberwesel;, Rhein;55430;06744;Rheinland-Pfalz
Oberweser;, Hess;34399;05574;Hessen
Oberwiera;;08396;037608;Sachsen
Oberwies;b Nassau, Lahn;56379;02604;Rheinland-Pfalz
Oberwiesen;, Pfalz;67294;06358;Rheinland-Pfalz
Oberwillingen;;99326;03629;Thüringen
Oberwolfach;;77709;07834;Baden-Württemberg
Oberwörresbach;;55758;06785;Rheinland-Pfalz
Oberzissen;;56651;02636;Rheinland-Pfalz
Obhausen;;06268;034771;Sachsen-Anhalt
Obing;;83119;08624;Bayern
Obrigheim;(Pfalz);67283;06359;Rheinland-Pfalz
Obrigheim;, Baden;74847;06261;Baden-Württemberg
Ochsenbusch;;67305;06351;Rheinland-Pfalz
Ochsenfurt;, Unterfr;97199;09331;Bayern
Ochsenhausen;;88416;07352;Baden-Württemberg
Ochtendung;;56299;02625;Rheinland-Pfalz
Ochtersum;, Ostfriesl;26489;04977;Niedersachsen
Ochtmersleben;;39167;039206;Sachsen-Anhalt
Ochtrup;;48607;02553;Nordrhein-Westfalen
Ockenfels;;53545;02644;Rheinland-Pfalz
Ockenheim;, Rheinhess;55437;06725;Rheinland-Pfalz
Ockfen;;54441;06581;Rheinland-Pfalz
Ockholm;;25842;04674;Schlewig-Holstein
Odderade;;25785;04806;Schlewig-Holstein
Odelzhausen;;85235;08134;Bayern
Odenbach;;67748;06753;Rheinland-Pfalz
Ödenbach;, Oberbay;85669;08124;Bayern
Ödenpullach;;82041;089;Bayern
Odenthal;;51519;02202;Nordrhein-Westfalen
Oderberg;, Mark;16248;033369;Brandenburg
Oderberg;b Traunwalchen;83374;08669;Bayern
Oderin;;15757;033765;Brandenburg
Odernheim;am Glan;55571;06755;Rheinland-Pfalz
Oderwitz;;02791;035842;Sachsen
Odisheim;;21775;04756;Niedersachsen
Oebelitz;;18461;038322;Mecklenburg-Vorpommern
Oebisfelde;;39646;039002;Sachsen-Anhalt
Oebles-Schlechtewitz;;06231;034446;Sachsen-Anhalt
Oechlitz;;06268;034632;Sachsen-Anhalt
Oechsen;;36404;036965;Thüringen
Oed;b Frauenneuharting;83565;08092;Bayern
Oederan;;09569;037292;Sachsen
Oederquart;;21734;04779;Niedersachsen
Oedheim;;74229;07136;Baden-Württemberg
Oegeln;;15848;03366;Brandenburg
Oehna;b Jüterbog;14913;033742;Brandenburg
Oehrenstock;;98704;03677;Thüringen
Oelde;, Westf;59302;02522;Nordrhein-Westfalen
Oelixdorf;;25524;04821;Schlewig-Holstein
Oelsberg;;56357;06772;Rheinland-Pfalz
Oelsen;b Pirna;01816;035023;Sachsen
Oelsig;;04936;035361;Brandenburg
Oelsitz;;01589;03525;Sachsen
Oelsitz;;01589;03525;Sachsen
Oelsnitz;b Großenhain, Sachs;01561;035248;Sachsen
Oelsnitz;, Vogtl;08606;037421;Sachsen
Oelsnitz;/Erzgeb.;09376;0375;Sachsen
Oepfershausen;;98634;036940;Thüringen
Oerbke;b Fallingbostel;29683;05163;Niedersachsen
Oerel;;27432;04765;Niedersachsen
Oer-Erkenschwick;;45739;02368;Nordrhein-Westfalen
Oering;, Holst;23845;04535;Schlewig-Holstein
Oerlenbach;;97714;09725;Bayern
Oerlinghausen;;33813;05202;Nordrhein-Westfalen
Oersberg;;24407;04642;Schlewig-Holstein
Oersdorf;b Neumünster;24568;04191;Schlewig-Holstein
Oeschebüttel;;25548;04822;Schlewig-Holstein
Oesterdeichstrich;;25761;04834;Schlewig-Holstein
Oesterwurth;;25764;04833;Schlewig-Holstein
Oestrich-Winkel;;65375;06723;Hessen
Oetjendorf;;22961;04534;Schlewig-Holstein
Oettelin;;18246;038461;Mecklenburg-Vorpommern
Oettern;;99438;036453;Thüringen
Oettersdorf;;07907;03663;Thüringen
Oettingen;i Bay;86732;09082;Bayern
Oetzen;;29588;05805;Niedersachsen
Oevenum;;25938;04681;Schlewig-Holstein
Oeverdiek;, Gem Ratekau;23669;;Schlewig-Holstein
Oeversee;;24988;04630;Schlewig-Holstein
Offenau;, Württ;74254;07136;Baden-Württemberg
Offenbach;am Main;63065;069;Hessen
Offenbach;am Main;63067;069;Hessen
Offenbach;am Main;63069;069;Hessen
Offenbach;am Main;63071;069;Hessen
Offenbach;am Main;63073;069;Hessen
Offenbach;am Main;63075;069;Hessen
Offenbach;an der Queich;76877;06348;Rheinland-Pfalz
Offenbach-Hundheim;;67749;06382;Rheinland-Pfalz
Offenberg;;94560;0991;Bayern
Offenburg;;77652;0781;Baden-Württemberg
Offenburg;;77654;0781;Baden-Württemberg
Offenburg;;77656;0781;Baden-Württemberg
Offenbüttel;;25767;04835;Schlewig-Holstein
Offenhausen;, Mittelfr;91238;09158;Bayern
Offenheim;, Rheinhess;55234;06736;Rheinland-Pfalz
Offenstätter;;83739;;Bayern
Offingen;, Donau;89362;08224;Bayern
Offstein;;67591;06243;Rheinland-Pfalz
Ofterdingen;;72131;07473;Baden-Württemberg
Ofterschwang;;87527;08321;Bayern
Oftersheim;;68723;06202;Baden-Württemberg
Oggelshausen;;88422;07582;Baden-Württemberg
Ogrosen;;03205;035436;Brandenburg
Ohlenhard;;53520;02694;Rheinland-Pfalz
Ohlsbach;;77797;07803;Baden-Württemberg
Ohlstadt;;82441;08841;Bayern
Ohlweiler;;55469;06761;Rheinland-Pfalz
Ohmbach;, Pfalz;66903;06386;Rheinland-Pfalz
Ohmden;;73275;07023;Baden-Württemberg
Ohne;;48465;05923;Niedersachsen
Ohnewitz;;14728;033875;Brandenburg
Öhningen;, Bodensee;78337;07735;Baden-Württemberg
Ohorn;;01896;035955;Sachsen
Ohrdruf;;99885;03624;Thüringen
Ohrenbach;, Mittelfr;91620;09865;Bayern
Öhringen;;74613;07941;Baden-Württemberg
Ohrsleben;;39393;039405;Sachsen-Anhalt
Ohrum;;38312;05337;Niedersachsen
Oland;, Hallig;25869;04667;Schlewig-Holstein
Olbernhau;;09526;037360;Sachsen
Olbersdorf;;02785;03583;Sachsen
Olbersleben;;99628;036372;Thüringen
Ölbronn-Dürrn;;75248;07043;Baden-Württemberg
Olching;;82140;08142;Bayern
Oldenborstel;;25560;04892;Schlewig-Holstein
Oldenburg;in Holstein;23758;04361;Schlewig-Holstein
Oldenburg;(Oldb);26121;0441;Niedersachsen
Oldenburg;(Oldb);26122;0441;Niedersachsen
Oldenburg;(Oldb);26123;0441;Niedersachsen
Oldenburg;(Oldb);26125;0441;Niedersachsen
Oldenburg;(Oldb);26127;0441;Niedersachsen
Oldenburg;(Oldb);26129;0441;Niedersachsen
Oldenburg;(Oldb);26131;0441;Niedersachsen
Oldenburg;(Oldb);26133;0441;Niedersachsen
Oldenburg;(Oldb);26135;0441;Niedersachsen
Oldenbüttel;b Hanerau-Hademarschen;25557;04872;Schlewig-Holstein
Oldendorf;(Luhe);21385;04144;Niedersachsen
Oldendorf;, Kr Stade;21726;04144;Niedersachsen
Oldendorf;, Holst;25588;04821;Schlewig-Holstein
Oldenhütten;;24793;04392;Schlewig-Holstein
Oldenswort;;25870;04864;Schlewig-Holstein
Oldersbek;;25873;04848;Schlewig-Holstein
Olderup;;25860;04846;Schlewig-Holstein
Oldisleben;;06578;03466;Thüringen
Oldsum;;25938;04683;Schlewig-Holstein
Olenkorb;;66851;;Rheinland-Pfalz
Olfen;, Westf;59399;02595;Nordrhein-Westfalen
Olgashof;;23966;03841;Mecklenburg-Vorpommern
Ollendorf;b Erfurt;99198;036203;Thüringen
Öllingen;, Württ;89129;07345;Baden-Württemberg
Ollmuth;;54316;06588;Rheinland-Pfalz
Ollndorf;;23923;038828;Mecklenburg-Vorpommern
Olmscheid;;54689;06550;Rheinland-Pfalz
Ölmühle;b Eisenberg, Thür;07616;;Thüringen
Ölmühle;b Jagstzell;73489;;Baden-Württemberg
Ölmühle;b Welzheim;73553;;Baden-Württemberg
Olpe;, Biggesee;57462;02761;Nordrhein-Westfalen
Olsberg;;59939;02962;Nordrhein-Westfalen
Olsbrücken;;67737;06308;Rheinland-Pfalz
Olsdorf;;54646;06527;Rheinland-Pfalz
Ölsen;, Westerw;57612;02681;Rheinland-Pfalz
Olzheim;;54597;06552;Rheinland-Pfalz
Onsdorf;;54456;06584;Rheinland-Pfalz
Opfenbach;;88145;08385;Bayern
Öpfingen;;89614;0751;Baden-Württemberg
Oppach;;02736;035872;Sachsen
Oppelhain;;03238;035325;Brandenburg
Oppenau;;77728;07804;Baden-Württemberg
Oppenheim;;55276;06133;Rheinland-Pfalz
Oppenweiler;;71570;07191;Baden-Württemberg
Oppershausen;, Thür;99986;036028;Thüringen
Oppertshausen;;55469;06761;Rheinland-Pfalz
Oppin;b Halle, Saale;06188;0345;Sachsen-Anhalt
Oppurg;;07381;03647;Thüringen
Oranienbaum;;06785;034904;Sachsen-Anhalt
Oranienburg;;16515;03301;Brandenburg
Orbachshof;;74632;07947;Baden-Württemberg
Orbis;;67294;06352;Rheinland-Pfalz
Orenhofen;;54298;06580;Rheinland-Pfalz
Orfgen;;57632;02685;Rheinland-Pfalz
Orlamünde;;07768;036423;Thüringen
Orlenbach;;54595;06556;Rheinland-Pfalz
Orlishausen;;99610;03634;Thüringen
Ormont;;54597;06557;Rheinland-Pfalz
Ornbau;;91737;09826;Bayern
Orsfeld;;54655;06563;Rheinland-Pfalz
Orsingen-Nenzingen;;78359;07771;Baden-Württemberg
Ortenberg;, Hess;63683;06046;Hessen
Ortenberg;, Baden;77799;0781;Baden-Württemberg
Ortenburg;;94496;08542;Bayern
Ortrand;;01990;035755;Brandenburg
Ortwig;;15324;033478;Brandenburg
Osann-Monzel;;54518;06535;Rheinland-Pfalz
Osburg;;54317;06500;Rheinland-Pfalz
Oschatz;;04758;03435;Sachsen
Oschätzchen;;04924;035341;Brandenburg
Oschersleben;(Bode);39387;03949;Sachsen-Anhalt
Osdorf;b Ludwigsfelde;14979;033701;Brandenburg
Osdorf;b Kiel;24251;04346;Schlewig-Holstein
Osendorf;b Evenhausen, Oberbay;83552;08075;Bayern
Osloß;;38557;05362;Niedersachsen
Osnabrück;;49074;0541;Niedersachsen
Osnabrück;;49076;0541;Niedersachsen
Osnabrück;;49078;0541;Niedersachsen
Osnabrück;;49080;0541;Niedersachsen
Osnabrück;;49082;0541;Niedersachsen
Osnabrück;;49084;0541;Niedersachsen
Osnabrück;;49086;0541;Niedersachsen
Osnabrück;;49088;0541;Niedersachsen
Osnabrück;;49090;0541;Niedersachsen
Ossa;;04643;034346;Sachsen
Ossendorf;b Eisenhüttenstadt;15898;033656;Brandenburg
Oßling;;01920;035792;Sachsen
Oßmannstedt;;99510;036462;Thüringen
Ostbevern;;48346;02532;Nordrhein-Westfalen
Osteel;;26529;04931;Niedersachsen
Ostelsheim;;75395;07033;Baden-Württemberg
Osten;, Oste;21756;04776;Niedersachsen
Ostenfeld;(Rendsburg);24790;04331;Schlewig-Holstein
Ostenfeld;(Husum);25872;04845;Schlewig-Holstein
Ostenholz;;29664;05167;Niedersachsen
Osterberg;b Illertissen;89296;08337;Bayern
Osterberg;b Erfurt;99192;;Thüringen
Osterberg;b Erfurt;99192;;Thüringen
Osterbruch;, Niederelbe;21762;04751;Niedersachsen
Osterburg;(Altmark);39606;03937;Sachsen-Anhalt
Osterburken;;74706;06291;Baden-Württemberg
Osterby;b Eckernförde;24367;04351;Schlewig-Holstein
Osterby;b Medelby;24994;04605;Schlewig-Holstein
Ostercappeln;;49179;05473;Niedersachsen
Osterdeich;b Niebüll;25899;;Schlewig-Holstein
Ostereistedt;;27404;04285;Niedersachsen
Osterfeld;b Naumburg, Saale;06721;034422;Sachsen-Anhalt
Osterhausen;b Lutherstadt Eisleben;06295;034776;Sachsen-Anhalt
Osterhever;;25836;04865;Schlewig-Holstein
Osterhofen;, Niederbay;94486;09932;Bayern
Osterholz;b Bopfingen;73441;07362;Baden-Württemberg
Osterholz-Scharmbeck;;27711;04791;Niedersachsen
Osterhorn;;25364;04127;Schlewig-Holstein
Osternienburg;;06386;034973;Sachsen-Anhalt
Osteroda;;04916;03535;Brandenburg
Osterode;am Harz;37520;05522;Niedersachsen
Osterode;am Fallstein;38835;039421;Sachsen-Anhalt
Oster-Ohrstedt;;25885;04847;Schlewig-Holstein
Osterrade;, Dithm;25767;04802;Schlewig-Holstein
Osterrönfeld;;24783;04331;Schlewig-Holstein
Ostersmühle;;56291;;Rheinland-Pfalz
Osterspai;;56340;02627;Rheinland-Pfalz
Osterstedt;;25590;04874;Schlewig-Holstein
Osterwald;b Neuenhaus, Dinkel;49828;05946;Niedersachsen
Osterwald;, Oberbay;82380;08803;Bayern
Osterweddingen;;39171;039205;Sachsen-Anhalt
Osterwieck;;38835;039421;Sachsen-Anhalt
Osterwohle;;29413;03901;Sachsen-Anhalt
Osterzell;;87662;08345;Bayern
Ostfildern;;73760;0711;Baden-Württemberg
Osthausen-Wülfershausen;;99310;036200;Thüringen
Ostheim;v d Rhön;97645;09777;Bayern
Osthofen;, Rheinhess;67574;06242;Rheinland-Pfalz
Ostingersleben;;39343;039052;Sachsen-Anhalt
Ostrach;;88356;07585;Baden-Württemberg
Ostramondra;;99636;036378;Thüringen
Ostrau;b Grimma;04668;034386;Sachsen
Ostrau;, Sachs;04749;034324;Sachsen
Ostrau;b Halle, Saale;06193;034600;Sachsen-Anhalt
Ostrhauderfehn;;26842;04952;Niedersachsen
Östringen;;76684;07259;Baden-Württemberg
Ostritz;;02899;035823;Sachsen
Ostrohe;;25746;0481;Schlewig-Holstein
Ostseebad Ahrenshoop;;18347;038220;Mecklenburg-Vorpommern
Ostseebad Binz;;18609;0381;Mecklenburg-Vorpommern
Ostseebad Boltenhagen;;23946;038825;Mecklenburg-Vorpommern
Ostseebad Boltenhagen Zeltplatz;;23946;038825;Mecklenburg-Vorpommern
Ostseebad Nienhagen;;18211;038203;Mecklenburg-Vorpommern
Ostseebad Prerow;;18375;038233;Mecklenburg-Vorpommern
Ostseebad Rerik;;18230;038294;Mecklenburg-Vorpommern
Ostseebad Rerik Zeltplatz;;18230;0381;Mecklenburg-Vorpommern
Ostseebad Wustrow;;18347;0381;Mecklenburg-Vorpommern
Oststeinbek;;22113;040;Schlewig-Holstein
Othenstorf;;19217;038872;Mecklenburg-Vorpommern
Ötigheim;;76470;07222;Baden-Württemberg
Ötisheim;;75443;07041;Baden-Württemberg
Ottenbach;, Württ;73113;07165;Baden-Württemberg
Ottenbüttel;;25591;04893;Schlewig-Holstein
Ottendorf;b Sebnitz;01855;035971;Sachsen
Ottendorf;b Stadtroda;07646;036426;Thüringen
Ottendorf;b Kiel;24107;0431;Schlewig-Holstein
Ottendorf-Okrilla;;01458;035205;Sachsen
Ottengrün;b Oelsnitz, Vogtl;08606;037434;Sachsen
Ottenhain;b Geithain;04643;034341;Sachsen
Ottenhofen;, Oberbay;85570;08121;Bayern
Ottenhöfen;im Schwarzwald;77883;07842;Baden-Württemberg
Ottensoos;;91242;09123;Bayern
Ottenstein;, Niedersachs;31868;05286;Niedersachsen
Otter;;21259;04282;Niedersachsen
Otterbach;, Pfalz;67731;06301;Rheinland-Pfalz
Otterberg;;67697;06301;Rheinland-Pfalz
Otterfing;;83624;08024;Bayern
Otterndorf;, Niederelbe;21762;04751;Niedersachsen
Ottersberg;b Bremen;28870;04205;Niedersachsen
Ottersheim;b Kirchheimbolanden;67308;06355;Rheinland-Pfalz
Ottersheim;b Landau, Pfalz;76879;06348;Rheinland-Pfalz
Otterskirchen;;94155;08546;Bayern
Otterstadt;;67166;06232;Rheinland-Pfalz
Otterstedt;b Greußen;99718;036370;Thüringen
Ottersweier;;77833;07223;Baden-Württemberg
Otterwisch;;04668;034345;Sachsen
Otting;b Donauwörth;86700;09091;Bayern
Ottmannshausen;;99439;036452;Thüringen
Ottobeuren;;87724;08332;Bayern
Ottobrunn;;85521;089;Bayern
Ottrau;;34633;06639;Hessen
Ottstedt a Berge;;99428;036203;Thüringen
Ottweiler;;66564;06824;Saarland
Otzbach;;36404;036965;Thüringen
Otzberg;;64853;06162;Hessen
Otzing;;94563;09931;Bayern
Ötzingen;;56244;02602;Rheinland-Pfalz
Otzweiler;;55606;06757;Rheinland-Pfalz
Ovelgönne;, Kr Wesermarsch;26939;04480;Niedersachsen
Ovelgünne;;39365;039409;Sachsen-Anhalt
Ovendorferredder;, Gem Groß Buchwald;24637;;Schlewig-Holstein
Overath;, Bergisches Land;51491;02206;Nordrhein-Westfalen
Owen;, Teck;73277;07021;Baden-Württemberg
Owingen;, Bodensee;88696;07551;Baden-Württemberg
Owschlag;;24811;04336;Schlewig-Holstein
Oy-Mittelberg;;87466;08366;Bayern
Oyten;;28876;04207;Niedersachsen
Paaren;;14476;033208;Brandenburg
Paaren im Glien;;14641;033230;Brandenburg
Pabstorf;;38836;039428;Sachsen-Anhalt
Packebusch;;39624;039030;Sachsen-Anhalt
Padenstedt;;24634;04321;Schlewig-Holstein
Paderborn;;33098;05251;Nordrhein-Westfalen
Paderborn;;33100;05251;Nordrhein-Westfalen
Paderborn;;33102;05251;Nordrhein-Westfalen
Paderborn;;33104;05251;Nordrhein-Westfalen
Paderborn;;33106;05251;Nordrhein-Westfalen
Paderborn;;33154;05251;Nordrhein-Westfalen
Paderborn;;33161;05251;Nordrhein-Westfalen
Padingbüttel;;27632;04742;Niedersachsen
Pagensand;, Insel;25377;04125;Schlewig-Holstein
Pähl;;82396;08808;Bayern
Pahlen;;25794;04803;Schlewig-Holstein
Pahlsdorf;;03249;035323;Brandenburg
Pahren;;07937;036628;Thüringen
Painten;b Kelheim;93351;09499;Bayern
Paitzdorf;;07580;036602;Thüringen
Palingen;;23923;038821;Mecklenburg-Vorpommern
Palling;, Oberbay;83349;08629;Bayern
Palzem;;54439;06583;Rheinland-Pfalz
Pampin;;19372;038785;Mecklenburg-Vorpommern
Pampow;b Pasewalk;17322;039744;Mecklenburg-Vorpommern
Pampow;b Schwerin, Meckl;19075;03865;Mecklenburg-Vorpommern
Pamprin;;19246;038851;Mecklenburg-Vorpommern
Panitz;;01594;035268;Sachsen
Panitzsch;;04451;0341;Sachsen
Panker;;24321;04385;Schlewig-Holstein
Panolsreut;;83329;08681;Bayern
Panschwitz-Kuckau;;01920;035796;Sachsen
Pansdorf;, Holst;23689;04504;Schlewig-Holstein
Pansfelde;;06543;034779;Sachsen-Anhalt
Pantelitz;;18442;038321;Mecklenburg-Vorpommern
Panten;;23896;04542;Schlewig-Holstein
Pantenburg;;54531;06572;Rheinland-Pfalz
Panzweiler;b Blankenrath;56865;06545;Rheinland-Pfalz
Papenbrook;;24861;04885;Schlewig-Holstein
Papenbruch;;16909;03394;Brandenburg
Papenburg;;26871;04961;Niedersachsen
Papendorf;b Pasewalk;17309;03973;Mecklenburg-Vorpommern
Papendorf;b Rostock;18059;0381;Mecklenburg-Vorpommern
Papenhagen;b Grimmen;18510;038325;Mecklenburg-Vorpommern
Papenhusen;;23936;038824;Mecklenburg-Vorpommern
Papiermühle;b Neustadt, Orla;07806;036481;Thüringen
Paplitz;b Zossen b Berlin;15837;033704;Brandenburg
Paplitz;b Genthin;39307;039346;Sachsen-Anhalt
Pappenheim;, Mittelfr;91788;09143;Bayern
Papsdorf;;04668;034386;Sachsen
Parber;;19217;038872;Mecklenburg-Vorpommern
Parchau;;39291;03921;Sachsen-Anhalt
Parchen;;39307;039345;Sachsen-Anhalt
Parchim;;19370;03871;Mecklenburg-Vorpommern
Parchow;b Bad Doberan;18233;038294;Mecklenburg-Vorpommern
Parchtitz;;18528;03838;Mecklenburg-Vorpommern
Paretz;;14669;033233;Brandenburg
Parey;b Rathenow;14715;033872;Brandenburg
Parey;, Elbe;39317;039349;Sachsen-Anhalt
Parin;;23948;03881;Mecklenburg-Vorpommern
Parkentin;;18209;038203;Mecklenburg-Vorpommern
Parkstein;;92711;09602;Bayern
Parkstetten;;94365;09421;Bayern
Parlow-Glambeck;;16247;033361;Brandenburg
Parmen-Weggun;;17291;039855;Brandenburg
Parsau;;38470;05368;Niedersachsen
Parsberg;, Oberpf;92331;09492;Bayern
Parschall;;83367;08686;Bayern
Parsdorf;;85599;089;Bayern
Parstein;;16248;033365;Brandenburg
Partenheim;;55288;06732;Rheinland-Pfalz
Partenstein;im Spessart;97846;09355;Bayern
Parthenstein;b Grimma;04668;034293;Sachsen
Parum;b Hagenow;19243;03869;Mecklenburg-Vorpommern
Parzing;;83374;08669;Bayern
Paschel;;54314;06587;Rheinland-Pfalz
Paschwitz;;04838;03423;Sachsen
Pasewalk;;17309;03973;Mecklenburg-Vorpommern
Paska;;07381;036483;Thüringen
Passade;;24253;04344;Schlewig-Holstein
Passau;;94032;0851;Bayern
Passau;;94034;0851;Bayern
Passau;;94036;0851;Bayern
Passee;;23992;038429;Mecklenburg-Vorpommern
Passow;b Gadebusch;19205;03886;Mecklenburg-Vorpommern
Passow;b Lübz;19386;038732;Mecklenburg-Vorpommern
Pastetten;;85669;08124;Bayern
Pastin;;19406;03847;Mecklenburg-Vorpommern
Paterdamm;;14776;;Brandenburg
Paterdamm;;14776;;Brandenburg
Patersberg;;56348;06771;Rheinland-Pfalz
Patersdorf;, Niederbay;94265;09929;Bayern
Pätow;;19230;03883;Mecklenburg-Vorpommern
Pattensen;b Hannover;30982;05101;Niedersachsen
Pätz;;15741;033762;Brandenburg
Patzig;;18528;03838;Mecklenburg-Vorpommern
Paulinenaue;;14641;033237;Brandenburg
Paulinzella;;07422;036739;Thüringen
Paunzhausen;;85307;08444;Bayern
Pausa;/Vogtl.;07952;037432;Sachsen
Pausin;;14641;033231;Brandenburg
Pausitz;;04828;034383;Sachsen
Päwesin;;14778;033838;Brandenburg
Pechbrunn;;95701;09231;Bayern
Pechüle;;14913;033748;Brandenburg
Peckfitz;;39649;039082;Sachsen-Anhalt
Peenemünde;;17449;038371;Mecklenburg-Vorpommern
Peffingen;;54668;06523;Rheinland-Pfalz
Pegau;;04523;034296;Sachsen
Pegestorf;;37619;05533;Niedersachsen
Pegnitz;;91257;09241;Bayern
Peheim;, Gem Molbergen;49696;04479;Niedersachsen
Peickwitz;;01945;035756;Brandenburg
Peine;;31224;05171;Niedersachsen
Peine;;31226;05171;Niedersachsen
Peine;;31228;05171;Niedersachsen
Peißen;b Halle, Saale;06188;0345;Sachsen-Anhalt
Peißen;b Bernburg;06408;03471;Sachsen-Anhalt
Peissen;, Holst;25551;04876;Schlewig-Holstein
Peißenberg;;82380;08803;Bayern
Peiting;;86971;08861;Bayern
Peitz;;03185;035601;Brandenburg
Pellingen;;54331;06588;Rheinland-Pfalz
Pellworm;;25849;04844;Schlewig-Holstein
Pelm;;54570;06591;Rheinland-Pfalz
Pelsin;;17392;03971;Mecklenburg-Vorpommern
Pelzerhaus;;56864;;Rheinland-Pfalz
Pemfling;;93482;09971;Bayern
Penig;;09322;037381;Sachsen
Penkow;;17213;039932;Mecklenburg-Vorpommern
Penkun;;17328;039751;Mecklenburg-Vorpommern
Pennewitt;;19417;038482;Mecklenburg-Vorpommern
Pennewitz;;98708;036783;Thüringen
Pennigsehl;;31621;05028;Niedersachsen
Pentenried;;82349;089;Bayern
Pentling;;93080;0941;Bayern
Penzberg;;82377;08856;Bayern
Penzin;b Bützow;18249;038464;Mecklenburg-Vorpommern
Penzin;b Sternberg;19412;038483;Mecklenburg-Vorpommern
Penzing;i Auerbergland;86929;08191;Bayern
Penzlin;b Waren;17217;03962;Mecklenburg-Vorpommern
Pepelow;;18233;038294;Mecklenburg-Vorpommern
Perach;, Kr Altötting;84567;08670;Bayern
Perasdorf;;94366;09962;Bayern
Perdöhl;;19243;038852;Mecklenburg-Vorpommern
Peritz;;01609;035265;Sachsen
Perkam;;94368;09429;Bayern
Perl;, Saar;66706;06867;Saarland
Perleberg;;19348;03876;Brandenburg
Perlesreut;;94157;08555;Bayern
Perlin;;19209;03869;Mecklenburg-Vorpommern
Perne;;01665;035245;Sachsen
Perniek;;23992;038422;Mecklenburg-Vorpommern
Pernitz;;14778;;Brandenburg
Perscheid;;55430;06744;Rheinland-Pfalz
Perwenitz;;14641;03302;Brandenburg
Peseckendorf;;39398;039408;Sachsen-Anhalt
Pessin;;14641;033237;Brandenburg
Pesterwitz;;01705;0351;Sachsen
Peterhof;b Gersthofen;86456;08230;Bayern
Petersaurach;;91580;09872;Bayern
Petersberg;b Döbeln;04720;034325;Sachsen
Petersberg;b Halle, Saale;06193;034600;Sachsen-Anhalt
Petersberg;b Eisenberg, Thür;07616;036691;Thüringen
Petersberg;b Schönberg, Meckl;23923;038828;Mecklenburg-Vorpommern
Petersberg;, Kr Fulda;36100;0661;Hessen
Petersberg;, Pfalz;66989;06331;Rheinland-Pfalz
Petersdorf;b Frankfurt, Oder;15236;033608;Brandenburg
Petersdorf;b Fürstenwalde, Spree;15518;033631;Brandenburg
Petersdorf;b Strasburg;17348;03963;Mecklenburg-Vorpommern
Petersdorf;b Aichach;86574;08237;Bayern
Petersdorf;b Nordhausen;99735;03631;Thüringen
Petersdorf b Wismar, Meckl;;23966;03841;Mecklenburg-Vorpommern
Petershagen;b Fredersdorf b Berlin;15370;033439;Brandenburg
Petershagen;, Weser;32469;05707;Nordrhein-Westfalen
Petershausen;, Oberbay;85238;08137;Bayern
Peterskoogsdeich;;25840;;Schlewig-Holstein
Peterslahr;;57632;02685;Rheinland-Pfalz
Petersmark;b Stendal;39579;;Sachsen-Anhalt
Petersroda;;06809;034954;Sachsen-Anhalt
Peterswald-Löffelscheid;;56858;06545;Rheinland-Pfalz
Petkus;;14913;033745;Brandenburg
Petriroda;;99887;036253;Thüringen
Pettendorf;, Oberpf;93186;0941;Bayern
Petting;;83367;08686;Bayern
Pettstadt;, Oberfr;96175;09502;Bayern
Petznick;;17268;03987;Brandenburg
Petzow;;14542;03327;Brandenburg
Peukendorf;;99713;036020;Thüringen
Peuschen;;07389;03647;Thüringen
Pfaffendorf;b Beeskow;15848;033672;Brandenburg
Pfaffenhausen;, Schwab;87772;08265;Bayern
Pfaffenhäusle;;73574;07175;Baden-Württemberg
Pfaffenhofen;, Württ;74397;07046;Baden-Württemberg
Pfaffenhofen;a d Glonn;85235;08134;Bayern
Pfaffenhofen;a d Ilm;85276;08441;Bayern
Pfaffenhofen;a d Roth;89284;07302;Bayern
Pfaffen-Schwabenheim;;55546;06701;Rheinland-Pfalz
Pfaffenstube;;72297;07448;Baden-Württemberg
Pfaffenweiler;, Breisgau;79292;07664;Baden-Württemberg

Pfaffing;a d Attel;83539;08076;Bayern
Pfaffing;, Gem Amerang;83552;08076;Bayern
Pfaffroda;b Olbernhau;09526;037360;Sachsen
Pfaffroda-Dittmannsdorf;;09526;037360;Sachsen
Pfaffroda-Dörnthal;;09526;037360;Sachsen
Pfaffroda-Hallbach;;09526;037360;Sachsen
Pfaffroda-Haselbach;;09526;037360;Sachsen
Pfaffroda-Hutha;;09526;037360;Sachsen
Pfaffroda-Schönfeld;;09526;037360;Sachsen
Pfaffschwende;;37308;036082;Thüringen
Pfahlbronner Mühle;;73655;;Baden-Württemberg
Pfahlhaus;, Ostfriesl;26409;;Niedersachsen
Pfahlhof;;74360;07062;Baden-Württemberg
Pfakofen;;93101;09451;Bayern
Pfalzfeld;, Hunsrück;56291;06746;Rheinland-Pfalz
Pfalzfeld;, Pfalz;67294;;Rheinland-Pfalz
Pfalzgrafenweiler;;72285;07445;Baden-Württemberg
Pfalzheim;;16818;033924;Brandenburg
Pfalzkyll;;54662;;Rheinland-Pfalz
Pfannenschoppen;;57577;;Rheinland-Pfalz
Pfarrkirchen;, Niederbay;84347;08561;Bayern
Pfarrweisach;;96176;09535;Bayern
Pfatter;;93102;09481;Bayern
Pfedelbach;;74629;07941;Baden-Württemberg
Pfeffelbach;;66871;06384;Rheinland-Pfalz
Pfeffenhausen;;84076;08782;Bayern
Pfeilhalde;;73529;;Baden-Württemberg
Pfeilhalde;;73529;;Baden-Württemberg
Pfenninghof;;92345;08461;Bayern
Pferdingsleben;;99869;036258;Thüringen
Pferdsdorf;;36414;036962;Thüringen
Pfiffelbach;;99510;036462;Thüringen
Pfinztal;;76327;07240;Baden-Württemberg
Pflaumdorf;;86926;08193;Bayern
Pflückuff;b Torgau;04889;03421;Sachsen
Pflügkuff;;14913;033747;Brandenburg
Pfofeld;;91738;09834;Bayern
Pförring;;85104;08403;Bayern
Pforzen;;87666;08346;Bayern
Pforzheim;;75172;07231;Baden-Württemberg
Pforzheim;;75173;07231;Baden-Württemberg
Pforzheim;;75175;07231;Baden-Württemberg
Pforzheim;;75177;07231;Baden-Württemberg
Pforzheim;;75179;07231;Baden-Württemberg
Pforzheim;;75180;07231;Baden-Württemberg
Pforzheim;;75181;07231;Baden-Württemberg
Pfreimd;;92536;09606;Bayern
Pfronstetten;;72539;07388;Baden-Württemberg
Pfronten;;87459;08363;Bayern
Pfuhlsborn;;99510;036464;Thüringen
Pfullendorf;, Baden;88630;07552;Baden-Württemberg
Pfullingen;;72793;07128;Baden-Württemberg
Pfungstadt;;64319;06157;Hessen
Philadelphia;;15859;033678;Brandenburg
Philippsburg;, Baden;76661;07256;Baden-Württemberg
Philippsheim;;54662;06565;Rheinland-Pfalz
Philippsreut;;94158;08550;Bayern
Philippsthal;b Potsdam;14532;033200;Brandenburg
Philippsthal;(Werra);36269;06620;Hessen
Phöben;;14542;03327;Brandenburg
Pian;;16775;033089;Brandenburg
Picher;;19230;038751;Mecklenburg-Vorpommern
Pickließem;;54647;06565;Rheinland-Pfalz
Piding;;83451;08651;Bayern
Pielenhofen;;93188;09409;Bayern
Piesau;;98739;036701;Thüringen
Piesport;;54498;06507;Rheinland-Pfalz
Piethen;;06388;034976;Sachsen-Anhalt
Pietzpuhl;;39291;039222;Sachsen-Anhalt
Pieverstorf;b Grevesmühlen;23936;038822;Mecklenburg-Vorpommern
Pillgram;;15236;033608;Brandenburg
Pillig;;56753;02605;Rheinland-Pfalz
Pilligerheck;;56754;02672;Rheinland-Pfalz
Pillingsdorf;;07819;036481;Thüringen
Pilsach;;92367;09181;Bayern
Pilsting;;94431;09953;Bayern
Pingelshagen;;19069;0385;Mecklenburg-Vorpommern
Pinnau;;19273;038845;Niedersachsen
Pinneberg;;25421;04101;Schlewig-Holstein
Pinnewitz;;01623;035246;Sachsen
Pinnow;b Angermünde;16278;033335;Brandenburg
Pinnow;b Schwerin, Meckl;19065;03861;Mecklenburg-Vorpommern
Pinnow;b Perleberg;19357;038788;Brandenburg
Pinnow-Heideland;;03172;;Brandenburg
Pinnowhof;;23992;038429;Mecklenburg-Vorpommern
Pintesfeld;;54649;06554;Rheinland-Pfalz
Pinzberg;;91361;09191;Bayern
Pirk;b Weiden, Oberpf;92712;0961;Bayern
Pirmannsteinerhof;;66482;06339;Rheinland-Pfalz
Pirmasens;;66953;06331;Rheinland-Pfalz
Pirmasens;;66954;06331;Rheinland-Pfalz
Pirmasens;;66955;06331;Rheinland-Pfalz
Pirna;;01796;03501;Sachsen
Pirow;;19348;038785;Brandenburg
Pischertshofen;;82282;08145;Bayern
Piskaborn;;06343;034782;Sachsen-Anhalt
Pistre;, Gem Hettingen;72501;07574;Baden-Württemberg
Pitschen-Pickel;;15926;035454;Brandenburg
Pittenbach;;54595;06556;Rheinland-Pfalz
Pittenhart;;83132;08624;Bayern
Plaaz;;18276;038455;Mecklenburg-Vorpommern
Plaidt;;56637;02632;Rheinland-Pfalz
Planegg;;82152;089;Bayern
Planitz;;18356;038231;Mecklenburg-Vorpommern
Plänitz;;16845;033970;Brandenburg
Planken;;39345;03904;Sachsen-Anhalt
Plankenfels;;95515;09204;Bayern
Plankstadt;;68723;06202;Baden-Württemberg
Planschwitz;;08606;037436;Sachsen
Plascheid;;54673;06564;Rheinland-Pfalz
Plate;, Meckl;19086;03861;Mecklenburg-Vorpommern
Platenhof;b Perleberg;19348;038793;Brandenburg
Platkow;;15306;03346;Brandenburg
Platschow;;19372;038785;Mecklenburg-Vorpommern
Plattele;;82467;08821;Bayern
Platten;b Wittlich;54518;06535;Rheinland-Pfalz
Plattenberg;b Stein a d Traun;83371;;Bayern
Plattenburg;;19336;038791;Brandenburg
Plattenburg Forsthaus;;19322;;Brandenburg
Plattenthal;;09488;03733;Sachsen
Plattkow;;15848;035476;Brandenburg
Plattling;;94447;09931;Bayern
Plau;;19395;038735;Mecklenburg-Vorpommern
Plaue;, Thür;99338;036207;Thüringen
Plauen;, Vogtl;08523;03741;Sachsen
Plauen;, Vogtl;08525;03741;Sachsen
Plauen;, Vogtl;08527;03741;Sachsen
Plauen;, Vogtl;08529;03741;Sachsen
Plauerhagen;;19395;038735;Mecklenburg-Vorpommern
Plech;;91287;09244;Bayern
Pleckhausen;;56593;02687;Rheinland-Pfalz
Pleckhausermühle;;56593;02684;Rheinland-Pfalz
Pleidelsheim;;74385;07144;Baden-Württemberg
Plein;;54518;06571;Rheinland-Pfalz
Pleinfeld;;91785;09144;Bayern
Pleiskirchen;;84568;08635;Bayern
Pleißa;;09246;0371;Sachsen
Pleisweiler-Oberhofen;;76889;06343;Rheinland-Pfalz
Pleitersheim;;55576;06701;Rheinland-Pfalz
Pleizenhausen;;55469;06761;Rheinland-Pfalz
Pleß;, Iller;87773;08335;Bayern
Plessa;;04928;03533;Brandenburg
Plessow;;14542;03327;Brandenburg
Plettenberg;;58840;02391;Nordrhein-Westfalen
Pleystein;;92714;09654;Bayern
Pliening;;85652;08121;Bayern
Pliezhausen;;72124;07127;Baden-Württemberg
Plirup;;24808;04337;Schlewig-Holstein
Plochingen;;73207;07153;Baden-Württemberg
Plodda;;06774;034955;Sachsen-Anhalt
Plön;;24306;04522;Schlewig-Holstein
Plößberg;, Oberpf;95703;09636;Bayern
Plossig;;06922;035386;Sachsen-Anhalt
Plothen;;07907;036648;Thüringen
Plotitz;;01594;035268;Sachsen
Plötz;b Halle, Saale;06193;034603;Sachsen-Anhalt
Plötz;b Demmin;17129;039997;Mecklenburg-Vorpommern
Plötzin;;14542;03327;Brandenburg
Plötzkau;;06425;034692;Sachsen-Anhalt
Plötzky;;39245;039200;Sachsen-Anhalt
Plotzsägemühle;;76332;;Baden-Württemberg
Plöwen;;17321;039754;Mecklenburg-Vorpommern
Plüderhausen;;73655;07181;Baden-Württemberg
Plüschow;;23936;03841;Mecklenburg-Vorpommern
Plütscheid;;54597;06554;Rheinland-Pfalz
Pluwig;;54316;06588;Rheinland-Pfalz
Pobershau;;09496;037363;Sachsen
Pobzig;;06429;034721;Sachsen-Anhalt
Pockau;, Flöhatal;09509;037363;Sachsen
Pocking;;94060;08531;Bayern
Pöcking;, Kr Starnberg;82343;08157;Bayern
Pödelist;;06618;034464;Sachsen-Anhalt
Podelzig;;15326;033601;Brandenburg
Pogeez;;23911;04541;Schlewig-Holstein
Pogez;;19217;038873;Mecklenburg-Vorpommern
Poggelow;;17168;039977;Mecklenburg-Vorpommern
Poggensee;b Nusse;23896;04543;Schlewig-Holstein
Pogreß;;19243;038852;Mecklenburg-Vorpommern
Pohl;b Nassau, Lahn;56357;06772;Rheinland-Pfalz
Pöhla;, Erzgeb;08352;03774;Sachsen
Pohle;;31867;05723;Niedersachsen
Pohlheim;;35415;06403;Hessen
Pohlitz;b Eisenhüttenstadt;15890;033653;Brandenburg
Pohnsdorf;b Preetz, Holst;24211;04342;Schlewig-Holstein
Pohnstorf;b Grevesmühlen;23936;038824;Mecklenburg-Vorpommern
Pohrsdorf;;01737;035203;Sachsen
Pöhsig;b Riesa;01594;035268;Sachsen
Pöhsig;b Grimma;04668;03437;Sachsen
Poing;b München;85586;08121;Bayern
Point;b Neukirchen a Teisenberg;83364;08666;Bayern
Poischendorf;;23992;038429;Mecklenburg-Vorpommern
Poischendorf Molkerei;;23992;038429;Mecklenburg-Vorpommern
Poischwitz;;04668;034386;Sachsen
Pokrent;;19205;038874;Mecklenburg-Vorpommern
Polch;;56751;02654;Rheinland-Pfalz
Polcherholz;;56761;02653;Rheinland-Pfalz
Pölchow;;18059;038207;Mecklenburg-Vorpommern
Polenz;b Wurzen;04821;034292;Sachsen
Polenzko;;39264;039248;Sachsen-Anhalt
Poley;b Bernburg;06408;03471;Sachsen-Anhalt
Pölich;;54340;06507;Rheinland-Pfalz
Pölitz;b Güstrow;18299;038455;Mecklenburg-Vorpommern
Pölitz;, Kr Stormarn;23847;04531;Schlewig-Holstein
Polle;, Weser;37647;05535;Niedersachsen
Polleben;;06295;03475;Sachsen-Anhalt
Pollenfeld;;85131;08421;Bayern
Pollhagen;;31718;05721;Niedersachsen
Polling;, Kr Weilheim-Schongau;82398;0881;Bayern
Polling;, Kr Mühldorf a Inn;84570;08631;Bayern
Pollitz;;39615;039395;Sachsen-Anhalt
Pöllwitz;;07937;037432;Thüringen
Pölsfeld;;06528;03464;Sachsen-Anhalt
Polsingen;;91805;09093;Bayern
Polßen;;16278;039861;Brandenburg
Poltnitz;;19376;038729;Mecklenburg-Vorpommern
Polz;b Dömitz;19303;038758;Mecklenburg-Vorpommern
Polzen;;04916;03535;Brandenburg
Pölzig;;07554;036695;Thüringen
Polzow;, Vorpommern;17309;039743;Mecklenburg-Vorpommern
Pommelsbrunn;;91224;09154;Bayern
Pömmelte;;39249;039298;Sachsen-Anhalt
Pommerby;b Gelting, Angeln;24395;04643;Schlewig-Holstein
Pommern;, Mosel;56829;02672;Rheinland-Pfalz
Pommersfelden;;96178;09548;Bayern
Pomster;;53534;02691;Rheinland-Pfalz
Ponitz;b Schmölln, Thür;04639;0371;Thüringen
Ponitz;b Perleberg;19348;038784;Brandenburg
Ponnsdorf;;03238;03531;Brandenburg
Popelau;;19273;038841;Niedersachsen
Poppenbüll;;25836;04865;Schlewig-Holstein
Poppendorf;b Rostock;18184;038202;Mecklenburg-Vorpommern
Poppenhausen;(Wasserkuppe);36163;06658;Hessen
Poppenhausen;, Unterfr;97490;09725;Bayern
Poppenricht;;92284;09621;Bayern
Poppenwind;b Eisfeld;98673;036878;Thüringen
Pörmitz;;07907;03663;Thüringen
Pörnbach;;85309;08446;Bayern
Porschdorf;;01814;035022;Sachsen
Porst;;06369;03496;Sachsen-Anhalt
Porta Westfalica;;32457;05706;Nordrhein-Westfalen
Pöschendorf;;25560;04892;Schlewig-Holstein
Poseritz;;18574;038307;Mecklenburg-Vorpommern
Poserna;;06686;03443;Sachsen-Anhalt
Pösing;;93483;09461;Bayern
Posseck;b Oelsnitz, Vogtl;08606;037434;Sachsen
Possendorf;b Freital;01728;035206;Sachsen
Possendorf;b Weimar, Thür;99438;03643;Thüringen
Possendorf;b Weimar, Thür;99438;03643;Thüringen
Pößneck;;07381;03647;Thüringen
Postau;;84103;08702;Bayern
Postbauer-Heng;;92353;09188;Bayern
Posterstein;;04626;03762;Thüringen
Postfeld;;24211;04342;Schlewig-Holstein
Posthof;b Pfaffenhofen a d Ilm;85276;;Bayern
Postlin;;19357;038797;Brandenburg
Postlow;;17391;039728;Mecklenburg-Vorpommern
Postmünster;;84389;08561;Bayern
Pötenitz;;23942;038826;Mecklenburg-Vorpommern
Potsdam;;14467;0331;Brandenburg
Potsdam;;14469;0331;Brandenburg
Potsdam;;14471;0331;Brandenburg
Potsdam;;14473;0331;Brandenburg
Potsdam;;14478;0331;Brandenburg
Potsdam;;14480;0331;Brandenburg
Potsdam;;14482;0331;Brandenburg
Pottenstein;, Oberfr;91278;09243;Bayern
Pottiga;;07366;036642;Thüringen
Pöttmes;;86554;08253;Bayern
Pottum;;56459;02664;Rheinland-Pfalz
Potzehne;;39638;039087;Sachsen-Anhalt
Potzlow;;17291;039863;Brandenburg
Pouch;;06774;03493;Sachsen-Anhalt
Poxdorf;b Eisenberg, Thür;07616;036692;Thüringen
Poxdorf;, Oberfr;91099;09133;Bayern
Poyenberg;;25581;04877;Schlewig-Holstein
Pracht;b Hamm, Sieg;57589;0261;Rheinland-Pfalz
Prackenbach;;94267;09942;Bayern
Prädikow;;15345;033436;Brandenburg
Präfekturhof;;56759;;Rheinland-Pfalz
Pragsdorf;;17094;03966;Mecklenburg-Vorpommern
Prambach;, Gem Hettenshausen;85304;08441;Bayern
Pramsdorf;b Brandenburg an der Havel;14793;;Brandenburg
Pramsdorf;b Zossen b Berlin;15834;033708;Brandenburg
Prasdorf;;24253;04348;Schlewig-Holstein
Pratau;;06888;03491;Sachsen-Anhalt
Prath;;56346;06771;Rheinland-Pfalz
Prebberede;;17168;039976;Mecklenburg-Vorpommern
Prebitz;;95473;09270;Bayern
Preddöhl;;16928;033986;Brandenburg
Preetz;b Stralsund;18445;038323;Mecklenburg-Vorpommern
Preetz;, Holst;24211;04342;Schlewig-Holstein
Preischeid;;54689;06550;Rheinland-Pfalz
Preischeiderley;;54673;06524;Rheinland-Pfalz
Preist;;54664;06562;Rheinland-Pfalz
Prem;, Oberbay;86984;08862;Bayern
Premnitz;;14727;03386;Brandenburg
Premsendorf;;06926;035389;Sachsen-Anhalt
Premslin;;19357;038797;Brandenburg
Prenden;;16348;033396;Brandenburg
Prensdorf;;15936;035451;Brandenburg
Prenzlau;;17291;03984;Brandenburg
Preschen;;03159;035600;Brandenburg
Pressath;;92690;09644;Bayern
Presseck;;95355;09222;Bayern
Pressel;;04849;034243;Sachsen
Pressig;;96332;09265;Bayern
Prestewitz;;04924;035341;Brandenburg
Preten;;19273;038841;Niedersachsen
Pretschen;;15910;035476;Brandenburg
Prettin;;06922;035386;Sachsen-Anhalt
Pretzfeld;;91362;09194;Bayern
Pretzien;;39245;039200;Sachsen-Anhalt
Pretzier;;29416;039037;Sachsen-Anhalt
Pretzsch;b Weißenfels;06667;034926;Sachsen-Anhalt
Pretzsch;(Elbe);06909;034926;Sachsen-Anhalt
Pretzschendorf;;01774;035058;Sachsen
Preußisch Oldendorf;;32361;05742;Nordrhein-Westfalen
Preußisch Oldendorf;;32361;05742;Nordrhein-Westfalen
Preußlitz;;06408;034722;Sachsen-Anhalt
Preußnitz;;14806;033841;Brandenburg
Prezelle;;29491;05848;Niedersachsen
Priborn;;17209;039923;Mecklenburg-Vorpommern
Prichsenstadt;;97357;09383;Bayern
Prien;a. Chiemsee;83209;08051;Bayern
Priepert;;17255;039828;Mecklenburg-Vorpommern
Prieros;;15752;033768;Brandenburg
Prieschka;;04924;035341;Brandenburg
Priesen;b Lommatzsch;01623;034325;Sachsen
Priesendorf;;96170;09549;Bayern
Priesitz;;06909;034926;Sachsen-Anhalt
Prießen;;03253;035327;Brandenburg
Prießnitz;b Geithain;04651;034345;Sachsen
Prießnitz;b Naumburg, Saale;06618;034466;Sachsen-Anhalt
Priester;b Delitzsch;04509;034295;Sachsen
Priestewitz;;01561;035249;Sachsen
Prietzen;;14715;033875;Brandenburg
Primank;;19372;038726;Mecklenburg-Vorpommern
Prinzenmoor;;24805;0431;Schlewig-Holstein
Prinzhöfte;;27243;04244;Niedersachsen
Priort;;14641;033234;Brandenburg
Pripsleben;;17091;03961;Mecklenburg-Vorpommern
Prisannewitz;;18196;038208;Mecklenburg-Vorpommern
Prisdorf;;25497;04101;Schlewig-Holstein
Prislich;;19300;038756;Mecklenburg-Vorpommern
Prittitz;;06667;034445;Sachsen-Anhalt
Prittriching;;86931;08206;Bayern
Pritzerbe;;14798;033834;Brandenburg
Pritzier;b Hagenow;19230;038856;Mecklenburg-Vorpommern
Pritzwalk;;16928;03395;Brandenburg
Privelack;;19273;038845;Niedersachsen
Probst Jesar;;19249;038855;Mecklenburg-Vorpommern
Probsteierhagen;;24253;04348;Schlewig-Holstein
Probstzella;;07330;036735;Thüringen
Prödel;;39264;039242;Sachsen-Anhalt
Profen;;06725;034424;Sachsen-Anhalt
Profit;;24361;04356;Schlewig-Holstein
Prohn;;18445;038323;Mecklenburg-Vorpommern
Pronsfeld;;54597;06556;Rheinland-Pfalz
Pronstorf;;23820;04553;Schlewig-Holstein
Proschim;;03130;035751;Brandenburg
Proschwitz;b Meißen, Sachs;01665;03521;Sachsen
Proseken;;23968;038428;Mecklenburg-Vorpommern
Prösen;;04932;03533;Brandenburg
Prosigk;;06369;034978;Sachsen-Anhalt
Prositz;b Riesa;01594;035268;Sachsen
Prosselsheim;;97279;09386;Bayern
Proßmarke;;04936;035364;Brandenburg
Pröttlin;;19357;038788;Brandenburg
Prötzel;;15345;033436;Brandenburg
Protzen;;16833;033932;Brandenburg
Pruchten;;18356;038231;Mecklenburg-Vorpommern
Prüfern;;04720;034325;Sachsen
Prüm;;54595;06551;Rheinland-Pfalz
Prümzurlay;;54668;06523;Rheinland-Pfalz
Prusdorf;;18320;038225;Mecklenburg-Vorpommern
Prutting;;83134;08036;Bayern
Prützke;;14797;033835;Brandenburg
Prüzen;;18276;038450;Mecklenburg-Vorpommern
Püchau;;04828;03425;Sachsen
Püchersreuth;;92715;09603;Bayern
Puchheim;, Oberbay;82178;089;Bayern
Puchow;;17217;03962;Mecklenburg-Vorpommern
Pudagla;;17429;038379;Mecklenburg-Vorpommern
Puderbach;, Westerw;56305;02684;Rheinland-Pfalz
Püggen;b Salzwedel;29416;039035;Sachsen-Anhalt
Pukholt;;24354;04355;Schlewig-Holstein
Pulheim;;50259;02238;Nordrhein-Westfalen
Pullach;i. Isartal;82049;089;Bayern
Pullenreuth;;95704;09234;Bayern
Pulow;;17440;038374;Mecklenburg-Vorpommern
Puls;;25560;04892;Schlewig-Holstein
Pulsnitz;;01896;035955;Sachsen
Pulspforde;;39264;03923;Sachsen-Anhalt
Pulvermühle;b Kirchheimbolanden;67292;;Rheinland-Pfalz
Pünderich;;56862;0261;Rheinland-Pfalz
Punzing;;94565;08541;Bayern
Purfing;;85646;08106;Bayern
Pürgen;;86932;08196;Bayern
Purtschellerhaus;;83471;;Bayern
Purzien;;06925;035385;Sachsen-Anhalt
Puschendorf;;90617;09101;Bayern
Püschow;;18239;038295;Mecklenburg-Vorpommern
Puschwitz;b Bautzen;02699;035933;Sachsen
Pustohl;b Bad Doberan;18239;038297;Mecklenburg-Vorpommern
Putbus;;18581;038301;Mecklenburg-Vorpommern
Putgarten;;18556;038391;Mecklenburg-Vorpommern
Putlitz;;16949;033981;Brandenburg
Püttelkow;;19243;038852;Mecklenburg-Vorpommern
Puttgarden;;23769;04371;Schlewig-Holstein
Püttlingen;;66346;06898;Saarland
Putzar;;17392;039722;Mecklenburg-Vorpommern
Putzbrunn;;85640;089;Bayern
Putzkau;;01877;03594;Sachsen
Pützlingen;;99735;036337;Thüringen
Pyrbaum;;90602;09180;Bayern
Quakenbrück;;49610;05431;Niedersachsen
Qualitz;;18249;038462;Mecklenburg-Vorpommern
Quappendorf;;15320;033476;Brandenburg
Quarnbek;;24107;04340;Schlewig-Holstein
Quarnstedt;b Wrist;25563;04822;Schlewig-Holstein
Quaschwitz;;07389;036484;Thüringen
Quassel;;19249;038855;Mecklenburg-Vorpommern
Quaßliner Mühle;;19386;038733;Mecklenburg-Vorpommern
Quedlinburg;;06484;03946;Sachsen-Anhalt
Queidersbach;;66851;06371;Rheinland-Pfalz
Queienfeld;;98631;036944;Thüringen
Queis;;06188;034602;Sachsen-Anhalt
Quellendorf;;06386;034977;Sachsen-Anhalt
Quendorf;;48465;05923;Niedersachsen
Quenstedt;;06333;03473;Sachsen-Anhalt
Querenhorst;;38368;05358;Niedersachsen
Querfurt;;06268;034771;Sachsen-Anhalt
Quermathen;;14641;033239;Brandenburg
Quern;;24972;04632;Schlewig-Holstein
Quernheim;b Diepholz;49448;05447;Niedersachsen
Quernheimerbruch;;49459;;Niedersachsen
Quersa;;01561;035248;Sachsen
Querstedt;;39579;039324;Sachsen-Anhalt
Questenberg;;06536;034651;Sachsen-Anhalt
Questin;b Grevesmühlen;23936;03881;Mecklenburg-Vorpommern
Quetzdölsdorf;;06780;034604;Sachsen-Anhalt
Quickborn;, Kr Pinneberg;25451;04106;Schlewig-Holstein
Quickborn;, Dithm;25712;04106;Schlewig-Holstein
Quiddelbach;;53518;02691;Rheinland-Pfalz
Quierschied;;66287;06897;Saarland
Quillschina;;06179;034609;Sachsen-Anhalt
Quirla;;07646;036428;Thüringen
Quirnbach;, Westerw;56242;02626;Rheinland-Pfalz
Quirnbach;, Pfalz;66909;06383;Rheinland-Pfalz
Quirnheim;;67280;06359;Rheinland-Pfalz
Quittelsdorf;;07422;036739;Thüringen
Quitzdorf am See;;02906;03588;Sachsen
Quitzerow;;17111;03998;Mecklenburg-Vorpommern
Quitzöbel;;19336;038791;Brandenburg
Quitzow;;19348;03876;Brandenburg
Quolsdorf;;02923;035894;Sachsen
Raa-Besenbek;;25335;04121;Schlewig-Holstein
Rabel;;24376;04642;Schlewig-Holstein
Raben;b Niemegk;14823;033848;Brandenburg
Raben Steinfeld;;19065;03860;Mecklenburg-Vorpommern
Rabenau;, Sachs;01734;0351;Sachsen
Rabenau;, Hess;35466;06407;Hessen
Rabenäußig;;96528;03675;Thüringen
Rabenholz;b Gelting, Angeln;24395;04643;Schlewig-Holstein
Rabenholzlück;;24891;04646;Schlewig-Holstein
Rabenkirchen-Faulück;;24407;04641;Schlewig-Holstein
Räbke;;38375;05355;Niedersachsen
Räckelwitz;;01920;035796;Sachsen
Rackith;;06901;034927;Sachsen-Anhalt
Racksen;;57612;02682;Rheinland-Pfalz
Rackwitz;;04519;034294;Sachsen
Radbruch;;21449;04178;Niedersachsen
Raddestorf;;31604;05765;Niedersachsen
Raddingsdorf;;19217;038873;Mecklenburg-Vorpommern
Raddusch;;03226;035433;Brandenburg
Rade;b Jessen, Elster;06917;035388;Sachsen-Anhalt
Rade;b. Hohenwestedt;24594;04871;Schlewig-Holstein
Rade;b. Rendsburg;24790;04331;Schlewig-Holstein
Rade;b Fitzbek;25579;04877;Schlewig-Holstein
Radeberg;, Sachs;01454;03528;Sachsen
Radebeul;;01445;0351;Sachsen
Radeburg;;01471;035208;Sachsen
Radegast;b Köthen, Anh;06369;034978;Sachsen-Anhalt
Radegast;b Bad Doberan;18239;038295;Mecklenburg-Vorpommern
Radegast;b Gadebusch;19205;03886;Mecklenburg-Vorpommern
Radekow;;16307;033333;Brandenburg
Rädel;;14797;03382;Brandenburg
Radeland;b Baruth, Mark;15837;033704;Brandenburg
Rademin;;29416;039034;Sachsen-Anhalt
Radensleben;;16818;033925;Brandenburg
Rader Insel;;24794;04331;Schlewig-Holstein
Radevormwald;;42477;02195;Nordrhein-Westfalen
Radewege;;14778;033837;Brandenburg
Radgendorf;;02763;03583;Sachsen
Radibor;;02627;035935;Sachsen
Rädigke;;14823;033848;Brandenburg
Radinkendorf;;15848;03366;Brandenburg
Radis;;06773;034953;Sachsen-Anhalt
Radisleben;;06463;039483;Sachsen-Anhalt
Radolfzell;am Bodensee;78315;07732;Baden-Württemberg
Radthal;;83739;08025;Bayern
Raduhn;;19374;038722;Mecklenburg-Vorpommern
Raesfeld;;46348;02865;Nordrhein-Westfalen
Rägelin;;16818;033924;Brandenburg
Ragewitz;b Grimma;04668;034385;Sachsen
Ragösen;b Dessau, Anh;06862;034907;Sachsen-Anhalt
Ragösen;b Belzig;14806;033846;Brandenburg
Ragow;, Spree;03222;03542;Brandenburg
Ragow;b Königs Wusterhausen;15749;033764;Brandenburg
Ragow;b Beeskow;15848;03366;Brandenburg
Raguhn;;06779;034906;Sachsen-Anhalt
Raguth;;19243;038853;Mecklenburg-Vorpommern
Rahden;, Westf;32369;05771;Nordrhein-Westfalen
Rahnisdorf;;04916;03535;Brandenburg
Rahnsdorf;b Lutherstadt Wittenberg;06895;034924;Sachsen-Anhalt
Raich;;79692;07629;Baden-Württemberg
Rain;, Lech;86641;09002;Bayern
Rain;, Niederbay;94369;09429;Bayern
Rainau;, Württ;73492;07961;Baden-Württemberg
Rainer;b Neukirchen a Teisenberg;83364;08666;Bayern
Raisdorf;;24223;04307;Schlewig-Holstein
Raisting;, Oberbay;82399;08807;Bayern
Raitenbuch;, Mittelfr;91790;09147;Bayern
Rakow;b Neubukow;18233;038294;Mecklenburg-Vorpommern
Ralbitz-Rosenthal;;01920;035796;Sachsen
Ralingen;a d Sauer;54310;06585;Rheinland-Pfalz
Ralswiek;;18528;03838;Mecklenburg-Vorpommern
Rambeel;;19205;03886;Mecklenburg-Vorpommern
Ramberg;, Pfalz;76857;06345;Rheinland-Pfalz
Rambin;;18573;038306;Mecklenburg-Vorpommern
Rambow;b Wittenberge;19336;038784;Brandenburg
Rambow;b Wismar, Meckl;23966;03841;Mecklenburg-Vorpommern
Rambow a See;;19309;038781;Brandenburg
Ramerberg;;83561;08039;Bayern
Ramgraben;;83329;08681;Bayern
Ramhusen;;25715;04851;Schlewig-Holstein
Ramin;;17321;039749;Mecklenburg-Vorpommern
Rammelsbach;, Pfalz;66887;06381;Rheinland-Pfalz
Rammenau;;01877;03594;Sachsen
Rammertshofen;;82282;08145;Bayern
Rammingen;, Schwab;86871;08245;Bayern
Rammingen;, Württ;89192;0731;Baden-Württemberg
Rampe;b Schwerin, Meckl;19067;03866;Mecklenburg-Vorpommern
Ramsau;b. Berchtesgaden;83486;08657;Bayern
Ramsen;, Pfalz;67305;06351;Rheinland-Pfalz
Ramsin;;06794;034954;Sachsen-Anhalt
Ramsla;;99439;036452;Thüringen
Ramstedt;, Nordfriesl;25876;04884;Schlewig-Holstein
Ramstein-Miesenbach;;66877;06371;Rheinland-Pfalz
Ramsthal;;97729;09704;Bayern
Randersacker;;97236;0931;Bayern
Rangendingen;;72414;07471;Baden-Württemberg
Rangsdorf;;15834;033708;Brandenburg
Ranies;;39221;039200;Sachsen-Anhalt
Ranis;;07389;03647;Thüringen
Rankendorf;;23936;038827;Mecklenburg-Vorpommern
Rankwitz;;17406;038372;Mecklenburg-Vorpommern
Rannstedt;;99518;036463;Thüringen
Rannungen;;97517;09738;Bayern
Ransbach-Baumbach;;56235;02623;Rheinland-Pfalz
Ranschbach;;76829;06345;Rheinland-Pfalz
Ranstadt;;63691;06041;Hessen
Ransweiler;;67808;06361;Rheinland-Pfalz
Rantrum;b Husum, Nordsee;25873;04848;Schlewig-Holstein
Rantrumdeich;;25840;;Schlewig-Holstein
Rantum;(Sylt);25980;04651;Schlewig-Holstein
Rantzau;;24329;04383;Schlewig-Holstein
Rantzauer Papiermühle;;24321;04383;Schlewig-Holstein
Ranzig;;15848;033675;Brandenburg
Ranzin;;17495;038355;Mecklenburg-Vorpommern
Ranzow;, NL;03229;035434;Brandenburg
Rappin;;18528;03838;Mecklenburg-Vorpommern
Rapshagen;;16928;033986;Brandenburg
Raschau;, Erzgeb;08352;03774;Sachsen
Rascheid;;54413;06586;Rheinland-Pfalz
Rasdorf;b Hünfeld;36169;06651;Hessen
Rassau;b Neuhaus, Elbe;19273;038845;Niedersachsen
Raßmannsdorf;;15848;033672;Brandenburg
Raßnitz;;06184;034605;Sachsen-Anhalt
Rastatt;;76437;07222;Baden-Württemberg
Rastdorf;, Hümmling;26901;05956;Niedersachsen
Rastede;;26180;04402;Niedersachsen
Rastenberg;;99636;036377;Thüringen
Rastorf;b Wismar, Meckl;23996;038424;Mecklenburg-Vorpommern
Rastorf;, Holst;24211;04384;Schlewig-Holstein
Rastow;;19077;03868;Mecklenburg-Vorpommern
Ratekau;;23626;04504;Schlewig-Holstein
Rathebur;;17398;039726;Mecklenburg-Vorpommern
Rathendorf;;04657;034346;Sachsen
Rathenow;;14712;03385;Brandenburg
Rathjensdorf;;24306;04522;Schlewig-Holstein
Rathmannsdorf;b Pirna;01814;035022;Sachsen
Rathmannsdorf;b Staßfurt;39439;039262;Sachsen-Anhalt
Rathmannsdorf, Gut;;24161;0431;Schlewig-Holstein
Rathmannsdorf, Hof;;24161;0431;Schlewig-Holstein
Rathmannsdorferschleuse;;24161;;Schlewig-Holstein
Rathskirchen;;67744;06364;Rheinland-Pfalz
Rathsmannsdorf;;94565;08546;Bayern
Rathstock;;15328;033601;Brandenburg
Rathsweiler;;66887;06387;Rheinland-Pfalz
Rathsweiler Mühle;;66887;06387;Rheinland-Pfalz
Ratingen;;40472;02102;Nordrhein-Westfalen
Ratingen;;40878;02102;Nordrhein-Westfalen
Ratingen;;40880;02102;Nordrhein-Westfalen
Ratingen;;40882;02102;Nordrhein-Westfalen
Ratingen;;40883;02102;Nordrhein-Westfalen
Ratingen;;40885;02102;Nordrhein-Westfalen
Ratshausen;;72365;07427;Baden-Württemberg
Rattelsdorf;b Stadtroda;07646;036426;Thüringen
Rattelsdorf;, Oberfr;96179;09547;Bayern
Rattenberg;, Niederbay;94371;09963;Bayern
Rattenkirchen;;84431;08082;Bayern
Rattiszell;;94372;09964;Bayern
Ratzdorf;;15898;033652;Brandenburg
Ratzeburg;;23909;04541;Schlewig-Holstein
Ratzensägmühle;;74579;07962;Baden-Württemberg
Ratzert;;57614;02684;Rheinland-Pfalz
Rätzlingen;, Kr Uelzen;29590;05804;Niedersachsen
Rätzlingen;b Haldensleben;39359;039057;Sachsen-Anhalt
Raubach;, Westerw;56316;02684;Rheinland-Pfalz
Raubling;;83064;08035;Bayern
Rauchenberg;b Großhelfendorf;85655;08095;Bayern
Rauda;;07613;036691;Thüringen
Rauen;b Fürstenwalde, Spree;15518;03361;Brandenburg
Rauenberg;, Kraichgau;69231;06222;Baden-Württemberg
Rauenstein;, Thür;96528;036766;Thüringen
Rauhenebrach;;96181;09554;Bayern
Raumbach;;55592;06753;Rheinland-Pfalz
Raunheim;;65479;06142;Hessen
Rauschenberg;, Hess;35282;06425;Hessen
Rauschengesees;;07356;036643;Thüringen
Rauschwitz;b Eisenberg, Thür;07616;036691;Thüringen
Rausdorf;b Stadtroda;07646;036428;Thüringen
Rausdorf;, Kr Stormarn;22929;04154;Schlewig-Holstein
Raußlitz;;01623;035246;Sachsen
Ravengiersburg;;55471;06761;Rheinland-Pfalz
Ravensberg;b Neubukow;18233;038297;Mecklenburg-Vorpommern
Ravensburg;, Württ;88212;0751;Baden-Württemberg
Ravensburg;, Württ;88213;0751;Baden-Württemberg
Ravensburg;, Württ;88214;0751;Baden-Württemberg
Ravensruh;;23992;038422;Mecklenburg-Vorpommern
Ravenstein;, Baden;74747;06297;Baden-Württemberg
Raversbeuren;;56850;06543;Rheinland-Pfalz
Rayerschied;;55469;06766;Rheinland-Pfalz
Realsmühle;;92342;;Bayern
Rebersreuth;;08626;037423;Sachsen
Rebesgrün;;08209;03744;Sachsen
Rech;, Ahr;53506;02643;Rheinland-Pfalz
Rechberghausen;;73098;07161;Baden-Württemberg
Rechenberg-Bienenmühle;;09623;037327;Sachsen
Rechlin;;17248;039823;Mecklenburg-Vorpommern
Rechtenbach;, Spessart;97848;09352;Bayern
Rechtenstein;;89611;07375;Baden-Württemberg
Rechtmehring;;83562;08076;Bayern
Rechtsupweg;;26529;04934;Niedersachsen
Reckahn;;14778;033835;Brandenburg
Recke;;49509;05453;Nordrhein-Westfalen
Reckendorf;, Oberfr;96182;09544;Bayern
Reckenroth;;56370;06120;Rheinland-Pfalz
Reckenthin;;16928;033982;Brandenburg
Reckenzin;;19357;038788;Brandenburg
Reckershausen;, Hunsrück;55481;06763;Rheinland-Pfalz
Recklinghausen;, Westf;45657;02361;Nordrhein-Westfalen
Recklinghausen;, Westf;45659;02361;Nordrhein-Westfalen
Recklinghausen;, Westf;45661;02361;Nordrhein-Westfalen
Recklinghausen;, Westf;45663;02361;Nordrhein-Westfalen
Recklinghausen;, Westf;45665;02361;Nordrhein-Westfalen
Recknitz;;18276;038455;Mecklenburg-Vorpommern
Reddeber;;38855;03943;Sachsen-Anhalt
Reddelich;;18209;038203;Mecklenburg-Vorpommern
Reddern;;03229;035434;Brandenburg
Redebas;;18314;038324;Mecklenburg-Vorpommern
Redefin;;19230;038854;Mecklenburg-Vorpommern
Redekin;;39319;039341;Sachsen-Anhalt
Rederank;;18239;038295;Mecklenburg-Vorpommern
Redewisch;b Lübz;19395;;Mecklenburg-Vorpommern
Redewisch;b Grevesmühlen;23946;038825;Mecklenburg-Vorpommern
Redewisch Fischerhaus;;23946;038825;Mecklenburg-Vorpommern
Rednitzhembach;;91126;09122;Bayern
Redwitz;a d Rodach;96257;09574;Bayern
Rees;;46459;02851;Nordrhein-Westfalen
Reesdorf;b Potsdam;14547;033204;Brandenburg
Reesdorf;b Kiel;24241;04322;Schlewig-Holstein
Reesdorf;b Burg b Magdeburg;39291;039225;Sachsen-Anhalt
Reesen;;39291;03921;Sachsen-Anhalt
Reesmoor;;24576;04192;Schlewig-Holstein
Reeßum;;27367;04264;Niedersachsen
Reetz;b Belzig;14827;033849;Brandenburg
Reetz;b Perleberg;19348;038782;Brandenburg
Reetzerhütten;;14827;033849;Brandenburg
Reffenthal;;67166;06232;Rheinland-Pfalz
Regen;;94209;09921;Bayern
Regensburg;;93047;0941;Bayern
Regensburg;;93049;0941;Bayern
Regensburg;;93051;0941;Bayern
Regensburg;;93053;0941;Bayern
Regensburg;;93055;0941;Bayern
Regensburg;;93057;0941;Bayern
Regensburg;;93059;0941;Bayern
Regenstauf;;93128;09402;Bayern
Regesbostel;;21649;0451;Niedersachsen
Regis-Breitingen;;04565;034343;Sachsen
Regnitzlosau;;95194;09294;Bayern
Rehagen;b Zossen b Berlin;15806;033703;Brandenburg
Rehau;, Oberfr;95111;09283;Bayern
Rehbach;b Sobernheim;55566;06756;Rheinland-Pfalz
Rehborn;;55592;06753;Rheinland-Pfalz
Rehbruck;;94481;08552;Bayern
Rehburg-Loccum;;31547;05766;Niedersachsen
Rehden;, Kr Diepholz;49453;05446;Niedersachsen
Rehe;;56479;02664;Rheinland-Pfalz
Reher;, Holst;25593;04876;Schlewig-Holstein
Rehfeld;b Falkenberg, Elster;04895;035365;Brandenburg
Rehfeld-Berlitt;;16866;033971;Brandenburg
Rehfelde;b Strausberg;15345;033435;Brandenburg
Rehhorst;, Holst;23619;04533;Schlewig-Holstein
Rehling;;86508;08237;Bayern
Rehlingen;, Kr Lüneburg;21385;04132;Niedersachsen
Rehlingen-Siersburg;;66780;06833;Saarland
Rehm-Flehde-Bargen;;25776;04882;Schlewig-Holstein
Rehmsdorf;;06729;03441;Sachsen-Anhalt
Rehna;;19217;038872;Mecklenburg-Vorpommern
Rehsen;;06786;034905;Sachsen-Anhalt
Rehungen;;99759;036338;Thüringen
Rehweiler;, Pfalz;66907;06383;Rheinland-Pfalz
Rehwinkel;;29303;05054;Niedersachsen
Reich;, Hunsrück;55471;06761;Rheinland-Pfalz
Reichardtswerben;;06667;03443;Sachsen-Anhalt
Reichartshausen;, Baden;74934;06262;Baden-Württemberg
Reichelsheim;(Wetterau);61203;0931;Hessen
Reichelsheim;(Odenwald);64385;06164;Hessen
Reichelshof;;97526;09721;Bayern
Reichen;, Bodensee;88079;07543;Baden-Württemberg
Reichenau;, Baden;78479;07534;Baden-Württemberg
Reichenbach;, OL;02894;035828;Sachsen
Reichenbach;b Saalfeld, Saale;07330;036731;Thüringen
Reichenbach;b Hermsdorf, Thür;07629;036601;Thüringen
Reichenbach;, Vogtl;08468;03765;Sachsen
Reichenbach;, Kr Birkenfeld, Nahe;55776;06783;Rheinland-Pfalz
Reichenbach;an der Fils;73262;07153;Baden-Württemberg
Reichenbach;am Heuberg;78564;07429;Baden-Württemberg
Reichenbach;, Oberpf;93189;09464;Bayern
Reichenbach;, Kr Kronach;96358;09268;Bayern
Reichenbacherhof;;67731;06301;Rheinland-Pfalz
Reichenbach-Reichenau;;01936;035795;Sachsen
Reichenbach-Steegen;;66879;06385;Rheinland-Pfalz
Reichenberg;b Dresden;01468;0351;Sachsen
Reichenberg;b Buckow, Märkische Schweiz;15377;033437;Brandenburg
Reichenberg;, Rhein-Lahn-Kr;56357;06771;Rheinland-Pfalz
Reichenberg;, Unterfr;97234;0931;Bayern
Reichenberg b Riedlhütte;;94566;08553;Bayern
Reichenhain;b Prösen;04932;035341;Brandenburg
Reichenow-Möglin;;15345;033436;Brandenburg
Reichenschwand;;91244;09151;Bayern
Reichenwalde;;15526;033631;Brandenburg
Reichersbeuern;;83677;08041;Bayern
Reichertshausen;, Ilm;85293;08441;Bayern
Reichertsheim;;84437;08073;Bayern
Reichertshofen;, Oberbay;85084;08453;Bayern
Reichling;, Oberbay;86934;08194;Bayern
Reichmannsdorf;b Neuhaus a Rennweg;98739;036701;Thüringen
Reicho;;06926;035389;Sachsen-Anhalt
Reichshof;;51580;02296;Nordrhein-Westfalen
Reichstädt;b Dippoldiswalde;01744;03504;Sachsen
Reichstädt;b Gera;07580;036602;Thüringen
Reichsthal;;67759;06363;Rheinland-Pfalz
Reichwalde;b Luckau, NL;15938;035474;Brandenburg
Reichweiler;;66871;06384;Rheinland-Pfalz
Reichwimm;;83379;08681;Bayern
Reidenbruch;;53547;02638;Rheinland-Pfalz
Reidenhausen;;56865;06545;Rheinland-Pfalz
Reifenberg;, Pfalz;66507;06375;Rheinland-Pfalz
Reifenhof;;73489;07967;Baden-Württemberg
Reifensägmühle;;73489;07967;Baden-Württemberg
Reiferscheid;b Flammersfeld;57632;02685;Rheinland-Pfalz
Reiff;;54619;06550;Rheinland-Pfalz
Reiffelbach;;67829;06753;Rheinland-Pfalz
Reifferscheid;b Adenau;53520;02691;Rheinland-Pfalz
Reigersberg;;94551;09904;Bayern
Reil;;56861;06542;Rheinland-Pfalz
Reilingen;;68799;06205;Baden-Württemberg
Reimerath;;53539;02692;Rheinland-Pfalz
Reimersgrün;;08491;036621;Sachsen
Reimershagen;;18276;038457;Mecklenburg-Vorpommern
Reimlingen;;86756;09081;Bayern
Reinbek;;21465;04104;Schlewig-Holstein
Reinberg;b Grimmen;18519;038328;Mecklenburg-Vorpommern
Reinfeld;(Holstein);23858;04533;Schlewig-Holstein
Reinhardshagen;;34359;05544;Hessen
Reinhardswinden;;97478;09528;Bayern
Reinhardtsdorf;b Bad Schandau;01814;035028;Sachsen
Reinhardtsgrimma;;01768;035053;Sachsen
Reinheim;, Odenw;64354;06162;Hessen
Reinholdshain;b Dippoldiswalde;01744;03504;Sachsen
Reinholterode;;37308;036085;Thüringen
Reinsberg;b Freiberg, Sachs;09629;037324;Sachsen
Reinsbüttel;;25764;04833;Schlewig-Holstein
Reinsdorf;b Artern;06556;03466;Thüringen
Reinsdorf;b Naumburg, Saale;06642;034461;Sachsen-Anhalt
Reinsdorf;b Lutherstadt Wittenberg;06896;03491;Sachsen-Anhalt
Reinsdorf;b Zwickau;08141;037603;Sachsen
Reinsdorf;b Jüterbog;14913;033746;Brandenburg
Reinsfeld;, Hunsrück;54421;06503;Rheinland-Pfalz
Reinshagen;b Bad Doberan;18239;038295;Mecklenburg-Vorpommern
Reinstädt;;07768;036422;Thüringen
Reinstedt;;06463;034741;Sachsen-Anhalt
Reinstorf;b Bützow;18246;038466;Mecklenburg-Vorpommern
Reinstorf;, Kr Lüneburg;21400;04137;Niedersachsen
Reinstorf;b Wismar, Meckl;23992;038422;Mecklenburg-Vorpommern
Reinthal;b Habach, Oberbay;82392;08847;Bayern
Reipeldingen;;54689;06550;Rheinland-Pfalz
Reipoltskirchen;;67753;06364;Rheinland-Pfalz
Reisbach;, Niederbay;94419;08734;Bayern
Reischach;, Kr Altötting;84571;08670;Bayern
Reisdorf;b Bad Sulza;99518;036463;Thüringen
Reiser;, Gem Gars a Inn;83559;08073;Bayern
Reiser;b Mühlhausen, Thür;99974;03601;Thüringen
Reisgang;b Pfaffenhofen a d Ilm;85276;08441;Bayern
Reiskirchen;, Wieseck;35447;06408;Hessen
Reisleite;;83546;08073;Bayern
Reismühle;b Landstuhl;66903;06384;Rheinland-Pfalz
Reit;, Chiemgau;83368;08621;Bayern
Reit im Winkl;;83242;08640;Bayern
Reit, Gem Kirchensur;;83552;08074;Bayern
Reitling;;38173;05305;Niedersachsen
Reitmehring;, Stadt Wasserburg a Inn;83512;08071;Bayern
Reitrain;;83700;08022;Bayern
Reitwein;;15328;033601;Brandenburg
Reitzengeschwenda;;07338;036737;Thüringen
Reitzenhain;, Erzgeb;09496;037364;Sachsen
Reitzenhain;, Taunus;56357;06771;Rheinland-Pfalz
Reken;;48734;02864;Nordrhein-Westfalen
Rellingen;;25462;04101;Schlewig-Holstein
Relsberg;;67753;06363;Rheinland-Pfalz
Remagen;;53424;02642;Rheinland-Pfalz
Remchingen;;75196;07232;Baden-Württemberg
Remda;;07407;036744;Thüringen
Remkersleben;;39164;039407;Sachsen-Anhalt
Remlin;;17168;039977;Mecklenburg-Vorpommern
Remlingen;, Kr Wolfenbüttel;38319;05337;Niedersachsen
Remlingen;, Unterfr;97280;09369;Bayern
Remmels;;24594;04871;Schlewig-Holstein
Remplin;;17139;03996;Mecklenburg-Vorpommern
Remptendorf;;07368;036640;Thüringen
Remscheid;;42853;02191;Nordrhein-Westfalen
Remscheid;;42855;02191;Nordrhein-Westfalen
Remscheid;;42857;02191;Nordrhein-Westfalen
Remscheid;;42859;02191;Nordrhein-Westfalen
Remscheid;;42897;02191;Nordrhein-Westfalen
Remscheid;;42899;02191;Nordrhein-Westfalen
Remse;b Glauchau;08373;03763;Sachsen
Remseck;am Neckar;71686;07146;Baden-Württemberg
Remshalden;;73630;07151;Baden-Württemberg
Remstädt;;99869;03621;Thüringen
Renchen;;77871;07843;Baden-Württemberg
Rendsburg;;24768;04331;Schlewig-Holstein
Rendswühren;;24619;04394;Schlewig-Holstein
Rengsdorf;, Kr Neuwied;56579;02634;Rheinland-Pfalz
Renkenberge;;49762;05933;Niedersachsen
Rennau;;38368;05365;Niedersachsen
Renneritz;;06794;034954;Sachsen-Anhalt
Rennerod;, Westerw;56477;02664;Rheinland-Pfalz
Rennersdorf;, OL;02747;035873;Sachsen
Rennertshofen;, Oberbay;86643;08434;Bayern
Renningen;;71272;07159;Baden-Württemberg
Renquishausen;;78603;07429;Baden-Württemberg
Rensdorf;;19258;038847;Mecklenburg-Vorpommern
Renthendorf;;07646;036426;Thüringen
Rentweinsdorf;;96184;09531;Bayern
Rentwertshausen;;98631;036944;Thüringen
Rentzschmühle;;07985;037439;Sachsen
Renzow;;19209;038874;Mecklenburg-Vorpommern
Reppel;;24861;04885;Schlewig-Holstein
Reppelin;;18190;038209;Mecklenburg-Vorpommern
Reppenstedt;;21391;04131;Niedersachsen
Reppenstedt;;21391;04131;Niedersachsen
Reppichau;;06386;034909;Sachsen-Anhalt
Reppinichen;;14828;033847;Brandenburg
Repten;;03205;035433;Brandenburg
Reschwitz;;07318;036736;Thüringen
Ressen;, NL;03103;035751;Brandenburg
Ressen;a Schwielochsee;15913;035751;Brandenburg
Resthausen;, Gem Molbergen;49696;04474;Niedersachsen
Retelsdorf;;23923;038828;Mecklenburg-Vorpommern
Retgendorf;;19067;0385;Mecklenburg-Vorpommern
Rethem;(Aller);27336;05165;Niedersachsen
Rethemer Fähre;;27336;;Niedersachsen
Rethwisch;b Bad Doberan;18211;038203;Mecklenburg-Vorpommern
Rethwisch;, Kr Stormarn;23847;04539;Schlewig-Holstein
Rethwisch;b Lägerdorf;25566;04828;Schlewig-Holstein
Retschow;;18211;038203;Mecklenburg-Vorpommern
Retschow Waldsiedlung;;18211;038203;Mecklenburg-Vorpommern
Rettenbach;a.Auerberg;87675;08861;Bayern
Rettenbach;, Kr Günzburg;89364;08224;Bayern
Rettenbach;, Oberpf;93191;09462;Bayern
Rettenberg;, Oberallgäu;87549;08327;Bayern
Retterath;;56769;02657;Rheinland-Pfalz
Rettersen;;57635;02686;Rheinland-Pfalz
Rettershain;;56357;06775;Rheinland-Pfalz
Rettert;;56370;0641;Rheinland-Pfalz
Retzau;;06779;034906;Sachsen-Anhalt
Retzin;b Perleberg;19348;038789;Brandenburg
Retzow;b Nauen;14641;033237;Brandenburg
Retzow;b Templin;17279;039888;Brandenburg
Retzow;b Lübz;19395;038733;Mecklenburg-Vorpommern
Retzstadt;;97282;09364;Bayern
Reudelsterz;;56727;02651;Rheinland-Pfalz
Reuden;b Zeitz, Elster;06725;034424;Sachsen-Anhalt
Reuden;b Zerbst;39264;039243;Sachsen-Anhalt
Reuden a d Fuhne;;06766;03494;Sachsen-Anhalt
Reudnitz;b Beeskow;15848;033676;Brandenburg
Reupzig;;06369;034977;Sachsen-Anhalt
Reurieth;;98646;03685;Thüringen
Reußen;b Halle, Saale;06188;034602;Sachsen-Anhalt
Reußenköge;;25821;04671;Schlewig-Holstein
Reut;a Teisenberg;83362;;Bayern
Reut;, Niederbay;84367;08572;Bayern
Reute;b Schwäbisch Gmünd;73569;;Baden-Württemberg
Reute;, Breisgau;79276;07641;Baden-Württemberg
Reuth;b Plauen;08538;037435;Sachsen
Reuth;, Eifel;54597;06552;Rheinland-Pfalz
Reuth;b. Erbendorf;92717;09682;Bayern
Reuthen;, NL;03130;03563;Brandenburg
Reutlingen;;72760;07121;Baden-Württemberg
Reutlingen;;72762;07121;Baden-Württemberg
Reutlingen;;72764;07121;Baden-Württemberg
Reutlingen;;72766;07121;Baden-Württemberg
Reutlingen;;72768;07121;Baden-Württemberg
Reutlingen;;72770;07121;Baden-Württemberg
Rey;;17168;039973;Mecklenburg-Vorpommern
Rhade;b Zeven;27404;04285;Niedersachsen
Rhäsa;;01683;035242;Sachsen
Rhauderfehn;;26817;04952;Niedersachsen
Rhaunen;, Hunsrück;55624;06544;Rheinland-Pfalz
Rheda-Wiedenbrück;;33378;05242;Nordrhein-Westfalen
Rhede;(Ems);26899;04964;Niedersachsen
Rhede;, Westf;46414;02872;Nordrhein-Westfalen
Rheden;;31039;05182;Niedersachsen
Rheinau;, Baden;77866;07844;Baden-Württemberg
Rheinbach;;53359;02226;Nordrhein-Westfalen
Rheinberg;;47495;02843;Nordrhein-Westfalen
Rheinböllen;;55494;06764;Rheinland-Pfalz
Rheinbreitbach;;53619;02224;Rheinland-Pfalz
Rheinbrohl;;56598;02635;Rheinland-Pfalz
Rheine;;48429;05971;Nordrhein-Westfalen
Rheine;;48431;05971;Nordrhein-Westfalen
Rheine;;48432;05971;Nordrhein-Westfalen
Rheinfelden;(Baden);79618;07623;Baden-Württemberg
Rheinhausen;, Breisgau;79365;07643;Baden-Württemberg
Rheinmünster;;77836;07227;Baden-Württemberg
Rheinsberg;;16831;033931;Brandenburg
Rheinsberg Glienicke;;16818;033929;Brandenburg
Rheinstetten;, Baden;76287;07242;Baden-Württemberg
Rheinzabern;;76764;07272;Rheinland-Pfalz
Rhens;;56321;02628;Rheinland-Pfalz
Rheurdt;;47509;02845;Nordrhein-Westfalen
Rhinow;;14728;033875;Brandenburg
Rhoden;b Osterwieck;38835;039421;Sachsen-Anhalt
Rhodt;unter Rietburg;76835;06323;Rheinland-Pfalz
Rhumspringe;;37434;05529;Niedersachsen
Ribbeck;b Nauen;14641;033237;Brandenburg
Ribbeck;b Gransee;16775;033080;Brandenburg
Ribbeckshorst;;16818;033922;Brandenburg
Ribbesbüttel;;38551;05374;Niedersachsen
Ribnitz-Damgarten;;18311;03821;Mecklenburg-Vorpommern
Richtenberg;;18461;038322;Mecklenburg-Vorpommern
Rickenbach;, Hotzenw;79736;07765;Baden-Württemberg
Rickert;;24782;04331;Schlewig-Holstein
Rickling;;24635;04328;Schlewig-Holstein
Riebau;;29416;039037;Sachsen-Anhalt
Rieben;;14547;033204;Brandenburg
Ried;, Gem Amerang;83552;;Bayern
Ried;, Gem Frauenneuharting;83565;08092;Bayern
Ried;b Mering, Schwab;86510;08233;Bayern
Riedbach;, Unterfr;97519;09526;Bayern
Riede;, Kr Verden, Aller;27339;04294;Niedersachsen
Riedelberg;;66484;06339;Rheinland-Pfalz
Riedelberg-Tal;;66482;06339;Rheinland-Pfalz
Rieden;, Eifel;56745;02655;Rheinland-Pfalz
Rieden;b Kaufbeuren;87668;08346;Bayern
Rieden;am Forggensee;87669;08362;Bayern
Rieden;, Oberpf;92286;09624;Bayern
Riedenberg;, Rhön;97792;09749;Bayern
Riedenburg;, Altmühltal;93339;09442;Bayern
Riedenheim;;97283;09338;Bayern
Rieder;b Quedlinburg;06507;039485;Sachsen-Anhalt
Riederich;;72585;07123;Baden-Württemberg
Riedering;b Rosenheim, Oberbay;83083;08036;Bayern
Riedhausen;, Württ;88377;07587;Baden-Württemberg
Riedhof;b Weißenhorn;89264;;Bayern
Riedl;b Wörnsmühl;83739;08020;Bayern
Riedlhütte;;94566;08558;Bayern
Riedlingen;, Württ;88499;07371;Baden-Württemberg
Riedstadt;;64560;06158;Hessen
Riegel;, Kaiserstuhl;79359;07642;Baden-Württemberg
Riegelsberg;, Saar;66292;06806;Saarland
Riegenroth;;55469;06766;Rheinland-Pfalz
Riegersheim;;74597;07967;Baden-Württemberg
Riegsee;;82418;08847;Bayern
Riekofen;;93104;09480;Bayern
Rielasingen-Worblingen;;78239;07731;Baden-Württemberg
Riemerling;;85521;089;Bayern
Riemserort;;17498;038351;Mecklenburg-Vorpommern
Riemserort;;17498;038351;Mecklenburg-Vorpommern
Riendlhäusl;;83329;08681;Bayern
Rieneck;;97794;09354;Bayern
Rieplos;;15859;033678;Brandenburg
Rieps;;19217;038873;Mecklenburg-Vorpommern
Riepsdorf;;23738;04363;Schlewig-Holstein
Riepshof;;21255;04182;Niedersachsen
Riesa;;01587;03525;Sachsen
Riesa;;01589;03525;Sachsen
Riesa;;01591;03525;Sachsen
Riesbürg;;73469;07362;Baden-Württemberg
Rieschweiler-Mühlbach;;66509;06336;Rheinland-Pfalz
Riesdorf;b Köthen, Anh;06369;034978;Sachsen-Anhalt
Riesdorf;b Jüterbog;14913;033746;Brandenburg
Rieseby;;24354;04355;Schlewig-Holstein
Riesewohld;, Gem Odderade;25767;;Schlewig-Holstein
Riesigk;;06786;034905;Sachsen-Anhalt
Rießen;b Eisenhüttenstadt;15890;033654;Brandenburg
Rieste;, Hase;49597;05464;Niedersachsen
Riestedt;b Sangerhausen;06528;03464;Sachsen-Anhalt
Riesweiler;, Hunsrück;55499;06761;Rheinland-Pfalz
Rietberg;;33397;05244;Nordrhein-Westfalen
Rietdorf;;15936;035451;Brandenburg
Rieth;b Torgelow, Vorpommern;17375;039775;Mecklenburg-Vorpommern
Rieth;b Hildburghausen;98663;036871;Thüringen
Rietheim-Weilheim;;78604;07424;Baden-Württemberg
Riethgen;;99638;036375;Thüringen
Riethnordhausen;b Sangerhausen;06528;03464;Sachsen-Anhalt
Riethnordhausen;, Thür;99195;03634;Thüringen
Rietschen;;02956;035772;Sachsen
Rietz;b Brandenburg an der Havel;14797;033835;Brandenburg
Rietz;b Jüterbog;14929;033748;Brandenburg
Rietzel;;39291;039223;Sachsen-Anhalt
Rietz-Neuendorf;;15848;033672;Brandenburg
Rietzneuendorf-Friedrichshof;;15910;035477;Brandenburg
Riewend;;14778;033838;Brandenburg
Rimbach;, Odenw;64668;06253;Hessen
Rimbach;b Eggenfelden;84326;08727;Bayern
Rimbach;, Oberpf;93485;09941;Bayern
Rimpar;;97222;09365;Bayern
Rimsberg;;55765;06782;Rheinland-Pfalz
Rimsting;;83253;08051;Bayern
Rinchnach;;94269;09921;Bayern
Ringe;b Emlichheim;49824;05944;Niedersachsen
Ringelah;;38559;05376;Niedersachsen
Ringelai;;94160;08555;Bayern
Ringelsdorf;, Jerichower Land;39291;039346;Sachsen-Anhalt
Ringenhain;;01904;035951;Sachsen
Ringenwalde;b Buckow, Märkische Schweiz;15377;033437;Brandenburg
Ringenwalde;b Templin;17268;039881;Brandenburg
Ringfurth;;39517;039366;Sachsen-Anhalt
Ringgau;;37296;05659;Hessen
Ringleben;b Artern;06556;03466;Thüringen
Ringleben;b Gebesee;99189;036201;Thüringen
Ringsberg;;24977;04636;Schlewig-Holstein
Ringsee;b Bad Wiessee;83707;08022;Bayern
Ringsheim;;77975;07822;Baden-Württemberg
Ringstedt;;27624;04708;Niedersachsen
Rinnthal;;76857;06346;Rheinland-Pfalz
Rinow;;14913;033744;Brandenburg
Rinteln;;31737;05751;Niedersachsen
Rinzenberg;;55767;06782;Rheinland-Pfalz
Riol;;54340;06502;Rheinland-Pfalz
Rippach;;06686;03461;Sachsen-Anhalt
Rippershausen;;98639;03693;Thüringen
Rippien;;01728;0351;Sachsen
Ristedt;, Altmark;38486;03909;Sachsen-Anhalt
Risum-Lindholm;;25920;04661;Schlewig-Holstein
Ritschenhausen;;98617;036949;Thüringen
Rittbruch;;23746;;Schlewig-Holstein
Ritteburg;;06556;03466;Thüringen
Ritterhude;;27721;04292;Niedersachsen
Rittermühle;;54636;06561;Rheinland-Pfalz
Ritterode;;06333;034781;Sachsen-Anhalt
Rittersdorf;, Eifel;54636;06561;Rheinland-Pfalz
Rittersdorf;b Weimar, Thür;99448;036450;Thüringen
Rittersgrün;;08355;037757;Sachsen
Rittersheim;;67294;06352;Rheinland-Pfalz
Rittersmühle;b Pressig;96332;;Bayern
Ritze;;29410;03901;Sachsen-Anhalt
Ritzerau;;23896;04543;Schlewig-Holstein
Ritzerow;;17153;039954;Mecklenburg-Vorpommern
Ritzgerode;;06543;034775;Sachsen-Anhalt
Rivenich;;54518;06508;Rheinland-Pfalz
Riveris;;54317;06500;Rheinland-Pfalz
Röbel;/Müritz;17207;039931;Mecklenburg-Vorpommern
Röblingen am See;;06317;034774;Sachsen-Anhalt
Rochau;;39579;039328;Sachsen-Anhalt
Rochlitz;;09306;03737;Sachsen
Röcken;;06686;03461;Sachsen-Anhalt
Rockenberg;;35519;06181;Hessen
Rockendorf;b Pößneck;07387;03647;Thüringen
Rockenhausen;;67806;06361;Rheinland-Pfalz
Rockensußra;;99713;036020;Thüringen
Rockeskyll;;54570;06591;Rheinland-Pfalz
Rockhausen;;99102;0361;Thüringen
Röckingen;;91740;09832;Bayern
Rockstedt;b Ebeleben;99713;036020;Thüringen
Röckwitz;;17091;039600;Mecklenburg-Vorpommern
Roda;b Ilmenau;98693;03677;Thüringen
Rodalben;;66976;06331;Rheinland-Pfalz
Rodau;b Mehltheuer, Vogtl;08539;037435;Sachsen
Roddahn;;16845;033973;Brandenburg
Roddan;;19336;038791;Brandenburg
Röddelin;;17268;03987;Brandenburg
Rodden;;06231;034638;Sachsen-Anhalt
Rodder;b Adenau;53520;02693;Rheinland-Pfalz
Rodeberg;;99976;036026;Thüringen
Rödelbach;;56346;;Rheinland-Pfalz
Rödelhausen;;56858;06543;Rheinland-Pfalz
Rödelmaier;;97618;09771;Bayern
Rödelsee;;97348;09323;Bayern
Rödelwitz;;07407;036742;Thüringen
Roden;, Unterfr;97849;09396;Bayern
Rodenäs;;25924;04664;Schlewig-Holstein
Rodenbach;b Altenkirchen, Westerwald;57639;02684;Rheinland-Pfalz
Rodenbach;b Hanau;63517;06184;Hessen
Rodenbach;, Kr Kaiserslautern;67688;06374;Rheinland-Pfalz
Rodenbek;b Kiel;24247;04347;Schlewig-Holstein
Rodenberg;b Grevesmühlen;23936;038824;Mecklenburg-Vorpommern
Rodenberg;, Deister;31552;05723;Niedersachsen
Rödental;;96472;09563;Bayern
Rodenwalde;;19260;038848;Mecklenburg-Vorpommern
Röderau-Bobersen;;01619;03525;Sachsen
Röderaue;;01609;035263;Sachsen
Röderhäuser;;01896;;Sachsen
Röderhof;b Kaiserslautern;67725;06357;Rheinland-Pfalz
Rödermark;;63322;06074;Hessen
Rödern;, Hunsrück;55481;06763;Rheinland-Pfalz
Rodersdorf;b Halberstadt;38828;039423;Sachsen-Anhalt
Rodershausen;;54673;06524;Rheinland-Pfalz
Rödersheim-Gronau;;67127;06231;Rheinland-Pfalz
Rodewald;;31637;05074;Niedersachsen
Rodewisch;;08228;03744;Sachsen
Rodgau;;63110;06106;Hessen
Rödgen;b Mansfeld, Südharz;06343;034782;Sachsen-Anhalt
Rödgen;b Wolfen;06766;034956;Sachsen-Anhalt
Rodigast-Lucka;;07616;036692;Thüringen
Roding;, Regen;93426;09461;Bayern
Rödinghausen;, Westf;32289;05746;Nordrhein-Westfalen
Rödinghausen;, Westf;32289;05746;Nordrhein-Westfalen
Rodishain;;99762;0345;Thüringen
Rodleben;;06862;034901;Sachsen-Anhalt
Rödlin-Thurow;;17237;039826;Mecklenburg-Vorpommern
Roduchelstorf;;23923;038828;Mecklenburg-Vorpommern
Roes;;56754;0981;Rheinland-Pfalz
Roetgen;, Eifel;52159;02471;Nordrhein-Westfalen
Röfingen;;89365;09964;Bayern
Rogätz;;39326;039208;Sachsen-Anhalt
Rogeez;;17209;039924;Mecklenburg-Vorpommern
Rögen;, Holst;24217;;Schlewig-Holstein
Rögen, Gut;;24360;;Schlewig-Holstein
Roggenburg;, Schwab;89297;07300;Bayern
Roggendorf;b Gadebusch;19205;038876;Mecklenburg-Vorpommern
Roggenstein;b München;82223;08142;Bayern
Roggenstorf;;23936;038824;Mecklenburg-Vorpommern
Roggentin;b Neustrelitz;17252;039829;Mecklenburg-Vorpommern
Roggentin;b Rostock;18184;038204;Mecklenburg-Vorpommern
Roggosen;;03058;035605;Brandenburg
Roggow;b Bad Doberan;18230;038294;Mecklenburg-Vorpommern
Rögling;, Schwab;86703;09094;Bayern
Röglitz;;06184;034605;Sachsen-Anhalt
Rögnitz;;19205;038853;Mecklenburg-Vorpommern
Rohda a Haarberg;;99102;0361;Thüringen
Rohda a Haarberg;;99102;0361;Thüringen
Röhl;;54636;06562;Rheinland-Pfalz
Rohlsdorf;a d Dömitz;16928;033989;Brandenburg
Rohlsdorf;b Perleberg;19348;03876;Brandenburg
Rohlsdorf;, Holst;23689;04504;Schlewig-Holstein
Rohlsdorferbeek;;23689;;Schlewig-Holstein
Rohlstorf;, Holst;23821;04559;Schlewig-Holstein
Rohlstorf;b Wismar, Meckl;23974;03841;Mecklenburg-Vorpommern
Rohna;;07570;036607;Thüringen
Rohnstedt;;99718;036370;Thüringen
Rohr;, Mittelfr;91189;09876;Bayern
Rohr;i. NB;93352;08783;Bayern
Rohr;b Suhl;98530;036844;Thüringen
Rohrbach;b Rudolstadt;07429;036730;Thüringen
Rohrbach;, Hunsrück;55490;06765;Rheinland-Pfalz
Rohrbach;, Nahe;55776;06789;Rheinland-Pfalz
Rohrbach;, Pfalz;76865;06349;Rheinland-Pfalz
Rohrbach;, Ilm;85296;08442;Bayern
Rohrbach;b Weimar, Thür;99439;036451;Thüringen
Rohrbeck;b Falkensee;14624;03322;Brandenburg
Rohrbeck;b Jüterbog;14913;03372;Brandenburg
Rohrberg;b Heilbad Heiligenstadt;37318;036083;Thüringen
Rohrberg;, Altmark;38489;039000;Sachsen-Anhalt
Rohrborn;b Sömmerda;99610;03634;Thüringen
Rohrdorf;b Nagold;72229;07452;Baden-Württemberg
Rohrdorf;, Kr Rosenheim, Oberbay;83101;08032;Bayern
Rohrenfels;;86701;08431;Bayern
Röhrig;;37318;036087;Thüringen
Rohrkrug;;17335;039772;Mecklenburg-Vorpommern
Rohrlack;;16845;033928;Brandenburg
Röhrmoos;, Gem Esgrus;24395;;Schlewig-Holstein
Röhrmoos;, Kr Dachau;85244;08139;Bayern
Röhrnbach;;94133;08582;Bayern
Röhrsdorf;b Pirna;01809;0351;Sachsen
Röhrsdorf;b Chemnitz, Sachs;09247;0371;Sachsen
Röhrsdorf;b Chemnitz, Sachs;09247;0371;Sachsen
Rohrsen;, Kr Nienburg, Weser;31627;05024;Niedersachsen
Rohrsheim;;38836;039426;Sachsen-Anhalt
Roigheim;;74255;06298;Baden-Württemberg
Roitham;b Stein a d Traun;83371;08621;Bayern
Roitzsch;b Bitterfeld;06809;034954;Sachsen-Anhalt
Roklum;;38325;05336;Niedersachsen
Roll;b Neukirchen a Teisenberg;83364;08666;Bayern
Röllbach;;63934;09372;Bayern
Rollhäusl;;94107;;Bayern
Rollshausen;, Eichsfeld;37434;05528;Niedersachsen
Rollwitz;;17309;039740;Mecklenburg-Vorpommern
Rolofshagen;b Grevesmühlen;23948;03881;Mecklenburg-Vorpommern
Rom;b Parchim;19372;038731;Mecklenburg-Vorpommern
Rom;, Eifel;54574;06599;Rheinland-Pfalz
Römerberg;, Pfalz;67354;06232;Rheinland-Pfalz
Römerstein;;72587;07382;Baden-Württemberg
Römhild;;98631;036948;Thüringen
Rommersheim;b Prüm;54597;06551;Rheinland-Pfalz
Rommerskirchen;;41569;02183;Nordrhein-Westfalen
Römnitz;;23909;04541;Schlewig-Holstein
Romrod;;36329;06636;Hessen
Römstedt;;29591;05821;Niedersachsen
Rondeshagen;;23919;04544;Schlewig-Holstein
Rönnebeck;b Gransee;16775;033083;Brandenburg
Ronneburg;, Thür;07580;036602;Thüringen
Ronneburg;, Hess;63549;06184;Hessen
Ronnenberg;;30952;05109;Niedersachsen
Ronsberg;;87671;08306;Bayern
Ronshausen;;36217;06622;Hessen
Ropahl;;24363;04357;Schlewig-Holstein
Röpersdorf;;17291;03984;Brandenburg
Rorodt;;54411;06504;Rheinland-Pfalz
Rosa;;98590;036968;Thüringen
Rösa;;06774;034208;Sachsen-Anhalt
Rosbach;v d Höhe;61191;06003;Hessen
Roschbach;;76835;06323;Rheinland-Pfalz
Rosche;;29571;05803;Niedersachsen
Roscheid;, Eifel;54619;06559;Rheinland-Pfalz
Rosdorf;, Holstein;25548;04822;Schlewig-Holstein
Rosdorf;, Kr Göttingen;37124;05509;Niedersachsen
Roseburg;;21514;04158;Schlewig-Holstein
Rosenau;b Wusterwitz;14789;;Brandenburg
Rosenbach;b Löbau;02708;03585;Sachsen
Rosenberg;, Württ;73494;07967;Baden-Württemberg
Rosenberg;, Baden;74749;06295;Baden-Württemberg
Rosendahl;, Westf;48720;02547;Nordrhein-Westfalen
Rosendorf;;07819;036481;Thüringen
Rosenfeld;, Württ;72348;07428;Baden-Württemberg
Rosengarten;, Kr Harburg;21224;04108;Niedersachsen
Rosengarten;, Württ;74538;0791;Baden-Württemberg
Rosenhagen;b Satow b Bad Doberan;18239;038295;Mecklenburg-Vorpommern
Rosenhagen;b Gadebusch;19209;038874;Mecklenburg-Vorpommern
Rosenhagen;b Perleberg;19348;03876;Brandenburg
Rosenhagen;b Grevesmühlen;23942;038826;Mecklenburg-Vorpommern
Rosenheim;(Landkreis Altenkirchen);57520;02747;Rheinland-Pfalz
Rosenheim;, Oberbay;83022;08031;Bayern
Rosenheim;, Oberbay;83024;08031;Bayern
Rosenheim;, Oberbay;83026;08031;Bayern
Rosenheimerlay;;57520;02747;Rheinland-Pfalz
Rosenhof;b Bad Kreuznach;55543;0671;Rheinland-Pfalz
Rosenhof;b Bad Kreuznach;55543;0671;Rheinland-Pfalz
Rosenhof;b Dieblich;56332;;Rheinland-Pfalz
Rosenkopf;;66894;06372;Rheinland-Pfalz
Rosenow;b Altentreptow;17091;039602;Mecklenburg-Vorpommern
Rosenow;b Gadebusch;19205;038874;Mecklenburg-Vorpommern
Rosenthal;b Leutenberg;07338;036734;Thüringen
Rosenthal;, Mark;15936;035451;Brandenburg
Rosenthal;b Wismar, Meckl;23966;03841;Mecklenburg-Vorpommern
Rosenthal;, Hess;35119;06458;Hessen
Rosenthal-Bielatal;;01824;035033;Sachsen
Rosenwinkel;;16928;033984;Brandenburg
Rosian;;39279;039245;Sachsen-Anhalt
Rosien;;19273;038841;Niedersachsen
Rositz;;04617;034498;Thüringen
Roskow;;14778;033831;Brandenburg
Röslau;;95195;09238;Bayern
Rosow;;16307;033333;Brandenburg
Rösrath;;51503;02205;Nordrhein-Westfalen
Rossau;b Mittweida;09661;03727;Sachsen
Rossau;, Altmark;39606;03937;Sachsen-Anhalt
Roßbach;b Merseburg;06242;03461;Sachsen-Anhalt
Roßbach;, Wied;53547;02638;Rheinland-Pfalz
Roßbach;, Westerw;56271;02680;Rheinland-Pfalz
Roßbach;, Niederbay;94439;08547;Bayern
Roßdorf;b Genthin;39307;03933;Sachsen-Anhalt
Roßdorf;b Darmstadt;64380;06154;Hessen
Roßdorf;b Darmstadt;64380;06154;Hessen
Roßdorf;b Schmalkalden;98590;036968;Thüringen
Rossewitz;;18276;038455;Mecklenburg-Vorpommern
Roßhaupten;, Forggensee;87672;08367;Bayern
Rossin;;17398;039726;Mecklenburg-Vorpommern
Roßla;;06536;034651;Sachsen-Anhalt
Roßlaich;;82380;08803;Bayern
Roßlau;(Elbe);06862;034901;Sachsen-Anhalt
Roßleben;;06571;034672;Thüringen
Rößnitz;;08527;037431;Sachsen
Rossow;b Wittstock, Dosse;16909;033964;Brandenburg
Rossow;b Pasewalk;17322;039743;Mecklenburg-Vorpommern
Roßtal;;90574;09127;Bayern
Roßwein;;04741;034322;Sachsen
Rostock;;18055;0381;Mecklenburg-Vorpommern
Rostock;;18057;0381;Mecklenburg-Vorpommern
Rostock;;18059;0381;Mecklenburg-Vorpommern
Rostock;;18069;0381;Mecklenburg-Vorpommern
Rostock;;18106;0381;Mecklenburg-Vorpommern
Rostock;;18107;0381;Mecklenburg-Vorpommern
Rostock;;18109;0381;Mecklenburg-Vorpommern
Rostock;;18119;0381;Mecklenburg-Vorpommern
Rostock;;18146;0381;Mecklenburg-Vorpommern
Rostock;;18147;0381;Mecklenburg-Vorpommern
Rostock;;18182;0381;Mecklenburg-Vorpommern
Rot;an der Rot;88430;08395;Baden-Württemberg
Rot am See;;74585;07958;Baden-Württemberg
Rötenbach;b Abtsgmünd;73453;07975;Baden-Württemberg
Rotenberg, Jagdhaus;b Gieboldehausen;37434;;Niedersachsen
Rotenburg;(Wümme);27356;04261;Niedersachsen
Rotenburg;a. d. Fulda;36199;06623;Hessen
Rotenhain;;56459;02661;Rheinland-Pfalz
Rötgesbüttel;;38531;05304;Niedersachsen
Roth;bei Prüm;54597;06552;Rheinland-Pfalz
Roth;an der Our;54675;06566;Rheinland-Pfalz
Roth;b Stromberg, Hunsrück;55442;06724;Rheinland-Pfalz
Roth;, Rhein-Hunsrück-Kr;56288;06772;Rheinland-Pfalz
Roth;, Rhein-Lahn-Kr;56368;06772;Rheinland-Pfalz
Roth;b Hamm, Sieg;57539;02742;Rheinland-Pfalz
Roth;, Mittelfr;91154;09171;Bayern
Rotha;;06528;034658;Sachsen-Anhalt
Rötha;;04571;034206;Sachsen
Rothebach;;14806;;Brandenburg
Rothekreuz;, Gem Leubsdorf;53562;02644;Rheinland-Pfalz
Rothemühl;;17379;039772;Mecklenburg-Vorpommern
Rothen;b Sternberg, Meckl;19406;038485;Mecklenburg-Vorpommern
Rothenbach;, Westerw;56459;02666;Rheinland-Pfalz
Röthenbach;b Dippoldiswalde;01744;035058;Sachsen
Röthenbach;(Allgäu);88167;08384;Bayern
Röthenbach;a d Pegnitz;90552;0911;Bayern
Rothenberg;, Odenw;64757;06275;Hessen
Rothenbergerhof;, Hunsrück;56288;06762;Rheinland-Pfalz
Rothenbuch;;63860;06094;Bayern
Rothenburg;/O.L.;02929;035891;Sachsen
Rothenburg;b Könnern;06420;034691;Sachsen-Anhalt
Rothenburg;ob der Tauber;91541;09861;Bayern
Rothenfels;, Unterfr;97851;09393;Bayern
Rothenklempenow;;17321;039744;Mecklenburg-Vorpommern
Rothenschirmbach;;06295;034776;Sachsen-Anhalt
Rothenstein;b Jena;07751;036424;Thüringen
Rotherhof;, Gem Wierschem;56754;;Rheinland-Pfalz
Rothersdorf;;04828;034383;Sachsen
Rothesütte;;99768;036331;Thüringen
Röthlein;;97520;09723;Bayern
Rothschönberg;;01683;035245;Sachsen
Rothselberg;;67753;06304;Rheinland-Pfalz
Rothstein;;04924;035341;Brandenburg
Rotscherlinde;;14776;033835;Brandenburg
Rötsweiler-Nockenthal;;55767;06787;Rheinland-Pfalz
Rott;, Westerw;57632;02685;Rheinland-Pfalz
Rott;a. Inn;83543;08039;Bayern
Rott;, Lech;86935;08869;Bayern
Rotta;;06773;034921;Sachsen-Anhalt
Rottach-Egern;;83700;08022;Bayern
Rottelsdorf;b Lutherstadt Eisleben;06295;034773;Sachsen-Anhalt
Rottenacker;;89616;07393;Baden-Württemberg
Rottenbach;, Thür;07422;036739;Thüringen
Röttenbach;b Schwabach;91187;09172;Bayern
Röttenbach;b Forchheim, Oberfr;91341;09195;Bayern
Rottenbuch;, Oberbay;82401;08867;Bayern
Rottenburg;am Neckar;72108;07472;Baden-Württemberg
Rottenburg;a d Laaber;84056;08781;Bayern
Rottendorf;, Unterfr;97228;09302;Bayern
Rottenfuß;;82282;08134;Bayern
Rotterode;;98587;036847;Thüringen
Rottewitz;;01665;03521;Sachsen
Rotthalmünster;;94094;08533;Bayern
Röttingen;, Unterfr;97285;09338;Bayern
Rottleben;;06567;034671;Thüringen
Rottleberode;;06548;034653;Sachsen-Anhalt
Rottmersleben;;39343;039206;Sachsen-Anhalt
Rottstock;;14793;033847;Brandenburg
Rottweil;;78628;0741;Baden-Württemberg
Rotwandhaus;;83727;;Bayern
Rötz;, Oberpf;92444;09976;Bayern
Rövershagen;;18182;038202;Mecklenburg-Vorpommern
Röwitz;;38486;039008;Sachsen-Anhalt
Roxförde;;39638;039056;Sachsen-Anhalt
Roxheim;, Kr Bad Kreuznach;55595;0671;Rheinland-Pfalz
Roxin;;23936;038824;Mecklenburg-Vorpommern
Rübeland;b Blankenburg, Harz;38889;039454;Sachsen-Anhalt
Rübenau;;09526;037366;Sachsen
Rubenow;b Wolgast;17509;038354;Mecklenburg-Vorpommern
Rüber;;56295;02654;Rheinland-Pfalz
Rubkow;;17390;039724;Mecklenburg-Vorpommern
Rubow;;19067;03866;Mecklenburg-Vorpommern
Ruchow;;19406;038481;Mecklenburg-Vorpommern
Rückeroth;;56244;02626;Rheinland-Pfalz
Rückersdorf;b Doberlug-Kirchhain;03238;035325;Brandenburg
Rückersdorf;b Gera;07580;036602;Thüringen
Rückersdorf;, Mittelfr;90607;0911;Bayern
Rückerswind;;96528;036766;Thüringen
Rückholz;;87494;08369;Bayern
Rückweiler;;55776;06789;Rheinland-Pfalz
Rüde;b Satrup;24986;04633;Schlewig-Holstein
Rudelzhausen;;84104;08752;Bayern
Rüdenau;;63924;09371;Bayern
Rüdenhausen;;97355;09383;Bayern
Ruderatshofen;;87674;08343;Bayern
Rudersberg;, Württ;73635;07183;Baden-Württemberg
Rudersdorf;b Sömmerda;99628;036373;Thüringen
Rüdersdorf;b Berlin;15562;033638;Brandenburg
Rüdershausen;, Eichsfeld;37434;05529;Niedersachsen
Ruderting;;94161;08509;Bayern
Rüdesheim;, Kr Bad Kreuznach;55593;0671;Rheinland-Pfalz
Rüdesheim am Rhein;;65385;06722;Hessen
Rudeshof;;88459;08395;Baden-Württemberg
Rüdigsdorf;b Nordhausen;99734;03631;Thüringen
Rüdnitz;;16321;03338;Brandenburg
Rudolstadt;;07407;03672;Thüringen
Ruest;;19374;038727;Mecklenburg-Vorpommern
Rugendorf;;95365;09223;Bayern
Rugensee;;19069;03867;Mecklenburg-Vorpommern
Rügge;;24405;04646;Schlewig-Holstein
Rüggow;;23970;03841;Mecklenburg-Vorpommern
Rügkamp;;23992;038422;Mecklenburg-Vorpommern
Rügland;;91622;09828;Bayern
Rühen;;38471;05367;Niedersachsen
Ruhethal;b Hagenow;19230;038856;Mecklenburg-Vorpommern
Ruhla;;99842;036929;Thüringen
Ruhland;;01945;035752;Brandenburg
Ruhlsdorf;b Jessen, Elster;06917;03537;Sachsen-Anhalt
Ruhlsdorf;b Strausberg;15345;03341;Brandenburg
Ruhlsdorf;b Bernau b Berlin;16348;033395;Brandenburg
Ruhlsdorf Ausbau;;16348;033395;Brandenburg
Ruhmannsfelden;;94239;09929;Bayern
Rühn;;18246;038462;Mecklenburg-Vorpommern
Ruhpolding;;83324;08663;Bayern
Ruhsdorf;;83329;08681;Bayern
Rühstädt;;19322;038791;Brandenburg
Ruhstorf;a d Rott;94099;08531;Bayern
Ruhwinkel;;24601;04323;Schlewig-Holstein
Rukieten;;18258;038453;Mecklenburg-Vorpommern
Rullstorf;;21379;04136;Niedersachsen
Rülzheim;;76761;07272;Rheinland-Pfalz
Rumbach;;76891;06394;Rheinland-Pfalz
Rümmelsheim;;55452;06721;Rheinland-Pfalz
Rümmingen;;79595;07621;Baden-Württemberg
Rumohr;;24254;04347;Schlewig-Holstein
Rümpel;;23843;04531;Schlewig-Holstein
Runding;b Cham, Oberpf;93486;09971;Bayern
Rundsmühlhof;;71729;07144;Baden-Württemberg
Runkel;, Lahn;65594;06482;Hessen
Rupbach, Gem Bremberg;;56379;06439;Rheinland-Pfalz
Rupbach, Gem Gutenacker;;56379;06439;Rheinland-Pfalz
Rupbach, Gem Steinsberg;;56379;06439;Rheinland-Pfalz
Rupensdorf;;23923;038828;Mecklenburg-Vorpommern
Ruppach-Goldhausen;;56412;02602;Rheinland-Pfalz
Ruppersdorf;b Lobenstein;07356;036643;Thüringen
Ruppertsberg;;67152;06326;Rheinland-Pfalz
Ruppertsecken;;67808;06361;Rheinland-Pfalz
Ruppertsgrün;b Plauen;08543;037439;Sachsen
Ruppertshofen;, Taunus;56357;06772;Rheinland-Pfalz
Ruppertshofen;b Schwäbisch Gmünd;73577;07176;Baden-Württemberg
Ruppertsweiler;;66957;06395;Rheinland-Pfalz
Ruppichteroth;;53809;02295;Nordrhein-Westfalen
Ruschberg;;55776;06783;Rheinland-Pfalz
Rüscheid;;56584;02639;Rheinland-Pfalz
Rüsseina;;01623;035242;Sachsen
Rüsselsheim;;65428;06142;Hessen
Rüssingen;;67308;06355;Rheinland-Pfalz
Rust;, Baden;77977;07822;Baden-Württemberg
Rustenfelde;;37318;036081;Thüringen
Rutenberg;;17279;039888;Brandenburg
Rüterberg;;19303;038758;Mecklenburg-Vorpommern
Rutesheim;;71277;07152;Baden-Württemberg
Rüthen;;59602;02952;Nordrhein-Westfalen
Ruthenbeck;;19089;03863;Mecklenburg-Vorpommern
Rüthnick;;16835;033926;Brandenburg
Ruthweiler;;66869;06381;Rheinland-Pfalz
Rüting;b Grevesmühlen;23936;038822;Mecklenburg-Vorpommern
Rüting Steinfort;;23936;038822;Mecklenburg-Vorpommern
Rutsweiler;am Glan;66887;06381;Rheinland-Pfalz
Rutsweiler;an der Lauter;67752;06304;Rheinland-Pfalz
Ruttersdorf-Lotschen;;07646;036428;Thüringen
Saal;b Ribnitz-Damgarten;18317;038223;Mecklenburg-Vorpommern
Saal;a d Donau;93342;09441;Bayern
Saal;a d Saale;97633;09762;Bayern
Saalburg;, Saale;07929;036647;Thüringen
Saalburg;, Taunus, Bahnhof;61273;;Hessen
Saaldorf;b Lobenstein;07356;036651;Thüringen
Saalfeld;/Saale;07318;03671;Thüringen
Saalhausen;, NL;01994;035329;Brandenburg
Saalow;;15806;03377;Brandenburg
Saalstadt;;66919;06375;Rheinland-Pfalz
Saara;b Schmölln, Thür;04603;034493;Thüringen
Saara;b Gera;07589;036604;Thüringen
Saarbrücken;;66111;0681;Saarland
Saarbrücken;;66113;0681;Saarland
Saarbrücken;;66115;0681;Saarland
Saarbrücken;;66117;0681;Saarland
Saarbrücken;;66119;0681;Saarland
Saarbrücken;;66121;0681;Saarland
Saarbrücken;;66123;0681;Saarland
Saarbrücken;;66125;0681;Saarland
Saarbrücken;;66126;0681;Saarland
Saarbrücken;;66127;0681;Saarland
Saarbrücken;;66128;0681;Saarland
Saarbrücken;;66129;0681;Saarland
Saarbrücken;;66130;0681;Saarland
Saarbrücken;;66131;0681;Saarland
Saarbrücken;;66132;0681;Saarland
Saarbrücken;;66133;0681;Saarland
Saarburg;, Saar;54439;06581;Rheinland-Pfalz
Saargrund;;98678;03686;Thüringen
Saaringen;;14778;03381;Brandenburg
Saaringen;;14778;03381;Brandenburg
Saarlouis;;66740;06831;Saarland
Saarmund;;14552;033200;Brandenburg
Saarwellingen;;66793;06838;Saarland
Saatel;b Ribnitz-Damgarten;18314;038324;Mecklenburg-Vorpommern
Saathain;;04932;03533;Brandenburg
Sabel;b Güstrow;18299;038454;Mecklenburg-Vorpommern
Sabow;;23923;038828;Mecklenburg-Vorpommern
Saccassne;;03096;035603;Brandenburg
Sachau;b Gardelegen;39649;039082;Sachsen-Anhalt
Sachsdorf;;01665;035204;Sachsen
Sachsen;b. Ansbach;91623;09827;Bayern
Sachsenbande;b Wilster;25554;04823;Schlewig-Holstein
Sachsenbrunn;;98678;03686;Thüringen
Sachsenburg;b Artern;06578;034673;Thüringen
Sachsendorf;, Oderbruch;15306;033601;Brandenburg
Sachsendorf;b Schönebeck, Elbe;39240;039295;Sachsen-Anhalt
Sachsenhagen;;31553;05725;Niedersachsen
Sachsenhausen;b Weimar, Thür;99439;03643;Thüringen
Sachsenheim;, Württ;74343;07147;Baden-Württemberg
Sachsenkam;;83679;08021;Bayern
Sachsgrün;;08606;037434;Sachsen
Sackenheimerhof;;56299;02625;Rheinland-Pfalz
Sacrow;;15913;0331;Brandenburg
Sadelkow;;17099;039606;Mecklenburg-Vorpommern
Sadenbeck;;16928;033989;Brandenburg
Saerbeck;;48369;02574;Nordrhein-Westfalen
Saerbeck;;48369;02574;Nordrhein-Westfalen
Saffig;;56648;02625;Rheinland-Pfalz
Sagard;;18551;038302;Mecklenburg-Vorpommern
Sagsdorf;;19406;03847;Mecklenburg-Vorpommern
Sahms;;21493;04151;Schlewig-Holstein
Sailauf;;63877;06093;Bayern
Salach;, Württ;73084;07162;Baden-Württemberg
Salbitz;;04769;035268;Sachsen
Salching;;94330;09426;Bayern
Saldenburg;;94163;08504;Bayern
Salem;b Ratzeburg;23911;04541;Schlewig-Holstein
Salem;, Baden;88682;07553;Baden-Württemberg
Salgen;;87775;08265;Bayern
Sallgast;;03238;035329;Brandenburg
Sallmannshausen;;99834;036922;Thüringen
Sallneck;;79692;07629;Baden-Württemberg
Salm;, Eifel;54570;06599;Rheinland-Pfalz
Salmbacher Passage;, Forsthaus;76779;;Rheinland-Pfalz
Salmberg;;54533;;Rheinland-Pfalz
Salmtal;;54528;06578;Rheinland-Pfalz
Salomonsborn;;99100;0361;Thüringen
Salomonsborn;;99100;0361;Thüringen
Salow;;17099;039601;Mecklenburg-Vorpommern
Salscheid;, Gem Seelbach;56379;;Rheinland-Pfalz
Salz;, Westerw;56414;06435;Rheinland-Pfalz
Salz;b Bad Neustadt a d Saale;97616;09771;Bayern
Salzbergen;;48499;05976;Niedersachsen
Salzbrunn;;14547;033204;Brandenburg
Salzburg;, Westerw;56479;02667;Rheinland-Pfalz
Salzburg;b Neufahrn, Niederbay;84088;08773;Bayern
Salzenweiler;;72291;07446;Baden-Württemberg
Salzfurtkapelle;;06779;03494;Sachsen-Anhalt
Salzgitter;;38226;05341;Niedersachsen
Salzgitter;;38228;05341;Niedersachsen
Salzgitter;;38229;05341;Niedersachsen
Salzgitter;;38239;05341;Niedersachsen
Salzgitter;;38259;05341;Niedersachsen
Salzhausen;, Lüneburger Heide;21376;04172;Niedersachsen
Salzhemmendorf;;31020;05153;Niedersachsen
Salzkotten;;33154;05258;Nordrhein-Westfalen
Salzmünde;;06198;034609;Sachsen-Anhalt
Salzwedel;, Hansestadt;29410;03901;Sachsen-Anhalt
Salzweg;;94121;0851;Bayern
Samerberg;;83122;08032;Bayern
Samern;;48465;05923;Niedersachsen
Samkow;;19217;038873;Mecklenburg-Vorpommern
Samswegen;;39326;039202;Sachsen-Anhalt
Samtens;;18573;038306;Mecklenburg-Vorpommern
Sand;a. Main;97522;09524;Bayern
Sandau;(Elbe);39524;039383;Sachsen-Anhalt
Sandauerholz;;39606;039390;Sachsen-Anhalt
Sandbeiendorf;;39517;039364;Sachsen-Anhalt
Sandberg;, Unterfr;97657;09701;Bayern
Sandbostel;;27446;04764;Niedersachsen
Sandbühlerhof;;76887;;Rheinland-Pfalz
Sande;b Ratzeburg;23909;04541;Schlewig-Holstein
Sande;, Gem Bünsdorf;24361;;Schlewig-Holstein
Sande;, Kr Friesl;26452;04422;Niedersachsen
Sandersdorf;, Sachs-Anh;06792;03493;Sachsen-Anhalt
Sandersleben;;06456;034785;Sachsen-Anhalt
Sandersmühle;;65326;06120;Rheinland-Pfalz
Sandesneben;;23898;04536;Schlewig-Holstein
Sandhagen;b Bad Doberan;18233;038294;Mecklenburg-Vorpommern
Sandhagen Abbau;;18233;038292;Mecklenburg-Vorpommern
Sandhausen;, Baden;69207;06224;Baden-Württemberg
Sandhausen;, Baden;69207;06224;Baden-Württemberg
Sandkrug;b Eberswalde;16230;033366;Brandenburg
Sandkrug;a d Elbe;19322;;Brandenburg
Sandkrug;b Lübz;19386;;Mecklenburg-Vorpommern
Sandschenke;;02923;;Sachsen
Sandstedt;;27628;04702;Niedersachsen
Sangerhausen;;06526;03464;Sachsen-Anhalt
Sanitz;b Rostock;18190;038209;Mecklenburg-Vorpommern
Sankelmark;;24988;04638;Schlewig-Holstein
Sankt Aldegund;;56858;06542;Rheinland-Pfalz
Sankt Augustin;;53757;02241;Nordrhein-Westfalen
Sankt Englmar;;94379;09965;Bayern
Sankt Johann;b Mayen;56727;02651;Rheinland-Pfalz
Sankt Julian;;66887;06387;Rheinland-Pfalz
Sankt Katharinen;, Kr Bad Kreuznach;55595;06706;Rheinland-Pfalz
Sankt Margarethen;;25572;04858;Schlewig-Holstein
Sankt Peter-Ording;;25826;04863;Schlewig-Holstein
Sankt Sebastian;;56220;0261;Rheinland-Pfalz
Sankt Sebastian;;56220;0261;Rheinland-Pfalz
Sankt Thomas;, Eifel;54655;06563;Rheinland-Pfalz
Sankt Wolfgang;;84427;08085;Bayern
Sanne;b Stendal;39596;039034;Sachsen-Anhalt
Sanne-Kerkuhn;b Osterburg;39606;039034;Sachsen-Anhalt
Sansenhof;;63916;09373;Bayern
Santow;;23936;03881;Mecklenburg-Vorpommern
Sanzkow;;17111;03998;Mecklenburg-Vorpommern
Sargenroth;;55471;06756;Rheinland-Pfalz
Sargleben;;19357;038797;Brandenburg
Sargstedt;;38822;039425;Sachsen-Anhalt
Sarkwitz;;23629;04504;Schlewig-Holstein
Sarkwitz;;23629;04504;Schlewig-Holstein
Sarlhusen;;24616;04324;Schlewig-Holstein
Sarmersbach;;54552;06592;Rheinland-Pfalz
Sarmstorf;b Güstrow;18276;03843;Mecklenburg-Vorpommern
Sarnow;b Pritzwalk;16928;03395;Brandenburg
Sarnow;b Anklam;17392;039722;Mecklenburg-Vorpommern
Sarow;;17111;039996;Mecklenburg-Vorpommern
Sarstedt;;31157;05066;Niedersachsen
Sarzbüttel;;25785;04806;Schlewig-Holstein
Sasbach;b Achern, Baden;77880;07841;Baden-Württemberg
Sasbach;am Kaiserstuhl;79361;07642;Baden-Württemberg
Sasbachwalden;;77887;07841;Baden-Württemberg
Sassen;, Vorpommern;17121;039998;Mecklenburg-Vorpommern
Sassen;, Eifel;56767;02692;Rheinland-Pfalz
Sassenberg;, Westf;48336;02583;Nordrhein-Westfalen
Sassenburg;;38524;05371;Niedersachsen
Sassenhof;;92681;09682;Bayern
Saßleben;;03205;03541;Brandenburg
Sassnitz;;18546;038392;Mecklenburg-Vorpommern
Saterland;;26683;04492;Niedersachsen
Satow;b Röbel;17209;039924;Mecklenburg-Vorpommern
Satow;b Bad Doberan;18239;038295;Mecklenburg-Vorpommern
Satrup;;24986;04633;Schlewig-Holstein
Satteldorf;;74589;07951;Baden-Württemberg
Sattelmühle;, Pfalz;67468;06325;Rheinland-Pfalz
Sättelstädt;;99848;036920;Thüringen
Sattlthambach;;83546;08073;Bayern
Satuelle;;39345;039058;Sachsen-Anhalt
Satzkorn;;14476;033208;Brandenburg
Satzung;;09496;037364;Sachsen
Saubach;;06647;034465;Sachsen-Anhalt
Sauen;;15848;033672;Brandenburg
Sauensiek;;21644;04169;Niedersachsen
Sauerbrunnen;b Birkenfeld, Nahe;55765;;Rheinland-Pfalz
Saueressigs Mühle;;56346;;Rheinland-Pfalz
Sauerlach;;82054;08104;Bayern
Sauerseifen;;54570;06572;Rheinland-Pfalz
Sauersmühle;;56754;;Rheinland-Pfalz
Sauerthal;;65391;06726;Rheinland-Pfalz
Sauerwies;;54673;;Rheinland-Pfalz
Sauldorf;;88605;07777;Baden-Württemberg
Saulgrub;;82442;08845;Bayern
Saulheim;;55291;06732;Rheinland-Pfalz
Saultitz;;01683;035242;Sachsen
Saunstorf;;23996;038424;Mecklenburg-Vorpommern
Saupsdorf;;01855;035974;Sachsen
Saurasen;;06333;034781;Sachsen-Anhalt
Saurenhof;, Filstal;73529;07165;Baden-Württemberg
Saustrup;;24392;04641;Schlewig-Holstein
Sauzin;;17440;03836;Mecklenburg-Vorpommern
Saxdorf;;04895;035341;Brandenburg
Saxler;;54558;06573;Rheinland-Pfalz
Sayda;;09619;037365;Sachsen
Schaafheim;;64850;06073;Hessen
Schaalby;;24882;04622;Schlewig-Holstein
Schachahof;;84385;08565;Bayern
Schachdorf Ströbeck;;38822;039427;Sachsen-Anhalt
Schachen;, Gem Gars a Inn;83546;08073;Bayern
Schachen;, Gem Amerang;83552;;Bayern
Schacherhof;;67806;06361;Rheinland-Pfalz
Schacht-Audorf;;24790;04331;Schlewig-Holstein
Schachtebich;;37318;036083;Thüringen
Schackendorf;b Bad Segeberg;23795;04551;Schlewig-Holstein
Schackensleben;;39343;039206;Sachsen-Anhalt
Schackenthal;;06449;034746;Sachsen-Anhalt
Schacksdorf;;03238;03531;Brandenburg
Schackstedt;;06425;034692;Sachsen-Anhalt
Schaddingsdorf;;19217;038873;Mecklenburg-Vorpommern
Schadebeuster;;19322;03877;Brandenburg
Schadeleben;;06449;034741;Sachsen-Anhalt
Schaderthal;;07330;036731;Thüringen
Schafflund;;24980;04639;Schlewig-Holstein
Schafstädt;;06255;034636;Sachsen-Anhalt
Schafstedt;, Dithm;25725;04805;Schlewig-Holstein
Schäftlarn;;82069;08178;Bayern
Schaibmaierhof;, Gem Hettenshausen;85304;08441;Bayern
Schaidenhausen;;85376;08165;Bayern
Schalkau;;96528;036766;Thüringen
Schalkenbach;;53426;02646;Rheinland-Pfalz
Schalkenmehren;;54552;06592;Rheinland-Pfalz
Schalkham;b Gerzen, Vils;84175;08744;Bayern
Schalkholz;;25782;04838;Schlewig-Holstein
Schalksmühle;;58579;02355;Nordrhein-Westfalen
Schallbach;;79597;07621;Baden-Württemberg
Schallenburg;;99610;03634;Thüringen
Schallodenbach;;67701;06363;Rheinland-Pfalz
Schallstadt;;79227;07664;Baden-Württemberg
Schänitz;b Meißen, Sachs;01665;035246;Sachsen
Schankweiler;;54668;06523;Rheinland-Pfalz
Schanzerhof;;66882;;Rheinland-Pfalz
Schanzermühle;;66882;06372;Rheinland-Pfalz
Schäpe;;14547;033204;Brandenburg
Schapen;, Emsl;48480;05458;Niedersachsen
Schäplitz;;39579;039324;Sachsen-Anhalt
Schapow;;17291;039853;Brandenburg
Schaprode;;18569;038309;Mecklenburg-Vorpommern
Scharbeutz;;23683;04503;Schlewig-Holstein
Scharbeutz;;23684;04503;Schlewig-Holstein
Scharbow;;19230;03883;Mecklenburg-Vorpommern
Scharfbillig;;54636;06561;Rheinland-Pfalz
Scharfenberg;b Meißen, Sachs;01665;03521;Sachsen
Scharfenholz;;24214;;Schlewig-Holstein
Scharfenstein;, Erzgeb;09435;03725;Sachsen
Scharfstorf;;23996;038424;Mecklenburg-Vorpommern
Scharleuk;;19339;038791;Brandenburg
Scharling;b Waging a See;83329;08681;Bayern
Scharnebeck;b Lüneburg;21379;04136;Niedersachsen
Scharnhorst;, Kr Celle;29348;05142;Niedersachsen
Scharrhof;;66851;06375;Rheinland-Pfalz
Scharrmühle;, Pfalz;66851;06375;Rheinland-Pfalz
Schartau;b Burg b Magdeburg;39291;03921;Sachsen-Anhalt
Schashagen;;23730;04564;Schlewig-Holstein
Schattin;;23923;038821;Mecklenburg-Vorpommern
Schauen;;38835;039421;Sachsen-Anhalt
Schauenburg;;34270;05601;Hessen
Schauenstein;;95197;09252;Bayern
Schauerberg;, Pfalz;66919;06375;Rheinland-Pfalz
Schaufling;;94571;09904;Bayern
Schaurach;;83565;08092;Bayern
Schauren;b Idar-Oberstein;55758;06786;Rheinland-Pfalz
Schauren;b Blankenrath;56865;06545;Rheinland-Pfalz
Schautenhof;;73642;;Baden-Württemberg
Schechen;, Oberbay;82393;08801;Bayern
Schechen;b Rosenheim, Oberbay;83135;08039;Bayern
Schechingen;;73579;07175;Baden-Württemberg
Scheden;;37127;05546;Niedersachsen
Scheer;;72516;07572;Baden-Württemberg
Scheeßel;;27383;04263;Niedersachsen
Schefflenz;;74850;06293;Baden-Württemberg
Scheggerott;;24392;04641;Schlewig-Holstein
Scheibe-Alsbach;;98749;036704;Thüringen
Scheibenberg;, Erzgeb;09481;037349;Sachsen
Scheibenhardt;, Pfalz;76779;07277;Rheinland-Pfalz
Scheid;, Eifel;54611;06557;Rheinland-Pfalz
Scheidegg;, Allgäu;88175;08381;Bayern
Scheiditz;;07646;036692;Thüringen
Scheidt;, Rhein-Lahn-Kr;56379;06439;Rheinland-Pfalz
Scheinfeld;;91443;09162;Bayern
Scheitenkorb;;54673;06524;Rheinland-Pfalz
Schelertal;;66954;;Rheinland-Pfalz
Schelkau;;06682;034443;Sachsen-Anhalt
Schelklingen;;89601;07394;Baden-Württemberg
Schellbach;b Zeitz, Elster;06712;034423;Sachsen-Anhalt
Schelldorf;b Tangerhütte;39517;039362;Sachsen-Anhalt
Schellerten;;31174;05123;Niedersachsen
Schellhorn;b Preetz, Holst;24211;04342;Schlewig-Holstein
Schellweiler;;66869;06381;Rheinland-Pfalz
Schemmerhofen;;88433;07356;Baden-Württemberg
Schenefeld;, Bz Hamburg;22869;040;Schlewig-Holstein
Schenefeld;, Mittelholst;25560;04892;Schlewig-Holstein
Schenkelberg;;56244;02626;Rheinland-Pfalz
Schenkenberg;b Brandenburg an der Havel;14778;033207;Brandenburg
Schenkenberg;b Prenzlau;17291;039854;Brandenburg
Schenkendorf;b Königs Wusterhausen;15711;03375;Brandenburg
Schenkenhorst;b Potsdam;14532;03379;Brandenburg
Schenkenhorst;, Altmark;39638;039080;Sachsen-Anhalt
Schenkenzell;;77773;07836;Baden-Württemberg
Schenkhöfle;;73655;07182;Baden-Württemberg
Schenklengsfeld;;36277;06629;Hessen
Scherbenmühle;;74523;07903;Baden-Württemberg
Scherer;;83552;08074;Bayern
Schermbeck;, Niederrhein;46514;02853;Nordrhein-Westfalen
Schermcke;;39387;039407;Sachsen-Anhalt
Schermen;;39291;039222;Sachsen-Anhalt
Schernberg;b Ebeleben;99713;036020;Thüringen
Schernebeck;;39517;039361;Sachsen-Anhalt
Schernfeld;;85132;08422;Bayern
Schernikau;b Stendal;39579;039320;Sachsen-Anhalt
Schernsdorf;;15890;033655;Brandenburg
Scherstetten;;86872;08204;Bayern
Scheßlitz;;96110;09542;Bayern
Scheuder;;06386;034977;Sachsen-Anhalt
Scheuerfeld;, Sieg;57584;02741;Rheinland-Pfalz
Scheuerlehof;;78567;07463;Baden-Württemberg
Scheuern;b Neuerburg, Eifel;54673;06564;Rheinland-Pfalz
Scheuring;;86937;08195;Bayern
Scheyern;;85298;08441;Bayern
Schiebsdorf;;15938;035474;Brandenburg
Schieder-Schwalenberg;;32816;05282;Nordrhein-Westfalen
Schielo;;06493;039484;Sachsen-Anhalt
Schierau;;06779;034906;Sachsen-Anhalt
Schieren;b Bad Segeberg;23795;04551;Schlewig-Holstein
Schierensee;, Holst;24241;04347;Schlewig-Holstein
Schierke;am Brocken;38879;039455;Sachsen-Anhalt
Schierling;;84069;09451;Bayern
Schierschwende;;99988;036024;Thüringen
Schiersfeld;;67823;06362;Rheinland-Pfalz
Schiesheim;;65623;06430;Rheinland-Pfalz
Schießhof;;74639;07948;Baden-Württemberg
Schiffdorf;;27619;04706;Niedersachsen
Schifferstadt;;67105;06235;Rheinland-Pfalz
Schiffmühle;a d Oder;16259;03344;Brandenburg
Schiffweiler;;66578;06821;Saarland
Schilda;;03253;035326;Brandenburg
Schildau;, Gneisenaustadt;04889;034221;Sachsen
Schildberg;b Grevesmühlen;23936;038822;Mecklenburg-Vorpommern
Schilde;;19322;038793;Brandenburg
Schildfeld;;19260;038843;Mecklenburg-Vorpommern
Schildow;;16552;033056;Brandenburg
Schillingen;;54429;06589;Rheinland-Pfalz
Schillinghof;;73553;07182;Baden-Württemberg
Schillingsfürst;, Mittelfr;91583;09868;Bayern
Schillingstedt;;99625;03635;Thüringen
Schillsdorf;;24637;04394;Schlewig-Holstein
Schilpenbühl;;73577;;Baden-Württemberg
Schiltach;;77761;07836;Baden-Württemberg
Schiltberg;;86576;08259;Bayern
Schimberg;;37308;;Thüringen
Schimm;;23972;03841;Mecklenburg-Vorpommern
Schimmelshütte;, Odenw;64720;;Hessen
Schindhard;;66996;06391;Rheinland-Pfalz
Schinkel;b Gettorf;24214;04346;Schlewig-Holstein
Schinne;;39579;039320;Sachsen-Anhalt
Schiphorst;;23847;04536;Schlewig-Holstein
Schipkau;;01993;035754;Brandenburg
Schirgiswalde;;02681;03592;Sachsen
Schirmitz;;92718;0961;Bayern
Schirnding;;95706;09233;Bayern
Schirnrod;;98678;03686;Thüringen
Schkauditz;;06712;034425;Sachsen-Anhalt
Schkeuditz;;04435;034204;Sachsen
Schkölen;b Eisenberg, Thür;07619;036694;Thüringen
Schköna;;06773;034955;Sachsen-Anhalt
Schkopau;;06258;0345;Sachsen-Anhalt
Schkortleben;;06688;034446;Sachsen-Anhalt
Schlabendorf;;15926;035439;Brandenburg
Schladen;;38315;05335;Niedersachsen
Schladt;;54534;06575;Rheinland-Pfalz
Schlagenthin;;39307;039348;Sachsen-Anhalt
Schlagsdorf;b Gadebusch;19217;038875;Mecklenburg-Vorpommern
Schlaitdorf;;72667;07127;Baden-Württemberg
Schlaitz;;06774;034955;Sachsen-Anhalt
Schlalach;;14822;033748;Brandenburg
Schlamau;;14827;033849;Brandenburg
Schlammersdorf;, Oberpf;95519;09205;Bayern
Schlangen;;33189;05252;Nordrhein-Westfalen
Schlangenbad;, Taunus;65388;06129;Hessen
Schlanstedt;;38838;039401;Sachsen-Anhalt
Schlat;;73114;07161;Baden-Württemberg
Schlattwiesen;;72147;;Baden-Württemberg
Schlauchhof;;73453;07975;Baden-Württemberg
Schleberoda;;06632;034464;Sachsen-Anhalt
Schleching;;83259;08649;Bayern
Schlechtenmühle;;71144;;Baden-Württemberg
Schlechtsart;;98663;036875;Thüringen
Schleesen;;06785;034904;Sachsen-Anhalt
Schlegel;b Zittau;02788;035843;Sachsen
Schlegel;b Lobenstein;07366;036642;Thüringen
Schlegel;b Mittweida;09661;037207;Sachsen
Schlehdorf;;82444;08851;Bayern
Schleich;;54340;06507;Rheinland-Pfalz
Schleid;b Vacha;36419;036967;Thüringen
Schleid;b Bitburg;54636;06569;Rheinland-Pfalz
Schleiden;, Eifel;53937;02445;Nordrhein-Westfalen
Schleife;, OL;02959;035773;Sachsen
Schleife-Mulkwitz;;02959;035773;Sachsen
Schleife-Rohne;;02959;035773;Sachsen
Schleifhäusle;b Abtsgmünd;73453;07963;Baden-Württemberg
Schleifmühle;, Gem Bischbrunn;97852;;Bayern
Schleifreisen;;07629;036601;Thüringen
Schleifthor;;97836;;Bayern
Schleimünde;;24404;04642;Schlewig-Holstein
Schleinitz;b Zeitz, Elster;06727;034422;Sachsen-Anhalt
Schleiz;;07907;03663;Thüringen
Schlema;;08301;03772;Sachsen
Schlemmin;b Bützow;18249;038464;Mecklenburg-Vorpommern
Schlemmin;b Ribnitz-Damgarten;18320;038225;Mecklenburg-Vorpommern
Schlenzer;;14913;033746;Brandenburg
Schlepzig;;15910;035472;Brandenburg
Schlesen;;24256;04303;Schlewig-Holstein
Schleswig;;24837;04621;Schlewig-Holstein
Schlettau;, Erzgeb;09487;03733;Sachsen
Schleuse;b Laurenburg;56377;;Rheinland-Pfalz
Schleuse Dausenau;;56130;;Rheinland-Pfalz
Schleuse Ems;;56130;;Rheinland-Pfalz
Schleuse Grütz;;14715;;Brandenburg
Schleusenow;;19273;038844;Mecklenburg-Vorpommern
Schleusingen;;98553;036841;Thüringen
Schleusingerneundorf;;98553;036841;Thüringen
Schlichting;, Dithm;25779;04882;Schlewig-Holstein
Schlieben;;04936;035361;Brandenburg
Schliengen;;79418;07635;Baden-Württemberg
Schlier;;88281;07529;Baden-Württemberg
Schlierschied;;55483;06765;Rheinland-Pfalz
Schliersee;;83727;08026;Bayern
Schlitz;;36110;06642;Hessen
Schlöben;;07646;036428;Thüringen
Schlockow;;18249;038462;Mecklenburg-Vorpommern
Schloen;;17192;039934;Mecklenburg-Vorpommern
Schloß Holte-Stukenbrock;;33758;05207;Nordrhein-Westfalen
Schloßböckelheim;;55596;06758;Rheinland-Pfalz
Schlosser;;83739;;Bayern
Schloßhof;b Naunheim, Maifeld;56753;;Rheinland-Pfalz
Schloßhof;, Schwarzw;78730;;Baden-Württemberg
Schloßkulm;;07407;03672;Thüringen
Schlößlesmühle;;71144;;Baden-Württemberg
Schloßvippach;;99195;036371;Thüringen
Schlotfeld;;25551;04826;Schlewig-Holstein
Schlotheim;;99994;036021;Thüringen
Schlowe;;19406;038485;Mecklenburg-Vorpommern
Schluchsee;;79859;07656;Baden-Württemberg
Schlüchtern;;36381;06661;Hessen
Schlunkendorf;;14547;033204;Brandenburg
Schlüsselfeld;;96132;09552;Bayern
Schmachtenhagen;b Oranienburg;16515;03301;Brandenburg
Schmachthagen;b Grevesmühlen;23936;038824;Mecklenburg-Vorpommern
Schmadebeck;;18236;038292;Mecklenburg-Vorpommern
Schmakentin;;23992;03841;Mecklenburg-Vorpommern
Schmalenberg;, Pfalz;67718;06307;Rheinland-Pfalz
Schmalensee;;24638;04323;Schlewig-Holstein
Schmalfeld;, Holst;24640;04191;Schlewig-Holstein
Schmalfelderhof;;67822;06362;Rheinland-Pfalz
Schmalkalden;;98574;03683;Thüringen
Schmallenberg;;57392;02972;Nordrhein-Westfalen
Schmalstede;;24241;04322;Schlewig-Holstein
Schmalzerode;;06295;03475;Sachsen-Anhalt
Schmalzmühle;;91717;09832;Bayern
Schmargendorf;b Angermünde;16278;03331;Brandenburg
Schmarsow;, Vorpommern;17129;039991;Mecklenburg-Vorpommern
Schmatzfeld;;38855;039451;Sachsen-Anhalt
Schmatzin;;17390;039724;Mecklenburg-Vorpommern
Schmedeland;, Gem Ulsnis;24888;04641;Schlewig-Holstein
Schmedeswurth;;25724;04851;Schlewig-Holstein
Schmeheim;;98530;036846;Thüringen
Schmelz;, Saar;66839;06887;Saarland
Schmerbach;b Gotha, Thür;99891;036259;Thüringen
Schmerdorf;;06667;034445;Sachsen-Anhalt
Schmergow;;14550;033207;Brandenburg
Schmerkendorf;;04895;035365;Brandenburg
Schmerwitz;;14827;033849;Brandenburg
Schmetzdorf;b Rathenow;14715;033870;Brandenburg
Schmidgaden;;92546;09438;Bayern
Schmidmühlen;;92287;09474;Bayern
Schmidthachenbach;;55758;06757;Rheinland-Pfalz
Schmiechen;, Bay;86511;08206;Bayern
Schmiedeberg;, Osterzgeb;01762;035052;Sachsen
Schmiedeberg;b Angermünde;16278;033334;Brandenburg
Schmiedefeld;am Rennsteig;98711;036782;Thüringen
Schmiedefeld;b Neuhaus a Rennweg;98739;036782;Thüringen
Schmiedehausen;;99518;036421;Thüringen
Schmieden;b Rudolstadt;07407;036743;Thüringen
Schmieding;b Traunwalchen;83374;08669;Bayern
Schmieritz;;07819;036481;Thüringen
Schmilau;;23911;04541;Schlewig-Holstein
Schmilkendorf;;06896;034929;Sachsen-Anhalt
Schmißberg;;55765;06782;Rheinland-Pfalz
Schmitshausen;;66484;06375;Rheinland-Pfalz
Schmitt;;56825;02677;Rheinland-Pfalz
Schmitten;, Taunus;61389;06084;Hessen
Schmittweiler;b Callbach;67829;06753;Rheinland-Pfalz
Schmögelsdorf;;14913;033747;Brandenburg
Schmogrow;;03096;035603;Brandenburg
Schmolde;;16945;033968;Brandenburg
Schmölen;;04828;03425;Sachsen
Schmölln;, OL;01877;03594;Sachsen
Schmölln;, Thür;04626;034491;Thüringen
Schmölln;b Prenzlau;17291;039862;Brandenburg
Schmölz;b Kulmbach;95355;;Bayern
Schmon;;06268;034771;Sachsen-Anhalt
Schmorda;;07389;03647;Thüringen
Schmorkau;b Königsbrück;01936;035795;Sachsen
Schnabelwaid;;91289;09270;Bayern
Schnackenburg;;29493;05840;Niedersachsen
Schnackenwöhr;;95515;09204;Bayern
Schnaditz;;04849;034243;Sachsen
Schnaidt;b Neukirchen a Teisenberg;83364;;Bayern
Schnaitsee;;83530;08074;Bayern
Schnaittach;;91220;09153;Bayern
Schnaittenbach;;92253;09622;Bayern
Schnakenbek;;21481;04153;Schlewig-Holstein
Schnarup-Thumby;;24891;04623;Schlewig-Holstein
Schnatzlreut;;83362;08681;Bayern
Schneckengrün;;08527;09282;Sachsen
Schneckenhausen;;67699;06301;Rheinland-Pfalz
Schneckenlohe;;96277;09266;Bayern
Schneeberg;, Erzgeb;08289;03772;Sachsen
Schneeberg;b Beeskow;15848;03366;Brandenburg
Schneeberg;, Unterfr;63936;09373;Bayern
Schneefernerhaus;;82475;;Bayern
Schnega;;29465;05842;Niedersachsen
Schneidenbach;;08468;03765;Sachsen
Schneiderkrug;, Gem Emstek;49685;04447;Niedersachsen
Schneidlingen;;39435;039267;Sachsen-Anhalt
Schneidmühle;, Taunus;56355;06772;Rheinland-Pfalz
Schneizlreuth;;83458;08665;Bayern
Schnelldorf;, Mittelfr;91625;07950;Bayern
Schnellin;;06901;034927;Sachsen-Anhalt
Schnellmannshausen;;99831;036926;Thüringen
Schneppenbach;, Hunsrück;55608;06544;Rheinland-Pfalz
Schneverdingen;;29640;05193;Niedersachsen
Schnorbach;;55497;06764;Rheinland-Pfalz
Schnürpflingen;;89194;07346;Baden-Württemberg
Schöbendorf;;15837;033704;Brandenburg
Schobüll;b Husum, Nordsee;25875;04841;Schlewig-Holstein
Schochen;;87724;08306;Bayern
Schöchleinsmühle;;95490;09279;Bayern
Schochwitz;;06179;034609;Sachsen-Anhalt
Schoden;;54441;06581;Rheinland-Pfalz
Schöffengrund;;35641;06445;Hessen
Schöfweg;;94572;09908;Bayern
Scholen;b Sulingen;27251;04245;Niedersachsen
Schöllbronner Mühle;;76359;;Baden-Württemberg
Schollbrunn;, Spessart;97852;09394;Bayern
Schollene;;14715;039389;Sachsen-Anhalt
Schöllkrippen;;63825;06024;Bayern
Schöllnach;;94508;09903;Bayern
Schöllnitz;;03229;035434;Brandenburg
Scholmunderhof;;56843;;Rheinland-Pfalz
Scholterhaus;;88400;07351;Baden-Württemberg
Schömberg;b Gera;07570;036603;Thüringen
Schömberg;b Balingen;72355;07427;Baden-Württemberg
Schömberg;b Neuenbürg, Württ;75328;07084;Baden-Württemberg
Schömerich;;54314;06587;Rheinland-Pfalz
Schöna;b Pirna;01814;035028;Sachsen
Schöna;b Eilenburg;04838;034244;Sachsen
Schonach;im Schwarzwald;78136;07722;Baden-Württemberg
Schönaich;, Württ;71101;07031;Baden-Württemberg
Schöna-Kolpien;;04936;035364;Brandenburg
Schönau;(Pfalz);66996;06393;Rheinland-Pfalz
Schönau;, Odenw;69250;06228;Baden-Württemberg
Schönau;im Schwarzwald;79677;07673;Baden-Württemberg
Schönau;a. Königssee;83471;08652;Bayern
Schönau;, Niederbay;84337;08726;Bayern
Schönau;a d Brend;97659;09775;Bayern
Schönau v d Walde;;99894;036253;Thüringen
Schönau-Berzdorf;;02899;035874;Sachsen
Schönbach;b Löbau;02708;035872;Sachsen
Schönbach;b Greiz;07985;0365;Thüringen
Schönbach;, Kr Daun;54552;02676;Rheinland-Pfalz
Schönbeck;, Meckl;17349;03968;Mecklenburg-Vorpommern
Schönbek;, Holst;24582;04322;Schlewig-Holstein
Schönberg;b Glauchau;08393;037438;Sachsen
Schönberg;b Plauen;08539;036645;Sachsen
Schönberg;b Neuruppin;16835;033933;Brandenburg
Schönberg;b Neustadt, Dosse;16866;033971;Brandenburg
Schönberg;, Kr Hzgt Lauenb;22929;04534;Schlewig-Holstein
Schönberg;, Meckl;23923;038828;Mecklenburg-Vorpommern
Schönberg;(Holstein);24217;04344;Schlewig-Holstein
Schönberg;, Altmark;39615;039396;Sachsen-Anhalt
Schönberg;b Hermeskeil;54426;06504;Rheinland-Pfalz
Schönberg;, Kr Mühldorf a Inn;84573;08637;Bayern
Schönberg;, Niederbay;94513;08554;Bayern
Schönborn;b Dresden;01465;03528;Sachsen
Schönborn;b Dresden;01465;03528;Sachsen
Schönborn;b Großenhain, Sachs;01561;035248;Sachsen
Schönborn;, NL;03253;035326;Brandenburg
Schönborn;, Hunsrück;55469;06761;Rheinland-Pfalz
Schönborn;, Rhein-Lahn-Kr;56370;0641;Rheinland-Pfalz
Schönborn;, Pfalz;67808;06361;Rheinland-Pfalz
Schönbornerhof;;67742;06387;Rheinland-Pfalz
Schönbrunn;b Reichenbach, Vogtl;08485;037606;Sachsen
Schönbrunn;b Oelsnitz, Vogtl;08606;037421;Sachsen
Schönbrunn;b Zschopau;09429;03735;Sachsen
Schönbrunn;, Baden;69436;06262;Baden-Württemberg
Schönbrunn;i. Steigerwald;96185;09546;Bayern
Schönbrunn;b Hildburghausen;98667;036874;Thüringen
Schönburg;b Naumburg, Saale;06618;03445;Sachsen-Anhalt
Schondorf;a.Ammersee;86938;08192;Bayern
Schöndorf;b Schleiz;07924;036483;Thüringen
Schöndorf;, Ruwer;54316;06588;Rheinland-Pfalz
Schondra;;97795;09747;Bayern
Schöne Aussicht;b Kemmenau;56132;;Rheinland-Pfalz
Schöne Aussicht, Gem Landkern;;56759;;Rheinland-Pfalz
Schöne Aussicht, Gem Masburg;;56759;;Rheinland-Pfalz
Schönebeck;b Pritzwalk;16928;03395;Brandenburg
Schönebeck;(Elbe);39218;03928;Sachsen-Anhalt
Schöneberg;b Angermünde;16278;033338;Brandenburg
Schöneberg;, Hunsrück;55444;06724;Rheinland-Pfalz
Schöneberg;, Westerw;57638;02681;Rheinland-Pfalz
Schöneck;/Vogtl.;08261;037464;Sachsen
Schöneck;, Hess;61137;06187;Hessen
Schönecken;;54614;06553;Rheinland-Pfalz
Schönefeld;b Berlin;12529;030;Brandenburg
Schönefeld;b Berlin;12529;030;Brandenburg
Schönefeld;b Beelitz, Mark;14547;033204;Brandenburg
Schönefeld;b Jüterbog;14913;033743;Brandenburg
Schöneiche;b. Berlin;15566;030;Brandenburg
Schöneiche;b Zossen b Berlin;15806;03377;Brandenburg
Schöneicher Plan;;15749;033764;Brandenburg
Schönenberg;, Schwarzw;79677;07673;Baden-Württemberg
Schönenberg-Kübelberg;;66901;06373;Rheinland-Pfalz
Schönerlinde;b Bernau b Berlin;16352;030;Brandenburg
Schönermark;b Angermünde;16278;033335;Brandenburg
Schönermark;b Gransee;16775;03306;Brandenburg
Schönermark;b Neustadt, Dosse;16845;033973;Brandenburg
Schönermark;b Prenzlau;17291;039852;Brandenburg
Schönewalde;b Herzberg, Elster;04916;035362;Brandenburg
Schönewerda;;06556;034672;Thüringen
Schönewörde;;29396;05835;Niedersachsen
Schönfeld;b Großenhain, Sachs;01561;035248;Sachsen
Schönfeld;b Artern;06556;03466;Thüringen
Schönfeld;b Annaberg-Buchholz;09488;037341;Sachsen
Schönfeld;b Angermünde;16307;033333;Brandenburg
Schönfeld;b Bernau b Berlin;16356;033398;Brandenburg
Schönfeld;b Demmin;17111;039994;Mecklenburg-Vorpommern
Schönfeld;b Prenzlau;17291;039854;Brandenburg
Schönfeld;b Gadebusch;19205;038871;Mecklenburg-Vorpommern
Schönfeld;, Westprignitz;19348;03876;Brandenburg
Schönfeld;b Sternberg;19406;;Mecklenburg-Vorpommern
Schönfeld;b Schönhausen, Elbe;39524;039382;Sachsen-Anhalt
Schönfelde;;15518;033637;Brandenburg
Schönfels;;08115;037600;Sachsen
Schönfließ;b Seelow;15326;033602;Brandenburg
Schönfließ;b Oranienburg;16567;033056;Brandenburg
Schongau;;86956;08861;Bayern
Schöngeising;;82296;08153;Bayern
Schöngleina;;07646;036692;Thüringen
Schönhagen;b Luckenwalde;14959;033731;Brandenburg
Schönhagen;b Neustadt, Dosse;16866;033977;Brandenburg
Schönhagen;a d Dömnitz;16928;03395;Brandenburg
Schönhagen;b Heilbad Heiligenstadt;37318;036083;Thüringen
Schönhagener Mühle;;16928;;Brandenburg
Schönhausen;, Meckl;17337;039753;Mecklenburg-Vorpommern
Schönhausen;(Elbe);39524;0391;Sachsen-Anhalt
Schönheide;, Erzgeb;08304;037462;Sachsen
Schönhof;b Grevesmühlen;23936;038871;Mecklenburg-Vorpommern
Schönholz;b Rathenow;14728;033875;Brandenburg
Schönholz;b Perleberg;19348;038782;Brandenburg
Schönhorn Forsthaus;;16798;;Brandenburg
Schönhorst;, Holst;24220;04347;Schlewig-Holstein
Schöningen;;38364;05352;Niedersachsen
Schönkirchen;, Holst;24232;04348;Schlewig-Holstein
Schönlage;;19412;038483;Mecklenburg-Vorpommern
Schönow;b Angermünde;16306;03338;Brandenburg
Schönow;b Berlin;16321;03338;Brandenburg
Schönsee;;92539;09674;Bayern
Schönstedt;;99947;036022;Thüringen
Schonstett;;83137;08055;Bayern
Schöntal;, Jagst;74214;07943;Baden-Württemberg
Schönteichen;;01920;03578;Sachsen
Schonterhöhe;;73312;;Baden-Württemberg
Schönthal;, Oberpf;93488;09978;Bayern
Schonungen;;97453;09721;Bayern
Schönwald;im Schwarzwald;78141;07722;Baden-Württemberg
Schönwald;, Oberfr;95173;09287;Bayern
Schönwalde;b Falkensee;14621;03322;Brandenburg
Schönwalde;b Lübben;15910;035474;Brandenburg
Schönwalde;b Wandlitz;16352;033056;Brandenburg
Schönwalde;, Vorpommern;17309;03973;Mecklenburg-Vorpommern
Schönwalde;am Bungsberg;23744;04528;Schlewig-Holstein
Schönwalde;(Altmark);39517;03935;Sachsen-Anhalt
Schönwerder;;17291;039853;Brandenburg
Schönwolde;b Gadebusch;19205;038876;Mecklenburg-Vorpommern
Schönwölkau;;04509;034208;Sachsen
Schopfheim;;79650;07622;Baden-Württemberg
Schopfloch;, Kr Freudenstadt;72296;07443;Baden-Württemberg
Schopfloch;, Mittelfr;91626;09857;Bayern
Schopp;;67707;06307;Rheinland-Pfalz
Schöppenstedt;;38170;05332;Niedersachsen
Schöppingen;;48624;02555;Nordrhein-Westfalen
Schöps;b Jena;07768;036424;Thüringen
Schopsdorf;;39291;039225;Sachsen-Anhalt
Schöpstal;;02829;03581;Sachsen
Schorbus;;03058;035602;Brandenburg
Schoren;, Schwarzw;78112;;Baden-Württemberg
Schörghof;;82362;0881;Bayern
Schorlenberg;;67677;06303;Rheinland-Pfalz
Schorndorf;, Württ;73614;07181;Baden-Württemberg
Schorndorf;, Oberpf;93489;09467;Bayern
Schornsheim;;55288;06732;Rheinland-Pfalz
Schorstedt;;39606;039328;Sachsen-Anhalt
Schortens;;26419;04461;Niedersachsen
Schortens;;26419;04461;Niedersachsen
Schortewitz;;06369;034978;Sachsen-Anhalt
Schossin;;19073;03869;Mecklenburg-Vorpommern
Schotten;, Hess;63679;06044;Hessen
Schottersmühle;;91344;09196;Bayern
Schraden;b Elsterwerda;04928;03574;Brandenburg
Schramberg;;78713;07422;Baden-Württemberg
Schrampe;;39619;039384;Sachsen-Anhalt
Schrapfendorf;;99831;036926;Thüringen
Schraplau;;06279;034774;Sachsen-Anhalt
Schrebitz;b Meißen, Sachs;01665;035244;Sachsen
Schrecksbach;;34637;06698;Hessen
Schrenz;;06780;034956;Sachsen-Anhalt
Schrepkow;;16866;033977;Brandenburg
Schrepperie;, Gem Stoltebüll;24402;;Schlewig-Holstein
Schretstaken;;21493;04156;Schlewig-Holstein
Schreyerhof;;74394;07143;Baden-Württemberg
Schriesheim;;69198;06203;Baden-Württemberg
Schrobenhausen;;86529;08252;Bayern
Schrozberg;;74575;07935;Baden-Württemberg
Schrum;, Dithm;25782;04835;Schlewig-Holstein
Schuby;b Schleswig;24850;04621;Schlewig-Holstein
Schuckmannshöhe;;17328;039746;Mecklenburg-Vorpommern
Schuhegg;;83367;08686;Bayern
Schuhlen-Wiese;;15910;035476;Brandenburg
Schuld;;53520;02695;Rheinland-Pfalz
Schulenberg;b Ribnitz-Damgarten;18334;038221;Mecklenburg-Vorpommern
Schulenberg;im Oberharz;38707;05329;Niedersachsen
Schulenbrook;;23972;03841;Mecklenburg-Vorpommern
Schulendorf;, Lauenb;21516;04155;Schlewig-Holstein
Schülldorf;;24790;04331;Schlewig-Holstein
Schüller;;54586;06597;Rheinland-Pfalz
Schülp;b Nortorf b Neumünster;24589;04392;Schlewig-Holstein
Schülp;b. Rendsburg;24813;04331;Schlewig-Holstein
Schülp;, Dithm;25764;04833;Schlewig-Holstein
Schülperneuensiel;, Gem Strübbel;25764;;Schlewig-Holstein
Schulzendorf;b Eichwalde;15732;033762;Brandenburg
Schulzendorf;b Gransee;16775;033083;Brandenburg
Schummhof;b Crailsheim;74564;;Baden-Württemberg
Schünow;;15806;03377;Brandenburg
Schürdt;;57632;02685;Rheinland-Pfalz
Schürensöhlen;;23847;04539;Schlewig-Holstein
Schurrenhof;;73072;07165;Baden-Württemberg
Schurrenhof;;73072;07165;Baden-Württemberg
Schuttertal;;77978;07826;Baden-Württemberg
Schutterwald;;77746;0781;Baden-Württemberg
Schüttorf;;48465;05923;Niedersachsen
Schutz;;54570;06599;Rheinland-Pfalz
Schütz;b Neukirchen a Teisenberg;83364;;Bayern
Schutzbach;;57520;02741;Rheinland-Pfalz
Schützberg;;06917;035388;Sachsen-Anhalt
Schützenhof;b Wäschenbeuren;73116;07161;Baden-Württemberg
Schwaan;;18258;03844;Mecklenburg-Vorpommern
Schwaara;;07554;0365;Thüringen
Schwabach;;91126;09122;Bayern
Schwabbruck;i Auerbergland;86986;08868;Bayern
Schwabeck;;14913;033747;Brandenburg
Schwabenheim;an der Selz;55270;06130;Rheinland-Pfalz
Schwaberow;;19230;038856;Mecklenburg-Vorpommern
Schwabert;;54673;06550;Rheinland-Pfalz
Schwabhausen;b Dachau;85247;08138;Bayern
Schwabhausen;b Gotha, Thür;99869;036256;Thüringen
Schwäbisch Gmünd;;73525;07171;Baden-Württemberg
Schwäbisch Gmünd;;73527;07171;Baden-Württemberg
Schwäbisch Gmünd;;73529;07171;Baden-Württemberg
Schwäbisch Hall;;74523;0791;Baden-Württemberg
Schwabmünchen;;86830;08232;Bayern
Schwabsoien;;86987;08868;Bayern
Schwabstedt;;25876;04884;Schlewig-Holstein
Schwaförden;;27252;04277;Niedersachsen
Schwaig;b. Nürnberg;90571;09123;Bayern
Schwaigen;, Oberbay;82445;08841;Bayern
Schwaigern;, Württ;74193;07138;Baden-Württemberg
Schwaikheim;;71409;07195;Baden-Württemberg
Schwalbach;am Taunus;65824;06196;Hessen
Schwalbach;, Saar;66773;06834;Saarland
Schwalberhof;b Dieblich;56332;;Rheinland-Pfalz
Schwall;;56281;06747;Rheinland-Pfalz
Schwallungen;;98590;036941;Thüringen
Schwalmstadt;;34613;06691;Hessen
Schwalmtal;, Hess;36318;06638;Hessen
Schwalmtal;, Niederrhein;41366;02163;Nordrhein-Westfalen
Schwammgraben;;83364;;Bayern
Schwanau;;77963;07824;Baden-Württemberg
Schwanbeck;b Neubrandenburg;17099;03969;Mecklenburg-Vorpommern
Schwandorf;, Bay;92421;09431;Bayern
Schwanebeck;b Belzig;14806;033841;Brandenburg
Schwanebeck;b Bernau b Berlin;16341;030;Brandenburg
Schwanebeck;b Oschersleben;39397;039424;Sachsen-Anhalt
Schwaneberg;b Prenzlau;17291;039862;Brandenburg
Schwaneberg;b Magdeburg;39171;039205;Sachsen-Anhalt
Schwanefeld;;39343;039050;Sachsen-Anhalt
Schwanenmühle;;66851;06307;Rheinland-Pfalz
Schwanensee;;19339;;Brandenburg
Schwanewede;;28790;04209;Niedersachsen
Schwanfeld;;97523;09384;Bayern
Schwangau;;87645;08362;Bayern
Schwanheide;;19258;038847;Mecklenburg-Vorpommern
Schwanheim;, Pfalz;76848;06392;Rheinland-Pfalz
Schwanow;;16831;033929;Brandenburg
Schwansee;;99195;036204;Thüringen
Schwanstetten;;90596;09170;Bayern
Schwante;;16727;033055;Brandenburg
Schwarme;;27327;04258;Niedersachsen
Schwarmstedt;;29690;05071;Niedersachsen
Schwartbuck;;24257;04385;Schlewig-Holstein
Schwarz;b Neustrelitz;17252;039827;Mecklenburg-Vorpommern
Schwarz;b Schönebeck, Elbe;39240;039291;Sachsen-Anhalt
Schwarza;b Suhl;98547;036843;Thüringen
Schwarzach;, Odenw;74869;06262;Baden-Württemberg
Schwarzach;b. Nabburg;92548;09435;Bayern
Schwarzach;, Niederbay;94374;09962;Bayern
Schwarzach;a. Main;97359;09324;Bayern
Schwarzbach;b Ruhland;01945;035752;Brandenburg
Schwarzbach;b Gera;07589;036604;Thüringen
Schwarzbach;b Rochlitz;09306;03737;Sachsen
Schwarzbach;, Eifel;54597;06553;Rheinland-Pfalz
Schwarzbach;b Eisfeld;98673;036878;Thüringen
Schwarzburg;;07427;036730;Thüringen
Schwarze Pumpe;;03139;03564;Brandenburg
Schwarzen;, Hunsrück;55481;06763;Rheinland-Pfalz
Schwarzenbach;b Pressath;92720;09644;Bayern
Schwarzenbach a d Saale;;95126;09284;Bayern
Schwarzenbach a Wald;;95131;09289;Bayern
Schwarzenbek;;21493;04151;Schlewig-Holstein
Schwarzenberg;/Erzgeb.;08340;03774;Sachsen
Schwarzenberg;b Neukirchen a Teisenberg;83364;08666;Bayern
Schwarzenborn;, Knüll;34639;05686;Hessen
Schwarzenborn;, Eifel;54533;06567;Rheinland-Pfalz
Schwarzenbruck;;90592;09128;Bayern
Schwarzenfeld;, Oberpf;92521;09435;Bayern
Schwarzengraben;;67813;06361;Rheinland-Pfalz
Schwarzenhof b Groß Gievitz;;17192;039934;Mecklenburg-Vorpommern
Schwarzerden;, Hunsrück;55629;06765;Rheinland-Pfalz
Schwarzhausen;;99891;036259;Thüringen
Schwarzheide;;01987;035752;Brandenburg
Schwarzhofen;;92447;09672;Bayern
Schwarzholz;b Stendal;39596;039394;Sachsen-Anhalt
Schwarzhöring;;94565;08541;Bayern
Schwebheim;, Unterfr;97525;09723;Bayern
Schwechow;;19230;038856;Mecklenburg-Vorpommern
Schwedelbach;;67685;06374;Rheinland-Pfalz
Schwedeneck;;24229;04308;Schlewig-Holstein
Schwedt;;16303;03332;Brandenburg
Schwegenheim;;67365;06344;Rheinland-Pfalz
Schweich;, Mosel;54338;06502;Rheinland-Pfalz
Schweickershausen;;98663;036871;Thüringen
Schweigen-Rechtenbach;;76889;06342;Rheinland-Pfalz
Schweighausen;b Nassau, Lahn;56377;02604;Rheinland-Pfalz
Schweighofen;;76889;06342;Rheinland-Pfalz
Schweiler;;54608;06555;Rheinland-Pfalz
Schweina;b Bad Liebenstein;36448;036961;Thüringen
Schweinbach;b Leutenberg, Thür;07338;036734;Thüringen
Schweindorf;, Harlingerl;26556;04975;Niedersachsen
Schweinfurt;;97421;09721;Bayern
Schweinfurt;;97422;09721;Bayern
Schweinfurt;;97424;09721;Bayern
Schweinfurter Haus;;97647;09774;Bayern
Schweinitz;, Elster;06928;03537;Sachsen-Anhalt
Schweinitz;b Zerbst;39279;039245;Sachsen-Anhalt
Schweinrich;;16909;033966;Brandenburg
Schweinschied;;67744;06753;Rheinland-Pfalz
Schweisweiler;;67808;06302;Rheinland-Pfalz
Schweitenkirchen;;85301;08444;Bayern
Schweix;;66957;06335;Rheinland-Pfalz
Schwellhäusl;;94227;09925;Bayern
Schwelm;;58332;02336;Nordrhein-Westfalen
Schweltholm;, Gem Stoltebüll;24407;;Schlewig-Holstein
Schwemsal;;06774;034243;Sachsen-Anhalt
Schwenda;;06547;034658;Sachsen-Anhalt
Schwendi;, Württ;88477;07353;Baden-Württemberg
Schwenkenhof;;72290;;Baden-Württemberg
Schwenningen;, Baden;72477;07579;Baden-Württemberg
Schwenningen;, Kr Dillingen a d Donau;89443;09084;Bayern
Schwepnitz;;01936;035797;Sachsen
Schweppenhausen;;55444;06724;Rheinland-Pfalz
Schwerbach;;55624;06544;Rheinland-Pfalz
Schwerborn;;99195;036204;Thüringen
Schwerborn;;99195;036204;Thüringen
Schwerin;b Königs Wusterhausen;15755;033766;Brandenburg
Schwerin;b Storkow, Mark;15859;033678;Brandenburg
Schwerin;, Meckl;19053;0385;Mecklenburg-Vorpommern
Schwerin;, Meckl;19055;0385;Mecklenburg-Vorpommern
Schwerin;, Meckl;19057;0385;Mecklenburg-Vorpommern
Schwerin;, Meckl;19059;0385;Mecklenburg-Vorpommern
Schwerin;, Meckl;19061;0385;Mecklenburg-Vorpommern
Schwerin;, Meckl;19063;0385;Mecklenburg-Vorpommern
Schweringen;;27333;04257;Niedersachsen
Schweringen, Ziegelei;;27324;;Niedersachsen
Schwerinsburg;;17398;039722;Mecklenburg-Vorpommern
Schwerinsdorf;;26835;04956;Niedersachsen
Schwerstedt;b Weimar, Thür;99439;036452;Thüringen
Schwerstedt;b Sömmerda;99634;036376;Thüringen
Schwerte;;58239;02304;Nordrhein-Westfalen
Schwerte;;58239;02304;Nordrhein-Westfalen
Schwerz;;06188;034604;Sachsen-Anhalt
Schwerzko;;15898;033652;Brandenburg
Schwesing;;25813;04841;Schlewig-Holstein
Schwetzingen;;68723;06202;Baden-Württemberg
Schwichtenberg;b Neubrandenburg;17099;039607;Mecklenburg-Vorpommern
Schwickershausen;b Meiningen;98631;036944;Thüringen
Schwieberdingen;;71701;07150;Baden-Württemberg
Schwienau;;29593;05822;Niedersachsen
Schwiesau;;39638;039085;Sachsen-Anhalt
Schwifting;;86940;08191;Bayern
Schwindegg;;84419;08082;Bayern
Schwinkendorf;;17139;039953;Mecklenburg-Vorpommern
Schwirzheim;;54597;06558;Rheinland-Pfalz
Schwissel;;23795;04551;Schlewig-Holstein
Schwittersdorf;;06295;034773;Sachsen-Anhalt
Schwittschau;, Gem Treia;24887;04626;Schlewig-Holstein
Schwobfeld;;37318;036082;Thüringen
Schwollen;;55767;06787;Rheinland-Pfalz
Schwörstadt;;79739;07762;Baden-Württemberg
Schwülper;;38179;05303;Niedersachsen
Sebnitz;;01855;035971;Sachsen
Sechzehneichen;;16866;033971;Brandenburg
Seck;;56479;02664;Rheinland-Pfalz
Seckach;;74743;06292;Baden-Württemberg
Seddin;b Perleberg;19348;038789;Brandenburg
Seddiner See;;14554;033205;Brandenburg
Sedlitz;;01968;03573;Brandenburg
Seebach;, Baden;77889;07842;Baden-Württemberg
Seebach;b Eisenach, Thür;99846;036929;Thüringen
Seebad Ahlbeck;;17419;038378;Mecklenburg-Vorpommern
Seebad Bansin;;17429;038378;Mecklenburg-Vorpommern
Seebad Heringsdorf;;17424;0395;Mecklenburg-Vorpommern
Seebeck;b Lindow, Mark;16835;033933;Brandenburg
Seebenau;;29413;03901;Sachsen-Anhalt
Seebergen;b Gotha, Thür;99869;036256;Thüringen
Seebruck;, Chiemsee;83358;08667;Bayern
Seeburg;b Lutherstadt Eisleben;06317;034774;Sachsen-Anhalt
Seeburg;b Potsdam;14476;033201;Brandenburg
Seeburg;, Niedersachs;37136;05507;Niedersachsen
Seedorf;b Bad Segeberg;23823;04555;Schlewig-Holstein
Seedorf;, Kr Hzgt Lauenb;23883;04545;Schlewig-Holstein
Seedorf;b Zeven;27404;04284;Niedersachsen
Seefeld;b Werneuchen;16356;03337;Brandenburg
Seefeld;b Pritzwalk;16928;03395;Brandenburg
Seefeld;b Grevesmühlen;23936;038871;Mecklenburg-Vorpommern
Seefeld;b Hanerau-Hademarschen;25557;04872;Schlewig-Holstein
Seefeld;, Oberbay;82229;08152;Bayern
Seeg;;87637;08364;Bayern
Seega;;06567;034671;Thüringen
Seegrehna;;06888;034928;Sachsen-Anhalt
Seehaus;i Fichtelgeb;95686;09272;Bayern
Seehausen;b Artern;06567;034671;Thüringen
Seehausen;b Jüterbog;14913;033743;Brandenburg
Seehausen;b Prenzlau;17291;039863;Brandenburg
Seehausen;, Börde;39365;039407;Sachsen-Anhalt
Seehausen;(Altmark);39615;039386;Sachsen-Anhalt
Seehausen;a. Staffelsee;82418;08841;Bayern
Seeheim-Jugenheim;;64342;06257;Hessen
Seehof;b Schwerin, Meckl;19069;03867;Mecklenburg-Vorpommern
Seekirch;;88422;07582;Baden-Württemberg
Seelbach;b Nassau, Lahn;56377;02604;Rheinland-Pfalz
Seelbach;b Hamm, Sieg;57577;0261;Rheinland-Pfalz
Seelbach;(Westerwald);57632;02685;Rheinland-Pfalz
Seelbach;, Schutter;77960;07823;Baden-Württemberg
Seeleiten;b Evenhausen, Oberbay;83552;08075;Bayern
Seelen;;67744;06363;Rheinland-Pfalz
Seelensdorf;;14798;033834;Brandenburg
Seelingstädt;b Gera;07580;036608;Thüringen
Seelitz;b Rochlitz;09306;03737;Sachsen
Seelow;;15306;03346;Brandenburg
Seelze;;30926;05137;Niedersachsen
Seeon;, Chiemgau;83370;08667;Bayern
Seepoint;;84036;0871;Bayern
Seerhausen;;01594;035268;Sachsen
Seesbach;;55629;06754;Rheinland-Pfalz
Seesen;, Harz;38723;05381;Niedersachsen
Seeshaupt;;82402;08801;Bayern
Seester;;25370;04125;Schlewig-Holstein
Seestermühe;;25371;04125;Schlewig-Holstein
Seeth;b Husum, Nordsee;25878;04881;Schlewig-Holstein
Seeth-Ekholt;;25337;04121;Schlewig-Holstein
Seethen;;39638;039084;Sachsen-Anhalt
Seetz;;19357;038781;Brandenburg
Seevetal;;21217;04105;Niedersachsen
Seevetal;;21218;04105;Niedersachsen
Seevetal;;21220;04105;Niedersachsen
Seewald;;72297;07448;Baden-Württemberg
Seewiesen;;82319;08151;Bayern
Seffern;;54636;06569;Rheinland-Pfalz
Sefferweich;;54636;06569;Rheinland-Pfalz
Segeletz;;16845;033978;Brandenburg
Segeletz Bahnhof;;16845;033978;Brandenburg
Seggebruch;;31691;05722;Niedersachsen
Seggerde;;39356;039061;Sachsen-Anhalt
Segnitz;;97340;09332;Bayern
Sehestedt;, Eider;24814;04357;Schlewig-Holstein
Sehlde;b Salzgitter;38279;05341;Niedersachsen
Sehlem;, Niedersachs;31196;05060;Niedersachsen
Sehlem;b Wittlich;54518;06508;Rheinland-Pfalz
Sehlen;, Rügen;18528;03838;Mecklenburg-Vorpommern
Sehmatal-Cranzahl;;09465;037342;Sachsen
Sehmatal-Neudorf;;09465;037342;Sachsen
Sehmatal-Sehma;;09465;03733;Sachsen
Sehnde;;31319;05138;Niedersachsen
Seibersbach;;55444;06724;Rheinland-Pfalz
Seibis;;07366;036642;Thüringen
Seidewitz;b Grimma;04668;034321;Sachsen
Seidingstadt;;98646;036875;Thüringen
Seifartsdorf;;07613;036691;Thüringen
Seifen;b Flammersfeld;57632;02685;Rheinland-Pfalz
Seifhennersdorf;;02782;03586;Sachsen
Seilershof;;16775;033085;Brandenburg
Seinsfeld;;54655;06567;Rheinland-Pfalz
Seinsheim;;97342;09332;Bayern
Seisla;;07389;03647;Thüringen
Seitenroda;;07768;036424;Thüringen
Seitershof;;66917;06375;Rheinland-Pfalz
Seitingen-Oberflacht;;78606;07464;Baden-Württemberg
Seiwerath;;54597;06553;Rheinland-Pfalz
Selb;;95100;09287;Bayern
Selbach;(Sieg);57537;02742;Rheinland-Pfalz
Selbelang;;14641;033237;Brandenburg
Selben;;04509;034202;Sachsen
Selberting;;83379;0861;Bayern
Selbitz;b Bitterfeld;06773;034928;Sachsen-Anhalt
Selbitz;, Oberfr;95152;09280;Bayern
Selchenbach;;66871;06384;Rheinland-Pfalz
Selchow;b Königs Wusterhausen;15831;03379;Brandenburg
Selchow;b Storkow, Mark;15859;033678;Brandenburg
Selent;, Holst;24238;04384;Schlewig-Holstein
Selfkant;;52538;02456;Nordrhein-Westfalen
Seligenstadt;, Hess;63500;06182;Hessen
Seligweiler;;89081;0731;Bayern
Selk;;24884;04621;Schlewig-Holstein
Sellendorf;;15938;035452;Brandenburg
Sellerich;;54608;06551;Rheinland-Pfalz
Sellessen;;03130;03563;Brandenburg
Sellin;, Ostseebad;18586;038303;Mecklenburg-Vorpommern
Sellin;b Wismar, Meckl;23992;;Mecklenburg-Vorpommern
Selm;;59379;02592;Nordrhein-Westfalen
Selmsdorf;;23923;038823;Mecklenburg-Vorpommern
Selow;;18246;038461;Mecklenburg-Vorpommern
Selpin;;18195;038205;Mecklenburg-Vorpommern
Selsingen;;27446;04284;Niedersachsen
Seltendorf;;96528;036766;Thüringen
Selters;(Westerwald);56242;02626;Rheinland-Pfalz
Selters;(Taunus);65618;06483;Hessen
Selzen;;55278;06737;Rheinland-Pfalz
Sembach;;67681;06303;Rheinland-Pfalz
Semlow;;18334;038222;Mecklenburg-Vorpommern
Semmenstedt;;38327;05336;Niedersachsen
Semmerin;;19300;038755;Mecklenburg-Vorpommern
Senden;, Westf;48308;02597;Nordrhein-Westfalen
Senden;, Iller;89250;07307;Bayern
Sendenhorst;;48324;02526;Nordrhein-Westfalen
Senftenberg;, NL;01968;03573;Brandenburg
Senftenhütte;;16230;033364;Brandenburg
Sengenthal;;92369;09181;Bayern
Sengerich;;54619;06559;Rheinland-Pfalz
Senheim;;56820;02673;Rheinland-Pfalz
Sennewitz;;06193;0345;Sachsen-Anhalt
Sennfeld;, Unterfr;97526;09721;Bayern
Sennhütte;, Burghaun;36166;;Hessen
Sensbachtal;;64759;06068;Hessen
Senscheid;;53520;02696;Rheinland-Pfalz
Senst;;06869;034929;Sachsen-Anhalt
Sensweiler;;55758;06786;Rheinland-Pfalz
Senzig;;15754;03375;Brandenburg
Senzke;;14662;033238;Brandenburg
Serba;;07616;036601;Thüringen
Sereetz;;23611;0451;Schlewig-Holstein
Sereetzerfeld;;23611;;Schlewig-Holstein
Sergen;;03058;035605;Brandenburg
Serno;;06862;034907;Sachsen-Anhalt
Sernow;;14913;033746;Brandenburg
Serrig;;54455;06581;Rheinland-Pfalz
Sersheim;;74372;07042;Baden-Württemberg
Serwest;;16230;033364;Brandenburg
Sessenbach;;56237;02601;Rheinland-Pfalz
Sessenhausen;, Westerw;56244;02626;Rheinland-Pfalz
Seßlach;;96145;09569;Bayern
Seth;, Holst;23845;04194;Schlewig-Holstein
Settin;;19089;03863;Mecklenburg-Vorpommern
Setzin;;19230;038856;Mecklenburg-Vorpommern
Setzingen;;89129;07345;Baden-Württemberg
Setzsteig;;14827;033849;Brandenburg
Seubersdorf;, Oberpf;92358;09497;Bayern
Seukendorf;;90556;0911;Bayern
Seulingen;;37136;05507;Niedersachsen
Seupahn;;09306;03737;Sachsen
Sevenig;(Our);54617;06559;Rheinland-Pfalz
Sevenig;bei Neuerburg;54673;06524;Rheinland-Pfalz
Severin;;19374;038728;Mecklenburg-Vorpommern
Sewekow;;16909;033966;Brandenburg
Sexau;;79350;07641;Baden-Württemberg
Seybothenreuth;;95517;09275;Bayern
Seyda;;06918;035387;Sachsen-Anhalt
Sibbesse;;31079;05065;Niedersachsen
Sichau;;39649;039082;Sachsen-Anhalt
Sickerode;;37308;036082;Thüringen
Sickingerhof;;67699;06301;Rheinland-Pfalz
Sickte;;38173;05305;Niedersachsen
Siebeldingen;;76833;06345;Rheinland-Pfalz
Siebenbach;;56729;02656;Rheinland-Pfalz
Siebenbäumen;;23847;04501;Schlewig-Holstein
Siebeneichen;, Kr Hzgt Lauenb;21514;04158;Schlewig-Holstein
Siebenlehn;;09634;0351;Sachsen
Siebigerode;;06308;034772;Sachsen-Anhalt
Siedenbollentin;;17089;03969;Mecklenburg-Vorpommern
Siedenbrünzow;;17111;03998;Mecklenburg-Vorpommern
Siedenburg;;27254;04272;Niedersachsen
Siedenheide;;17252;039832;Mecklenburg-Vorpommern
Siedenlangenbeck;;29416;039035;Sachsen-Anhalt
Siedlung Bergheide;;14552;;Brandenburg
Siefersheim;;55599;06703;Rheinland-Pfalz
Siegadel;;15913;035471;Brandenburg
Siegbach;;35768;02778;Hessen
Siegburg;;53721;02241;Nordrhein-Westfalen
Siegelhausen;;71563;07144;Baden-Württemberg
Siegelsbach;, Kraichgau;74936;07264;Baden-Württemberg
Siegen;;57072;0271;Nordrhein-Westfalen
Siegen;;57074;0271;Nordrhein-Westfalen
Siegen;;57076;0271;Nordrhein-Westfalen
Siegen;;57078;0271;Nordrhein-Westfalen
Siegen;;57080;0271;Nordrhein-Westfalen
Siegenburg;;93354;09444;Bayern
Siegmundsburg;;98749;036704;Thüringen
Siegsdorf;, Oberbay;83313;08662;Bayern
Siek;, Kr Stormarn;22962;04107;Schlewig-Holstein
Sielbek;b Bad Schwartau;23611;;Schlewig-Holstein
Sielenbach;;86577;08258;Bayern
Siemerode;;37308;03606;Thüringen
Sien;;55758;06788;Rheinland-Pfalz
Sienhachenbach;;55758;06788;Rheinland-Pfalz
Sierksdorf;;23730;04563;Schlewig-Holstein
Sierksrade;;23847;04501;Schlewig-Holstein
Sierscheid;;53520;02695;Rheinland-Pfalz
Siershahn;;56427;02623;Rheinland-Pfalz
Siersleben;;06308;03476;Sachsen-Anhalt
Siesbach;;55767;06781;Rheinland-Pfalz
Siestedt;;39356;039061;Sachsen-Anhalt
Siethen;;14974;03378;Brandenburg
Sietow;;17209;039931;Mecklenburg-Vorpommern
Sietzing;;15324;033474;Brandenburg
Sietzsch;;06188;034602;Sachsen-Anhalt
Sieversdorf;b Frankfurt, Oder;15236;033608;Brandenburg
Sieversdorf;b Neustadt, Dosse;16845;033970;Brandenburg
Sievershagen;b Grevesmühlen;23936;038822;Mecklenburg-Vorpommern
Sievershütten;;24641;04194;Schlewig-Holstein
Sieverstedt;;24885;04603;Schlewig-Holstein
Siewisch;;03116;035602;Brandenburg
Siezbüttel;;25560;04892;Schlewig-Holstein
Siggelkow;;19376;038724;Mecklenburg-Vorpommern
Sigmaringen;;72488;07571;Baden-Württemberg
Sigmaringendorf;;72517;07571;Baden-Württemberg
Sigmarszell;;88138;08389;Bayern
Sigrön;;19336;038791;Brandenburg
Silberfeld;;07937;036628;Thüringen
Silberhälden;;71732;;Baden-Württemberg
Silberhaus;;95615;09236;Bayern
Silberhausen;;37351;036075;Thüringen
Silberstedt;;24887;04625;Schlewig-Holstein
Silberstraße;;08121;037603;Sachsen
Silbertal;, Forsthaus;67157;;Rheinland-Pfalz
Silbitz;;07613;036693;Thüringen
Silkerode;;37345;036072;Thüringen
Silz;, Meckl;17214;039927;Mecklenburg-Vorpommern
Silz;, Pfalz;76857;06346;Rheinland-Pfalz
Silzen;;25551;04871;Schlewig-Holstein
Simbach;a. Inn;84359;08571;Bayern
Simbach;, Niederbay;94436;09954;Bayern
Simmelsdorf;;91245;09155;Bayern
Simmerath;;52152;02473;Nordrhein-Westfalen
Simmern;, Hunsrück;55469;06761;Rheinland-Pfalz
Simmern;, Westerw;56337;02620;Rheinland-Pfalz
Simmersdorf;;03149;035695;Brandenburg
Simmersfeld;;72226;07484;Baden-Württemberg
Simmershofen;;97215;09848;Bayern
Simmertal;;55618;06754;Rheinland-Pfalz
Simmozheim;;75397;07033;Baden-Württemberg
Simonsberg;b Husum, Nordsee;25813;04841;Schlewig-Holstein
Simonshagen;;19348;033983;Brandenburg
Simonshof;b Perleberg;19357;038785;Brandenburg
Simonshof;b Rockenhausen;67806;06361;Rheinland-Pfalz
Simonswald;;79263;07683;Baden-Württemberg
Sindelfingen;;71063;07031;Baden-Württemberg
Sindelfingen;;71065;07031;Baden-Württemberg
Sindelfingen;;71067;07031;Baden-Württemberg
Sindelfingen;;71069;07031;Baden-Württemberg
Sindelsdorf;;82404;08856;Bayern
Singen;(Hohentwiel);78224;07731;Baden-Württemberg
Singen;b Arnstadt;99326;03629;Thüringen
Singhofen;;56379;02604;Rheinland-Pfalz
Sinn;, Hess;35764;02772;Hessen
Sinntal;;36391;06665;Hessen
Sinsheim;, Elsenz;74889;07261;Baden-Württemberg
Sinspelt;;54675;06522;Rheinland-Pfalz
Sinzendorf;;93449;09975;Bayern
Sinzheim;b Baden-Baden;76547;07221;Baden-Württemberg
Sinzig;, Rhein;53489;02642;Rheinland-Pfalz
Sinzing;, Oberpf;93161;0941;Bayern
Sippersfeld;;67729;06357;Rheinland-Pfalz
Sipplingen;;78354;07551;Baden-Württemberg
Siptenfelde;;06507;039488;Sachsen-Anhalt
Sirksfelde;;23898;04543;Schlewig-Holstein
Sittensen;;27419;04282;Niedersachsen
Sitters;;67823;06362;Rheinland-Pfalz
Sitzendorf;;07429;036730;Thüringen
Sixenhof;b Mögglingen;73563;07174;Baden-Württemberg
Skäßchen;;01561;03522;Sachsen
Skaup;;01561;03522;Sachsen
Skoplau;;04680;034381;Sachsen
Söchtenau;;83139;08053;Bayern
Soderstorf;;21388;04132;Niedersachsen
Soest;, Westf;59494;02921;Nordrhein-Westfalen
Sögel;;49751;05952;Niedersachsen
Sohl;, Westerw;56588;;Rheinland-Pfalz
Sohland;a d Spree;02689;035936;Sachsen
Sohland;a. Rotstein;02894;035828;Sachsen
Söhlde;;31185;05129;Niedersachsen
Sohnstedt;;99198;036203;Thüringen
Sohren;, Hunsrück;55487;06543;Rheinland-Pfalz
Söhren;b Ratzeburg;23909;;Schlewig-Holstein
Söhrewald;;34320;05608;Hessen
Sohrschied;;55487;06763;Rheinland-Pfalz
Sölden;, Breisgau;79294;0761;Baden-Württemberg
Söldenham;;94565;08541;Bayern
Solikante;;15324;033475;Brandenburg
Solingen;;42651;0212;Nordrhein-Westfalen
Solingen;;42653;0212;Nordrhein-Westfalen
Solingen;;42655;0212;Nordrhein-Westfalen
Solingen;;42657;0212;Nordrhein-Westfalen
Solingen;;42659;0212;Nordrhein-Westfalen
Solingen;;42697;0212;Nordrhein-Westfalen
Solingen;;42699;0212;Nordrhein-Westfalen
Solingen;;42719;0212;Nordrhein-Westfalen
Solkwitz;;07381;03647;Thüringen
Söllenthin;;19339;038787;Brandenburg
Sollerup;;24852;04625;Schlewig-Holstein
Söllichau;;06774;034243;Sachsen-Anhalt
Söllingen;, Niedersachs;38387;05354;Niedersachsen
Sollstedt;, Wipper;99759;036338;Thüringen
Sollstedt;b Mühlhausen, Thür;99976;036338;Thüringen
Sollwitt;;25884;04843;Schlewig-Holstein
Solms;, Lahn;35606;06442;Hessen
Solnhofen;;91807;09145;Bayern
Solpke;;39638;039087;Sachsen-Anhalt
Solsdorf;;07422;036739;Thüringen
Soltau;;29614;05191;Niedersachsen
Soltendieck;;29594;05874;Niedersachsen
Soltow;;19273;038844;Mecklenburg-Vorpommern
Sommerach;;97334;09381;Bayern
Sommerau;, Ruwer;54317;06588;Rheinland-Pfalz
Sömmerda;;99610;03634;Thüringen
Sommerfeld;b Velten;16766;033055;Brandenburg
Sommerhausen;, Main;97286;09333;Bayern
Sommerkahl;;63825;06024;Bayern
Sommerland;;25358;04126;Schlewig-Holstein
Sommerloch;;55595;06706;Rheinland-Pfalz
Sommersdorf;b Demmin;17111;039952;Mecklenburg-Vorpommern
Sommersdorf;b Pasewalk;17328;039751;Mecklenburg-Vorpommern
Sommersdorf;, Börde;39365;039402;Sachsen-Anhalt
Sonderhof;b Eggenthal;87782;08306;Bayern
Sonderhofen;;97255;09337;Bayern
Sondershausen;, Thür;99706;03632;Thüringen
Sondheim;v d Rhön;97647;09779;Bayern
Sonneberg;, Thür;96515;03675;Thüringen
Sonneborn;b Gotha, Thür;99869;036255;Thüringen
Sönnebüll;;25821;04671;Schlewig-Holstein
Sonnefeld;;96242;09562;Bayern
Sonnen;, Niederbay;94164;08584;Bayern
Sonnenberg;b Gransee;16775;03306;Brandenburg
Sonnenberg-Winnenberg;;55767;06787;Rheinland-Pfalz
Sonnenbühl;;72820;07128;Baden-Württemberg
Sonnenhof;b Stuttgart;70378;;Baden-Württemberg
Sonnenreuth;b Fischbachau;83739;08025;Bayern
Sonnewalde;;03249;035323;Brandenburg
Sonnschied;;55758;06785;Rheinland-Pfalz
Sonsbeck;;47665;02838;Nordrhein-Westfalen
Sontheim;b Memmingen;87776;08336;Bayern
Sontheim;an der Brenz;89567;07325;Baden-Württemberg
Sonthofen;, Oberallgäu;87527;08321;Bayern
Sontra;;36205;05653;Hessen
Sophienhamm;;24806;0431;Schlewig-Holstein
Sophienholz;;18239;;Mecklenburg-Vorpommern
Sophienstädt;;16348;03337;Brandenburg
Sophienthal;, Oderbruch;15324;033473;Brandenburg
Sophienthal;b Hildburghausen;98646;03685;Thüringen
Sören;;24241;04322;Schlewig-Holstein
Sorgbrück;, Gem Tetenhusen;24806;;Schlewig-Holstein
Sorge;, Harz;38875;039457;Sachsen-Anhalt
Sörgenloch;;55270;06136;Rheinland-Pfalz
Sornzig-Ablaß;;04769;034362;Sachsen
Sörth;;57636;02681;Rheinland-Pfalz
Sörup;;24966;04635;Schlewig-Holstein
Sosa;;08326;037752;Sachsen
Sosberg;;56858;06545;Rheinland-Pfalz
Sössen;;06686;034444;Sachsen-Anhalt
Sotterhausen;;06528;03464;Sachsen-Anhalt
Sottrum;, Kr Rotenburg, Wümme;27367;04264;Niedersachsen
Soyen;;83564;08071;Bayern
Spaatz;;14715;033872;Brandenburg
Spabrücken;;55595;06706;Rheinland-Pfalz
Spahl;;36419;036967;Thüringen
Spahnharrenstätte;;49751;05951;Niedersachsen
Spaichingen;;78549;07424;Baden-Württemberg
Spall;;55595;06706;Rheinland-Pfalz
Spalt;;91174;09175;Bayern
Spaltenhof;;25813;04841;Schlewig-Holstein
Spangdahlem;;54529;06565;Rheinland-Pfalz
Spangenberg;, Hess;34286;05663;Hessen
Spantekow;;17392;039727;Mecklenburg-Vorpommern
Spardorf;;91080;09131;Bayern
Sparneck;;95234;09251;Bayern
Spatzenhausen;;82447;08847;Bayern
Spay;, Rhein;56322;02628;Rheinland-Pfalz
Spechbach;;74937;06226;Baden-Württemberg
Spechtsbrunn;;98743;036762;Thüringen
Speicher;, Eifel;54662;06562;Rheinland-Pfalz
Speichersdorf;;95469;09275;Bayern
Speichrow;;15848;033676;Brandenburg
Speinshart;;92676;09645;Bayern
Spelle;;48480;05977;Niedersachsen
Spenge;;32139;05225;Nordrhein-Westfalen
Sperenberg;;15838;033703;Brandenburg
Spergau;;06237;034446;Sachsen-Anhalt
Sperrhof;;73489;07967;Baden-Württemberg
Spesenroth;;56288;06762;Rheinland-Pfalz
Spessart;, Eifel;56746;02655;Rheinland-Pfalz
Speyer;;67346;06232;Rheinland-Pfalz
Spickendorf;;06188;034602;Sachsen-Anhalt
Spiegelau;;94518;08553;Bayern
Spiegelberg;, Württ;71579;07194;Baden-Württemberg
Spiegelhagen;;19348;03876;Brandenburg
Spiekeroog;;26474;04976;Niedersachsen
Spielberg;b Naumburg, Saale;06628;034463;Sachsen-Anhalt
Spielberg;b Großhelfendorf;85655;08093;Bayern
Spielmannsholz;;54619;06559;Rheinland-Pfalz
Spiesen-Elversberg;;66583;06821;Saarland
Spiesheim;;55288;06732;Rheinland-Pfalz
Spirkelbach;;76848;06392;Rheinland-Pfalz
Spitalhaus;;93149;;Bayern
Spittenreut;;83364;08666;Bayern
Spittersberg;;83552;08075;Bayern
Spitzau, Gem Attenhofen;;84076;08782;Bayern
Spitzkunnersdorf;;02794;035842;Sachsen
Splietsdorf;;18513;038325;Mecklenburg-Vorpommern
Sponheim;;55595;06758;Rheinland-Pfalz
Sponholz;;17039;0395;Mecklenburg-Vorpommern
Spora;;06724;0365;Sachsen-Anhalt
Spören;;06780;034956;Sachsen-Anhalt
Spornitz;;19372;038726;Mecklenburg-Vorpommern
Spraitbach;;73565;07176;Baden-Württemberg
Sprakebüll;;25917;04662;Schlewig-Holstein
Sprakensehl;;29365;05837;Niedersachsen
Spree;;02923;035894;Sachsen
Spreeau;;15528;033633;Brandenburg
Spreenhagen;;15528;033633;Brandenburg
Spreetal;;02979;03564;Sachsen
Spreewitz;;02979;03563;Sachsen
Spreiterhof;;67806;06363;Rheinland-Pfalz
Spremberg;, NL;03130;03563;Brandenburg
Sprendlingen;, Rheinhess;55576;06701;Rheinland-Pfalz
Spring;;14827;033849;Brandenburg
Springe;, Deister;31832;05041;Niedersachsen
Springhoe;, Gem Oeschebüttel;25551;04822;Schlewig-Holstein
Springstille;;98587;036847;Thüringen
Sprink;;54558;06573;Rheinland-Pfalz
Spritzenmühle;;63928;09378;Baden-Württemberg
Sprockhövel;;45549;02339;Nordrhein-Westfalen
Sprötau;;99610;036371;Thüringen
Sprotta;;04838;03423;Sachsen
Sprung;;83364;08666;Bayern
Sputendorf;;14532;033701;Brandenburg
St Alban;, Pfalz;67813;06362;Rheinland-Pfalz
St Gangloff;;07629;036606;Thüringen
St Georgen;, Chiemgau;83368;08669;Bayern
St Johann;, Rheinhess;55578;06701;Rheinland-Pfalz
St Katharinen;b Linz a Rhein;53562;02645;Rheinland-Pfalz
St Leonhard;am Wonneberg;83329;08681;Bayern
St Martin;, Pfalz;67487;06323;Rheinland-Pfalz
St Oswald;, Niederbay;94568;08553;Bayern
St Ottilien;, Oberbay;86941;08193;Bayern
St. Andreasberg;;37444;05582;Niedersachsen
St. Annen;;25776;04882;Schlewig-Holstein
St. Bernhard;;98660;036873;Thüringen
St. Blasien;;79837;07672;Baden-Württemberg
St. Egidien;;09356;03723;Sachsen
St. Georgen;im Schwarzwald;78112;07724;Baden-Württemberg
St. Goar;;56329;06741;Rheinland-Pfalz
St. Goarshausen;;56346;06771;Rheinland-Pfalz
St. Ingbert;;66386;06894;Saarland
St. Johann;, Württ;72813;07122;Baden-Württemberg
St. Kilian;;98553;036841;Thüringen
St. Leon-Rot;;68789;06227;Baden-Württemberg
St. Märgen;;79274;07669;Baden-Württemberg
St. Michaelisdonn;;25693;04853;Schlewig-Holstein
St. Peter;, Schwarzw;79271;07660;Baden-Württemberg
St. Wendel;, Saar;66606;06851;Saarland
Staakow;, NL;15910;035477;Brandenburg
Staats;;39599;039325;Sachsen-Anhalt
Stäbelow;;18198;038207;Mecklenburg-Vorpommern
Staben;b Ribnitz-Damgarten;18317;;Mecklenburg-Vorpommern
Stabhammer;;08309;;Sachsen
Stackelitz;;06862;034907;Sachsen-Anhalt
Stade;, Niederelbe;21680;04141;Niedersachsen
Stade;, Niederelbe;21682;04141;Niedersachsen
Stade;, Niederelbe;21683;04141;Niedersachsen
Stade;, Niederelbe;21684;04141;Niedersachsen
Stadecken-Elsheim;;55271;06136;Rheinland-Pfalz
Stadelhofen;, Oberfr;96187;09504;Bayern
Stadensen;;29596;05802;Niedersachsen
Stadl;b Neukirchen a Teisenberg;83364;08666;Bayern
Stadland;;26935;04734;Niedersachsen
Stadland;;26936;04734;Niedersachsen
Stadland;;26937;04734;Niedersachsen
Stadlern;, Oberpf;92549;09674;Bayern
Stadt Wehlen;;01829;035024;Sachsen
Stadtallendorf;;35260;06428;Hessen
Stadtbek, Gem Bosau;;24326;04527;Schlewig-Holstein
Stadtbek, Gem Seedorf;;24326;04527;Schlewig-Holstein
Stadtbekermühle;;24326;04527;Schlewig-Holstein
Stadtbergen;;86391;0821;Bayern
Stadthagen;;31655;05721;Niedersachsen
Stadtilm;;99326;03629;Thüringen
Stadtkyll;;54589;06597;Rheinland-Pfalz
Stadtlauringen;;97488;09724;Bayern
Stadtlengsfeld;;36457;036965;Thüringen
Stadtlohn;;48703;02563;Nordrhein-Westfalen
Stadtoldendorf;;37627;05532;Niedersachsen
Stadtprozelten;;97909;09392;Bayern
Stadtroda;;07646;036428;Thüringen
Stadtsteinach;;95346;09225;Bayern
Stadum;;25917;04662;Schlewig-Holstein
Staffelde;b Kremmen;16766;033055;Brandenburg
Staffelde;b Stendal;39596;03931;Sachsen-Anhalt
Staffhorst;;27254;04272;Niedersachsen
Stafstedt;b Rendsburg;24816;04875;Schlewig-Holstein
Stahlberg;;67808;06361;Rheinland-Pfalz
Stahlhofen;, Westerw;56412;02602;Rheinland-Pfalz
Stahlhofen;am Wiesensee;56459;02663;Rheinland-Pfalz
Stahlhütte;;53533;;Rheinland-Pfalz
Stahna;;01623;035246;Sachsen
Stahnsdorf;;14532;03329;Brandenburg
Staig;, Weihung;89195;07346;Baden-Württemberg
Staitz;;07950;036622;Thüringen
Stakendorf;;24217;04344;Schlewig-Holstein
Stalförden;, Gem Molbergen;49696;04475;Niedersachsen
Stallwang;b Straubing;94375;09964;Bayern
Stammbach;;95236;09256;Bayern
Stammham;, Inn;84533;08678;Bayern
Stammham;b Ingolstadt, Donau;85134;08405;Bayern
Stampfl;b Au a Inn;83546;08073;Bayern
Stamsried;;93491;09466;Bayern
Stanau;;07806;036481;Thüringen
Ständehof;;66981;;Rheinland-Pfalz
Standenbühl;;67816;06357;Rheinland-Pfalz
Standorf;b Weikersheim;97990;07933;Baden-Württemberg
Stangenhagen;;14959;033731;Brandenburg
Stangerode;;06543;034742;Sachsen-Anhalt
Stanggaß;b Berchtesgaden;83471;08652;Bayern
Stangheck;;24395;04643;Schlewig-Holstein
Stannewisch;;02923;035894;Sachsen
Stapel;b Neuhaus, Elbe;19273;038841;Niedersachsen
Stapelburg;;38871;039452;Sachsen-Anhalt
Stapelfeld;, Bz Hamburg;22145;040;Schlewig-Holstein
Stappenbeck;;29416;039032;Sachsen-Anhalt
Starbach;;01683;035242;Sachsen
Starkenberg;;04617;034495;Thüringen
Starkenburg;;56843;06541;Rheinland-Pfalz
Starnberg;;82319;08151;Bayern
Starsiedel;;06686;034444;Sachsen-Anhalt
Starzach;;72181;07483;Baden-Württemberg
Starzberg;;83739;08064;Bayern
Staßfurt;;39418;03925;Sachsen-Anhalt
Staucha;;01594;035268;Sachsen
Stauchitz;;01594;035268;Sachsen
Staudach-Egerndach;;83224;08641;Bayern
Staudernheim;;55568;06751;Rheinland-Pfalz
Staudt;;56424;02602;Rheinland-Pfalz
Staufen;im Breisgau;79219;07633;Baden-Württemberg
Staufenberg;, Niedersachs;34355;05543;Niedersachsen
Staufenberg;, Hess;35460;06406;Hessen
Stausteinerhof;;66482;06335;Rheinland-Pfalz
Staven;;17039;039608;Mecklenburg-Vorpommern
Stavenhagen;, Reuterstadt;17153;039954;Mecklenburg-Vorpommern
Stavenow;;19357;038797;Brandenburg
Stavern;, Emsl;49777;05965;Niedersachsen
Stebach;;56276;02689;Rheinland-Pfalz
Stechau;;04936;035361;Brandenburg
Stecherschleuse;;16248;033362;Brandenburg
Stechow;b Rathenow;14715;033874;Brandenburg
Steckenbergermühle;;56379;06439;Rheinland-Pfalz
Stecklenberg;;06507;03947;Sachsen-Anhalt
Stedesand;;25920;04662;Schlewig-Holstein
Stedesdorf;;26427;04974;Niedersachsen
Stedten;b Lutherstadt Eisleben;06317;034774;Sachsen-Anhalt
Stedten a Ettersberg;;99439;036452;Thüringen
Stedtlingen;;98617;036943;Thüringen
Steegen;b Hagenow;19230;03883;Mecklenburg-Vorpommern
Steenfeld;;25557;04872;Schlewig-Holstein
Steesow;;19300;038781;Mecklenburg-Vorpommern
Stefansberg;b Maisach, Oberbay;82216;08141;Bayern
Steffeln;;54597;06593;Rheinland-Pfalz
Steffenberg;;35239;06464;Hessen
Steffenshagen;b Pritzwalk;16928;03395;Brandenburg
Steffenshagen;b Bad Doberan;18209;038203;Mecklenburg-Vorpommern
Steffenshof;;56288;;Rheinland-Pfalz
Steffin;;23966;03841;Mecklenburg-Vorpommern
Stegaurach;;96135;0951;Bayern
Stegelitz;b Templin;17268;039887;Brandenburg
Stegelitz;b Burg b Magdeburg;39291;039221;Sachsen-Anhalt
Stegen;, Hochschwarzw;79252;07661;Baden-Württemberg
Steigerthal;;99762;03631;Thüringen
Steigra;;06268;034461;Sachsen-Anhalt
Steimbke;;31634;05026;Niedersachsen
Steimel;;57614;02684;Rheinland-Pfalz
Steimke;, Altmark;38486;039008;Sachsen-Anhalt
Stein;b Laboe;24235;04343;Schlewig-Holstein
Stein;a d Traun;83371;08669;Bayern
Stein;, Mittelfr;90547;0911;Bayern
Steina;b Pulsnitz;01920;035955;Sachsen
Steinach;, Baden;77790;07832;Baden-Württemberg
Steinach;b Straubing;94377;09428;Bayern
Steinach;, Thür;96523;036762;Thüringen
Steinächle;;71364;07195;Baden-Württemberg
Steinalben;;66851;06333;Rheinland-Pfalz
Steinau;, Niederelbe;21775;04756;Niedersachsen
Steinau;an der Straße;36396;06663;Hessen
Steinau;, Gem Gars a Inn;83546;08073;Bayern
Steinbach;b Annaberg-Buchholz;09477;037364;Sachsen
Steinbach;b Freiberg, Sachs;09629;037364;Sachsen
Steinbach;b Bad Salzungen;36448;036961;Thüringen
Steinbach;b Heilbad Heiligenstadt;37308;036085;Thüringen
Steinbach;, Hunsrück;56291;06766;Rheinland-Pfalz
Steinbach;(Taunus);61449;06171;Hessen
Steinbach;am Glan;66909;06383;Rheinland-Pfalz
Steinbach;am Donnersberg;67808;06357;Rheinland-Pfalz
Steinbach;a. Wald;96361;09263;Bayern
Steinbach;b Hildburghausen;98667;036874;Thüringen
Steinbach b Moritzburg;;01471;035243;Sachsen
Steinbach-Hallenberg;, Thüringer W;98587;036847;Thüringen
Steinbeck;b Bad Freienwalde;16259;033454;Brandenburg
Steinbeck;b Parchim;19372;038726;Mecklenburg-Vorpommern
Steinbeck;b Grevesmühlen;23948;038825;Mecklenburg-Vorpommern
Steinberg;, Vogtl;08237;037462;Sachsen
Steinberg;b Brandenburg an der Havel;14793;033830;Brandenburg
Steinberg;b Perleberg;19348;038782;Brandenburg
Steinberg;b Flensburg;24972;04632;Schlewig-Holstein
Steinberg;, Oberpf;92449;09431;Bayern
Steinbergkirche;;24972;04632;Schlewig-Holstein
Stein-Bockenheim;;55599;06703;Rheinland-Pfalz
Steinborn;, Eifel;54655;06567;Rheinland-Pfalz
Steinbrücken;b Nordhausen;99735;03631;Thüringen
Steinburg;b Naumburg, Saale;06647;034465;Sachsen-Anhalt
Steinburg;, Kr Stormarn;22964;04534;Schlewig-Holstein
Steindorf;, Paar;82297;08202;Bayern
Steinebach;, Sieg;57520;02747;Rheinland-Pfalz
Steinebach;an der Wied;57629;02662;Rheinland-Pfalz
Steineberg;, Eifel;54552;06573;Rheinland-Pfalz
Steineck;b St Georgen, Chiemgau;83368;08669;Bayern
Steinefrenz;;56414;06435;Rheinland-Pfalz
Steinen;, Westerw;56244;02666;Rheinland-Pfalz
Steinen;, Kr Lörrach;79585;07627;Baden-Württemberg
Steinenbronn;, Württ;71144;07157;Baden-Württemberg
Steinerholz;b Karby, Schwansen;24398;;Schlewig-Holstein
Steineroth;;57518;02747;Rheinland-Pfalz
Steinfeld;b Rostock;18184;038204;Mecklenburg-Vorpommern
Steinfeld;b Schleswig;24888;04641;Schlewig-Holstein
Steinfeld;(Altmark);39599;039324;Sachsen-Anhalt
Steinfeld;(Oldenburg);49439;05492;Niedersachsen
Steinfeld;, Pfalz;76889;06340;Rheinland-Pfalz
Steinfeld;b Lohr a Main;97854;09359;Bayern
Steinfeld;b Hildburghausen;98646;03685;Thüringen
Steinförde;;16798;033093;Brandenburg
Steinfurt;, Westf;48565;02551;Nordrhein-Westfalen
Steingaden;, Oberbay;86989;08862;Bayern
Steingraben;b Forchheim, Oberfr;91358;;Bayern
Steinhagen;b Neubukow;18233;038297;Mecklenburg-Vorpommern
Steinhagen;b Satow b Bad Doberan;18239;038461;Mecklenburg-Vorpommern
Steinhagen;b Bützow;18246;038461;Mecklenburg-Vorpommern
Steinhagen;b Stralsund;18442;038461;Mecklenburg-Vorpommern
Steinhagen;, Westf;33803;05204;Nordrhein-Westfalen
Steinhausen;an der Rottum;88416;07352;Baden-Württemberg
Steinheid;;98749;036704;Thüringen
Steinheim;, Westf;32839;05233;Nordrhein-Westfalen
Steinheim;an der Murr;71711;07144;Baden-Württemberg
Steinheim;am Albuch;89555;07329;Baden-Württemberg
Steinheuterode;;37318;036083;Thüringen
Steinhöfel;b Fürstenwalde, Spree;15518;033636;Brandenburg
Steinhöfel;b Angermünde;16278;033334;Brandenburg
Steinhöring;b München;85643;08094;Bayern
Steinhorst;, Kr Hzgt Lauenb;23847;04536;Schlewig-Holstein
Steinhorst;, Niedersachs;29367;05148;Niedersachsen
Steinigtwolmsdorf;;01904;035951;Sachsen
Steiningen;;54552;06573;Rheinland-Pfalz
Steinitz;, Altmark;29416;03901;Sachsen-Anhalt
Steinkirchen;, Kr Stade;21720;04142;Niedersachsen
Steinkirchen;, Holzland;84439;08084;Bayern
Steinmauern;;76479;07222;Baden-Württemberg
Stein-Neukirch;;56479;02667;Rheinland-Pfalz
Steinpleis;;08432;0375;Sachsen
Steinrab;;83376;08667;Bayern
Steinrausch;, Eifel;54689;;Rheinland-Pfalz
Steinrode;b Leinefelde;37345;036077;Thüringen
Steinsberg;, Rhein-Lahn-Kr;56379;06439;Rheinland-Pfalz
Steinschleuse;;25868;04883;Schlewig-Holstein
Steinsdorf;b Jessen, Elster;06928;035384;Sachsen-Anhalt
Steinsdorf;b Saalfeld, Saale;07338;036622;Thüringen
Steinsdorf;b Gera;07570;036622;Thüringen
Steinsdorf;b Eisenhüttenstadt;15898;033657;Brandenburg
Steinsfeld;, Mittelfr;91628;09861;Bayern
Steinthaleben;;06567;034651;Thüringen
Steintoch;;15324;033475;Brandenburg
Steinweiler;, Pfalz;76872;06349;Rheinland-Pfalz
Steinwenden;;66879;06371;Rheinland-Pfalz
Steinwiesen;;96349;09262;Bayern
Stein-Wingert;;57629;02688;Rheinland-Pfalz
Steißlingen;;78256;07738;Baden-Württemberg
Stelle;, Kr Harburg;21435;04174;Niedersachsen
Stelle-Wittenwurth;;25795;04837;Schlewig-Holstein
Stellshagen;;23948;038825;Mecklenburg-Vorpommern
Stelzen;b Eisfeld;98678;03686;Thüringen
Stelzenberg;, Pfalz;67705;06306;Rheinland-Pfalz
Stemmen;b Rotenburg, Wümme;27389;04267;Niedersachsen
Stempeda;;99762;034653;Thüringen
Stemshorn;;49448;05474;Niedersachsen
Stemwede;;32351;05773;Nordrhein-Westfalen
Stendal;;39576;03931;Sachsen-Anhalt
Stendell;;16306;033336;Brandenburg
Stenn;;08144;0375;Sachsen
Stenten;;24811;04338;Schlewig-Holstein
Stepenitz;;16945;033969;Brandenburg
Stepfershausen;;98617;036943;Thüringen
Stephanskirchen;, Simssee;83071;08036;Bayern
Stephanskirchen;, Gem Amerang;83552;08036;Bayern
Stephansposching;;94569;09935;Bayern
Sterley;, Holst;23883;04545;Schlewig-Holstein
Sterleyerheide;;23883;;Schlewig-Holstein
Sternberg;, Meckl;19406;03847;Mecklenburg-Vorpommern
Sternberger Burg;;19406;03847;Mecklenburg-Vorpommern
Sternebeck;;16269;033436;Brandenburg
Sternenfels;;75447;07045;Baden-Württemberg
Sternhagen;b Prenzlau;17291;039856;Brandenburg
Sterup;;24996;04637;Schlewig-Holstein
Stetten;, Pfalz;67294;06355;Rheinland-Pfalz
Stetten;am kalten Markt;72510;07573;Baden-Württemberg
Stetten;, Schwab;87778;08261;Bayern
Stetten;b Meersburg, Bodensee;88719;07532;Baden-Württemberg
Stettfeld;, Unterfr;96188;09522;Bayern
Steuden;;06179;034636;Sachsen-Anhalt
Steudten;b Riesa;01594;034324;Sachsen
Steuerwaldsmühle;;67294;;Rheinland-Pfalz
Steutz;;39264;039244;Sachsen-Anhalt
Steyerberg;;31595;05764;Niedersachsen
Stiefenhofen;;88167;08383;Bayern
Stiege;;38899;039459;Sachsen-Anhalt
Stiepelse;;19273;038844;Niedersachsen
Stieten;;19406;03847;Mecklenburg-Vorpommern
Stimpfach;;74597;07967;Baden-Württemberg
Stinstedt;, Niederelbe;21772;04756;Niedersachsen
Stintenburg;;19246;038858;Mecklenburg-Vorpommern
Stintenburger Hütte;;19246;038858;Mecklenburg-Vorpommern
Stipsdorf;;23795;04551;Schlewig-Holstein
Stipshausen;;55758;06544;Rheinland-Pfalz
Stixe;;19273;038845;Niedersachsen
Stobra;;99510;03644;Thüringen
Stockach;, Baden;78333;07771;Baden-Württemberg
Stockbornerhof;;66484;06336;Rheinland-Pfalz
Stockdorf;, Gem Gauting;82131;089;Bayern
Stockelsdorf;;23617;04505;Schlewig-Holstein
Stockem;, Eifel;54646;06527;Rheinland-Pfalz
Stöckey;;37345;036072;Thüringen
Stockhausen-Illfurth;;56472;02661;Rheinland-Pfalz
Stockheim;, Oberfr;96342;09265;Bayern
Stockheim;, Unterfr;97640;09776;Bayern
Stocksberg, Gem Beilstein;, Württ;71543;07130;Baden-Württemberg
Stöckse;;31638;05026;Niedersachsen
Stocksee;;24326;04526;Schlewig-Holstein
Stockstadt;a. Main;63811;06027;Bayern
Stockstadt;am Rhein;64589;06158;Hessen
Stockum-Püschen;;56459;02661;Rheinland-Pfalz
Stödten;;99610;03634;Thüringen
Stödtlen;;73495;07964;Baden-Württemberg
Stoetze;;29597;05872;Niedersachsen
Stöffel;, Westerw;57647;;Rheinland-Pfalz
Stofferstorf;;23968;03841;Mecklenburg-Vorpommern
Stöffin;;16833;03391;Brandenburg
Stöhrhaus;;83471;08652;Bayern
Stolberg;(Harz);06547;034654;Sachsen-Anhalt
Stolberg;(Rhld.);52222;02402;Nordrhein-Westfalen
Stolberg;(Rhld.);52223;02402;Nordrhein-Westfalen
Stolberg;(Rhld.);52224;02402;Nordrhein-Westfalen
Stolk;;24890;04623;Schlewig-Holstein
Stollbeck;;72419;07577;Baden-Württemberg
Stollberg;/Erzgeb.;09366;037298;Sachsen
Stölln;b Rhinow;14728;033875;Brandenburg
Stollsdorf;;09306;03737;Sachsen
Stolpe;b Angermünde;16278;033338;Brandenburg
Stolpe;b Berlin;16540;03303;Brandenburg
Stolpe;b Anklam;17391;039721;Mecklenburg-Vorpommern
Stolpe;auf Usedom;17406;038372;Mecklenburg-Vorpommern
Stolpe;b Parchim;19372;038725;Mecklenburg-Vorpommern
Stolpe;b Neumünster;24601;04326;Schlewig-Holstein
Stolpen;;01833;035973;Sachsen
Stolpe-Süd;;16761;03302;Brandenburg
Stoltebüll;;24409;04642;Schlewig-Holstein
Stoltenberg;;24256;04303;Schlewig-Holstein
Stoltenhagen;;18510;038326;Mecklenburg-Vorpommern
Stoltoft;;24405;04646;Schlewig-Holstein
Stolzenau;, Weser;31592;05761;Niedersachsen
Stolzenbergerhof;;67822;06362;Rheinland-Pfalz
Stolzenhagen;b Eberswalde;16248;033365;Brandenburg
Stolzenhagen;b Wandlitz;16348;033397;Brandenburg
Stolzenhain;b Schönewalde;04916;035384;Brandenburg
Stolzenhain a d Röder;;04932;03533;Brandenburg
Stopperich;;56588;02638;Rheinland-Pfalz
Storbeck;b Neuruppin;16818;03391;Brandenburg
Storbeckshof;;19339;038787;Brandenburg
Storchhof;;74731;06286;Baden-Württemberg
Stördorf;;25554;04823;Schlewig-Holstein
Störkathen;;25548;04822;Schlewig-Holstein
Storkau;b Köthen, Anh;06386;034977;Sachsen-Anhalt
Storkau;b Weißenfels;06667;03443;Sachsen-Anhalt
Storkau;b Stendal;39590;039321;Sachsen-Anhalt
Storkow;, Mark;15859;033678;Brandenburg
Storkow;b Templin;17268;03987;Brandenburg
Storkow;b Pasewalk;17328;039751;Mecklenburg-Vorpommern
Störnstein;;92721;09602;Bayern
Stösitz;;01594;035268;Sachsen
Stößen;;06667;034445;Sachsen-Anhalt
Stötten;a. Auerberg;87675;08349;Bayern
Stotternheim;;99195;036204;Thüringen
Stotternheim;;99195;036204;Thüringen
Stöttwang;;87677;08345;Bayern
Stove;b Gadebusch;19217;038873;Mecklenburg-Vorpommern
Straach;;06896;034929;Sachsen-Anhalt
Strachau;;19273;038758;Niedersachsen
Strachtitz;;18581;;Mecklenburg-Vorpommern
Stradow;;03226;035433;Brandenburg
Straelen;;47638;02834;Nordrhein-Westfalen
Straguth;;39264;039248;Sachsen-Anhalt
Strahlungen;;97618;09733;Bayern
Strahwalde;;02747;035873;Sachsen
Stralendorf;b Schwerin, Meckl;19073;0385;Mecklenburg-Vorpommern
Stralendorf;b Parchim;19372;03871;Mecklenburg-Vorpommern
Stralsund;;18435;03831;Mecklenburg-Vorpommern
Stralsund;;18437;03831;Mecklenburg-Vorpommern
Stralsund;;18439;03831;Mecklenburg-Vorpommern
Strameuß;;23992;038429;Mecklenburg-Vorpommern
Strande;, Holst;24229;04349;Schlewig-Holstein
Strasburg;(Uckermark);17335;039753;Mecklenburg-Vorpommern
Strasen;;17255;039828;Mecklenburg-Vorpommern
Straßberg;b Quedlinburg;06493;039489;Sachsen-Anhalt
Straßberg;b Plauen;08527;03741;Sachsen
Straßberg;b Plauen;08527;03741;Sachsen
Straßberg;, Hohenz;72479;07434;Baden-Württemberg
Strassen;;19294;038755;Mecklenburg-Vorpommern
Straßenhaus;, Westerw;56587;02634;Rheinland-Pfalz
Straßgräbchen;;01936;035723;Sachsen
Straßkirchen;a d Donau;94342;09424;Bayern
Straßlach-Dingharting;;82064;08170;Bayern
Straubenhardt;;75334;07082;Baden-Württemberg
Straubing;;94315;09421;Bayern
Sträubl;;83329;08681;Bayern
Strauch;b Großenhain/Sachsen;01561;03522;Sachsen
Straupitz;;15913;035475;Brandenburg
Strausberg;;15344;03341;Brandenburg
Straußberg;;99713;036334;Thüringen
Straußfurt;;99634;036376;Thüringen
Streckenthin;;16928;033989;Brandenburg
Streesow;;19357;038788;Brandenburg
Streetz;b Roßlau, Elbe;06862;034901;Sachsen-Anhalt
Streganz;;15752;033768;Brandenburg
Strehla;;01616;035264;Sachsen
Strehlen;b Karstädt, Prignitz;19357;038797;Brandenburg
Streichwitz;;15898;033652;Brandenburg
Streiflach;;81249;;Bayern
Streit;b Evenhausen, Oberbay;83552;08075;Bayern
Streitau;b Wörnsmühl;83739;08025;Bayern
Streitberg;, Kr Pfaffenhofen;85307;08444;Bayern
Streitberg;, Stadt Biberach;88441;;Baden-Württemberg
Streitfeld;, Ostfriesl;26441;;Niedersachsen
Streithausen;;57629;02662;Rheinland-Pfalz
Streitholz;b Heilbad Heiligenstadt;37308;03606;Thüringen
Strelln;;04838;034244;Sachsen
Stremmen;;15848;033675;Brandenburg
Strenznaundorf;;06425;034692;Sachsen-Anhalt
Stresendorf;;19372;038721;Mecklenburg-Vorpommern
Stresow;b Burg b Magdeburg;39291;039223;Sachsen-Anhalt
Stressenhausen;;98646;03685;Thüringen
Streufdorf;;98646;036875;Thüringen
Streumen;;01609;035265;Sachsen
Strickscheid;;54597;06556;Rheinland-Pfalz
Striegistal;;09661;037207;Sachsen
Striesdorf;;18299;038459;Mecklenburg-Vorpommern
Striesow;;03096;035606;Brandenburg
Strigleben;;19348;038782;Brandenburg
Ströbels Mühle;;56346;;Rheinland-Pfalz
Strodehne;;14728;033875;Brandenburg
Stroga;;01561;03522;Sachsen
Strohkirchen;b Hagenow;19230;038751;Mecklenburg-Vorpommern
Strohkirchen;b Grevesmühlen;23936;03881;Mecklenburg-Vorpommern
Strohn;;54558;06573;Rheinland-Pfalz
Stromberg;, Hunsrück;55442;06724;Rheinland-Pfalz
Strotzbüsch;;54552;06573;Rheinland-Pfalz
Strub;;83489;08652;Bayern
Strübbel;;25792;04837;Schlewig-Holstein
Strubensee;;16835;033933;Brandenburg
Struckum;;25821;04671;Schlewig-Holstein
Strukdorf;;23815;04553;Schlewig-Holstein
Strullendorf;;96129;09543;Bayern
Struppen;;01796;035020;Sachsen
Strußberg;;83364;08666;Bayern
Strüth;, Taunus;56357;06775;Rheinland-Pfalz
Struvenhütten;;24643;04194;Schlewig-Holstein
Struxdorf;;24891;04623;Schlewig-Holstein
Stubben;b Bad Oldesloe;23847;04534;Schlewig-Holstein
Stubben;b Bremerhaven;27616;04748;Niedersachsen
Stubbendorf;b Malchin;17159;039959;Mecklenburg-Vorpommern
Stubbendorf;b Rostock;18195;038228;Mecklenburg-Vorpommern
Stubenberg;, Niederbay;94166;08571;Bayern
Stuck;;19294;038755;Mecklenburg-Vorpommern
Stücken;;14547;033204;Brandenburg
Stüdenitz;;16845;033972;Brandenburg
Stuer;;17209;039924;Mecklenburg-Vorpommern
Stühlingen;;79780;07744;Baden-Württemberg
Stuhr;;28816;04206;Niedersachsen
Stulln;;92551;09435;Bayern
Stülow;;18209;038203;Mecklenburg-Vorpommern
Stumsdorf;;06780;0345;Sachsen-Anhalt
Stünzmühle;;07619;;Thüringen
Stürzelbach;;57614;02681;Rheinland-Pfalz
Stutensee;;76297;07249;Baden-Württemberg
Stüterhof;;67661;06306;Rheinland-Pfalz
Stuttgart;;70173;0711;Baden-Württemberg
Stuttgart;;70174;0711;Baden-Württemberg
Stuttgart;;70176;0711;Baden-Württemberg
Stuttgart;;70178;0711;Baden-Württemberg
Stuttgart;;70180;0711;Baden-Württemberg
Stuttgart;;70182;0711;Baden-Württemberg
Stuttgart;;70184;0711;Baden-Württemberg
Stuttgart;;70186;0711;Baden-Württemberg
Stuttgart;;70188;0711;Baden-Württemberg
Stuttgart;;70190;0711;Baden-Württemberg
Stuttgart;;70191;0711;Baden-Württemberg
Stuttgart;;70192;0711;Baden-Württemberg
Stuttgart;;70193;0711;Baden-Württemberg
Stuttgart;;70195;0711;Baden-Württemberg
Stuttgart;;70197;0711;Baden-Württemberg
Stuttgart;;70199;0711;Baden-Württemberg
Stuttgart;;70327;0711;Baden-Württemberg
Stuttgart;;70329;0711;Baden-Württemberg
Stuttgart;;70372;0711;Baden-Württemberg
Stuttgart;;70374;0711;Baden-Württemberg
Stuttgart;;70376;0711;Baden-Württemberg
Stuttgart;;70378;0711;Baden-Württemberg
Stuttgart;;70435;0711;Baden-Württemberg
Stuttgart;;70437;0711;Baden-Württemberg
Stuttgart;;70439;0711;Baden-Württemberg
Stuttgart;;70469;0711;Baden-Württemberg
Stuttgart;;70499;0711;Baden-Württemberg
Stuttgart;;70563;0711;Baden-Württemberg
Stuttgart;;70565;0711;Baden-Württemberg
Stuttgart;;70567;0711;Baden-Württemberg
Stuttgart;;70569;0711;Baden-Württemberg
Stuttgart;;70597;0711;Baden-Württemberg
Stuttgart;;70599;0711;Baden-Württemberg
Stuttgart;;70619;0711;Baden-Württemberg
Stuttgart;;70629;0711;Baden-Württemberg
Stützengrün;;08328;037462;Sachsen
Stutzenklinge;;73565;07176;Baden-Württemberg
Stützerbach;;98714;036784;Thüringen
Stuvenborn;;24641;04194;Schlewig-Holstein
Sückau;;19273;038841;Niedersachsen
Suckow;b Parchim;19376;038729;Mecklenburg-Vorpommern
Suckwitz Abbau;;19399;;Mecklenburg-Vorpommern
Südbrookmerland;;26624;04942;Niedersachsen
Suddendorf;;48465;05923;Niedersachsen
Sudenhof;;19230;03883;Mecklenburg-Vorpommern
Süderau;;25361;04824;Schlewig-Holstein
Süderbrarup;;24392;04641;Schlewig-Holstein
Suderburg;;29556;05826;Niedersachsen
Süderdeich;, Dithm;25764;04833;Schlewig-Holstein
Süderdorf;;25782;04838;Schlewig-Holstein
Süderende;auf Föhr;25938;04683;Schlewig-Holstein
Süderfahrenstedt;;24890;04623;Schlewig-Holstein
Südergellersen;;21394;04135;Niedersachsen
Süderhackstedt;;24852;04607;Schlewig-Holstein
Süderhackstedtfeld;b Jörl;24992;;Schlewig-Holstein
Süderhastedt;, Holst;25727;04830;Schlewig-Holstein
Süderheistedt;;25779;04836;Schlewig-Holstein
Süderhöft;;25876;04884;Schlewig-Holstein
Süderholz;, Vorpommern;18516;038331;Mecklenburg-Vorpommern
Süderlügum;;25923;04663;Schlewig-Holstein
Südermarsch;b Husum, Nordsee;25813;04881;Schlewig-Holstein
Süderoog;;25849;04844;Schlewig-Holstein
Süderstapel;;25879;04883;Schlewig-Holstein
Südfall;;25845;;Schlewig-Holstein
Südlohn;;46354;02862;Nordrhein-Westfalen
Südschacht;;38350;05355;Niedersachsen
Sudwalde;;27257;04247;Niedersachsen
Sugenheim;;91484;09165;Bayern
Suhhaus;;73453;07975;Baden-Württemberg
Suhl;;98527;03681;Thüringen
Suhl;;98528;03681;Thüringen
Suhl;;98529;03681;Thüringen
Suhlendorf;;29562;05820;Niedersachsen
Suhlequelle;;99102;;Thüringen
Suhlequelle;;99102;;Thüringen
Suhrbrook;, Gem Hütten;24367;;Schlewig-Holstein
Sukow;;19079;039977;Mecklenburg-Vorpommern
Sükow;;19348;03876;Brandenburg
Sukow-Marienhof;;17168;039977;Mecklenburg-Vorpommern
Sülfeld;, Holst;23867;04537;Schlewig-Holstein
Sulingen;;27232;04271;Niedersachsen
Sülldorf;, Börde;39171;039205;Sachsen-Anhalt
Sülm;;54636;06562;Rheinland-Pfalz
Sülsdorf;;23923;038823;Mecklenburg-Vorpommern
Sülstorf;;19077;0385;Mecklenburg-Vorpommern
Sülten;b Sternberg;19412;038483;Mecklenburg-Vorpommern
Sulz;am Neckar;72172;07454;Baden-Württemberg
Sulza;;07751;03641;Thüringen
Sulzbach;b Idar-Oberstein;55758;06544;Rheinland-Pfalz
Sulzbach;, Rhein-Lahn-Kr;56379;02604;Rheinland-Pfalz
Sulzbach;a. Main;63834;06028;Bayern
Sulzbach;(Taunus);65843;06196;Hessen
Sulzbach;, Saar;66280;06897;Saarland
Sulzbach;an der Murr;71560;07193;Baden-Württemberg
Sulzbacherhof;;66885;06381;Rheinland-Pfalz
Sulzbach-Laufen;;74429;07976;Baden-Württemberg
Sulzbach-Rosenberg;;92237;09661;Bayern
Sulzbachtal;;67734;06308;Rheinland-Pfalz
Sulzberg;, Allgäu;87477;08376;Bayern
Sulzburg;, Baden;79295;07634;Baden-Württemberg
Sulzdorf;a d Lederhecke;97528;09763;Bayern
Sulzemoos;;85254;08135;Bayern
Sulzfeld;, Baden;75056;07269;Baden-Württemberg
Sulzfeld;a. Main;97320;09321;Bayern
Sulzfeld;, Grabfeld;97633;09761;Bayern
Sülzfeld;b Meiningen;98617;036945;Thüringen
Sülzhayn;;99755;036332;Thüringen
Sulzheim;, Rheinhess;55286;06732;Rheinland-Pfalz
Sulzheim;, Unterfr;97529;09382;Bayern
Sulzhof;b Rockenhausen;67808;06361;Rheinland-Pfalz
Sulzthal;;97717;09704;Bayern
Sumte;;19273;038841;Niedersachsen
Sünching;;93104;09480;Bayern
Sundern;(Sauerland);59846;02933;Nordrhein-Westfalen
Sundhausen;b Nordhausen;99735;03631;Thüringen
Sundhausen;b Bad Langensalza;99947;036043;Thüringen
Sundremda;;07407;036744;Thüringen
Sünna;;36404;036962;Thüringen
Süplingen;;39343;039053;Sachsen-Anhalt
Süpplingen;, Kr Helmstedt;38373;05355;Niedersachsen
Süpplingenburg;;38376;05355;Niedersachsen
Süptitz;;04860;03421;Sachsen
Surau;;83552;08074;Bayern
Surberg;;83362;08666;Bayern
Surbergbichl;;83364;08666;Bayern
Surwold;;26903;04965;Niedersachsen
Sürzerhof;;56299;02625;Rheinland-Pfalz
Suschow;;03226;035433;Brandenburg
Süsel;;23701;04529;Schlewig-Holstein
Süßen;;73079;07162;Baden-Württemberg
Süßenborn;;99441;03643;Thüringen
Süßenborn;;99441;03643;Thüringen
Süßenhof;;67317;;Rheinland-Pfalz
Süstedt;;27305;04240;Niedersachsen
Sustrum;;49762;05939;Niedersachsen
Suthfeld;;31555;05723;Niedersachsen
Swisttal;;53913;02226;Nordrhein-Westfalen
Sydower Fließ;;16230;03337;Brandenburg
Syke;;28857;04242;Niedersachsen
Sylda;;06333;034742;Sachsen-Anhalt
Sylt-Ost;;25980;04651;Schlewig-Holstein
Syrau;;08548;037431;Sachsen
Syrgenstein;, Schwab;89428;09077;Bayern
Taarstedt;;24893;04622;Schlewig-Holstein
Tabarz;, Thüringer W;99891;036259;Thüringen
Taben-Rodt;;54441;06582;Rheinland-Pfalz
Tacherting;;83342;08621;Bayern
Taching;a. See;83373;08687;Bayern
Tacken;;19348;038789;Brandenburg
Tackesdorf;;25585;04872;Schlewig-Holstein
Tackesdorf-Nord;;24797;04332;Schlewig-Holstein
Tackmannshof;;17268;039820;Mecklenburg-Vorpommern
Täferrot;;73527;07175;Baden-Württemberg
Tagewerben;;06667;03443;Sachsen-Anhalt
Tagmersheim;;86704;09094;Bayern
Talheim;, Neckar;74388;07133;Baden-Württemberg
Talheim;, Kr Tuttlingen;78607;07464;Baden-Württemberg
Talheimer Hof;;74223;07131;Baden-Württemberg
Talkau;;21493;04156;Schlewig-Holstein
Talling;;54426;06504;Rheinland-Pfalz
Talmühle;, Westerw;57647;;Rheinland-Pfalz
Tambach-Dietharz;, Thüringer W;99897;036252;Thüringen
Tamm;;71732;07150;Baden-Württemberg
Tangeln;;38489;039007;Sachsen-Anhalt
Tangendorf;b Perleberg;19348;038789;Brandenburg
Tangerhütte;;39517;03935;Sachsen-Anhalt
Tangermünde;;39590;039322;Sachsen-Anhalt
Tangstedt;, Kr Stormarn;22889;04109;Schlewig-Holstein
Tangstedt;, Kr Pinneberg;25499;04101;Schlewig-Holstein
Tann;(Rhön);36142;06682;Hessen
Tann;, Niederbay;84367;08572;Bayern
Tanna;b Schleiz;07922;036646;Thüringen
Tanndorf;;04680;034381;Sachsen
Tanne;, Harz;38875;039457;Sachsen-Anhalt
Tanneberg;b Meißen, Sachs;01683;035245;Sachsen
Tannenberg;, Erzgeb;09468;037346;Sachsen
Tannenbergsthal;;08262;037465;Sachsen
Tannengrundhof;;63589;;Hessen
Tännesberg;;92723;09655;Bayern
Tannhausen;b Ellwangen, Jagst;73497;07964;Baden-Württemberg
Tannheim;, Württ;88459;08395;Baden-Württemberg
Tännich;;07407;036744;Thüringen
Tantow;;16307;033333;Brandenburg
Tanzenhaid;;91489;09104;Bayern
Tapfheim;;86660;09004;Bayern
Tappenbeck;;38479;05366;Niedersachsen
Tappendorf;;24594;04871;Schlewig-Holstein
Tarbek;;24619;04323;Schlewig-Holstein
Tarmow;;16833;033932;Brandenburg
Tarmstedt;;27412;04283;Niedersachsen
Tarnewitz;;23946;038825;Mecklenburg-Vorpommern
Tarnewitzerhagen;;23946;038825;Mecklenburg-Vorpommern
Tarnow;b Bützow;18249;038450;Mecklenburg-Vorpommern
Tarp;;24963;04638;Schlewig-Holstein
Tarthun;;39435;039268;Sachsen-Anhalt
Tarzow;;23972;03841;Mecklenburg-Vorpommern
Tasdorf;;24536;04394;Schlewig-Holstein
Tastrup;b Flensburg;24943;0461;Schlewig-Holstein
Tastungen;;37339;036071;Thüringen
Taterberg;;39646;039006;Sachsen-Anhalt
Tating;, Eiderstedt;25881;04862;Schlewig-Holstein
Taubenheim;b Meißen, Sachs;01665;035245;Sachsen
Taubensuhl;, Forsthaus;76857;;Rheinland-Pfalz
Tauberbischofsheim;;97941;09341;Baden-Württemberg
Tauberrettersheim;;97285;09338;Bayern
Taucha;b Leipzig;04425;034298;Sachsen
Taucha;b Weißenfels;06679;034441;Sachsen-Anhalt
Tauche;;15848;033675;Brandenburg
Tauer;;03185;035601;Brandenburg
Taufkirchen;, Kr München;82024;089;Bayern
Taufkirchen;(Vils);84416;08084;Bayern
Taufkirchen;, Kr Mühldorf a Inn;84574;08084;Bayern
Taugwitz;;06628;034463;Sachsen-Anhalt
Taunusstein;;65232;06128;Hessen
Taupadel;b Eisenberg, Thür;07616;036692;Thüringen
Taura;b. Burgstädt;09249;0371;Sachsen
Tauscha;b Großenhain, Sachs;01561;035240;Sachsen
Tauscha;b Rochlitz;09322;037381;Sachsen
Tautenburg;;07778;036427;Thüringen
Tautendorf;b Stadtroda;07646;036426;Thüringen
Tautenhain;b Geithain;04643;034494;Sachsen
Tautenhain;b Hermsdorf, Thür;07639;036601;Thüringen
Tawern;;54456;06501;Rheinland-Pfalz
Techau;;23689;04504;Schlewig-Holstein
Techelsdorf;;24220;04347;Schlewig-Holstein
Techentin;b Lübz;19399;038736;Mecklenburg-Vorpommern
Techin;;19246;038858;Mecklenburg-Vorpommern
Tecklenburg;;49545;05482;Nordrhein-Westfalen
Teerofen;b Eberswalde;16230;;Brandenburg
Teetz;;16866;033976;Brandenburg
Tegau;;07907;036648;Thüringen
Tegernau;, Baden;79692;07629;Baden-Württemberg
Tegernau;b Wasserburg a Inn;83565;08092;Bayern
Tegernheim;;93105;09403;Bayern
Tegernsee;;83684;08022;Bayern
Tegkwitz;;04617;03447;Thüringen
Teicha;b Halle, Saale;06193;034606;Sachsen-Anhalt
Teichel;;07407;036743;Thüringen
Teichland;;03185;035601;Brandenburg
Teichröda;;07407;036743;Thüringen
Teichweiden;;07407;03672;Thüringen
Teichwitz;;07570;036603;Thüringen
Teichwolframsdorf;;07989;036624;Thüringen
Teisendorf;;83317;08666;Bayern
Teising;, Oberbay;84576;08633;Bayern
Teisnach;;94244;09923;Bayern
Teistungen;;37339;036071;Thüringen
Teldau;;19273;038844;Mecklenburg-Vorpommern
Telgte;;48291;02504;Nordrhein-Westfalen
Tellig;;56858;06545;Rheinland-Pfalz
Tellingstedt;;25782;04838;Schlewig-Holstein
Teltow;;14513;03328;Brandenburg
Telz;;15806;033764;Brandenburg
Temmels;;54441;06584;Rheinland-Pfalz
Temmen;;17268;039881;Brandenburg
Tempelberg;b Fürstenwalde, Spree;15518;033432;Brandenburg
Templin;;17268;03987;Brandenburg
Tempzin;;19412;038483;Mecklenburg-Vorpommern
Tengen;;78250;07736;Baden-Württemberg
Teningen;;79331;07641;Baden-Württemberg
Tennenbronn;;78144;07729;Baden-Württemberg
Tensbüttel-Röst;;25767;04835;Schlewig-Holstein
Tensfeld;;23824;04557;Schlewig-Holstein
Teplitz;;23992;038429;Mecklenburg-Vorpommern
Terpe;;03139;03564;Brandenburg
Terpitzsch;;09306;034381;Sachsen
Terpt;;15926;035456;Brandenburg
Teschendorf;b Oranienburg;16515;033094;Brandenburg
Teschendorf;b Neubrandenburg;17094;039603;Mecklenburg-Vorpommern
Teschenmoschel;;67806;06364;Rheinland-Pfalz
Teschow;b Schönberg, Meckl;23923;038823;Mecklenburg-Vorpommern
Teschow;b Grevesmühlen;23936;03881;Mecklenburg-Vorpommern
Tespe;;21395;04176;Niedersachsen
Tessenow;;19376;038729;Mecklenburg-Vorpommern
Tessin;b Rostock;18195;038205;Mecklenburg-Vorpommern
Tessin;b Hagenow;19243;038853;Mecklenburg-Vorpommern
Tessin;b. Boizenburg;19258;038844;Mecklenburg-Vorpommern
Tessin;b Sternberg;19412;038486;Mecklenburg-Vorpommern
Teßmannsdorf;;18233;038294;Mecklenburg-Vorpommern
Testorf;b Hagenow;19246;038851;Mecklenburg-Vorpommern
Testorf;b Grevesmühlen;23936;038822;Mecklenburg-Vorpommern
Testorf-Steinfort;;23936;038871;Mecklenburg-Vorpommern
Tetenbüll;;25882;04862;Schlewig-Holstein
Tetenhusen;;24817;04624;Schlewig-Holstein
Teterow;;17166;03996;Mecklenburg-Vorpommern
Tettau;b Ruhland;01945;03574;Brandenburg
Tettau;, Oberfr;96355;09269;Bayern
Tettenweis;;94167;08534;Bayern
Tettnang;;88069;07542;Baden-Württemberg
Tetzelstein;;38154;;Niedersachsen
Teublitz;;93158;09471;Bayern
Teuchern;;06682;034443;Sachsen-Anhalt
Teufelshof;;14641;033230;Brandenburg
Teugn;;93356;09405;Bayern
Teunz;;92552;09671;Bayern
Teupitz;;15755;033766;Brandenburg
Teuschnitz;;96358;09268;Bayern
Teusin;;17111;039993;Mecklenburg-Vorpommern
Teutleben;b Sömmerda;99628;036373;Thüringen
Teutleben;b Gotha, Thür;99880;03622;Thüringen
Teutschenthal;;06179;034601;Sachsen-Anhalt
Tewswoos;;19303;038759;Mecklenburg-Vorpommern
Thaden;;25557;04872;Schlewig-Holstein
Thaining;;86943;08194;Bayern
Thal;b Wissen, Sieg;57537;02742;Rheinland-Pfalz
Thal;, Gem Gars a Inn;83555;08073;Bayern
Thal;b Eisenach, Thür;99843;036929;Thüringen
Thalberg;b Bad Liebenwerda;04924;035341;Brandenburg
Thalbürgel;;07616;036692;Thüringen
Thale;;06502;03947;Sachsen-Anhalt
Thalebra;;99713;036020;Thüringen
Thaleischweiler-Fröschen;;66987;06334;Rheinland-Pfalz
Thälendorf;;07422;036739;Thüringen
Thalfang;;54424;06504;Rheinland-Pfalz
Thalhausen;, Kr Neuwied;56584;02639;Rheinland-Pfalz
Thalhausen;b Hamm, Sieg;57577;02682;Rheinland-Pfalz
Thalhausermühle;;57577;;Rheinland-Pfalz
Thalheim;b Wolfen;06766;03494;Sachsen-Anhalt
Thalheim;, Erzgeb;09380;03721;Sachsen
Thalhofen;b Landsberg a Lech;86932;08196;Bayern
Thallichtenberg;;66871;06381;Rheinland-Pfalz
Thallwitz;;04808;03425;Sachsen
Thalmassing;, Oberpf;93107;09453;Bayern
Thalmässing;, Mittelfr;91177;09173;Bayern
Thalwenden;;37318;036083;Thüringen
Thalwinkel;;06647;034465;Sachsen-Anhalt
Thammenhain;;04808;034262;Sachsen
Thamsbrück;;99947;03603;Thüringen
Thandorf;;19217;038875;Mecklenburg-Vorpommern
Thannhausen;, Schwab;86470;08281;Bayern
Thanstein;;92554;09676;Bayern
Tharandt;;01737;035203;Sachsen
Thedinghausen;;27321;04204;Niedersachsen
Theeschütz;;04720;034325;Sachsen
Theeßen;;39291;039223;Sachsen-Anhalt
Theilenhofen;;91741;09834;Bayern
Theilheim;, Kr Würzburg;97288;09303;Bayern
Theisa;;04924;035341;Brandenburg
Theisbergstegen;;66871;06381;Rheinland-Pfalz
Theismühlen;;56346;;Rheinland-Pfalz
Theisseil;;92637;0961;Bayern
Theißen;;06727;03441;Sachsen-Anhalt
Thelkow;;18195;038205;Mecklenburg-Vorpommern
Themar;;98660;036873;Thüringen
Theres;;97531;09521;Bayern
Theresenhof;b Rangsdorf;15834;033708;Brandenburg
Thermalbad Wiesenbad;;09488;037369;Sachsen
Thesenvitz;;18528;03838;Mecklenburg-Vorpommern
Theuma;;08541;03741;Sachsen
Thielbeer;;39619;039384;Sachsen-Anhalt
Thiendorf;;01561;035248;Sachsen
Thierbach;b Lobenstein;07356;036652;Thüringen
Thierbach;b Rochlitz;09322;037381;Sachsen
Thierhaupten;;86672;08276;Bayern
Thierschneck;;07774;036421;Thüringen
Thiersheim;;95707;09233;Bayern
Thierstein;;95199;09235;Bayern
Thiesholz;;24405;04646;Schlewig-Holstein
Thießen;b Dessau, Anh;06862;034907;Sachsen-Anhalt
Thiessow;;18586;038308;Mecklenburg-Vorpommern
Thimmendorf;;07356;036643;Thüringen
Tholey;;66636;06853;Saarland
Thomasburg;, Kr Lüneburg;21401;05859;Niedersachsen
Thomm;;54317;06500;Rheinland-Pfalz
Thonhausen;b Schmölln, Thür;04626;034496;Thüringen
Thöringswerder;;16269;033456;Brandenburg
Thörlingen;;56291;06746;Rheinland-Pfalz
Thörnich;;54340;06507;Rheinland-Pfalz
Thorstorf;;23936;03881;Mecklenburg-Vorpommern
Thoßfell;;08541;03741;Sachsen
Thuine;, Emsl;49832;05902;Niedersachsen
Thulendorf;;18184;038209;Mecklenburg-Vorpommern
Thum;, Erzgeb;09419;037297;Sachsen
Thumby;, Schwansen;24351;04355;Schlewig-Holstein
Thum-Herold;;09419;037297;Sachsen
Thum-Jahnsbach;;09419;037297;Sachsen
Thundorf;, Unterfr;97711;09724;Bayern
Thüngen;;97289;09360;Bayern
Thüngersheim;;97291;09364;Bayern
Thür;;56743;02652;Rheinland-Pfalz
Thüringenhausen;;99713;036370;Thüringen
Thüringer Hütte;, Berggasthof;97647;;Bayern
Thürkow;;17168;039975;Mecklenburg-Vorpommern
Thurland;;06779;034906;Sachsen-Anhalt
Thurmansbang;;94169;08504;Bayern
Thurnau;;95349;09228;Bayern
Thurow;b Sternberg;19412;038483;Mecklenburg-Vorpommern
Thyrnau;;94136;08501;Bayern
Thyrow;;14974;033731;Brandenburg
Tiddische;;38473;05366;Niedersachsen
Tieckow;;14798;033834;Brandenburg
Tiefenau;b Riesa;01609;035263;Sachsen
Tiefenbach;, Sachs;09661;034322;Sachsen
Tiefenbach;, Hunsrück;55471;06761;Rheinland-Pfalz
Tiefenbach;, Kr Landshut;84184;08709;Bayern
Tiefenbach;, Federsee;88422;07582;Baden-Württemberg
Tiefenbach;, Oberpf;93464;09673;Bayern
Tiefenbach;, Kr Passau;94113;08509;Bayern
Tiefenbronn;;75233;07234;Baden-Württemberg
Tiefenbrunnen;;14929;033748;Brandenburg
Tiefengruft;, Gem Quern;24989;;Schlewig-Holstein
Tiefenort;;36469;03695;Thüringen
Tiefensee;b Eilenburg;04849;034243;Sachsen
Tiefensee;b Bad Freienwalde;16259;033398;Brandenburg
Tiefenthal;b Perleberg;19348;;Brandenburg
Tiefenthal;, Rheinhess;55546;06709;Rheinland-Pfalz
Tiefenthal;, Pfalz;67311;06351;Rheinland-Pfalz
Tiefenweg;;83546;08073;Bayern
Tiefthal;;99189;0361;Thüringen
Tiefthal;;99189;0361;Thüringen
Tielen;b Erfde;24803;04333;Schlewig-Holstein
Tielenhemme;;25794;04803;Schlewig-Holstein
Tietzow;;14641;033230;Brandenburg
Tilleda;(Kyffhäuser);06537;034651;Sachsen-Anhalt
Timmaspe;;24644;04392;Schlewig-Holstein
Timmendorfer Strand;;23669;04503;Schlewig-Holstein
Timmenrode;;06502;0391;Sachsen-Anhalt
Tinningstedt;;25917;04662;Schlewig-Holstein
Tirpersdorf;;08606;037463;Sachsen
Tirschendorf;;08606;037421;Sachsen
Tirschenreuth;;95643;09631;Bayern
Tissa;;07646;036428;Thüringen
Tiste;;27419;04282;Niedersachsen
Titisee-Neustadt;;79822;07651;Baden-Württemberg
Titting;, Oberbay;85135;08423;Bayern
Tittling;;94104;08504;Bayern
Tittmoning;;84529;08683;Bayern
Titz;;52445;02463;Nordrhein-Westfalen
Tivoli;, Holst;24321;04281;Schlewig-Holstein
Toba;;99713;036330;Thüringen
Toddin;;19230;03883;Mecklenburg-Vorpommern
Todenbüttel;;24819;04874;Schlewig-Holstein
Todendorf;, Kr Stormarn;22965;04534;Schlewig-Holstein
Todenhagen;;18320;;Mecklenburg-Vorpommern
Todenroth;;55481;06763;Rheinland-Pfalz
Todesfelde;;23826;04558;Schlewig-Holstein
Todsburg;;73349;;Baden-Württemberg
Todtenweis;;86447;08237;Bayern
Todtmoos;;79682;07674;Baden-Württemberg
Todtnau;;79674;07671;Baden-Württemberg
Töfenreut;;83379;08681;Bayern
Töging;a. Inn;84513;08631;Bayern
Tolk;;24894;04622;Schlewig-Holstein
Tollow;;23992;038422;Mecklenburg-Vorpommern
Tollwitz;;06231;03462;Sachsen-Anhalt
Tömmelsdorf;;07819;036482;Thüringen
Tönisvorst;;47918;02151;Nordrhein-Westfalen
Tonna;;99958;036042;Thüringen
Tonndorf;b Bad Berka;99438;036450;Thüringen
Tönning;;25832;04861;Schlewig-Holstein
Töpchin;;15755;033769;Brandenburg
Töpen;;95183;09295;Bayern
Topfstedt;;99718;03636;Thüringen
Töplitz;;14476;033202;Brandenburg
Toppenstedt;;21442;04173;Niedersachsen
Törber;;19217;038872;Mecklenburg-Vorpommern
Torfbrücke;;18181;038206;Mecklenburg-Vorpommern
Torfbrücke;;18181;038206;Mecklenburg-Vorpommern
Torfgrube;;73266;07023;Baden-Württemberg
Torfhaus;, Harz;38667;05320;Niedersachsen
Torfkaten;;23883;;Schlewig-Holstein
Torfstich;;08318;;Sachsen
Torgau;;04860;03421;Sachsen
Torgelow;am See;17192;03976;Mecklenburg-Vorpommern
Torgelow;, Vorpommern;17358;03976;Mecklenburg-Vorpommern
Torgelow-Holländerei;;17358;03976;Mecklenburg-Vorpommern
Torisdorf;;23923;038828;Mecklenburg-Vorpommern
Torna;b Zeitz, Elster;06729;034424;Sachsen-Anhalt
Tornau;b Gräfenhainichen;06774;034243;Sachsen-Anhalt
Tornau v d Heide;;06779;034906;Sachsen-Anhalt
Tornesch;;25436;04122;Schlewig-Holstein
Tornitz;b Schönebeck, Elbe;39249;039291;Sachsen-Anhalt
Tornow;b Königs Wusterhausen;15755;033766;Brandenburg
Tornow;b Gransee;16775;033080;Brandenburg
Tornow;b Neuruppin;16818;033929;Brandenburg
Tornow;b Neustadt, Dosse;16866;033971;Brandenburg
Tornowhof;;17259;039831;Mecklenburg-Vorpommern
Törpt;;23923;038828;Mecklenburg-Vorpommern
Tostedt;;21255;04182;Niedersachsen
Tosterglope;;21371;05853;Niedersachsen
Töttelstädt;;99100;036208;Thüringen
Töttelstädt;;99100;036208;Thüringen
Tottleben;;99947;036043;Thüringen
Töttleben;;99198;036203;Thüringen
Töttleben;;99198;036203;Thüringen
Traben-Trarbach;;56841;06541;Rheinland-Pfalz
Trabitz;b Schönebeck, Elbe;39240;039291;Sachsen-Anhalt
Trabitz;, Oberpf;92724;09648;Bayern
Tradlenz;;82390;08847;Bayern
Train;;93358;09444;Bayern
Traisen;;55595;0671;Rheinland-Pfalz
Traitsching;;93455;09974;Bayern
Tramm;b Schwerin, Meckl;19089;038722;Mecklenburg-Vorpommern
Tramm;, Kr Hzgt Lauenb;21516;04156;Schlewig-Holstein
Tramm;b Grevesmühlen;23936;038824;Mecklenburg-Vorpommern
Trams;;19417;038484;Mecklenburg-Vorpommern
Trannroda;;07381;03647;Thüringen
Trantow;;17121;039998;Mecklenburg-Vorpommern
Trappenkamp;;24610;04323;Schlewig-Holstein
Trappstadt;;97633;09765;Bayern
Traßdorf;;99326;03629;Thüringen
Trassem;;54441;06581;Rheinland-Pfalz
Trassenheide;;17449;038371;Mecklenburg-Vorpommern
Traubenmühle;;74523;07903;Baden-Württemberg
Traun;;07819;036481;Thüringen
Traunreut;;83301;08669;Bayern
Traunstein;, Oberbay;83278;0861;Bayern
Traunwalchen;;83374;08669;Bayern
Traupitz;;06729;034424;Sachsen-Anhalt
Trausnitz;;92555;09655;Bayern
Trautenhof;;74249;07943;Baden-Württemberg
Trautenstein;;38899;039459;Sachsen-Anhalt
Trautshofen;;85655;08095;Bayern
Trautskirchen;;90619;09107;Bayern
Travenbrück;;23843;04537;Schlewig-Holstein
Travenhorst;;23827;04556;Schlewig-Holstein
Traventhal;;23795;04551;Schlewig-Holstein
Trebatsch;;15848;033674;Brandenburg
Trebbichau;an der Fuhne;06369;034975;Sachsen-Anhalt
Trebbin;;14959;033731;Brandenburg
Trebbus;;03253;035322;Brandenburg
Trebel;;29494;05848;Niedersachsen
Treben;b Riesa;01594;035268;Sachsen
Treben;b Altenburg, Thür;04617;034343;Thüringen
Trebendorf;b Weißwasser;02959;035773;Sachsen
Trebendorf;b Forst, Lausitz;03149;035695;Brandenburg
Trebendorf-Mühlrose;;02959;035773;Sachsen
Trebgast;;95367;09227;Bayern
Trebishain;;04651;034345;Sachsen
Trebitz;b Lutherstadt Wittenberg;06909;034927;Sachsen-Anhalt
Trebitz;b Belzig;14822;033844;Brandenburg
Trebitz;b Beeskow;15868;033671;Brandenburg
Trebnitz;b Merseburg;06217;03461;Sachsen-Anhalt
Trebnitz;b Könnern;06420;034691;Sachsen-Anhalt
Trebnitz;b Weißenfels;06682;034443;Sachsen-Anhalt
Trebnitz;b Müncheberg;15320;033477;Brandenburg
Trebra;b Sondershausen;99718;036379;Thüringen
Trebs;;19249;038855;Mecklenburg-Vorpommern
Trebsen;/Mulde;04687;034383;Sachsen
Trebur;;65468;06147;Hessen
Trebus;b Niesky;02923;035894;Sachsen
Trechtingshausen;;55413;06721;Rheinland-Pfalz
Trechwitz;;14778;03382;Brandenburg
Treffelstein;;93492;09673;Bayern
Treffurt;;99830;036923;Thüringen
Treia;b Schleswig;24896;04626;Schlewig-Holstein
Treis-Karden;;56253;02672;Rheinland-Pfalz
Tremmen;;14641;033233;Brandenburg
Tremnitz;;07985;036621;Thüringen
Tremsbüttel;;22967;04532;Schlewig-Holstein
Tremsdorf;;14552;033205;Brandenburg
Trendelburg;;34388;05675;Hessen
Trenkmoos;;83359;0861;Bayern
Trennewurth;;25693;04857;Schlewig-Holstein
Trent;, Rügen;18569;038309;Mecklenburg-Vorpommern
Treplin;;15236;033602;Brandenburg
Treppeln;;15898;033656;Brandenburg
Treppendorf;b Rudolstadt;07407;036743;Thüringen
Trescherberg;;83546;08073;Bayern
Treseburg;;38889;039456;Sachsen-Anhalt
Treuchtlingen;;91757;09142;Bayern
Treuen;, Vogtl;08233;037468;Sachsen
Treuenbrietzen;;14929;033748;Brandenburg
Treugeböhla;;01561;03522;Sachsen
Triberg;im Schwarzwald;78098;07722;Baden-Württemberg
Tribohm;;18320;038225;Mecklenburg-Vorpommern
Tribsees;;18465;038320;Mecklenburg-Vorpommern
Trieb;, Vogtl;08239;037463;Sachsen
Triebel;, Vogtl;08606;037434;Sachsen
Triebes;;07950;036622;Thüringen
Triebischtal;;01665;035245;Sachsen
Triefenstein;;97855;09395;Bayern
Triepkendorf;;17259;039820;Mecklenburg-Vorpommern
Trieplatz;;16845;033974;Brandenburg
Trier;;54290;0651;Rheinland-Pfalz
Trier;;54292;0651;Rheinland-Pfalz
Trier;;54293;0651;Rheinland-Pfalz
Trier;;54294;0651;Rheinland-Pfalz
Trier;;54295;0651;Rheinland-Pfalz
Trier;;54296;0651;Rheinland-Pfalz
Trierscheid;;53520;02696;Rheinland-Pfalz
Trierweiler;;54311;0651;Rheinland-Pfalz
Triftern;;84371;08562;Bayern
Triglitz;;16949;033981;Brandenburg
Trimbs;;56753;02654;Rheinland-Pfalz
Trimport;;54636;06562;Rheinland-Pfalz
Trinum;;06369;03496;Sachsen-Anhalt
Trinwillershagen;;18320;038225;Mecklenburg-Vorpommern
Tripkau;b Neuhaus, Elbe;19273;038845;Niedersachsen
Trippigleben;;39649;039004;Sachsen-Anhalt
Trippstadt;;67705;06306;Rheinland-Pfalz
Triptis;;07819;036482;Thüringen
Trittau;;22946;04154;Schlewig-Holstein
Trittenheim;;54349;06507;Rheinland-Pfalz
Triwalk;;23966;03841;Mecklenburg-Vorpommern
Tröbigau;;01877;03594;Sachsen
Tröbitz;;03253;035326;Brandenburg
Tröbnitz;;07646;036428;Thüringen
Tröbsdorf;b Weimar, Thür;99428;03643;Thüringen
Tröbsdorf;b Weimar, Thür;99428;03643;Thüringen
Tröchtelborn;;99869;036258;Thüringen
Trochtelfingen;, Hohenz;72818;07124;Baden-Württemberg
Trockenborn-Wolfersdorf;;07646;036428;Thüringen
Trogen;, Oberfr;95183;09281;Bayern
Tröglitz;;06729;03441;Sachsen-Anhalt
Troisdorf;;53840;02241;Nordrhein-Westfalen
Troisdorf;;53842;02241;Nordrhein-Westfalen
Troisdorf;;53844;02241;Nordrhein-Westfalen
Troistedt;;99438;03643;Thüringen
Trollenhagen;;17039;0395;Mecklenburg-Vorpommern
Tromsdorf;;06648;034467;Sachsen-Anhalt
Tröndel;;24321;04385;Schlewig-Holstein
Trossin;;04880;034223;Sachsen
Trossingen;;78647;07425;Baden-Württemberg
Tröstau;;95709;09232;Bayern
Trostberg;;83308;08621;Bayern
Truchtlaching;;83376;08667;Bayern
Trügleben;;99880;03621;Thüringen
Trulben;;66957;06335;Rheinland-Pfalz
Trunkelsberg;;87779;08331;Bayern
Trusetal;;98596;036840;Thüringen
Tryppehna;;39291;039221;Sachsen-Anhalt
Tschernitz;;03130;035600;Brandenburg
Tschirn;;96367;09268;Bayern
Tübingen;;72070;07071;Baden-Württemberg
Tübingen;;72072;07071;Baden-Württemberg
Tübingen;;72074;07071;Baden-Württemberg
Tübingen;;72076;07071;Baden-Württemberg
Tucheim;;39307;039346;Sachsen-Anhalt
Tüchen;;16928;033982;Brandenburg
Tuchenbach;;90587;0911;Bayern
Tuchtfeld;;37620;05533;Niedersachsen
Tülau;;38474;05833;Niedersachsen
Tümlauer Koog;;25881;04862;Schlewig-Holstein
Tunau;, Schwarzw;79677;07673;Baden-Württemberg
Tuningen;;78609;07464;Baden-Württemberg
Tuntenhausen;;83104;08067;Bayern
Tunzenhausen;;99610;03634;Thüringen
Türkendorf;;03130;03563;Brandenburg
Türkenfeld;b Fürstenfeldbruck;82299;08193;Bayern
Türkheim;, Wertach;86842;08245;Bayern
Turloff;;19406;038485;Mecklenburg-Vorpommern
Turnow-Preilack;;03185;035601;Brandenburg
Tüschow;;19260;038843;Mecklenburg-Vorpommern
Tussenhausen;;86874;08268;Bayern
Tüßling;;84577;08633;Bayern
Tutow;;17129;039999;Mecklenburg-Vorpommern
Tüttendorf;;24214;04346;Schlewig-Holstein
Tüttleben;;99869;03621;Thüringen
Tuttlingen;;78532;07461;Baden-Württemberg
Tutzing;;82327;08158;Bayern
Tützpatz;;17091;03961;Mecklenburg-Vorpommern
Tüzen;b Wismar, Meckl;23992;038429;Mecklenburg-Vorpommern
Twedt;b Schleswig;24894;04641;Schlewig-Holstein
Twieflingen;;38388;05354;Niedersachsen
Twist;, Emsl;49767;05936;Niedersachsen
Twistetal;;34477;05695;Hessen
Twistringen;;27239;04243;Niedersachsen
Tylsen;;29413;039033;Sachsen-Anhalt
Tyrlaching;;84558;08623;Bayern
Übach-Palenberg;;52531;02451;Nordrhein-Westfalen
Übereisenbach;;54689;06524;Rheinland-Pfalz
Überherrn;;66802;06836;Saarland
Überlingen;, Bodensee;88662;07551;Baden-Württemberg
Übersee;;83236;08642;Bayern
Ubstadt-Weiher;;76698;07251;Baden-Württemberg
Uchtdorf;b Tangerhütte;39517;03935;Sachsen-Anhalt
Uchte;;31600;05763;Niedersachsen
Üchtelhausen;;97532;09720;Bayern
Uchtspringe;;39599;039325;Sachsen-Anhalt
Ückeritz;, Usedom;17459;038375;Mecklenburg-Vorpommern
Uckerland;;17337;;Brandenburg
Uckro;;15926;035454;Brandenburg
Udenheim;, Rheinhess;55288;06732;Rheinland-Pfalz
Uder;;37318;036083;Thüringen
Üdersdorf;;54552;06596;Rheinland-Pfalz
Üdersee b Werbellin;;16244;;Brandenburg
Udersleben;;06556;034671;Thüringen
Udestedt;;99198;036203;Thüringen
Udler;;54552;06573;Rheinland-Pfalz
Uebigau;b Großenhain, Sachs;01561;03522;Sachsen
Uebigau;, Elster;04938;035365;Brandenburg
Ueckermünde;;17373;039771;Mecklenburg-Vorpommern
Uedem;;47589;02825;Nordrhein-Westfalen
Uehlfeld;;91486;09163;Bayern
Uehrde;;38170;05332;Niedersachsen
Uelitz;;19077;03868;Mecklenburg-Vorpommern
Uelsby;;24860;04623;Schlewig-Holstein
Uelsen;;49843;05942;Niedersachsen
Uelversheim;;55278;06249;Rheinland-Pfalz
Uelvesbüll;;25889;04864;Schlewig-Holstein
Uelzen;, Lüneburger Heide;29525;0581;Niedersachsen
Uenglingen;;39579;03931;Sachsen-Anhalt
Uenze;;19348;038784;Brandenburg
Uersfeld;, Eifel;56767;02657;Rheinland-Pfalz
Ueß;;56767;02692;Rheinland-Pfalz
Uetersen;;25436;04122;Schlewig-Holstein
Uettingen;;97292;09369;Bayern
Uetz;;14476;033208;Brandenburg
Uetz;, Altmark;39517;039366;Sachsen-Anhalt
Uetze;;31311;05173;Niedersachsen
Uffenheim;;97215;09842;Bayern
Uffing;a. Staffelsee;82449;08846;Bayern
Uftrungen;;06548;034653;Sachsen-Anhalt
Uhingen;;73066;07161;Baden-Württemberg
Uhldingen-Mühlhofen;;88690;07556;Baden-Württemberg
Uhlenbrook;;18233;038294;Mecklenburg-Vorpommern
Uhler;;56290;06762;Rheinland-Pfalz
Ühlingen-Birkendorf;;79777;07747;Baden-Württemberg
Uhlstädt;;07407;036742;Thüringen
Uhrsleben;;39343;039052;Sachsen-Anhalt
Uhusmühle;;56357;06772;Rheinland-Pfalz
Uhyst;, Spree;02999;035728;Sachsen
Uichteritz;;06667;03443;Sachsen-Anhalt
Ullersdorf;b Radeberg;01454;03528;Sachsen
Ullersdorf;b Beeskow;15868;033671;Brandenburg
Ulm;, Donau;89073;0731;Baden-Württemberg
Ulm;, Donau;89075;0731;Baden-Württemberg
Ulm;, Donau;89077;0731;Baden-Württemberg
Ulm;, Donau;89079;0731;Baden-Württemberg
Ulm;, Donau;89081;0731;Baden-Württemberg
Ulmen;;56766;02676;Rheinland-Pfalz
Ulmet;;66887;06387;Rheinland-Pfalz
Ulrichstein;;35327;06645;Hessen
Ulrikenhof;;18246;038464;Mecklenburg-Vorpommern
Ulsnis;;24897;04641;Schlewig-Holstein
Ulzigerode;;06543;034742;Sachsen-Anhalt
Umkirch;;79224;07665;Baden-Württemberg
Umleitungsdeich;, Gem Tetenhusen;24799;;Schlewig-Holstein
Ummanz;;18569;038305;Mecklenburg-Vorpommern
Ummendorf;, Börde;39365;039409;Sachsen-Anhalt
Ummendorf;, Kr Biberach an der Riß;88444;07351;Baden-Württemberg
Ummern;;29369;05083;Niedersachsen
Ummerstadt;;98663;036871;Thüringen
Umpferstedt;;99441;03643;Thüringen
Unbesandten;;19309;038758;Brandenburg
Undeloh;;21274;04189;Niedersachsen
Undenheim;;55278;06737;Rheinland-Pfalz
Ungerhausen;;87781;08393;Bayern
Unkel;;53572;02224;Rheinland-Pfalz
Unkenbach;;67823;06362;Rheinland-Pfalz
Unlingen;;88527;07371;Baden-Württemberg
Unna;;59423;02303;Nordrhein-Westfalen
Unna;;59425;02303;Nordrhein-Westfalen
Unna;;59427;02303;Nordrhein-Westfalen
Unnau;;57648;02661;Rheinland-Pfalz
Unseburg;;39435;039263;Sachsen-Anhalt
Unsleben;;97618;09773;Bayern
Unterammergau;;82497;08822;Bayern
Unterbodnitz;;07646;036424;Thüringen
Unterbreizbach;;36414;06620;Thüringen
Unterbrüz;;19399;038736;Mecklenburg-Vorpommern
Unterdielbach;;69429;06271;Baden-Württemberg
Unterdießen;;86944;08243;Bayern
Unterdietfurt;;84339;08724;Bayern
Untere Horbachsmühle;;55606;;Rheinland-Pfalz
Untere Kleinmichelesmühle;;71111;07157;Baden-Württemberg
Untere Mühle;b Calw;75399;;Baden-Württemberg
Untere Ölmühle;;71720;;Baden-Württemberg
Untere Rauhmühle;;71111;;Baden-Württemberg
Unteregg;b Mindelheim;87782;08269;Bayern
Untereinöd;b Au a Inn;83546;08073;Bayern
Untereisesheim;;74257;07132;Baden-Württemberg
Unterellen;;99819;036927;Thüringen
Unterensingen;;72669;07022;Baden-Württemberg
Unterfischbach;b Sulzbach an der Murr;71560;07193;Baden-Württemberg
Unterföhring;;85774;089;Bayern
Unterführbuch;;89165;07347;Baden-Württemberg
Untergriesbach;, Niederbay;94107;08593;Bayern
Untergruppenbach;;74199;07131;Baden-Württemberg
Unterhaching;;82008;089;Bayern
Unterhain;;07426;036738;Thüringen
Unter-Hainbrunn;;64757;06275;Hessen
Unterholzen;b Petting;83367;08686;Bayern
Unterjeckenbach;;67746;06788;Rheinland-Pfalz
Unterkaka;;06721;034422;Sachsen-Anhalt
Unterkatz;;98634;036940;Thüringen
Unterkirnach;;78089;07721;Baden-Württemberg
Unterkirnach;;78089;07721;Baden-Württemberg
Unterleinleiter;;91364;09194;Bayern
Unterlemnitz;;07356;036651;Thüringen
Unterloquitz;;07330;036731;Thüringen
Unterlüß;;29345;05827;Niedersachsen
Untermarchtal;;89617;07393;Baden-Württemberg
Untermaßfeld;;98617;036949;Thüringen
Untermeitingen;;86836;08232;Bayern
Untermerzbach;;96190;09533;Bayern
Untermittweilerhof;;67806;;Rheinland-Pfalz
Untermödling;;83546;08073;Bayern
Untermoosen;b Waging a See;83329;08681;Bayern
Untermünkheim;;74547;0791;Baden-Württemberg
Unterneukirchen;;84579;08634;Bayern
Unteröd;b Evenhausen, Oberbay;83552;;Bayern
Unterpleichfeld;;97294;09367;Bayern
Unterpörlitz;;98693;03677;Thüringen
Unterreichenbach;, Kr Calw;75399;07235;Baden-Württemberg
Unterreit;b Wasserburg a Inn;83567;08073;Bayern
Unterrißdorf;;06295;03475;Sachsen-Anhalt
Unterrotenstein;;78652;07420;Baden-Württemberg
Unterroth;b Illertissen;89299;07343;Bayern
Unterschleißheim;;85716;089;Bayern
Unterschneidheim;;73485;07966;Baden-Württemberg
Unterschönau;b Schmalkalden;98587;036847;Thüringen
Unterschönberg;;83714;08025;Bayern
Unterschops;;85655;08095;Bayern
Unterschwaningen;;91743;09836;Bayern
Unterschweinbach;;82282;08145;Bayern
Untershausen;;56412;02602;Rheinland-Pfalz
Untersiegenbühl;;73434;07366;Baden-Württemberg
Untersiemau;;96253;09565;Bayern
Unterstadion;;89619;07393;Baden-Württemberg
Untersteinach;b Kulmbach;95369;09225;Bayern
Unterstreit;;83552;08075;Bayern
Unterthierwasen;;67292;06352;Rheinland-Pfalz
Unterthingau;;87647;08377;Bayern
Unterwachingen;;89597;07393;Baden-Württemberg
Unterwaldhausen;;88379;07587;Baden-Württemberg
Unterweid;;98634;036946;Thüringen
Unterweißbach;;98744;036730;Thüringen
Unterwellenborn;;07333;03671;Thüringen
Unterwendling;b Waging a See;83329;08681;Bayern
Unterwirbach;;07422;036741;Thüringen
Unterwössen;;83246;08641;Bayern
Unterwürschnitz;;08626;037421;Sachsen
Untrasried;;87496;08372;Bayern
Unzenberg;;55483;06763;Rheinland-Pfalz
Upahl;;23936;038822;Mecklenburg-Vorpommern
Upgant-Schott;;26529;04934;Niedersachsen
Uphusum;b Süderlügum;25923;04663;Schlewig-Holstein
Uplengen;;26670;04956;Niedersachsen
Upost;;17111;03998;Mecklenburg-Vorpommern
Uppershausen;;54673;06564;Rheinland-Pfalz
Urbach;, Westerw;56317;02684;Rheinland-Pfalz
Urbach;, Rems;73660;07181;Baden-Württemberg
Urbach;b Nordhausen;99765;036333;Thüringen
Urbach;b Mühlhausen, Thür;99996;036333;Thüringen
Urbar;, Rhein-Hunsrück;55430;06741;Rheinland-Pfalz
Urbar;b Koblenz am Rhein;56182;0261;Rheinland-Pfalz
Urbich;;99198;0361;Thüringen
Urbich;;99198;0361;Thüringen
Urfeld;, Oberbay;82432;08851;Bayern
Urleben;;99955;036041;Thüringen
Urmersbach;;56761;02653;Rheinland-Pfalz
Urmitz;, Rhein;56220;02630;Rheinland-Pfalz
Urnshausen;;36457;036964;Thüringen
Ursberg;;86513;08281;Bayern
Urschmitt;;56825;02677;Rheinland-Pfalz
Ursensollen;;92289;09628;Bayern
Urspringen;b Marktheidenfeld;97857;09396;Bayern
Ürzig;, Mosel;54539;06532;Rheinland-Pfalz
Usch;;54655;06594;Rheinland-Pfalz
Usedom;;17406;038372;Mecklenburg-Vorpommern
Userin;;17237;039832;Mecklenburg-Vorpommern
Usingen;;61250;06081;Hessen
Uslar;;37170;05571;Niedersachsen
Ustersbach;;86514;08236;Bayern
Utarp;, Harlingerl;26556;04975;Niedersachsen
Utecht;;19217;038875;Mecklenburg-Vorpommern
Utenbach;b Naumburg, Saale;06618;036694;Sachsen-Anhalt
Utendorf;;98617;03693;Thüringen
Utershorst;;14641;03321;Brandenburg
Utersum;;25938;04683;Schlewig-Holstein
Uthausen;;06773;034921;Sachsen-Anhalt
Uthleben;;99765;036333;Thüringen
Uthlede;;27628;04296;Niedersachsen
Uthmöden;;39345;039058;Sachsen-Anhalt
Utscheid;;54675;06564;Rheinland-Pfalz
Uttenreuth;;91080;09131;Bayern
Uttenweiler;;88524;07374;Baden-Württemberg
Üttfeld;;54619;06559;Rheinland-Pfalz
Utting;a. Ammersee;86919;08806;Bayern
Utzberg;;99428;036203;Thüringen
Utzedel;;17111;039993;Mecklenburg-Vorpommern
Utzenfeld;;79694;07673;Baden-Württemberg
Utzenhain;;56291;06746;Rheinland-Pfalz
Utzerath;;54552;02676;Rheinland-Pfalz
Utzstetten;;73577;07175;Baden-Württemberg
Üxheim;;54579;02696;Rheinland-Pfalz
Vaale;, Holst;25594;04827;Schlewig-Holstein
Vaalerfeld;;25572;04825;Schlewig-Holstein
Vaalermoor;;25594;04823;Schlewig-Holstein
Vacha;;36404;036962;Thüringen
Vachdorf;;98617;036949;Thüringen
Vachendorf;, Kr Traunstein, Oberbay;83377;0861;Bayern
Vahlberg;b Schöppenstedt;38170;05333;Niedersachsen
Vahlbruch;;37647;05535;Niedersachsen
Vahlde;;27389;04267;Niedersachsen
Vahldorf;;39345;039202;Sachsen-Anhalt
Vaihingen;an der Enz;71665;07042;Baden-Württemberg
Valfitz;;29416;039035;Sachsen-Anhalt
Vallendar;;56179;0261;Rheinland-Pfalz
Valley;, Oberbay;83626;08024;Bayern
Valluhn;;19246;038851;Mecklenburg-Vorpommern
Valwig;;56812;02671;Rheinland-Pfalz
Varchentin;;17192;039934;Mecklenburg-Vorpommern
Varel;, Jadebusen;26316;04451;Niedersachsen
Varrel;b Sulingen;27259;04274;Niedersachsen
Vastorf;;21397;04137;Niedersachsen
Vaterstetten;;85591;08106;Bayern
Vatterode;b Hettstedt, Sachs-Anh;06343;034782;Sachsen-Anhalt
Vechelde;;38159;05302;Niedersachsen
Vechta;;49377;04441;Niedersachsen
Veckenstedt;;38871;039452;Sachsen-Anhalt
Veelböken;;19205;03886;Mecklenburg-Vorpommern
Vehlefanz;;16727;033055;Brandenburg
Vehlen;b Wusterwitz;14789;033839;Brandenburg
Vehlgast;;39539;039387;Sachsen-Anhalt
Vehlin;;16866;033977;Brandenburg
Vehlitz;;39291;039200;Sachsen-Anhalt
Vehlow;;16866;033976;Brandenburg
Veilsdorf;;98669;036878;Thüringen
Veitsbronn;;90587;0911;Bayern
Veitshöchheim;;97209;0931;Bayern
Veitsrodt;;55758;06781;Rheinland-Pfalz
Velbert;;42549;02051;Nordrhein-Westfalen
Velbert;;42551;02051;Nordrhein-Westfalen
Velbert;;42553;02051;Nordrhein-Westfalen
Velbert;;42555;02051;Nordrhein-Westfalen
Velburg;;92355;09182;Bayern
Velden;, Vils;84149;08742;Bayern
Velden;, Mittelfr;91235;09152;Bayern
Veldenz;;54472;06534;Rheinland-Pfalz
Velen;;46342;02863;Nordrhein-Westfalen
Velgast;;18469;038324;Mecklenburg-Vorpommern
Vellahn;;19260;038848;Mecklenburg-Vorpommern
Vellberg;;74541;07907;Baden-Württemberg
Vellmar;;34246;0561;Hessen
Velpke;, Niedersachs;38458;05364;Niedersachsen
Velsdorf;;39359;039051;Sachsen-Anhalt
Velten;;16727;03304;Brandenburg
Veltensmühle;;38822;;Sachsen-Anhalt
Veltheim;(Ohe);38173;05305;Niedersachsen
Veltheim;b Halberstadt;38835;039426;Sachsen-Anhalt
Vendersheim;;55578;06732;Rheinland-Pfalz
Venningen;;67482;06323;Rheinland-Pfalz
Ventschow;;19417;038484;Mecklenburg-Vorpommern
Venusberg;b Zschopau;09430;03725;Sachsen
Venusberg;, Gem Hochdorf;88436;;Baden-Württemberg
Venusberg Spinnerei;;09419;037297;Sachsen
Verchen;;17111;039994;Mecklenburg-Vorpommern
Verden;(Aller);27283;04231;Niedersachsen
Veringenstadt;;72519;07577;Baden-Württemberg
Verl;;33415;05246;Nordrhein-Westfalen
Verlorenwasser;;14806;033846;Brandenburg
Versmold;;33775;05423;Nordrhein-Westfalen
Vesser;;98711;036782;Thüringen
Vesser;;98711;036782;Thüringen
Vestenbergsgreuth;;91487;09552;Bayern
Vetschau;/Spreewald;03226;035433;Brandenburg
Vettelschoß;;53560;02645;Rheinland-Pfalz
Vettin;;16928;033975;Brandenburg
Vettweiß;;52391;02424;Nordrhein-Westfalen
Vichel;;16845;03391;Brandenburg
Viechtach;;94234;09942;Bayern
Viehle;;19273;038841;Niedersachsen
Vielank;;19303;038759;Mecklenburg-Vorpommern
Vielbach;;56244;02626;Rheinland-Pfalz
Vielist;;17194;03991;Mecklenburg-Vorpommern
Vielitz;b Lindow, Mark;16835;033933;Brandenburg
Vienau;;39624;039030;Sachsen-Anhalt
Vienenburg;;38690;05324;Niedersachsen
Vierden;;27419;04282;Niedersachsen
Viereck;;17309;039748;Mecklenburg-Vorpommern
Viereth-Trunstadt;;96191;09503;Bayern
Vierherrenborn;;54314;06587;Rheinland-Pfalz
Vierhöfen;b Winsen, Luhe;21444;0451;Niedersachsen
Vieritz;;14715;033870;Brandenburg
Vierkirchen;b Görlitz, Neiße;02894;035827;Sachsen
Vierkirchen;, Oberbay;85256;08139;Bayern
Viernau;;98547;036847;Thüringen
Viernheim;;68519;06204;Hessen
Vierraden;;16306;03332;Brandenburg
Viersen;;41747;02162;Nordrhein-Westfalen
Viersen;;41748;02162;Nordrhein-Westfalen
Viersen;;41749;02162;Nordrhein-Westfalen
Viersen;;41751;02162;Nordrhein-Westfalen
Viesecke;;19336;038796;Brandenburg
Vieselbach;;99198;036203;Thüringen
Vieselbach;;99198;036203;Thüringen
Vietgest;;18279;038452;Mecklenburg-Vorpommern
Vietmannsdorf;;17268;039882;Brandenburg
Vietow;b Boizenburg;19260;;Mecklenburg-Vorpommern
Vietznitz;;14662;033235;Brandenburg
Viez;;19230;03883;Mecklenburg-Vorpommern
Viezen;;18249;038464;Mecklenburg-Vorpommern
Viktoriastollen;, Gem Weisel;56349;06774;Rheinland-Pfalz
Vilgertshofen;;86946;08194;Bayern
Villenbach;;86637;08296;Bayern
Villingendorf;;78667;0741;Baden-Württemberg
Villingen-Schwenningen;;78048;07721;Baden-Württemberg
Villingen-Schwenningen;;78050;07721;Baden-Württemberg
Villingen-Schwenningen;;78052;07721;Baden-Württemberg
Villingen-Schwenningen;;78054;07721;Baden-Württemberg
Villingen-Schwenningen;;78056;07721;Baden-Württemberg
Villmar;;65606;06474;Hessen
Vilsbiburg;;84137;08741;Bayern
Vilseck;, Oberpf;92249;09662;Bayern
Vilsheim;;84186;08706;Bayern
Vilshofen;, Niederbay;94474;08541;Bayern
Vimfow;;19374;038727;Mecklenburg-Vorpommern
Vinningen;;66957;06335;Rheinland-Pfalz
Vinzelberg;;39599;039325;Sachsen-Anhalt
Viöl;;25884;04843;Schlewig-Holstein
Vippachedelhausen;;99439;036452;Thüringen
Vipperow;;17209;039923;Mecklenburg-Vorpommern
Virneburg;;56729;02656;Rheinland-Pfalz
Visbek;, Kr Vechta;49429;04445;Niedersachsen
Visselhövede;;27374;04262;Niedersachsen
Vissum;;29416;039034;Sachsen-Anhalt
Vitense;b Rehna;19217;038872;Mecklenburg-Vorpommern
Vitte;;18565;038300;Mecklenburg-Vorpommern
Vitzenburg;;06268;034461;Sachsen-Anhalt
Vlotho;;32602;05733;Nordrhein-Westfalen
Vockerode;b Dessau, Anh;06786;034905;Sachsen-Anhalt
Vockfey;;19273;038841;Niedersachsen
Voerde;(Niederrhein);46562;02855;Nordrhein-Westfalen
Vogelkoje;;25992;04651;Schlewig-Holstein
Vogelsang;a d Oder;15890;03364;Brandenburg
Vogelsang;b Zehdenick;16792;03307;Brandenburg
Vogelsang-Warsin;;17375;039773;Mecklenburg-Vorpommern
Vogelsberg;b Sömmerda;99610;036372;Thüringen
Vogelsdorf;b Fredersdorf b Berlin;15370;033638;Brandenburg
Vogelsdorf;b Halberstadt;38836;039422;Sachsen-Anhalt
Vögelsen;;21360;04131;Niedersachsen
Voggenmühlhöfle;;73553;07182;Baden-Württemberg
Vogt;;88267;07529;Baden-Württemberg
Vogtareuth;;83569;08038;Bayern
Vogthof;;56865;;Rheinland-Pfalz
Vogtsburg;im Kaiserstuhl;79235;07662;Baden-Württemberg
Vohburg;a d Donau;85088;08457;Bayern
Vohenstrauß;;92648;09651;Bayern
Vöhl;;34516;05635;Hessen
Vöhrenbach;;78147;07727;Baden-Württemberg
Vöhringen;, Württ;72189;07454;Baden-Württemberg
Vöhringen;, Iller;89269;07306;Bayern
Voigtsdorf;b Freiberg, Sachs;09619;037365;Sachsen
Voigtsdorf;b Strasburg;17349;03968;Mecklenburg-Vorpommern
Voigtshain;;04808;034262;Sachsen
Voigtstedt;;06556;03466;Thüringen
Voitswinkl;;83379;0861;Bayern
Volgfelde;;39599;039325;Sachsen-Anhalt
Volkach;;97332;09381;Bayern
Völkenroth;;56288;06762;Rheinland-Pfalz
Volkenschwand;;84106;08754;Bayern
Volkenshagen;b Grevesmühlen;23936;;Mecklenburg-Vorpommern
Volkerode;, Eichsfeld;37308;036082;Thüringen
Völkershausen;b Vacha;36404;036965;Thüringen
Völkersweiler;;76857;06346;Rheinland-Pfalz
Volkertshausen;;78269;07774;Baden-Württemberg
Volkerzen;;57612;02681;Rheinland-Pfalz
Volkesfeld;;56745;02655;Rheinland-Pfalz
Völklingen;;66333;06898;Saarland
Volkmannsdorf;b Saalfeld, Saale;07318;036736;Thüringen
Volkmannsdorf;b Schleiz;07924;03663;Thüringen
Volkmarsen;;34471;05693;Hessen
Volkstedt;;06295;03475;Sachsen-Anhalt
Volkstorf;b Dassow;23942;;Mecklenburg-Vorpommern
Vollenborn;;37355;036076;Thüringen
Vollersode;;27729;04793;Niedersachsen
Vollersroda;;99438;03643;Thüringen
Vollerwiek;;25836;04862;Schlewig-Holstein
Vollmersbach;;55758;06781;Rheinland-Pfalz
Vollmershain;;04626;034496;Thüringen
Vollmersweiler;;76744;06340;Rheinland-Pfalz
Vollrathsruhe;;17194;039933;Mecklenburg-Vorpommern
Vollstedt;;25821;04671;Schlewig-Holstein
Völmlesmühle;;72297;07448;Baden-Württemberg
Völpke;;39393;039402;Sachsen-Anhalt
Völschow;;17129;039997;Mecklenburg-Vorpommern
Volsemenhusen;;25693;04857;Schlewig-Holstein
Voltlage;;49599;05467;Niedersachsen
Volxheim;;55546;06703;Rheinland-Pfalz
Volzrade;;19249;038855;Mecklenburg-Vorpommern
Vorbach;, Oberpf;95519;09205;Bayern
Vorbeck;b Bützow;18258;03844;Mecklenburg-Vorpommern
Vorbein;;17121;039998;Mecklenburg-Vorpommern
Vorderhagen;;19273;038874;Mecklenburg-Vorpommern
Vorderholzmühle;;83552;08074;Bayern
Vorderleiten;b Neukirchen a Teisenberg;83364;;Bayern
Vorderloh;b Neukirchen a Teisenberg;83364;08666;Bayern
Vorderweidenthal;;76889;06398;Rheinland-Pfalz
Vordorf;, Kr Gifhorn;38533;05304;Niedersachsen
Vorra;, a d Pegnitz;91247;09152;Bayern
Vörstetten;;79279;07666;Baden-Württemberg
Vorsuchhütte;;95100;09287;Bayern
Vorwerk;b Tarmstedt;27412;04288;Niedersachsen
Vorwerk Friedrich;;76726;;Rheinland-Pfalz
Voßkuhl;b Wismar, Meckl;23968;03841;Mecklenburg-Vorpommern
Vreden;;48691;02564;Nordrhein-Westfalen
Vrees;;49757;04479;Niedersachsen
Waabs;;24369;04358;Schlewig-Holstein
Waake;, Kr Göttingen;37136;05507;Niedersachsen
Waakirchen;;83666;08021;Bayern
Waal;, Schwab;86875;08246;Bayern
Wabern;, Hess;34590;05683;Hessen
Wachau;b. Radeberg;01454;03528;Sachsen
Wachenheim;an der Weinstraße;67157;06322;Rheinland-Pfalz
Wachenheim;, Rheinhess;67591;06322;Rheinland-Pfalz
Wachenroth;;96193;09548;Bayern
Wachow;;14641;033239;Brandenburg
Wachsenburggemeinde;;99310;03628;Thüringen
Wachstedt;;37359;036075;Thüringen
Wachtberg;;53343;0228;Nordrhein-Westfalen
Wachtendonk;;47669;02836;Nordrhein-Westfalen
Wächtersbach;;63607;06053;Hessen
Wachtküppel;, Gem Poppenhausen, Wasserkupp;36129;;Hessen
Wacken;;25596;04827;Schlewig-Holstein
Wackernheim;;55263;06132;Rheinland-Pfalz
Wackerow;b Malchin;17153;039954;Mecklenburg-Vorpommern
Wackerow;b Greifswald;17498;038351;Mecklenburg-Vorpommern
Wackersberg;b Bad Tölz;83646;08041;Bayern
Wackersdorf;;92442;09431;Bayern
Wackersleben;;39393;039401;Sachsen-Anhalt
Waddeweitz;;29496;05849;Niedersachsen
Wadelsdorf;;03130;035698;Brandenburg
Wadern;;66687;06871;Saarland
Wadersloh;;59329;02523;Nordrhein-Westfalen
Wadewitz;b Zeitz, Elster;06729;034424;Sachsen-Anhalt
Wadgassen;;66787;06834;Saarland
Waffenbrunn;;93494;09971;Bayern
Waffenrod;;98666;03686;Thüringen
Wagenfeld;;49419;05444;Niedersachsen
Wagenhausen;, Vulkaneifel;56826;02677;Rheinland-Pfalz
Wagenhoff;;38559;05376;Niedersachsen
Wagenitz;;14641;033237;Brandenburg
Wagersrott;;24392;04641;Schlewig-Holstein
Waghäusel;;68753;07254;Baden-Württemberg
Waging;a. See;83329;08681;Bayern
Wagun;;17159;039959;Mecklenburg-Vorpommern
Wahlbach;, Hunsrück;55494;06766;Rheinland-Pfalz
Wahlenau;;55491;06543;Rheinland-Pfalz
Wahlendorf;;16818;033920;Brandenburg
Wahlenhalden;;73453;07975;Baden-Württemberg
Wahlhausen;;37318;05652;Thüringen
Wahlheim;;55234;06731;Rheinland-Pfalz
Wahlitz;;39175;039200;Sachsen-Anhalt
Wahlrod;;57614;02680;Rheinland-Pfalz
Wahlsburg;, Weser;37194;05572;Hessen
Wahlsdorf;b Jüterbog;14913;033745;Brandenburg
Wahlstedt;;23812;04554;Schlewig-Holstein
Wahlstorf;b Lübz;19386;038733;Mecklenburg-Vorpommern
Wahlstorf;b Preetz, Holst;24211;04342;Schlewig-Holstein
Wahns;;98634;036941;Thüringen
Wahnwegen;;66909;06384;Rheinland-Pfalz
Wahrenberg;, Altmark;39615;039397;Sachsen-Anhalt
Wahrenbrück;;04924;035341;Brandenburg
Wahrenholz;;29399;05835;Niedersachsen
Wahrsow;;23923;038821;Mecklenburg-Vorpommern
Wahrstorf;b Grevesmühlen;23948;038428;Mecklenburg-Vorpommern
Waiblingen;, Rems;71332;07151;Baden-Württemberg
Waiblingen;, Rems;71334;07151;Baden-Württemberg
Waiblingen;, Rems;71336;07151;Baden-Württemberg
Waibstadt;;74915;07263;Baden-Württemberg
Waidhaus;;92726;09652;Bayern
Waidhofen;, Oberbay;86579;08443;Bayern
Waigandshain;;56477;02664;Rheinland-Pfalz
Waigolshausen;;97534;09722;Bayern
Wain;;88489;0751;Baden-Württemberg
Wainsdorf;;04932;03533;Brandenburg
Waischenfeld;;91344;09202;Bayern
Wakendorf;b Wismar, Meckl;23992;038429;Mecklenburg-Vorpommern
Wakendorf;b Henstedt-Ulzburg;24558;04535;Schlewig-Holstein
Wakendorf I;;23845;04550;Schlewig-Holstein
Walbeck;b Hettstedt, Sachs-Anh;06333;03476;Sachsen-Anhalt
Walbeck;b Haldensleben;39356;039061;Sachsen-Anhalt
Walchenberg;;83374;08669;Bayern
Walchensee;, Oberbay;82432;08858;Bayern
Walchow;;16833;033932;Brandenburg
Walchum;;26907;04963;Niedersachsen
Wald;b Neukirchen a Teisenberg;83364;08666;Bayern
Wald;b Weibhausen;83379;08681;Bayern
Wald;, Ostallgäu;87616;08302;Bayern
Wald;, Hohenz;88639;07578;Baden-Württemberg
Wald;, Oberpf;93192;09463;Bayern
Walda;b Großenhain/Sachsen;01561;03522;Sachsen
Waldachtal;;72178;07443;Baden-Württemberg
Waldalgesheim;;55425;06721;Rheinland-Pfalz
Waldaschaff;;63857;06095;Bayern
Waldau;b Zeitz, Elster;06721;034422;Sachsen-Anhalt
Waldau;b Hildburghausen;98667;036878;Thüringen
Waldböckelheim;;55596;06758;Rheinland-Pfalz
Waldbreitbach;;56588;02638;Rheinland-Pfalz
Waldbröl;;51545;02291;Nordrhein-Westfalen
Waldbronn;, Albtal;76337;07243;Baden-Württemberg
Waldbrunn;(Westerwald);65620;06479;Hessen
Waldbrunn;, Odenw;69429;06274;Baden-Württemberg
Waldbrunn;, Kr Würzburg;97295;09306;Bayern
Waldburg;, Württ;88289;07529;Baden-Württemberg
Waldbüttelbrunn;;97297;0931;Bayern
Walddorfhäslach;;72141;07127;Baden-Württemberg
Walddrehna;;15926;035455;Brandenburg
Waldeck;b Stadtroda;07646;036692;Thüringen
Waldeck;b Grevesmühlen;23936;03881;Mecklenburg-Vorpommern
Waldeck;, Hess;34513;05634;Hessen
Waldeck;b Landau, Pfalz;76857;;Rheinland-Pfalz
Waldems;;65529;06087;Hessen
Waldenbuch;;71111;07157;Baden-Württemberg
Waldenburg;, Sachs;08396;037608;Sachsen
Waldenburg;, Württ;74638;07942;Baden-Württemberg
Waldenhofen;;88453;07354;Baden-Württemberg
Walderbach;;93194;09464;Bayern
Waldershof;;95679;09231;Bayern
Waldesch;b Koblenz am Rhein;56323;02628;Rheinland-Pfalz
Waldesruh;b Berlin;12625;030;Brandenburg
Waldfeucht;;52525;02455;Nordrhein-Westfalen
Waldfischbach-Burgalben;;67714;06333;Rheinland-Pfalz
Waldfriede;b Birkenfeld, Nahe;55765;06782;Rheinland-Pfalz
Waldfriede;b Höhr-Grenzhausen;56203;02624;Rheinland-Pfalz
Waldfrieden;b Schönhausen, Elbe;39539;039387;Sachsen-Anhalt
Waldfrieden-Schweizertal;b Miellen;56132;;Rheinland-Pfalz
Waldgrehweiler;;67822;06364;Rheinland-Pfalz
Waldhambach;, Pfalz;76857;06346;Rheinland-Pfalz
Waldhaus;b Zwickau;08144;;Sachsen
Waldhaus Grebe;;35216;;Hessen
Waldheim;, Sachs;04736;034327;Sachsen
Waldheim;b Falkensee;14612;03322;Brandenburg
Waldhof;b Perleberg;19348;038782;Brandenburg
Waldhof;b Bad Bergzabern;76889;;Rheinland-Pfalz
Waldhof-Falkenstein;;54673;06524;Rheinland-Pfalz
Waldhufen;;02906;035827;Sachsen
Waldkappel;;37284;05656;Hessen
Waldkirch;, Breisgau;79183;07681;Baden-Württemberg
Waldkirchen;, Vogtl;08485;037606;Sachsen
Waldkirchen;, Erzgeb;09437;037294;Sachsen
Waldkirchen;, Niederbay;94065;08581;Bayern
Waldkraiburg;;84478;08638;Bayern
Waldlaubersheim;;55444;06707;Rheinland-Pfalz
Waldleiningen;, Pfalz;67693;06305;Rheinland-Pfalz
Wald-Michelbach;;69483;06207;Hessen
Waldmohr;;66914;06373;Rheinland-Pfalz
Waldmühlen;;56479;02664;Rheinland-Pfalz
Waldmünchen;;93449;09972;Bayern
Waldorf;, Kr Ahrweiler;53498;02636;Rheinland-Pfalz
Waldow;;15910;035477;Brandenburg
Waldow-Sacrow;;15913;035475;Brandenburg
Waldrach;;54320;06500;Rheinland-Pfalz
Waldrohrbach;;76857;06346;Rheinland-Pfalz
Waldsassen;;95652;09632;Bayern
Waldschmidtmühle;;56377;02604;Rheinland-Pfalz
Waldsee;, Pfalz;67165;06236;Rheinland-Pfalz
Waldshagen;, Gem Bosau;24306;;Schlewig-Holstein
Waldshut-Tiengen;;79761;07741;Baden-Württemberg
Waldsieversdorf;;15377;033433;Brandenburg
Waldsolms;;35647;06085;Hessen
Waldstadt;b Wünsdorf;15838;033702;Brandenburg
Waldstetten;, Württ;73550;07171;Baden-Württemberg
Waldstetten;, Württ;73550;07171;Baden-Württemberg
Waldstetten;, Kr Günzburg;89367;08223;Bayern
Waldthurn;;92727;09657;Bayern
Waldweiler;;54429;06589;Rheinland-Pfalz
Walhausen;b Blankenrath;56865;06545;Rheinland-Pfalz
Walheim;, Württ;74399;07143;Baden-Württemberg
Walkendorf;;17179;039972;Mecklenburg-Vorpommern
Walkenried;;37445;05525;Niedersachsen
Walkertshofen;b Schwabmünchen;86877;08239;Bayern
Walksfelde;;23896;04543;Schlewig-Holstein
Wall;b Neuruppin;16818;033925;Brandenburg
Wallbach;b Meiningen;98639;03693;Thüringen
Walldorf;, Baden;69190;06227;Baden-Württemberg
Walldorf;b Meiningen;98639;03693;Thüringen
Walldürn;;74731;06282;Baden-Württemberg
Wallen;, Holst;25788;04803;Schlewig-Holstein
Wallenborn;;54570;06599;Rheinland-Pfalz
Wallendorf;(Luppe);06254;034639;Sachsen-Anhalt
Wallendorf;, Eifel;54675;06566;Rheinland-Pfalz
Wallenfels;, Oberfr;96346;09262;Bayern
Wallenhorst;;49134;05407;Niedersachsen
Wallenried;;87782;08269;Bayern
Wallerfangen;;66798;06837;Saarland
Wallerfing;;94574;09936;Bayern
Wallersdorf;, Niederbay;94522;09933;Bayern
Wallersheim;, Eifel;54597;06558;Rheinland-Pfalz
Wallerstein;;86757;09081;Bayern
Wallertheim;;55578;06732;Rheinland-Pfalz
Wallgau;;82499;08825;Bayern
Wallhalben;;66917;06375;Rheinland-Pfalz
Wallhausen;b Sangerhausen;06528;034656;Sachsen-Anhalt
Wallhausen;, Kr Bad Kreuznach;55595;06706;Rheinland-Pfalz
Wallhausen;, Württ;74599;07955;Baden-Württemberg
Wallichen;;99198;036203;Thüringen
Wallichen;;99198;036203;Thüringen
Wallitz;;16837;033923;Brandenburg
Wallmenroth;b Betzdorf, Sieg;57584;02741;Rheinland-Pfalz
Wallmerod;, Westerw;56414;06435;Rheinland-Pfalz
Wallmoden;;38729;05383;Niedersachsen
Wallmow;;17291;039862;Brandenburg
Wallroda;b Dresden;01477;035200;Sachsen
Wallsbüll;b Flensburg;24980;04639;Schlewig-Holstein
Wallscheid;;54531;06572;Rheinland-Pfalz
Wallstawe;;29413;039033;Sachsen-Anhalt
Walluf;;65396;06123;Hessen
Wallwitz;(Saalkreis);06193;034606;Sachsen-Anhalt
Wallwitz;b Burg b Magdeburg;39291;039221;Sachsen-Anhalt
Walow;;17209;039932;Mecklenburg-Vorpommern
Walpernhain;;07613;036691;Thüringen
Walpertskirchen;;85469;08122;Bayern
Wälschbuch;;85406;08168;Bayern
Walschleben;;99189;036201;Thüringen
Walsdorf;, Eifel;54578;06593;Rheinland-Pfalz
Walsdorf;, Oberfr;96194;09549;Bayern
Walshausen;b Zweibrücken, Pfalz;66484;06339;Rheinland-Pfalz
Walsheim;, Pfalz;76833;06341;Rheinland-Pfalz
Walsleben;b Neuruppin;16818;033920;Brandenburg
Walsleben;, Altmark;39606;039388;Sachsen-Anhalt
Walsrode;;29664;05161;Niedersachsen
Waltenhausen;;86480;08282;Bayern
Waltenhofen;, Allgäu;87448;08303;Bayern
Walternienburg;;39264;039247;Sachsen-Anhalt
Walterschen;;57632;02686;Rheinland-Pfalz
Waltersdorf;b Zittau;02799;035841;Sachsen
Waltersdorf;b Altenburg, Thür;04617;034498;Thüringen
Waltersdorf;b Stadtroda;07646;036623;Thüringen
Waltersdorf;b Greiz;07980;03661;Thüringen
Waltersdorf;b Jüterbog;14913;033744;Brandenburg
Waltersdorf;b Königs Wusterhausen;15732;033762;Brandenburg
Waltersdorf;, NL;15926;035455;Brandenburg
Waltershausen;, Thür;99880;03622;Thüringen
Waltershofen;b Unterschweinbach;82282;08145;Bayern
Waltersleben;;99102;0361;Thüringen
Waltersleben;;99102;0361;Thüringen
Walthersdorf;, Erzgeb;09474;037344;Sachsen
Walting;, Kr Eichstätt, Bay;85137;08426;Bayern
Waltrop;;45731;02309;Nordrhein-Westfalen
Walzbachtal;;75045;07203;Baden-Württemberg
Walzenmühle;;71144;;Baden-Württemberg
Wambacherhof;;67808;06302;Rheinland-Pfalz
Wamckow;;19406;038488;Mecklenburg-Vorpommern
Wandersleben;;99869;036256;Thüringen
Wanderup;;24997;04606;Schlewig-Holstein
Wandlitz;;16348;033397;Brandenburg
Wanfried;;37281;05655;Hessen
Wang;, Isar;85368;08761;Bayern
Wangelau;;21483;04155;Schlewig-Holstein
Wangelnstedt;;37627;05532;Niedersachsen
Wangels;;23758;04382;Schlewig-Holstein
Wangen;b Naumburg, Saale;06642;034461;Sachsen-Anhalt
Wangen;, Kr Göppingen;73117;07161;Baden-Württemberg
Wangen;im Allgäu;88239;07522;Baden-Württemberg
Wangenheim;;99869;036255;Thüringen
Wangerland;;26434;04463;Niedersachsen
Wangerooge;, Nordseebad;26486;04469;Niedersachsen
Wankendorf;;24601;04326;Schlewig-Holstein
Wanna;;21776;04757;Niedersachsen
Wannefeld;;39638;039088;Sachsen-Anhalt
Wannweil;;72827;07121;Baden-Württemberg
Wansdorf;;14641;033231;Brandenburg
Wansleben am See;;06318;034601;Sachsen-Anhalt
Wanzer;;39615;039395;Sachsen-Anhalt
Wanzleben;;39164;039209;Sachsen-Anhalt
Wanzlitz;;19300;038756;Mecklenburg-Vorpommern
Wapelfeld;;24594;04871;Schlewig-Holstein
Warberg;, Kr Helmstedt;38378;05355;Niedersachsen
Warburg;, Westf;34414;05641;Nordrhein-Westfalen
Wardenburg;;26203;04407;Niedersachsen
Warder;b Neumünster;24646;04329;Schlewig-Holstein
Wardow;;18299;038459;Mecklenburg-Vorpommern
Waren;(Müritz);17192;03991;Mecklenburg-Vorpommern
Warendorf;;48231;02581;Nordrhein-Westfalen
Warin;;19417;038482;Mecklenburg-Vorpommern
Warlin;;17039;039606;Mecklenburg-Vorpommern
Warlitz;;19230;038856;Mecklenburg-Vorpommern
Warlow;;19288;038751;Mecklenburg-Vorpommern
Warmensteinach;;95485;09277;Bayern
Warmsen;;31606;05767;Niedersachsen
Warmsroth;;55442;06724;Rheinland-Pfalz
Warnau;b Nettelsee;24250;04302;Schlewig-Holstein
Warnau;b Schönhausen, Elbe;39524;039382;Sachsen-Anhalt
Warngau;;83627;08021;Bayern
Warnitz;, Uckermark;17291;039863;Brandenburg
Warnkenhagen;b Teterow;17168;039975;Mecklenburg-Vorpommern
Warnkenhagen;b Grevesmühlen;23948;038827;Mecklenburg-Vorpommern
Warnkenhagen;b Wismar, Meckl;23992;038429;Mecklenburg-Vorpommern
Warnow;b Bützow;18249;038462;Mecklenburg-Vorpommern
Warnow;b Grevesmühlen;23936;03881;Mecklenburg-Vorpommern
Warnsdorf;b Meyenburg, Prignitz;16945;033986;Brandenburg
Warnstedt;b Thale;06502;03947;Sachsen-Anhalt
Warpe;;27333;05022;Niedersachsen
Warrenzin;;17111;03998;Mecklenburg-Vorpommern
Warringholz;;25560;04892;Schlewig-Holstein
Warsow;b Nauen;14662;033235;Brandenburg
Warsow;b Schwerin, Meckl;19075;038859;Mecklenburg-Vorpommern
Warstein;;59581;02902;Nordrhein-Westfalen
Wartbichl;;83739;08025;Bayern
Wartenberg;, Hess;36367;06641;Hessen
Wartenberg;, Oberbay;85456;08762;Bayern
Wartenberg-Rohrbach;;67681;06302;Rheinland-Pfalz
Wartenburg;;06901;034927;Sachsen-Anhalt
Wartenstein;, Schloß;55606;;Rheinland-Pfalz
Warthausen;an der Riß;88447;07351;Baden-Württemberg
Warthof;b Spiegelberg, Württ;71579;07194;Baden-Württemberg
Wartin;;16306;033331;Brandenburg
Wartmannsroth;;97797;09737;Bayern
Warwerort;;25761;04834;Schlewig-Holstein
Warza;;99869;036255;Thüringen
Wasbek;;24647;04321;Schlewig-Holstein
Wasbüttel;;38553;05374;Niedersachsen
Wäschenbeuren;;73116;07172;Baden-Württemberg
Waschow;b Hagenow;19243;038852;Mecklenburg-Vorpommern
Wasdow;;17179;039971;Mecklenburg-Vorpommern
Wasenbach;;56370;06432;Rheinland-Pfalz
Washof;;85276;08441;Bayern
Wassenach;;56653;02636;Rheinland-Pfalz
Wassenberg;;41849;02432;Nordrhein-Westfalen
Wasserburg;a. Inn;83512;08071;Bayern
Wasserburg;(Bodensee);88142;08382;Bayern
Wasserleben;;38871;039451;Sachsen-Anhalt
Wasserliesch;;54332;06501;Rheinland-Pfalz
Wasserlosen;;97535;09726;Bayern
Wassermühle;Bergenhusen;24861;04885;Schlewig-Holstein
Wassersuppe;;14715;033872;Brandenburg
Wasserthaleben;;99718;036370;Thüringen
Wassertrüdingen;;91717;09832;Bayern
Waßmannsdorf;;15831;03379;Brandenburg
Wasungen;;98634;036941;Thüringen
Waterdiek;;24214;04346;Schlewig-Holstein
Waterloo;b Karstädt, Prignitz;19357;038797;Brandenburg
Wathlingen;;29339;05144;Niedersachsen
Wattenbek;;24582;04322;Schlewig-Holstein
Wattendorf;, Oberfr;96196;09504;Bayern
Wattenheim;, Pfalz;67319;06356;Rheinland-Pfalz
Wattmannshagen;;18279;038455;Mecklenburg-Vorpommern
Watzerath;;54595;06551;Rheinland-Pfalz
Watzkendorf;;17237;039826;Mecklenburg-Vorpommern
Watzlsteg;;93479;09946;Bayern
Watzmannhaus;;83486;;Bayern
Watzumer Häuschen;;38154;;Niedersachsen
Wawern;, Saar;54441;06501;Rheinland-Pfalz
Wawern;, Eifel;54612;06553;Rheinland-Pfalz
Waxweiler;;54649;06554;Rheinland-Pfalz
Webau;;06679;034443;Sachsen-Anhalt
Webelsfelde;;19205;038871;Mecklenburg-Vorpommern
Weberhäusl;b Weibhausen;83379;08681;Bayern
Weberin;;19412;03863;Mecklenburg-Vorpommern
Weberstedt;;99947;036022;Thüringen
Webling;b Pfaffenhofen a d Ilm;85276;08441;Bayern
Wechingen;;86759;09085;Bayern
Wechmar;;99869;036256;Thüringen
Wechselburg;;09306;037384;Sachsen
Weckersdorf;;07937;036628;Thüringen
Weddelbrook;;24576;040;Schlewig-Holstein
Weddersleben;;06502;03946;Sachsen-Anhalt
Wedderstedt;;06458;039481;Sachsen-Anhalt
Weddingstedt;;25795;04837;Schlewig-Holstein
Wedel;;22880;04103;Schlewig-Holstein
Wedemark;;30900;05130;Niedersachsen
Wedendorf;;19217;038872;Mecklenburg-Vorpommern
Wedlitz;;06429;034721;Sachsen-Anhalt
Wedringen;;39345;03904;Sachsen-Anhalt
Weede;;23795;04551;Schlewig-Holstein
Weener;;26826;04951;Niedersachsen
Weenzen;;31096;05185;Niedersachsen
Wees;;24999;04631;Schlewig-Holstein
Weesby;;24994;04605;Schlewig-Holstein
Weesow;;16356;033398;Brandenburg
Weeze;;47652;02837;Nordrhein-Westfalen
Wefensleben;;39365;039400;Sachsen-Anhalt
Weferlingen;, Aller;39356;039061;Sachsen-Anhalt
Weg;, Kr Erding;85447;;Bayern
Wegberg;;41844;02434;Nordrhein-Westfalen
Wegefarth;;09600;037321;Sachsen
Wegeleben;;38828;039423;Sachsen-Anhalt
Wegensen;;37620;05533;Niedersachsen
Wegenstedt;;39359;039059;Sachsen-Anhalt
Weggen-Ziegelhütte;;73568;07176;Baden-Württemberg
Wegscheid;, Niederbay;94110;08592;Bayern
Wehingen;, Württ;78564;07426;Baden-Württemberg
Wehnde;;37339;036071;Thüringen
Wehningen;;19273;038845;Niedersachsen
Wehr;, Eifel;56653;02636;Rheinland-Pfalz
Wehr;, Baden;79664;07762;Baden-Württemberg
Wehrbleck;;27259;04274;Niedersachsen
Wehretal;;37287;05658;Hessen
Wehrhain;;04936;035361;Brandenburg
Wehrheim;;61273;06081;Hessen
Wehringen;;86517;08234;Bayern
Weiberhof;;63846;06093;Bayern
Weibern;, Brohltal;56745;02655;Rheinland-Pfalz
Weibersbrunn;;63879;06094;Bayern
Weibhausen;;83379;08681;Bayern
Weichering;;86706;08454;Bayern
Weichs;, Kr Dachau;85258;08136;Bayern
Weickelsdorf;;06722;034422;Sachsen-Anhalt
Weida;, Thür;07570;036603;Thüringen
Weiden;b Hottenbach;55758;06785;Rheinland-Pfalz
Weiden;, Oberpf;92637;0961;Bayern
Weiden;b Weimar, Thür;99439;036451;Thüringen
Weidenbach;b Gerolstein;54570;06599;Rheinland-Pfalz
Weidenbach;, Taunus;56355;06775;Rheinland-Pfalz
Weidenbach;, Mittelfr;91746;09826;Bayern
Weidenberg;, Fichtelgeb;95466;09278;Bayern
Weidenhahn;;56244;02666;Rheinland-Pfalz
Weidenhain;;04860;03421;Sachsen
Weidenhell;;56859;06545;Rheinland-Pfalz
Weidenhof;, Dithm;25725;04805;Schlewig-Holstein
Weidenstetten;;89197;07340;Baden-Württemberg
Weidenthal;, Pfalz;67475;06329;Rheinland-Pfalz
Weidhausen;b. Coburg;96279;09562;Bayern
Weiding;b Oberviechtach;92557;09674;Bayern
Weiding;, Kr Cham, Oberpf;93495;09977;Bayern
Weiding;, Niederbay;94529;08546;Bayern
Weidingen;;54636;06527;Rheinland-Pfalz
Weiditz;;09306;03737;Sachsen
Weidmannsruh;b Jüterbog;14913;;Brandenburg
Weifa;;01904;035951;Sachsen
Weigendorf;, Oberpf;91249;09154;Bayern
Weigenheim;;97215;09842;Bayern
Weihenzell;;91629;09802;Bayern
Weiherhammer;;92729;09605;Bayern
Weiherhof;, Württ;72186;07454;Baden-Württemberg
Weihermühle;b Pirmasens;66987;06334;Rheinland-Pfalz
Weihmichl;;84107;08708;Bayern
Weikersheim;;97990;07934;Baden-Württemberg
Weil;, Kr Landsberg a Lech;86947;08195;Bayern
Weil am Rhein;;79576;07621;Baden-Württemberg
Weil der Stadt;;71263;07033;Baden-Württemberg
Weil im Schönbuch;;71093;07157;Baden-Württemberg
Weilar;;36457;036965;Thüringen
Weilbach;, Unterfr;63937;09373;Bayern
Weilburg;;35781;06471;Hessen
Weilen;unter den Rinnen;72367;07427;Baden-Württemberg
Weiler;b Bingen am Rhein;55413;06721;Rheinland-Pfalz
Weiler;bei Monzingen;55627;06754;Rheinland-Pfalz
Weiler;b Mayen;56729;02656;Rheinland-Pfalz
Weiler;b Gevenich, Eifel;56825;02678;Rheinland-Pfalz
Weilerbach;, Pfalz;67685;06374;Rheinland-Pfalz
Weilersbach;, Oberfr;91365;09191;Bayern
Weiler-Simmerberg;;88171;08387;Bayern
Weilerswist;;53919;02254;Nordrhein-Westfalen
Weilheim;an der Teck;73235;07023;Baden-Württemberg
Weilheim;, Kr Waldshut;79809;07755;Baden-Württemberg
Weilheim;i. OB;82362;0881;Bayern
Weilmünster;;35789;06472;Hessen
Weilrod;;61276;06083;Hessen
Weiltingen;;91744;09853;Bayern
Weimar;(Lahn);35096;06421;Hessen
Weimar;, Thür;99423;03643;Thüringen
Weimar;, Thür;99425;03643;Thüringen
Weimar;, Thür;99427;03643;Thüringen
Weinähr;;56379;02604;Rheinland-Pfalz
Weinbach;;35796;06471;Hessen
Weinbergen;;99998;03601;Thüringen
Weinböhla;;01689;035243;Sachsen
Weingarten;(Pfalz);67366;06344;Rheinland-Pfalz
Weingarten;(Baden);76356;07244;Baden-Württemberg
Weingarten;, Gem Gars a Inn;83546;08073;Bayern
Weingarten;, Württ;88250;0751;Baden-Württemberg
Weingarten;, Württ;88250;0751;Baden-Württemberg
Weingarten;b Gotha, Thür;99869;036254;Thüringen
Weinheim;, Bergstr;69469;06201;Baden-Württemberg
Weinolsheim;;55278;06249;Rheinland-Pfalz
Weinsberg;;74189;07134;Baden-Württemberg
Weinsheim;, Eifel;54595;06551;Rheinland-Pfalz
Weinsheim;, Kr Bad Kreuznach;55595;06758;Rheinland-Pfalz
Weinstadt;;71384;07151;Baden-Württemberg
Weira;;07806;036484;Thüringen
Weisbach;b Lobenstein, Thür;07356;036643;Thüringen
Weisbrunn;b St Georgen, Chiemgau;83368;08669;Bayern
Weischlitz;;08538;037436;Sachsen
Weischütz;;06636;034462;Sachsen-Anhalt
Weisel;b St Goarshausen;56348;06774;Rheinland-Pfalz
Weisen;;19322;03877;Brandenburg
Weisenbach;, Murgtal;76599;07224;Baden-Württemberg
Weisendorf;;91085;09135;Bayern
Weisenheim am Berg;;67273;06353;Rheinland-Pfalz
Weisenheim am Sand;;67256;06353;Rheinland-Pfalz
Weisham;b St Georgen, Chiemgau;83368;08669;Bayern
Weiskirchen;, Saar;66709;06876;Saarland
Weismain;;96260;09575;Bayern
Weissach;, Württ;71287;07044;Baden-Württemberg
Weissach;im Tal;71554;07044;Baden-Württemberg
Weißach;, Gem Kreuth;83700;08029;Bayern
Weißack;;15926;035455;Brandenburg
Weißandt-Gölzau;;06369;034978;Sachsen-Anhalt
Weißbach;b Stadtroda;07646;036426;Thüringen
Weißbach;b Rochlitz;09306;03737;Sachsen
Weißbach;, Württ;74679;07947;Baden-Württemberg
Weißbach;b Waging a See;83329;08681;Bayern
Weißbach b Königsbrück;;01936;035795;Sachsen
Weißdorf;;95237;09251;Bayern
Weiße Krug;;19417;038482;Mecklenburg-Vorpommern
Weißen;b Jüterbog;14913;033744;Brandenburg
Weißenberg;, Sachs;02627;035876;Sachsen
Weißenborn;b Droyßig;06722;034425;Sachsen-Anhalt
Weißenborn;b Hermsdorf, Thür;07639;036601;Thüringen
Weißenborn;/Erzgeb.;09600;037322;Sachsen
Weißenborn;b Eschwege;37299;05659;Hessen
Weißenborn-Lüderode;;37345;036072;Thüringen
Weißenbrunn;, Kr Kronach;96369;09261;Bayern
Weißenburg;i Bay;91781;09141;Bayern
Weißenburg;b Sömmerda;99610;;Thüringen
Weißendiez;;36460;03695;Thüringen
Weißendorf;, Thür;07950;036622;Thüringen
Weißenfeld;;85622;089;Bayern
Weißenfels;, Saale;06667;03443;Sachsen-Anhalt
Weißenhorn;;89264;07309;Bayern
Weißenohe;;91367;09192;Bayern
Weißensberg;;88138;08389;Bayern
Weißenschirmbach;;06268;034461;Sachsen-Anhalt
Weißensee;, Thür;99631;036374;Thüringen
Weißenseifen, Gem Mürlenbach;;54597;;Rheinland-Pfalz
Weißenseifen, Gem Wallersheim;;54597;;Rheinland-Pfalz
Weißenstadt;;95163;09253;Bayern
Weißenthurm;, Rhein;56575;02637;Rheinland-Pfalz
Weißer Hirsch;;23909;;Schlewig-Holstein
Weißewarte;;39517;03935;Sachsen-Anhalt
Weißig a Raschütz;;01561;035248;Sachsen
Weißkeißel;;02957;03576;Sachsen
Weißkirchhof;;63589;;Hessen
Weißnaußlitz;;02633;035930;Sachsen
Weißwasser;/O.L.;02943;03576;Sachsen
Weisweil;, Breisgau;79367;07646;Baden-Württemberg
Weitefeld;;57586;02743;Rheinland-Pfalz
Weitendorf;b Güstrow;18299;0385;Mecklenburg-Vorpommern
Weitendorf;b Sternberg;19412;038483;Mecklenburg-Vorpommern
Weitendorf;b Wismar, Meckl;23968;038428;Mecklenburg-Vorpommern
Weitenhagen;b Greifswald;17498;03834;Mecklenburg-Vorpommern
Weitenhagen;b Franzburg;18461;038324;Mecklenburg-Vorpommern
Weitersbach;;55624;06544;Rheinland-Pfalz
Weitersborn;;55629;06754;Rheinland-Pfalz
Weitersburg;;56191;02622;Rheinland-Pfalz
Weitersglashütte;;08309;037752;Sachsen
Weiterstadt;;64331;06150;Hessen
Weitersweiler;;67808;06357;Rheinland-Pfalz
Weitmoos;b Evenhausen, Oberbay;83552;;Bayern
Weitnau;;87480;08375;Bayern
Weitramsdorf;;96479;09561;Bayern
Weitwies, Gem Neukirchen;a Teisenberg;83364;;Bayern
Weitzgrund;;14806;033841;Brandenburg
Welbsleben;;06333;03473;Sachsen-Anhalt
Welcherath;;53539;02692;Rheinland-Pfalz
Welchweiler;;66887;06387;Rheinland-Pfalz
Welden;b Augsburg;86465;08293;Bayern
Welfesholz;;06333;034783;Sachsen-Anhalt
Welgesheim;;55576;06701;Rheinland-Pfalz
Welkenbach;, Westerw;57644;02680;Rheinland-Pfalz
Welle;, Nordheide;21261;04188;Niedersachsen
Wellen;b Magdeburg;39167;039206;Sachsen-Anhalt
Wellen;, Mosel;54441;06584;Rheinland-Pfalz
Wellendingen;, Württ;78669;07426;Baden-Württemberg
Wellheim;;91809;08427;Bayern
Welling;b Mayen;56753;02654;Rheinland-Pfalz
Wellingshof;;73614;07181;Baden-Württemberg
Wellmitz;;15898;033652;Brandenburg
Wellspang;;24860;04623;Schlewig-Holstein
Welmbüttel;;25782;04838;Schlewig-Holstein
Welschbillig;;54298;06506;Rheinland-Pfalz
Welschenbach;;56729;02656;Rheinland-Pfalz
Welschneudorf;;56412;02608;Rheinland-Pfalz
Welschrötherhof;;55606;06757;Rheinland-Pfalz
Welsebruch;;16306;033336;Brandenburg
Welsickendorf;;14913;033746;Brandenburg
Welsigke;;14823;033849;Brandenburg
Welsleben;;39221;039296;Sachsen-Anhalt
Welsow;;16278;03331;Brandenburg
Welt;;25836;04862;Schlewig-Holstein
Welterod;;56357;06775;Rheinland-Pfalz
Weltersburg;;56459;06435;Rheinland-Pfalz
Weltwitz;;07819;036481;Thüringen
Welver;;59514;02384;Nordrhein-Westfalen
Welzheim;;73642;07182;Baden-Württemberg
Welzin;b Grevesmühlen;23948;038824;Mecklenburg-Vorpommern
Welzmühle;, Oberfr;96369;;Bayern
Welzow;;03119;035751;Brandenburg
Wembach;, Schwarzw;79677;07673;Baden-Württemberg
Wemding;;86650;09092;Bayern
Wenddorf;;39517;039363;Sachsen-Anhalt
Wendeberg;;14715;;Brandenburg
Wendeburg;;38176;05303;Niedersachsen
Wendehausen;;99988;036024;Thüringen
Wendelsheim;, Rheinhess;55234;06734;Rheinland-Pfalz
Wendelstein;, Mittelfr;90530;09129;Bayern
Wendelstorf;b Ostseebad Rerik;18230;038296;Mecklenburg-Vorpommern
Wendemark;, Altmark;39615;039393;Sachsen-Anhalt
Wenden;, Südsauerland;57482;02762;Nordrhein-Westfalen
Wendisch Baggendorf;;18513;038326;Mecklenburg-Vorpommern
Wendisch Evern;;21403;04131;Niedersachsen
Wendisch Priborn;;19395;038737;Mecklenburg-Vorpommern
Wendisch Rambow;;23996;038423;Mecklenburg-Vorpommern
Wendisch Rietz;;15864;033679;Brandenburg
Wendisch Waren;;19399;038736;Mecklenburg-Vorpommern
Wendlingen;am Neckar;73240;07024;Baden-Württemberg
Wendorf;b Stralsund;18442;03831;Mecklenburg-Vorpommern
Wendorf;b Sternberg;19412;038486;Mecklenburg-Vorpommern
Wendorf;b Grevesmühlen;23936;;Mecklenburg-Vorpommern
Wendtorf;;24235;04343;Schlewig-Holstein
Weng;, Kr Landshut;84187;08702;Bayern
Wengelsdorf;;06688;03461;Sachsen-Anhalt
Wenigenehrich;;99713;036370;Thüringen
Wenigenlupnitz;;99819;036920;Thüringen
Wenigensömmern;;99610;03634;Thüringen
Wenigentaft;;36419;036967;Thüringen
Wenigossa;;04643;034346;Sachsen
Wennbüttel;;25767;04835;Schlewig-Holstein
Wennigsen;(Deister);30974;05103;Niedersachsen
Wenningstedt;(Sylt);25996;04651;Schlewig-Holstein
Wense;b Dorfmark;29683;05163;Niedersachsen
Wensickendorf;;16515;033053;Brandenburg
Wensin;;23827;04559;Schlewig-Holstein
Wentdorf;;19322;038794;Brandenburg
Wentorf;bei Hamburg;21465;040;Schlewig-Holstein
Wentorf;b Sandesneben;23898;04536;Schlewig-Holstein
Wenze;;38486;039005;Sachsen-Anhalt
Wenzenbach;;93173;09407;Bayern
Wenzendorf;;21279;04165;Niedersachsen
Wenzlow;b Brandenburg an der Havel;14778;033833;Brandenburg
Werbach;;97956;09348;Baden-Württemberg
Werbellin;;16244;033363;Brandenburg
Werben;, NL;03096;035603;Brandenburg
Werben;b Zossen b Berlin;15806;03378;Brandenburg
Werben;(Elbe);39615;039393;Sachsen-Anhalt
Werbig;b Belzig;14806;033847;Brandenburg
Werbig;b Jüterbog;14913;033746;Brandenburg
Werbig;b Seelow;15306;0355;Brandenburg
Werchau;;04916;035362;Brandenburg
Werchow;;03205;03541;Brandenburg
Werda;b Auerbach, Vogtl;08223;037463;Sachsen
Werdau;, Sachs;08412;03764;Sachsen
Werder;(Havel);14542;03327;Brandenburg
Werder;b Jüterbog;14913;03381;Brandenburg
Werder;b Strausberg;15345;033435;Brandenburg
Werder;b Beeskow;15848;033677;Brandenburg
Werder;b Neuruppin;16818;033920;Brandenburg
Werder;b Altentreptow;17089;03969;Mecklenburg-Vorpommern
Werder;b Lübz;19386;038731;Mecklenburg-Vorpommern
Werdohl;;58791;02392;Nordrhein-Westfalen
Werdum;;26427;04974;Niedersachsen
Werenzhain;;03253;035322;Brandenburg
Wergzahna;;14913;033747;Brandenburg
Werkhausen;;57635;02686;Rheinland-Pfalz
Werl;;59457;02922;Nordrhein-Westfalen
Werlaburgdorf;;38315;05335;Niedersachsen
Werle;b Ludwigslust;19300;038788;Mecklenburg-Vorpommern
Werlte;, Emsl;49757;05951;Niedersachsen
Wermelskirchen;;42929;02196;Nordrhein-Westfalen
Wermelskirchen;;42929;02196;Nordrhein-Westfalen
Wermsdorf;;04779;034364;Sachsen
Wernau;(Neckar);73249;07153;Baden-Württemberg
Wernberg-Köblitz;;92533;09604;Bayern
Wernburg;;07381;03647;Thüringen
Werne;;59368;02389;Nordrhein-Westfalen
Werneck;;97440;09722;Bayern
Wernersberg;, Pfalz;76857;06346;Rheinland-Pfalz
Werneuchen;;16356;033398;Brandenburg
Wernigerode;;38855;03943;Sachsen-Anhalt
Wernikow;;16909;03394;Brandenburg
Werningshausen;;99634;036376;Thüringen
Wernitz;b Nauen;14641;033234;Brandenburg
Wernitzgrün;;08258;037422;Sachsen
Wernrode;;99735;036334;Thüringen
Wernsdorf;b Erkner;15537;03375;Brandenburg
Wernshausen;;98590;036848;Thüringen
Wernstedt;;39624;039080;Sachsen-Anhalt
Weroth;b Montabaur;56414;06435;Rheinland-Pfalz
Werpeloh;;49751;05952;Niedersachsen
Werrabronn;;76356;07244;Baden-Württemberg
Werschen;;06682;034443;Sachsen-Anhalt
Wershofen;;53520;02694;Rheinland-Pfalz
Wertach;;87497;08365;Bayern
Wertheim;a Main;97877;09342;Baden-Württemberg
Werther;(Westf.);33824;05203;Nordrhein-Westfalen
Werther;b Nordhausen;99735;03631;Thüringen
Wertingen;;86637;08272;Bayern
Wesel;a Rhein;46483;0281;Nordrhein-Westfalen
Wesel;a Rhein;46485;0281;Nordrhein-Westfalen
Wesel;a Rhein;46487;0281;Nordrhein-Westfalen
Weselberg;;66919;06333;Rheinland-Pfalz
Weselitz;;17291;039858;Brandenburg
Wesenberg;, Meckl;17255;039832;Mecklenburg-Vorpommern
Wesenberg;, Holst;23858;04533;Schlewig-Holstein
Wesendahl;;15345;03341;Brandenburg
Wesendorf;b Zehdenick;16792;03307;Brandenburg
Wesendorf;, Niedersachs;29392;05376;Niedersachsen
Weseram;;14778;033831;Brandenburg
Wespen;;39249;039298;Sachsen-Anhalt
Wesselburen;;25764;04833;Schlewig-Holstein
Wesselburener Deichhausen;;25764;04833;Schlewig-Holstein
Wesselburenerkoog;;25764;04833;Schlewig-Holstein
Wesseling;, Rheinl;50389;02236;Nordrhein-Westfalen
Wesseling;, Rheinl;50389;02236;Nordrhein-Westfalen
Wesseln;, Dithm;25746;0481;Schlewig-Holstein
Wessin;;19089;03863;Mecklenburg-Vorpommern
Weßling;, Oberbay;82234;08153;Bayern
Wessobrunn;;82405;08809;Bayern
Westdorf;b Aschersleben, Sachs-Anh;06449;03473;Sachsen-Anhalt
Weste;;29599;05805;Niedersachsen
Westenbrügge;;18233;038294;Mecklenburg-Vorpommern
Westendorf;b Donauwörth;86707;08273;Bayern
Westendorf;b Kaufbeuren;87679;08344;Bayern
Westenfeld;b Meiningen;98631;036948;Thüringen
Westensee;;24259;04305;Schlewig-Holstein
Westerau;, Holst;23847;04539;Schlewig-Holstein
Westerbeck;b Schönberg, Meckl;23923;;Mecklenburg-Vorpommern
Westerborstel;;25782;04838;Schlewig-Holstein
Westerburer Polder;;26427;;Niedersachsen
Westerburg;, Westerw;56457;02663;Rheinland-Pfalz
Westerdeichstrich;;25761;04834;Schlewig-Holstein
Westeregeln;;39448;039268;Sachsen-Anhalt
Westerengel;;99718;036379;Thüringen
Westergellersen;;21394;04135;Niedersachsen
Westerhausen;b Quedlinburg;06484;03946;Sachsen-Anhalt
Westerheim;, Württ;72589;07333;Baden-Württemberg
Westerheim;b Memmingen;87784;08336;Bayern
Westerhever;;25881;04865;Schlewig-Holstein
Westerholt;, Ostfriesl;26556;04975;Niedersachsen
Westerholz;b Langballig;24977;04636;Schlewig-Holstein
Westerhorn;;25364;04127;Schlewig-Holstein
Westerkappeln;;49492;05404;Nordrhein-Westfalen
Westerland;;25980;04651;Schlewig-Holstein
Westermoor;, Holst;25597;04828;Schlewig-Holstein
Westermoor;, Dithm;25779;04836;Schlewig-Holstein
Westerngrund;;63825;06024;Bayern
Westernohe;;56479;02664;Rheinland-Pfalz
Wester-Ohrstedt;;25885;04847;Schlewig-Holstein
Westerrade;;23815;04553;Schlewig-Holstein
Westerrönfeld;;24784;04337;Schlewig-Holstein
Westerstede;;26655;04488;Niedersachsen
Westerstetten;;89198;07348;Baden-Württemberg
Westert;;56459;06435;Rheinland-Pfalz
Westertimke;;27412;04289;Niedersachsen
Westerwalsede;;27386;04269;Niedersachsen
Westfehmarn;;23769;04372;Schlewig-Holstein
Westfeld;b Alfeld, Leine;31079;05065;Niedersachsen
Westgreußen;;99718;03636;Thüringen
Westhausen;b Heilbad Heiligenstadt;37308;03606;Thüringen
Westhausen;, Württ;73463;07363;Baden-Württemberg
Westhausen;b Hildburghausen;98663;036875;Thüringen
Westhausen;b Gotha, Thür;99869;036255;Thüringen
Westheim;(Pfalz);67368;06344;Rheinland-Pfalz
Westheim;b Gunzenhausen;91747;09833;Bayern
Westhof;b Ostseebad Rerik;18230;038296;Mecklenburg-Vorpommern
Westhofen;, Rheinhess;67593;06244;Rheinland-Pfalz
Westkinberg;;88175;;Bayern
Westoverledingen;;26810;04955;Niedersachsen
Westre;;25926;04666;Schlewig-Holstein
Wethau;;06618;03445;Sachsen-Anhalt
Wetschen;;49453;05446;Niedersachsen
Wettelrode;;06528;03464;Sachsen-Anhalt
Wettenberg;, Hess;35435;0641;Hessen
Wetter;(Hessen);35083;06423;Hessen
Wetter;(Ruhr);58300;02335;Nordrhein-Westfalen
Wetterzeube;;06722;036693;Sachsen-Anhalt
Wettin;;06198;034607;Sachsen-Anhalt
Wettingen;;89129;07345;Baden-Württemberg
Wettlingen;;54646;06527;Rheinland-Pfalz
Wettringen;, Kr Steinfurt;48493;02557;Nordrhein-Westfalen
Wettringen;, Mittelfr;91631;09869;Bayern
Wettrup;;49838;05909;Niedersachsen
Wettstetten;;85139;0841;Bayern
Wetzelsberg;b Neukirchen a Teisenberg;83364;08666;Bayern
Wetzlar;;35576;06441;Hessen
Wetzlar;;35578;06441;Hessen
Wetzlar;;35579;06441;Hessen
Wetzlar;;35580;06441;Hessen
Wetzlar;;35581;06441;Hessen
Wetzlar;;35582;06441;Hessen
Wetzlar;;35583;06441;Hessen
Wetzlar;;35584;06441;Hessen
Wetzlar;;35585;06441;Hessen
Wetzlar;;35586;06441;Hessen
Wewelsfleth;;25599;04829;Schlewig-Holstein
Weyarn;;83629;08020;Bayern
Weyer;, Taunus;56357;06771;Rheinland-Pfalz
Weyerbusch;;57635;02686;Rheinland-Pfalz
Weyhausen;, Kr Gifhorn;38554;05366;Niedersachsen
Weyhe;b Bremen;28844;04203;Niedersachsen
Weyher;in der Pfalz;76835;06323;Rheinland-Pfalz
Wiblishauserhof;;89335;08223;Bayern
Wichmannsdorf;b Grevesmühlen;23946;038825;Mecklenburg-Vorpommern
Wichmar;;07774;036421;Thüringen
Wichtshausen;;98530;036846;Thüringen
Wichtshausen;;98530;036846;Thüringen
Wickede;(Ruhr);58739;02377;Nordrhein-Westfalen
Wickendorf;b Saalfeld, Saale;07338;036734;Thüringen
Wickenrodt;;55758;06785;Rheinland-Pfalz
Wickerode;;06536;034651;Sachsen-Anhalt
Wickersdorf;b Saalfeld, Saale;07318;036736;Thüringen
Wickerstedt;;99510;03644;Thüringen
Widdern;;74259;06298;Baden-Württemberg
Wiebelsdorf;;07950;036626;Thüringen
Wiebelsheim;, Hunsrück;56291;06766;Rheinland-Pfalz
Wiebendorf;;19258;038842;Mecklenburg-Vorpommern
Wieck a Darß;;18375;;Mecklenburg-Vorpommern
Wied;b Hachenburg;57629;02662;Rheinland-Pfalz
Wieda;;37447;05586;Niedersachsen
Wiedemar;;04509;034602;Sachsen
Wieden;, Schwarzw;79695;07673;Baden-Württemberg
Wiedenborstel;;24613;04873;Schlewig-Holstein
Wiedensahl;;31719;05726;Niedersachsen
Wiedenzhausen;;85259;08134;Bayern
Wiederau;b Uebigau, Elster;04938;035365;Brandenburg
Wiedergeltingen;;86879;08241;Bayern
Wiedermuth;;99713;036020;Thüringen
Wiedersbach;b Hildburghausen;98667;036878;Thüringen
Wiederstedt;;06333;034785;Sachsen-Anhalt
Wiedischhausen;;56271;;Rheinland-Pfalz
Wiedmann;;83739;08025;Bayern
Wiednitz;;02994;035723;Sachsen
Wiefelstede;;26215;04458;Niedersachsen
Wiegendorf;;99510;036462;Thüringen
Wieglitz;;39345;039058;Sachsen-Anhalt
Wiehe;, Unstruttal;06571;034672;Thüringen
Wiehl;;51674;02262;Nordrhein-Westfalen
Wiek;;18556;038391;Mecklenburg-Vorpommern
Wielen;b Neuenhaus, Dinkel;49847;05948;Niedersachsen
Wielenbach;;82407;0881;Bayern
Wiemersdorf;;24649;04192;Schlewig-Holstein
Wiemerstedt;;25779;04836;Schlewig-Holstein
Wiendorf;b Könnern;06420;034691;Sachsen-Anhalt
Wiendorf;b Bützow;18258;03844;Mecklenburg-Vorpommern
Wienhausen;;29342;05149;Niedersachsen
Wienrode;;38889;03944;Sachsen-Anhalt
Wiepersdorf;b Jüterbog;14913;033746;Brandenburg
Wiepke;;39638;039085;Sachsen-Anhalt
Wiepkenhagen;;18320;038225;Mecklenburg-Vorpommern
Wieren;;29568;05825;Niedersachsen
Wierenkamp;, Gem Lentföhrden;24640;;Schlewig-Holstein
Wiernsheim;;75446;07044;Baden-Württemberg
Wierschem;;56294;02605;Rheinland-Pfalz
Wiersdorf;, Eifel;54636;06569;Rheinland-Pfalz
Wiershop;;21502;04157;Schlewig-Holstein
Wies;, Schwarzw;79697;07629;Baden-Württemberg
Wiesa;, Erzgeb;09488;037341;Sachsen
Wiesach;b Kressbronn;88079;07543;Baden-Württemberg
Wiesau;;95676;09634;Bayern
Wiesbach;, Pfalz;66894;06337;Rheinland-Pfalz
Wiesbaden;;65183;0611;Hessen
Wiesbaden;;65185;0611;Hessen
Wiesbaden;;65187;0611;Hessen
Wiesbaden;;65189;0611;Hessen
Wiesbaden;;65191;0611;Hessen
Wiesbaden;;65193;0611;Hessen
Wiesbaden;;65195;0611;Hessen
Wiesbaden;;65197;0611;Hessen
Wiesbaden;;65199;0611;Hessen
Wiesbaden;;65201;0611;Hessen
Wiesbaden;;65203;0611;Hessen
Wiesbaden;;65205;0611;Hessen
Wiesbaden;;65207;0611;Hessen
Wiesbauer;b Kirchdorf an der Iller;88459;08395;Baden-Württemberg
Wiesbaum;;54578;06593;Rheinland-Pfalz
Wiesemscheid;;53534;02691;Rheinland-Pfalz
Wiesen;, Unterfr;63831;06096;Bayern
Wiesenau;b Frankfurt, Oder;15295;033653;Brandenburg
Wiesenbach;, Baden;69257;06223;Baden-Württemberg
Wiesenbach;, Schwab;86519;08283;Bayern
Wiesenbronn;;97355;09325;Bayern
Wiesenburg;, Mark;14827;033849;Brandenburg
Wiesenfeld;b Heilbad Heiligenstadt;37308;036082;Thüringen
Wiesenfelden;;94344;09966;Bayern
Wiesenhagen;;14943;033731;Brandenburg
Wiesenmühle;, Pfrimm;67308;06355;Rheinland-Pfalz
Wiesensteig;;73349;07335;Baden-Württemberg
Wiesent;;93109;09482;Bayern
Wiesenthal;b Bad Salzungen;36466;036964;Thüringen
Wiesenthau;;91369;09191;Bayern
Wiesentheid;;97353;09383;Bayern
Wiesenttal;, Fränk Schweiz;91346;09196;Bayern
Wieser;b Wörnsmühl;83739;08064;Bayern
Wieserode;;06543;034743;Sachsen-Anhalt
Wieseth;;91632;09822;Bayern
Wieskau;;06388;034976;Sachsen-Anhalt
Wieslauterhof;;66999;;Rheinland-Pfalz
Wieslet;;79699;07622;Baden-Württemberg
Wiesloch;;69168;06222;Baden-Württemberg
Wiesmoor;;26639;04944;Niedersachsen
Wiesthal;, Unterfr;97859;06020;Bayern
Wiesweiler;;67744;06382;Rheinland-Pfalz
Wiethagen;;18182;038202;Mecklenburg-Vorpommern
Wiethagen;;18182;038202;Mecklenburg-Vorpommern
Wietmarschen;;49835;05925;Niedersachsen
Wietow;;23966;03841;Mecklenburg-Vorpommern
Wietstock;b Ludwigsfelde;14974;03378;Brandenburg
Wietstock;b Torgelow, Vorpommern;17379;039777;Mecklenburg-Vorpommern
Wietze;;29323;05146;Niedersachsen
Wietzen;;31613;05022;Niedersachsen
Wietzendorf;;29649;05196;Niedersachsen
Wiggensbach;;87487;08370;Bayern
Wilburgstetten;;91634;09853;Bayern
Wildau;b Königs Wusterhausen;15745;033762;Brandenburg
Wildau-Wentdorf;, NL;15938;035453;Brandenburg
Wildberg;b Herzberg, Elster;04916;035362;Brandenburg
Wildberg;b Neustadt, Dosse;16845;033928;Brandenburg
Wildberg;b Altentreptow;17091;039604;Mecklenburg-Vorpommern
Wildberg;, Württ;72218;07054;Baden-Württemberg
Wildberg;b Neukirchen a Teisenberg;83364;08666;Bayern
Wildburg;;55490;;Rheinland-Pfalz
Wildeck;, Hess;36208;06626;Hessen
Wildemann;;38709;05323;Niedersachsen
Wildenberg;, Hallertau;93359;09444;Bayern
Wildenbörten;;04626;034496;Thüringen
Wildenbruch;;14552;033205;Brandenburg
Wildenbungert;;56283;;Rheinland-Pfalz
Wildenfels;b Zwickau;08134;037603;Sachsen
Wildenhain;b Großenhain, Sachs;01561;03522;Sachsen
Wildenhain;b Eilenburg;04838;034244;Sachsen
Wildenspring;;98701;036781;Thüringen
Wildenstein;, Neckar;78652;07420;Baden-Württemberg
Wildenthal;, Stadt Eibenstock;08309;037752;Sachsen
Wildeshausen;;27793;04431;Niedersachsen
Wildetaube;;07980;036625;Thüringen
Wildflecken;;97772;09745;Bayern
Wildgrube;;04924;035341;Brandenburg
Wildhof;;63150;;Hessen
Wildkuhl;;17209;039922;Mecklenburg-Vorpommern
Wildpark West;;14542;03327;Brandenburg
Wildpoldsried;;87499;08304;Bayern
Wildschütz;b Eilenburg;04838;034244;Sachsen
Wildsteig;;82409;08867;Bayern
Wilgartswiesen;;76848;06392;Rheinland-Pfalz
Wilhelmsaue;;15324;033475;Brandenburg
Wilhelmsburg;, Vorpommern;17379;039778;Mecklenburg-Vorpommern
Wilhelmsdorf;b Pößneck;07389;03647;Thüringen
Wilhelmsdorf;, Württ;88271;07503;Baden-Württemberg
Wilhelmsdorf;, Mittelfr;91489;09104;Bayern
Wilhelmsfeld;;69259;06220;Baden-Württemberg
Wilhelmshaven;;26382;04421;Niedersachsen
Wilhelmshaven;;26384;04421;Niedersachsen
Wilhelmshaven;;26386;04421;Niedersachsen
Wilhelmshaven;;26388;04421;Niedersachsen
Wilhelmshaven;;26389;04421;Niedersachsen
Wilhelmshöhe;b Eisenberg, Thür;07616;;Thüringen
Wilhelmshorst;;14557;033205;Brandenburg
Wilhelmsthal;, Oberfr;96352;09260;Bayern
Wilhermsdorf;;91452;09102;Bayern
Wilischthal;;09439;03725;Sachsen
Wilkau-Haßlau;;08112;0375;Sachsen
Wilkenhagen;;23936;03881;Mecklenburg-Vorpommern
Wilkenstorf;;19273;038845;Niedersachsen
Willanzheim;;97348;09326;Bayern
Willebadessen;;34439;05646;Nordrhein-Westfalen
Willenscharen;;24616;04324;Schlewig-Holstein
Willerstedt;;99510;036463;Thüringen
Willich;;47877;02154;Nordrhein-Westfalen
Willingen;(Upland);34508;05632;Hessen
Willingen;, Westerw;56479;02667;Rheinland-Pfalz
Willingshausen;;34628;06697;Hessen
Willmars;;97647;09779;Bayern
Willmenrod;;56459;02663;Rheinland-Pfalz
Willmering;;93497;09971;Bayern
Willmersdorf;b Bernau b Berlin;16356;033398;Brandenburg
Willmersdorf;b Ilmenau;98701;036781;Thüringen
Willmersdorf-Stöbritz;, NL;15926;035456;Brandenburg
Willroth;;56594;02687;Rheinland-Pfalz
Willstätt;;77731;07852;Baden-Württemberg
Willwerscheid;;54533;06574;Rheinland-Pfalz
Wilmeröderberg;;37647;;Niedersachsen
Wilmersdorf;b Briesen, Mark;15518;033635;Brandenburg
Wilmersdorf;b Beeskow;15848;033672;Brandenburg
Wilmersdorf;b Angermünde;16278;033334;Brandenburg
Wilmersdorf;b Pritzwalk;16928;033989;Brandenburg
Wilmshagen;b Grimmen;18519;038333;Mecklenburg-Vorpommern
Wilnsdorf;;57234;02739;Nordrhein-Westfalen
Wilsdruff;;01723;035204;Sachsen
Wilsecker;;54655;06563;Rheinland-Pfalz
Wilsleben;;06449;03473;Sachsen-Anhalt
Wilstedt;, Niedersachs;27412;04283;Niedersachsen
Wilster;;25554;04823;Schlewig-Holstein
Wilsum;b Emlichheim;49849;05945;Niedersachsen
Wilthen;;02681;03592;Sachsen
Wiltingen;;54459;06501;Rheinland-Pfalz
Wiltzsch;;09423;037297;Sachsen
Wilzenberg-Hußweiler;;55767;06787;Rheinland-Pfalz
Wilzschmühle;;08309;037752;Sachsen
Wimbach;b Adenau;53518;02691;Rheinland-Pfalz
Wimbachgrieshütte;;83486;;Bayern
Wimbachschloß;;83486;;Bayern
Wimmelburg;;06313;03475;Sachsen-Anhalt
Wimpasing;b Evenhausen, Oberbay;83552;08074;Bayern
Wimpersing;b Frauenneuharting;83565;08092;Bayern
Wimsheim;;71299;07044;Baden-Württemberg
Wincheringen;;54457;06583;Rheinland-Pfalz
Windach;, Kr Landsberg a Lech;86949;08193;Bayern
Windberg;, Niederbay;94336;09422;Bayern
Windberge;;39579;039361;Sachsen-Anhalt
Windbergen;;25729;04859;Schlewig-Holstein
Windeby;;24340;04354;Schlewig-Holstein
Windeck;, Sieg;51570;02292;Nordrhein-Westfalen
Windehausen;;99765;036333;Thüringen
Windelsbach;;91635;09867;Bayern
Winden;, Rhein-Lahn-Kr;56379;02604;Rheinland-Pfalz
Winden;, Pfalz;76872;06349;Rheinland-Pfalz
Winden;im Elztal;79297;07682;Baden-Württemberg
Winden;, Gem Hettenshausen;85304;08444;Bayern
Windesheim;;55452;06707;Rheinland-Pfalz
Windhagen;, Westerw;53578;02645;Rheinland-Pfalz
Windhausen;, Harz;37539;05327;Niedersachsen
Windischeschenbach;;92670;09681;Bayern
Windischholzhausen;;99102;0361;Thüringen
Windischholzhausen;;99102;0361;Thüringen
Windischleuba;;04603;03447;Thüringen
Windorf;, Niederbay;94575;08546;Bayern
Windsbach;;91575;09871;Bayern
Windwart;;83739;08025;Bayern
Wingerode;;37327;03605;Thüringen
Wingst;;21789;04778;Niedersachsen
Winhöring;;84543;08671;Bayern
Winkel;b Bad Liebenwerda;04924;035341;Brandenburg
Winkel;b Sangerhausen;06542;034652;Sachsen-Anhalt
Winkel;(Eifel);54558;06573;Rheinland-Pfalz
Winkel;, Gem Ummendorf;88454;07355;Baden-Württemberg
Winkelbach;;57644;02680;Rheinland-Pfalz
Winkelhaid;b Nürnberg, Mittelfr;90610;09187;Bayern
Winkelsett;;27243;04431;Niedersachsen
Winkelstedt;b Kalbe, Milde;39624;039081;Sachsen-Anhalt
Winklarn;, Oberpf;92559;09676;Bayern
Winkwitz;;01665;03521;Sachsen
Winnemark;;24398;04644;Schlewig-Holstein
Winnen;, Westerw;56459;02663;Rheinland-Pfalz
Winnenden;, Württ;71364;07195;Baden-Württemberg
Winnerath;;53520;02695;Rheinland-Pfalz
Winnerhöfe;, Hess;61130;;Hessen
Winnert;b Husum, Nordsee;25887;04845;Schlewig-Holstein
Winnigstedt;;38170;05336;Niedersachsen
Winningen;b Aschersleben, Sachs-Anh;06449;034745;Sachsen-Anhalt
Winningen;, Mosel;56333;02606;Rheinland-Pfalz
Winnweiler;;67722;06302;Rheinland-Pfalz
Winringen;;54614;06553;Rheinland-Pfalz
Winseldorf;;25551;04826;Schlewig-Holstein
Winsen;(Luhe);21423;04171;Niedersachsen
Winsen;b Neumünster;24568;04191;Schlewig-Holstein
Winsen;(Aller);29308;05143;Niedersachsen
Winterbach;, Kr Bad Kreuznach;55595;06756;Rheinland-Pfalz
Winterbach;(Pfalz);66484;06337;Rheinland-Pfalz
Winterbach;b Kaiserslautern;67737;;Rheinland-Pfalz
Winterbach;b Schorndorf, Württ;73650;07181;Baden-Württemberg
Winterbach;, Kr Günzburg;89368;09075;Bayern
Winterberg;, Westf;59955;02981;Nordrhein-Westfalen
Winterberg;, Gem Gars a Inn;83546;08073;Bayern
Winterborn;, Pfalz;67822;06362;Rheinland-Pfalz
Winterburg;;55595;06756;Rheinland-Pfalz
Winterfeld;b Salzwedel;29416;039009;Sachsen-Anhalt
Winterhausen;;97286;09333;Bayern
Winterhof;, Wetterau;61203;06035;Hessen
Winterlingen;;72474;07434;Baden-Württemberg
Winterrieden;;87785;08333;Bayern
Winterscheid;, Eifel;54608;06555;Rheinland-Pfalz
Wintersdorf;b Meuselwitz, Thür;04610;03448;Thüringen
Wintersheim;;67587;06733;Rheinland-Pfalz
Winterspelt;;54616;06555;Rheinland-Pfalz
Winterstein;, Thüringer W;99891;036259;Thüringen
Winterwerb;;56355;06776;Rheinland-Pfalz
Wintrich;;54487;06534;Rheinland-Pfalz
Wintzingerode;;37339;036074;Thüringen
Winzenburg;;31088;05184;Niedersachsen
Winzer;, Donau;94577;09901;Bayern
Wipersdorf;;19412;0385;Mecklenburg-Vorpommern
Wipfeld;;97537;09385;Bayern
Wipfratal;;99310;03628;Thüringen
Wipperdorf;;99752;036338;Thüringen
Wipperfürth;;51688;02267;Nordrhein-Westfalen
Wippingen;, Hümmling;26892;04966;Niedersachsen
Wippra;;06543;034775;Sachsen-Anhalt
Wirchensee Försterei;;15868;;Brandenburg
Wirdum;, Ostfriesl;26529;04920;Niedersachsen
Wirft;;53534;02691;Rheinland-Pfalz
Wirfus;;56814;02653;Rheinland-Pfalz
Wirges;;56422;02602;Rheinland-Pfalz
Wirsberg;;95339;09227;Bayern
Wirscheid;;56237;02601;Rheinland-Pfalz
Wirschweiler;;55758;06786;Rheinland-Pfalz
Wisch;b Kiel;24217;04344;Schlewig-Holstein
Wisch;, Nordfriesl;25876;04884;Schlewig-Holstein
Wischhafen;;21737;04770;Niedersachsen
Wischroda;;06647;034467;Sachsen-Anhalt
Wischuer;;18230;038292;Mecklenburg-Vorpommern
Wischuer Abbau;;18230;038292;Mecklenburg-Vorpommern
Wismar;, Meckl;23966;03841;Mecklenburg-Vorpommern
Wismar;, Meckl;23968;03841;Mecklenburg-Vorpommern
Wismar;, Meckl;23970;03841;Mecklenburg-Vorpommern
Wissen;, Sieg;57537;02742;Rheinland-Pfalz
Wißmannsdorf;;54636;06527;Rheinland-Pfalz
Wistedt;, Nordheide;21255;04182;Niedersachsen
Witsum;;25938;04683;Schlewig-Holstein
Wittbeck;;29229;05086;Niedersachsen
Wittbek;b Husum, Nordsee;25872;04845;Schlewig-Holstein
Wittbrietzen;;14547;033204;Brandenburg
Wittchenstein;;07819;036482;Thüringen
Wittdün;, Amrum;25946;04682;Schlewig-Holstein
Wittelshofen;;91749;09854;Bayern
Witten;;58452;02302;Nordrhein-Westfalen
Witten;;58453;02302;Nordrhein-Westfalen
Witten;;58454;02302;Nordrhein-Westfalen
Witten;;58455;02302;Nordrhein-Westfalen
Witten;;58456;02302;Nordrhein-Westfalen
Wittenbeck;;18209;038293;Mecklenburg-Vorpommern
Wittenberge;, Prignitz;19322;03877;Brandenburg
Wittenbergen;b Kellinghusen;25548;04822;Schlewig-Holstein
Wittenborn;, Meckl;17337;039607;Mecklenburg-Vorpommern
Wittenborn;, Kr Segeberg;23829;04554;Schlewig-Holstein
Wittenburg;, Meckl;19243;038852;Mecklenburg-Vorpommern
Wittenförden;;19073;0385;Mecklenburg-Vorpommern
Wittenhagen;b Grimmen;18510;038327;Mecklenburg-Vorpommern
Wittenmoor;b Stendal;39579;039325;Sachsen-Anhalt
Witterda;;99189;036201;Thüringen
Wittgendorf;b Zeitz, Elster;06712;034423;Sachsen-Anhalt
Wittgendorf;b Saalfeld, Saale;07318;036736;Thüringen
Wittgensdorf;b Chemnitz, Sachs;09228;037200;Sachsen
Wittgensdorf;b Chemnitz, Sachs;09228;037200;Sachsen
Wittgert;;56237;02623;Rheinland-Pfalz
Wittibreut;;84384;08574;Bayern
Wittichenau;;02997;035725;Sachsen
Wittighausen;, Baden;97957;09347;Baden-Württemberg
Wittingen;, Niedersachs;29378;05831;Niedersachsen
Wittingen;, Niedersachs;29379;05831;Niedersachsen
Wittislingen;;89426;09076;Bayern
Wittlich;;54516;06571;Rheinland-Pfalz
Wittlingen;, Baden;79599;07621;Baden-Württemberg
Wittmannsdorf-Bückchen;;15910;035476;Brandenburg
Wittmannsgereuth;;07318;03671;Thüringen
Wittmar;;38329;05337;Niedersachsen
Wittmoldt;;24306;04522;Schlewig-Holstein
Wittmoor;;19357;038783;Brandenburg
Wittmund;;26409;04462;Niedersachsen
Wittnau;, Breisgau;79299;0761;Baden-Württemberg
Wittorf;, Kr Lüneburg;21357;04133;Niedersachsen
Wittstock;, Dosse;16909;03394;Brandenburg
Witzeeze;;21514;04155;Schlewig-Holstein
Witzendorf;;07318;036736;Thüringen
Witzenhausen;;37213;05542;Hessen
Witzenhausen;;37214;05542;Hessen
Witzenhausen;;37215;05542;Hessen
Witzenhausen;;37216;05542;Hessen
Witzenhausen;;37217;05542;Hessen
Witzenhausen;;37218;05542;Hessen
Witzhave;;22969;04104;Schlewig-Holstein
Witzin;;19406;038481;Mecklenburg-Vorpommern
Witzke;;14715;033872;Brandenburg
Witzleben;;99310;036200;Thüringen
Witzmannsberg;b Tittling;94104;08504;Bayern
Witzschdorf;;09437;03725;Sachsen
Witzwort;;25889;04864;Schlewig-Holstein
Wöbbelin;;19288;038753;Mecklenburg-Vorpommern
Wobbenbüll;;25856;04846;Schlewig-Holstein
Wochowsee;;15859;033678;Brandenburg
Woddow;;17326;039742;Brandenburg
Wodenhof;;19073;0385;Mecklenburg-Vorpommern
Woez;;19243;038853;Mecklenburg-Vorpommern
Woffleben;;99762;036332;Thüringen
Woggersin;;17039;0395;Mecklenburg-Vorpommern
Wohlbach;b Adorf, Vogtl;08626;037464;Sachsen
Wohlde;b Schleswig;24899;04885;Schlewig-Holstein
Wohlenberg;b Grevesmühlen;23948;038825;Mecklenburg-Vorpommern
Wohlenhagen;;23948;038428;Mecklenburg-Vorpommern
Wohlmirstedt;;06642;0361;Sachsen-Anhalt
Wohlmuthausen;b Meiningen;98617;036943;Thüringen
Wohlsborn;;99439;03643;Thüringen
Wohlsdorf;b Bernburg;06408;034722;Sachsen-Anhalt
Wöhlsdorf;b Saalfeld, Saale;07318;03671;Thüringen
Wöhlsdorf;b Pößneck;07389;03647;Thüringen
Wohltorf;;21521;04104;Schlewig-Holstein
Wohnroth;;56288;06762;Rheinland-Pfalz
Wohnste;;27419;04169;Niedersachsen
Wohratal;;35288;06453;Hessen
Wöhrden;, Dithm;25797;04839;Schlewig-Holstein
Woitendorf;;19217;038873;Mecklenburg-Vorpommern
Wokrent;;18246;038466;Mecklenburg-Vorpommern
Wokuhl-Dabelow;;17237;;Mecklenburg-Vorpommern
Woland;b Sternberg;19406;038485;Mecklenburg-Vorpommern
Wolde;b Altentreptow;17091;0395;Mecklenburg-Vorpommern
Wolde;b Wismar, Meckl;23968;03841;Mecklenburg-Vorpommern
Woldegk;;17348;03963;Mecklenburg-Vorpommern
Woldert;;57614;02684;Rheinland-Pfalz
Wolfach;;77709;07834;Baden-Württemberg
Wolfegg;, Allgäu;88364;07527;Baden-Württemberg
Wolfen;, Anh;06766;03494;Sachsen-Anhalt
Wolfenbüttel;, Niedersachs;38300;05331;Niedersachsen
Wolfenbüttel;, Niedersachs;38302;05331;Niedersachsen
Wolfenbüttel;, Niedersachs;38304;05331;Niedersachsen
Wölferbütt;;36404;036965;Thüringen
Wölferlingen;;56244;02666;Rheinland-Pfalz
Wolferode;b Lutherstadt Eisleben;06295;03475;Sachsen-Anhalt
Wolferschwenda;;99713;036370;Thüringen
Wolfersdorf;, Oberbay;85395;08168;Bayern
Wölfershausen;b Meiningen;98617;036947;Thüringen
Wölfersheim;;61200;06036;Hessen
Wolferstadt;;86709;09092;Bayern
Wolferstedt;;06542;034652;Sachsen-Anhalt
Wolfertschwenden;;87787;08334;Bayern
Wolfhagen;;34466;05692;Hessen
Wölfis;;99885;03624;Thüringen
Wolfmannshausen;;98631;036944;Thüringen
Wolfner Mühle;;08352;03774;Sachsen
Wolframs-Eschenbach;;91639;09875;Bayern
Wolfratshausen;;82515;08171;Bayern
Wolfsberg;b Sangerhausen;06536;036785;Sachsen-Anhalt
Wolfsbühl;, Schwarzw;78730;;Baden-Württemberg
Wolfsburg;;38440;05361;Niedersachsen
Wolfsburg;;38442;05361;Niedersachsen
Wolfsburg;;38444;05361;Niedersachsen
Wolfsburg;;38446;05361;Niedersachsen
Wolfsburg;;38448;05361;Niedersachsen
Wolfsburg-Unkeroda;;99819;036925;Thüringen
Wolfschlugen;;72649;07022;Baden-Württemberg
Wolfseck, Forsthaus;b Burrweiler;76835;;Rheinland-Pfalz
Wolfsegg;, Oberpf;93195;09409;Bayern
Wolfsgrün;, Stadt Eibenstock;08318;037752;Sachsen
Wolfsgrün;b Freiberg, Sachs;09629;;Sachsen
Wolfsgrund;;09619;;Sachsen
Wolfshagen;b Perleberg;19348;038789;Brandenburg
Wolfshain;, NL;03130;035600;Brandenburg
Wolfsheim;;55578;06701;Rheinland-Pfalz
Wolfsmühle;b Rockenhausen;67806;06364;Rheinland-Pfalz
Wolfsruh;;16775;033083;Brandenburg
Wolfstein;, Pfalz;67752;06304;Rheinland-Pfalz
Wolgast;;17438;03836;Mecklenburg-Vorpommern
Wolkau;;01683;035242;Sachsen
Wolken;b Koblenz am Rhein;56332;02607;Rheinland-Pfalz
Wolkenburg-Kaufungen;;08399;0371;Sachsen
Wolkenstein;, Sachs;09429;037369;Sachsen
Wolkramshausen;;99735;036334;Thüringen
Wollbach;b Bad Neustadt a d Saale;97618;09773;Bayern
Wollbrandshausen;;37434;05528;Niedersachsen
Wollershausen;;37434;05528;Niedersachsen
Wöllersheim;;31195;05183;Niedersachsen
Wollersleben;;99735;036334;Thüringen
Wolletz;;16278;033337;Brandenburg
Wollin;b Brandenburg an der Havel;14778;033833;Brandenburg
Wollin;b Prenzlau;17291;039862;Brandenburg
Wollin;, Vorpommern;17328;039751;Mecklenburg-Vorpommern
Wollmerath;;56826;02677;Rheinland-Pfalz
Wöllnau;;04838;034244;Sachsen
Wollschow;;17326;039742;Brandenburg
Wöllstadt;;61206;06034;Hessen
Wöllstein;, Rheinhess;55597;06703;Rheinland-Pfalz
Wolmersdorf;;25704;04832;Schlewig-Holstein
Wölmersen;;57635;02681;Rheinland-Pfalz
Wolmirsleben;;39435;039268;Sachsen-Anhalt
Wolmirstedt;;39326;039201;Sachsen-Anhalt
Wölmsdorf;;14913;033741;Brandenburg
Wolnzach;;85283;08442;Bayern
Wolpertshausen;;74549;07904;Baden-Württemberg
Wolpertswende;;88284;07502;Baden-Württemberg
Wölpinghausen;;31556;05037;Niedersachsen
Wölschendorf;;23936;03881;Mecklenburg-Vorpommern
Wolsdorf;, Kr Helmstedt;38379;05355;Niedersachsen
Wolsfeld;;54636;06568;Rheinland-Pfalz
Wölsickendorf-Wollenberg;;16259;033454;Brandenburg
Wolsier;;14715;033875;Brandenburg
Woltersdorf;b Brandenburg an der Havel;14789;033839;Brandenburg
Woltersdorf;b Erkner;15569;03362;Brandenburg
Woltersdorf;b Angermünde;16306;033331;Brandenburg
Woltersdorf;, Kr Hzgt Lauenb;21516;04542;Schlewig-Holstein
Woltersdorf;, Niedersachs;29497;05841;Niedersachsen
Woltersdorf;b Magdeburg;39175;039292;Sachsen-Anhalt
Woltershausen;;31099;05183;Niedersachsen
Wolzig;;15754;033767;Brandenburg
Womrath;;55481;06763;Rheinland-Pfalz
Wonfurt;;97539;09521;Bayern
Wonneberg;;83329;08681;Bayern
Wonsees;;96197;09274;Bayern
Wonsheim;;55599;06703;Rheinland-Pfalz
Woosmer;;19303;038759;Mecklenburg-Vorpommern
Wootz;;19309;038792;Brandenburg
Woppenroth;;55490;06544;Rheinland-Pfalz
Worbis;;37339;036074;Thüringen
Wörblitz;;04880;034223;Sachsen
Wörbzig;;06369;034976;Sachsen-Anhalt
Worin;;15306;033477;Brandenburg
Woringen;;87789;08331;Bayern
Wörlitz;;06786;034905;Sachsen-Anhalt
Wormlage;;01994;035329;Brandenburg
Wörmlitz;, Jerichower Land;39291;039224;Sachsen-Anhalt
Worms;;67547;06241;Rheinland-Pfalz
Worms;;67549;06241;Rheinland-Pfalz
Worms;;67550;06241;Rheinland-Pfalz
Worms;;67551;06241;Rheinland-Pfalz
Wormsdorf;;39365;039409;Sachsen-Anhalt
Wormshöft;, Gem Maasholm;24376;;Schlewig-Holstein
Wormstedt;;99510;036464;Thüringen
Wörnersberg;;72299;07453;Baden-Württemberg
Wörnitz;;91637;09868;Bayern
Wörnsmühl;;83739;08028;Bayern
Wörpen;;06869;034903;Sachsen-Anhalt
Worpswede;;27726;04792;Niedersachsen
Wörrstadt;;55286;06732;Rheinland-Pfalz
Wört;, Württ;73499;07964;Baden-Württemberg
Worth;b Geesthacht;21502;04152;Schlewig-Holstein
Wörth;a. Main;63939;09372;Bayern
Wörth;am Rhein;76744;07271;Rheinland-Pfalz
Wörth;, Gem Gars a Inn;83546;08073;Bayern
Wörth;a d Isar;84109;08702;Bayern
Wörth;, Kr Erding;85457;08122;Bayern
Wörth;a d Donau;93086;09482;Bayern
Wörthsee;;82237;08153;Bayern
Woschkow;;01983;035753;Brandenburg
Woserin;;19406;038485;Mecklenburg-Vorpommern
Wotenick;;17111;03998;Mecklenburg-Vorpommern
Wotenitz;;23936;03881;Mecklenburg-Vorpommern
Wotersen;;21514;04158;Schlewig-Holstein
Wrangelsburg;;17495;038355;Mecklenburg-Vorpommern
Wredenhagen;;17209;039925;Mecklenburg-Vorpommern
Wremen;;27638;04705;Niedersachsen
Wrestedt;;29559;05802;Niedersachsen
Wriedel;;29565;05829;Niedersachsen
Wriezen;;16269;033456;Brandenburg
Wriezener Höhe;;16269;033456;Brandenburg
Wrist;;25563;04822;Schlewig-Holstein
Wrixum;;25938;04681;Schlewig-Holstein
Wrodow;;17091;039602;Mecklenburg-Vorpommern
Wrohm;, Dithm;25799;04802;Schlewig-Holstein
Wucker;;16247;;Brandenburg
Wulfen;b Köthen, Anh;06369;034979;Sachsen-Anhalt
Wulfenhusen;;25764;;Schlewig-Holstein
Wulfersdorf;b Wittstock, Dosse;16909;033963;Brandenburg
Wülfershausen;a d Saale;97618;09762;Bayern
Wulferstedt;;39387;039401;Sachsen-Anhalt
Wülfrath;;42489;02058;Nordrhein-Westfalen
Wulfsen;;21445;04173;Niedersachsen
Wulfshof;;23777;04365;Schlewig-Holstein
Wulfskuhl;b Wittenburg, Meckl;19243;038852;Mecklenburg-Vorpommern
Wulfsmoor;;25563;04822;Schlewig-Holstein
Wulften;, Harz;37199;05556;Niedersachsen
Wulkau;;39524;039383;Sachsen-Anhalt
Wulkenzin;;17039;0395;Mecklenburg-Vorpommern
Wülknitz;b Riesa;01609;035263;Sachsen
Wülknitz;b Köthen, Anh;06369;03496;Sachsen-Anhalt
Wulkow;b Seelow;15320;033476;Brandenburg
Wulkow;b Frankfurt, Oder;15326;033602;Brandenburg
Wulkow;b Neuruppin;16835;03391;Brandenburg
Wulkow;b Genthin;39319;039341;Sachsen-Anhalt
Wülperode;;38835;039421;Sachsen-Anhalt
Wulsbüttel;;27628;04746;Niedersachsen
Wümbach;;98704;036785;Thüringen
Wunder;, Gem Reisbach;84163;;Bayern
Wunderfeld;;19395;;Mecklenburg-Vorpommern
Wundersleben;;99610;036376;Thüringen
Wünsch;;06255;034636;Sachsen-Anhalt
Wünschbach;;64753;;Hessen
Wünschendorf;/Elster;07570;036603;Thüringen
Wünsdorf;;15838;033702;Brandenburg
Wunsiedel;;95632;09232;Bayern
Wunstorf;;31515;05031;Niedersachsen
Wuppertal;;42103;0202;Nordrhein-Westfalen
Wuppertal;;42105;0202;Nordrhein-Westfalen
Wuppertal;;42107;0202;Nordrhein-Westfalen
Wuppertal;;42109;0202;Nordrhein-Westfalen
Wuppertal;;42111;0202;Nordrhein-Westfalen
Wuppertal;;42113;0202;Nordrhein-Westfalen
Wuppertal;;42115;0202;Nordrhein-Westfalen
Wuppertal;;42117;0202;Nordrhein-Westfalen
Wuppertal;;42119;0202;Nordrhein-Westfalen
Wuppertal;;42275;0202;Nordrhein-Westfalen
Wuppertal;;42277;0202;Nordrhein-Westfalen
Wuppertal;;42279;0202;Nordrhein-Westfalen
Wuppertal;;42281;0202;Nordrhein-Westfalen
Wuppertal;;42283;0202;Nordrhein-Westfalen
Wuppertal;;42285;0202;Nordrhein-Westfalen
Wuppertal;;42287;0202;Nordrhein-Westfalen
Wuppertal;;42289;0202;Nordrhein-Westfalen
Wuppertal;;42327;0202;Nordrhein-Westfalen
Wuppertal;;42329;0202;Nordrhein-Westfalen
Wuppertal;;42349;0202;Nordrhein-Westfalen
Wuppertal;;42369;0202;Nordrhein-Westfalen
Wuppertal;;42389;0202;Nordrhein-Westfalen
Wuppertal;;42399;0202;Nordrhein-Westfalen
Würchwitz;;06712;034426;Sachsen-Anhalt
Wurmannsquick;;84329;08725;Bayern
Wurmberg;, Württ;75449;07044;Baden-Württemberg
Wurmlingen;b Tuttlingen;78573;07461;Baden-Württemberg
Wurmsham;;84189;08745;Bayern
Würrich;;56858;06543;Rheinland-Pfalz
Würschhauserhof;;66917;06375;Rheinland-Pfalz
Würschhausermühle;;66917;06375;Rheinland-Pfalz
Würschnitz;;01561;035240;Sachsen
Würselen;;52146;02405;Nordrhein-Westfalen
Wurzbach;;07343;036652;Thüringen
Würzburg;;97070;0931;Bayern
Würzburg;;97072;0931;Bayern
Würzburg;;97074;0931;Bayern
Würzburg;;97076;0931;Bayern
Würzburg;;97078;0931;Bayern
Würzburg;;97080;0931;Bayern
Würzburg;;97082;0931;Bayern
Würzburg;;97084;0931;Bayern
Wurzen;;04808;03425;Sachsen
Würzweiler;;67808;06361;Rheinland-Pfalz
Wüschheim;, Hunsrück;55471;06761;Rheinland-Pfalz
Wußwerk;;15913;035475;Brandenburg
Wust;b Brandenburg an der Havel;14778;033835;Brandenburg
Wust;b Schönhausen, Elbe;39524;039323;Sachsen-Anhalt
Wüsten-Buchholz;;19348;03876;Brandenburg
Wüstenfelde;b Loitz b Demmin;17121;039998;Mecklenburg-Vorpommern
Wüstenjerichow;;39291;039225;Sachsen-Anhalt
Wüstenmark;;23936;038871;Mecklenburg-Vorpommern
Wüstenrot;;71543;07945;Baden-Württemberg
Wüstenwetzdorf;;07819;036482;Thüringen
Wüsteratherhof;;56729;;Rheinland-Pfalz
Wusterhausen;;16868;033979;Brandenburg
Wusterhusen;;17509;038354;Mecklenburg-Vorpommern
Wustermark;;14641;033234;Brandenburg
Wüstermarke;;15926;035454;Brandenburg
Wusterwitz;;14789;033839;Brandenburg
Wüstheuterode;;37318;036087;Thüringen
Wustrau;;16818;033925;Brandenburg
Wustrow;b Wesenberg, Meckl;17255;039828;Mecklenburg-Vorpommern
Wustrow;b Ludwigslust;19309;038780;Brandenburg
Wustrow;(Wendland);29462;05843;Niedersachsen
Wutach;;79879;07709;Baden-Württemberg
Wutha-Farnroda;;99848;036921;Thüringen
Wuthenow;;16818;03391;Brandenburg
Wutike;;16866;033976;Brandenburg
Wutöschingen;;79793;07746;Baden-Württemberg
Wutzetz;;14662;033235;Brandenburg
Wutzow;;14828;033847;Brandenburg
Wyhl;am Kaiserstuhl;79369;07642;Baden-Württemberg
Wyhratal;;04552;03433;Sachsen
Wyk;auf Föhr;25938;04681;Schlewig-Holstein
Wymeer;;26831;04903;Niedersachsen
Xanten;;46509;02801;Nordrhein-Westfalen
Zaatzke;;16909;03394;Brandenburg
Zabakuck;;39307;039348;Sachsen-Anhalt
Zabelsdorf;;16775;033080;Brandenburg
Zabeltitz;;01561;03522;Sachsen
Zabenstedt;;06347;034783;Sachsen-Anhalt
Zaberfeld;;74374;07046;Baden-Württemberg
Zabitz;b Köthen, Anh;06369;034979;Sachsen-Anhalt
Zachenberg;;94239;09929;Bayern
Zachow;b Nauen;14669;033233;Brandenburg
Zäckericker Loose;;16259;033457;Brandenburg
Zadelsdorf;;07937;036628;Thüringen
Zahna;;06895;034924;Sachsen-Anhalt
Zahrensdorf;b Boizenburg;19258;038847;Mecklenburg-Vorpommern
Zahrensdorf;b Sternberg;19412;038483;Mecklenburg-Vorpommern
Zaisenhausen;, Baden;75059;07258;Baden-Württemberg
Zandt;, Oberpf;93499;09944;Bayern
Zangberg;;84539;08636;Bayern
Zangenberg;;06712;03441;Sachsen-Anhalt
Zanken;;73453;;Baden-Württemberg
Zapel;b Crivitz;19089;03863;Mecklenburg-Vorpommern
Zapel;b Hagenow;19230;03883;Mecklenburg-Vorpommern
Zapel;, Westprignitz;19357;038781;Brandenburg
Zapfendorf;;96199;09547;Bayern
Zappendorf;;06179;034601;Sachsen-Anhalt
Zarfzow;;18233;038297;Mecklenburg-Vorpommern
Zarnekow;b Malchin;17159;039959;Mecklenburg-Vorpommern
Zarnewanz;;18195;038332;Mecklenburg-Vorpommern
Zarnewenz;;23923;038823;Mecklenburg-Vorpommern
Zarpen;;23619;04533;Schlewig-Holstein
Zarrendorf;;18510;038327;Mecklenburg-Vorpommern
Zarrentin;, Meckl;19246;038851;Mecklenburg-Vorpommern
Zartwitz;;17237;039829;Mecklenburg-Vorpommern
Zaschendorf;b Sternberg;19412;038486;Mecklenburg-Vorpommern
Zaschwitz;b Grimma;04668;034385;Sachsen
Zauchwitz;;14547;033204;Brandenburg
Zaue;;15913;035478;Brandenburg
Zaunröden;;99713;036029;Thüringen
Zechenhaus;, Rhein-Lahn-Kr;56379;;Rheinland-Pfalz
Zechin;;15328;033473;Brandenburg
Zechlinerhütte;;16831;033921;Brandenburg
Zechow;b Rheinsberg;16831;033931;Brandenburg
Zeckerin;;03249;035323;Brandenburg
Zeddenick;;39291;039221;Sachsen-Anhalt
Zedlitz;;07557;036603;Thüringen
Zeesen;;15711;03375;Brandenburg
Zeestow;;14641;033234;Brandenburg
Zeetze;b Neuhaus, Elbe;19273;038845;Niedersachsen
Zegastmühle;;95356;09255;Bayern
Zehbitz;;06369;034978;Sachsen-Anhalt
Zehdenick;;16792;03307;Brandenburg
Zehlendorf;b Oranienburg;16515;033053;Brandenburg
Zehmen;;23936;038828;Mecklenburg-Vorpommern
Zehna;;18276;038458;Mecklenburg-Vorpommern
Zehnhausen;b. Wallmerod;56414;06435;Rheinland-Pfalz
Zehnhausen;bei Rennerod;56477;02664;Rheinland-Pfalz
Zehntweg;, Niederelbe;21734;;Niedersachsen
Zehren;b Meißen, Sachs;01665;035247;Sachsen
Zeil;a. Main;97475;09524;Bayern
Zeilarn;;84367;08572;Bayern
Zeiler;b Sinzing;93152;09404;Bayern
Zeischa;;04924;035341;Brandenburg
Zeiskam;;67378;06347;Rheinland-Pfalz
Zeithain;;01619;035264;Sachsen
Zeitlarn;b Regensburg;93197;0941;Bayern
Zeitlofs;;97799;09746;Bayern
Zeitz;, Elster;06712;03441;Sachsen-Anhalt
Zell;(Mosel);56856;06542;Rheinland-Pfalz
Zell;unter Aichelberg;73119;07164;Baden-Württemberg
Zell;am Harmersbach;77736;07835;Baden-Württemberg
Zell;im Wiesental;79669;07625;Baden-Württemberg
Zell;, Kr München;82067;08178;Bayern
Zell;b Lauter, Oberbay;83362;08681;Bayern
Zell;, Oberpf;93199;09468;Bayern
Zell;, Oberfr;95239;09257;Bayern
Zell;a. Main;97299;0931;Bayern
Zella;b Pößneck;07381;03647;Thüringen
Zella;b Bad Salzungen;36452;036964;Thüringen
Zella;b Mühlhausen, Thür;99976;036023;Thüringen
Zella-Mehlis;;98544;03682;Thüringen
Zellendorf;;14913;033742;Brandenburg
Zellertal;, Pfalz;67308;06355;Rheinland-Pfalz
Zellingen;;97225;09364;Bayern
Zeltingen, Forsthaus;b Ürzig, Mosel;54539;;Rheinland-Pfalz
Zeltingen-Rachtig;;54492;06532;Rheinland-Pfalz
Zembschen;;06679;034441;Sachsen-Anhalt
Zemitz;;17440;038374;Mecklenburg-Vorpommern
Zemmer;;54313;06580;Rheinland-Pfalz
Zemnick;;06918;035387;Sachsen-Anhalt
Zempin;;17459;038377;Mecklenburg-Vorpommern
Zempow;;16837;033923;Brandenburg
Zendscheid;;54655;06563;Rheinland-Pfalz
Zens;;39221;039291;Sachsen-Anhalt
Zenting;;94579;09907;Bayern
Zepelin;;18246;038461;Mecklenburg-Vorpommern
Zepernick;;16341;030;Brandenburg
Zepkow;;17209;039925;Mecklenburg-Vorpommern
Zeppernick;;39279;039245;Sachsen-Anhalt
Zepzig;;06408;;Sachsen-Anhalt
Zerben;;39317;039344;Sachsen-Anhalt
Zerbst;;39261;03923;Sachsen-Anhalt
Zerf;;54314;06587;Rheinland-Pfalz
Zermützel;;16827;03391;Brandenburg
Zernien;, Niedersachs;29499;05863;Niedersachsen
Zernikow;b Gransee;16775;033082;Brandenburg
Zernikow;b Wittenberge;19339;038787;Brandenburg
Zernin;;18249;038462;Mecklenburg-Vorpommern
Zernitz;b Neustadt, Dosse;16845;033973;Brandenburg
Zernitz;b Zerbst;39264;03923;Sachsen-Anhalt
Zernsdorf;;15758;03375;Brandenburg
Zerpenschleuse;;16348;033395;Brandenburg
Zerre;;02979;03563;Sachsen
Zerrenthin;;17309;039743;Mecklenburg-Vorpommern
Zesch am See;;15838;033704;Brandenburg
Zeschdorf;;15326;033602;Brandenburg
Zetel;;26340;04453;Niedersachsen
Zethau;;09619;037320;Sachsen
Zethlingen;;39624;039009;Sachsen-Anhalt
Zetta;;01665;035244;Sachsen
Zettemin;;17153;039951;Mecklenburg-Vorpommern
Zettingen;;56761;02653;Rheinland-Pfalz
Zettlitz;b Rochlitz;09306;03737;Sachsen
Zeuchfeld;;06632;034464;Sachsen-Anhalt
Zeuden;;14913;033747;Brandenburg
Zeughaus;b Sebnitz;01855;;Sachsen
Zeulenroda;;07937;036628;Thüringen
Zeunitz;;04668;03437;Sachsen
Zeuthen;;15738;033762;Brandenburg
Zeutsch;;07407;036742;Thüringen
Zeven;;27404;04281;Niedersachsen
Zichow;;16306;039861;Brandenburg
Zichtau;;39638;039085;Sachsen-Anhalt
Zichtow;b Wittenberge;19339;038787;Brandenburg
Zickeritz;;06420;034691;Sachsen-Anhalt
Zickhusen;;19069;0385;Mecklenburg-Vorpommern
Zieckau;;15926;035453;Brandenburg
Ziegelheim;;04618;037608;Thüringen
Ziegelhof;b Friedrichstadt, Eider;25840;04881;Schlewig-Holstein
Ziegelhütte;b Dellfeld;66503;06336;Rheinland-Pfalz
Ziegelhütte;b Birkweiler;76831;;Rheinland-Pfalz
Ziegelroda;;06268;034771;Sachsen-Anhalt
Ziegendorf;;19372;038721;Mecklenburg-Vorpommern
Ziegenhain;b Lommatzsch;01623;035246;Sachsen
Ziegenhain;, Westerw;57632;02685;Rheinland-Pfalz
Ziegenrück;, Thür;07924;036483;Thüringen
Zieglhaus;b Wörnsmühl;83739;08025;Bayern
Zieglstadl;b Stein a d Traun;83371;08621;Bayern
Ziegra-Knobelsdorf;;04720;03431;Sachsen
Zieko;;06869;034903;Sachsen-Anhalt
Zielitz;;39326;039208;Sachsen-Anhalt
Ziemendorf;;39619;039384;Sachsen-Anhalt
Ziemetshausen;;86473;08284;Bayern
Ziemkendorf;;17291;039857;Brandenburg
Ziepel;b Burg b Magdeburg;39291;039224;Sachsen-Anhalt
Zierenberg;;34289;05606;Hessen
Zierow;;23968;038428;Mecklenburg-Vorpommern
Ziertheim;;89446;09076;Bayern
Zierzow;b Röbel;17207;039931;Mecklenburg-Vorpommern
Zierzow;b Ludwigslust;19300;038752;Mecklenburg-Vorpommern
Ziesar;;14793;033830;Brandenburg
Ziesendorf;;18059;038207;Mecklenburg-Vorpommern
Zieslübbe;;19374;038728;Mecklenburg-Vorpommern
Ziethen;b Anklam;17390;03971;Mecklenburg-Vorpommern
Ziethen;b Ratzeburg;23911;04541;Schlewig-Holstein
Ziethenhorst;;16818;033925;Brandenburg
Ziezow;;14806;033843;Brandenburg
Zillbeck;;39340;039059;Sachsen-Anhalt
Zilly;;38835;039458;Sachsen-Anhalt
Zilshausen;;56288;06762;Rheinland-Pfalz
Ziltendorf;;15295;033653;Brandenburg
Zimmern;b Jena;07778;036427;Thüringen
Zimmern;unter der Burg;72369;07427;Baden-Württemberg
Zimmern;ob Rottweil;78658;0741;Baden-Württemberg
Zimmern;b Bad Langensalza;99947;03603;Thüringen
Zimmernsupra;;99100;036208;Thüringen
Zimmerschied;;56379;02608;Rheinland-Pfalz
Zingst;, Ostseebad;18374;038232;Mecklenburg-Vorpommern
Zinkweilerhof;;55776;06783;Rheinland-Pfalz
Zinna;;04860;03421;Sachsen
Zinndorf;;15345;033435;Brandenburg
Zinnitz;;03205;035439;Brandenburg
Zinnowitz;, Ostseebad;17454;038377;Mecklenburg-Vorpommern
Zinsbachmühle;;72299;07445;Baden-Württemberg
Zippelsförde;;16827;033933;Brandenburg
Zirchow;;17419;038376;Mecklenburg-Vorpommern
Zirkow;;18528;038393;Mecklenburg-Vorpommern
Zirndorf;, Mittelfr;90513;0911;Bayern
Zirtow;;17252;039833;Mecklenburg-Vorpommern
Zirzow;;17039;0395;Mecklenburg-Vorpommern
Zislow;;17209;039924;Mecklenburg-Vorpommern
Zittau;;02763;03583;Sachsen
Zitters;;36419;036967;Thüringen
Zittow;;19067;03866;Mecklenburg-Vorpommern
Zixdorf;;14823;033843;Brandenburg
Zobbenitz;;39638;039056;Sachsen-Anhalt
Zobersdorf;;04924;035341;Brandenburg
Zobes;;08541;03741;Sachsen
Zöblitz;;09517;037363;Sachsen
Zölkow;;19374;038723;Mecklenburg-Vorpommern
Zollchow;b Rathenow;14715;033870;Brandenburg
Zolling;, Oberbay;85406;08167;Bayern
Zöllmersdorf;;15926;03544;Brandenburg
Zöllnitz;b Jena;07751;03641;Thüringen
Zollwitz;;09306;034381;Sachsen
Zootzen;b Gransee;16798;033093;Brandenburg
Zootzen;b Wittstock, Dosse;16909;03394;Brandenburg
Zootzen Damm;;14662;03391;Brandenburg
Zorbau;;06679;034441;Sachsen-Anhalt
Zörbig;;06780;034956;Sachsen-Anhalt
Zorge;;37449;05586;Niedersachsen
Zorneding;;85604;08106;Bayern
Zornheim;;55270;06136;Rheinland-Pfalz
Zörnigall;;06888;034922;Sachsen-Anhalt
Zöschen;;06254;034638;Sachsen-Anhalt
Zöschingen;;89447;09077;Bayern
Zossen;b Berlin;15806;03377;Brandenburg
Zotzenheim;;55576;06701;Rheinland-Pfalz
Zschachenmühle;;07343;036652;Thüringen
Zschadraß;;04680;034381;Sachsen
Zschaitz-Ottewig;;04720;034324;Sachsen
Zschepen;;04509;034202;Sachsen
Zschepplin;;04838;034242;Sachsen
Zscherben;b Halle, Saale;06179;0345;Sachsen-Anhalt
Zscherndorf;;06794;03493;Sachsen-Anhalt
Zschirla;;09306;034381;Sachsen
Zschopau;;09405;03725;Sachsen
Zschoppach;;04668;034386;Sachsen
Zschorlau;;08321;03771;Sachsen
Zschorna;b Großenhain, Sachs;01561;035208;Sachsen
Zschornewitz;;06791;034953;Sachsen-Anhalt
Zschortau;;04509;034202;Sachsen
Zuchau;;39240;039295;Sachsen-Anhalt
Zuckerfabrik Vitzenburg;;06642;;Sachsen-Anhalt
Zudar;;18574;038304;Mecklenburg-Vorpommern
Zuflucht;, Gem Bad Peterstal-Griesbach;72250;07804;Baden-Württemberg
Zühlen;b Neuruppin;16831;033931;Brandenburg
Zühlsdorf;;16515;033397;Brandenburg
Zühr;;19243;038848;Mecklenburg-Vorpommern
Züllsdorf;;04895;035363;Brandenburg
Zülow;b Schwerin, Meckl;19073;03869;Mecklenburg-Vorpommern
Zülow;b Sternberg;19406;038481;Mecklenburg-Vorpommern
Zülpich;;53909;02252;Nordrhein-Westfalen
Zur Schunterquelle;;38154;;Niedersachsen
Zurow;;23992;038422;Mecklenburg-Vorpommern
Zusamaltheim;;86637;08272;Bayern
Züsch;;54422;06503;Rheinland-Pfalz
Züsedom;;17309;039747;Mecklenburg-Vorpommern
Zusmarshausen;;86441;08291;Bayern
Züsow;;23992;038422;Mecklenburg-Vorpommern
Züssow;;17495;038355;Mecklenburg-Vorpommern
Zützen;, NL;15938;035452;Brandenburg
Zützen;b Angermünde;16306;03332;Brandenburg
Zuzenhausen;;74939;06226;Baden-Württemberg
Zwackau;;07819;036481;Thüringen
Zweckham;;83374;08669;Bayern
Zweedorf;b Bad Doberan;18230;038294;Mecklenburg-Vorpommern
Zweibrücken;, Pfalz;66482;06332;Rheinland-Pfalz
Zweifelscheid;;54673;06564;Rheinland-Pfalz
Zweiflingen;, Württ;74639;07948;Baden-Württemberg
Zweihausen;b Wismar, Meckl;23992;03841;Mecklenburg-Vorpommern
Zweimen;;06254;034638;Sachsen-Anhalt
Zwenkau;;04442;034203;Sachsen
Zwergern;;82432;08858;Bayern
Zwickau;;08056;0375;Sachsen
Zwickau;;08058;0375;Sachsen
Zwickau;;08060;0375;Sachsen
Zwickau;;08062;0375;Sachsen
Zwickau;;08064;0375;Sachsen
Zwickau;;08066;0375;Sachsen
Zwickmühle;;36154;06650;Hessen
Zwiefalten;, Württ;88529;07373;Baden-Württemberg
Zwiesel;;94227;09922;Bayern
Zwillingshof;;85737;089;Bayern
Zwinge;;37345;036072;Thüringen
Zwingenberg;, Bergstr;64673;06251;Hessen
Zwingenberg;, Baden;69439;06263;Baden-Württemberg
Zwischendeich;;19322;03877;Brandenburg
Zwochau;b Delitzsch;04509;034207;Sachsen
Zwönitz;;08297;037754;Sachsen
Zwota;;08267;037467;Sachsen`
