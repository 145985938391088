import {
    CollectingPlaceOther,
    CollectingPlaceOthersService,
    CollectingPlaceOtherPatch,
    CollectingBox, CollectingPlaceOtherCreation,
} from '../services/takebackApi';

export type {
    CollectingPlaceOther,
    CollectingPlaceOtherPatch
}

const {
    getCollectingPlacesCollectingPlacesOtherGet,
    createCollectingPlacesOtherPost,
    patchCollectingPlaceCollectingPlacesOtherCollectingPlaceIdPatch,
    deleteCollectingPlaceCollectingPlacesOtherCollectingPlaceIdDelete,
    getCollectingBoxesForPlaceCollectingPlacesOtherCollectingPlaceIdCollectingBoxesGet,
    createCollectingBoxCollectingPlacesOtherCollectingPlaceIdCollectingBoxesPost,
    createCollectingBoxShipmentCollectingPlacesOtherCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost,
    createCollectingBoxReturnCollectingPlacesOtherCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost
} = CollectingPlaceOthersService;


export const getCollectingPlaceOthers = async (season: string | null): Promise<Array<CollectingPlaceOther>> => {
    try {
        const collecting_places = await getCollectingPlacesCollectingPlacesOtherGet();
        if (season) {
            return collecting_places.filter(it => it.season === season)
        } else {
            return collecting_places
        }
    } catch (error) {
        throw error
    }
};

export const createCollectingPlaceOther = async (
    collectingPlace: CollectingPlaceOtherCreation
): Promise<CollectingPlaceOther> => {
    try {
        return await createCollectingPlacesOtherPost(collectingPlace)
    } catch (error) {
        throw error
    }
};
export const patchCollectingPlaceOther = async (
    id: string,
    updates: CollectingPlaceOtherPatch
): Promise<CollectingPlaceOther> => {
    try {
        return await patchCollectingPlaceCollectingPlacesOtherCollectingPlaceIdPatch(id, updates);
    } catch (error) {
        throw error
    }
};

export const deleteCollectingPlaceOther = async (
    collectingPlaceId: string
): Promise<null> => {
    try {
        return await deleteCollectingPlaceCollectingPlacesOtherCollectingPlaceIdDelete(collectingPlaceId);
    } catch (error) {
        throw error
    }
};
export const getCollectingBoxes = async (collectingPlaceId: string): Promise<Array<CollectingBox>> => {
    try {
        return await getCollectingBoxesForPlaceCollectingPlacesOtherCollectingPlaceIdCollectingBoxesGet(collectingPlaceId)
    } catch (error) {
        throw error
    }
}

export const createCollectingBox = async (
    collectingPlaceId: string
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxCollectingPlacesOtherCollectingPlaceIdCollectingBoxesPost(collectingPlaceId);
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxShipment = async (
    collectingPlaceId: string,
    collectingBoxId: string,
    weight: number,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxShipmentCollectingPlacesOtherCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
            collectingPlaceId,
            collectingBoxId,
            {
                weight: {
                    value: weight
                }
            });
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxReturn = async (
    collectingPlaceId: string,
    collectingBoxId: string,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxReturnCollectingPlacesOtherCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(collectingPlaceId, collectingBoxId);
    } catch (error) {
        throw error
    }
};
