import React, {useEffect, useState} from 'react'
import MiniDrawer from "./common/navigation/MiniDrawer";
import {
    Outlet, useNavigate,
} from "react-router-dom";
import {ThemeProvider} from "@emotion/react";
import {createTheme} from "@mui/material";
import {SnackbarProvider} from "notistack";
import APICredentialsDialog from "./common/APICredentialsDialog";

const colors = {
    primary: "#968b3e"
};

const mdTheme = createTheme({
    palette: {
        primary: {
            // light: will be calculated from palette.primary.main,
            main: colors.primary,
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            light: '#0066ff',
            main: '#000000',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 3,
        // Used by the functions below to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        tonalOffset: 0.2,
    },
    components: {
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        borderLeft: `6px solid ${colors.primary}`
                    }
                }
            }
        }
    }
})

export interface LayoutProps {
}

export default function Layout(props: LayoutProps) {
    const [APIConfigDialogOpen, setAPIConfigDialogOpen] = useState<boolean>(true)

    const navigate = useNavigate()


    useEffect(() => {
        if (sessionStorage.getItem("host") && sessionStorage.getItem("key") && sessionStorage.getItem("season")) {
            setAPIConfigDialogOpen(false)
        }
    }, []);


    const handleAPIConfigDialogClose = () => {
        setAPIConfigDialogOpen(false)
        navigate(0)
    }

    const openAPIConfigDialog = () => {
        setAPIConfigDialogOpen(true)
    }

    return (
        <ThemeProvider theme={mdTheme}>
            {/*
            // @ts-ignore */}
            <APICredentialsDialog id={"credentials"} open={APIConfigDialogOpen} keepMounted={true} onClose={handleAPIConfigDialogClose}/>
            <SnackbarProvider maxSnack={3}>
                <MiniDrawer openAPIConfigDialog={openAPIConfigDialog} currentFlavor={sessionStorage.getItem("flavor")} currentSeason={sessionStorage.getItem("season")}>
                    <Outlet/>
                </MiniDrawer>
            </SnackbarProvider>
        </ThemeProvider>
    )
}
