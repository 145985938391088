import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {
    DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";


interface DeletionDialogProps {
    collectingPlaceId: string
    open: boolean,
    onConfirm: () => void,
    onClose: () => void
}

export default function DeletionDialog(props: DeletionDialogProps) {
    const handleClose = () => {
        props.onClose()
    }
    const handleConfirm = () => {
        props.onConfirm()
        props.onClose()
    }

    return (
        <Dialog
            scroll={"paper"}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle>
                {"Einrichtung löschen?"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Soll Einrichtung {props.collectingPlaceId} wirklich gelöscht werden?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Nein</Button>
                <Button onClick={handleConfirm} autoFocus>
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
    )
}
