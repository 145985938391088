import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import {CollectingPlaceSchoolsMain as PlaybackCollectingPlacesMain} from "./playback/ui/collectingPlaces/CollectingPlaceSchoolsMain";
import {CollectingPlaceRetailsMain as PlaybackCollectingPlaceRetailsMain} from "./playback/ui/collectingPlaces/CollectingPlaceRetailsMain";
import {CollectingPlaceOthersMain as PlaybackCollectingPlaceOthersMain} from "./playback/ui/collectingPlaces/CollectingPlaceOthersMain";
import {ReturnsMain as PlaybackReturnsMain} from "./playback/ui/returns/ReturnsMain";
import {CollectingPlaceDaycaresMain as TakebackCollectingPlacesMain} from "./takeback/ui/collectingPlaces/CollectingPlaceDaycaresMain";
import {CollectingPlaceRetailsMain as TakebackCollectingPlaceRetailsMain} from "./takeback/ui/collectingPlaces/CollectingPlaceRetailsMain";
import {CollectingPlaceOthersMain as TakebackCollectingPlaceOthersMain} from "./takeback/ui/collectingPlaces/CollectingPlaceOthersMain";
import {ReturnsMain as TakebackReturnsMain} from "./takeback/ui/returns/ReturnsMain";
import Layout from "./Layout";
import {OpenAPI as PlaybackOpenAPI} from "./playback/services/takebackApi";
import {OpenAPI as TakebackOpenAPI} from "./takeback/services/takebackApi";

/*
This is all a bit messy but what it generally does.
If we set our custom env "REACT_APP_ENV" it sets the local API version per default. That's nice for developing.
Otherwise, it's per default the productive instance.
In the `APICredentialsDialog` we than show the assigned values to OpenAPI in case no sessionStorage exists.
Hacky but works for now.
 */
const flavor = sessionStorage.getItem("flavor") || "playback";
const api_version = (flavor === "playback") ? "v1" : "takeback";

const open_api = (flavor === "playback") ? PlaybackOpenAPI : TakebackOpenAPI;

if(process.env.REACT_APP_ENV === "local") {
    // @ts-ignore
    open_api.BASE = sessionStorage.getItem("host") || "http://localhost:8000/api/" + api_version + "/intern";
    open_api.HEADERS = {
        // @ts-ignore
        "X-API-Key": sessionStorage.getItem("key") || "59088226494384491047762136192955492238"
    }
} else {
    // @ts-ignore
    open_api.BASE = sessionStorage.getItem("host") || "https://" + flavor + ".prod.holypoly.co/api/" + api_version + "/intern";
    open_api.HEADERS = {
        // @ts-ignore
        "X-API-Key": sessionStorage.getItem("key") || ""
    }
}
if(!sessionStorage.getItem("host")) {
    sessionStorage.setItem("host", open_api.BASE)
}
if(!sessionStorage.getItem("key")) {
    sessionStorage.setItem("key", open_api.HEADERS["X-API-Key"])
}

class App extends React.Component {
    render() {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        {flavor === "takeback" ? (
                            <>
                                <Route path="collectingPlaces" element={<TakebackCollectingPlacesMain/>}/>
                                <Route path="collectingPlaces/retails" element={<TakebackCollectingPlaceRetailsMain/>}/>
                                <Route path="collectingPlaces/others" element={<TakebackCollectingPlaceOthersMain/>}/>
                                <Route path="returns" element={<TakebackReturnsMain/>}/>
                            </>
                            ): (
                            <>
                                <Route path="collectingPlaces" element={<PlaybackCollectingPlacesMain/>}/>
                                <Route path="collectingPlaces/retails" element={<PlaybackCollectingPlaceRetailsMain/>}/>
                                <Route path="collectingPlaces/others" element={<PlaybackCollectingPlaceOthersMain/>}/>
                                <Route path="returns" element={<PlaybackReturnsMain/>}/>
                            </>
                        )}
                        <Route path="" element={<Navigate replace to="/collectingPlaces"/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
}

export default App
