import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, MenuItem, Select, SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";

export interface APICredentialsDialogProps {
    id: string;
    keepMounted: boolean;
    open: boolean;
    onClose: () => void;
}

function APICredentialsDialog(props: APICredentialsDialogProps) {
    const [flavor, setFlavor] = React.useState<string>("")
    const [host, setHost] = React.useState<string>("")
    const [key, setKey] = React.useState<string>("")
    const [season, setSeason] = React.useState<string>("")

    const SEASONS: Record<string, string[]> = {
        "playback": ["y1", "y2"],
        "takeback": ["y1", "y2", "y3"]
    };

    React.useEffect(() => {
        const initialFlavor = sessionStorage.getItem("flavor") || "playback";
        setFlavor(initialFlavor);
        if (sessionStorage.getItem("host")) {
            setHost(sessionStorage.getItem("host") || "");
        }
        if (sessionStorage.getItem("key")) {
            setKey(sessionStorage.getItem("key") || "");
        }
        if (sessionStorage.getItem("season")) {
            setSeason(sessionStorage.getItem("season") || "y1")
        } else {
            setSeason(SEASONS[initialFlavor] && SEASONS[initialFlavor]!!.at(-1) || "y1");
        }
    }, [props.open]);

    const handleEntering = () => {

    };

    const handleClose = () => {
        if (!props.keepMounted) props.onClose()
    };

    const handleOk = () => {
        sessionStorage.setItem("flavor", flavor)
        sessionStorage.setItem("host", host)
        sessionStorage.setItem("key", key)
        sessionStorage.setItem("season", season)
        props.onClose()
    };

    const handleFlavorChange = (event: SelectChangeEvent) => {
        setFlavor(event.target.value)
        if (process.env.REACT_APP_ENV !== "local") {
            const api_version = (event.target.value === "playback") ? "v1" : "v2"
            setHost("https://" + event.target.value + ".prod.holypoly.co/api/" + api_version + "/intern")
            setKey("")
            setSeason(SEASONS[event.target.value]!!.at(-1) || "y1");
        }
    };
    const handleHostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHost((event.target as HTMLInputElement).value)
    };
    const handleKeyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKey((event.target as HTMLInputElement).value)
    };

    const handleSeasonChange = (event: SelectChangeEvent) => {
        setSeason(event.target.value)
    };

    return (
        <Dialog
            sx={{'& .MuiDialog-paper': {width: '80%', maxHeight: 435}}}
            maxWidth="xs"
            TransitionProps={{onEntering: handleEntering}}
            open={props.open}
            keepMounted={props.keepMounted}
        >
            <DialogTitle>Select TakeBack API Version</DialogTitle>
            <DialogContent dividers>
                <Typography style={{fontWeight: 600, marginBottom: 5}}>IMPORTANT: PlayBack needs `v1`, TakeBack needs
                    `v2`!</Typography>
                <FormControl fullWidth>
                    <Select
                        id="flavor"
                        value={flavor}
                        label="Flavor"
                        onChange={handleFlavorChange}
                    >
                        <MenuItem value={"playback"}>Mattel PlayBack</MenuItem>
                        <MenuItem value={"takeback"}>NUK TakeBack</MenuItem>
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <TextField id="host" label="API Host" variant="standard" value={host} onChange={handleHostChange}/>
                </FormControl>
                <FormControl fullWidth sx={{mt: 2}}>
                    <TextField id="key" label="API Key" variant="standard" value={key} onChange={handleKeyChange}/>
                </FormControl>
                <Typography style={{fontWeight: 600, marginTop: 10}}>Choose the data season</Typography>
                <FormControl fullWidth>
                    <Select
                        id="season"
                        value={season}
                        label="Season"
                        onChange={handleSeasonChange}
                    >
                        {flavor && SEASONS[flavor] && SEASONS[flavor]!!.map((season) => {
                            return <MenuItem value={season}>{season}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                {!props.keepMounted ? <Button onClick={handleClose}>Abbrechen</Button> : null}
                <Button onClick={handleOk}>Okay</Button>
            </DialogActions>
        </Dialog>
    );
}

export default APICredentialsDialog
