import React from 'react'
import {
    Box
} from "@mui/material";
import CollectingPlaceSchoolsList from "./CollectingPlaceSchoolsList";


export function CollectingPlaceSchoolsMain() {

    return (
        <>
            <Box display="flex" flexDirection={"column"} p={2} pt={4} bgcolor="background.paper" height={"100%"}>
                <Box mt={2} flexGrow={2} display={"flex"} flexDirection={"row"} width={"100%"} gap={2}>
                    <CollectingPlaceSchoolsList/>
                </Box>
            </Box>
        </>
    )
}
