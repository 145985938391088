import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import DataList from "../../../common/tables/DataList";
import {
    getCollectingPlaceSchoolClasses, patchCollectingPlaceSchoolClass
} from "../../features/collectingPlaces";
import {GridColDef, GridRowModel} from "@mui/x-data-grid";
import renderCellExpand from "../../../common/tables/GridCellExpand";
import {CollectingPlaceSchoolClassInDB} from "../../services/takebackApi";
import Typography from "@mui/material/Typography";


interface SchoolClassesListProps {
    collectingPlaceId: string

    onClassesLoaded?: (schoolClasses: CollectingPlaceSchoolClassInDB[]) => void

    onUpdate? : (time: number) => void
    lastUpdate?: number
}

export default function SchoolClassesList(props: SchoolClassesListProps) {
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [schoolClasses, setSchoolClasses] = useState<CollectingPlaceSchoolClassInDB[]>([]);

    const [lastUpdate, setLastUpdate] = useState<number>(-1)

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 175,
        },
        {
            field: 'className',
            headerName: 'className',
            minWidth: 150,
            flex: 1,
            editable: true,
        },
        {
            field: 'countStudents',
            headerName: 'countStudents',
            type: 'number',
            minWidth: 150,
            flex: 1,
            editable: true,
        },
        {
            field: 'name',
            headerName: 'name',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.contactInfo && params.row.contactInfo.name,
            renderCell: renderCellExpand
        },
        {
            field: 'email',
            headerName: 'email',
            minWidth: 150,
            flex: 1,
            editable: true,
            valueGetter: (params) => params.row.contactInfo && params.row.contactInfo.email,
            renderCell: renderCellExpand
        },
        {
            field: 'isMainContact',
            headerName: 'isMainContact',
            type: 'boolean',
        },
    ]

    const processRowUpdate = async (newRow: GridRowModel) => {
        const response = await patchCollectingPlaceSchoolClass(props.collectingPlaceId, newRow.id, {
            className: newRow.className,
            countStudents: newRow.countStudents,
            contactInfo: {
                name: newRow.name,
                email: newRow.email,
            }
        })
        enqueueSnackbar('Änderungen erfolgreich gespeichert', {variant: 'success'})

        reloadSchoolClasses()

        const time = (new Date()).getTime()
        setLastUpdate(time)
        if(props.onUpdate) props.onUpdate(time)

        return response
    }

    const reloadSchoolClasses = () => {
        setIsLoading(true)

        getCollectingPlaceSchoolClasses(props.collectingPlaceId)
            .then((allSchoolClasses) => {
                setSchoolClasses(allSchoolClasses)
                setIsLoading(false)

                if(props.onClassesLoaded) {
                    props.onClassesLoaded(allSchoolClasses)
                }
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    useEffect(() => {
        if(lastUpdate !== props.lastUpdate)
            reloadSchoolClasses()
    }, [props.collectingPlaceId, props.lastUpdate])

    return (
        <>
            <DataList
                items={schoolClasses}
                columns={columns}
                isLoading={isLoading}
                processRowUpdate={processRowUpdate}/>
        </>
    )
}
