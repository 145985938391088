import {
    CollectingPlaceRetail,
    CollectingPlaceRetailsService,
    CollectingPlaceRetailPatch,
    CollectingBox, CollectingPlaceRetailCreation,
} from '../services/takebackApi';

export type {
    CollectingPlaceRetail,
    CollectingPlaceRetailPatch
}

const {
    getCollectingPlacesCollectingPlacesRetailsGet,
    createRetailCollectingPlacesRetailsPost,
    patchCollectingPlaceCollectingPlacesRetailsCollectingPlaceIdPatch,
    deleteCollectingPlaceCollectingPlacesRetailsCollectingPlaceIdDelete,
    getCollectingBoxesForPlaceCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesGet,
    createCollectingBoxCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesPost,
    createCollectingBoxShipmentCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost,
    createCollectingBoxReturnCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost
} = CollectingPlaceRetailsService;


export const getCollectingPlaceRetails = async (season: string | null): Promise<Array<CollectingPlaceRetail>> => {
    try {
        const collecting_places = await getCollectingPlacesCollectingPlacesRetailsGet();
        if (season) {
            return collecting_places.filter(it => it.season === season)
        } else {
            return collecting_places
        }
    } catch (error) {
        throw error
    }
};

export const createCollectingPlaceRetail = async (
    collectingPlace: CollectingPlaceRetailCreation
): Promise<CollectingPlaceRetail> => {
    try {
        return await createRetailCollectingPlacesRetailsPost(collectingPlace)
    } catch (error) {
        throw error
    }
};
export const patchCollectingPlaceRetail = async (
    id: string,
    updates: CollectingPlaceRetailPatch
): Promise<CollectingPlaceRetail> => {
    try {
        return await patchCollectingPlaceCollectingPlacesRetailsCollectingPlaceIdPatch(id, updates);
    } catch (error) {
        throw error
    }
};

export const deleteCollectingPlaceRetail = async (
    collectingPlaceId: string
): Promise<null> => {
    try {
        return await deleteCollectingPlaceCollectingPlacesRetailsCollectingPlaceIdDelete(collectingPlaceId);
    } catch (error) {
        throw error
    }
};
export const getCollectingBoxes = async (collectingPlaceId: string): Promise<Array<CollectingBox>> => {
    try {
        return await getCollectingBoxesForPlaceCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesGet(collectingPlaceId)
    } catch (error) {
        throw error
    }
}

export const createCollectingBox = async (
    collectingPlaceId: string
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesPost(collectingPlaceId);
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxShipment = async (
    collectingPlaceId: string,
    collectingBoxId: string,
    weight: number,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxShipmentCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
            collectingPlaceId,
            collectingBoxId,
            {
                weight: {
                    value: weight
                }
            });
    } catch (error) {
        throw error
    }
};

export const createCollectingBoxReturn = async (
    collectingPlaceId: string,
    collectingBoxId: string,
): Promise<CollectingBox> => {
    try {
        return await createCollectingBoxReturnCollectingPlacesRetailsCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(collectingPlaceId, collectingBoxId);
    } catch (error) {
        throw error
    }
};
