import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import * as React from "react";
import {Link, useResolvedPath, To, useMatch} from "react-router-dom";

interface NavigationItemProps {
    label: string,
    icon: React.ReactElement,
    collapsed: boolean,
    to: To
}

export default function NavigationItem(props: NavigationItemProps) {
    let resolved = useResolvedPath(props.to);
    let match = useMatch({path: resolved.pathname, end: true});

    return (
        <Link to={props.to} style={{textDecoration: 'none', color: 'unset'}}>
            <ListItemButton
                key={props.label}
                sx={{
                    minHeight: 48,
                    justifyContent: props.collapsed ? 'initial' : 'center',
                    px: 2.5
                }}
                selected={match !== null}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: props.collapsed ? 3 : 'auto',
                        justifyContent: 'center',
                    }}
                >
                    {props.icon}
                </ListItemIcon>
                <ListItemText primary={props.label} sx={{opacity: props.collapsed ? 1 : 0}}/>
            </ListItemButton>
        </Link>
    )
}