import * as React from 'react';
import {useRef} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box, DialogActions, DialogContent, DialogTitle,
    Grid,
    TextField,
} from "@mui/material";
import {useSnackbar} from "notistack";
import {
    CollectingPlaceOther,
    createCollectingPlaceOther
} from "../../features/collectingPlaceOthers";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface CreationDialogProps {
    open: boolean,
    onCancel: () => void
    onSubmit: () => void
}

export default function OtherCreationDialog(props: CreationDialogProps) {
    const {enqueueSnackbar} = useSnackbar();

    const formRef = useRef<CreateOtherForm | null>(null);
    const handleCancel = () => {
        props.onCancel()
    }

    const handleCreate = () => {
        if(formRef.current != null) {
            createCollectingPlaceOther({
                company: formRef.current.state.company!!,
                collectingPlaceAddress: {
                    name1: formRef.current.state.name1!!,
                    name2: formRef.current.state.name2,
                    name3: formRef.current.state.name3,
                    streetName: formRef.current.state.streetName!!,
                    houseNumber: formRef.current.state.houseNumber!!,
                    postCode: formRef.current.state.postCode!!,
                    city: formRef.current.state.city!!
                }
            })
                .then((collectingPlace: CollectingPlaceOther) => {
                    props.onSubmit()
                })
                .catch((error) => {
                    enqueueSnackbar(error.message, {variant: 'error'});
                })
        }
    }

    return (
        <Dialog
            scroll={"paper"}
            open={props.open}
            onClose={handleCancel}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                {"Create other collecting place"}
            </DialogTitle>
            <DialogContent>
                <Box m={6}
                     component="form"
                     autoComplete="off">
                    <CreateOtherForm ref={formRef} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleCreate} autoFocus>
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    )
}

interface CreateOtherFormState {
    company?: string;
    name1?: string;
    name2?: string;
    name3?: string;
    streetName?: string;
    houseNumber?: string;
    postCode?: string;
    city?: string;
}

class CreateOtherForm extends React.Component<{}, CreateOtherFormState> {
    constructor(props: any) {
        super(props);
        this.state = {
            company: "",
            name1: "",
            name2: "",
            name3: "",
            streetName: "",
            houseNumber: "",
            postCode: "",
            city: ""
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    render() {
        return (
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="company"
                            name="company"
                            inputProps={{ maxLength: 35 }}
                            value={this.state.company}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="name1"
                            name="name1"
                            inputProps={{ maxLength: 35 }}
                            value={this.state.name1}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            label="name2"
                            name="name2"
                            inputProps={{ maxLength: 35 }}
                            value={this.state.name2}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            label="name3"
                            name="name3"
                            inputProps={{ maxLength: 35 }}
                            value={this.state.name3}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            required
                            label="streetName"
                            name="streetName"
                            inputProps={{ maxLength: 35 }}
                            value={this.state.streetName}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            required
                            label="houseNumber"
                            name="houseNumber"
                            inputProps={{ maxLength: 5 }}
                            value={this.state.houseNumber}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            required
                            label="postCode"
                            name="postCode"
                            inputProps={{ maxLength: 10 }}
                            value={this.state.postCode}
                            onChange={this.handleInputChange}
                        />
                        <TextField
                            required
                            label="city"
                            name="city"
                            inputProps={{ maxLength: 35 }}
                            value={this.state.city}
                            onChange={this.handleInputChange}
                        />
                    </Grid>
                </Grid>
            </form>
        );
    }
}
