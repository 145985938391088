import * as React from 'react';
import {useEffect, useState} from 'react';
import {useSnackbar} from "notistack";
import DataList from "../../../common/tables/DataList";
import {GridActionsCellItem, GridColDef, GridRowParams} from "@mui/x-data-grid";
import {CollectingBox} from "../../services/takebackApi";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

import {Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField} from '@mui/material';

interface CollectingBoxesListProps {
    collectingPlaceId: string

    getCollectingBoxes: (collectingPlaceId: string) => Promise<CollectingBox[]>,
    createCollectingBoxReturn: (collectingPlaceId: string, collectingBoxId: string) => Promise<CollectingBox>,
    createCollectingBoxShipment: (collectingPlaceId: string, collectingBoxId: string, weight: number) => Promise<CollectingBox>,

    onUpdate?: (time: number) => void
    lastUpdate?: number
}

export default function CollectingBoxesList(props: CollectingBoxesListProps) {
    const {enqueueSnackbar} = useSnackbar();

    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const [collectingBoxes, setCollectingBoxes] = useState<CollectingBox[]>([]);

    const [lastUpdate, setLastUpdate] = useState<number>(-1)

    const [activeCollectingBoxId, setActiveCollectingBoxId] = React.useState<string | null>(null)
    const [isWeightDialogOpen, setIsWeightDialogOpen] = React.useState<boolean>(false)
    const [weight, setWeight] = React.useState<number>(1000)

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'ID',
            width: 175,
        },
        {
            field: 'date',
            headerName: 'date',
            type: "dateTime",
            minWidth: 150,
            flex: 1,
            valueGetter: ({value}) => value && new Date(value),
        },
        {
            field: 'shipments',
            headerName: 'Shipments',
            type: 'actions',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                for (let shipment of params.row.shipments) {
                    // @ts-ignore
                    actions.push(<GridActionsCellItem icon={<PictureAsPdfIcon/>}
                                                      label="Label"
                                                      onClick={downloadLabel(shipment.shipmentData.labelData)}
                                                      showInMenu={false}/>)
                }
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<NoteAddIcon/>}
                                                  label="Add"
                                                  onClick={createShipment(params.row.id)}
                                                  showInMenu={false}/>)
                return actions
            }
        },
        {
            field: 'returns',
            headerName: 'Returns',
            type: 'actions',
            // @ts-ignore
            getActions: (params: GridRowParams) => {
                let actions: Array<typeof GridActionsCellItem> = []
                for (let returnOrder of params.row.returns) {
                    // @ts-ignore
                    actions.push(<GridActionsCellItem icon={<PictureAsPdfIcon/>}
                                                      label="Label"
                                                      onClick={downloadLabel(returnOrder.shipmentData.labelData)}
                                                      showInMenu={false}/>)
                }
                // @ts-ignore
                actions.push(<GridActionsCellItem icon={<NoteAddIcon/>}
                                                  label="Add"
                                                  onClick={createReturn(params.row.id)}
                                                  showInMenu={false}/>)
                return actions
            }
        },
    ]

    // taken from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        return new Blob(byteArrays, {type: contentType})
    }

    const downloadLabel = (label: string) => () => {
        // @ts-ignore
        const file = b64toBlob(label, 'application/pdf')

        const element = document.createElement("a");
        element.href = URL.createObjectURL(file);
        element.download = "labels-" + props.collectingPlaceId + ".pdf";

        // simulate link click
        document.body.appendChild(element); // Required for this to work in firefox
        element.click();
    }

    const createShipment = (collectingBoxId: string) => () => {
        setIsWeightDialogOpen(true)
        setActiveCollectingBoxId(collectingBoxId)
    }

    const createReturn = (collectingBoxId: string) => () => {
        props.createCollectingBoxReturn(props.collectingPlaceId, collectingBoxId)
            .then((collectingBox) => {
                reloadCollectingBoxes()

                const time = (new Date()).getTime()
                setLastUpdate(time)
                if (props.onUpdate) props.onUpdate(time)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    const reloadCollectingBoxes = () => {
        setIsLoading(true)

        props.getCollectingBoxes(props.collectingPlaceId)
            .then((allCollectingBoxes) => {
                setCollectingBoxes(allCollectingBoxes)
                setIsLoading(false)
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }


    const handleWeightDialogClose = () => {
        setIsWeightDialogOpen(false)
        setActiveCollectingBoxId(null)
    }

    const handleWeightDialogSubmit = () => {
        setIsWeightDialogOpen(false)
        if (activeCollectingBoxId != null) {
            props.createCollectingBoxShipment(props.collectingPlaceId, activeCollectingBoxId, weight)
                .then((collectingBox) => {
                    setActiveCollectingBoxId(null)
                    reloadCollectingBoxes()

                    const time = (new Date()).getTime()
                    setLastUpdate(time)
                    if (props.onUpdate) props.onUpdate(time)
                })
                .catch((error) => {
                    setActiveCollectingBoxId(null)
                    enqueueSnackbar(error.message, {variant: 'error'});
                })
        }
    }
    const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setWeight(parseInt((event.target as HTMLInputElement).value))
    };


    useEffect(() => {
        if (lastUpdate !== props.lastUpdate)
            reloadCollectingBoxes()
    }, [props.collectingPlaceId, props.lastUpdate])

    return (
        <>
            <DataList
                items={collectingBoxes}
                columns={columns}
                isLoading={isLoading}/>
            <Dialog
                open={isWeightDialogOpen}
                onClose={handleWeightDialogClose}
            >
                <DialogTitle>Shipment Weight</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Weight"
                        sx={{m: 1, width: '25ch'}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">g</InputAdornment>,
                        }}
                        value={weight}
                        onChange={handleWeightChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleWeightDialogClose}>Cancel</Button>
                    <Button onClick={handleWeightDialogSubmit}>Create Shipment</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
