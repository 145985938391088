import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box, Card, CardActions, CardContent,
    Grid,
} from "@mui/material";
import {useSnackbar} from "notistack";
import CollectingBoxesList from "./CollectingBoxesList";
import {CollectingBox} from "../../services/takebackApi";


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface ConsignmentDialogProps {
    collectingPlaceId: string
    open: boolean,
    onClose: () => void

    getCollectingBoxes : (collectingPlaceId: string) => Promise<CollectingBox[]>
    createCollectingBox : (collectingPlaceId: string) => Promise<CollectingBox>
    createCollectingBoxReturn : (collectingPlaceId: string, collectingBoxId: string) => Promise<CollectingBox>
    createCollectingBoxShipment : (collectingPlaceId: string, collectingBoxId: string, weight: number) => Promise<CollectingBox>
}

export default function DefaultConsignmentDialog(props: ConsignmentDialogProps) {
    const {enqueueSnackbar} = useSnackbar();

    const [lastUpdate, setLastUpdate] = useState<number>(0)

    const handleUpdate = (time: number) => {
        setLastUpdate(time)
    }

    const handleClose = () => {
        props.onClose()
    }

    const performCollectingBoxCreation = () => {
        props.createCollectingBox(props.collectingPlaceId)
            .then((collectingBox: CollectingBox) => {
                handleUpdate((new Date()).getTime());
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    return (
        <Dialog
            fullScreen
            scroll={"paper"}
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h5" component="div">
                        Consignment for collecting place {props.collectingPlaceId}
                    </Typography>
                    <Button autoFocus color="inherit" size="large" onClick={handleClose}>
                        <Typography variant="h6">Close</Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <Box m={6}
                 component="form"
                 autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item xs={5}>
                        <Typography variant={"h6"} sx={{mb: 2}}>Collecting Boxes:</Typography>
                        <CollectingBoxesList
                            collectingPlaceId={props.collectingPlaceId}
                            getCollectingBoxes={props.getCollectingBoxes}
                            createCollectingBoxReturn={props.createCollectingBoxReturn}
                            createCollectingBoxShipment={props.createCollectingBoxShipment}
                            onUpdate={handleUpdate}
                            lastUpdate={lastUpdate}/>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={3}>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    New Collecting Box
                                </Typography>
                                <Typography gutterBottom>
                                    Creates Collecting Box. Afterwards, Collecting Box Shipment and Collecting Box Return can be created in the list.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size={"large"} onClick={performCollectingBoxCreation}>Create Collecting Box</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}
