/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectingBox } from '../models/CollectingBox';
import type { CollectingPlaceSchool } from '../models/CollectingPlaceSchool';
import type { CollectingPlaceSchoolClassInDB } from '../models/CollectingPlaceSchoolClassInDB';
import type { CollectingPlaceSchoolClassPatch } from '../models/CollectingPlaceSchoolClassPatch';
import type { CollectingPlaceSchoolPatch } from '../models/CollectingPlaceSchoolPatch';
import type { CollectingPlaceSchoolWaitList } from '../models/CollectingPlaceSchoolWaitList';
import type { CollectingPlaceSchoolWaitListPatch } from '../models/CollectingPlaceSchoolWaitListPatch';
import type { DefaultMattelConsignment } from '../models/DefaultMattelConsignment';
import type { DHLShipmentOrderRequestShipmentDetails } from '../models/DHLShipmentOrderRequestShipmentDetails';
import type { ReturnOrder } from '../models/ReturnOrder';
import type { Shipment } from '../models/Shipment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CollectingPlaceSchoolsService {
    /**
     * Get Collecting Places
     * Gets all registered **Collecting Places**
     * @returns CollectingPlaceSchool Registered **Collecting Places**
     * @throws ApiError
     */
    public static getCollectingPlacesCollectingPlacesSchoolsGet(): CancelablePromise<Array<CollectingPlaceSchool>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/schools',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Places Wait List
     * Gets all contacts on the wait list
     * @returns CollectingPlaceSchoolWaitList Registered **Wait List Collecting Places**
     * @throws ApiError
     */
    public static getCollectingPlacesWaitListCollectingPlacesSchoolsWaitListGet(): CancelablePromise<Array<CollectingPlaceSchoolWaitList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/schools/waitList',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Patch Collecting Place Wait List
     * Updates details of wait list collecting place
     * @param collectingPlaceId
     * @param requestBody
     * @returns CollectingPlaceSchoolWaitList Patched **Wait List Collecting Place**
     * @throws ApiError
     */
    public static patchCollectingPlaceWaitListCollectingPlacesSchoolsWaitListCollectingPlaceIdPatch(
        collectingPlaceId: string,
        requestBody: CollectingPlaceSchoolWaitListPatch,
    ): CancelablePromise<CollectingPlaceSchoolWaitList> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/schools/waitList/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Place
     * Gets registered **Collecting Places**
     * @param collectingPlaceId
     * @returns CollectingPlaceSchool Registered **Collecting Place**
     * @throws ApiError
     */
    public static getCollectingPlaceCollectingPlacesSchoolsCollectingPlaceIdGet(
        collectingPlaceId: string,
    ): CancelablePromise<CollectingPlaceSchool> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/schools/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Delete Collecting Place
     * Delete collecting place
     * @param collectingPlaceId
     * @returns any Successful deletion of **Collecting Place**
     * @throws ApiError
     */
    public static deleteCollectingPlaceCollectingPlacesSchoolsCollectingPlaceIdDelete(
        collectingPlaceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/collectingPlaces/schools/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Patch Collecting Place
     * Updates details of collecting place
     * @param collectingPlaceId
     * @param requestBody
     * @returns CollectingPlaceSchool Patched **Collecting Place**
     * @throws ApiError
     */
    public static patchCollectingPlaceCollectingPlacesSchoolsCollectingPlaceIdPatch(
        collectingPlaceId: string,
        requestBody: CollectingPlaceSchoolPatch,
    ): CancelablePromise<CollectingPlaceSchool> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/schools/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Place Classes
     * Gets **Collecting Place** classes
     * @param collectingPlaceId
     * @returns CollectingPlaceSchoolClassInDB **Collecting Place** classes
     * @throws ApiError
     */
    public static getCollectingPlaceClassesCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<CollectingPlaceSchoolClassInDB>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/schools/{collecting_place_id}/schoolClasses',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Place Class
     * Gets **Collecting Place** class
     * @param collectingPlaceId
     * @param collectingPlaceClassId
     * @returns CollectingPlaceSchoolClassInDB **Collecting Place** class
     * @throws ApiError
     */
    public static getCollectingPlaceClassCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesCollectingPlaceClassIdGet(
        collectingPlaceId: string,
        collectingPlaceClassId: string,
    ): CancelablePromise<CollectingPlaceSchoolClassInDB> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/schools/{collecting_place_id}/schoolClasses/{collecting_place_class_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_place_class_id': collectingPlaceClassId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Patch Collecting Place Class
     * Updates details of collecting place class
     * @param collectingPlaceId
     * @param collectingPlaceClassId
     * @param requestBody
     * @returns CollectingPlaceSchoolClassInDB Patched **Collecting Place class**
     * @throws ApiError
     */
    public static patchCollectingPlaceClassCollectingPlacesSchoolsCollectingPlaceIdSchoolClassesCollectingPlaceClassIdPatch(
        collectingPlaceId: string,
        collectingPlaceClassId: string,
        requestBody: CollectingPlaceSchoolClassPatch,
    ): CancelablePromise<CollectingPlaceSchoolClassInDB> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/schools/{collecting_place_id}/schoolClasses/{collecting_place_class_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_place_class_id': collectingPlaceClassId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Consignment
     * Performs the default consignment. It creates a collecting box for the specified **Collecting Place** with 1 return order and 1 DHL shipment.
     *
     * An additional material shipment is created with a specified weight.
     * @param collectingPlaceId
     * @param materialWeight
     * @param materialStudentsCount
     * @param date
     * @returns DefaultMattelConsignment Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createConsignmentCollectingPlacesSchoolsCollectingPlaceIdConsignmentPost(
        collectingPlaceId: string,
        materialWeight: number,
        materialStudentsCount: number,
        date?: string,
    ): CancelablePromise<DefaultMattelConsignment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/schools/{collecting_place_id}/consignment',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'materialWeight': materialWeight,
                'materialStudentsCount': materialStudentsCount,
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Boxes For Place
     * Gets all collecting boxes for a **Collecting Place**.
     * @param collectingPlaceId
     * @returns CollectingBox **Collecting Boxes** for school
     * @throws ApiError
     */
    public static getCollectingBoxesForPlaceCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<CollectingBox>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/schools/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Collecting Box
     * Creates a collecting box for the specified **Collecting Place**.
     *
     * A collecting box consists of
     * 0-n return orders to be sent back
     * 0-n DHL shipments to send the collecting box
     * @param collectingPlaceId
     * @param date
     * @returns CollectingBox Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createCollectingBoxCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesPost(
        collectingPlaceId: string,
        date?: string,
    ): CancelablePromise<CollectingBox | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/schools/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Collecting Box Return
     * Creates a return for the specified **Collecting Box**.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param date
     * @returns ReturnOrder Successful Response
     * @returns any Created **Return Order**
     * @throws ApiError
     */
    public static createCollectingBoxReturnCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        date?: string,
    ): CancelablePromise<ReturnOrder | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/schools/{collecting_place_id}/collectingBoxes/{collecting_box_id}/returns',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Collecting Box Shipment
     * Creates a shipment for the specified **Collecting Box**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingBoxShipmentCollectingPlacesSchoolsCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        requestBody: DHLShipmentOrderRequestShipmentDetails,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/schools/{collecting_place_id}/collectingBoxes/{collecting_box_id}/shipments',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Place Material Shipment
     * Gets all material shipment for the specified **Collecting Place**.
     * @param collectingPlaceId
     * @returns Shipment **Material Shipments**
     * @throws ApiError
     */
    public static getCollectingPlaceMaterialShipmentCollectingPlacesSchoolsCollectingPlaceIdMaterialShipmentsGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<Shipment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/schools/{collecting_place_id}/materialShipments',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Collecting Place Material Shipment
     * Creates a material shipment for the specified **Collecting Place**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param referenceNumber
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingPlaceMaterialShipmentCollectingPlacesSchoolsCollectingPlaceIdMaterialShipmentsPost(
        collectingPlaceId: string,
        referenceNumber: string,
        requestBody: DHLShipmentOrderRequestShipmentDetails,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/schools/{collecting_place_id}/materialShipments',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'reference_number': referenceNumber,
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Get Collecting Places
     * Gets all registered **Collecting Places**
     * @returns CollectingPlaceSchool Registered **Collecting Places**
     * @throws ApiError
     */
    public static getCollectingPlacesCollectingPlacesGet(): CancelablePromise<Array<CollectingPlaceSchool>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Get Collecting Places Wait List
     * Gets all contacts on the wait list
     * @returns CollectingPlaceSchoolWaitList Registered **Wait List Collecting Places**
     * @throws ApiError
     */
    public static getCollectingPlacesWaitListCollectingPlacesWaitListGet(): CancelablePromise<Array<CollectingPlaceSchoolWaitList>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/waitList',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Patch Collecting Place Wait List
     * Updates details of wait list collecting place
     * @param collectingPlaceId
     * @param requestBody
     * @returns CollectingPlaceSchoolWaitList Patched **Wait List Collecting Place**
     * @throws ApiError
     */
    public static patchCollectingPlaceWaitListCollectingPlacesWaitListCollectingPlaceIdPatch(
        collectingPlaceId: string,
        requestBody: CollectingPlaceSchoolWaitListPatch,
    ): CancelablePromise<CollectingPlaceSchoolWaitList> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/waitList/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Get Collecting Place
     * Gets registered **Collecting Places**
     * @param collectingPlaceId
     * @returns CollectingPlaceSchool Registered **Collecting Place**
     * @throws ApiError
     */
    public static getCollectingPlaceCollectingPlacesCollectingPlaceIdGet(
        collectingPlaceId: string,
    ): CancelablePromise<CollectingPlaceSchool> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Delete Collecting Place
     * Delete collecting place
     * @param collectingPlaceId
     * @returns any Successful deletion of **Collecting Place**
     * @throws ApiError
     */
    public static deleteCollectingPlaceCollectingPlacesCollectingPlaceIdDelete(
        collectingPlaceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/collectingPlaces/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Patch Collecting Place
     * Updates details of collecting place
     * @param collectingPlaceId
     * @param requestBody
     * @returns CollectingPlaceSchool Patched **Collecting Place**
     * @throws ApiError
     */
    public static patchCollectingPlaceCollectingPlacesCollectingPlaceIdPatch(
        collectingPlaceId: string,
        requestBody: CollectingPlaceSchoolPatch,
    ): CancelablePromise<CollectingPlaceSchool> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Get Collecting Place Classes
     * Gets **Collecting Place** classes
     * @param collectingPlaceId
     * @returns CollectingPlaceSchoolClassInDB **Collecting Place** classes
     * @throws ApiError
     */
    public static getCollectingPlaceClassesCollectingPlacesCollectingPlaceIdSchoolClassesGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<CollectingPlaceSchoolClassInDB>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/{collecting_place_id}/schoolClasses',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Get Collecting Place Class
     * Gets **Collecting Place** class
     * @param collectingPlaceId
     * @param collectingPlaceClassId
     * @returns CollectingPlaceSchoolClassInDB **Collecting Place** class
     * @throws ApiError
     */
    public static getCollectingPlaceClassCollectingPlacesCollectingPlaceIdSchoolClassesCollectingPlaceClassIdGet(
        collectingPlaceId: string,
        collectingPlaceClassId: string,
    ): CancelablePromise<CollectingPlaceSchoolClassInDB> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/{collecting_place_id}/schoolClasses/{collecting_place_class_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_place_class_id': collectingPlaceClassId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Patch Collecting Place Class
     * Updates details of collecting place class
     * @param collectingPlaceId
     * @param collectingPlaceClassId
     * @param requestBody
     * @returns CollectingPlaceSchoolClassInDB Patched **Collecting Place class**
     * @throws ApiError
     */
    public static patchCollectingPlaceClassCollectingPlacesCollectingPlaceIdSchoolClassesCollectingPlaceClassIdPatch(
        collectingPlaceId: string,
        collectingPlaceClassId: string,
        requestBody: CollectingPlaceSchoolClassPatch,
    ): CancelablePromise<CollectingPlaceSchoolClassInDB> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/{collecting_place_id}/schoolClasses/{collecting_place_class_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_place_class_id': collectingPlaceClassId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Create Consignment
     * Performs the default consignment. It creates a collecting box for the specified **Collecting Place** with 1 return order and 1 DHL shipment.
     *
     * An additional material shipment is created with a specified weight.
     * @param collectingPlaceId
     * @param materialWeight
     * @param materialStudentsCount
     * @param date
     * @returns DefaultMattelConsignment Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createConsignmentCollectingPlacesCollectingPlaceIdConsignmentPost(
        collectingPlaceId: string,
        materialWeight: number,
        materialStudentsCount: number,
        date?: string,
    ): CancelablePromise<DefaultMattelConsignment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/consignment',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'materialWeight': materialWeight,
                'materialStudentsCount': materialStudentsCount,
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Get Collecting Boxes For Place
     * Gets all collecting boxes for a **Collecting Place**.
     * @param collectingPlaceId
     * @returns CollectingBox **Collecting Boxes** for school
     * @throws ApiError
     */
    public static getCollectingBoxesForPlaceCollectingPlacesCollectingPlaceIdCollectingBoxesGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<CollectingBox>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Create Collecting Box
     * Creates a collecting box for the specified **Collecting Place**.
     *
     * A collecting box consists of
     * 0-n return orders to be sent back
     * 0-n DHL shipments to send the collecting box
     * @param collectingPlaceId
     * @param date
     * @returns CollectingBox Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createCollectingBoxCollectingPlacesCollectingPlaceIdCollectingBoxesPost(
        collectingPlaceId: string,
        date?: string,
    ): CancelablePromise<CollectingBox | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Create Collecting Box Return
     * Creates a return for the specified **Collecting Box**.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param date
     * @returns ReturnOrder Successful Response
     * @returns any Created **Return Order**
     * @throws ApiError
     */
    public static createCollectingBoxReturnCollectingPlacesCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        date?: string,
    ): CancelablePromise<ReturnOrder | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes/{collecting_box_id}/returns',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Create Collecting Box Shipment
     * Creates a shipment for the specified **Collecting Box**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingBoxShipmentCollectingPlacesCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        requestBody: DHLShipmentOrderRequestShipmentDetails,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/collectingBoxes/{collecting_box_id}/shipments',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Get Collecting Place Material Shipment
     * Gets all material shipment for the specified **Collecting Place**.
     * @param collectingPlaceId
     * @returns Shipment **Material Shipments**
     * @throws ApiError
     */
    public static getCollectingPlaceMaterialShipmentCollectingPlacesCollectingPlaceIdMaterialShipmentsGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<Shipment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/{collecting_place_id}/materialShipments',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * @deprecated
     * Create Collecting Place Material Shipment
     * Creates a material shipment for the specified **Collecting Place**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param referenceNumber
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingPlaceMaterialShipmentCollectingPlacesCollectingPlaceIdMaterialShipmentsPost(
        collectingPlaceId: string,
        referenceNumber: string,
        requestBody: DHLShipmentOrderRequestShipmentDetails,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/{collecting_place_id}/materialShipments',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'reference_number': referenceNumber,
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
}
