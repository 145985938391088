import * as React from 'react';
import {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {TransitionProps} from '@mui/material/transitions';
import {
    Box, Card, CardActions, CardContent,
    Grid,
    InputAdornment, TextField,
} from "@mui/material";
import {useSnackbar} from "notistack";
import SchoolClassesList from "./SchoolClassesList";
import {CollectingPlaceSchoolClassInDB} from "../../services/takebackApi";
import {
    createCollectingBoxReturn,
    createCollectingBoxShipment,
    createConsignment,
    getCollectingBoxes
} from "../../features/collectingPlaces";
import CollectingBoxesList from "./CollectingBoxesList";
import MaterialShipmentsList from './MaterialShipmentsList';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


interface ConsignmentDialogProps {
    collectingPlaceId: string
    open: boolean,
    onClose: () => void
}

export default function SchoolConsignmentDialog(props: ConsignmentDialogProps) {
    const {enqueueSnackbar} = useSnackbar();
    // The weight of a single item in Gramm, one book in our case
    const itemWeight:number = 64
    // Assumed average size of a class
    const classSize:number = 18

    const [materialStudentCount, setMaterialStudentCount] = useState<number>(0)
    const [materialWeight, setMaterialWeight] = useState<number>(0) // TODO(CU-861mnmf5m)

    const [classesCount, setClassesCount] = useState<number>(0)
    const [studentsCountTotal, setStudentsCountTotal] = useState<number>(0)

    const [lastUpdate, setLastUpdate] = useState<number>(0)

    const handleUpdate = (time: number) => {
        setLastUpdate(time)
    }

    const handleClose = () => {
        props.onClose()
    }

    const calculateNumberOfItems = (countStudents: number) => {
        let numberOfExtraItems:number = 0;
        //const numberOfExtraItems:number =  Math.floor(countStudents / classSize)
        numberOfExtraItems =  Math.floor(countStudents / classSize);
        if (numberOfExtraItems < 1 ) {
            numberOfExtraItems = 1;
        }
        const numberOfItems:number = countStudents + numberOfExtraItems;
        return numberOfItems;
    }

    const handleMaterialStudentCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value)
        if (!isNaN(numberValue)) {
            //setMaterialStudentCount(numberValue) 
            const numberOfItems:number = calculateNumberOfItems(numberValue)
            setMaterialStudentCount(numberOfItems)
            setMaterialWeight(numberOfItems * itemWeight)
        }
    }

    const handleMaterialWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const numberValue = parseInt(event.target.value)
        if (!isNaN(numberValue)) {
            setMaterialWeight(numberValue)
        }
    }

    const handleClassesLoaded = (schoolClasses: CollectingPlaceSchoolClassInDB[]) => {
        setClassesCount(schoolClasses.length)
        setStudentsCountTotal(schoolClasses.reduce((sum, current) => sum + current.countStudents, 0))
        // I had to use a intermediate variable for the student count cause
        // accessing the value of materialStudentCount was not working. An async issue?
        const sumCountMaterial = schoolClasses.reduce((sum, current) => sum + current.countStudents, 0)
        const numberOfItems:number = calculateNumberOfItems(sumCountMaterial)
        setMaterialStudentCount(numberOfItems)
        //setMaterialStudentCount(sumCountMaterial)
        setMaterialWeight(numberOfItems * itemWeight)
    }

    // taken from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data)
        const byteArrays = []

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize)

            const byteNumbers = new Array(slice.length)
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i)
            }

            const byteArray = new Uint8Array(byteNumbers)
            byteArrays.push(byteArray)
        }

        return new Blob(byteArrays, {type: contentType})
    }

    const performConsignment = () => {
        createConsignment(props.collectingPlaceId, materialWeight, materialStudentCount)
            .then((consignment) => {
                // @ts-ignore
                const file = b64toBlob(consignment.pdf, 'application/pdf')

                const element = document.createElement("a");
                element.href = URL.createObjectURL(file);
                element.download = "labels-" + props.collectingPlaceId + ".pdf";

                // simulate link click
                document.body.appendChild(element); // Required for this to work in firefox
                element.click();

                handleUpdate((new Date()).getTime());
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {variant: 'error'});
            })
    }

    useEffect(() => {
    //handleMaterialWeightUpdate(materialWeight)
    }, [props.open, props.collectingPlaceId])


    return (
        <Dialog
            fullScreen
            scroll={"paper"}
            open={props.open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon/>
                    </IconButton>
                    <Typography sx={{ml: 2, flex: 1}} variant="h5" component="div">
                        Consignment for collecting place {props.collectingPlaceId}
                    </Typography>
                    <Button autoFocus color="inherit" size="large" onClick={handleClose}>
                        <Typography variant="h6">Close</Typography>
                    </Button>
                </Toolbar>
            </AppBar>
            <Box m={6}
                 component="form"
                 autoComplete="off">
                <Grid container spacing={3}>
                    <Grid item xs={12} height={400}>
                        <SchoolClassesList
                            collectingPlaceId={props.collectingPlaceId}
                            onClassesLoaded={handleClassesLoaded}
                            onUpdate={handleUpdate}
                            lastUpdate={lastUpdate}/>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography variant={"h6"} sx={{mb: 2}}>Collecting Boxes:</Typography>
                        <CollectingBoxesList
                            collectingPlaceId={props.collectingPlaceId}
                            getCollectingBoxes={getCollectingBoxes}
                            createCollectingBoxReturn={createCollectingBoxReturn}
                            createCollectingBoxShipment={createCollectingBoxShipment}
                            onUpdate={handleUpdate}
                            lastUpdate={lastUpdate}/>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant={"h6"} sx={{mb: 2}}>Material Shipments:</Typography>
                        <MaterialShipmentsList
                            collectingPlaceId={props.collectingPlaceId}
                            onUpdate={handleUpdate}
                            lastUpdate={lastUpdate}/>
                    </Grid>
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant={"h6"} sx={{mb: 2}}>In total: {classesCount} school classes
                            and {studentsCountTotal} students</Typography>
                        <Card>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Default Consignment
                                </Typography>
                                <Typography gutterBottom>
                                    Creates Collecting Box, Collecting Box Shipment and Collecting Box Return.
                                </Typography>
                                <Typography gutterBottom>
                                    Should also create Material Shipment?
                                </Typography>
                                <TextField
                                    label="Total Student Count"
                                    id="total-student-count"
                                    sx={{m: 1, width: '25ch'}}
                                    value={studentsCountTotal}
                                />
                                <TextField
                                    label="Material Shipment Count"
                                    id="material-shipment-count"
                                    sx={{m: 1, width: '25ch'}}
                                    InputProps={{
                                        inputMode: 'numeric',
                                    }}
                                    value={materialStudentCount}
                                    onChange={handleMaterialStudentCountChange}
                                />
                                <TextField
                                    label="Material Shipment Weight"
                                    id="material-shipment-count"
                                    sx={{min: 1, width: '25ch'}}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                        inputMode: 'numeric',
                                    }}
                                    value={materialWeight}
                                    onChange={handleMaterialWeightChange}
                                />
                            </CardContent>
                            <CardActions>
                                <Button size={"large"} onClick={performConsignment}>Perform default consignment</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    )
}
