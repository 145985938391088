/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CollectingBox } from '../models/CollectingBox';
import type { CollectingPlaceOther } from '../models/CollectingPlaceOther';
import type { CollectingPlaceOtherCreation } from '../models/CollectingPlaceOtherCreation';
import type { CollectingPlaceOtherPatch } from '../models/CollectingPlaceOtherPatch';
import type { DHLShipmentOrderRequestShipmentDetails } from '../models/DHLShipmentOrderRequestShipmentDetails';
import type { ReturnOrder } from '../models/ReturnOrder';
import type { Shipment } from '../models/Shipment';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CollectingPlaceOthersService {
    /**
     * Get Collecting Places
     * Gets all registered **Collecting Places**
     * @returns CollectingPlaceOther Registered **Collecting Places**
     * @throws ApiError
     */
    public static getCollectingPlacesCollectingPlacesOtherGet(): CancelablePromise<Array<CollectingPlaceOther>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/other',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create
     * Creates a **default collecting place** for the campaign.
     * @param requestBody
     * @param date
     * @returns CollectingPlaceOther Successfully created collecting place
     * @throws ApiError
     */
    public static createCollectingPlacesOtherPost(
        requestBody: CollectingPlaceOtherCreation,
        date?: string,
    ): CancelablePromise<CollectingPlaceOther> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/other',
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Place
     * Gets registered **Collecting Places**
     * @param collectingPlaceId
     * @returns CollectingPlaceOther Registered **Collecting Place**
     * @throws ApiError
     */
    public static getCollectingPlaceCollectingPlacesOtherCollectingPlaceIdGet(
        collectingPlaceId: string,
    ): CancelablePromise<CollectingPlaceOther> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/other/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Delete Collecting Place
     * Delete collecting place
     * @param collectingPlaceId
     * @returns any Successful deletion of **Collecting Place**
     * @throws ApiError
     */
    public static deleteCollectingPlaceCollectingPlacesOtherCollectingPlaceIdDelete(
        collectingPlaceId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/collectingPlaces/other/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Patch Collecting Place
     * Updates details of collecting place
     * @param collectingPlaceId
     * @param requestBody
     * @returns CollectingPlaceOther Patched **Collecting Place**
     * @throws ApiError
     */
    public static patchCollectingPlaceCollectingPlacesOtherCollectingPlaceIdPatch(
        collectingPlaceId: string,
        requestBody: CollectingPlaceOtherPatch,
    ): CancelablePromise<CollectingPlaceOther> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/collectingPlaces/other/{collecting_place_id}',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Get Collecting Boxes For Place
     * Gets all collecting boxes for a **Collecting Place**.
     * @param collectingPlaceId
     * @returns CollectingBox **Collecting Boxes** for school
     * @throws ApiError
     */
    public static getCollectingBoxesForPlaceCollectingPlacesOtherCollectingPlaceIdCollectingBoxesGet(
        collectingPlaceId: string,
    ): CancelablePromise<Array<CollectingBox>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/collectingPlaces/other/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Collecting Box
     * Creates a collecting box for the specified **Collecting Place**.
     *
     * A collecting box consists of
     * 0-n return orders to be sent back
     * 0-n DHL shipments to send the collecting box
     * @param collectingPlaceId
     * @param date
     * @returns CollectingBox Successful Response
     * @returns any Created **Collecting Box**
     * @throws ApiError
     */
    public static createCollectingBoxCollectingPlacesOtherCollectingPlaceIdCollectingBoxesPost(
        collectingPlaceId: string,
        date?: string,
    ): CancelablePromise<CollectingBox | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/other/{collecting_place_id}/collectingBoxes',
            path: {
                'collecting_place_id': collectingPlaceId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Collecting Box Return
     * Creates a return for the specified **Collecting Box**.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param date
     * @returns ReturnOrder Successful Response
     * @returns any Created **Return Order**
     * @throws ApiError
     */
    public static createCollectingBoxReturnCollectingPlacesOtherCollectingPlaceIdCollectingBoxesCollectingBoxIdReturnsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        date?: string,
    ): CancelablePromise<ReturnOrder | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/other/{collecting_place_id}/collectingBoxes/{collecting_box_id}/returns',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            errors: {
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
    /**
     * Create Collecting Box Shipment
     * Creates a shipment for the specified **Collecting Box**.
     * IMPORTANT: DHL checks all addresses for their existence! If any address does not exist, this call returns a `400`.
     * @param collectingPlaceId
     * @param collectingBoxId
     * @param requestBody
     * @param date
     * @returns Shipment Successful Response
     * @returns any Created **Shipment**
     * @throws ApiError
     */
    public static createCollectingBoxShipmentCollectingPlacesOtherCollectingPlaceIdCollectingBoxesCollectingBoxIdShipmentsPost(
        collectingPlaceId: string,
        collectingBoxId: string,
        requestBody: DHLShipmentOrderRequestShipmentDetails,
        date?: string,
    ): CancelablePromise<Shipment | any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/collectingPlaces/other/{collecting_place_id}/collectingBoxes/{collecting_box_id}/shipments',
            path: {
                'collecting_place_id': collectingPlaceId,
                'collecting_box_id': collectingBoxId,
            },
            query: {
                'date': date,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized: The client could not be authenticated since the request misses the required HTTP headers or the credentials are wrong.`,
                403: `Forbidden: The client has successfully been authenticated but could not be authorized for the particular request.`,
                422: `Validation Error.`,
                500: `Internal Server Error.`,
            },
        });
    }
}
