import {
    ReturnOrder, ReturnsService, ReturnOrderPatch
} from '../services/takebackApi';

export type {
    ReturnOrder,
    ReturnOrderPatch,
}

const {
    getReturnOrdersReturnsGet,
    patchReturnOrderReturnsReturnOrderIdPatch,
    getReturnOrderReturnsReturnOrderIdGet
} = ReturnsService;


export const getReturns = async (season: string | null) : Promise<Array<ReturnOrder>> => {
    try {
        const returns = await getReturnOrdersReturnsGet();
        if(season) {
            return returns.filter(it => it.season === season)
        } else {
            return returns
        }
    } catch (error) {
        throw error
    }
};

export const patchReturn = async (
    id: string,
    updates: ReturnOrderPatch
): Promise<ReturnOrder> => {
    try {
        return await patchReturnOrderReturnsReturnOrderIdPatch(id, updates);
    } catch (error) {
        throw error
    }
};

export const getReturn = async (returnId: string) : Promise<ReturnOrder> => {
    try {
        return await getReturnOrderReturnsReturnOrderIdGet(returnId);
    } catch (error) {
        throw error
    }
};
